import {Flex} from "../../../../../shared/components/Layouts"
import {FrameworkTopBar} from "../../components/frameworkTopBar";
import {useFramework} from "../../hooks/useFramework";
import {Spinner} from "../../../../../shared/components/Spinner";
import {DeleteFrameworkDialog} from "../../components/dialogs/frameworks/deleteFramework";
import {BuilderControlsTable} from "../../components/builderControlsTable";
import {Typo} from "../../../../../shared/components/Typography";
import {AddControlDialog} from "../../components/dialogs/controls/addControl";
import {UpdateControlDialog} from "../../components/dialogs/controls/updateControl";
import {BuilderEvidencesTable} from "../../components/builderEvidencesTable";
import {AddEvidenceDialog} from "../../components/dialogs/evidences/addEvidence";
import {UpdateEvidenceDialog} from "../../components/dialogs/evidences/updateEvidence";
import {DetachEvidenceDialog} from "../../components/dialogs/evidences/detachEvidence";
import {SelectExistingControl} from "../../components/dialogs/controls/selectExistingControl";
import {SelectExistingEvidence} from "../../components/dialogs/evidences/selectExistingEvidence";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {UpdateFrameworkDialog} from "../../components/dialogs/frameworks/updateFramework";

export const Framework = () => {
    const {framework, isLoading, isEmpty, selectedFrameworkId, _isLoadingControlAndEvidencesByFrameworkId} = useFramework();
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage'})
    return(
        <Flex w={'100%'} h={'100%'} direction={'column'} >
            {framework &&
                <>
                    <FrameworkTopBar framework={framework}/>

                    <Flex w={'100%'} h={'100%'} jc={'space-between'} m={'10px 0 0 0'}>
                        <BuilderControlsTable/>
                        <BuilderEvidencesTable/>
                    </Flex>

                    <UpdateFrameworkDialog />
                    <DeleteFrameworkDialog framework={framework}/>
                </>
            }

            {(isEmpty && !isLoading) &&  <Typo>No custom framework yet...</Typo>}

            {(!selectedFrameworkId && !isEmpty) &&
                <Flex w={'100%'} h={'100%'} jc={'center'} ai={'center'}>
                    <Typo fontSize={'24px'} fontWeight={500}>{t('Select Framework')}</Typo>
                </Flex>
            }
            {(!isLoading && _isLoadingControlAndEvidencesByFrameworkId && selectedFrameworkId) && <Spinner />}
            <AddControlDialog />
            <UpdateControlDialog />
            <SelectExistingControl />

            <AddEvidenceDialog />
            <UpdateEvidenceDialog />
            <DetachEvidenceDialog />
            <SelectExistingEvidence />
        </Flex>
    )
}