import {
    frameworks,
    isLoaded,
    isLoading,
    isLoadingControlAndEvidencesByFrameworkId,
    selectedFramework
} from "../../store/slice";
import {useSelector} from "react-redux";
import {frameworkType} from "../../types";
import {useEffect, useState} from "react";

export const useFramework = () => {
    const selectedFrameworkId = useSelector(selectedFramework);
    const _frameworks = useSelector(frameworks);
    const _isLoadingControlAndEvidencesByFrameworkId = useSelector(isLoadingControlAndEvidencesByFrameworkId);
    const _isLoading = useSelector(isLoading);
    const _isLoaded = useSelector(isLoaded);


    const [framework, setFramework] = useState<frameworkType | null>(null);

    useEffect(() => {
        if(selectedFrameworkId && !_isLoadingControlAndEvidencesByFrameworkId && _isLoaded){
            setFramework(_frameworks.find(e => e.id === selectedFrameworkId) || null);
        }
        if(!selectedFrameworkId && !_isLoadingControlAndEvidencesByFrameworkId){
            setFramework(null);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFrameworkId, _isLoadingControlAndEvidencesByFrameworkId, _isLoaded, _frameworks]);

    return{
        selectedFrameworkId,
        framework,
        isLoading: _isLoading,
        _isLoadingControlAndEvidencesByFrameworkId,
        isEmpty: !_frameworks.length
    }
}