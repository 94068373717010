import {PaginationInput} from "../../../../shared/types";
import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {
    extendedOrganization,
    getSmallOrgsResponse,
    organizationCollabsRuleConfig,
    quickStartType,
    workspaceConfigProfileMappingType
} from "../types";


export const getShortOrganizations = async (pageInfo: PaginationInput): Promise<getSmallOrgsResponse> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getSmallOrgsByAdmin($pageInfo: PaginationInput!){
                      getOrganizationsByAdmin(pageInfo:$pageInfo){
                        pageInfo{
                          total
                          count
                          page
                          sort
                        }
                        orgs{
                          id
                          name
                          subscription
                          subscriptionExpiry
                          blocked
                        }
                      }
                    }
                `,
                variables: {
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationsByAdmin;
    })
)

export const getExtendedOrganization = async (id: string): Promise<extendedOrganization> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getExtendedOrganizationByAdmin($id:String!){
                      getExtendedOrganizationByAdmin(id:$id){
                        id
                        name
                        blocked
                        blockedReason
                        trialUsed
                        subscription
                        subscriptionExpiry
                        configuration{
                          availableWizards
                          availableWorkspaceCount
                          availableCollaboratorsCount
                          availableTargetsVulnerabilityScan
                          availableCollaboratorsRules{
                            email
                            type
                            count
                          }
                        }
                        collaborators{
                          email
                          publicId
                          firstName
                          lastName
                          status
                          role
                        }
                        workspaces{
                          name
                          id
                          config{
                            accountsMappingSchema
                            profilesMappingSchema{
                              appName
                              groupsName
                            }
                          }
                          collaborators{
                            email
                            publicId
                            firstName
                            lastName
                            status
                            role
                          }
                          invites{
                            email
                            roles
                            inviteId
                            status
                          }
                        }
                      }
                    }
                `,
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getExtendedOrganizationByAdmin;
    })
)

export const getQuickStarts = async (): Promise<quickStartType[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getQuickStarts{
                      getQuickStarts{
                        id
                        name
                      }
                    }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getQuickStarts;
    })
)

export const activateOrganization = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation activateOrganizationByAdmin($id:String!){
                      activateOrganizationByAdmin(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.activateOrganizationByAdmin.message;
    })
)

// export const addCollabToOrganization = async (id: string, email: string, role: string): Promise<smallCollab> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     mutation addCollabToOrganizationByAdmin($id:String!, $email:String!, $role:String!){
//                       addCollabToOrganizationByAdmin(id:$id, email:$email, role:$role){
//                         lastName
//                         firstName
//                         email
//                         role
//                         status
//                         publicId
//                       }
//                     }
//                 `,
//                 variables: {
//                     id,
//                     email,
//                     role
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.addCollabToOrganizationByAdmin;
//     })
// )

export const blockOrganization = async (id: string, reason: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation blockOrganizationByAdmin($id:String!, $reason:String!){
                      blockOrganizationByAdmin(id:$id, reason:$reason){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    reason
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.blockOrganizationByAdmin.message;
    })
)

export const deleteOrganization = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation deleteOrganizationByAdmin($id:String!){
                      deleteOrganizationByAdmin(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteOrganizationByAdmin.message;
    })
)

export const deleteWorkspace = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation deleteWorkspaceByAdmin($id:String!){
                      deleteWorkspaceByAdmin(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceByAdmin.message;
    })
)

export const transferOwnership = async (organizationId: string, transferFromPublicId: string, transferToPublicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation transferOrganizationOwnershipByAdmin($transferToPublicId:String!,$organizationId:String!, $transferFromPublicId:String!){
                      transferOrganizationOwnershipByAdmin(transferToPublicId:$transferToPublicId, organizationId:$organizationId, transferFromPublicId:$transferFromPublicId){
                        message
                      }
                    }
                `,
                variables: {
                    organizationId,
                    transferFromPublicId,
                    transferToPublicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.transferOrganizationOwnershipByAdmin.message;
    })
)

export const updateWorkspaceAccountMappingConfig = async (id: string, newValue: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateWorkspaceAccountMappingConfigByAdmin($newValue:String!,$id:String!){
                      updateWorkspaceAccountMappingConfigByAdmin(newValue:$newValue, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceAccountMappingConfigByAdmin.message;
    })
)

export const updateWorkspaceProfileMappingConfig = async (id: string, newValue: workspaceConfigProfileMappingType[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateWorkspaceProfileMappingConfigByAdmin($newValue:UpdateProfileMappingInputUpdateProfileMappingInput!,$id:String!){
                      updateWorkspaceProfileMappingConfigByAdmin(newValue:$newValue, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue: {scheme: newValue}
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceProfileMappingConfigByAdmin.message;
    })
)

export const updateAvailableCollabsCounter = async (id: string, newValue: number): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateAvailableCollabsCounterByAdmin($newValue:String!,$id:String!){
                      updateAvailableCollabsCounterByAdmin(newValue:$newValue, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue: newValue.toString()
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAvailableCollabsCounterByAdmin.message;
    })
)


export const updateAvailableWorkspacesCounter = async (id: string, newValue: number): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateAvailableWorkspacesCounterByAdmin($newValue:String!,$id:String!){
                      updateAvailableWorkspacesCounterByAdmin(newValue:$newValue, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue: newValue.toString()
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAvailableWorkspacesCounterByAdmin.message;
    })
)

export const updateOrgCollabsRules = async (id: string, newValue: organizationCollabsRuleConfig[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateOrgCollabsRulesByAdmin($newValue:adminOrganizationCollabRuleInputArray!,$id:String!){
                      updateOrgCollabsRulesByAdmin(newValue:$newValue, id:$id){  
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue: {rules: newValue} //yep, its dump
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrgCollabsRulesByAdmin.message;
    })
)

export const updateOrgSubscription = async (id: string, subscription: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateOrgSubscriptionByAdmin($subscription:String!,$id:String!){
                      updateOrgSubscriptionByAdmin(subscription:$subscription, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    subscription,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrgSubscriptionByAdmin.message;
    })
)

export const updateOrgSubscriptionExpiry = async (id: string, expiry: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateOrgSubscriptionExpiryByAdmin($expiry:String!,$id:String!){
                      updateOrgSubscriptionExpiryByAdmin(expiry:$expiry, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    expiry,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrgSubscriptionExpiryByAdmin.message;
    })
)

export const updateOrgTrialUsed = async (id: string, isUsed: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateOrgTrialUsedByAdmin($isUsed:String!,$id:String!){
                      updateOrgTrialUsedByAdmin(isUsed:$isUsed, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    isUsed,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrgTrialUsedByAdmin.message;
    })
)

export const updateOrganizationWizards = async (id: string, wizardIds: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateOrganizationWizardsByAdmin($wizards:UpdateWizardsInput!,$id:String!){
                      updateOrganizationWizardsByAdmin(wizards:$wizards, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    wizards: {wizardIds},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationWizardsByAdmin.message;
    })
)

export const updateAvailableVulTargetsCounter = async (id: string, newValue: number): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation updateAvailableVulTargetsCounterByAdmin($newValue:String!,$id:String!){
                      updateAvailableVulTargetsCounterByAdmin(newValue:$newValue, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    newValue: newValue.toString()
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAvailableVulTargetsCounterByAdmin.message;
    })
)