//Core
import {useForm} from "../../../../../shared/hooks/useForm";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {loginAction} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {isPerformingLoginSelector} from "../../store/selectors";
import {openContactDialog} from "../../store/slice";
import {UseManageWorkspacesAndOrganizations} from "../../../newWorkspaces/hooks/useManageWorkspacesAndOrganizations";
import {PATH_LOCAL_2FA} from "../../../../Components/builder/constants";
// import {PATH_LOCAL_SPACE} from "../../../shell/constants";

type ReturnTypes = {
    signIn: (_username: string, _password: string) => void;
    handleChange:(event: React.ChangeEvent<HTMLInputElement>) => void,
    isVisible:boolean;
    isLoading:boolean;
    isActive: boolean;
    changeVisiblePassword: () => void;
    form: {
        username: string;
        password: string;
    },
    handleForm: (e: any) => void;
    openContactUs: () => void;
}

export const useUserLogin = ():ReturnTypes => {
    const {form: { username, password}, handleChange} = useForm({username: '', password: ''});
    const [isVisible, changeVisibility] = useState<boolean>(false);

    const {setAuthComplete} = UseManageWorkspacesAndOrganizations();

    const isLoading = useSelector(isPerformingLoginSelector);
    const isActive = validateEmail(username) && validatePassword(password);
    const dispatch = useDispatch();
    const history = useHistory();

    const onSuccess = (value: boolean) => {
        setAuthComplete(value);
        history.push(PATH_LOCAL_2FA); //going to 2fa page
    };

    useEffect(() => {
        localStorage.clear();
    }, []);

    const signIn = (_username: string, _password: string) => {
        dispatch(loginAction({username: _username.toLowerCase(), password: _password, onSuccess}))
    };

    const changeVisiblePassword = () => {
        changeVisibility(prevState => !prevState);
    }

    //-------

    const handleForm = (e: any) => {
        e.preventDefault();
        const email: string = e.target.username.value;
        const password: string = e.target.password.value;
        if(validateEmail(email)){
            if(validatePassword(password)){
                signIn(email, password);
            }
        }
    }

    function validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validatePassword(password: string): boolean {
        var re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        return re.test(password);
    }

    return {
        signIn,
        handleChange,
        isVisible,
        isActive,
        changeVisiblePassword,
        isLoading,
        form: {
            username,
            password
        },
        handleForm,
        openContactUs: () => dispatch(openContactDialog())
    }
}
