import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useState} from "react";

export type createFrameworkIdGapDialogInputProps = {
    isOpen: boolean;
    handleClose: () => void,
    onCreate: (innerGap: string) => void,
    addedGaps: string[]
}

export const CreateInnerGap = ({isOpen, onCreate, handleClose, addedGaps}: createFrameworkIdGapDialogInputProps) => {
    const [form, setForm] = useState<string>('');

    const isExist = form.length > 0 && addedGaps.some(e => e === form);

    const isOk = (): boolean => {
        return form.trim().length > 0 && !isExist;
    }

    const handleSubmit = () => {
        onCreate(form);
        cleanUp();
    }

    const onClose = () => {
        handleClose();
        cleanUp();
    }

    const cleanUp = () => {
        setForm('');
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Create inner Gap')}</DialogTitle>

            <DialogContent>
                <TextField
                    required
                    autoFocus
                    label={('Type inner gap')}
                    value={form}
                    onChange={(e) => setForm(e.target.value as string)}
                    size={'small'}
                    sx={{margin: '8px 0 0 0'}}
                    error={isExist}
                    helperText={isExist ? ('Such inner gap already added') : ''}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant={'text'}>{('Cancel')}</Button>
                <Button
                    disabled={!isOk()}
                    variant={'contained'}
                    onClick={handleSubmit}
                >{('Save')}</Button>
            </DialogActions>
        </Dialog>
    )

}