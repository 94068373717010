import {useForm} from "../../../../../../shared/hooks/useForm";
import {TPermission} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideCreatePermissionDialog, loading, permissions} from "../../../store/slice";
import {addInfoSnack} from "../../../../../BarsENV/snack/store/slice";
import {CreatePermissionByAdmin} from "../../../store/actions";
import {useEffect} from "react";

export const useCreatePermission = () => {
    const dispatch = useDispatch();

    const _permissions = useSelector(permissions);
    const isOpen = useSelector(dialogs).createPermission;
    const isLoading = useSelector(loading).permissionsCreate;

    const handleClose = () => {
        dispatch(hideCreatePermissionDialog());
    }

    useEffect(() => {
        if(isOpen) cleanUp();
        //eslint-disable-next-line
    }, [isOpen]);

    const {form, setForm, handleChange} = useForm<TPermission & {autocompleteInput: string}>({
        name: '',
        tags: [],
        autocompleteInput: ''
    });

    const handleAddTag = () => {
        // console.log(`form.autocompleteInput: ${form.autocompleteInput}`);
        const split = form.autocompleteInput.split(', ');
        if(split.length > 0){
            console.log(split);
            split.forEach(e => {
                if(form.tags.some(t => t.trim() === e.trim())) {
                    dispatch(addInfoSnack(`Permission with name ${e} already exists! This tag was skipped.`))
                }else{
                    // console.log(`settings ${e} to tags`);
                    setForm(prev => {return {...prev, tags: [...prev.tags, e]}});
                }
            })
            setForm(prev => {return {...prev, autocompleteInput: ''}});
            return;
        }else{
            if(form.autocompleteInput.trim().length > 0){
                if(form.tags.some(e => e.trim() === form.autocompleteInput.trim())){
                    dispatch(addInfoSnack(`Permission with name ${form.autocompleteInput} already exists!`))
                    setForm({...form, autocompleteInput: ''});
                }else{
                    setForm({...form, tags: [...form.tags, form.autocompleteInput], autocompleteInput: ''});
                }
            }
        }
    }

    const handleAddAutoCompletedAddTag = (tag: string) => {
        if(tag.trim().length > 0){
            if(form.tags.some(e => e.trim().toLowerCase() === tag.trim().toLowerCase())){
                dispatch(addInfoSnack(`Permission with name ${form.name} already exists!`))
            }else{
                setForm({...form, tags: [...form.tags, tag], autocompleteInput: ''});
            }
        }
    }

    const handleDeleteTag = (tag: string) => {
        setForm({
            ...form,
            tags: form.tags.filter(e => e !== tag)
        })
    }

    const handleSetAutocompleteValue = (value: string) => {
        console.log(value);
        setForm({...form, autocompleteInput: value});
    }

    const handleSubmit = () => {
        if(_permissions.some(e => e.name.trim().toLowerCase() === form.name.trim().toLowerCase())){
            dispatch(addInfoSnack(`Permission with name ${form.name} already exists!`));
        }else{
            if(form.name.trim().length > 0){
                dispatch(CreatePermissionByAdmin({permission: {name: form.name.trim(), tags: form.tags}}))
            }
        }
    }

    const cleanUp = () => {
        setForm({
            name: '',
            tags: [],
            autocompleteInput: ''
        })
    }
    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        isOk: form.name.trim().length > 0,

        form,
        handleChange,
        handleAddTag,
        handleDeleteTag,
        _permissions,
        handleSetAutocompleteValue,
        handleAddAutoCompletedAddTag
    }
}