import React, {FC} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {LOAD_FILTERS_VALUES_ON_MOUNT} from "../../../constants";
import {Flex} from "../../Layouts";
import {ChipItem} from "../../ChipItem";
import {useDownloadCategories} from "../hooks/useDownloadCategories";
import {DocumentNode} from "@apollo/client";
import {ChipProps, TGeneralCategory} from "../../../types";

type TProps = {
    index: number,
    array: string[],
    filterName: string,

    localValues?: string[],

    queryCategory: DocumentNode,
    mapQueryTitle: TGeneralCategory,
    chips: ChipProps[],

    handleAdd: (chip: ChipProps) => void,
    handleDel: (chip: ChipProps) => void,

    localePrefix: string
}
export const QueryFilterItem: FC<TProps> = (
    {
        index,
        array,
        localValues,

        queryCategory,
        filterName,
        localePrefix,
        mapQueryTitle,
        chips,

        handleAdd,
        handleDel,
    }
) => {

    const {loading, variants, inputObj, setInputObj} = useDownloadCategories(queryCategory, filterName, localValues || [], Boolean(localValues));


    return (
        <>
            <Autocomplete
                options={variants.filter(variant => !chips.some(chip => chip.value === variant && chip.filterName === filterName))}
                getOptionLabel={(option) => option}

                sx={{margin: '10px 0', direction: 'ltr'}}
                size={"small"}
                clearOnEscape
                renderInput={(params) => <TextField {...params} label={mapQueryTitle[filterName]} variant="outlined"/>}
                fullWidth
                value={null}
                onChange={(e, value: string | null) => {
                    handleAdd({filterName, value: value ?? ''});
                    setInputObj(() => ({ [filterName]: ''}));
                }}
                loading={loading}
                inputValue={inputObj[filterName]}
                onInputChange={(e: any) => setInputObj(() => ({ [filterName]: e?.target?.value || ''}))}
                openOnFocus={LOAD_FILTERS_VALUES_ON_MOUNT}
            />

            <Flex p={index !== array.length - 1 ? "10px 0 10px 0": '10px 0 0 0'} direction={"column"} w={'100%'}>
                {chips.filter(c => c.filterName === filterName).map(
                    ({value, filterName}) => <ChipItem
                        key={`${filterName}:${value}`}
                        margin="0 8px 8px 0"
                        label={value}
                        onDelete={() => {
                        handleDel({filterName, value});
                    }}/>
                )}
            </Flex>
        </>
    )
}