
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    createWorkspace,
    getOrganizationsWithWorkspacesWithPaging,
    getWorkspacesByOrgIdWithPaging,
    joinWorkspaceByOrgAdmin
} from "../../api";
import {
    TOrganizationWithWorkspaces,
    TWorkspaceWithShortCollabs,
    TWorkspaceWithShortCollabsAndRequester
} from "../../types";
import {TPageInfo} from "../../../../../shared/types";
import { addSuccessfulSnack } from "../../../../BarsENV/snack/store/slice";

export const GetOrganizationsWithWorkspacesWithPaging = createAsyncThunk(
    'ChooseWorkspace/getOrganizationsWithWorkspacesWithPaging',
    async (data: {page: number, count: number, name: string}, ): Promise<{organizations: TOrganizationWithWorkspaces[], pageInfo: TPageInfo, invitesCounter: number}> => {
        return await getOrganizationsWithWorkspacesWithPaging(data.page, data.count, data.name);
    }
);

export const GetWorkspacesByOrgIdWithPaging = createAsyncThunk(
    'ChooseWorkspace/getWorkspacesByOrgIdWithPaging',
    async (data: {page: number, count: number, organizationId: string, name: string} ): Promise<{workspaces: TWorkspaceWithShortCollabsAndRequester[], pageInfo: TPageInfo, organizationId: string}> => {
        const resp =  await getWorkspacesByOrgIdWithPaging(data.page, data.count, data.organizationId, data.name);
        return {
            ...resp,
            organizationId: data.organizationId,
        }
    }
);

//createWorkspace
export const CreateWorkspace = createAsyncThunk(
    'ChooseWorkspace/CreateWorkspace',
    async (data: {organizationId: string, workspaceName: string}, {dispatch}): Promise<TWorkspaceWithShortCollabs> => {
        const res = await createWorkspace(data.organizationId, data.workspaceName);
        dispatch(addSuccessfulSnack(`Workspace ${data.workspaceName} created!`));
        return res;
    }
);

//joinWorkspaceByOrgAdmin
export const JoinWorkspaceByOrgAdmin = createAsyncThunk(
    'ChooseWorkspace/joinWorkspaceByOrgAdmin',
    async (data: {workspaceId: string}, {dispatch}): Promise<string> => {
        const res = await joinWorkspaceByOrgAdmin(data.workspaceId);
        dispatch(addSuccessfulSnack(res));
        return data.workspaceId;
    }
);