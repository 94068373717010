import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideChangeOrgSubExpiryDialog} from "../../../store/slice";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {UpdateOrgSubscriptionExpiry} from "../../../store/actions";

export const UpdateOrganizationSubscriptionExpiryDialog = () => {
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeSubExpiry} = useSelector(loading);
    const {changeOrganizationSubExpiryDialog : {isOpen}} = useSelector(dialogs);
    const [date, setDate] = useState<Date>(new Date());

    const handleClose = () => {
        dispatch(hideChangeOrgSubExpiryDialog());
    }

    const handleSubmit = () => {
        if(org){
            const parsedDate = new Date(org.subscriptionExpiry.endsWith('Z') ? org.subscriptionExpiry : `${org.subscriptionExpiry}Z`);
            if(parsedDate !== date){
                dispatch(UpdateOrgSubscriptionExpiry({id: org.id, expiry: date.toISOString()}));
            }
        }
    }

    useEffect(() => {
        if(isOpen && org){
            const parsedDate = new Date(org.subscriptionExpiry.endsWith('Z') ? org.subscriptionExpiry : `${org.subscriptionExpiry}Z`);
            setDate(parsedDate);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Update Subscription Expiry</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} sx={{marginTop: '10px'}} />}
                        label="Expiry"
                        value={date}
                        ampm={false}
                        ampmInClock={false}
                        views={['day', 'month', 'year']}
                        onChange={(newValue) => {
                            newValue && setDate(newValue);
                        }}
                    />
                </LocalizationProvider>
            </DialogContent>
            {isLoadingChangeSubExpiry ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}