import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideActivateOrgDialog, extendedOrganization, loading} from "../../../store/slice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import {ActivateOrganization} from "../../../store/actions";

export const ActivateOrganizationDialog = () => {
    const dispatch = useDispatch();
    const {activateOrganizationDialog: {isOpen}} = useSelector(dialogs);
    const org = useSelector(extendedOrganization);
    const {isLoadingActivateOrg} = useSelector(loading);

    const handleClose = () => {
        dispatch(hideActivateOrgDialog());
    };

    const handleActivateOrg = () => {
        if(org){
            dispatch(ActivateOrganization({id: org.id}));
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Activate Organization</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want activate this organization?
                </DialogContentText>
            </DialogContent>
            {isLoadingActivateOrg ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleActivateOrg} disabled={!org} color={'success'}>Activate</Button>
                </DialogActions>
            }
        </Dialog>
    )
}