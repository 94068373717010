import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideBlockOrgDialog, loading, extendedOrganization} from "../../../store/slice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import {BlockOrganization} from "../../../store/actions";

export const BlockOrganizationDialog = () => {
    const dispatch = useDispatch();
    const {blockOrganizationDialog: {isOpen}} = useSelector(dialogs);
    const org = useSelector(extendedOrganization);
    const {isLoadingBlockOrg} = useSelector(loading);
    const [reason, setReason] = useState<string>('');

    const handleClose = () => {
        dispatch(hideBlockOrgDialog());
        setReason('');
    };

    const handleBlockUser = () => {
        if(org){
            dispatch(BlockOrganization({reason, id: org.id}));
            setReason('');
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Block organization</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To block organization type the reason below
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Blocking Reason"
                    fullWidth
                    variant="standard"
                    value={reason}
                    onChange={(e) => {setReason(e.target.value as string)}}
                />
            </DialogContent>
            {isLoadingBlockOrg ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleBlockUser} disabled={!org || !reason.trim().length} color={'success'}>Block</Button>
                </DialogActions>
            }
        </Dialog>
    )
}