import {useDispatch, useSelector} from "react-redux";
import {controls, deleteControlDialog, hideDeleteControl} from "../../../../store/slice";
import {Flex} from "../../../../../../../shared/components/Layouts";
import CloseIcon from "@material-ui/icons/Close";
import {DialogContent, TextField} from "@mui/material";
import {Typo} from "../../../../../../../shared/components/Typography";
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {WarningDialog} from "../../../styled";
import React, {useEffect, useState} from "react";
import {controlType} from "../../../../types";
import {DeleteControl} from "../../../../store/actions";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeleteControlDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const dispatch = useDispatch();
    const {isOpen, controlId} = useSelector(deleteControlDialog);
    const _controls = useSelector(controls);

    const [currentControl, setCurrentControl] = useState<controlType | null>(null);
    const [field, updateField] = useState<string>('');

    useEffect(() => {
        updateField('');
        if(controlId){
            const currControl = _controls.find(e => e.id === controlId);
            if(currControl) setCurrentControl(currControl);
        }else{
            setCurrentControl(null);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideDeleteControl());
    }
    
    const handleConfirm = () => {
        if(field.trim().toLowerCase() === 'confirm' && controlId){
            dispatch(DeleteControl(controlId));
        }
    }

    return((isOpen && controlId && currentControl) ?
        <WarningDialog
            // maxWidth={''}
            style={{borderRadius: '25px !important', border: '1px solid red'}}
            open={isOpen}
            onClose={handleClose}
        >
            <Flex w={'100%'} jc={'flex-end'} p={!revDir ? '30px 30px 0 0' : '30px 0 0 30px'}>
                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
            </Flex>

            <DialogContent>
                <Flex w={'100%'} direction={'column'} ai={'center'} talign={'center'} p={'0 50px'}>
                    <Typo fontSize={'25px'} fontWeight={300} letterSpacing={'1.53px'} color={'#1D1D1D'}>{t('Delete Control')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} margin={'27px 0 0 0'} color={'background: rgba(0, 0, 0, 0.54)'}>{t('Any changes will be effects all frameworks that the control has attached!')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'}>Effected on {currentControl?.foundInFrameworksCounter || 0} frameworks</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'} margin={'20px 0 10px 0'}>{t('Please type')} “confirm” {t('to delete')}</Typo>

                    <TextField id="filled-basic" label={t("Confirm")}  variant="outlined" sx={{margin: '0 0 20px 0', width: '400px'}} value={field} onChange={(e) => {updateField(e.target.value as string)}}/>

                    
                    {/* <ButtonWithBackround
                        margin={'0 0 10px 0 '}
                        width={'214px'}
                        height={'37px'}
                        color={field.trim().toLowerCase() === 'confirm' ? '#FF0033' : 'gray'}
                        text={'DELETE'}
                        fontColor={'white'}
                        fontSize={'16px'}
                        fontWeight={600}
                        onClick={handleConfirm}
                    /> */}
                    <CustomButton
                        variant={'contained'} 
                        color={'lightError'} 
                        disabled={field.trim().toLowerCase() !== 'confirm'}
                        onClick={handleConfirm}
                        sx={{marginBottom: '10px'}}
                    >{t('Delete')}</CustomButton>
                </Flex>
            </DialogContent>
        </WarningDialog>
            :
            null
    )
}