import {useEffect} from "react";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {useParameters2} from "../useParameters";

import {convertObjectToSearchString} from "../../utils/pagination";
import {TDefaultPagination, useDefaultPagination} from "../useDefaultPagination";

export const usePaginationURL = (
    getData: (pageInfo: {page: number, count: number, sort?: string, filter?: {[key: string]: string[]}}) => void,
    type: TDefaultPagination,
    minus: number = 0
) => {

    const history = useHistory();
    const location = useLocation();
    const {count, page, filter} = useParameters2();
    const DEFAULT_PAGINATION = useDefaultPagination(type, minus);

    useEffect(() => {
        if (isNaN(page) || count === 0 || isNaN(count)) {
            const str = convertObjectToSearchString({
                page: String(DEFAULT_PAGINATION.page),
                count: String(DEFAULT_PAGINATION.rowsPerPage),
                ...filter
            }) || '';
            history.replace({
                pathname: location.pathname,
                search: str,
            });
            // getData({page: DEFAULT_PAGINATION.page, count: DEFAULT_PAGINATION.rowsPerPage, filter});
        } else {
            getData({page, count, filter});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, count, filter])
}