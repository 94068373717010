import React, {FC, useContext, useState} from "react";
import {LeftSideBarItem, TAccordion} from "../../../types";
import {ActiveBox, MenuIconBox, MenuItemWrapper, TitleBox} from "../../../styles";
import {Typo} from "../../../../../../shared/components/Typography";
import {ACTIVE_MENU, TEXT_MENU} from "../../../constants";
import {ThemeContext} from "styled-components";
import {useMainTranslation} from "../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

type PropTypes = {
    item: LeftSideBarItem;
    activeRoute: LeftSideBarItem[];
    handleActive: (item: LeftSideBarItem) => void;
    complex?: boolean;
    status: TAccordion;
};

export const RegularItem: FC<PropTypes> = (props: PropTypes) => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'navigationMenu'});

    const { item, activeRoute: [firstLevel], handleActive, complex = false, status: {expanded} } = props;
    const isActive = firstLevel.title === item.title;
    const { short, bit } = useContext(ThemeContext);
    const [hover, setHover] = useState<{[key: string]: boolean}>({});
    return (
        <MenuItemWrapper ai={'flex-start'} onClick={() => handleActive(item)}
                         onMouseEnter={() => setHover({[item.title]: true})}
                         onMouseLeave={() => setHover({[item.title]: false})}
                         complex={complex} id={item.title}
                         style={{...expanded && bit && !complex &&{display: 'none'}}}
        >
            <ActiveBox active={isActive} expanded revDir={revDir}/>
            <MenuIconBox active={isActive} expanded isHover={hover[item.title]} revDir={revDir}>
                {
                    item.icon
                        ? item.icon.active && isActive
                        ? <item.icon.active onClick={() => {}} />
                        : <item.icon.inactive onClick={() => {}} />
                        : null
                }
            </MenuIconBox>
            <TitleBox revDir={revDir}>
                <Typo fontSize={bit ? '10px': short ? '12px' : '14px'} color={hover[item.title] ? 'white' : isActive ? ACTIVE_MENU : TEXT_MENU} fontWeight={300} letterSpacing={'0.1px'} cursor={'pointer'}>{t(item.title)}</Typo>
            </TitleBox>
        </MenuItemWrapper>
    )
};