import {useDispatch, useSelector} from "react-redux";
import {setDialogAddFilesAction, TVicaTasksSlice, vicaTasksDialogsSelector} from "../../store/slice";

type TResponse = {
    addFiles: TVicaTasksSlice["dialogs"]["addFiles"] & {setAddFilesDialog: (data: {isOpen: boolean}) => void},
}
export const useTasksDialogs = (): TResponse => {
    const dispatch = useDispatch();

    const {addFiles} = useSelector(vicaTasksDialogsSelector);

    const setSelectedTask: TResponse['addFiles']["setAddFilesDialog"] = (data) => {
        dispatch(setDialogAddFilesAction(data))
    }

    return {
        addFiles: {
            ...addFiles,
            setAddFilesDialog: setSelectedTask,
        },
    }
}