import {newApplicationsInitialStateType} from "../types";
import {AppInfo} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import { AppState } from "../../../../shared/redux/rootReducer";
import {AddApp, DeleteManagedApp, GetAppsInfos, UpdateApp } from "./actions";

export const initialState: newApplicationsInitialStateType = {
    apps: [],
    selectedApp: null,
    loadings: {
        list: false,
        exact: false,
        create: false,
        update: false,
        deleteApp: false,
    },
    dialogs: {
        deleteApp: false,
    }
}

export const newApplicationsSlice = createSlice({
    name: 'newApplications',
    initialState,
    reducers: {
        selectApp: (slice, {payload}: {payload: AppInfo}) => {
            slice.selectedApp = payload;
        },

        openDeleteApp: (slice) => {slice.dialogs.deleteApp = true},
        hideDeleteApp: (slice) => {slice.dialogs.deleteApp = false}
    },
    extraReducers: (builder) => {
        builder
            //GetAppsInfos
            .addCase(GetAppsInfos.pending, (slice) => {
                slice.loadings.list = true;
            })
            .addCase(GetAppsInfos.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(GetAppsInfos.fulfilled, (slice, {payload}) => {
                slice.loadings.list = false;
                slice.apps = payload;
            })
        //DeleteManagedApp
            .addCase(DeleteManagedApp.pending, (slice) => {
                slice.loadings.deleteApp = true;
            })
            .addCase(DeleteManagedApp.rejected, (slice) => {
                slice.loadings.deleteApp = false;
            })
            .addCase(DeleteManagedApp.fulfilled, (slice, {payload}) => {
                slice.loadings.deleteApp = false;
                slice.dialogs.deleteApp = false;
                slice.selectedApp = null;
                slice.apps = slice.apps.filter(e => e.id !== payload);
            })
        //AddApp
            .addCase(AddApp.pending, (slice) => {
                slice.loadings.create = true;
            })
            .addCase(AddApp.rejected, (slice) => {
                slice.loadings.create = false;
            })
            .addCase(AddApp.fulfilled, (slice, {payload}) => {
                slice.loadings.create = false;
                //endpoint returns no ids - so in action in onSuccess going to list page where list will be updated
            })
        //UpdateApp
            .addCase(UpdateApp.pending, (slice) => {
                slice.loadings.update = true;
            })
            .addCase(UpdateApp.rejected, (slice) => {
                slice.loadings.update = false;
            })
            .addCase(UpdateApp.fulfilled, (slice, {payload}) => {
                slice.loadings.update = false;
                //endpoint returns no ids - so in action in onSuccess going to list page where list will be updated
                slice.apps = slice.apps.map(e => e.id === payload.id ? payload : e);
                if(slice.selectedApp){
                    slice.selectedApp = payload;
                }
            })
    }

});

export const newApplicationsReducer = newApplicationsSlice.reducer;

export const {
    selectApp,

    openDeleteApp,
    hideDeleteApp
} = newApplicationsSlice.actions;

const selectSelf = (state: AppState):newApplicationsInitialStateType => state.newApplications as newApplicationsInitialStateType;
export const loadings = createSelector(selectSelf, state => state.loadings);
export const apps = createSelector(selectSelf, state => state.apps);
export const selectedApp = createSelector(selectSelf, state => state.selectedApp);
export const dialogs = createSelector(selectSelf, state => state.dialogs);


