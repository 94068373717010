import {useAssetsVariants} from "../../useAssetsVariants";
import {useForm} from "../../../../../../shared/hooks/useForm";
import {TJobDescription, TStatus} from "../../../types";
import {useEffect, useState} from "react";


export const useAddAssetSetting = () => {
    const {
        hideAddSetting: handleClose,
        fields: {selectedField},
        variants, jobs, jobTypeVariants, statuses,
        isLoading: { isAddingField: isLoading, dataForDialog: isLoadingDataForDialog },
        dialogs: { createAssetSetting:isOpen },
        add,
        getDataForCreateJobTitle,
    } = useAssetsVariants();

    const initialJob: TJobDescription = {
        id: '',
        title: '',
        type: '',
        description: '',
        requirements: '',
    }

    const initialStatus: TStatus = {
        id: '',
        title: '',
        textColor: '',
        backgroundColor: '',
    }

    const {form: job, setForm, handleChange} = useForm<TJobDescription | null>(null);
    const {form: status, setForm: setStatusForm, handleChange: handleChangeStatus} = useForm<TStatus | null>(null);
    const [title, setTitle] = useState<string>('');

    const handleConfirm = () => {
        switch(selectedField) {
            case 'jobTitle': job && add({variant: job.title, job}); break;
            case 'status': status && add({variant: status.title, status}); break;
            default: add({variant: title})
        }
    }

    const handleSetJobType = (type: string) => {
        setForm(prev => prev && ({...prev, type}))
    }

    const isOk = () => {
        switch(selectedField){
            case 'jobTitle': return job && job?.title.trim().length > 0 && job?.type.trim().length > 0 && !jobs?.some((e) => e.title.toLowerCase() === job.title.toLowerCase().trim());
            case 'status': return status && status?.title.trim().length > 0 && !statuses?.some((e) => e.title.toLowerCase() === status.title.toLowerCase().trim());
            default: return title.trim().length > 0 && !variants.some(e => e.toLowerCase() === title.toLowerCase().trim());
        }
    }

    const isTitleExist = (): boolean => {
        switch (selectedField) {
            case 'jobTitle': return Boolean(jobs?.some((e) => e.title.toLowerCase() === job?.title.toLowerCase().trim()));
            case 'status': return Boolean(statuses?.some((e) => e.title.toLowerCase() === status?.title.toLowerCase().trim()));
            default: return Boolean(variants.some(e => e.toLowerCase() === title.toLowerCase().trim()));
        }
    }

    useEffect(() => {
        if(isOpen){
            if (selectedField === 'jobTitle') {
                getDataForCreateJobTitle();
                setForm(initialJob);
            }
            selectedField === 'status' && setStatusForm(initialStatus)
        } else {
            setForm(null);
            setStatusForm(null);
            setTitle('');
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return {
        isOk: isOk(),
        isTitleExist: isTitleExist(),
        isOpen,
        isLoading,
        isLoadingDataForDialog,
        job,
        status,
        jobTypeVariants,
        title, setTitle,

        jobs,
        statuses,
        variants,

        handleClose,
        handleConfirm,
        handleChange,
        handleChangeStatus,
        handleSetJobType,
    }

}