import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, hideDeleteApp, loadings, selectedApp} from "../../../store/slice";
import {PATH_LOCAL_APPS_LIST} from "../../../constants";
import {DeleteManagedApp} from "../../../store/actions";
import { LoadingButton } from "@mui/lab";

export const DeleteIntegrationDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const app = useSelector(selectedApp);
    const isOpen = useSelector(dialogs).deleteApp;
    const isLoading = useSelector(loadings).deleteApp;

    const handleClose = () => {
        dispatch(hideDeleteApp());
    }

    const onSuccess = () => history.push(PATH_LOCAL_APPS_LIST);

    const handleSubmit = () => {
        app && dispatch(DeleteManagedApp({id: app.id, onSuccess}));
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Delete ${app?.name} integration`)}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{('Are you sure you want to delete this integration?')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: '0 16px 0 0'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}