//Core
import React, {FC} from 'react';
// import {useHistory} from 'react-router-dom';
//Styled
//Material
import {Box} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

//Typography
import {Typo} from "../../../shared/components/Typography";

//Layouts
import {Flex} from '../../../shared/components/Layouts';
//Shared Components
import {Spinner} from "../../../shared/components/Spinner";

//Hooks
import {useUserLogin} from "./hooks/useUserSignIn";

//consts
import {LABEL, VERSION} from '../../../shared/constants';

// import {ReactComponent as DrawerLogo} from "../../../shared/images/login/new_lg/logo.svg";
import {SignPage} from "../../../shared/components/SignPage";
import {ContactDialog} from "./components/ContactDialog";
import {CustomInput, PasswordField} from "../../../shared/components/Inputs";
import {CustomButton} from "../../../shared/components/Buttons";
import {useMainTranslation} from "../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoginLogo} from "../../../shared/components/TobBarLogo";

export const PasswordHint = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'loginPage'});

    return <React.Fragment>
        <Typo color="inherit" fontSize={"16px"}>{t('Password Hint')}</Typo>
        <Typo color={"primary"} fontSize={"14px"}>{t('At least one digit')}</Typo>
        <Typo color={"primary"} fontSize={"14px"}>{t('At least one lowercase character')}</Typo>
        <Typo color={"primary"} fontSize={"14px"}>{t('At least one uppercase character')}</Typo>
        <Typo color={"primary"} fontSize={"14px"}>{t('At least one special character')}</Typo>
        <Typo color={"primary"} fontSize={"14px"}>{t('At least 8 characters in length')}</Typo>
    </React.Fragment>
}

export const SignIn: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'loginPage'});

    const {
        handleChange,
        // isVisible,
        // changeVisiblePassword,
        isLoading,
        isActive,
        handleForm,
        // openContactUs,
    } = useUserLogin();
    // const history = useHistory();
    return (
        <SignPage revDir={revDir}>
            {(isLoading) && <Spinner/>}
            {/*<Flex w={'100%'}/>*/}

            <Flex overflow={'auto'} minw={'400px'} w={'min(600px, 80%)'} m={'auto'}  direction={"column"} jc={"space-between"}>
                <Box>
                    <Typo fontSize="45px" fontWeight={300}>{t('SIGN IN')}</Typo>
                    <Typo fontSize="25px" fontWeight={300} margin={'10px 0 0 0'}>{(`Welcome to ${LABEL} admin`)}</Typo>
                </Box>

                <form onSubmit={handleForm} style={{width: '100%', display: "flex", flexDirection: "column"}}>

                    <CustomInput
                        label={t('Email')}
                        sx={{margin: '25px 0 15px 0', direction: 'ltr'}}
                        onChange={handleChange}
                        id="email"
                        name="username"
                        type={'email'}
                    />
                    <Tooltip title={PasswordHint()} placement="right" leaveDelay={200}>
                        <PasswordField onChange={handleChange}/>
                    </Tooltip>

                    <CustomButton
                        type={'submit'}
                        active={isActive}
                        disabled={!isActive}
                        variant={'contained'}
                        sx={{
                            marginTop: '22px'
                        }}
                    >
                        {t('SIGN IN')}
                    </CustomButton>
                </form>

                <Flex w="100%" ai="center" jc="center" minh={'80px'} m={'15px 0 10px 0'} direction="column">
                    <LoginLogo />
                    <Typo fontSize="16px" fontWeight={300} letterSpacing="1.23px" cursor="pointer" color="#A0A2B1" margin="10px 0 0 0">{VERSION}</Typo>
                </Flex>
            </Flex>
            <ContactDialog />
        </SignPage>
    )
}
