import {FC} from 'react';
import { Flex } from '../../../shared/components/Layouts';
import {useOrganizations} from "./hooks/useOrganizations";
import {usePaginationURL} from "../../../shared/hooks/usePaginationURL";
import {OrganizationsLeftSideBar} from "./components/leftSideBar";
import {Typo} from "../../../shared/components/Typography";
import {LocalSpinner} from "../../../shared/components/Spinner";
import {OrgInfoAndActions} from "./components/orgInfoAndActions";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {OrganizationCollabsTable} from "./components/organizationCollabsTable";
import {WorkspacesList} from "./components/workspacesList";
import {UpdateOrganizationSubscriptionDialog} from "./components/dialogs/updateSubscription";
import {UpdateOrganizationSubscriptionExpiryDialog} from "./components/dialogs/updateSubscriptionExpiry";
import {BlockOrganizationDialog} from "./components/dialogs/blockOrganization";
import {ActivateOrganizationDialog} from "./components/dialogs/activateOrganization";
import {DeleteOrganizationDialog} from "./components/dialogs/deleteOrganization";
import {UpdateWorkspaceCounter} from "./components/dialogs/updateWorkspaceCounter";
import {UpdateCollabsCounter} from "./components/dialogs/updateCollabsCounter";
import {UpdateOrganizationTrialUsedDialog} from "./components/dialogs/updateTrailUsed";
import {UpdateOrganizationWizardsDialog} from "./components/dialogs/updateWizards";
import {UpdateOrganizationCollabsRulesDialog} from "./components/dialogs/updateCollabsRules";
import {UpdateAccountMappingDialog} from "./components/dialogs/updateAccountMappingDialog";
import {DeleteWorkspaceDialog} from "./components/dialogs/deleteWorkspace";
// import {AddOrgCollabDialog} from "./components/dialogs/addOrgCollab";
import {TransferOwnershipDialog} from "./components/dialogs/transferOwnership";
import {UpdateProfileMappingDialog} from "./components/dialogs/updateProfileMappingDialog";
import {UpdateVulTargetsCounter} from "./components/dialogs/updateVulTargetsCounter";


export const Organizations:FC = () => {
    const {getData, isLoadingExtended, isExtendedOrgPresent, tab, setTab} = useOrganizations();

    usePaginationURL(({page, count, filter}) => {
        getData({page, count, sort: '', filters: filter})
    }, 'left');

    return(
        <Flex w={'100%'} h={'100%'} p={"25px"}>
            <OrganizationsLeftSideBar />
            <Flex grow={'1'} h={'100%'} m={'0 0 0 30px'} direction={'column'} ai={'center'}>
                {!isExtendedOrgPresent && !isLoadingExtended && <Typo>Select Organization</Typo>}
                {isLoadingExtended && <LocalSpinner />}
                {!isLoadingExtended && isExtendedOrgPresent &&
                <>
                    <OrgInfoAndActions />

                    <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                        <ToggleButtonGroup
                            color="warning"
                            value={tab}
                            exclusive
                            onChange={(e, value) => {value && setTab(value)}}
                            size={'small'}
                            sx={{margin: '15px 0 15px 0'}}
                        >
                            <ToggleButton value="collabs">Collaborators</ToggleButton>
                            <ToggleButton value="workspaces">Workspaces</ToggleButton>
                        </ToggleButtonGroup>
                    </Flex>

                    {tab === 'collabs' && <OrganizationCollabsTable />}
                    {tab === 'workspaces' && <WorkspacesList />}
                </>
                }
            </Flex>

            {/*DIALOGS*/}
            <UpdateOrganizationSubscriptionDialog />
            <UpdateOrganizationSubscriptionExpiryDialog />
            <BlockOrganizationDialog />
            <ActivateOrganizationDialog />
            <DeleteOrganizationDialog />
            <UpdateWorkspaceCounter />
            <UpdateCollabsCounter />
            <UpdateOrganizationTrialUsedDialog />
            <UpdateOrganizationWizardsDialog />
            <UpdateOrganizationCollabsRulesDialog />
            <UpdateAccountMappingDialog />
            <UpdateAccountMappingDialog />
            <DeleteWorkspaceDialog />
            {/*<AddOrgCollabDialog />*/}
            <TransferOwnershipDialog/>
            <UpdateProfileMappingDialog/>
            <UpdateVulTargetsCounter />
        </Flex>
    )
} 