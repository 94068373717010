import {LeftSecondMenuColumn} from "../../../../../shared/components/LeftSecondMenuColumn";
import {LeftPaginationFilter} from "../../../../../shared/components/LeftPaginationFilter";
import {useDispatch, useSelector} from "react-redux";
import {extendedLicense, loading, pageInfo, smallLicenses} from "../../store/slice";
import {loader} from "graphql.macro";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {SecondMenuRow} from "../../../../../shared/components/SecondMenuRow";
import {Typo} from "../../../../../shared/components/Typography";
import {Flex} from "../../../../../shared/components/Layouts";
import {handleGetSubColor, handleGetSubNormalName} from "../../../accounts/helpers";
import {AddLicenseCard} from "../addLicenseCard";
import {GetExtendedLicense} from "../../store/actions";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";

export const LicensesLeftSideBar = ({setDefaultTab}: {setDefaultTab: () => void}) => {
    const dispatch = useDispatch();
    const {page, count, total} = useSelector(pageInfo);
    const smalls = useSelector(smallLicenses);
    const {isLoadingSmallLicenses} = useSelector(loading);
    const selected = useSelector(extendedLicense);

    const onClick = (id: string) => {
        dispatch(GetExtendedLicense({id}));
    }

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    return(
        <Flex direction={'column'}>
            <AddLicenseCard setDefaultTab={setDefaultTab}/>
            <LeftPaginationFilter page={page} count={count} total={total}
                                  handle={{type: "url"}}
                                  withFilter={{
                                      mapCategoryTitle: {
                                          used: 'Used',
                                          issued: 'Issued',
                                      },
                                      mapLocalValues: {
                                          used: ['Used', 'Unused'],
                                          issued: ['Issued', 'Not Issued'],
                                      },

                                      mapQueryTitle: {
                                          id: 'License ID',
                                      },
                                      queryCategory: loader('../../gql/query.available.filter.graphql'),
                                      localePrefix: 'adminLicenseFilter'
                                  }}
            />

            <LeftSecondMenuColumn>
                {isLoadingSmallLicenses && <LocalSpinner />}
                {!isLoadingSmallLicenses && smalls.length > 0 && smalls.map((e) => {
                    return(
                        <SecondMenuRow
                            direction={'column'}
                            onClick={() => onClick(e.id)}
                            jc={'space-between'}
                            p={'18px'}
                            h={'auto'}
                            active={selected ? selected.id === e.id : false}
                        >
                            <Typo margin={'0 0 5px 0'} fontSize={'10px'} color={'#c0c0c0'}>{e.id}</Typo>

                            <Typo margin={'0 0 5px 0'} color={handleGetSubColor(e.subscription)}>{handleGetSubNormalName(e.subscription)}</Typo>

                            <Flex w={'100%'} jc={'space-between'}>
                                <Typo color={!e.issued ? 'green' : 'red'}>{e.issued ? 'ISSUED' : 'NOT ISSUED'}</Typo>
                                <Typo color={!e.used ? 'green' : 'red'}>{e.used ? 'USED' : 'NOT USED'}</Typo>
                            </Flex>

                            <Typo margin={'5px 0 0 0'}>Created at: {parseDate(e.createdDate, momentLocale)}</Typo>
                        </SecondMenuRow>
                    )
                })}
            </LeftSecondMenuColumn>
        </Flex>
    )
}