import {accountsInitialState} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {
    ActivateUser,
    AdminGenerateRecoveryCodesByPublicId,
    BlockUser,
    DeleteUser,
    GetExtendedCollab,
    GetShortCollabs
} from "./actions";

export const initialState: accountsInitialState = {
    pageInfo: {
        page: 0,
        total: 0,
        count: 10,
        sort: 'default'
    },
    smallCollabs: [],
    extendedCollab: null,
    extendedCollabOrgs: [],

    mfaForceResult: [],

    loadings: {
        isLoadingSmallCollabs: false,
        isLoadingExtendedCollab: false,
        isLoadingDeleteCollab: false,
        isLoadingBlockCollab: false,
        isLoadingActivateCollab: false,
        isLoadingForceMfa: false
    },

    dialogs: {
        deleteCollabDialog: {
            isOpen: false,
        },
        blockCollabDialog: {
            isOpen: false,
        },
        activateCollabDialog: {
            isOpen: false,
        },
        forceMfaResultDialog: false
    }
}

export const AccountsSlice = createSlice({
    name: 'Accounts',
    initialState,
    reducers: {
        openDeleteCollabDialog: (state) => {state.dialogs.deleteCollabDialog = {isOpen: true}},
        hideDeleteCollabDialog: (state) => {state.dialogs.deleteCollabDialog = {isOpen: false}},

        openBlockCollabDialog: (state) => {state.dialogs.blockCollabDialog = {isOpen: true}},
        hideBlockCollabDialog: (state) => {state.dialogs.blockCollabDialog = {isOpen: false}},

        openActivateCollabDialog: (state) => {state.dialogs.activateCollabDialog = {isOpen: true}},
        hideActivateCollabDialog: (state) => {state.dialogs.activateCollabDialog = {isOpen: false}},

        hideForceMfaDialog: (state) => {
            state.dialogs.forceMfaResultDialog = false;
            state.mfaForceResult = [];
        },

        cleanUp: () => {return initialState},
    },
    extraReducers: (builder) => {
        builder
            //GetShortCollabs
            .addCase(GetShortCollabs.pending, (slice) => {
                slice.loadings.isLoadingSmallCollabs = true;
            })
            .addCase(GetShortCollabs.rejected, (slice) => {
                slice.loadings.isLoadingSmallCollabs = false;
            })
            .addCase(GetShortCollabs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSmallCollabs = false;
                slice.smallCollabs = payload.collabs;
                slice.pageInfo = payload.pageInfo;
            })
        //GetExtendedCollab
            .addCase(GetExtendedCollab.pending, (slice) => {
                slice.loadings.isLoadingExtendedCollab = true;
            })
            .addCase(GetExtendedCollab.rejected, (slice) => {
                slice.loadings.isLoadingExtendedCollab = false;
            })
            .addCase(GetExtendedCollab.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExtendedCollab = false;
                slice.extendedCollab = payload.collab;
                slice.extendedCollabOrgs = payload.orgs;
            })
        //BlockUser
            .addCase(BlockUser.pending, (slice) => {
                slice.loadings.isLoadingBlockCollab = true;
            })
            .addCase(BlockUser.rejected, (slice) => {
                slice.loadings.isLoadingBlockCollab = false;
            })
            .addCase(BlockUser.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingBlockCollab = false;
                slice.dialogs.blockCollabDialog.isOpen = false;
                slice.smallCollabs = slice.smallCollabs.map(e => e.publicId === payload.id ? {...e, active: false} : e);
                if(slice.extendedCollab && slice.extendedCollab.publicId === payload.id){
                    slice.extendedCollab = {...slice.extendedCollab, active: false, blockedReason: payload.reason};
                }
            })
        //ActivateUser
            .addCase(ActivateUser.pending, (slice) => {
                slice.loadings.isLoadingActivateCollab = true;
            })
            .addCase(ActivateUser.rejected, (slice) => {
                slice.loadings.isLoadingActivateCollab = false;
            })
            .addCase(ActivateUser.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingActivateCollab = false;
                slice.dialogs.activateCollabDialog.isOpen = false;
                slice.smallCollabs = slice.smallCollabs.map(e => e.publicId === payload.id ? {...e, active: true} : e);
                if(slice.extendedCollab && slice.extendedCollab.publicId === payload.id){
                    slice.extendedCollab = {...slice.extendedCollab, active: true, blockedReason: null};
                }
            })
        //DeleteUser
            .addCase(DeleteUser.pending, (slice) => {
                slice.loadings.isLoadingDeleteCollab = true;
            })
            .addCase(DeleteUser.rejected, (slice) => {
                slice.loadings.isLoadingDeleteCollab = false;
            })
            .addCase(DeleteUser.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDeleteCollab = false;
                slice.dialogs.deleteCollabDialog.isOpen = false;
                slice.smallCollabs = slice.smallCollabs.filter(e => e.publicId !== payload.id);
                if(slice.extendedCollab && slice.extendedCollab.publicId === payload.id){
                    slice.extendedCollab = null;
                    slice.extendedCollabOrgs = [];
                }
            })
        //AdminGenerateRecoveryCodesByPublicId
            .addCase(AdminGenerateRecoveryCodesByPublicId.pending, (slice) => {
                slice.loadings.isLoadingForceMfa = true;
            })
            .addCase(AdminGenerateRecoveryCodesByPublicId.rejected, (slice) => {
                slice.loadings.isLoadingForceMfa = false;
            })
            .addCase(AdminGenerateRecoveryCodesByPublicId.fulfilled, (slice, {payload}) => {
                console.log(payload);
                slice.loadings.isLoadingForceMfa = false;
                slice.dialogs.forceMfaResultDialog = true;
                slice.mfaForceResult = payload;
            })

    }
});

export const AccountsReducer = AccountsSlice.reducer;

export const {
    openDeleteCollabDialog,
    hideDeleteCollabDialog,

    openBlockCollabDialog,
    hideBlockCollabDialog,

    openActivateCollabDialog,
    hideActivateCollabDialog,

    hideForceMfaDialog,

    cleanUp
} = AccountsSlice.actions;

const selectSelf = (state: AppState):accountsInitialState => state.Accounts as accountsInitialState;

export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const smallCollabs = createSelector(selectSelf, state => state.smallCollabs);
export const extendedCollab = createSelector(selectSelf, state => state.extendedCollab);
export const loading = createSelector(selectSelf, state => state.loadings);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const extendedCollabOrgs = createSelector(selectSelf, state => state.extendedCollabOrgs);
export const mfaForceResult = createSelector(selectSelf, state => state.mfaForceResult);



