import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {dialogs, hideManagePermissionsDialog} from "../../../store/slice";
import {ManagePermissionsDialogProps, TPermission} from "../../../types";
import {useForm} from "../../../../../../shared/hooks/useForm";

export const useManagePermissionsDialog = ({availablePermissions, selectedPermissions, selectPermission, deselectPermission, roleName, selectAllPermission, deselectAllPermissions}: ManagePermissionsDialogProps) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).managePermissions;

    const handleClose = () => {
        dispatch(hideManagePermissionsDialog());
    }

    const onCellClick = (permission: TPermission) => {
        // console.log(`${permission} - onCellClick`);
        const isAlreadySelected = selectedPermissions.some(e => e === permission.name);
        if(isAlreadySelected){
            deselectPermission(permission.name);
        }else{
            selectPermission(permission.name);
        }
    }

    useEffect(() => {
        setForm({search: ''});
        setFilteredPermission(availablePermissions);
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     //updating array if page was inited here and waited for fetch
    //     setForm({search: ''});
    //     setFilteredPermission(availablePermissions);
    //     //eslint-disable-next-line
    // }, [isLoading]);

    //search
    const [filteredPermission, setFilteredPermission] = useState<TPermission[]>([]);

    const {form, setForm, handleChange} = useForm<{search: string}>({search: ''});

    const handleCleanSearch = () => {
        setForm({search: ''});
        setFilteredPermission(availablePermissions);
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            setFilteredPermission(availablePermissions);
        }
        if(e.keyCode === 13 && form.search.trim().length > 0){
            setFilteredPermission(availablePermissions.filter(e =>
                e.name.trim().toLowerCase().startsWith(form.search.trim().toLowerCase()) ||
                e.tags.some(e => e.trim().toLowerCase().startsWith(form.search.trim().toLowerCase()))
            ));
        }
    }

    const counter = filteredPermission.filter(e => selectedPermissions.some(s => s === e.name)).length;

    // console.log(`counter: ${counter} | isSelectAllCheckboxSelected: counter > 0 -> ${counter > 0}`);
    return{
        availablePermissions: filteredPermission,
        selectedPermissions,
        selectPermission,
        deselectPermission,
        isOpen,
        handleClose,
        roleName,
        onCellClick,
        search: {
            handleChange,
            search: form.search,
            handleEnterKey,
            handleCleanSearch
        },
        selectAllPermission,
        deselectAllPermissions,
        isSelectAllCheckboxSelected: counter > 0,
        isShowNoResult: form.search.trim().length > 0 && !filteredPermission.length
    }
}