import {Button, Divider, Paper, Table, TableBody, TableContainer} from "@mui/material";
import { Flex } from "../../../../../shared/components/Layouts";
import {useRolesList} from "../../hooks/useRolesList";
import {RoleTableRow} from "../../components/roleTableRow";
import { Spinner } from "../../../../../shared/components/Spinner";
import {RoleTableHeader} from "../../components/roleTableHeader";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {PreviewRoleDialog} from "../../components/dialogs/previewRoleDialog";
import {DeleteRoleDialog} from "../../components/dialogs/deleteRoleDialog";
import {CreateRoleDialog} from "../../components/dialogs/createRole";
import {EditRoleDialog} from "../../components/dialogs/editRole";
import {PermissionsTableHeader} from "../../components/permissionsTableHeader";
import {FixedSizeList as List} from "react-window";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import React from "react";
import {PermissionsTabRowRenderer} from "../../components/PermissionsTabRowRenderer";
import colors from "../../../../../shared/theme/colors";
import {CustomSearchField} from "../../../../../shared/components/Inputs";
import {CreatePermission} from "../../components/dialogs/createPermission";
import {EditPermissionDialog} from "../../components/dialogs/editPermission";
import {DeletePermissionDialog} from "../../components/dialogs/deletePermissionDialog";

export const RolesList = () => {
    const {
        roles,
        isLoading,
        tab,
        setTab,
        handleOpenCreateRole,
        handleOpenCreatePermission,
        _permissions,

        search
    } = useRolesList();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'} p={'20px'}>
            {isLoading && <Spinner />}

            <Flex w={'100%'} ai={'center'}>
                <ToggleButtonGroup
                    color="primary"
                    value={tab}
                    exclusive
                    onChange={(e, value) => {value && setTab(value)}}
                    size={'small'}
                    sx={{margin: '15px 0 15px 0'}}
                >
                    <ToggleButton value="roles">Roles</ToggleButton>
                    <ToggleButton value="permissions">Permissions</ToggleButton>
                </ToggleButtonGroup>

                {tab === 'roles' ?
                    <Button variant={'contained'} size={'small'} onClick={handleOpenCreateRole} sx={{margin: '0 0 0 16px'}} color={'secondary'}>{('Create role')}</Button>
                    :
                    <Button variant={'contained'} size={'small'} onClick={handleOpenCreatePermission} sx={{margin: '0 0 0 16px'}} color={'secondary'}>{('Create permission')}</Button>
                }
            </Flex>

            {tab === 'roles' &&
                    <Paper sx={{ width: '100%', overflow: 'auto' }}>
                        <TableContainer>
                            <Table stickyHeader>
                                <RoleTableHeader />
                                <TableBody>
                                    {roles.map((role) => <RoleTableRow key={role.id} role={role}/>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
            }

            {tab === 'permissions' &&
                <Flex w={'100%'} h={'90%'} direction={'column'}>

                    <CustomSearchField
                        variant={'outlined'}
                        onChange={search.handleChange}
                        name={'search'}
                        value={search.search}
                        onClear={search.handleCleanSearch}
                        onKeyDown={search.handleEnterKey}
                        label={('Search')}
                        dir={'ltr'}
                        placeholder={('Search by id, name or tag')}
                        size="small"
                        fullWidth
                        sx={{margin: '0 0 8px 0', width: '25%'}}
                    />

                    <PermissionsTableHeader />
                    {<Divider flexItem sx={{bgcolor: colors.skip, borderColor: colors.skip}}/>}
                    <AutoSizer>
                        {({height, width}: Size) => (
                            <List
                                height={(height ?? 0) - 100}
                                itemCount={_permissions.length}
                                itemSize={58}
                                width={width ?? 0}
                                style={{borderRadius: '5px'}}
                            >
                                {(props) => PermissionsTabRowRenderer({...props, _permissions})}
                            </List>
                        )}
                    </AutoSizer>
                </Flex>
            }
            {/*DIALOGS*/}
            <PreviewRoleDialog />
            <DeleteRoleDialog />
            <CreateRoleDialog />
            <EditRoleDialog />
            <CreatePermission />
            <EditPermissionDialog />
            <DeletePermissionDialog />
        </Flex>
    )
}
