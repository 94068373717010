import React, {FC, useState} from 'react';
import { Flex } from '../../../shared/components/Layouts';
import {SignPage} from "../../../shared/components/SignPage";
import {useMainTranslation} from "../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {Typo} from "../../../shared/components/Typography";
import {CustomInput} from "../../../shared/components/Inputs";
import {CustomButton} from "../../../shared/components/Buttons";
import {loginByRecoveryCode, resendCode, validateCode} from "./api";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addSuccessfulSnack} from "../../BarsENV/snack/store/slice"; 
import {LocalSpinner} from "../../../shared/components/Spinner";
import {SHOW_SKIP_BUTTON_IN_MFA} from "../../../shared/constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {UseManageWorkspacesAndOrganizations} from "../newWorkspaces/hooks/useManageWorkspacesAndOrganizations";
import {PATH_ACCOUNTS} from "../../BarsENV/shell/constants";


export const SignIn2FA:FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {setUser, isGoogle2FaComplete} = UseManageWorkspacesAndOrganizations();

    const {revDir} = useMainTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const [isCodeExpiry, setIsCodeExpiry] = useState<boolean>(false);

    const handleContinue = async(code: string) => {
        //here we finally receive workspaces and orgs
        // then sending user to frameworks page if there is one ws only or to worksapces page
        setIsLoading(true);
        try{
            const resp = await validateCode(code);
            if(resp){
                setUser(resp.currentUser);
                history.push(PATH_ACCOUNTS);
            }
        }catch (e: any){
            if(e.message === 'Token error: The time to use this code has expired! Please use resend code!'){
                setIsCodeExpiry(true);
            }
        }finally {
            setIsLoading(false);
        }
    }

    const handleLoginByRecoveryCode = async (recoveryCode: string) => {
        //same logic as in 2fa login
        try{
            setIsLoading(true);
            const resp = await loginByRecoveryCode(recoveryCode);
            if(resp){
                setUser(resp.currentUser);
                history.push(PATH_ACCOUNTS);
            }
        }finally {
            setIsLoading(false);
        }

    }

    const handleReSend = async() => {
        setIsLoading(true);
        const message = await resendCode();
        dispatch(addSuccessfulSnack(message));
        setIsCodeExpiry(false);
        setCode('');
        setIsLoading(false);
    }

    const [recoveryCode, setRecoveryCode] = useState<string>('');
    const [isRecoveryCodeView, setIsRecoveryCodeView] = useState<boolean>(false);


    return(
        <SignPage revDir={revDir}>
            <Flex direction={'column'} minw={'400px'} w={'min(600px, 80%)'} m={'auto'} overflowx={'clip'} overflowy={'overlay'}>
                {isRecoveryCodeView ?
                    <>
                        <Typo fontSize={'24px'} fontWeight={500}>{('Use recovery code')}</Typo>
                        <Typo fontSize={'16px'} fontWeight={300} color={'#535353'} margin={'15px 0'}>{('Use recovery code to access your account without paired device.')}</Typo>
                        <CustomInput
                            autoFocus
                            label={('Recovery code')}
                            sx={{direction: 'ltr'}}
                            onChange={(e) => setRecoveryCode(e.target.value as string)}
                            id="twoFactorCode"
                            name='code'
                        />

                        <CustomButton
                            type={'submit'}
                            variant={'contained'}
                            onClick={() => handleLoginByRecoveryCode(recoveryCode)}
                            disabled={!recoveryCode.trim().length}
                            fullWidth
                            sx={{
                                marginTop: '15px'
                            }}
                        >
                            {('Continue')}
                        </CustomButton>
                    </>
                    :
                    <>
                        <Typo fontSize={'24px'} fontWeight={500}>{('Two-Factor Authentication')}</Typo>
                        <Typo fontSize={'16px'} fontWeight={300} color={'#535353'} margin={'15px 0'}>{('Your account is protected with two-factor authentication. We\'ve sent you text message. Please enter code below.')}</Typo>
                        <CustomInput
                            autoFocus
                            label={('Two-Factor Code')}
                            sx={{direction: 'ltr'}}
                            onChange={(e) => setCode(e.target.value as string)}
                            id="twoFactorCode"
                            name='code'
                        />

                        {isCodeExpiry && <Typo cursor={'pointer'} fontSize={'16px'} fontWeight={300} margin={'15px 0 0 0'} color={'red'} onClick={handleReSend}>{('Code expired! Try resend message!')}</Typo>}

                        <CustomButton
                            type={'submit'}
                            variant={'contained'}
                            onClick={() => handleContinue(code)}
                            disabled={!code.trim().length || isCodeExpiry}
                            fullWidth
                            sx={{
                                marginTop: '15px'
                            }}
                        >
                            {('Continue')}
                        </CustomButton>

                        {SHOW_SKIP_BUTTON_IN_MFA &&
                            <CustomButton
                                type={'submit'}
                                variant={'contained'}
                                onClick={() => handleContinue('')}
                                disabled={isCodeExpiry}
                                fullWidth
                                sx={{
                                    marginTop: '15px'
                                }}
                            >
                                {('SKIP')}
                            </CustomButton>
                        }

                        <Typo cursor={'pointer'} fontSize={'16px'} fontWeight={300} margin={'15px 0 0 0'} color={'#FAC000'} onClick={handleReSend}>{('Resend code via text message')}  </Typo>
                        {isGoogle2FaComplete && <Typo cursor={'pointer'} fontSize={'16px'} fontWeight={300} margin={'15px 0 0 0'} color={'#FAC000'} onClick={() => setIsRecoveryCodeView(true)}>{('Use recovery code')}  </Typo>}
                    </>
                }
                {isLoading && <LocalSpinner />}

                <Flex w="100%" ai="center" jc="center" onClick={() => !isRecoveryCodeView ? history.push('/login') : setIsRecoveryCodeView(false)} m="60px 0 35px 0">
                    {<KeyboardBackspaceIcon style={{ color: "#A0A2B1", cursor: "pointer" }} />}
                    {!isRecoveryCodeView ?
                        <Typo fontSize="16px" fontWeight={300} letterSpacing="1.23px" cursor="pointer" color="#A0A2B1"
                              margin="0 0 0 5px">{('Back to Login')}</Typo>
                        :
                        <Typo fontSize="16px" fontWeight={300} letterSpacing="1.23px" cursor="pointer" color="#A0A2B1"
                              margin="0 0 0 5px">{('Back to 2FA')}</Typo>
                    }
                </Flex>
            </Flex>
        </SignPage>
    )
}
