import {FC} from "react";
import {Skeleton, TableCell, TableRow} from "@mui/material";

type TProps = {
    reff?: (node?: Element | null) => void,

    columns?: number
}
export const TableRowSkeleton: FC<TProps> = ({reff, columns = 3}) => {

    return (
        <TableRow ref={reff}>
            {Array(columns).fill(1).map((_, index) => (
                <TableCell key={columns + ' ' + index}><Skeleton/></TableCell>
            ))}
        </TableRow>
    )
}