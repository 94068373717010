import {createSelector, createSlice} from "@reduxjs/toolkit";

//types
import {AppState} from "../../../../shared/redux/rootReducer";
import {TCategoryWithTasks, TTaskOrder} from "../types";
import {
    vicaAdminAddFromFilesAction, vicaAdminChangeCategoriesOrderAction,
    vicaAdminCreateCategoryAction,
    vicaAdminCreateTaskAction,
    vicaAdminDeleteCategoryAction,
    vicaAdminDeleteTaskAction,
    vicaAdminGetCategoriesAction,
    vicaAdminGetTaskByIdAction,
    vicaAdminGetTasksByCategoryIdAction,
    vicaAdminUpdateCategoryAction,
    vicaAdminUpdateTaskAction
} from "./actions";

//actions

export type TVicaTasksSlice = {
    categories: {
        categories: TCategoryWithTasks[],
        order: TTaskOrder[],
    },
    selectedCategoryId: string | null,
    selectedTaskId: string | null,

    isLoading: {
        isLoadingCategories: boolean,
        isLoadingTasks: string[], //id of loading category
        isLoadingTask: boolean,
        isEditingTask: boolean,
        isDeletingTask: boolean,
        isEditingCategory: boolean,
        isEditingCategoriesOrder: boolean,
        isDeletingCategory: boolean,
        isUploadingFiles: boolean,
    },

    dialogs: {
        addFiles: {
            isOpen: boolean,
        },
    },
}

const initialState: TVicaTasksSlice = {
    categories: {
        categories: [],
        order: [],
    },
    selectedCategoryId: null,
    selectedTaskId: null,

    isLoading: {
        isLoadingCategories: false,
        isLoadingTasks: [], //id of loading category
        isLoadingTask: false,
        isEditingTask: false,
        isDeletingTask: false,
        isEditingCategory: false,
        isEditingCategoriesOrder: false,
        isDeletingCategory: false,
        isUploadingFiles: false,
    },

    dialogs: {
        addFiles: {
            isOpen: false,
        },
    },
}


const VicaTasks = createSlice({
    name: "VicaTasks",
    initialState,
    reducers: {
        setSelectedTaskAction: (slice: TVicaTasksSlice, {payload}: {payload: {categoryId: TVicaTasksSlice["selectedCategoryId"], taskId?: TVicaTasksSlice["selectedTaskId"]}}) => {
            slice.selectedCategoryId = payload.categoryId;
            slice.selectedTaskId = payload.taskId || null;
        },

        setDialogAddFilesAction: (slice: TVicaTasksSlice, {payload}: {payload: {isOpen: boolean}}) => {
            slice.dialogs.addFiles.isOpen = payload.isOpen;
        },

        cleanUpAction(){
            return initialState;
        },
        // cleanAction: (slice: TVicaTasksSlice, {payload}: {payload: ('page' | 'fields' | 'isLoading')[]}) => {
        //     if (payload.includes("page")) slice.page = initialState.page;
        //     if (payload.includes("fields")) slice.fields = initialState.fields;
        //     if (payload.includes("isLoading")) slice.isLoading = initialState.isLoading;
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(vicaAdminGetCategoriesAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isLoadingCategories = true;
            })
            .addCase(vicaAdminGetCategoriesAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isLoadingCategories = false;
            })
            .addCase(vicaAdminGetCategoriesAction.fulfilled, (slice: TVicaTasksSlice, {payload}) => {
                slice.isLoading.isLoadingCategories = false;
                slice.categories.categories = payload.categories.map((category) => ({...category, tasks: []}));
                slice.categories.order = payload.flow;
            })

            .addCase(vicaAdminGetTasksByCategoryIdAction.pending, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isLoadingTasks.push(categoryId);
            })
            .addCase(vicaAdminGetTasksByCategoryIdAction.rejected, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isLoadingTasks = slice.isLoading.isLoadingTasks.filter((id) => id !== categoryId);
            })
            .addCase(vicaAdminGetTasksByCategoryIdAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}, payload}) => {
                slice.isLoading.isLoadingTasks = slice.isLoading.isLoadingTasks.filter((id) => id !== categoryId);
                slice.categories.categories = slice.categories.categories.map(category => category.id === categoryId ? {...category, tasks: payload} : category);
            })

            .addCase(vicaAdminGetTaskByIdAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isLoadingTask = true;
            })
            .addCase(vicaAdminGetTaskByIdAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isLoadingTask = false;
            })
            .addCase(vicaAdminGetTaskByIdAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId, taskId}}, payload}) => {
                slice.isLoading.isLoadingTask = false;

                const categories = slice.categories.categories;
                const index = categories.findIndex(category => category.id === categoryId);
                if (index > -1) {
                    const category = categories[index];
                    const taskIndex = category.tasks.findIndex(task => task.id === taskId);
                    if (taskIndex > -1) {
                        category.tasks[taskIndex] = payload;
                        categories[index] = category;
                        slice.categories.categories = categories;
                    }
                }
            })

            .addCase(vicaAdminCreateTaskAction.pending, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isEditingTask = true;
            })
            .addCase(vicaAdminCreateTaskAction.rejected, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isEditingTask = false;
            })
            .addCase(vicaAdminCreateTaskAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}, payload: task}) => {
                slice.isLoading.isEditingTask = false;

                const categories = slice.categories.categories;
                const index = categories.findIndex(category => category.id === categoryId);
                if (index > -1) {
                    const category = categories[index];
                    task.order !== null && category.tasks.push({...task, order: task.order - 0.5});
                    category.tasks = category.tasks.sort((a, b) => a.order! - b.order!).map((task, index) => ({...task, order: index}));

                    categories[index] = category;
                    slice.categories.categories = categories;
                }

                slice.selectedTaskId = initialState.selectedTaskId;
            })

            .addCase(vicaAdminUpdateTaskAction.pending, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isEditingTask = true;
            })
            .addCase(vicaAdminUpdateTaskAction.rejected, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isEditingTask = false;
            })
            .addCase(vicaAdminUpdateTaskAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}, payload: {task, moveToCategoryId}}) => {
                slice.isLoading.isEditingTask = false;

                if(moveToCategoryId){
                    slice.categories.categories = slice.categories.categories.map(e => {
                        if(e.id === categoryId){
                            return {
                                ...e,
                                tasks: e.tasks.filter(e => e.id !== task.id).sort((a, b) => a.order! - b.order!).map((task, index) => ({...task, order: index}))
                            }
                        }
                        if(e.id === moveToCategoryId){
                            if(e.tasks.length > 0){
                                return {
                                    ...e,
                                    tasks: [...e.tasks, {...task, order: -0.5}]
                                        .sort((a, b) => a.order! - b.order!)
                                        .map((task, index) => ({...task, order: index}))
                                }
                            }
                        }

                        return e;
                    });
                    slice.selectedTaskId = initialState.selectedTaskId;
                    return;
                }

                const categories = slice.categories.categories;
                const index = categories.findIndex(category => category.id === categoryId);
                if (index > -1) {
                    const category = categories[index];
                    const taskIndex = category.tasks.findIndex(t => t.id === task.id);
                    if (taskIndex > -1) {
                        category.tasks[taskIndex] = {...task, order: task.order! + (task.order! > (category.tasks[taskIndex].order ?? -2) ? 0.5 : -0.5)};
                        category.tasks = category.tasks.sort((a, b) => a.order! - b.order!).map((task, index) => ({...task, order: index}));

                        categories[index] = category;
                        slice.categories.categories = categories;
                    }
                }

                slice.selectedTaskId = initialState.selectedTaskId;
            })

            .addCase(vicaAdminDeleteTaskAction.pending, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isDeletingTask = true;
            })
            .addCase(vicaAdminDeleteTaskAction.rejected, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}}) => {
                slice.isLoading.isDeletingTask = false;
            })
            .addCase(vicaAdminDeleteTaskAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId, taskId}}, payload}) => {
                slice.isLoading.isDeletingTask = false;

                slice.categories.categories = slice.categories.categories.map(category =>
                    category.id === categoryId
                        ? {...category, tasks: category.tasks.filter(task => task.id !== taskId)}
                        : category
                );

                slice.selectedTaskId = initialState.selectedTaskId;
            })

            .addCase(vicaAdminCreateCategoryAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategory = true;
            })
            .addCase(vicaAdminCreateCategoryAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategory = false;
            })
            .addCase(vicaAdminCreateCategoryAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {orders}}, payload}) => {
                slice.isLoading.isEditingCategory = false;

                slice.categories.categories = [...slice.categories.categories, {...payload, tasks: []}];
                slice.categories.order = orders.map(order => order.id === 'new' ? {...order, id: payload.id} : order);
                slice.selectedCategoryId = payload.id;
            })

            .addCase(vicaAdminUpdateCategoryAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategory = true;
            })
            .addCase(vicaAdminUpdateCategoryAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategory = false;
            })
            .addCase(vicaAdminUpdateCategoryAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {orders, tasks}}, payload}) => {
                slice.isLoading.isEditingCategory = false;

                const index = slice.categories.categories.findIndex(category => category.id === payload.id);
                if (index > -1) {
                    slice.categories.categories[index] = {...slice.categories.categories[index], ...payload };
                    if (tasks) slice.categories.categories[index] = {...slice.categories.categories[index], ...payload, tasks };
                    slice.categories.order = orders;
                }
            })

            .addCase(vicaAdminChangeCategoriesOrderAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategoriesOrder = true;
            })
            .addCase(vicaAdminChangeCategoriesOrderAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isEditingCategoriesOrder = false;
            })
            .addCase(vicaAdminChangeCategoriesOrderAction.fulfilled, (slice: TVicaTasksSlice, { payload}) => {
                slice.isLoading.isEditingCategoriesOrder = false;

                slice.categories.order = payload;
            })

            .addCase(vicaAdminDeleteCategoryAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isDeletingCategory = true;
            })
            .addCase(vicaAdminDeleteCategoryAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isDeletingCategory = false;
            })
            .addCase(vicaAdminDeleteCategoryAction.fulfilled, (slice: TVicaTasksSlice, {meta: {arg: {categoryId}}, payload}) => {
                slice.isLoading.isDeletingCategory = false;

                slice.categories.categories = slice.categories.categories.filter(category => category.id !== categoryId);
                slice.categories.order = slice.categories.order.filter(o => o.id !== categoryId).map((o, index) => ({...o, order: index}));
                slice.selectedTaskId = initialState.selectedTaskId;
                slice.selectedCategoryId = initialState.selectedCategoryId;
            })

            .addCase(vicaAdminAddFromFilesAction.pending, (slice: TVicaTasksSlice) => {
                slice.isLoading.isUploadingFiles = true;
            })
            .addCase(vicaAdminAddFromFilesAction.rejected, (slice: TVicaTasksSlice) => {
                slice.isLoading.isUploadingFiles = false;
            })
            .addCase(vicaAdminAddFromFilesAction.fulfilled, (slice: TVicaTasksSlice, {payload}) => {
                slice.isLoading.isUploadingFiles = false;
                slice.categories.categories = payload.categories.map((category) => ({...category, tasks: []}));
                slice.categories.order = payload.flow;

                slice.dialogs.addFiles = initialState.dialogs.addFiles;
            })
    }
});

export const VicaTasksReducer = VicaTasks.reducer;

//actions
export const {
    setSelectedTaskAction,

    setDialogAddFilesAction,

    cleanUpAction,
} = VicaTasks.actions;

//selectors
const selectSelf = (state: AppState): TVicaTasksSlice => state.vicaTasks;

export const vicaTasksCategoriesSelector = createSelector(selectSelf, state => state.categories);
export const vicaTasksSelectedCategorySelector = createSelector(selectSelf, state => state.selectedCategoryId);
export const vicaTasksSelectedTaskSelector = createSelector(selectSelf, state => state.selectedTaskId);
export const vicaTasksIsLoadingSelector = createSelector(selectSelf, state => state.isLoading);
export const vicaTasksDialogsSelector = createSelector(selectSelf, state => state.dialogs);



