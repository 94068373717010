import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {DeleteAllFile, GetBuilderData, GetControls} from "../../store/actions";
import {cleanUp, deletableFiles, setParams} from "../../store/slice";
import {useRouteMatch} from "react-router";
import {
    BUILDER_WITH_FRAME,
    BUILDER_WITH_FRAME_AND_CONTROL,
    CONTROLS,
    EVIDENCES,
    POLICIES,
    ROOT_BUILDER,
    ROOT_POLICIES
} from "../../constants";
import {useHistory} from "react-router-dom";

//the main hooks located deep to load data for every path
//on every another path will be another hook that use already loaded data
export const useMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _deletableFiles = useSelector(deletableFiles);

    useEffect(() => {
        //if user added sone template files in add/edit policy and gone to another route -> need to delete those files
       if(history.location.pathname !== ROOT_POLICIES && _deletableFiles.length > 0){
           dispatch(DeleteAllFile({fileIds: _deletableFiles, autoId: ''}));
       }
        //eslint-disable-next-line
    }, [history.location]);

    const builderFrameworkId = useRouteMatch<{frameworkId: string}>(BUILDER_WITH_FRAME)?.params;
    const builderFrameworkIdAndControlId = useRouteMatch<{frameworkId: string, controlId: string}>(BUILDER_WITH_FRAME_AND_CONTROL)?.params;
    const controlsWithId = useRouteMatch<{controlId: string}>(CONTROLS)?.params;
    const evidencesWithId = useRouteMatch<{evidenceId: string}>(EVIDENCES)?.params;
    const policiesWithId = useRouteMatch<{policyId: string}>(POLICIES)?.params;

    const onFrameworkDoesNotExist = () => history.push(ROOT_BUILDER);

    useEffect(() => {
        dispatch(GetBuilderData());
        dispatch(setParams({
            frameworkId: builderFrameworkId?.frameworkId || builderFrameworkIdAndControlId?.frameworkId,
            controlId: builderFrameworkIdAndControlId?.controlId || controlsWithId?.controlId,
            evidenceId: evidencesWithId?.evidenceId,
            policyId: policiesWithId?.policyId,
        }));

        //FETCHING CONTROLS AND EVIDENCES BY FRAMEWORK ID FROM SEARCH
        if(builderFrameworkId?.frameworkId){
            dispatch(GetControls({frameworkId: builderFrameworkId?.frameworkId, onReject: onFrameworkDoesNotExist}))
        }else if(builderFrameworkIdAndControlId?.frameworkId){
            dispatch(GetControls({frameworkId: builderFrameworkIdAndControlId?.frameworkId, onReject: onFrameworkDoesNotExist}))
        }

        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}