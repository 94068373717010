//Core
import React, {Suspense, useMemo} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {ApolloProvider} from '@apollo/react-hooks';

//Other
import {store} from '../../shared/redux';
import {client} from "../../shared/graphQl"


//Components
import {Shell} from "../../domain/BarsENV/shell";


//Styled Theme
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import theme from '../../shared/theme/theme';
import {useMediaQuery} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Spinner} from "../../shared/components/Spinner";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {initLabels} from "../../shared/env";

function useMediaTheme() {
    const isItUntilHeight1024 = useMediaQuery('(max-height:1024px)');
    const isItUntilWidth1312 = useMediaQuery('(max-width:1312px)');
    const isItUntilHeight900 = useMediaQuery('(max-height:900px)');
    const isItUntilHeight700 = useMediaQuery('(max-height:700px)');
    const isItNarrow = useMediaQuery('(max-width:1200px)');
    return useMemo(() => ({
        ...theme,
        // ...(isItUntilHeight1024 ? { height: 'calc(100vh - 60px)' } : { height: BODY_HEIGHT }),
        ...({ height: 'calc(100vh - 60px)' }),
        ...(isItUntilWidth1312 ? { width: 'calc(100vw)' } : { width:`calc(100vw)` }),
        ...({ short: isItUntilHeight900 }),
        ...({ bit: isItUntilHeight700 }),
        ...({ narrow: isItNarrow }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [isItUntilHeight1024, isItUntilWidth1312, isItUntilHeight900, isItUntilHeight700, isItNarrow]);
}

export default function App() {
    initLabels();

    const styledTheme = useMediaTheme();

    return (
        <Suspense fallback={<Spinner />}>
            <DndProvider backend={HTML5Backend}>
                <ApolloProvider client={client}>
                    <Provider store={store}>
                        <StyledThemeProvider theme={styledTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Router>
                                    <Shell/>
                                </Router>
                            </MuiPickersUtilsProvider>
                        </StyledThemeProvider>
                    </Provider>
                </ApolloProvider>
            </DndProvider>
        </Suspense>
    );
}
