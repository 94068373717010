import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideChangeOrgSubDialog} from "../../../store/slice";
import {ORGANIZATION_SUBSCRIPTIONS} from "../../../../../AuthWorkspaceAndCookies/newWorkspaces/types";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Autocomplete} from "@mui/lab";
import {UpdateOrgSubscription} from "../../../store/actions";
import {handleGetSubNormalName} from "../../../../accounts/helpers";

export const UpdateOrganizationSubscriptionDialog = () => {
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeSub} = useSelector(loading);
    const {changeOrganizationSubDialog : {isOpen}} = useSelector(dialogs);
    const [sub, setSub] = useState<ORGANIZATION_SUBSCRIPTIONS>('SUBSCRIPTION_FREE');
    const values: ORGANIZATION_SUBSCRIPTIONS[] = ['SUBSCRIPTION_BUSINESS', 'SUBSCRIPTION_BUSINESS_PLUS', 'SUBSCRIPTION_ENTERPRISE', 'SUBSCRIPTION_CONSULTANT', 'SUBSCRIPTION_AUDITOR', 'SUBSCRIPTION_FREE']

    const handleClose = () => {
        dispatch(hideChangeOrgSubDialog());
    }

    const handleSubmit = () => {
        if(org && sub !== org.subscription){
            dispatch(UpdateOrgSubscription({id: org.id, subscription: sub}));
        }
    }

    useEffect(() => {
        if(isOpen && org){
            setSub(org.subscription as ORGANIZATION_SUBSCRIPTIONS);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'xs'} fullWidth>
            <DialogTitle>Update Subscription</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{marginTop: '10px'}}
                    autoComplete
                    fullWidth
                    openOnFocus
                    id="combo-box-demo"
                    options={values}
                    getOptionLabel={handleGetSubNormalName}
                    onChange={(e, value) => {value && setSub(value)}}
                    value={sub}
                    renderInput={(params) => <TextField {...params} label="Subscription" />}
                />
            </DialogContent>
            {isLoadingChangeSub ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org || sub === org.subscription} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}