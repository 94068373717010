import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideChangeAvailableCollabsCounterDialog, loading, extendedOrganization} from "../../../store/slice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import {UpdateAvailableCollabsCounter} from "../../../store/actions";

export const UpdateCollabsCounter = () => {
    const dispatch = useDispatch();
    const {changeAvailableCollabsDialog: {isOpen}} = useSelector(dialogs);
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeCollabsCounter} = useSelector(loading);
    const [counter, setCounter] = useState<number>(0);

    const handleClose = () => {
        dispatch(hideChangeAvailableCollabsCounterDialog());
        setCounter(0);
    };

    const handleUpdate = () => {
        if(org){
            dispatch(UpdateAvailableCollabsCounter({id: org.id, newValue: counter}));
            setCounter(0);
        }
    }

    useEffect(() => {
        if(isOpen && org){
            setCounter(org.configuration.availableCollaboratorsCount);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle>Available Collabs counter</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Set collabs count below
                </DialogContentText>
                <TextField
                    type={'number'}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Collabs counter"
                    fullWidth
                    variant="standard"
                    value={counter}
                    onChange={(e) => {setCounter(parseInt(e.target.value))}}
                />
            </DialogContent>
            {isLoadingChangeCollabsCounter ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleUpdate} disabled={!org || (org && org.configuration.availableCollaboratorsCount === counter)} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}