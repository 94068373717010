// noinspection DuplicatedCode

import React, {FC} from 'react';
import {FlexAround} from '../Layouts';
import {Typo} from '../Typography';

type TAvatarProps = { d?: string, cc?: string, fc?: string, text?: string, fs?: string };


const Initials: FC<TAvatarProps> = (props: TAvatarProps) => {
    const {
        d = '40px',
        cc = '#cbcbcb',
        fc = '#FFFFFF',
        text,
        fs = '22px'
    } = props;
    const result = (text ?? 'N N').split(' ')
        .filter(x => x !== '')
        .filter((_ , index)  => index < 2 )
        .filter((x) => x !== '')
        .map(x => x.charAt(0).toUpperCase()).join('');
    return (
        <FlexAround br={'50%'} h={d} bc={cc} w={d} d={'column'}>
            <Typo color={fc} fontSize={fs}>{result}</Typo>
        </FlexAround>)
}

type TStyle = {
    [key in 'orange' | 'purple' | 'small' | 'bit' | 'micro' | 'root' | 'blue' | 'avatar' | 'big']: {
        color: string;
        backgroundColor: string;
        width?: string,
        height?: string,
    }
}

const style: TStyle = {
    big: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
    },
    root: {
        color: '#ffffff',
        backgroundColor: '#A0A2B1',
    },
    blue: {
        color: '#ffffff',
        backgroundColor: '#2681FF',
    },
    orange: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
    },
    purple: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
    },
    small: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
        width: '30px',
        height: '30px',
    },
    bit: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
        width: '25px',
        height: '25px',
    },
    micro: {
        color: '#ffffff',
        backgroundColor: '#f18a17',
        width: '16px',
        height: '16px',
    },
    avatar: {
        color: 'white',
        backgroundColor: '#0195c7',
    },
};



type PropTypes = {
    initials: string;
    color?: keyof TStyle | undefined;
    avatar?: string;
    w?: string;
    fs?: string;
    fsa?: string;
    cursor?: string;
    onClick?: () => void;
}
const getHeight = (type: string): string => {
    switch (type) {
        case 'big': {
            return "50px"
        }
        case 'root': {
            return "40px"
        }
        case 'orange': {
            return "40px"
        }
        case 'purple' : {
            return '40px'
        }
        case "small": {
            return '35px'
        }
        case "blue": {
            return '35px'
        }
        case 'bit': {
            return '30px';
        }
        case 'micro': {
            return '20px';
        }
        case 'avatar': {
            return '2.2em';
        }
        default:
            return '40px'
    }

}
export const Avatar = ({initials, color = 'purple', avatar = "", w = "auto", fs = '1.25em', cursor, onClick}: PropTypes) => {
    return (
        <div style={{borderRadius: 50, width: w, ...(cursor && {cursor})}} {...(onClick && {onClick})}>
            {avatar && (
                <img src={avatar} height={getHeight(color)} style={{borderRadius: "50%", justifyItems: "flex-end"}}
                     alt={"Avatar"}/>)}
            {!avatar && (<Initials fs={fs} cc={style[color || 'root'].backgroundColor} d={getHeight(color)} fc={style[color || 'root'].color} text={initials}/>)}
        </div>
    );
}
