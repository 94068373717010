import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {AppInfo} from "../types";


export const getAppsInfos = async (): Promise<AppInfo[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query{
                  getManagedAppsByAdmin{
                    apps{
                      id
                      name
                      type
                      logo
                      certifications
                      applicationGroup
                      description
                      securityScope
                      documentationLink
                      endpointMappingName
                      status
                      releaseDate
                      config{
                        collectionPrefix
                        collectionConfigs{
                            name
                            alias
                            headers
                            data
                            sortingField
                            excludeFields
                            mappingFieldsSchema{
                                sourceName
                                representationName
                                name
                            }
                        }
                        accountMappingConfig{
                            accountCollection
                            accountCollectionField
                            employeeCollection
                            employeeCollectionField
                        }
                      }
                    }
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getManagedAppsByAdmin.apps ?? [];
    })
)

export const deleteManagedApp = async (id: string) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation($data:String!){
                  deleteApplication(applicationId:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getManagedApps;
    })
)

export const addApp = async (app: AppInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation($data:ManagedApplicationModelInput!){
                    addApplication(application:$data){
                        message
                    }
                }
                `,
                variables: {
                    data: app
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addApplication;
    })
)

export const updateApp = async (app: AppInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation($data:ManagedApplicationModelInput!){
                  updateApplication(application:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: app
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateApplication;
    })
)