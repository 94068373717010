import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {usePreviewRoleDialog} from "../../../hooks/dialogs/usePreviewRoleDialog";

export const PreviewRoleDialog = () => {
    const {
        role,
        isOpen,
        handleClose
    } = usePreviewRoleDialog();

    if(!role){
        return(<></>);
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`Role ${role.name} permissions`}</DialogTitle>

            <DialogContent>
                {role.permissions.slice().sort().map(e => <Typography key={e} fontSize={'12px'} fontWeight={400}>{e}</Typography>)}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}