import {BuilderEvidencesTable} from "../../components/builderEvidencesTable";
import {Flex} from "../../../../../shared/components/Layouts";
import {Spinner} from "../../../../../shared/components/Spinner";
import {useEvidences} from "../../hooks/useEvidences";
import {AddEvidenceComponent} from "../../components/dialogs/evidences/addEvidenceComponent";
import {UpdateEvidenceComponent} from "../../components/dialogs/evidences/updateEvidenceComponent";
import {DeleteEvidenceDialog} from "../../components/dialogs/evidences/deleteEvidence";

export const Evidences = () => {
    const {isLoading, addDialog, editDialog, isLoadingDialogs} = useEvidences();

    return(
        <Flex w={'100%'} h={'100%'} jc={'space-between'} m={'10px 0 0 0'}>
            <BuilderEvidencesTable/>
            <AddEvidenceComponent isOpen={addDialog && !editDialog.isOpen}/>
            <UpdateEvidenceComponent isOpen={editDialog.isOpen} evidenceId={editDialog.evidenceId}/>
            {/*DIALOGS*/}
            {(isLoading || isLoadingDialogs) && <Spinner />}
            <DeleteEvidenceDialog />
        </Flex>
    )
}