import cookies, {CookieAttributes} from "js-cookie";
import {LABEL, MAX_LIVE_COOKIE, PATH_ARI} from "../../constants";


export const useCookies = () => {
    const getCookieByName = (name: string): string | null => {
        const res = cookies.get(name);
        if(!res) return null;
        try{
            return res;
        }catch (ex: any){
            // ex && console.log(`useCookies: getCookieByName error ${JSON.stringify(ex.message)}`);
        }
        return null;
    }

    const setCookieByName = (name: string, value: string): void => {
        cookies.set(name, value, getOptions());
        // console.log(`COOKIE SET WITH NAME ${name} : ${res}`);
    }

    const deleteCookieByName = (name: string): void => {
        //TODO ADD all options too
        cookies.remove(name);
    }

    const updateCookieByName = (name: string, value: string): void => {
        // console.log(`COOKIE UPDATED WITH NAME: ${name} AND value: ${value}`);
        cookies.remove(name);
        cookies.set(name, value, getOptions());
    }

    const getDomain = () => {
        switch (LABEL){
            case "Regulait": return 'regulait.com';
            case "Complico": return 'complico.me';
            default: return 'complico.me';
        }
    }

    const getOptions = (): CookieAttributes => {
        if(PATH_ARI === 'http://localhost:3000'){
            return {};
        }

         let options: CookieAttributes = {
            expires: new Date(Date.now() + MAX_LIVE_COOKIE),
            domain: getDomain(),
            secure: false,
            signed: true,
            httpOnly: false,
            sameSite: 'strict'
        };
        // console.log(options);
         return options;
    }

    return{
        getCookieByName,
        setCookieByName,
        updateCookieByName,
        deleteCookieByName,
    }
}