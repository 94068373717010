import React, {FC} from "react";
import {TTaskCategory} from "../../types";
import {Box, Icon, Typography} from "@mui/material";
import {useVicaTasks} from "../../hooks/useVicaTasks";
import colors from "../../../../../shared/theme/colors";
import {Skeleton} from "@material-ui/lab";
import {Add, FileCopy} from "@mui/icons-material";
import {useTasksDialogs} from "../../hooks/useTasksDialogs";


export const CategoryRow: FC<{category: TTaskCategory | null, newItem?: boolean, addFiles?: boolean}> = ({category, newItem, addFiles}) => {

    const {
        categories: {order: orders},
        selectedCategory, setSelectedTask
    } = useVicaTasks();
    const {addFiles: {setAddFilesDialog}} = useTasksDialogs();

    const categoryOrder = orders.find(e => e.id === category?.id)?.order ?? -1;

    const onClick = () => {
        if (newItem) setSelectedTask('new')
        else if (addFiles) setAddFilesDialog({isOpen: true})
        else if (category) setSelectedTask(category.id)
    }

    return (
        <Box display={"flex"} padding={'18px'} id={category?.id}
             onClick={onClick}
             sx={{
                 boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
                 border: (addFiles || newItem)
                     ? '1px solid ' + colors.yellow
                     : category && selectedCategory?.id === category?.id ? `1px solid ${colors.active}` : `1px solid ${categoryOrder >= 0 ? colors.d8 : 'transparent'}`,
                 borderRadius: '8px',
                 backgroundColor: !category || categoryOrder >= 0 ? 'white' : colors.d8,
                 '&:hover': {
                     border: `1px solid ${colors.yellow}`,
                     cursor: 'pointer',
                 },
             }}>
            <Box display={"flex"} alignItems={"center"} gap={'8px'} justifyContent={"center"}>
                {newItem && <Icon color={"primary"}><Add/></Icon>}
                {addFiles && <Icon color={"primary"}><FileCopy/></Icon>}
                <Typography variant={'h4'} fontWeight={500}>
                    {newItem
                        ? 'Add new Category'
                        : addFiles
                            ? 'Add By Files'
                            : category?.name || <Skeleton width={'200px'}/>}
                </Typography>
            </Box>

        </Box>
    )
}