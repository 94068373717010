import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {TEMPLATES_CREATE_PATH, TEMPLATES_EXACT_PATH, TEMPLATES_LIST_PATH} from "./constants";
import {EmailTemplatesList} from "./pages/list";
import {EmailTemplatesExact} from "./pages/exact";
import {EmailTemplatesAdd} from "./pages/add";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route path={TEMPLATES_LIST_PATH} exact component={EmailTemplatesList}/>
            <Route path={TEMPLATES_EXACT_PATH} exact component={EmailTemplatesExact}/>
            <Route path={TEMPLATES_CREATE_PATH} exact component={EmailTemplatesAdd}/>
            <Redirect to={TEMPLATES_LIST_PATH}/>
        </Switch>
    )
}