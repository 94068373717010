import {useAssetsVariants} from "../../useAssetsVariants";
import {useEffect, useState} from "react";


export const useDeleteAssetSetting = () => {
    const {
        hideDeleteSetting: handleClose,
        isLoading: {isDeletingField: isLoading},
        dialogs: {deleteAssetSetting: {isOpen, name, id}},
        fields: {selectedField},
        variants,
        remove,
    } = useAssetsVariants();

    const [newJobType, setNewJobType] = useState<string>('');

    const handleConfirm = () => {
        if (selectedField === 'jobType') id && remove(id, newJobType);
        else id && remove(id);
    }

    const handleSetJobType = (type: string) => {
        setNewJobType(type);
    }

    const isOk = () => {
        if (selectedField === 'jobType') return newJobType.length > 0 &&  newJobType !== name;
        else return true;
    }

    useEffect(() => {
        selectedField === 'jobType' && setNewJobType('');

        //eslint-disable-next-line
    }, [isOpen]);

    return {
        isOk: isOk(),
        isOpen,
        isLoading,
        name,
        selectedField,
        variants,
        newJobType,
        handleClose,
        handleConfirm,
        handleSetJobType,
    }

}