import {Flex} from "../../../../../shared/components/Layouts"
import {Avatar, Divider, Typography} from "@mui/material";
import {frameworkType} from "../../types";
import {Typo} from "../../../../../shared/components/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";
// import {FRAMEWORK_VISIBILITY} from "../../constants";
import {useDispatch} from "react-redux";
import {openDeleteFramework, openEditFramework} from '../../store/slice';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// import {ChangeFrameworkVisibility} from "../../store/actions";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const FrameworkTopBar = ({framework} : {framework: frameworkType}) => {
    const dispatch = useDispatch();
    const {revDir} = useMainTranslation('', {keyPrefix: 'builderPage.frameworkTopBar'});

    // const changeVisibility = (visibility: typeof FRAMEWORK_VISIBILITY[number]) => {
    //     dispatch(ChangeFrameworkVisibility({frameworkId: framework.id, visibility}));
    // }

    const deleteFramework = () => dispatch(openDeleteFramework());

    const openChangeFrameworkName = () => dispatch(openEditFramework(framework));

    return(
        <>
            <Flex w={'100%'} maxh={'120px'} wrap={'wrap'} ai={'center'} >
                <Flex  w={'10%'} ai={'center'} jc={'center'} m={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>
                    <Avatar src={(framework.logo && framework.logo.length > 0) ? framework.logo : undefined} sx={{width: '32px', height: '32px'}}>
                        {(framework.logo && framework.logo.length > 0) ? '' : framework.name.split('')[0]}
                    </Avatar>
                </Flex>

                <Flex w={'85%'} direction={'column'} wrap={'wrap'}>
                    <Flex jc={'space-between'} ai={'center'} w={'100%'}>

                        <Flex ai={'center'}>
                            <Typo fontWeight={300} fontSize={'18px'} margin={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>Name: <span style={{fontWeight: 500}}>{framework.name}</span></Typo>
                            <Typography fontWeight={300} fontSize={'18px'} margin={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>ID: <span style={{fontWeight: 500}}>{framework.id}</span></Typography>
                            <ModeEditOutlineOutlinedIcon onClick={openChangeFrameworkName} style={{cursor: 'pointer'}}/>
                        </Flex>

                        <DeleteIcon
                            style={{color: '#A0A2B1', cursor: 'pointer'}}
                            fontSize={'medium'}
                            onClick={deleteFramework}
                        />
                    </Flex>
                    {/*<Flex jc={'space-between'} ai={'center'} w={'100%'} m={'23px 0 0 0'}>*/}

                        {/* <FormControl> */}
                            {/* <NativeSelect
                                // defaultValue={framework.visibility}
                                value={framework.visibility}
                                disableUnderline
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => changeVisibility(event.target.value as typeof FRAMEWORK_VISIBILITY[number])}>
                                 */}
                            {/*<TextField*/}
                            {/*    select*/}
                            {/*    label={t("Visibility")}*/}
                            {/*    value={framework.visibility}*/}
                            {/*    sx={{width: '300px'}}*/}
                            {/*    onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeVisibility(event.target.value as typeof FRAMEWORK_VISIBILITY[number])}*/}
                            {/*>*/}
                            {/*    {FRAMEWORK_VISIBILITY.map(e => <MenuItem value={e} key={e}>{t(e)}</MenuItem>)}*/}
                            {/*</TextField>*/}
                            {/* </NativeSelect> */}
                        {/* </FormControl> */}
                    {/*</Flex>*/}
                </Flex>
            </Flex>
            <Divider flexItem style={{borderColor: '#FAC000', margin: '15px 0 0 0'}} />
        </>
    )
}