//Core
import React, {FC, useContext, useState} from 'react';
import {Routes} from "./routes";

//Layout
import {Box, FlexStart} from "../../../shared/components/Layouts";

//Styled
import {BodyContainer} from "./components/styled"
import {Snack} from "../snack";
import {ThemeContext} from "styled-components";
import {useSideBar} from "./hooks/useSideBar";
import {TopBar} from "../topBar";
import {WIDTH_COMPACTED} from "./constants";
import {useMainTranslationHookInit} from "../../../shared/hooks/useMainTranslationHooks/useInit";
import {createTheme, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {muiTheme} from "../../../shared/theme/MaterialStyles";
import {LeftSideBar} from "../leftSideBar";
import {useWorkspacesAndOrganizations} from "../../AuthWorkspaceAndCookies/newWorkspaces/hooks/useWorkspacesAndOrganizations";
import {Spinner} from "../../../shared/components/Spinner";

export const Shell: FC = () => {
    const isHiddenBar = useSideBar();
    const [expanded, setExpanded] = useState(false);
    const { height, width } = useContext(ThemeContext);
    const leftSideBarWidth = expanded ? width : WIDTH_COMPACTED;
    const bodyWidth = `calc(100% - ${leftSideBarWidth})`;
    const expand = () => {
        setExpanded((prev) => !prev);
    }
    const {revDir} = useMainTranslationHookInit();

    const {isLoadingCookieData} = useWorkspacesAndOrganizations();

    return (
        <ThemeProvider theme={createTheme(muiTheme)}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: !revDir ? 'right' : 'left' }} preventDuplicate>
                <FlexStart w="100vw" minh="100vh" of={"hidden"} d={'column'}>
                    <TopBar expanded={expanded} expand={expand}/>
                    <FlexStart h={isHiddenBar ? '100vh' : height}>
                        <LeftSideBar w={leftSideBarWidth} expanded={expanded} />
                        <BodyContainer w={bodyWidth} h={isHiddenBar ? '100vh' : height}>
                            <Snack/>
                            <Box background={'#F5F5F5'} overflow={"hidden"} h={'100%'}>
                                {!isLoadingCookieData ? <Routes/> : <Spinner />}
                            </Box>
                        </BodyContainer>
                    </FlexStart>
                </FlexStart>
            </SnackbarProvider>
        </ThemeProvider>
    )
}
