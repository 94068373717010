import {FC} from 'react';
import {useAccounts} from "./hooks/useAccounts";
import {usePaginationURL} from "../../../shared/hooks/usePaginationURL";
import { Flex } from '../../../shared/components/Layouts';
import {AccountsLeftSideBar} from "./components/leftSideBar";
import {CollabInfoWithActions} from "./components/collabInfoWithActions";
import {LocalSpinner} from "../../../shared/components/Spinner";
import {Typo} from "../../../shared/components/Typography";
import {CollabOrganizationsTable} from "./components/collabOrgsTable";
import {BlockUserDialog} from "./components/dialogs/blockUserDialog";
import {ActivateUserDialog} from "./components/dialogs/activateUserDialog";
import {DeleteUserDialog} from "./components/dialogs/deleteUserDialog";
import {ForceMfaResult} from "./components/dialogs/forceMfaResult";


export const Accounts:FC = () => {
    const {getData, isLoadingExtendedUser, isExtendedUserPresent} = useAccounts();

    usePaginationURL(({page, count, filter}) => {
        getData({page, count, sort: '', filters: filter})
    }, 'left');

    return(
        <Flex w={'100%'} h={'100%'} p={"25px"}>
            <AccountsLeftSideBar />
            <Flex grow={'1'} h={'100%'} m={'0 0 0 30px'} direction={'column'} ai={'center'}>
                {!isExtendedUserPresent && !isLoadingExtendedUser && <Typo>Select Account</Typo>}
                {isLoadingExtendedUser && <LocalSpinner />}
                {!isLoadingExtendedUser && isExtendedUserPresent &&
                    <>
                        <CollabInfoWithActions />
                        <CollabOrganizationsTable />
                    </>
                }
            </Flex>

            <BlockUserDialog />
            <ActivateUserDialog />
            <DeleteUserDialog />
            <ForceMfaResult />
        </Flex>
    )
} 