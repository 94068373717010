import {Flex} from "../../../../../shared/components/Layouts";
import {useExactApp} from "../../hooks/useExactApp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {Autocomplete, Avatar, Button, Chip, TextField, Typography} from "@mui/material";
import {LoadingButton, LocalizationProvider, Skeleton} from "@mui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconTextButton} from "../../../../../shared/components/Buttons";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {AVAILABLE_STATUS, STAGING_STATUS, UNAVAILABLE_STATUS} from "../../constants";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import IosShareIcon from '@mui/icons-material/IosShare';
import {DeleteIntegrationDialog} from "../../components/dialogs/deleteIntegration";
import {parseDate} from "../../../../../shared/utils/dateTools";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const ExactApp = () => {
    const {
        app,
        form,
        isLoadingExact,
        isLoadingUpdate,
        handleGoBack,
        handleDeleteApp,
        handleSaveChanges,
        handleCancelChanges,
        handleExportJson,
        isOkToSave,
        isEditMode,
        setIsEditMode,
        handleChange,

        handleAddCerf,
        handleDeleteCerf,
        cerfForm,

        handleSetStatus,
        handleSetReleaseDate,
        handleCreateConfig,
        handleChangeCollectionPrefix,
        handleChangeAccountMappingConfig,
        collectionForm,
        handleAddCollection,
        handleDeleteCollection,
        handleChangeCollectionConfig,

        header,
        data,
        exclude,
        mappingField
    } = useExactApp();

    const {currentLanguage: {momentLocale}} = useMainTranslation('')
    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <Flex ai={'center'}>
                <KeyboardBackspaceIcon sx={{color: '#106EBE', cursor: 'pointer'}} onClick={handleGoBack}/>

                <Avatar
                    src={app?.logo ?? ''}
                    sx={{width: '45px', height: '45px', backgroundColor: app?.logo !== null ? 'inherit' : '', margin: '0 8px'}}
                    imgProps={{width: '20px'}}
                />

                <Flex overflow={'hidden'}>
                    <Typography
                        variant={'h3'}
                        fontWeight={400}
                        sx={{
                            margin: '0 32px 0 8px',
                            color: '#111827',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                    >
                        {!isLoadingExact && form ? form.name : <Skeleton width={450}/>}
                    </Typography>
                </Flex>

                <IconTextButton
                    variant={'text'}
                    disabled={isEditMode || !app || isLoadingUpdate}
                    icon={<IosShareIcon fontSize="small"/>}
                    onClick={handleExportJson}
                    fullWidth={false}
                    sx={{'& .MuiButton-startIcon': {margin:'0 16px 0 -4px'}}}
                >
                    <Typography fontWeight={600}>{('Export JSON')}</Typography>
                </IconTextButton>

                <IconTextButton
                    variant={'text'}
                    disabled={isEditMode}
                    icon={<EditIcon fontSize="small"/>}
                    onClick={() => setIsEditMode(true)}
                    fullWidth={false}
                    sx={{'& .MuiButton-startIcon': {margin:'0 16px 0 -4px'}}}
                >
                    <Typography fontWeight={600}>{('Edit')}</Typography>
                </IconTextButton>

                <IconTextButton
                    variant={'text'}
                    color={'error'}
                    disabled={!app}
                    icon={<DeleteIcon fontSize="small"/>}
                    onClick={handleDeleteApp}
                    sx={{'& .MuiButton-startIcon': {margin: '0 16px 0 -4px'}}}
                >
                    <Typography fontWeight={600}>{('Delete')}</Typography>
                </IconTextButton>
            </Flex>

            <LocalSpinner isLoading={isLoadingExact}/>

            {isEditMode ?
                <Flex w={'90%'} jc={'space-between'}  overflow={'auto'}>
                    <Flex w={'50%'} direction={'column'} overflow={'auto'} disableScrollBar>
                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <LoadingButton
                                loading={isLoadingUpdate}
                                sx={{margin: '0 16px 0 0'}}
                                variant="contained"
                                size={'medium'}
                                onClick={handleSaveChanges}
                                disabled={!isOkToSave}
                            >{('Save')}</LoadingButton>
                            <Button disabled={isLoadingUpdate} variant={'outlined'} size={'medium'} onClick={handleCancelChanges} >{('Cancel')}</Button>
                        </Flex>


                        <TextField
                            required
                            value={form.name}
                            name={'name'}
                            onChange={handleChange}
                            label={'Name'}
                            size={'small'}
                            sx={{margin: '16px 0 0 0'}}
                        />

                        <TextField
                            required
                            value={form.logo}
                            name={'logo'}
                            onChange={handleChange}
                            label={'Logo'}
                            size={'small'}
                            sx={{margin: '16px 0 0 0'}}
                        />

                        <TextField
                            required
                            value={form.description}
                            name={'description'}
                            onChange={handleChange}
                            label={'Description'}
                            size={'small'}
                            minRows={5}
                            multiline
                            sx={{margin: '16px 0 0 0'}}
                        />

                        <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>

                        <TextField
                            required
                            value={form.type}
                            name={'type'}
                            onChange={handleChange}
                            label={'Type'}
                            size={'small'}
                            sx={{margin: '32px 0 0 0'}}
                        />

                        <TextField
                            required
                            value={form.applicationGroup}
                            name={'applicationGroup'}
                            onChange={handleChange}
                            label={'Application group'}
                            size={'small'}
                            sx={{margin: '16px 0 0 0'}}
                        />

                        <TextField
                            required
                            value={form.securityScope}
                            name={'securityScope'}
                            onChange={handleChange}
                            label={'Security scope'}
                            size={'small'}
                            sx={{margin: '16px 0 0 0'}}
                        />

                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <TextField
                                required
                                value={cerfForm.form.cerf}
                                name={'cerf'}
                                onChange={cerfForm.handleChange}
                                label={'Add certificate'}
                                size={'small'}
                                sx={{margin: '0 8px 0 0'}}
                            />
                            <Button disabled={!cerfForm.form.cerf.trim().length} onClick={handleAddCerf} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add')}</Button>
                        </Flex>

                        <Flex w={'100%'} wrap={'wrap'} m={'8px 0 0 0'}>
                            {form.certifications.map(e => <Chip sx={{margin: '4px'}} label={e} onDelete={() => handleDeleteCerf(e)} />)}
                        </Flex>

                        <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>

                        <TextField
                            required
                            value={form.endpointMappingName}
                            name={'endpointMappingName'}
                            onChange={handleChange}
                            label={'EMN'}
                            size={'small'}
                            sx={{margin: '32px 0 0 0'}}
                        />

                        <Autocomplete
                            options={[AVAILABLE_STATUS, UNAVAILABLE_STATUS, STAGING_STATUS]}
                            getOptionLabel={(option) => option.toUpperCase()}
                            renderInput={(params) => <TextField {...params} sx={{margin: '16px 0 0 0'}} label="Status" variant="outlined" />}
                            placeholder="Type"
                            fullWidth={true}
                            onChange={(event: any, newValue: string | null) => newValue && handleSetStatus(newValue)}
                            value={form.status}
                        />

                        {form.status === STAGING_STATUS &&
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Stage Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={form.releaseDate}
                                    onChange={(e: any) => e && handleSetReleaseDate(e ?? new Date(e).toISOString())}
                                    renderInput={(params: any) => <TextField sx={{margin: '16px 0 0 0'}} {...params} variant={'outlined'} />}
                                />
                            </LocalizationProvider>
                        }

                        <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                    </Flex>

                    <Flex w={'45%'} jc={'flex-start'} direction={'column'} overflow={'auto'} disableScrollBar>
                        {form.config === null ?
                            <Button onClick={handleCreateConfig}>Create config</Button>
                            :
                            <Flex w={'100%'} m={'16px 0 0 0'} direction={'column'}>
                                <TextField
                                    required
                                    value={form.config.collectionPrefix}
                                    name={'collectionPrefix'}
                                    onChange={(e) => handleChangeCollectionPrefix(e.target.value as string ?? '')}
                                    label={'Collection prefix'}
                                    size={'small'}
                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                />

                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                <Typography variant={'body1'} color={'#343440'} sx={{margin: '8px 0 0 0', fontWeight: 600}}>{('accountMappingConfig')}</Typography>
                                <Flex p={'8px 0 0 16px'} direction={'column'}>
                                    <TextField
                                        required
                                        value={form.config.accountMappingConfig.accountCollection}
                                        name={'accountCollection'}
                                        onChange={handleChangeAccountMappingConfig}
                                        label={'accountCollection'}
                                        size={'small'}
                                        sx={{margin: '16px 0 0 0', width: '100%'}}
                                    />

                                    <TextField
                                        required
                                        value={form.config.accountMappingConfig.accountCollectionField}
                                        name={'accountCollectionField'}
                                        onChange={handleChangeAccountMappingConfig}
                                        label={'accountCollectionField'}
                                        size={'small'}
                                        sx={{margin: '16px 0 0 0', width: '100%'}}
                                    />

                                    <TextField
                                        required
                                        value={form.config.accountMappingConfig.employeeCollection}
                                        name={'employeeCollection'}
                                        onChange={handleChangeAccountMappingConfig}
                                        label={'employeeCollection'}
                                        size={'small'}
                                        sx={{margin: '16px 0 0 0', width: '100%'}}
                                    />

                                    <TextField
                                        required
                                        value={form.config.accountMappingConfig.employeeCollectionField}
                                        name={'employeeCollectionField'}
                                        onChange={handleChangeAccountMappingConfig}
                                        label={'employeeCollectionField'}
                                        size={'small'}
                                        sx={{margin: '16px 0 0 0', width: '100%'}}
                                    />
                                </Flex>


                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                <Typography variant={'body1'} color={'#343440'} sx={{margin: '8px 0 0 0', fontWeight: 600}}>{('collectionConfigs')}</Typography>

                                <Flex ai={'center'} m={'16px 0 0 0'}>
                                    <TextField
                                        required
                                        value={collectionForm.form.name}
                                        name={'name'}
                                        onChange={collectionForm.handleChange}
                                        label={'Collection name'}
                                        size={'small'}
                                        sx={{margin: '0 8px 0 0'}}
                                    />
                                    <Button disabled={!collectionForm.form.name.trim().length} onClick={handleAddCollection} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add collection')}</Button>
                                </Flex>

                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>

                                {form.config.collectionConfigs.map(e => {
                                    return(
                                        <>
                                            <Typography variant={'body1'} color={'#343440'} sx={{margin: '8px 0 0 0', fontWeight: 600}}>{e.name}</Typography>
                                            <Flex key={e.name} p={'8px 0 0 16px'} direction={'column'}>
                                                <TextField
                                                    required
                                                    value={e.name}
                                                    disabled
                                                    label={'Collection name'}
                                                    size={'small'}
                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                />

                                                <TextField
                                                    required
                                                    value={e.alias}
                                                    onChange={(event) => handleChangeCollectionConfig(event, e.name)}
                                                    label={'Collection alias'}
                                                    name={'alias'}
                                                    size={'small'}
                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                />

                                                <TextField
                                                    required
                                                    value={e.sortingField ?? ''}
                                                    onChange={(event) => handleChangeCollectionConfig(event, e.name)}
                                                    label={'Sorting field'}
                                                    name={'sortingField'}
                                                    size={'small'}
                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                />

                                                {/*HEADERS*/}

                                                <Flex ai={'center'} m={'16px 0 0 0'}>
                                                    <TextField
                                                        required
                                                        value={header.headerForm.forCollectionName === e.name ? header.headerForm.header : ''}
                                                        onChange={(event) => header.setHeaderForm({header: event.target.value, forCollectionName: e.name})}
                                                        label={'Add header'}
                                                        size={'small'}
                                                        sx={{margin: '0 8px 0 0'}}
                                                    />
                                                    <Button disabled={header.headerForm.forCollectionName !== e.name || !header.headerForm.header.length} onClick={header.handleAddHeader} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add header')}</Button>
                                                </Flex>

                                                <Flex w={'100%'} wrap={'wrap'} m={'8px 0 0 0'}>
                                                    {e.headers.map(h => <Chip sx={{margin: '4px'}} label={h} onDelete={() => header.handleDeleteHeader(h, e.name)} />)}
                                                </Flex>

                                                {/*//DATA */}

                                                <Flex ai={'center'} m={'16px 0 0 0'}>
                                                    <TextField
                                                        required
                                                        value={data.dataForm.forCollectionName === e.name ? data.dataForm.data : ''}
                                                        onChange={(event) => data.setDataForm({data: event.target.value, forCollectionName: e.name})}
                                                        label={'Add data'}
                                                        size={'small'}
                                                        sx={{margin: '0 8px 0 0'}}
                                                    />
                                                    <Button disabled={data.dataForm.forCollectionName !== e.name || !data.dataForm.data.length} onClick={data.handleAddData} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add data')}</Button>
                                                </Flex>

                                                <Flex w={'100%'} wrap={'wrap'} m={'8px 0 0 0'}>
                                                    {e.data.map(d => <Chip sx={{margin: '4px'}} label={d} onDelete={() => data.handleDeleteData(d, e.name)} />)}
                                                </Flex>

                                                {/*//EXCLUDE */}

                                                <Flex ai={'center'} m={'16px 0 0 0'}>
                                                    <TextField
                                                        required
                                                        value={exclude.excludeForm.forCollectionName === e.name ? exclude.excludeForm.exclude : ''}
                                                        onChange={(event) => exclude.setExcludeForm({exclude: event.target.value, forCollectionName: e.name})}
                                                        label={'Add exclude field'}
                                                        size={'small'}
                                                        sx={{margin: '0 8px 0 0'}}
                                                    />
                                                    <Button disabled={exclude.excludeForm.forCollectionName !== e.name || !exclude.excludeForm.exclude.length} onClick={exclude.handleAddExclude} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add exlude field')}</Button>
                                                </Flex>

                                                <Flex w={'100%'} wrap={'wrap'} m={'8px 0 0 0'}>
                                                    {e.excludeFields.map(d => <Chip sx={{margin: '4px'}} label={d} onDelete={() => exclude.handleDeleteExclude(d, e.name)} />)}
                                                </Flex>

                                                {/*FIELD MAPPING SCHEME*/}

                                                <Flex ai={'center'} m={'16px 0 0 0'}>
                                                    <TextField
                                                        required
                                                        value={mappingField.mappingFieldForm.forCollectionName === e.name ? mappingField.mappingFieldForm.name : ''}
                                                        onChange={(event) => mappingField.setMappingFieldForm({name: event.target.value, forCollectionName: e.name})}
                                                        label={'Add mapping field scheme'}
                                                        size={'small'}
                                                        sx={{margin: '0 8px 0 0'}}
                                                    />
                                                    <Button disabled={mappingField.mappingFieldForm.forCollectionName !== e.name || !mappingField.mappingFieldForm.name.length} onClick={mappingField.handleAddMappingFieldScheme} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>{('add field mapping scheme')}</Button>
                                                </Flex>

                                                <Flex w={'100%'} wrap={'wrap'} m={'8px 0 0 0'}>
                                                    {e.mappingFieldsSchema.map(mfs => {
                                                        return(
                                                            <Flex w={'100%'} direction={'column'} border={'1px solid #c0c0c0'} p={'8px'} br={'8px'} m={'16px 0 0 16px'}>
                                                                <TextField
                                                                    required
                                                                    value={mfs.name ?? ''}
                                                                    disabled
                                                                    label={'Name (BD field)'}
                                                                    size={'small'}
                                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                                />

                                                                <TextField
                                                                    required
                                                                    value={mfs.sourceName ?? ''}
                                                                    label={'Source name (Integration field)'}
                                                                    onChange={(event) => mappingField.handleChangeMappingAccountField(event.target.value, e.name, mfs.name, 'sourceName')}
                                                                    name={'sourceName'}
                                                                    size={'small'}
                                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                                />

                                                                <TextField
                                                                    required
                                                                    value={mfs.representationName ?? ''}
                                                                    name={'representationName'}
                                                                    label={'Representation name (For collectors)'}
                                                                    onChange={(event) => mappingField.handleChangeMappingAccountField(event.target.value, e.name, mfs.name, 'representationName')}
                                                                    size={'small'}
                                                                    sx={{margin: '16px 0 0 0', width: '100%'}}
                                                                />

                                                                <Button sx={{margin: '16px 0 0 0'}} color={'error'} onClick={() => mappingField.handleDeleteMappingFieldScheme(mfs.name, e.name)}>Remove mapping field scheme</Button>
                                                            </Flex>
                                                        )
                                                    })}
                                                </Flex>

                                                <Button sx={{margin: '16px 0 0 0'}} color={'error'} onClick={() => handleDeleteCollection(e.name)}>Delete collection config</Button>
                                                {/*<Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Exclude Fields')} <i style={{color: '#343440'}}>{e.excludeFields.join(', ')}</i></Typography>*/}
                                            </Flex>
                                            <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                        </>
                                    )
                                })}
                            </Flex>
                        }
                    </Flex>
                </Flex>
                :
                <Flex w={'90%'} jc={'space-between'} overflow={'auto'} h={'100%'} m={'32px 0 0 0'}>
                    {app &&
                        <>
                            <Flex w={'50%'} direction={'column'} overflow={'auto'} disableScrollBar>
                                <Typography variant={'body1'} color={'#747474'}>{('ID')} <i style={{color: '#343440'}}>{app.id}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Name')} <i style={{color: '#343440'}}>{app.name}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0', wordWrap: 'break-word'}}>{('Logo')}</Typography>
                                <Typography variant={'body1'} color={'#343440'}>{app.logo ?? '- - -'}</Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Description')}</Typography>
                                <Typography variant={'body1'} color={'#343440'}>{app.description ?? '- - -'}</Typography>
                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Type')} <i style={{color: '#343440'}}>{app.type}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('applicationGroup')} <i style={{color: '#343440'}}>{app.applicationGroup}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('securityScope')} <i style={{color: '#343440'}}>{app.securityScope ?? '- - -'}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Certificates')} <i style={{color: '#343440'}}>{app.certifications.join(', ')}</i></Typography>
                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('endpointMappingName')} <i style={{color: '#343440'}}>{app.endpointMappingName ?? '- - -'}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('status')} <i style={{color: '#343440'}}>{app.status ?? '- - -'}</i></Typography>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Release date')} <i style={{color: '#343440'}}>{parseDate(app.releaseDate, momentLocale)}</i></Typography>
                                <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                            </Flex>

                            <Flex w={'45%'} direction={'column'} overflow={'auto'} disableScrollBar>
                                <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Config')} {app.config === null ? ' (Not exists)' : ''}</Typography>
                                {app.config &&
                                    <>
                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('collectionPrefix')} <i style={{color: '#343440'}}>{app.config.collectionPrefix}</i></Typography>

                                        <Typography variant={'body1'} color={'#343440'} sx={{margin: '8px 0 0 0', fontWeight: 600}}>{('accountMappingConfig')}</Typography>
                                        <Flex p={'8px 0 0 16px'} direction={'column'}>
                                            <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('accountCollection')} <i style={{color: '#343440'}}>{app.config.accountMappingConfig.accountCollection}</i></Typography>
                                            <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('accountCollectionField')} <i style={{color: '#343440'}}>{app.config.accountMappingConfig.accountCollectionField}</i></Typography>
                                            <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('employeeCollection')} <i style={{color: '#343440'}}>{app.config.accountMappingConfig.employeeCollection}</i></Typography>
                                            <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('employeeCollectionField')} <i style={{color: '#343440'}}>{app.config.accountMappingConfig.employeeCollectionField}</i></Typography>
                                        </Flex>

                                        <Typography variant={'body1'} color={'#343440'} sx={{margin: '8px 0 0 0', fontWeight: 600}}>{('collectionConfigs')}</Typography>
                                        {app.config.collectionConfigs.map(e => {
                                            return(
                                                <>
                                                    <Flex p={'8px 0 0 16px'} direction={'column'} key={e.name} hoverStyles={'background-color: #c0c0c0;'}>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Name')} <i style={{color: '#343440'}}>{e.name}</i></Typography>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Alias')} <i style={{color: '#343440'}}>{e.alias}</i></Typography>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Header')} <i style={{color: '#343440'}}>{e.headers.join(', ')}</i></Typography>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Data')} <i style={{color: '#343440'}}>{e.data.join(', ')}</i></Typography>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('sortingField')} <i style={{color: '#343440'}}>{e.sortingField ?? '- - -'}</i></Typography>
                                                        <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Exclude Fields')} <i style={{color: '#343440'}}>{e.excludeFields.join(', ')}</i></Typography>
                                                        {e.mappingFieldsSchema.length > 0 && <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Mapping Fields Schema')}</Typography>}
                                                        {e.mappingFieldsSchema.map(m => {
                                                            return(
                                                                <Flex direction={'column'} m={'16px 0 0 16px'}  border={'1px solid #c0c0c0'} p={'8px'} br={'8px'}>
                                                                    <Typography variant={'body1'} color={'#747474'}>{('Name')} <i style={{color: '#343440'}}>{m.name}</i></Typography>
                                                                    <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Source name')} <i style={{color: '#343440'}}>{m.sourceName}</i></Typography>
                                                                    <Typography variant={'body1'} color={'#747474'} sx={{margin: '8px 0 0 0'}}>{('Representation name')} <i style={{color: '#343440'}}>{m.representationName}</i></Typography>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Flex>
                                                    <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0', margin: '8px 0 0 0'}}/>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </Flex>
                        </>
                    }

                    <DeleteIntegrationDialog />
                </Flex>
            }
        </Flex>
    )
}