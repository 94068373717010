import React, {FC} from "react";
import {LeftSideBarItem, TAccordion} from "../../types";
import {AccordionItem} from "./AccordionItem";
import {RegularItem} from "./RegularItem";
import {PATH_ACCOUNTS} from "../../../shell/constants";
import {useHistory} from "react-router-dom";

type PropTypes = {
    item: LeftSideBarItem;
    activeRoute: LeftSideBarItem[];
    status: TAccordion;
    setExpanded:  React.Dispatch<React.SetStateAction<TAccordion>>;
};


export const ExpandedItem: FC<PropTypes> = (props: PropTypes) => {
    const { item } = props;
    const history = useHistory();
    const handleActive = (item: LeftSideBarItem) => {
        (item?.isItComplex() ?? true) || history.push(item?.path ?? PATH_ACCOUNTS);
    }
    const prop = {...props, handleActive};
    return (item.isItComplex() ? <AccordionItem {...prop} /> : <RegularItem  {...prop}/>)
};
