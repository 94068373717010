import {Flex} from "../../../../../../../shared/components/Layouts"
import {useDispatch, useSelector} from "react-redux";
import {
    addEvidenceDialog,
    controls, frameworks,
    hideAddEvidence,
    isLoading,
    selectedControl,
    selectedFramework
} from "../../../../store/slice";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Tab, Tabs, TextField} from "@mui/material";
import {Typo} from "../../../../../../../shared/components/Typography";
import CloseIcon from '@material-ui/icons/Close';
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {evidenceInputType, gapObject} from "../../../../types";
import React, {useEffect, useState} from "react";
import {WarningDialog} from "../../../styled";
import {AddEvidence} from '../../../../store/actions';
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {GAP_ORIGIN} from "../../../../constants";
import {CreateFrameworkIdGap} from "../gap/createFrameworkIdGap";
import {CreateInnerGap} from "../gap/createInnerGap";
import DialogActions from "@mui/material/DialogActions";

export const AddEvidenceDialog = () => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});
    const dispatch = useDispatch();
    const {isOpen} = useSelector(addEvidenceDialog);
    const _selectedControl = useSelector(selectedControl);
    const _controls = useSelector(controls);
    const _selectedFramework = useSelector(selectedFramework);
    const _isLoading = useSelector(isLoading);
    //
    const _frameworks = useSelector(frameworks);

    const [stage, setStage] = useState<number>(0);

    const emptyEvidence: evidenceInputType = {
        name: '',
        categories: [],
        description: '',
        id: null,
        origin: true,
        organizationId: null,
        visibility: null,
        controlId: _selectedControl || '',
        collectorId: null,
        gap: null
    }

    const [form, updateForm] = useState<evidenceInputType>(emptyEvidence);

    useEffect(() => {
        if(isOpen){
            const counter = _selectedControl && (_controls.find((e) => e.id === _selectedControl)?.foundInFrameworksCounter || 0);
            setStage(counter ? (counter < 2 ? 1 : 0) : 0);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        updateForm(emptyEvidence)
        //eslint-disable-next-line
        }, [_selectedControl]);

    const handleClose = () => {
        dispatch(hideAddEvidence());
        updateForm(emptyEvidence);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleChangeCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            categories: [event.target.value as string],
        }));
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     updateForm((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

    const validateForm = (): boolean => {
        return(
            form.name.trim().length > 0 &&
            form.description.trim().length > 0 &&
            form.categories.length > 0
        )
    }

    const onSave = () => {
        if(validateForm()) {
            dispatch(AddEvidence({evidence: form, frameworkId: _selectedFramework || '', isEvidencesPage : false}));
            updateForm(emptyEvidence);
        }
    }

    //
    //GAP

    const [frameworkIdGapAvailableTabs, setFrameworkIdGapAvailableTabs] = useState<string[]>([GAP_ORIGIN]);
    const [selectedFrameworkIdGapTab, setSelectedFrameworkIdGapTab] = useState<string>(GAP_ORIGIN);

    const [languageGapAvailableTabs, setLanguageGapAvailableTabs] = useState<string[]>([]);
    const [selectedLanguageGabTab, setSelectedLanguageGabTab] = useState<string>('');


    const handleSelectFrameworkIdGapTab = (ttab: string) => {
        setSelectedFrameworkIdGapTab(ttab);
        if(ttab === GAP_ORIGIN){
            setLanguageGapAvailableTabs([]);
            setSelectedLanguageGabTab('');
        }else{
            if(form.gap){
                const availableTabs = Object.keys(form.gap[ttab]);
                setLanguageGapAvailableTabs(availableTabs);
                availableTabs.length > 0 && setSelectedLanguageGabTab(availableTabs[0]);
            }
        }
    }

    const getGapNameValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.name ?? `No gap found: (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap)})`;
    }

    const getGapCategoryValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.categories[0] ?? `No gap found (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap?.categories)})`;
    }

    const getGapDescValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.description ?? `No gap found: (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap)})`;
    }

    //field updates
    const handleChangeInnerGapName = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        name: event.target.value as string
                    }
                }
            } : null
        }));
    }

    const handleChangeInnerGapCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        categories: [event.target.value as string]
                    }
                }
            } : null
        }));
    }

    const handleChangeInnerGapDesc = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        description: event.target.value as string
                    }
                }
            } : null
        }));
    }

    //dialogs
    const [isOpenCreateFrameworkIdGapDialog, setIsOpenCreateFrameworkIdGapDialog] = useState<boolean>(false);

    const handleAddFrameworkGap = (id: string, innerGap: string) => {
        updateForm({
            ...form,
            gap: {
                ...form.gap,
                [id]: {
                    [innerGap]: {
                        name: '',
                        categories: [''],
                        description: ''
                    }
                }
            }
        });
        setIsOpenCreateFrameworkIdGapDialog(false); //closing dialog
        setFrameworkIdGapAvailableTabs([...frameworkIdGapAvailableTabs, id]); //adding tab
        setLanguageGapAvailableTabs([innerGap]); //adding inner tab
        setSelectedFrameworkIdGapTab(id); //selecting just created framework id gap
        setSelectedLanguageGabTab(innerGap); //selecting just created inner gap
    }

    const [isOpenCreateInnerGap, setIsOpenCreateInnerGap] = useState<boolean>(false);

    const handleAddInnerGap = (innerGap: string) => {
        updateForm({
            ...form,
            gap: form.gap ? {
                ...form.gap,
                [selectedFrameworkIdGapTab]: {
                    ...form.gap[selectedFrameworkIdGapTab],
                    [innerGap]: {
                        name: '',
                        categories: [''],
                        description: ''
                    }
                }
            } : null
        });
        setIsOpenCreateInnerGap(false); //closing dialog
        setLanguageGapAvailableTabs([...languageGapAvailableTabs, innerGap]); //adding inner tab
        setSelectedLanguageGabTab(innerGap); //selecting just created inner gap
    }

    const handleDeleteCurrentFrameworkGap = () => {
        if(!form.gap || selectedFrameworkIdGapTab === GAP_ORIGIN) return;
        const newGap = {...form.gap};
        delete newGap[selectedFrameworkIdGapTab];
        if(!Object.keys(newGap).length){
            //no gap
            updateForm({...form, gap: null});
        }else{
            updateForm({...form, gap: newGap});
        }
        setFrameworkIdGapAvailableTabs([...frameworkIdGapAvailableTabs.filter(e => e !== selectedFrameworkIdGapTab)]); //deleting framework tab
        setSelectedFrameworkIdGapTab(GAP_ORIGIN); //setting default tab
        setLanguageGapAvailableTabs([]); //removing all inner tabs as its origin
        setSelectedLanguageGabTab('');
    }

    const handleDeleteCurrentInnerGap = () => {
        if(!form.gap || selectedFrameworkIdGapTab === GAP_ORIGIN || !selectedLanguageGabTab.trim().length) return;
        let newGap = JSON.parse(JSON.stringify(form.gap));
        // console.log(`currentGap: ${JSON.stringify(form.gap)} -> \n gap[${selectedFrameworkIdGapTab}]: ${JSON.stringify(form.gap[selectedFrameworkIdGapTab])} -> deleting ${selectedLanguageGabTab}`);
        const frameworkGapToDeleteFrom = newGap[selectedFrameworkIdGapTab];
        if(frameworkGapToDeleteFrom){
            delete frameworkGapToDeleteFrom[selectedLanguageGabTab];
        }
        updateForm({...form, gap: {...newGap, [selectedFrameworkIdGapTab]: frameworkGapToDeleteFrom}});
        setLanguageGapAvailableTabs([...languageGapAvailableTabs.filter(e => e !== selectedLanguageGabTab)]); //removing inner tab
        const availableTabs = Object.keys(form.gap[selectedFrameworkIdGapTab]);
        availableTabs.length > 0 && setSelectedLanguageGabTab(availableTabs[0]);
    }

    return(
        <>
            {(stage === 0  && !_isLoading) &&
            <WarningDialog
                // maxWidth={''}
                style={{borderRadius: '25px !important', border: '1px solid red'}}
                open={isOpen}
                onClose={handleClose}
            >
                <Flex w={'100%'} jc={'flex-end'} p={!revDir ? '30px 30px 0 0' : '30px 0 0 30px'}>
                    <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
                </Flex>

                <DialogContent>
                    <Flex  direction={'column'} ai={'center'} m={'0 0 0 0'} talign={'center'} p={'0 50px'}>
                        <Typo fontSize={'25px'} fontWeight={300} letterSpacing={'1.53px'} color={'#1D1D1D'}>{('CREATE EVIDENCE')}</Typo>
                        <Typo fontSize={'12px'} fontWeight={400} margin={'27px 0 0 0'} color={'background: rgba(0, 0, 0, 0.54)'}>{('Any changes will be effects all frameworks that the control has attached!')}</Typo>
                        <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'}>Effected on {_controls.find((e) => e.id === _selectedControl)?.foundInFrameworksCounter || 0} frameworks </Typo>

                        {/* <ButtonWithBackround
                            margin={'60px 0 30px 0 '}
                            width={'214px'}
                            height={'37px'}
                            color={'#FFA000'}
                            text={'NEXT'}
                            fontColor={'white'}
                            fontSize={'16px'}
                            fontWeight={600}
                            onClick={() => setStage(1)}
                        /> */}
                        <CustomButton
                            variant={'contained'}
                            onClick={() => setStage(1)}
                            sx={{margin: '60px 0 30px 0'}}
                        >
                            {'NEXT'}
                        </CustomButton>
                    </Flex>
                </DialogContent>
            </WarningDialog>
            }
            {(stage === 1 && !_isLoading) &&
                <Dialog
                    fullWidth
                    open={isOpen}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                            <Typo fontSize={'24px'} fontWeight={700} letterSpacing={'1.53px'} margin={!revDir ? '0 50px 0 0' : '0 0 0 50px'}>{'Create Evidence'}</Typo>
                            <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
                        </Flex>
                    </DialogTitle>

                    <DialogContent>
                        <Flex ai={'center'} jc={'space-between'}>
                            <Button variant={'contained'} onClick={() => setIsOpenCreateFrameworkIdGapDialog(true)}>{('Create gap')}</Button>
                            <Button color={'error'} variant={'contained'} onClick={handleDeleteCurrentFrameworkGap} disabled={selectedFrameworkIdGapTab === GAP_ORIGIN}>{'Delete current gap'}</Button>
                        </Flex>
                        <Tabs sx={{margin: '8px 0 0 0'}} variant={'scrollable'} value={selectedFrameworkIdGapTab} onChange={(e, newValue: string) => handleSelectFrameworkIdGapTab(newValue)} aria-label="basic tabs example">
                            {frameworkIdGapAvailableTabs.map(e => {
                                //searching for such framework by id
                                const currFramework = _frameworks.find(f => f.id === e);
                                return(
                                    <Tab key={e} label={e === GAP_ORIGIN ? e : `${e} (${currFramework?.name ?? '404'})`} value={e}/>
                                )
                            })}
                        </Tabs>

                        <Divider flexItem sx={{bgcolor: 'gray', borderColor: 'gray', margin: '16px 0'}}/>

                        <Flex ai={'center'} jc={'space-between'}>
                            <Button color={'secondary'} variant={'contained'} onClick={() => setIsOpenCreateInnerGap(true)} disabled={!(selectedFrameworkIdGapTab.trim().length > 0 && selectedFrameworkIdGapTab !== GAP_ORIGIN)}>{'Create inner gap'}</Button>
                            <Button color={'error'} variant={'contained'} onClick={handleDeleteCurrentInnerGap} disabled={selectedFrameworkIdGapTab === GAP_ORIGIN || languageGapAvailableTabs.length <= 1 || !selectedLanguageGabTab.trim().length}>{'Delete current inner gap'}</Button>
                        </Flex>

                        <Divider flexItem sx={{bgcolor: 'gray', borderColor: 'gray', margin: '16px 0'}}/>

                        {selectedFrameworkIdGapTab === GAP_ORIGIN ?
                            <Flex w={'100%'} direction={'column'}>
                                <TextField id="filled-basic" label={'Name'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={form.name} onChange={handleChange}/>
                                <TextField id="filled-basic" label={'Category'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'categories'} value={form.categories[0] || ''} onChange={handleChangeCategories}/>
                                <TextField id="filled-basic" label={'Description'} variant="outlined" multiline minRows={5} sx={{margin: '0 0 28px 0'}} name={'description'} value={form.description} onChange={handleChange}/>
                            </Flex>
                            :
                            <Flex w={'100%'} direction={'column'}>
                                <Tabs variant={'scrollable'} value={selectedLanguageGabTab} onChange={(e, newValue: string) => setSelectedLanguageGabTab(newValue)} aria-label="basic tabs example">
                                    {languageGapAvailableTabs.map(e => {
                                        return(
                                            <Tab key={e} label={e} value={e}/>
                                        )
                                    })}
                                </Tabs>

                                <Flex w={'100%'} direction={'column'} m={'16px 0 0 0'}>
                                    <TextField id="filled-basic" label={'Name'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapNameValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapName}/>
                                    <TextField id="filled-basic" label={'Category'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapCategoryValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapCategories}/>
                                    <TextField id="filled-basic" label={'Description'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapDescValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapDesc}/>
                                </Flex>
                            </Flex>
                        }
                    </DialogContent>

                    <DialogActions>
                        <CustomButton
                            variant={'contained'}
                            disabled={!validateForm()}
                            onClick={onSave}
                        >
                            {'SAVE'}
                        </CustomButton>
                    </DialogActions>

                    {/*DIALOG*/}
                    <CreateFrameworkIdGap
                        isOpen={isOpenCreateFrameworkIdGapDialog}
                        handleClose={() => setIsOpenCreateFrameworkIdGapDialog(false)}
                        onCreate={handleAddFrameworkGap}
                        addedGaps={Object.keys(form.gap ?? {})}
                    />

                    <CreateInnerGap
                        isOpen={isOpenCreateInnerGap}
                        handleClose={() => setIsOpenCreateInnerGap(false)}
                        onCreate={handleAddInnerGap}
                        addedGaps={Object.keys(form.gap ? (form.gap[selectedFrameworkIdGapTab] ?? {}) : {})}
                    />
                </Dialog>
            }
        </>
    )
}
