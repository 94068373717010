import { FC } from "react"
import {Redirect, Route, Switch} from "react-router"
import {PATH_ACCESS_MANAGEMENT_PERMISSIONS_LIST_PATH, PATH_ACCESS_MANAGEMENT_ROLES_LIST_PATH} from "./constants";
import {RolesList} from "./pages/rolesList";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_ACCESS_MANAGEMENT_ROLES_LIST_PATH} component={RolesList}/>
            <Route exact path={PATH_ACCESS_MANAGEMENT_PERMISSIONS_LIST_PATH} component={() => <h1>PERMISSIONS</h1>}/>
            <Redirect to={PATH_ACCESS_MANAGEMENT_ROLES_LIST_PATH}/>
    </Switch>
)
}