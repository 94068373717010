import {useDispatch} from "react-redux";
import {addErrorSnack, addInfoSnack, addSuccessfulSnack} from "../../store/slice";
import {useCallback} from "react";
import {TSnack} from "../../types";

export const useSnack = () => {
  const dispatch = useDispatch();
  const snack : { [key in TSnack]: (message: string) => void } = {
    info: (message: string) => dispatch(addInfoSnack(message)),
    error: (message: string) => dispatch(addErrorSnack(message)),
    success: (message: string) => dispatch(addSuccessfulSnack(message)),
  }
  return useCallback((message: string, variant: TSnack = 'info') => snack[variant](message)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , []);
}
