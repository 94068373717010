import {useDispatch, useSelector} from "react-redux";
import {policyType} from "../../../../types";
import React, {useEffect, useState} from "react";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import colors from "../../../../../../../shared/theme/colors";
import {UploadFileBTN} from "../../../../../../../shared/components/FileBTN/UploadFileBTN";
import {Delete} from "@material-ui/icons";
import {toBase64} from "../../../../../../../shared/utils/base64";
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {Spinner} from "../../../../../../../shared/components/Spinner";
import {policies, selectPolicy} from "../../../../store/slice";
import {UpdatePolicy} from "../../../../store/actions";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {deleteAllFiles, uploadFile} from "../../../../api";
import {shortString} from "../../../../helpers";

export const UpdatePolicyComponent = ({isOpen, policyId} : {isOpen: boolean, policyId: string | null}) => {
    const dispatch = useDispatch();
    const _policies = useSelector(policies);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    // const _deletableFiles = useSelector(deletableFiles);

    const initPolicy: policyType = {
        name: '',
        description: '',
        templates: [],
        tag: [],
        visibility: null,
        organizationId: null,
        origin: true,
        id: '',
        pipeLinesCounter: 0,
    }

    const [currPolicy, setCurrPolicy] = useState<policyType | null>(null);
    const [form, updateForm] = useState<policyType>(initPolicy);

    useEffect(() => {
        if(!policyId){
            setCurrPolicy(null);
            updateForm(initPolicy);
        }else{
            const currentPolicy = _policies.find(e => e.id === policyId);
            // console.log(`FOUND: ${JSON.stringify(currentPolicy)}`);
            if(currentPolicy) {
                setCurrPolicy(currentPolicy);
                updateForm(currentPolicy);
            }
        }
        //eslint-disable-next-line
    }, [policyId])

    useEffect(() => {
        return () => {
            dispatch(selectPolicy(null));
        }
        //eslint-disable-next-line
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    // useEffect(() => {
    //     if(!isOpen && form.templates.length > 0 && form.workspaceId.length > 0){
    //         //if user filled tempalates and closed component -> need to delete all those files
    //         dispatch(DeleteAllFile({fileIds: form.templates.map(e => e.fileId), autoId: ''}));
    //         dispatch(clearDeletableFiles());
    //     }
    //     if(!isOpen) updateForm(initPolicy);
    // }, [isOpen]);

    const onSave = () => {
        dispatch(UpdatePolicy(form));
        updateForm(initPolicy);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        updateForm((prevValues) => ({
            ...prevValues, visibility: value,
        }));
    }

    const addFile = async (file: FileList[number], fileName: string) => {
        const fileBase64: string = await toBase64(file) as string;
        setIsLoading(true);
        const fileId =  await uploadFile(fileBase64);
        setIsLoading(false);
        updateForm((prev) => {return {...prev, templates: [...prev.templates, {fileId, fileName}]}});
    };

    const removeFile = async(id: string) => {
        setIsLoading(true);
        await deleteAllFiles([id]);
        setIsLoading(false);
        updateForm((prev) => {return {...prev, templates: [...prev.templates.filter((e) => e.fileId !== id)]}});
    }


    const validateForm = (): boolean => {
        // console.log(`${JSON.stringify(form)} \n -- ${JSON.stringify(currPolicy)}`);
        if(JSON.stringify(form) === JSON.stringify(currPolicy)) return false;
        return(
            (form.name.trim().length > 0 && currPolicy?.name !== form.name) ||
            (form.description.trim().length > 0 && currPolicy?.description !== form.description) ||
            JSON.stringify(form.templates) !== JSON.stringify(currPolicy?.templates)
        )
    }

    return(isOpen ?
        <Flex w={'49.5%'} h={'fit-content'} direction={'column'} p={'20px'} background={'white'} box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'} br={'10px'} overflow={'auto'}>
            <TextField id="filled-basic" label={t("Name")} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={form.name} onChange={handleChange}/>
            <TextField id="filled-basic" label={t("Description")} variant="outlined" multiline minRows={5} sx={{margin: '0 0 28px 0'}} name={'description'} value={form.description} onChange={handleChange}/>

            <Table sx={{margin: '22px 0'}}>
                <TableHead sx={{
                    '& span': {fontSize: '16px', fontWeight: 400, letterSpacing: '1.23px', color: colors.table.head, whiteSpace: 'nowrap'},
                    '& .MuiTableCell-head': {
                        borderBottom: '1px solid ' + colors.yellow
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            <Flex ai={"center"}>
                                <span>{t('Templates')}</span>
                                <UploadFileBTN small revDir={revDir} text={t('Upload')} id={form.templates.length + ''} key={form.templates.length + ''} onChange={async (e) => {
                                    await addFile(e.currentTarget.files![0], e.currentTarget.files![0].name)
                                }}/>
                            </Flex>
                        </TableCell>

                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody sx={{
                    '& span': {fontSize: '18px', fontWeight: 200, letterSpacing: '0.57px'},
                }}>
                    {
                        form.templates.map((file, id, arr) => (
                            <TableRow key={file.fileName}>

                                <TableCell align={"left"} width={1000}>
                                    <span>{shortString(file.fileName, 20)}</span>
                                </TableCell>

                                <TableCell width={1}>
                                    <IconButton onClick={e => {
                                        e.stopPropagation();
                                        removeFile(file.fileId)
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                <RadioGroup row value={form.visibility} onChange={handleSelectVisibility}>
                    <FormControlLabel value="PRIVATE" control={<Radio />} label={t("Private") as string} />
                    <FormControlLabel value="PUBLIC" control={<Radio />} label={t("Public") as string} />
                    <FormControlLabel value="RESTRICTED" control={<Radio />} label={t("Restricted") as string} />
                </RadioGroup>

                {/* <ButtonWithBackround
                    width={'154px'}
                    height={'40px'}
                    color={validateForm() ? '#FFA000' : 'gray'}
                    text={'SAVE'}
                    fontColor={'white'}
                    fontSize={'16px'}
                    fontWeight={600}
                    onClick={onSave}
                /> */}
                <CustomButton 
                    variant={'contained'}
                    disabled={!validateForm()}
                    onClick={onSave}
                >{t('SAVE')}</CustomButton>
            </Flex>
            {isLoading && <Spinner />}
        </Flex> : <></>
    )
}