import {IconButton, TableCell, TableRow} from "@mui/material";
import {Typo} from "../../../../../shared/components/Typography";
import {Flex} from "../../../../../shared/components/Layouts";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {TRole} from "../../types";
import {useRoleRow} from "../../hooks/useRoleRow";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";

export const RoleTableRow = ({role}: {role: TRole}) => {
    const {actions} = useRoleRow({role});

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    return(
        <TableRow role="checkbox" tabIndex={-1} key={role.id}>
            <TableCell>
                <Typo>{role.name}</Typo>
            </TableCell>

            <TableCell>
                <Typo>{role.permissions.length}</Typo>
            </TableCell>

            <TableCell>
                <Typo>{parseDate(role.lastUpdateDate, momentLocale, 'D/M/YYYY hh:mm')}</Typo>
            </TableCell>

            <TableCell>
                <Typo>{parseDate(role.createDate, momentLocale, 'D/M/YYYY hh:mm')}</Typo>
            </TableCell>

            <TableCell>
                <Typo>{role.id}</Typo>
            </TableCell>

            <TableCell>
                <Typo color={role.system ? 'green' : 'red'}>{role.system ? 'True' : 'False'}</Typo>
            </TableCell>

            <TableCell>
                <Flex w={'100%'} ai={'center'}>
                    <IconButton onClick={() => actions.handlePreviewRole(role)}>
                        <PreviewIcon fontSize={'medium'} color={'primary'} />
                    </IconButton>
                    <IconButton onClick={() => actions.handleEditRole(role)}>
                        <EditIcon fontSize={'medium'} color={'primary'}/>
                    </IconButton>
                    <IconButton onClick={() => actions.handleDeleteRole(role)}>
                        <DeleteForeverIcon fontSize={'medium'} color={'primary'}/>
                    </IconButton>
                </Flex>
            </TableCell>
        </TableRow>
    )
}