import React, {useMemo} from "react";
import {useParameters} from "../useParameters";
import {useDispatch} from "react-redux";
import {replacePage, replaceRowsPerPage} from "../../utils/pagination";
import {DEFAULT_PAGINATION} from "../../constants";
import {useHistory} from "react-router";

import {ActionCreatorWithPayload} from "@reduxjs/toolkit";

type PropsType = {
    totalSelector: number | undefined,
    setBeforeSearchAction?: ActionCreatorWithPayload<any, string>,
}
export const usePagination = (props: PropsType) => {
    const dispatch = useDispatch();
    const {count, page} = useParameters();
    const total = props.totalSelector || 0;
    const history = useHistory();
    const {location} = window;
    const handleChangePage = (event: unknown, newPage: number) => {
        const newSearch = replacePage(location.search, newPage);
        props.setBeforeSearchAction && dispatch(props.setBeforeSearchAction(newSearch));
        history.push(
            {
                pathname: location.pathname,
                search: newSearch
            }
        );
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearch = replaceRowsPerPage(replacePage(location.search, DEFAULT_PAGINATION.page), +event.target.value);
        props.setBeforeSearchAction && dispatch(props.setBeforeSearchAction(newSearch));
        history.push(
            {
                pathname: location.pathname,
                search: newSearch
            }
        );
    };
    return useMemo(() => ({
        page: page,
        rowsPerPage: count,
        handleChangeRowsPerPage,
        handleChangePage,
        total,
    })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [count, page, total]);
}
