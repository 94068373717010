import React, {FC, useCallback, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import debounce from "lodash.debounce";
import {useDrop} from "react-dnd";
import * as S from "../../../styled";
import {Autocomplete, Button, Checkbox, CircularProgress, TextField} from "@mui/material";
import {TypographyBody2} from "../../../../../../../Inputs/styled";
import {CommonAvatar} from "../../../../../../../Basic/CommonAvatar";
import {ReactComponent as GroupIcon} from "../../../../../../../../../shared/images/documents/group-icon.svg";
import {ItemTypes, TEditorActor, TEditorStageApprover} from "../../../../../../types";
import {ApproverGroupEditor} from "../approverGroup";
import {TForm} from "../index";
import {getMsFromDays} from "../../../../../../../../utils/dateTools";

interface ApproversBlockViewProps {
    form: TForm;
    setForm:  (form: TForm) => void;
    employees: TEditorActor[];
    isLoadingEmployees: boolean;
    getEmployees: (value: string) => void;
    handleOpenAddGroupMessage: (data: {approverId?: string, groupId?: string, oldMessage: string}) => void;
    isApproversCustomOrder: boolean;
    setIsApproversCustomOrder: (isOrdered: boolean) => void;
    isStaff?: boolean;
}

export const ApproversBlockViewEditor: FC<ApproversBlockViewProps> = (props) => {
    const {
        form,
        setForm,
        employees,
        isLoadingEmployees,
        getEmployees,
        handleOpenAddGroupMessage,
        isApproversCustomOrder,
        setIsApproversCustomOrder,
        isStaff
    } = props;
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const [newApproverInput, setNewApproverInput] = useState<string>('');
    const [newApprover, setNewApprover] = useState<TEditorActor | null>(null);
    const [apprGroupIds, setApprGroupIds] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);

    const addedApprovers: string[] = [];
    form.approvers.forEach(appr => appr.groups.forEach(gr => gr.actors.forEach(a => addedApprovers.push(a.email))))

    const isOk = () => {
        if (newApprover) return true;
        else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return  re.test(newApproverInput);
        }
    }

    useEffect(() => {
        const groupIds: string[] = [];
        form.approvers.forEach(appr =>  appr.groups.forEach(gr => (gr.actors.length > 1 || gr.name) && groupIds.push(gr.id ?? '')))

        setApprGroupIds(groupIds)
        // eslint-disable-next-line
    }, [])
//
    const handleAdd = () => {

        const newApprovers = [
            ...form.approvers,
            {
                id: `index ${form.approvers.length + 1}`,
                order: isApproversCustomOrder ? form.approvers.length + 1 :  0,
                eta: getMsFromDays(7),
                groups: [{
                    id: `index group ${form.approvers.length + 1}`,
                    actors: [{email: newApproverInput, firstName: '', lastName: ''}],
                    name: '',
                    isRequired: true,
                    message: '',
                }]
            }
        ] satisfies TEditorStageApprover[];

        if (isOk()) {
            setForm({
                ...form,
                approvers: newApprovers
            });
            setNewApproverInput("");
            setNewApprover(null);
        } else {
            setError('Invalid email');
        }
    }

    const handleAddGroup = () => {

        const groupId =`index group ${form.approvers.length + 1}`

        const newApprovers = [...form.approvers, {
            id: `index ${form.approvers.length + 1}`,
            order: isApproversCustomOrder ? form.approvers.length + 1 :  0,
            eta: getMsFromDays(7),
            groups: [{
                id: groupId,
                actors: [],
                name: '',
                isRequired: true,
                message: '',
            }]
        }] satisfies TEditorStageApprover[]

        setForm({...form, approvers: newApprovers});
        setApprGroupIds(prev => prev && [...prev, groupId]);
    };

    const handleAddGroupActor = (approverId: string, groupId: string, actor: TEditorActor ) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, actors: [...gr.actors, actor]}) : gr)
            }) : appr)});
    }

    const handleDeleteGroupActor = (approverId: string, groupId: string, email: string) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({
                    ...gr,
                    actors: gr.actors.filter(actor => actor.email !== email)
                }) : gr)
            }) : appr)});
    }

    const handleToggleOrdered = (isOrdered: boolean) => {
        setForm({...form, approvers: form.approvers.map((appr, index) => ({
                ...appr,
                order: isOrdered ? index + 1 : 0,
            }))});

        setIsApproversCustomOrder(isOrdered);
    }

    const toggleIsRequired = (approverId: string, groupId: string ) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, isRequired: !gr.isRequired}) : gr)
            }) : appr)});
    }



    const handleDeleteGroup = (approveId: string) => {
        setForm({
            ...form,
            approvers: form.approvers.filter(appr => appr.id !== approveId),
        });
    }

    const handleSetNameGroup = (approveId: string, groupId: string, name: string) => {
        setForm({
            ...form,
            approvers: form.approvers.map(appr => appr.id === approveId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, name: name}) : gr)
            }) : appr)
        });
    }

    //eslint-disable-next-line
    useEffect(debounce(() => {

        getEmployees(newApproverInput);

        //eslint-disable-next-line
    }, 500), [newApproverInput]);


    useEffect(() => {
        error && setError(null);

        //eslint-disable-next-line
    }, [newApproverInput]);

    //DND APPROVERS
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setApprovers = useCallback(
        (cols: TEditorStageApprover[]) => {
            setForm({
                ...form,
                approvers: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TEditorStageApprover, index: number} | null = useCallback(
        (id: string) => {
            const column = form.approvers.find(e => e.id === id);

            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.approvers.indexOf(column)
            } : null;
        },
        [form.approvers],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.approvers));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setApprovers(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.approvers, setApprovers],
    )



    return (
        <>
            <S.FlexBox>
                <Checkbox disabled={isStaff} checked={isApproversCustomOrder} onChange={() => handleToggleOrdered(!isApproversCustomOrder)} />
                <TypographyBody2>{t('Custom approval order')}</TypographyBody2>
            </S.FlexBox>
            {!isStaff &&
                <S.AutocompleteBox>
                    <Autocomplete
                        disabled={isStaff}
                        freeSolo
                        options={employees.filter(emp => !addedApprovers.find(act => act === emp.email))}
                        filterOptions={(options, state) => options
                            .filter(emp => emp.firstName.toLowerCase().includes(newApproverInput)
                                || emp.lastName.toLowerCase().includes(newApproverInput)
                                || emp.email.toLowerCase().includes(newApproverInput))}
                        getOptionLabel={(e) => (e as TEditorActor).email}
                        renderOption={(props, option, state) => (
                            <li {...props}>
                                <S.ActorRowBox>
                                    <CommonAvatar name={`${option.firstName ?? option.email} ${option.lastName ?? option.email}`} size={"d40"}/>
                                    <S.ActorDataContainer>
                                        <S.TypographyName>{`${option.firstName} ${option.lastName}`}</S.TypographyName>
                                        <S.TypographyEmail>{`${option.email}`}</S.TypographyEmail>
                                    </S.ActorDataContainer>
                                </S.ActorRowBox>
                                <S.ActorRowDivider />
                            </li>
                        )}
                        sx={{width: '572px', '& label': {top: '0px !important'}}}
                        value={newApprover}
                        onChange={(event, value) => value && setNewApprover(value as TEditorActor)}
                        inputValue={newApproverInput}
                        onInputChange={(e, value) => setNewApproverInput(value)}

                        // onInputChange={debounce((e, value) => {getEmployees(value)}, 500)}
                        loading={isLoadingEmployees}
                        renderInput={(params) =>
                            <TextField {...params} size={'small'} label={t('Email or name')} error={Boolean(error)} helperText={error ?? ''}
                                       InputProps={{ ...params.InputProps, endAdornment: (
                                               <>
                                                   { isLoadingEmployees ? <CircularProgress color="inherit" size={20}/> : null}
                                                   {params.InputProps.endAdornment}
                                               </>),
                                       }}
                            />}
                    />
                    <Button size={'small'} sx={{marginLeft: '16px'}} onClick={handleAdd}>{t('Add')}</Button>
                </S.AutocompleteBox>
            }


            <div ref={!isStaff ? drop : undefined} style={{width: '100%'}}>
                {form.approvers.map((approver, index) => (
                    <ApproverGroupEditor
                        key={approver.id}
                        index={index}
                        isApproverCustomOrder={isApproversCustomOrder}
                        approver={approver}
                        addedApprovers={addedApprovers}
                        apprGroupIds={apprGroupIds}
                        handleDeleteGroup={handleDeleteGroup}
                        handleSetNameGroup={handleSetNameGroup}
                        toggleIsRequired={toggleIsRequired}
                        handleAddGroupActor={handleAddGroupActor}
                        handleDeleteGroupActor={!isStaff ? handleDeleteGroupActor : () => null}
                        handleOpenAddGroupMessage={handleOpenAddGroupMessage}

                        moveColumn={moveColumn}
                        findColumn={findColumn}
                        isLoadingEmployees={isLoadingEmployees}
                        employees={employees}
                        getEmployees={getEmployees}
                        isStaff={isStaff}
                    />))}

            </div>

            {!isStaff &&
                <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}} startIcon={<GroupIcon/>}
                        onClick={handleAddGroup}>{t('Create approver group')}</Button>
            }
        </>
    )
}