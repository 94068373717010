import {FlexEnd} from "../../../../shared/components/Layouts";
import React, {FC} from "react";
import {UserInfo} from "./UserInfo";
import {useMainTranslation} from "../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

type PropsType = {
    reference: any;
}
export const TopBarSettings: FC<PropsType> = (props: PropsType) => {
    const {revDir} = useMainTranslation();

    return (
                <FlexEnd>
                    <UserInfo reference={props.reference} revDir={revDir}/>
                </FlexEnd>
    )
}
