import {FC, ReactNode} from "react";
import {Flex} from "../Layouts"
import {useMedia} from "../../hooks/useMedia";

type TProps = {
    children?: ReactNode,
    highZIndex?: boolean
}
export const LeftSecondMenuColumn: FC<TProps> = ({highZIndex, children}: TProps) => {

    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    const {matches_1440Up, matches_1680Up} = useMedia()

    return (
        <Flex direction={"column"} overflow={"auto"} ai={"center"}
              h={'100%'}  zi={highZIndex ? '1001' : undefined}
              w={matches_1680Up ? '400px' : matches_1440Up ? '300px' : '200px'}
              minw={matches_1680Up ? '400px' : matches_1440Up ? '300px' : '200px'}
        >
            {children}
        </Flex>
    )
}