import {Flex} from "../../../../../../shared/components/Layouts";
import {selectedFramework, selectFramework} from "../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {frameworkType} from "../../../types";
import {Avatar, Divider, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {ROOT_BUILDER} from "../../../constants";
import React from "react";
import {GetControls} from "../../../store/actions";

export const FrameworkCard = ({framework} : {framework: frameworkType}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selected = useSelector(selectedFramework);

    const select = () => {
        dispatch(selectFramework(framework.id));
        dispatch(GetControls({frameworkId: framework.id}));
        history.push(ROOT_BUILDER + `/framework/${framework.id}`);
    }

    return(
        <Flex
            key={framework.id}
            minh={'90px'}
            w={'100%'}
            h={'90px'}
            border={selected === framework.id ? '1px solid #14CBFD' : ''}
            br={'12px'}
            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
            ai={'center'}
            jc={'space-between'}
            p={'18px 25px'}
            m={'0 0 12px 0'}
            background={'white'}
            onClick={select}
        >
            <Flex br={'50%'} w={'20%'}>
                <Avatar src={(framework.logo && framework.logo.length > 0) ? framework.logo : undefined} >
                    {(framework.logo && framework.logo.length > 0) ? '' : framework.name.split('')[0]}
                </Avatar>
            </Flex>
            <Flex direction={'column'} jc={'space-between'} w={'80%'}>
                <Flex w={'100%'} ai={'center'} jc={'space-between'}>
                    <Typography fontWeight={300} fontSize={'14px'} sx={{flexGrow: 1, ml: '6px', mr: '6px'}}>{framework.name}</Typography>
                    <Typography fontWeight={300} fontSize={'14px'}>{framework.id}</Typography>
                </Flex>

                <Flex m={'15px 0 0 0'} w={'100%'} ai={'center'} jc={'space-between'}>
                    <Typography fontWeight={300} fontSize={'14px'}>Controls {framework.controls}</Typography>
                    <Typography fontWeight={300} fontSize={'14px'}>Evidences {framework.evidences}</Typography>
                </Flex>
            </Flex>

            <Divider flexItem style={{backgroundColor: '#FAC000', margin: '16px 0 0 0'}} />

        </Flex>
    )
}