import {TLanguage} from "./hooks/useMainTranslationHooks/types";

export const LABEL = window.config?.REACT_APP_LABEL || 'Regulait';
export const IS_REGULAIT = LABEL === 'Regulait';

export const PATH_SERVER = window.config?.REACT_APP_PATH_SERVER || '';
export const PATH_LOGIN = '/login';
export const LINK_EMPTY_LOGO = 'http://defaultapp.com/img';
export const TOKEN = 'token';
export const RESERVED = 'RESERVED';
export const isAuthComplete = 'bb7c812e-2ea6-11ec-8d3d-0242ac130003';
export const VERSION = window.config?.REACT_APP_VERSION || '';

export const SHOW_CHANGE_LANGUAGE_IN_TOPBAR = false;

export const SUPPORTED_LANGUAGES: TLanguage[] = [
  {
    name: 'English',
    code: 'en',
    countryCode: 'US',
    dir: 'ltr',
    locale: 'enUS',
    momentLocale: 'en-au'
  },
  ...(IS_REGULAIT ? [{
    name: 'עברית',
    code: 'he',
    countryCode: 'IL',
    dir: 'rtl',
    locale: 'heIL',
    momentLocale: 'he'
  }] : []) as TLanguage[]
];

export const LOAD_FILTERS_VALUES_ON_MOUNT = true;
export const DEBOUNCE_TIMEOUT = 600;
export const DEFAULT_PAGINATION = { page: 0, rowsPerPage: 10 };
export const DEFAULT_PAGINATION_LEFT = { page: 0, rowsPerPage: 5 };

export const PATH_ARI = window.config?.REACT_APP_URI || '';
// export const SHOW_SKIP_BUTTON_IN_MFA = PATH_ARI === 'http://localhost:3000' || PATH_ARI === 'https://dev.admin.regulait.com';
export const SHOW_SKIP_BUTTON_IN_MFA = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';

//Shared cookies
export const MAX_LIVE_COOKIE = 1000 * 60 * 60 * 24;
export const MAIN_DATA_COOKIE_NAME = 'UserData';

// export const SHOW_EXTENDED_ERRORS = PATH_ARI === 'http://localhost:3000' || PATH_ARI === 'https://dev.admin.regulait.com';
export const SHOW_EXTENDED_ERRORS = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';

export const ACCEPT_IMAGE_PROP = 'image/*';
export const ACCEPT_IMAGE_PROP_WITHOUT_SVG = 'image/png, image/jpeg';
