import {FC} from 'react';
import { Flex } from '../../../shared/components/Layouts';
import {Routes} from "./routes";


export const NewApplications:FC = () => {
    return (
        <Flex w={'100%'} h={'100%'} p={"25px"}>
            <Routes />
        </Flex>
    )
}