import {useDispatch, useSelector} from "react-redux";
import {PaginationInput} from "../../../../../shared/types";
import {useEffect, useState} from "react";
import {cleanUp, extendedOrganization, loading} from "../../store/slice";
import {GetShortOrganizations} from "../../store/actions";


export const useOrganizations = () => {
    const dispatch = useDispatch();

    const getData = (pageInfo: PaginationInput) => {
        dispatch(GetShortOrganizations({pageInfo}));
    }

    useEffect(() => {
        return function clean(){
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    const [tab, setTab] = useState<'collabs' | 'workspaces'>('collabs');

    return{
        getData,
        isExtendedOrgPresent: useSelector(extendedOrganization) !== null,
        isLoadingExtended: useSelector(loading).isLoadingExtendedOrg,
        tab,
        setTab
    }
}