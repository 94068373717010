import {useDispatch, useSelector} from "react-redux";
import {setSelectedDocumentAction, templates} from "../../store/slice";
import {useState} from "react";
import {
    createTemplateAction,
    deleteTemplateByIdAction,
    getTemplateByIdAction,
    getTemplatesAction,
    updateTemplateByIdAction
} from "../../store/actions";
import {NewDocumentInput, NewDocumentModel} from "../../../../../shared/GQLTypes";


export const useGallery = () => {
    const dispatch = useDispatch();
    const {
        documents,
        isLoading,
        selectedDocument
    } = useSelector(templates)

    const [search, setSearch] = useState<string>('');

    const handleSetSearch = (value: string = '') => {setSearch(value || '')}

    const getTemplatesList = () => dispatch(getTemplatesAction({}));
    const getTemplateById = (id: string) => dispatch(getTemplateByIdAction({id}));
    const createTemplate = (id: string, name: string) => dispatch(createTemplateAction({id, name}));
    const updateTemplate = (id: string, document: NewDocumentInput) => dispatch(updateTemplateByIdAction({id, document}));
    const deleteTemplate = (id: string) => dispatch(deleteTemplateByIdAction({id}));

    const setSelectedDocument = (document: NewDocumentModel) => dispatch(setSelectedDocumentAction(document));
    const closeEditor = () => dispatch(setSelectedDocumentAction(null));

    return {
        selectedDocument: JSON.parse(JSON.stringify(selectedDocument)) as NewDocumentModel | null,
        documents: documents
            .filter(e => e.id.toLowerCase().includes(search.toLowerCase()) || e.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                if (a.id < b.id ) return -1;
                if (a.id > b.id ) return 1;
                return 0;
            }),
        isLoading,

        search,
        handleSetSearch,

        getTemplatesList,
        getTemplateById,
        createTemplate,
        updateTemplate,
        deleteTemplate,

        setSelectedDocument,
        closeEditor,
    }
}
