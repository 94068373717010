import {useEffect} from "react";
import {useDispatch} from "react-redux";
import { cleanUp } from "../../store/slice";
import {GetAdminRoles} from "../../store/actions";

export const useMainAccessManagement = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAdminRoles());

        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);
}