
import {createAsyncThunk} from "@reduxjs/toolkit";
import {PaginationInput} from "../../../../shared/types";
import {
    createMultiLicense,
    createOneLicense,
    deleteLicense,
    getExtendedLicense,
    getShortLicenses,
    releaseLicense
} from "../api";
import {getQuickStarts} from "../../organizations/api";
import {addInfoSnack, addSuccessfulSnack } from "../../../BarsENV/snack/store/slice";
import {creatableLicenseType, licenseType} from "../types";

export const GetShortLicenses = createAsyncThunk(
    'Licenses/getShortLicenses',
    async (data: {pageInfo: PaginationInput}) => {
        return await getShortLicenses(data.pageInfo);
    }
);

//getExtendedLicense
export const GetExtendedLicense = createAsyncThunk(
    'Licenses/getExtendedLicense',
    async (data: {id: string}) => {
        return await getExtendedLicense(data.id);
    }
);

//getQuickStarts
export const GetQuickStarts = createAsyncThunk(
    'Licenses/getQuickStarts',
    async () => {
        return await getQuickStarts();
    }
);

//releaseLicense
export const ReleaseLicense = createAsyncThunk(
    'Licenses/releaseLicense',
    async (data: {id: string, email: string, note: string}, {dispatch}) => {
        const res = await releaseLicense(data.id, data.note, data.email); //id
        dispatch(addSuccessfulSnack(res));
        return res;
    }
);

//deleteLicense
export const DeleteLicense = createAsyncThunk(
    'Licenses/deleteLicense',
    async (data: {id: string}, {dispatch}) => {
        const res = await deleteLicense(data.id); //mes
        dispatch(addInfoSnack(res));
        return data.id;
    }
);

//createOneLicense
export const CreateAndReleaseOneLicense = createAsyncThunk(
    'Licenses/createAndReleaseOneLicense',
    async (data: {license: creatableLicenseType, email: string, note: string}, {dispatch}) => {
        const requestLicense: licenseType = {
            ...data.license,
            workspaces: data.license.workspaces.map(e => {
                return {
                    name: e.name,
                    collaborators: e.collaboratorsId.split(', ').map((c) => {return {firstName: 'Reload the license', lastName: '', email: 'Reload the license', publicId: c}})
                }
            }),
            id: '',
            issued: true,
            notes: data.note
        }
        const res = await createOneLicense(requestLicense, data.email); //id
        dispatch(addInfoSnack(`License created with id: ${res.id}`));
        return {...requestLicense, id: res.id, collabs: res.collabs};
    }
);

//createOneANDNOTLicense
export const CreateOneLicense = createAsyncThunk(
    'Licenses/CreateOneLicense',
    async (data: {license: creatableLicenseType}, {dispatch}) => {
        const requestLicense: licenseType = {
            ...data.license,
            workspaces: data.license.workspaces.map(e => {
                return {
                    name: e.name,
                    collaborators: e.collaboratorsId.split(', ').map((c) => {return {firstName: 'Reload the license', lastName: '', email: 'Reload the license', publicId: c}})
                }
            }),
            id: '',
            issued: false,
            issuedDetails: null
        }
        const res = await createOneLicense(requestLicense); //id
        dispatch(addInfoSnack(`License created with id: ${res.id}`));
        return {...requestLicense, id: res.id, collabs: res.collabs};
    }
);

//createMultiLicense with release
export const CreateMultiLicenseWithRelease = createAsyncThunk(
    'Licenses/CreateMultiLicenseWithRelease',
    async (data: {license: creatableLicenseType, counter: number, note: string}, {dispatch}) => {
        const requestLicense: licenseType = {
            ...data.license,
            workspaces: data.license.workspaces.map(e => {
                return {
                    name: e.name,
                    collaborators: e.collaboratorsId.split(', ').map((c) => {return {firstName: 'Reload the license', lastName: '', email: 'Reload the license', publicId: c}})
                }
            }),
            id: '',
            issued: true,
            notes: data.note
        }
        const res = await createMultiLicense(requestLicense, data.counter.toString()); //ids
        dispatch(GetShortLicenses({pageInfo: {page: 0, count: 5, filters: {}}}));
        return res;
    }
);

export const CreateMultiLicense = createAsyncThunk(
    'Licenses/CreateMultiLicense',
    async (data: {license: creatableLicenseType, counter: number}, {dispatch}) => {
        const requestLicense: licenseType = {
            ...data.license,
            workspaces: data.license.workspaces.map(e => {
                return {
                    name: e.name,
                    collaborators: e.collaboratorsId.split(', ').map((c) => {return {firstName: '', lastName: '', email: '', publicId: c}})
                }
            }),
            id: '',
            issued: false,
            issuedDetails: null
        }
        const res = await createMultiLicense(requestLicense, data.counter.toString()); //ids
        dispatch(GetShortLicenses({pageInfo: {page: 0, count: 5, filters: {}}}));
        return res;
    }
);