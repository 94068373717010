import {useDispatch, useSelector} from "react-redux";
import {hideCreateOneNoReleaseDialog, dialogs, loading, creatableLicense} from "../../../store/slice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import {Button, DialogContentText} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {CreateOneLicense} from "../../../store/actions";

export const CreateOneLicenseDialog = () => {
    const dispatch = useDispatch();
    const license = useSelector(creatableLicense);
    const {createOneNoRelease: {isOpen}} = useSelector(dialogs);
    const {isLoadingCreateOne} = useSelector(loading);

    const handleClose = () => dispatch(hideCreateOneNoReleaseDialog());

    const handleSendForm = () => {
        if(license){
            dispatch(CreateOneLicense({license}));
        }
    }

    const isOk = () => license !== null;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Create one</DialogTitle>

            <DialogContent>
                <DialogContentText>Create without release?</DialogContentText>
            </DialogContent>

            {isLoadingCreateOne ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSendForm} disabled={!isOk()} color={'success'}>Create</Button>
                </DialogActions>
            }
        </Dialog>
    )
}