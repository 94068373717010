import {LeftSideBarItem} from "./types";

import {ReactComponent as CustomersIcon} from "../../../shared/images/Identities.svg";
import {ReactComponent as ApplicationIcon} from "../../../shared/images/Application.svg";
import {ReactComponent as ComplianceIcon} from "../../../shared/images/Compliance.svg";
import {ReactComponent as LicenseIcon} from "../../../shared/images/license.svg";
import {ReactComponent as OrganizationIcon} from "../../../shared/images/Organization.svg";
import {ReactComponent as AccessManagementIcon} from "../../../shared/images/accessManagement.svg";
import {ReactComponent as AssetSettingsIcon} from "../../../shared/images/assetSettings.svg";
import {ReactComponent as VicaTasksIcon} from "../../../shared/images/task-svgrepo-com.svg";
import {ReactComponent as EmailTemplateIcon} from "../../../shared/images/emailTemplate.svg";


import {
    ASSETS_SETTINGS_VARIANTS, EMAIL_TEMPLATES_PATH,
    PATH_ACCOUNTS,
    PATH_LICENSE,
    PATH_LOCAL_LOGIN,
    PATH_ORGANIZATIONS,
    VICA_TASKS
} from "../shell/constants";
import {PATH_LOCAL_2FA, ROOT_BUILDER} from "../../Components/builder/constants";
import {LOCAL_ACCESS_MANAGEMENT_PATH} from "../../Components/AccessManagement/constants";
import {NEW_APPLICATIONS_ROOT} from "../../Components/newApplications/constants";

//MENU SETTINGS
export const rootMenu = new LeftSideBarItem('Root', undefined, [], undefined);
const accounts = new LeftSideBarItem('Accounts', PATH_ACCOUNTS, [],{inactive: CustomersIcon});
const organizations = new LeftSideBarItem('Organizations', PATH_ORGANIZATIONS, [],{inactive: OrganizationIcon});
const license = new LeftSideBarItem('License', PATH_LICENSE, [],{inactive: LicenseIcon});
const apps = new LeftSideBarItem('Applications', NEW_APPLICATIONS_ROOT, [], {inactive: ApplicationIcon});
const compliance = new LeftSideBarItem('Builder', ROOT_BUILDER, [], {inactive: ComplianceIcon});
const accessManagement = new LeftSideBarItem('Access management', LOCAL_ACCESS_MANAGEMENT_PATH, [], {inactive: AccessManagementIcon});
const assetsSettings = new LeftSideBarItem('Assets settings', ASSETS_SETTINGS_VARIANTS, [], {inactive: AssetSettingsIcon});
const vicaTasks = new LeftSideBarItem('Vica tasks', VICA_TASKS, [], {inactive: VicaTasksIcon});
const templates = new LeftSideBarItem('Templates', EMAIL_TEMPLATES_PATH, [], {inactive: EmailTemplateIcon});
// const test = new LeftSideBarItem('TEST', '/test', [], {inactive: AccessManagementIcon});


rootMenu.addItems([
    accounts,
    accessManagement,
    organizations,
    license,
    apps,
    compliance,
    assetsSettings,
    vicaTasks,
    templates
]);

export const exception = new LeftSideBarItem('', '');
export const menu = rootMenu.getItems();

export const pathNameExceptionsMap = new Map<string, LeftSideBarItem>(
    []
);

export const unprotectedTopBarPaths: (string | { (pathname: string): boolean })[]  = [];

export const hideProtectedTopBarPaths: (string | { (pathname: string): boolean })[] = [
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_2FA,
    ...unprotectedTopBarPaths
];


export const hideLeftSideBarWhenPaths: (string | { (pathname: string): boolean })[] = [
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_2FA,
];

export const unprotectedPaths = [
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_2FA,
    ...unprotectedTopBarPaths
];

export const TEXT_MENU = '#c0c0c0';
export const WHITE_TEXT = '#E8E8E8';
export const ACTIVE_MENU = '#14CBFD';

export const WIDTH_EXPANDED = '9.52%';
export const WIDTH_COMPACTED =  '60px'