import {useParams, useLocation} from "react-router-dom";
import {useMemo} from "react";
import {readFilter, readPage, readRowsPerPage} from "../../utils/pagination";

export const useParameters = () => {
  const {id} = useParams<{ id: string }>();
  const {search} = useLocation();
  const page = Number(readPage(search));
  const count = Number(readRowsPerPage(search));
  const filter = readFilter(search);
  return useMemo(() => ({
    id, page, count, filter
  })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , [id, page, count, JSON.stringify(filter)]);
}


export const useParameters2 = () => {
  // const {id} = useParams<{ id: string }>();
  const {search} = useLocation();
  const page = Number(readPage(search));
  const count = Number(readRowsPerPage(search));
  const filter = readFilter(search);
  return useMemo(() => ({
        page, count, filter
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , [page, count, JSON.stringify(filter)]);
}
