import {useDispatch, useSelector} from "react-redux";
import {creatableLicense, wizards, loading, updateCreatableLicense} from "../../store/slice";
import {creatableLicenseType, licenseTabs} from "../../types";
import {Flex} from "../../../../../shared/components/Layouts";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {Typo} from "../../../../../shared/components/Typography";
import {
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField
} from "@mui/material";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {CustomInput} from "../../../../../shared/components/Inputs";
import {Autocomplete} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {organizationCollabsRuleConfig} from "../../../organizations/types";

export const CreateLicenseTabs = ({tab}: {tab: licenseTabs}) => {
    const dispatch = useDispatch();
    const license = useSelector(creatableLicense);
    const wizs = useSelector(wizards);
    const {isLoadingWizards} = useSelector(loading);

    //WIZARDS SETTINGS

    const handleAddAll = () => {
        if(license){
            const newLicense:creatableLicenseType = {...license, wizardId: wizs.map(e => e.id) };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleToggleWiz = (id: string) => {
        if(license){
            const newLicense:creatableLicenseType = license.wizardId.some((e) => e === id) ? {...license, wizardId: license.wizardId.filter(e => e !== id)} : {...license, wizardId: [...license.wizardId, id]};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    //WORKSPACES SETTINGS

    const handleUpdateWorkspaceName = (id: number, name: string) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, workspaces: [...license.workspaces.map((e, wId) => wId === id ? {...e, name} : e)]};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleUpdateWorkspaceCollabs = (id: number, collaboratorsId: string) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, workspaces: [...license.workspaces.map((e, wId) => wId === id ? {...e, collaboratorsId} : e)]};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleAddWorkspaceConfig = () => {
        if(license){
            const newLicense:creatableLicenseType = {...license, workspaces: [...license.workspaces, {name: `workspace-${license.workspaces.length}`, collaboratorsId: ''}]};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleDeleteWorkspaceConfig = (id: number) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, workspaces: license.workspaces.filter((e, wId) => wId !== id)};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    //COLLABS RULES

    const deleteRule = (id: number) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, collaboratorRules: license.collaboratorRules.filter((e, rId) => rId !== id )};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleAddRule = () => {
        if(license){
            const newLicense:creatableLicenseType = {...license, collaboratorRules: [...license.collaboratorRules, {type: 'email', email: `regulait.com-${license.collaboratorRules.length}`, count: 0}]};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleUpdateCounter = (id: number, count: number) => {
        if(license){
            const newRules = JSON.parse(JSON.stringify(license.collaboratorRules)).map((e: organizationCollabsRuleConfig, rId: number) => {
                if(rId === id){
                    const availableCollabs = license.availableCollaborators ?? 0;
                    const totalCounters = license.collaboratorRules.map(t => t.count).reduce((prevC, nextC) => prevC + nextC, 0);
                    if(availableCollabs > totalCounters && count >= 0){
                        //if any smaller then total -> making update
                        console.log(`${JSON.stringify(e)} -> `, count);
                        e.count = count;
                    }else if(availableCollabs <= totalCounters && count < e.count && count >= 0){
                        //if any equals or smaller then total but user decrease one of totals - making update
                        console.log(`${JSON.stringify(e)} -> `, count);
                        e.count = count;
                    }
                    return e;
                }
                return e;
            });

            const newLicense:creatableLicenseType = {...license, collaboratorRules: newRules};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleUpdateType = (id: number, type: string) => {
        if(license){
            const newRules = license.collaboratorRules.map((e, rId) => rId === id ? {...e, type} : e);
            const newLicense:creatableLicenseType = {...license, collaboratorRules: newRules};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleUpdateEmail = (id: number, email: string) => {
        if(license){
            const newRules = license.collaboratorRules.map((e, rId) => rId === id ? {...e, email} : e);
            const newLicense:creatableLicenseType = {...license, collaboratorRules: newRules};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    if(!license) return  <></>;
    switch(tab){
        case "wizards": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {isLoadingWizards && <LocalSpinner />}
                    {!isLoadingWizards && wizs.length > 0 && <CustomButton sx={{width: '50%'}} onClick={handleAddAll} fullWidth>Select All</CustomButton>}

                    <List sx={{ width: '50%', maxWidth: 360 }}>
                        {!isLoadingWizards && wizs.length > 0 && license && wizs.map((e) => {
                            const labelId = `checkbox-list-label-${e.id}`;
                            return(
                                <ListItem key={e.id} disablePadding>
                                    <ListItemButton role={undefined} onClick={() => handleToggleWiz(e.id)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={license?.wizardId.some(w => w === e.id)}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={e.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Flex>
            )
        }
        case "workspaces": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {license.workspaces.map((e, id) => {
                        return(
                            <>
                                <Flex direction={'column'} key={id} m={'15px 0 0 0'} box_shadow={'0 0 5px rgba(0, 0, 0, 0.25)'} p={'5px'} br={'5px'}>
                                    <CustomInput 
                                        value={e.name}
                                        label={'name'}
                                        onChange={(event) => {handleUpdateWorkspaceName(id, event.target.value)}}
                                    />
                                    <CustomInput
                                        multiline
                                        sx={{margin: '10px 0 0 0'}}
                                        value={e.collaboratorsId}
                                        label={'Collaborators ids by csv'}
                                        onChange={(event) => {handleUpdateWorkspaceCollabs(id, event.target.value)}}
                                    />

                                    <CustomButton onClick={() => handleDeleteWorkspaceConfig(id)} size={'small'} color={'error'} sx={{margin: '10px 0 0 0'}}>
                                        Delete workspace config
                                    </CustomButton>
                                </Flex>
                            </>
                        )
                    })}
                    <CustomButton onClick={handleAddWorkspaceConfig} size={'small'} color={'success'} sx={{margin: '10px 0 0 0'}}>
                        Add workspace config
                    </CustomButton>
                </Flex>
            )
        }
        case "collaboratorsRules": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    <Flex w={'100%'} direction={'column'} ai={'center'} m={'5px 0 0 0'}>
                        <Typo>available collaborators counter: {license.availableCollaborators ?? 0}</Typo>
                        {license.collaboratorRules.map((e, id) => {
                            return(
                                <Flex w={'100%'} jc={'space-between'} box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'} p={'5px'} m={'10px 0 0 0'} br={'5px'}>
                                    <Flex w={'90%'} jc={'space-between'}>
                                        <Autocomplete
                                            sx={{width: '30%'}}
                                            autoComplete
                                            id="combo-box-demo"
                                            options={['email', 'any']}
                                            disabled={true}
                                            value={e.type}
                                            onChange={(event, value) => {value && handleUpdateType(id, value)}}
                                            renderInput={(params) => <TextField {...params} label="Type" />}
                                        />

                                        <Flex w={'45%'}>
                                            <CustomInput
                                                disabled={e.type === 'any'}
                                                value={e.email}
                                                label={'Email'}
                                                onChange={(event) => {handleUpdateEmail(id, event.target.value as string)}}
                                            />
                                        </Flex>

                                        <Flex w={'20%'}>
                                            <CustomInput
                                                type={'number'}
                                                value={e.count}
                                                label={'Count'}
                                                onChange={(event) => {handleUpdateCounter(id, parseInt(event.target.value))}}
                                            />
                                        </Flex>
                                    </Flex>

                                    {e.type !== 'any' &&
                                    <IconButton onClick={() => {deleteRule(id)}}>
                                        <DeleteIcon />
                                    </IconButton>
                                    }
                                </Flex>
                            )
                        })}
                    </Flex>

                    <Flex w={'100%'}>
                        <IconButton onClick={handleAddRule}>
                            <AddCircleIcon />
                        </IconButton>
                    </Flex>
                </Flex>
            )
        }
        default: return <div>Tab not found!</div>;
    }
}