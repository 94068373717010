import {useDispatch, useSelector} from "react-redux";
import {hideCreateMultiReleaseDialog, dialogs, loading, creatableLicense} from "../../../store/slice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {Typo} from "../../../../../../shared/components/Typography";
import {useState} from "react";
import {CustomInput} from "../../../../../../shared/components/Inputs";
import {copyTextToClipBoard} from "../../../../../AuthWorkspaceAndCookies/newWorkspaces/helpers";
import {CreateMultiLicenseWithRelease} from "../../../store/actions";
import {Flex} from "../../../../../../shared/components/Layouts";

export const CreateMultiANDReleaseLicense = () => {
    const dispatch = useDispatch();
    const license = useSelector(creatableLicense);
    const {createMultiRelease: {isOpen}} = useSelector(dialogs);
    const {isLoadingCreateAndReleaseMulti: {isLoading, result}} = useSelector(loading);
    const [counter, setCounter] = useState<number>(1);
    const [note, setNote] = useState<string>('');

    const handleClose = () => dispatch(hideCreateMultiReleaseDialog());

    const handleSendForm = () => {
        if(license){
            dispatch(CreateMultiLicenseWithRelease({license, counter, note}));
        }
    }

    const isOk = () => license !== null && note.trim().length > 0 && counter > 0;

    return(
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'xs'}>
            <DialogTitle>Create multi and Release?</DialogTitle>

            <DialogContent>
                <CustomInput
                    sx={{margin: '15px 0 0 0'}}
                    label={'License count:'}
                    value={counter}
                    type={'number'}
                    onChange={(e) => {setCounter(parseInt(e.target.value))}}
                />

                <CustomInput
                    sx={{margin: '15px 0 0 0'}}
                    multiline
                    minRows={5}
                    value={note}
                    label={'Note'}
                    onChange={(e) => {setNote(e.target.value)}}
                />
            </DialogContent>

            {result.length > 0 &&
            <>
                <Flex w={'100%'} ai={'center'} p={'0 25px'}>
                    <Typo
                        cursor={'pointer'}
                        onClick={() => copyTextToClipBoard(result.join(', '), 'License ids copied to clipboard!')}
                    >
                        Result: {result.join(', ')}
                    </Typo>
                </Flex>
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Close</Button>
                </DialogActions>
            </>
            }

            {isLoading ?
                <LocalSpinner />
                :
                !result.length &&
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSendForm} disabled={!isOk()} color={'success'}>Create</Button>
                </DialogActions>
            }
        </Dialog>
    )
}