import {creatableLicenseType, licenseType} from "../types";

export const getInitialLicense = (creatorId: string, firstName: string, lastName: string): creatableLicenseType => {
    return {
        availableCollaborators: 4,
        availableTargetsVulnerabilityScan: 5,
        availableWorkspaces: 1,
        collaboratorRules: [{type: 'any', email: null, count: 4}],
        creatorId,
        expiry: 1,
        expiryUnit: 'YEARS',
        notes: '',
        usedDetails:  null,
        wizardId: [],
        workspaces: [],
        subscription: 'SUBSCRIPTION_BUSINESS_PLUS',
        issued: false,
        used: false,
        createdDate: new Date(Date.now()).toISOString(),
        creatorDetails: {
            firstName,
            lastName
        },
        issuedDetails: {
            issuedBy: creatorId,
            issuedDate: new Date(Date.now()).toISOString(),
            firstName,
            lastName
        },
    }
}

export const transformLicenseToAddable = (license: licenseType, creatorId: string, firstName: string, lastName: string): creatableLicenseType => {
    return {
        availableCollaborators: license.availableCollaborators,
        availableTargetsVulnerabilityScan: license.availableTargetsVulnerabilityScan,
        availableWorkspaces: license.availableWorkspaces,
        collaboratorRules: license.collaboratorRules,
        creatorId,
        expiry: license.expiry,
        expiryUnit: license.expiryUnit,
        notes: license.notes,
        usedDetails:  null,
        wizardId: license.wizardId,
        workspaces: license.workspaces.map(e => {return {name: e.name, collaboratorsId: e.collaborators.map(c => c.publicId).join(', ')}}),
        subscription: license.subscription,
        issued: false,
        used: false,
        createdDate: new Date(Date.now()).toISOString(),
        issuedDetails: {
            issuedBy: creatorId,
            issuedDate: new Date(Date.now()).toISOString(),
            firstName,
            lastName
        },
        creatorDetails: {
            firstName,
            lastName
        }
    }
}

export const validateEmail = (email: string): boolean => {
    // eslint-disable-next-line
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(email);
}