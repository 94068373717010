import {Button, IconButton, SvgIcon, Icon} from '@mui/material';
import styled from 'styled-components';
import {styled as styledMui} from '@mui/system';
import {ComponentProps, FC, ReactNode} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton as MuiLoadingButton} from "@mui/lab";


type TButtonProps = {
    variant?: 'outlined' | 'contained',
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' 
            | 'lightPrimary' | 'lightError' | 'lightInfo' | 'lightSuccess' | 'lightSecondary' | 'accent' | 'accentDark',
    disabled?: boolean,
    size?: 'small' | 'medium' | 'large',
    active?: boolean,
    component?: 'span' | 'button'
}

export const CustomButton = styled(Button)<TButtonProps>`
    letter-spacing: 1.25px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    ${({size}) => size !== "small" ? 'min-width: 100px !important;' : ''}
    &.MuiButton-contained {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }
    &.MuiButton-contained:hover {
       box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
    };
    &.MuiButton-containedPrimary.Mui-disabled {
        background-color: rgba(158, 158, 158, 1);
        color: #FFFFFF80;
    };
    &.MuiButton-containedSizeSmall {
        text-transform: none;
        height: 24px;
    };
`

export const RoundButtonText = styled(Button).attrs((props) => ({
    variant: 'contained',
    size: props.size ? props.size : 'large',
    }))<TButtonProps>`
    &.MuiButton-contained {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    };
    &.MuiButton-containedSizeSmall {
        text-transform: none;
        height: 32px;
        font-size: 12px;
        letter-spacing: 1.25px;
        line-height: 16px;
        font-weight: 500;
    };
`

type TIconButtonProps = {
    color?: 'primary' | 'lightInfo' | 'accent' | 'accentDark',
    size?: 'small' | 'medium' | 'large',
}


export const RoundButton = styledMui(IconButton)<TIconButtonProps>((props) => ({
    backgroundColor: (props.color === 'primary' && props.theme.palette.primary.main) || 
                    (props.color === 'lightInfo' && props.theme.palette.lightInfo.main) ||
                    (props.color === 'accentDark' && props.theme.palette.accentDark.main) ||
                    (props.color === 'accent' && props.theme.palette.accent.main),
    color:  (props.color === 'primary' && props.theme.palette.primary.contrastText) || 
            (props.color === 'lightInfo' && props.theme.palette.lightInfo.contrastText) ||
            (props.color === 'accentDark' && props.theme.palette.accentDark.contrastText) ||
            (props.color === 'accent' && props.theme.palette.accent.contrastText),
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    '&:hover': {
        backgroundColor: (props.color === 'primary' && props.theme.palette.primary.dark) || 
                    (props.color === 'lightInfo' && props.theme.palette.lightInfo.dark) ||
                    (props.color === 'accentDark' && props.theme.palette.accentDark.dark) ||
                    (props.color === 'accent' && props.theme.palette.accent.dark),
    },
    // size: props.size,
}));

type TAdoptButtonProps = {
    children?: ReactNode,
}

export const AdoptButton: FC<TAdoptButtonProps> = ({children}: TAdoptButtonProps) => {
    return (
        <Button 
        sx={{
            height: '91px',
            width: '380px',
            display: 'flex',
            flexDirection: 'column',
            textTransform: 'none',
            color: '#000000',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '1.53489px',
            fontWeight: '300',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
        }}>
            {children}
            <RoundButton size={'small'} color={'primary'} sx={{boxShadow: 'none', marginTop: '6px'}}><AddIcon/></RoundButton>
        </Button>
    )
}


//
type TIconTextButton = ComponentProps<typeof Button> & {
    icon: ComponentProps<typeof SvgIcon>
}

export const IconTextButton: FC<TIconTextButton> = ({children, icon,  ...props}) => {
    return (
        <Button startIcon={icon} {...props} >
            {children}
        </Button>
    )
}

export const LoadingButton: FC<ComponentProps<typeof MuiLoadingButton> & {label?: boolean}> = ({children, loading, startIcon, loadingPosition, label, ...props}) => {
    const {revDir} = useMainTranslation();

    return !label ? (
        <MuiLoadingButton loading={loading} variant={"contained"} loadingPosition={loadingPosition ?? (startIcon || loading) ? !revDir ? 'start' : 'end' : undefined}
                          startIcon={startIcon ?? (loading ? <Icon/> : undefined)}

                          {...props}
        >
            {children}
        </MuiLoadingButton>
    ) : (
        <MuiLoadingButton loading={loading} variant={props.variant ?? "contained"} loadingPosition={loadingPosition ?? (startIcon || loading) ? !revDir ? 'start' : 'end' : undefined}
                          startIcon={startIcon ?? (loading ? <Icon/> : undefined)}

                          endIcon={props.endIcon}
                          sx={props.sx} style={props.style} disabled={props.disabled}

                          component={'label'}
        >
            {children}
        </MuiLoadingButton>
    )
}