import React, {FC, useMemo} from "react";
import {CompactedMenu} from "./CompactedMenu";
import {ExtendedMenu} from "./ExpandedMenu";
import {LeftSideBarItem} from "./types";
import {useLocation} from "react-router-dom";
import {buildTitlePathList, hiddenLeftBarMenu} from "./helpers";

type PropTypes = {
    w: string;
    expanded: boolean;
};

export const LeftSideBar: FC<PropTypes> = (props: PropTypes) => {
    const {expanded} = props;
    const { pathname } = useLocation();
    const activeRoute: LeftSideBarItem[] = useMemo(() => buildTitlePathList(pathname)?.items ?? [], [pathname]);
    return (
        <>
            { hiddenLeftBarMenu(pathname)
                ? null
                : expanded
                    ? (<ExtendedMenu activeRoute={activeRoute}/>)
                    : (<CompactedMenu activeRoute={activeRoute} />)
            }
        </>
    )
};
