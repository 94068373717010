import {ThemeOptions} from "@mui/material/styles/createTheme";

export const muiTheme: ThemeOptions = {
    direction: 'ltr',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            c1440: 1440,
            c1680: 1680,
            c1920: 1920,
            c2048: 2048,
        }
    },

    typography: {
        fontFamily: `'Mulish', sans-serif`,
        fontSize: 14,
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 30,
            fontWeight: 600,
            letterSpacing: 0.1
        },
        h2: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 25,
            fontWeight: 300,
            letterSpacing: 0.57
        },
        h3: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 23,
            fontWeight: 300,
            letterSpacing: 0.79
        },
        h4: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 20,
            fontWeight: 600,
            letterSpacing: 0.79
        },
        h5: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 16,
            fontWeight: 300,
            letterSpacing: 0.79
        },
        h6: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 14,
            fontWeightBold: 300,
            letterspacing: 0.79
        },

        subtitle2: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 35,
            fontWeight: 600,
            letterSpacing: 0.45
        },

        subtitle1: {
            fontFamily: `'Mulish', sans-serif`,
            fontSize: 12,
            fontWeight: 300,
            letterSpacing: 0.57
        }
    },
    // shape: {borderRadius: 25},
    palette: {
        primary: {
            light: "#2681FF",
            main: '#FAC000',
            // dark: "#5458E1",
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: "#878BA1"
        },
        text: {
            primary: "#000000",
            secondary: "#878BA1"
        },
        error: {
            main: '#FF5959'
        },
        warning: {
            main: '#FAC000'
        },
        info: {
            main: '#64B5F6'
        },
        success: {
            main: '#3BCC89',
            contrastText: "#fff"
        },
        grey: {
            A700: "#56657F"
        },
        lightPrimary: {
            main: "#FFF5E5",
            dark: '#ccc2b3',
            light: '#ffffff',
            contrastText: '#FAC000'
        },
        lightError: {
            main: "#FCE7E7",
            dark: '#c9b5b5',
            light: '#ffffff',
            contrastText: '#FF5959'
        },
        lightInfo: {
            main: '#E3F2FD',
            dark: '#b1bfca',
            light: '#ffffff',
            contrastText: '#A0A2B1'
        },
        lightSuccess: {
            main: '#ECF9F3',
            dark: '#bac6c0',
            light: '#ffffff',
            contrastText: '#00C853'
        },
        lightSecondary: {
            main: '#E8E8E8',
            light: '#ffffff',
            dark: '#b6b6b6',
            contrastText: '#0000008A'
        },
        accent: {
            main: '#A0A2B1',
            dark: '#717382',
            light: '#d1d3e3',
            contrastText: '#FFFFFF'
        },
        accentDark: {
            main: '#23273F',
            dark: '#000019',
            light: '#4c4f6a',
            contrastText: '#FFFFFF'
        }
        //extend colors here
    },

    zIndex: {
        modal: 3100,
        snackbar: 3200,
        tooltip: 3300
    },

    components: {
        MuiIconButton: {
            defaultProps: {
                size: 'large'
            }
        },

        // MuiDialog: {
        //     defaultProps: {
        //         style: {zIndex: 3001}
        //     }
        // }

        MuiTypography: {
            variants: [

                // NEW UI
                {props: {variant: '*h1'}, style: ({theme}) => ({
                        fontSize: '26px',
                        fontWeight: 700,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*h2'}, style: ({theme}) => ({
                        fontSize: '22px',
                        fontWeight: 700,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*h3'}, style: ({theme}) => ({
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*btnLarge'}, style: ({theme}) => ({
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*btnMedium'}, style: ({theme}) => ({
                        fontSize: '13px',
                        fontWeight: 600,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*btnSmall'}, style: ({theme}) => ({
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*subtitle1'}, style: ({theme}) => ({
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*navigationText'}, style: ({theme}) => ({
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '130%',
                    })},

                {props: {variant: '*bodyText1'}, style: ({theme}) => ({
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '135%',
                    })},

                {props: {variant: '*bodyText1_semibold'}, style: ({theme}) => ({
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '135%',
                    })},

                {props: {variant: '*bodyText2'}, style: ({theme}) => ({
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '135%',
                    })},

                {props: {variant: '*bodyText2_semibold'}, style: ({theme}) => ({
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '135%',
                    })},

                {props: {variant: '*bodySmall'}, style: ({theme}) => ({
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '130%',
                    })},
            ]
        }
    }
};

// export default theme;/