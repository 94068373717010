import React, {Component, createRef, RefObject} from "react";
import {EditorTitleInput, EditorTitleWithActionsRowStyled, FlexColumn, FlexRow} from "./styled";
import {Button, IconButton, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {IStatusProps, TEditorActor, TEditorActorsConfigFromDB, TUpdateDataInFillableFieldEventData} from "../../types";
import {ArrowBack} from "@mui/icons-material";
import {
    createEventCommonDialogOpen,
    createEventOpenManageActorsDialog,
    createEventOpenManageEditorsDialog,
    createEventUnfocusAllBlocks,
    getStatusTitleByCode
} from "../../helpers";
import colors from "../../../../theme/colors";
import {EditorEditorsAvatars} from "../editorEditorsAvatars";
import {EditorApproversAndRecipientsAvatars} from "../editorApproversAndRecipientsAvatars";
import {OnInvalidateDocumentDialog} from "../dialogs/onInvalidateDocumentDialog";
import {OnRejectDialog} from "../dialogs/onRejectDialog";
import {EmptyTitleOfDocumentDialog} from "../dialogs/emptyTitleOfDocumentDialog";
import {IsNoRecipientsDialog} from "../dialogs/isNoRecipientsDialog";
import {IsNoAttachedFillableFieldsDialog} from "../dialogs/noAttachedFillableFieldsDialog";
import {RecipientWithNoFieldsDialog} from "../dialogs/recipientWithNoFieldsDialog";
import {LightTooltip} from "../editorSideMenu/styled";
import {needUpdateActorsEventName, updateDataInFillableFieldEventName, updateEditorsEventName} from "../../constants";
import {EditorDialog} from "../editorDialog";
import {NotAssignedRoleDialog} from "../dialogs/notAssignedRoleDialog";

interface Props extends IStatusProps {
    initialDocumentTitle?: string;
    actors: TEditorActorsConfigFromDB;
    onSend: (needPreview: boolean) => void;
    onSave: () => void;
    onApprove: () => void;
    onInvalidate: () => void;
    onReject: () => void;
    onClose: () => void;
    isMain: boolean;

    onReturnToDraft?: () => void;

    activeUserFields: {fieldId: string, value: string, isRequired: boolean}[];

    parentRef: RefObject<EditorDialog>["current"];
}

interface State {
    activeUserFields: Props["activeUserFields"];
    editors: TEditorActor[];
    actors: TEditorActorsConfigFromDB;
    titleRef: RefObject<HTMLInputElement>;
}

export class EditorTitleWithActionsRow extends Component<Props, State> {
    constructor(props: EditorTitleWithActionsRow["props"]) {
        super(props);

        this.state = {
            activeUserFields: props.activeUserFields,
            editors: props.actors.editors,
            titleRef: createRef<HTMLInputElement>(),
            actors: props.actors,
        }
    }

    handleUpdateEditors = (ev: Event) => {
        const {detail} = ev as CustomEvent<TEditorActor[]>;
        this.setState((prev) => ({...prev, editors: detail}))
    }

    componentDidMount() {
        document.addEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
        document.addEventListener(needUpdateActorsEventName, this.handleUpdateActorsByEvent);
        document.addEventListener(updateEditorsEventName, this.handleUpdateEditors);
    }
    componentWillUnmount() {
        document.removeEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
        document.removeEventListener(needUpdateActorsEventName, this.handleUpdateActorsByEvent);
        document.removeEventListener(updateEditorsEventName, this.handleUpdateEditors);
    }

    handleUpdateActorsByEvent = (ev: Event) => {
        // const {detail} = ev as CustomEvent<{}>;
        const actors = this.props.parentRef?.state.workzoneRef.current?.state.sideMenuRef.current?.getActorsData()

        actors && this.setState(prev => ({...prev, actors }))
    };

    handleUpdateDataInFillableFieldsByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TUpdateDataInFillableFieldEventData>;

        this.setState(prev => {
            detail.forEach(el => {
                const index = prev.activeUserFields.findIndex(fillableField => fillableField.fieldId === el.fieldId);
                if (index > -1) {
                    prev.activeUserFields[index].value = el.value;
                }
            })

            return prev
        })
    };

    getTitle = (): string => {
        return this.state.titleRef.current?.value ?? this.props.initialDocumentTitle ?? "Not found title input element's ref";
    };

    render() {
        const isCompleteDisabled = this.state.activeUserFields.some(e => e.isRequired && e.value.trim() === '');

        return (
            <EditorTitleWithActionsRowStyled onMouseDown={() => {
                createEventUnfocusAllBlocks({})
            }}>

                <FlexRow sx={{gap: '10px', flexGrow: 1, alignItems: 'center'}}>
                    <IconButton onClick={(this.props.status.isMain && this.props.status.isDraft && this.props.onReturnToDraft) || this.props.onClose} size={"small"}>
                        <ArrowBack/>
                    </IconButton>

                    <FlexColumn sx={{flexGrow: 1}}>
                        {this.props.readonly || !!this.props.onReturnToDraft ? (
                            <Typography variant={"subtitle2"}>{this.props.initialDocumentTitle}</Typography>
                        ) : (
                            <EditorTitleInput inputRef={this.state.titleRef} defaultValue={this.props.initialDocumentTitle || ''} fullWidth/>
                        )}
                        <FlexRow sx={{gap: '6px', alignItems: 'center', color: colors.text.grey_dark}}>
                            {/*<EditNote/>*/}
                            <span className={'statusTitle'}>{getStatusTitleByCode(this.props.status.currentFileStatus)}</span>
                            {/*<span>·</span>*/}
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>

                {!this.props.readonly && this.props.isMain && !this.props.onReturnToDraft &&
                    <EditorEditorsAvatars
                        actors={this.state.editors}
                        handleOpenEditorsDialog={() => {
                            createEventOpenManageEditorsDialog({isOpen: true})
                        }}
                    />
                }

                {!this.props.readonly && !this.props.onReturnToDraft &&
                    <EditorApproversAndRecipientsAvatars
                        actors={this.state.actors}
                        handleOpenRecipientsApproversDialog={() => {
                            createEventOpenManageActorsDialog({isOpen: true})
                        }}
                        isMain={this.props.isMain}
                    />
                }

                <FlexRow sx={{gap: '10px'}}>
                    {!this.props.readonly && !this.props.onReturnToDraft && (
                        <LoadingButton variant={"text"} size={"medium"} onClick={this.props.onSave} loading={this.props.isLoading?.isSave}>
                            Save Document
                        </LoadingButton>
                    )}

                    {this.props.status.isAbleToSendDocument && this.props.status.isVerifyPdf && (
                        <LoadingButton variant={"contained"} size={"medium"} onClick={() => this.props.onSend(false)} loading={this.props.isLoading?.isSending}>
                            Send
                        </LoadingButton>
                    )}

                    {this.props.status.isAbleToSendDocument && !this.props.status.isVerifyPdf && (
                        <LoadingButton variant={"contained"} size={"medium"} onClick={() => this.props.onSend(true)} loading={this.props.isLoading?.isSending}>
                            Verify & Send
                        </LoadingButton>
                    )}

                    {this.props.status.isAbleToInvalidate && (
                        <Button variant={"outlined"} size={"medium"} onClick={() => {createEventCommonDialogOpen({type: 'onInvalidate', isOpen: true, data: []})}}>
                            Invalidate
                        </Button>
                    )}

                    {this.props.status.isAbleToRejectDocument && (
                        <Button variant={"outlined"} size={"medium"} onClick={() => {createEventCommonDialogOpen({type: 'onReject', isOpen: true, data: []})}}>
                            Decline
                        </Button>
                    )}

                    {this.props.status.isAbleToApproveDocument && this.props.status.isApproval && (
                        <LoadingButton variant={"contained"} size={"medium"} onClick={this.props.onApprove} loading={this.props.isLoading?.isApproving}>
                            Approve
                        </LoadingButton>
                    )}

                    {this.props.status.isAbleToApproveDocument && this.props.status.isSigning && (
                        <LightTooltip title={'You have successfully filled in all fields. Click “Complete” to complete the operation.'}
                                      open={!isCompleteDisabled} arrow placement={"bottom-end"}>
                        <span>
                            <LoadingButton variant={"contained"} size={"medium"} onClick={this.props.onApprove} loading={this.props.isLoading?.isApproving}
                                           disabled={isCompleteDisabled}>
                                Complete
                        </LoadingButton>
                        </span>
                        </LightTooltip>
                    )}
                </FlexRow>

                <OnInvalidateDocumentDialog onConfirm={this.props.onInvalidate} isLoading={this.props.isLoading.isInvalidating}/>
                <OnRejectDialog onConfirm={this.props.onReject} isLoading={this.props.isLoading.isRejecting}/>
                <EmptyTitleOfDocumentDialog/>
                <IsNoRecipientsDialog/>
                <IsNoAttachedFillableFieldsDialog/>
                <NotAssignedRoleDialog/>
                <RecipientWithNoFieldsDialog/>
            </EditorTitleWithActionsRowStyled>
        );
    }
}