import {builderInitialState, evidenceType, frameworkType, TDocumentFile} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {
    AddControl,
    addControlsAndEvidencesFromFilesAction,
    AddEvidence,
    AddFramework,
    addGapFromFilesAction,
    AddPolicy,
    ChangeFrameworkName,
    ChangeFrameworkVisibility,
    createTemplateAction,
    DeattachControl,
    DeattachEvidence,
    DeleteControl,
    DeleteEvidence,
    DeleteFramework,
    DeletePolicy,
    deleteTemplateByIdAction,
    EditControl,
    EditEvidence,
    GetBuilderData,
    GetControls,
    GetControlsWithFilter,
    GetEvidencesWithFilter,
    GetPrivateControls,
    GetPrivateEvidences,
    GetPrivatePolicies,
    getTemplateByIdAction,
    getTemplatesAction,
    LinkControl,
    LinkEvidence,
    UpdateFramework,
    UpdatePolicy,
    updateTemplateByIdAction
} from "./actions";

export const initialState: builderInitialState = {
    //data
    frameworks: [],
    controls: [],
    evidences: [],
    policies: [],
    //tree
    tree: {
        allFrameworks: [
            {
                id: 'framework-1',
                description: '',
                type: '',
                name: 'FRAMEWORK#1',
                logo: ''
            }
        ],
        allControls: [
            {
                id: 'control-1',
                name: 'CONTROL#1',
                group: '',
                category: '',
                description: []
            }
        ],
        allEvidences: [
            {
                id: 'evidence-1',
                collectorId: null,
                description: '',
                categories: [],
                name: 'EVIDENCE#1',
                gap: null
            }
        ],
        fcMappers: [
            {
                id: 'FRAMEWORK1-CONTROL1',
                frameworkId: 'framework-1',
                controlId: 'control-1',
            }
        ],
        ceMappers: [
            {
                id: 'CONTROL1-EVIDENCE1',
                controlId: 'control-1',
                evidenceId: 'evidence-1'
            }
        ],
    },

    //DIALOG DATA
    fetchedControls: [], //controls for dialog of selection created controls (all)
    pageInfo: {
        page: 0,
        count: 0,
        total: 0,
        sort: '',
    },
    fetchedEvidences: [],

    //counters
    allControlsCounter: 0,
    allEvidencesCounter: 0,
    allPoliciesCounter: 0,

    //selections
    selectedFramework: null,
    selectedControl: null,
    selectedEvidence: null,
    selectedPolicy: null,
    selectedFile: null,
    isLoading: true,
    isLoadingControlAndEvidencesByFrameworkId: true,
    isLoadingControls: true,
    isLoadingEvidences: true,
    isLoadingPolicies: true,
    isLoaded: false,
    isLoadingAddControlsAndEvidencesFromFile: false,
    isLoadingAddGapFromFile: false,
    isLoadingEditor: false,
    isLoadingDelete: false,
    isLoadingUpdateFramework: false,

    deletableFiles: [],

    //dialogs

    //FRAMEWORKS
    addFrameworkDialog: {
        isOpen: false,
    },
    deleteFrameworkDialog: {
        isOpen: false,
    },
    editFrameworkDialog: {
        isOpen: false,
        framework: null,
    },

    //CONTROLS
    addControlDialog: {
        isOpen: false,
    },
    deleteControlDialog: {
        isOpen: false,
        controlId: null,
    },
    editControlDialog: {
        isOpen: false,
        controlId: null,
    },
    attachControlDialog: {
        isOpen: false,
    },
    unattachControlDialog: {
        isOpen: false,
        controlId: null,
    },

    //EVIDENCES
    addEvidenceDialog: {
        isOpen: false,
        isEvidencesPage: false,
    },
    deleteEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
    },
    editEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
        isEvidencesPage: false,
    },
    attachEvidenceDialog: {
        isOpen: false,
        isEvidencesPage: false,
    },
    unattachEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
    },
    previewEvidence: {
        isOpen: false,
        evidence: null,
    },

    //POLICY
    addPolicyDialog: {
        isOpen: false,
    },
    editPolicyDialog: {
        isOpen: false,
        policyId: null,
    },
    deletePolicyDialog: {
        isOpen: false,
        policyId: null,
    },
    addControlsAndEvidencesFromFileDialog: false,
    addGapFromFileDialog: false,
    editorDialog: {
        isOpen: false,
        file: null,
        docId: null,
    },
    createTemplateIdDialog: {
        isOpen: false,
        data: [],
        variables: [],
        documentTitle: null,
    },
    deleteFileDialog: {
        isOpen: false,
        file: null
    },

    templates: {
        documents: [],
        selectedDocument: null,

        isLoading: {
            isLoadingList: false,
            isCreating: false,
            isLoadingCurrent: [],
            isUpdatingCurrent: false,
            isDeleting: [],
        }
    }
};

export const BuilderSlice = createSlice({
    name: 'Builder',
    initialState,
    reducers: {
    //FRAMEWORKS
        //ADD
        openAddFramework: (slice) => {slice.addFrameworkDialog.isOpen = true},
        hideAddFramework: (slice) => {slice.addFrameworkDialog.isOpen = false},
        //DELETE
        openDeleteFramework: (slice) => {slice.deleteFrameworkDialog.isOpen = true},
        hideDeleteFramework: (slice) => {slice.deleteFrameworkDialog.isOpen = false},
        //EDIT
        openEditFramework: (slice, {payload}: {payload: frameworkType}) => {slice.editFrameworkDialog = {isOpen: true, framework: payload}},
        hideEditFramework: (slice) => {slice.editFrameworkDialog = {isOpen: false, framework: null}},
    //CONTROLS
        //ADD
        openAddControl: (slice) => {slice.addControlDialog.isOpen = true},
        hideAddControl: (slice) => {slice.addControlDialog.isOpen = false},
        //DELETE
        openDeleteControl: (slice, {payload}: {payload: string}) => {slice.deleteControlDialog.isOpen = true; slice.deleteControlDialog.controlId = payload},
        hideDeleteControl: (slice) => {slice.deleteControlDialog.isOpen = false; slice.deleteControlDialog.controlId = null},
        //EDIT
        openEditControl: (slice, {payload}: {payload: string}) => {slice.editControlDialog.isOpen = true; slice.editControlDialog.controlId = payload},
        hideEditControl: (slice) => {slice.editControlDialog.isOpen = false; slice.editControlDialog.controlId = null},
        //ATTACH
        openAttachControl: (slice) => {slice.attachControlDialog.isOpen = true;},
        hideAttachControl: (slice) => {
            slice.attachControlDialog.isOpen = false;
            slice.fetchedControls = [];
            // slice.fetchedControls = [];
            slice.pageInfo = {
                page: 0,
                count: 0,
                total: 0,
                sort: ''
            }
        },
        //UNATTACH
        openUnattachControl: (slice, {payload}: {payload: string}) => {slice.unattachControlDialog.isOpen = true; slice.editControlDialog.controlId = payload},
        hideUnattachControl: (slice) => {slice.editControlDialog.isOpen = false; slice.unattachControlDialog.controlId = null},
    //EVIDENCES
        openPreviewEvidence: (slice, {payload}: {payload: evidenceType}) => {
          slice.previewEvidence = {isOpen: true, evidence: payload}
        },
        hidePreviewEvidence: (slice) => {
            slice.previewEvidence = {isOpen: false, evidence: null}
        },
        //ADD
        openAddEvidence: (slice, {payload}: {payload: boolean}) => {
            slice.addEvidenceDialog.isOpen = true;
            slice.addEvidenceDialog.isEvidencesPage = payload;
            slice.editEvidenceDialog.isOpen = false;
            slice.selectedEvidence = null;
        },
        hideAddEvidence: (slice) => {slice.addEvidenceDialog.isOpen = false},
        //DELETE
        openDeleteEvidence: (slice, {payload}: {payload:  string}) => {
            slice.deleteEvidenceDialog.isOpen = true;
            slice.deleteEvidenceDialog.evidenceId = payload;
        },
        hideDeleteEvidence: (slice) => {
            slice.deleteEvidenceDialog.isOpen = false;
            slice.deleteEvidenceDialog.evidenceId = null;
        },
        //EDIT
        openEditEvidence: (slice, {payload}: {payload: {evidenceId: string, isEvidencesPage: boolean}}) => {
            slice.editEvidenceDialog.isOpen = true;
            slice.editEvidenceDialog.evidenceId = payload.evidenceId;
            slice.editEvidenceDialog.isEvidencesPage = payload.isEvidencesPage;
            slice.addEvidenceDialog.isOpen = false;
        },
        hideEditEvidence: (slice) => {
            slice.editEvidenceDialog.isOpen = false;
            slice.editEvidenceDialog.evidenceId = null;
            slice.editEvidenceDialog.isEvidencesPage = false;
        },
        //ATTACH
        openAttachEvidence: (slice, {payload}: {payload: boolean}) => {
            slice.attachEvidenceDialog.isOpen = true;
            slice.attachEvidenceDialog.isEvidencesPage = payload;

        },
        hideAttachEvidence: (slice) => {
            slice.attachEvidenceDialog = initialState.attachEvidenceDialog;
            slice.fetchedEvidences = [];
        },
        //UNATTACH
        openUnattachEvidence: (slice, {payload}: {payload: string}) => {slice.unattachEvidenceDialog.isOpen = true; slice.unattachEvidenceDialog.evidenceId = payload},
        hideUnattachEvidence: (slice) => {
            slice.unattachEvidenceDialog.isOpen = false;
            slice.unattachEvidenceDialog.evidenceId = null;
            slice.pageInfo = {
                page: 0,
                count: 0,
                total: 0,
                sort: ''
            };
        },
        openAddControlsAndEvidencesFromFile: (slice) => {slice.addControlsAndEvidencesFromFileDialog = true;},
        hideAddControlsAndEvidencesFromFile: (slice) => {slice.addControlsAndEvidencesFromFileDialog = false;},

        openAddGapFromFile: (slice) => {slice.addGapFromFileDialog = true;},
        hideAddGapFromFile: (slice) => {slice.addGapFromFileDialog = false;},

    //POLICIES
        openAddPolicy: (slice) => {
            slice.selectedPolicy = null;
            slice.addPolicyDialog.isOpen = true;
        },
        hideAddPolicy: (slice) => {
            slice.addPolicyDialog.isOpen = false;
        },
        //DELETE
        openDeletePolicy: (slice, {payload}: {payload:  string}) => {
            slice.deletePolicyDialog.isOpen = true;
            slice.deletePolicyDialog.policyId = payload;
        },
        hideDeletePolicy: (slice) => {
            slice.deletePolicyDialog.isOpen = false;
            slice.deletePolicyDialog.policyId = null;
        },
        //EDIT
        openEditPolicy: (slice, {payload}: {payload: string}) => {
            slice.editPolicyDialog.isOpen = true;
            slice.editPolicyDialog.policyId = payload;
        },
        hideEditPolicy: (slice) => {
            slice.editPolicyDialog.isOpen = false;
            slice.editPolicyDialog.policyId = null;
        },
    //GALLERY
        openDeleteFile: (slice, {payload}: {payload: {file?: TDocumentFile }}) => {
            slice.deleteFileDialog.isOpen = true;
            if (payload.file !== undefined) slice.deleteFileDialog.file = payload.file;
        },
        hideDeleteFile: (slice) => {
            slice.deleteFileDialog = initialState.deleteFileDialog;
        },
        openFileEditor: (slice, {payload}: {payload: {file?: TDocumentFile, docId?: string | null }}) => {
            slice.editorDialog.isOpen = true;
            if (payload.file !== undefined) slice.editorDialog.file = payload.file;
            if (payload.docId !== undefined) slice.editorDialog.docId = payload.docId;
        },
        hideFileEditor: (slice) => {
            slice.editorDialog = initialState.editorDialog;
        },
        openCreateTemplateId: (slice) => {
            slice.createTemplateIdDialog.isOpen = true;
            // if (payload.data !== undefined) slice.createTemplateIdDialog.data = payload.data;
            // if (payload.variables !== undefined) slice.createTemplateIdDialog.variables = payload.variables;
            // if (payload.documentTitle !== undefined) slice.createTemplateIdDialog.documentTitle = payload.documentTitle;
        },
        hideCreateTemplateId: (slice) => {
            slice.createTemplateIdDialog.isOpen = false;
        },

        //OTHER
        setParams: (slice, {payload}: {payload: {frameworkId?: string, controlId?: string, evidenceId?: string, policyId?: string}}) => {
            if(payload.frameworkId) slice.selectedFramework = payload.frameworkId;
            if(payload.controlId) slice.selectedControl = payload.controlId;
            if(payload.evidenceId) slice.selectedEvidence = payload.evidenceId;
            if(payload.policyId) slice.selectedPolicy = payload.policyId;
        },
        selectFramework: (slice, {payload}: {payload: string | null}) => {
            slice.selectedFramework = payload;
            slice.selectedControl = null;
            slice.selectedEvidence = null;
            slice.addEvidenceDialog.isOpen = false;
            slice.editEvidenceDialog.isOpen = false;
            slice.deleteEvidenceDialog.isOpen = false;
        },
        selectControl: (slice, {payload}: {payload: string | null}) => {
            slice.selectedControl = payload;
            slice.selectedEvidence = null;
        },
        selectEvidence: (slice, {payload}: {payload: string | null}) => {
            slice.selectedEvidence = payload;
            slice.addEvidenceDialog.isOpen = false;
            slice.addEvidenceDialog.isEvidencesPage = false;
            slice.editEvidenceDialog.isOpen = true;
            slice.editEvidenceDialog.isEvidencesPage = true;
        },
        selectPolicy: (slice, {payload}: {payload: string | null}) => {
            slice.selectedPolicy = payload;
            slice.addPolicyDialog.isOpen = false;
        },

        selectFile: (slice, {payload}: {payload: TDocumentFile | null}) => {
            slice.selectedFile = payload;
        },
        setDeletableFile: (slice, {payload}: {payload: string}) => {
            slice.deletableFiles.push(payload);
        },
        deleteFromDeletableFiles: (slice, {payload}: {payload: string}) => {
            slice.deletableFiles = slice.deletableFiles.filter(e => e !== payload);
        },
        clearDeletableFiles: (slice) => {slice.deletableFiles = []},

        setSelectedDocumentAction: (slice, {payload}: {payload: builderInitialState["templates"]["selectedDocument"]}) => {
            slice.templates.selectedDocument = payload;
        },

        cleanUp: () => initialState,
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetBuilderData.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(GetBuilderData.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetBuilderData.fulfilled, (slice, {payload}) => {
                slice.frameworks = payload.frameworks;
                slice.allControlsCounter = payload.allControlsCounter;
                slice.allEvidencesCounter = payload.allEvidencesCounter;
                slice.allPoliciesCounter = payload.allPoliciesCounter;

                slice.isLoading = false;
                slice.isLoaded = true;

                // if(payload.frameworks.length > 0){
                //     slice.selectedFramework = payload.frameworks[0].id;
                // }
            })
            //ChangeFrameworkName
            .addCase(ChangeFrameworkName.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(ChangeFrameworkName.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(ChangeFrameworkName.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.editFrameworkDialog = {isOpen: false, framework: null};
                slice.frameworks = slice.frameworks.map(e => e.id === payload.frameworkId ? {
                    ...e,
                    name: payload.name
                } : e);
            })
            //ChangeFrameworkVisibility
            .addCase(ChangeFrameworkVisibility.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(ChangeFrameworkVisibility.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(ChangeFrameworkVisibility.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.map(e => e.id === payload.frameworkId ? {
                    ...e,
                    visibility: payload.visibility
                } : e);
            })
            //DeleteFramework
            .addCase(DeleteFramework.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteFrameworkDialog.isOpen = false;
            })
            .addCase(DeleteFramework.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteFramework.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.filter(e => e.id !== payload);
                slice.selectedFramework = null;
                slice.selectedControl = null;
                slice.controls = [];
                slice.evidences = [];
            })
            //GetControls
            .addCase(GetControls.pending, (slice) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = true;
            })
            .addCase(GetControls.rejected, (slice) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
                slice.selectedFramework = null;
            })
            .addCase(GetControls.fulfilled, (slice, {payload}) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
                slice.controls = payload;
            })
            //AddControl
            .addCase(AddControl.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.addControlDialog = initialState.addControlDialog;

                slice.controls.push(payload.control);
                slice.allControlsCounter = slice.allControlsCounter + 1;
                slice.frameworks = slice.frameworks.map((e) => e.id === payload.frameworkId ? {
                    ...e,
                    controls: e.controls + 1
                } : e);
                if (payload.control.id) {
                    slice.selectedControl = payload.control.id;
                }
            })
            //EditControl
            .addCase(EditControl.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(EditControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(EditControl.fulfilled, (slice, {payload}) => {
                slice.editControlDialog = initialState.editControlDialog;
                slice.isLoading = false;
                slice.controls = slice.controls.map(e => e.id === payload.id ? payload : e);
            })
            //DeattachControl
            .addCase(DeattachControl.pending, (slice) => {
                slice.isLoading = true;
                slice.unattachControlDialog = {isOpen: false, controlId: null};
            })
            .addCase(DeattachControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeattachControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.filter((e) => e.id !== payload.controlId);
                if (slice.selectedControl === payload.controlId) slice.selectedControl = null;
                slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework.id ? payload.framework : f);
            })
            //AddEvidence
            .addCase(AddEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.addEvidenceDialog.isOpen = false;
                if(payload.isEvidencesPage){
                    slice.evidences.push(payload.evidence);
                    slice.allEvidencesCounter = slice.allEvidencesCounter + 1;
                    return;
                }
                slice.controls = slice.controls.map(e => {
                    if (e.id === payload.controlId && e.evidences) {
                        e.evidences.push(payload.evidence);
                    }
                    return e;
                })
                slice.allEvidencesCounter = slice.allEvidencesCounter + 1;
                slice.frameworks = slice.frameworks.map((e) => e.id === payload.frameworkId ? {
                    ...e,
                    evidences: e.evidences + 1
                } : e);
            })
            //EditEvidence
            .addCase(EditEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(EditEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(EditEvidence.fulfilled, (slice, {payload}) => {
                slice.editEvidenceDialog = {isOpen: false, evidenceId: null, isEvidencesPage: false};
                slice.isLoading = false;
                if(payload.isEvidencesPage){
                    slice.evidences = slice.evidences.map(ev => ev.id === payload.evidence.id ? payload.evidence : ev);
                    return;
                }
                slice.controls = slice.controls
                    .map(e => e.id === slice.selectedControl ?
                        {...e, evidences: e.evidences?.map(ev => ev.id === payload.evidence.id ? payload.evidence : ev) || []}
                        :
                        e
                    );
            })
            //DeattachEvidence

            .addCase(DeattachEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(DeattachEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeattachEvidence.fulfilled, (slice, {payload}) => {
                slice.unattachEvidenceDialog = {isOpen: false, evidenceId: null};
                slice.isLoading = false;
                slice.controls = slice.controls
                    .map(e => e.id === payload.controlId ?
                        {...e, evidences: e.evidences?.filter((ev) => ev.id !== payload.evidenceId) || []}
                        :
                        e
                    );
                if(payload.framework){ //IF BUILDER PAGE AND NEEDED TO REACALCULATE FRAMEWORK
                    slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework!.id ? payload.framework! : f);
                }
            })
            //GetControlsWithFilter
            .addCase(GetControlsWithFilter.pending, (slice) => {
                slice.isLoading = true;
                slice.fetchedControls = [];
            })
            .addCase(GetControlsWithFilter.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetControlsWithFilter.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.fetchedControls = payload.controls;
                slice.pageInfo = payload.pageInfo;
            })
            //LinkControl
            .addCase(LinkControl.pending, (slice) => {
                slice.isLoading = true;
                // slice.attachControlDialog.isOpen = false;
            })
            .addCase(LinkControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(LinkControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls.push(payload.control);
                slice.selectedControl = payload.control.id;
                slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework.id ? payload.framework : f);
            })
            //GetEvidencesWithFilter
            .addCase(GetEvidencesWithFilter.pending, (slice) => {
                slice.isLoading = true;
                // slice.attachControlDialog.isOpen = false;
            })
            .addCase(GetEvidencesWithFilter.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetEvidencesWithFilter.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.fetchedEvidences = payload.evidences;
                slice.pageInfo = payload.pageInfo;
            })
            //LinkEvidence
            .addCase(LinkEvidence.pending, (slice) => {
                slice.isLoading = true;
                // slice.attachControlDialog.isOpen = false;
            })
            .addCase(LinkEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(LinkEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.map(e => e.id === payload.controlId
                    ? {...e, evidences: [...e.evidences || [], payload.evidence]}
                    :
                    e
                )
                if(payload.framework){
                    slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework!.id ? payload.framework! : f);
                }
            })
        // AddFramework
            .addCase(AddFramework.pending, (slice) => {
                slice.isLoading = true;
                slice.addFrameworkDialog.isOpen = false;
                slice.selectedControl = null;
                slice.selectedFramework = null;
            })
            .addCase(AddFramework.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddFramework.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
                slice.frameworks.push(payload);
                slice.selectedFramework = payload.id;
                slice.controls = [];
            })
        //UpdateFramework
            .addCase(UpdateFramework.pending, (slice) => {
                slice.isLoadingUpdateFramework = true;
            })
            .addCase(UpdateFramework.rejected, (slice) => {
                slice.isLoadingUpdateFramework = false;
            })
            .addCase(UpdateFramework.fulfilled, (slice, {payload}) => {
                slice.isLoadingUpdateFramework = false;
                slice.editFrameworkDialog = {isOpen: false, framework: null};
                slice.frameworks = slice.frameworks.map(e => e.id === payload.id ? {...payload, controls: e.controls, evidences: e.evidences} : e);
            })
        //GetPrivateControls
            .addCase(GetPrivateControls.pending, (slice) => {
                slice.isLoadingControls = true;
                slice.controls = [];
            })
            .addCase(GetPrivateControls.rejected, (slice) => {
                slice.isLoadingControls = false;
            })
            .addCase(GetPrivateControls.fulfilled, (slice, {payload}) => {
                slice.isLoadingControls = false;
                slice.controls = payload.controls;
                slice.pageInfo = payload.pageInfo;
                slice.selectedEvidence = null;
            })
        //GetPrivateEvidences
            .addCase(GetPrivateEvidences.pending, (slice) => {
                slice.isLoadingEvidences = true;
            })
            .addCase(GetPrivateEvidences.rejected, (slice) => {
                slice.isLoadingEvidences = false;
            })
            .addCase(GetPrivateEvidences.fulfilled, (slice, {payload}) => {
                slice.isLoadingEvidences = false;
                slice.evidences = payload.evidences;
                slice.pageInfo = payload.pageInfo;
                slice.selectedEvidence = null;
            })
        //DeleteControl
            .addCase(DeleteControl.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteControlDialog.isOpen = false;
                slice.deleteControlDialog.controlId = null;
            })
            .addCase(DeleteControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.filter(e => e.id !== payload);
                slice.allControlsCounter = slice.allControlsCounter - 1;
                if(slice.selectedControl === payload){
                    slice.selectedControl = null;
                }
            })
        //DeleteEvidence
            .addCase(DeleteEvidence.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteEvidenceDialog.isOpen = false;
                slice.deleteEvidenceDialog.evidenceId = null;
            })
            .addCase(DeleteEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.evidences = slice.evidences.filter(e => e.id !== payload);
                slice.allEvidencesCounter = slice.allEvidencesCounter - 1;
                if(slice.selectedEvidence === payload){
                    slice.selectedEvidence = null;
                }
            })
        // GetPrivatePolicies
            .addCase(GetPrivatePolicies.pending, (slice) => {
                slice.isLoadingPolicies = true;
            })
            .addCase(GetPrivatePolicies.rejected, (slice) => {
                slice.isLoadingPolicies = false;
            })
            .addCase(GetPrivatePolicies.fulfilled, (slice, {payload}) => {
                slice.isLoadingPolicies = false;
                slice.pageInfo = payload.pageInfo;
                slice.policies = payload.policies;
            })
        //AddPolicy
            .addCase(AddPolicy.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddPolicy.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddPolicy.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.policies.push(payload);
                slice.allPoliciesCounter = slice.allPoliciesCounter + 1;
            })
        //UpdatePolicy
            .addCase(UpdatePolicy.pending, (slice) => {
                slice.isLoading = true;
                slice.selectedPolicy = null;
            })
            .addCase(UpdatePolicy.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(UpdatePolicy.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.policies = slice.policies.map(e => e.id === payload.id ? payload : e);
            })
        //DeletePolicy
            .addCase(DeletePolicy.pending, (slice) => {
                slice.isLoading = true;
                slice.deletePolicyDialog = {isOpen: false, policyId: null};
            })
            .addCase(DeletePolicy.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeletePolicy.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.policies = slice.policies.filter(e => e.id !== payload);
                if(slice.selectedPolicy === payload){
                    slice.selectedPolicy = null;
                }
            })

        //addFromFiles
            .addCase(addControlsAndEvidencesFromFilesAction.pending, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = true;
            })
            .addCase(addControlsAndEvidencesFromFilesAction.rejected, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = false;
            })
            .addCase(addControlsAndEvidencesFromFilesAction.fulfilled, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = false;
                slice.addControlsAndEvidencesFromFileDialog = false;
            })
            .addCase(addGapFromFilesAction.pending, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = true;
            })
            .addCase(addGapFromFilesAction.rejected, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = false;
            })
            .addCase(addGapFromFilesAction.fulfilled, (slice) => {
                slice.isLoadingAddControlsAndEvidencesFromFile = false;
                slice.addGapFromFileDialog = false;
            })

            /////TEMPLATES
            .addCase(getTemplatesAction.pending, (slice) => {
                slice.templates.isLoading.isLoadingList = true;
            })
            .addCase(getTemplatesAction.rejected, (slice) => {
                slice.templates.isLoading.isLoadingList = false;
            })
            .addCase(getTemplatesAction.fulfilled, (slice, {payload}) => {
                slice.templates.isLoading.isLoadingList = false;
                slice.templates.documents = payload;
            })

            .addCase(getTemplateByIdAction.pending, (slice, {meta: {arg: {id}}}) => {
                slice.templates.isLoading.isLoadingCurrent.push(id);
            })
            .addCase(getTemplateByIdAction.rejected, (slice, {meta: {arg: {id}}}) => {
                slice.templates.isLoading.isLoadingCurrent = slice.templates.isLoading.isLoadingCurrent.filter(e => e !== id);
            })
            .addCase(getTemplateByIdAction.fulfilled, (slice, {payload, meta: {arg: {id}}}) => {
                slice.templates.isLoading.isLoadingCurrent = slice.templates.isLoading.isLoadingCurrent.filter(e => e !== id);
                slice.templates.selectedDocument = payload;
            })

            .addCase(createTemplateAction.pending, (slice) => {
                slice.templates.isLoading.isCreating = true;
            })
            .addCase(createTemplateAction.rejected, (slice) => {
                slice.templates.isLoading.isCreating = false;
            })
            .addCase(createTemplateAction.fulfilled, (slice, {payload}) => {
                slice.templates.isLoading.isCreating = false;

                slice.templates.selectedDocument = payload;
                slice.templates.documents.push(payload);
            })

            .addCase(updateTemplateByIdAction.pending, (slice) => {
                slice.templates.isLoading.isUpdatingCurrent = true;
            })
            .addCase(updateTemplateByIdAction.rejected, (slice) => {
                slice.templates.isLoading.isUpdatingCurrent = false;
            })
            .addCase(updateTemplateByIdAction.fulfilled, (slice, {payload}) => {
                slice.templates.isLoading.isUpdatingCurrent = false;

                slice.templates.selectedDocument = payload;
                slice.templates.documents = slice.templates.documents.map(e => e.id === payload.id ? payload : e);
            })

            .addCase(deleteTemplateByIdAction.pending, (slice, {meta: {arg: {id}}}) => {
                slice.templates.isLoading.isDeleting.push(id);
            })
            .addCase(deleteTemplateByIdAction.rejected, (slice, {meta: {arg: {id}}}) => {
                slice.templates.isLoading.isDeleting = slice.templates.isLoading.isDeleting.filter(e => e !== id);
            })
            .addCase(deleteTemplateByIdAction.fulfilled, (slice, {payload, meta: {arg: {id}}}) => {
                slice.templates.isLoading.isDeleting = slice.templates.isLoading.isDeleting.filter(e => e !== id);
                
                slice.templates.selectedDocument = null;
                slice.templates.documents = slice.templates.documents.filter(e => e.id !== id);
            })


            // //GetFilesByFolderId
            // .addCase(GetFilesByFolderId.pending, (slice) => {
            //     slice.isLoadingGallery = true;
            // })
            // .addCase(GetFilesByFolderId.rejected, (slice) => {
            //     slice.isLoadingGallery = false;
            //
            // })
            // .addCase(GetFilesByFolderId.fulfilled, (slice, {payload}) => {
            //     slice.isLoadingGallery = false;
            //     slice.files = payload;
            // })
            // //UpdateDoc
            // .addCase(UpdateDoc.pending, (slice) => {
            //     slice.isLoadingEditor = true;
            // })
            // .addCase(UpdateDoc.rejected, (slice) => {
            //     slice.isLoadingEditor = false;
            //
            // })
            // .addCase(UpdateDoc.fulfilled, (slice, {payload}) => {
            //     slice.isLoadingEditor = false;
            //     slice.editorDialog = initialState.editorDialog;
            //     slice.files = slice.files.map(e => e.id === payload.id ?
            //         ({
            //             ...e,
            //             id: payload.id,
            //             name: payload.name,
            //             data: {
            //                 ...e.data,
            //                 innerHtml: payload.data.innerHtml,
            //                 variables: payload.data.variables,
            //                 editor: payload.data.editor,
            //             },
            //             lastEditDate: new Date(Date.now()).toISOString()
            //         }) : e);
            // })
            // //CreateDoc
            // .addCase(CreateDoc.pending, (slice) => {
            //     slice.isLoadingEditor = true;
            // })
            // .addCase(CreateDoc.rejected, (slice) => {
            //     slice.isLoadingEditor = false;
            //     slice.createTemplateIdDialog = initialState.createTemplateIdDialog;
            // })
            // .addCase(CreateDoc.fulfilled, (slice, {payload}) => {
            //     slice.isLoadingEditor = false;
            //     slice.editorDialog = initialState.editorDialog;
            //     slice.createTemplateIdDialog = initialState.createTemplateIdDialog;
            //     slice.files.push(payload);
            //     // slice.files.push({
            //     //     ...payload,
            //     //     workspaceId: null,
            //     //     folderId: null,
            //     //     documentId: null,
            //     //     description: null,
            //     //     status: null,
            //     //     origin: false,
            //     //     latest: true,
            //     //     visible: true,
            //     //     lastEditDate: new Date(Date.now()).toISOString(),
            //     //     createdDate: new Date(Date.now()).toISOString(),
            //     //     editorId: '',
            //     //     creatorId: '',
            //     //     version: 1,
            //     //     parentId: null,
            //     //     automationId: null,
            //     //     tags: [],
            //     //     accessRules: null,
            //     //     data: {
            //     //         innerHtml: payload.innerHtml,
            //     //         config: null,
            //     //         variables: [],
            //     //     }
            //     // })
            // })
            // .addCase(DeleteDoc.pending, (slice) => {
            //     slice.isLoadingDelete = true;
            // })
            // .addCase(DeleteDoc.rejected, (slice) => {
            //     slice.isLoadingDelete = false;
            // })
            // .addCase(DeleteDoc.fulfilled, (slice, {payload}) => {
            //     slice.isLoadingDelete = false;
            //     slice.deleteFileDialog = initialState.deleteFileDialog;
            //     slice.files = slice.files.filter((e) => e.id !== payload.id);
            // })

    },
});

export const {
//FRAMEWORKS
    openAddFramework,
    hideAddFramework,
    openEditFramework,
    hideEditFramework,
    openDeleteFramework,
    hideDeleteFramework,
//CONTROLS
    openAddControl,
    hideAddControl,
    openDeleteControl,
    hideDeleteControl,
    openEditControl,
    hideEditControl,
    openAttachControl,
    hideAttachControl,
    openUnattachControl,
    hideUnattachControl,
//EVIDENCES
    openAddEvidence,
    hideAddEvidence,
    openDeleteEvidence,
    hideDeleteEvidence,
    openEditEvidence,
    hideEditEvidence,
    openAttachEvidence,
    hideAttachEvidence,
    openUnattachEvidence,
    hideUnattachEvidence,

    openPreviewEvidence,
    hidePreviewEvidence,
//POLICIES
    selectPolicy,
    openAddPolicy,
    hideAddPolicy,
    openEditPolicy,
    hideEditPolicy,
    openDeletePolicy,
    hideDeletePolicy,
//OTHER
    setParams,
    selectFramework,
    selectControl,
    selectEvidence,
    setDeletableFile,
    deleteFromDeletableFiles,
    clearDeletableFiles,
    openAddControlsAndEvidencesFromFile,
    hideAddControlsAndEvidencesFromFile,
    openAddGapFromFile,
    hideAddGapFromFile,
    openFileEditor,
    hideFileEditor,
    openDeleteFile,
    hideDeleteFile,
    openCreateTemplateId,
    hideCreateTemplateId,
    cleanUp,

    setSelectedDocumentAction,
} = BuilderSlice.actions;

export const BuilderReducer = BuilderSlice.reducer;

const selectSelf = (state: AppState):builderInitialState => state.Builder as builderInitialState;

//DATA
export const frameworks = createSelector(selectSelf, state => state.frameworks);
export const controls = createSelector(selectSelf, state => state.controls);
export const evidences = createSelector(selectSelf, state => state.evidences);
export const policies = createSelector(selectSelf, state => state.policies);
export const templates = createSelector(selectSelf, state => state.templates);

//DIALOG DATA
export const fetchedControls = createSelector(selectSelf, state => state.fetchedControls);
export const fetchedEvidences = createSelector(selectSelf, state => state.fetchedEvidences);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);

//counters
export const allControlsCounter = createSelector(selectSelf, state => state.allControlsCounter);
export const allEvidencesCounter = createSelector(selectSelf, state => state.allEvidencesCounter);
export const allPoliciesCounter = createSelector(selectSelf, state => state.allPoliciesCounter);

//SELECTORS
export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const isLoadingControlAndEvidencesByFrameworkId = createSelector(selectSelf, state => state.isLoadingControlAndEvidencesByFrameworkId);
export const isLoadingControls = createSelector(selectSelf, state => state.isLoadingControls);
export const isLoadingEvidences = createSelector(selectSelf, state => state.isLoadingEvidences);
export const isLoadingPolicies = createSelector(selectSelf, state => state.isLoadingPolicies);
export const deletableFiles = createSelector(selectSelf, state => state.deletableFiles);


//isLoadingControls
export const isLoaded = createSelector(selectSelf, state => state.isLoaded);
export const selectedFramework = createSelector(selectSelf, state => state.selectedFramework);
export const selectedControl = createSelector(selectSelf, state => state.selectedControl);
export const selectedEvidence = createSelector(selectSelf, state => state.selectedEvidence);
export const selectedPolicy = createSelector(selectSelf, state => state.selectedPolicy);

//DIALOGS

//FRAMEWORKS
export const addFrameworkDialog = createSelector(selectSelf, state => state.addFrameworkDialog);
export const deleteFrameworkDialog = createSelector(selectSelf, state => state.deleteFrameworkDialog);
export const editFrameworkDialog = createSelector(selectSelf, state => state.editFrameworkDialog);
// export const editNameFrameworkDialog = createSelector(selectSelf, state => state.editNameFrameworkDialog);

//CONTROLS
export const addControlDialog = createSelector(selectSelf, state => state.addControlDialog);
export const deleteControlDialog = createSelector(selectSelf, state => state.deleteControlDialog);
export const editControlDialog = createSelector(selectSelf, state => state.editControlDialog);
export const attachControlDialog = createSelector(selectSelf, state => state.attachControlDialog);
export const unattachControlDialog = createSelector(selectSelf, state => state.unattachControlDialog);

//EVIDENCES
export const addEvidenceDialog = createSelector(selectSelf, state => state.addEvidenceDialog);
export const deleteEvidenceDialog = createSelector(selectSelf, state => state.deleteEvidenceDialog);
export const editEvidenceDialog = createSelector(selectSelf, state => state.editEvidenceDialog);
export const attachEvidenceDialog = createSelector(selectSelf, state => state.attachEvidenceDialog);
export const unattachEvidenceDialog = createSelector(selectSelf, state => state.unattachEvidenceDialog);
export const previewEvidence = createSelector(selectSelf, state => state.previewEvidence);

//POLICIES
export const addPolicyDialog = createSelector(selectSelf, state => state.addPolicyDialog);
export const deletePolicyDialog = createSelector(selectSelf, state => state.deletePolicyDialog);

export const editPolicyDialog = createSelector(selectSelf, state => state.editPolicyDialog);

export const isLoadingAddControlsAndEvidencesFromFile = createSelector(selectSelf, state => state.isLoadingAddControlsAndEvidencesFromFile);
export const isLoadingAddGapFromFile = createSelector(selectSelf, state => state.isLoadingAddGapFromFile);
export const isLoadingEditor = createSelector(selectSelf, state => state.isLoadingEditor);
export const isLoadingDelete = createSelector(selectSelf, state => state.isLoadingDelete);
export const addControlsAndEvidencesFromFile = createSelector(selectSelf, state => state.addControlsAndEvidencesFromFileDialog);
export const addGapFromFile = createSelector(selectSelf, state => state.addGapFromFileDialog);
export const editorDialog = createSelector(selectSelf, state => state.editorDialog);
export const deleteFileDialog = createSelector(selectSelf, state => state.deleteFileDialog);
export const createTemplateId = createSelector(selectSelf, state => state.createTemplateIdDialog);

export const isLoadingUpdateFramework = createSelector(selectSelf, state => state.isLoadingUpdateFramework);
