import styled from "styled-components";
import {Box, FlexAround, FlexStart} from "../../../../shared/components/Layouts";
import {Accordion, AccordionDetails, AccordionSummary, ListItemText, Menu, MenuItem, Tooltip} from "@material-ui/core";
import {ACTIVE_MENU, TEXT_MENU, WHITE_TEXT} from "../constants";
// import React from "react";

const h36 = 36;
const w24 = 24;
const w5 = 5;

export const ActiveBox = styled(Box)<{ active?: boolean; expanded?: boolean; revDir: boolean }>`
  display: ${props => props.active ? "block" : "none"};
  min-width: ${w5}px;
  width: ${w5}px;
  height: ${h36}px; 
  background-color: ${ACTIVE_MENU};
  ${props => !props.revDir ? 'margin-right: 13px;' : 'margin-left: 13px;'} 
`

export const MenuIconBox = styled(FlexAround)<{ active?: boolean; expanded?: boolean; isHover?: boolean, revDir: boolean }>`
  ${({active, expanded}) => !active && !expanded && `margin: 0 auto;`} 
  ${({active, expanded, revDir}) => (!active && expanded) ? !revDir ? `margin-left: 18px;` : 'margin-right: 18px;' : ''}
  ${({active, expanded, revDir}) => (active && !expanded) ? !revDir ? `margin-right: 18px;` : 'margin-left: 18px;' : ''}
  width: ${w24}px;   
  min-width: ${w24}px; 
  height: ${h36}px;  
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  
  & > svg {
    //max-width: ;
    object-fit: cover;
  }
  
  & > svg * {
    // ${({active, expanded, isHover}) => active && `fill: ${ACTIVE_MENU};`}
    // ${({active, expanded, isHover}) => active && `stroke: ${ACTIVE_MENU};`}

    ${({active, expanded, isHover}) => isHover && `fill: white;`}
    ${({active, expanded, isHover}) => isHover && `stroke: white;`}
  }
`

export const TitleBox = styled(FlexAround)<{ active?: boolean; compacted?: boolean, revDir: boolean }>`
  height: ${h36}px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  ${props => !props.revDir ? 'margin-left: 10px;' : 'margin-right: 10px;'}  
`


export const MenuItemWrapper = styled(FlexStart)<{ active?: boolean; complex?: boolean; }>`
  align-content: flex-start;
  margin: ${({complex, theme: {short, bit}}) => short || bit  ? complex ? "0" : '3px 0 3px 0' : '12px 0 12px 0'};
  height: 36px;
  svg:hover {
    fill: white;
    stroke: white;
    
    //path * {
    //  fill: white !important;
    //  stroke: white !important;
    //}
  }

`
export const StyledMenu = styled(Menu)<{ active?: boolean }>`
  background-color: inherit;
  left: 30px;

  .MuiPopover-paper {
    opacity: 1 !important;
    background-color: unset;
    padding: 0;
    box-shadow: none;  
   
  }
`;

export const Anchor = styled(Box)`
  height: 0.1px;
  align-self: center;
  //margin-left: 17.9px;
`;

export const Triangle = styled(Box)`
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  height: 10px;
  width: 10px;
  background-color: #152747;
`

export const Titled = styled(ListItemText)<{ color?: string; fs?: string; fw?: string }>`
  color: ${props => props.color ? props.color : TEXT_MENU};

  .MuiTypography-body1 {
    ${props => props.fs && `font-size: ${props.fs}`};
    ${props => props.fw && `font-weight: ${props.fw}`};
  }

  .MuiListItemText-root {
    padding: 1px 1px 1px 1px !important;
  }
  
  &:hover {
    color: white;
  }
`
export const Boxed = styled(Box)`
  border-radius: 10px;
  background-color: #152747;
  z-index: 3003;
`
export const Summary = styled(AccordionSummary)`
  background-color: #152747 !important;
  color: white !important;
  font-weight: 300;
  padding-left: 0 !important;
  width: 100% !important;
  min-height: ${({theme: {short, bit}}) => bit || short ? '36px' : '48px'} !important;

  //.MuiAccordionSummary-root {
  //  
  //}
  .MuiIconButton-root {
    color: ${WHITE_TEXT} !important;
    padding:  ${({theme: {short, bit}}) => bit ? '3px 8px' : short ? '6px 8px' : '12px 8px'} !important;
    transform: scale(0.75)
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    transform: scale(0.75) rotate(180deg);
  }

  .MuiAccordionSummary-content {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    ::after {
      content: "";
      background: ${WHITE_TEXT};
      position: absolute;
      bottom: 0;
      left: calc((60px - 10px) / 2);
      height: 1px;
      width: calc(100% - (60px - 10px) / 2 - 20px);
    }
  }

  &:hover {
    color: white;
  }
`

export const AccordionStyled = styled(Accordion)`
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  overflow-x: hidden !important;
  background-color: #152747 !important;
  margin: 0 !important;

  .MuiAccordion-root {
    margin: 0 !important;
  }

  .MuiAccordion-root {
    border: none !important;
    //box-shadow: none !important;  
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
`
export const Content = styled(AccordionDetails)`
  padding: 0 0 0 calc((60px + 20px) / 2) !important;
`

export const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-popper {
    z-index: 3020;
  }

  .MuiTooltip-tooltip {
    z-index: 3020;
  }

  z-index: 3020;
`

export const StyledPop = styled(FlexStart)<{ opacity?: string }>`
  background-color: transparent;
  //opacity: 0;
  opacity: ${({opacity}) => opacity ? opacity : '0'};
  transition: opacity 1s;
`

export const AnimatedLeftSideBar = styled(FlexStart)<{ w: string, opacity: string; expanded?: boolean; }>`
  height: ${props => props.theme.height};
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0 0 0;
  z-index: 3000;
  background-color: #152747;
  transition: opacity 600ms;
  opacity: ${({opacity}) => opacity};
  width: ${({w}) => w};
  ${props => props.expanded && 'min-width:  190px;'};
`

export const MenuItemStyled = styled(MenuItem)`
  padding:  ${({theme: {short, bit}}) => bit ? '3px 16px' : short ? '5px 16px' : '6px 16px'} !important;
  //.MuiMenuItem-root {
  //  
  //}
`
