// import {useDispatch, useSelector} from "react-redux";
// import {selectedLanguage, selectLanguage} from "../slice";
// import {TLanguage} from "../types";
// import {useTranslation, UseTranslationOptions} from "react-i18next";

// having resources like this:
/*{
  "translation": {
    "very": {
      "deeply": {
        "nested": {
          "key": "here"
        }
      }
    }
  }
}*/
// you can define a keyPrefix to be used for the resulting t function
// const { t } = useTranslation('translation', { keyPrefix: 'very.deeply.nested' });
// const text = t('key'); // "here"

export const useMainTranslation = (ns?: string | string[] | readonly string[] | undefined, options?: {keyPrefix: string}) => {
    // const dispatch = useDispatch();
    // const _selectedLanguage = useSelector(selectedLanguage);
    // const setLanguage = (language: TLanguage) => dispatch(selectLanguage(language));
    // const {t} = useTranslation(ns, options);

    return{
        setLanguage: () => null,
        currentLanguage: {
            name: 'English',
            code: 'en',
            countryCode: 'US',
            dir: 'ltr',
            locale: 'enUS',
            momentLocale: 'en-au'
        },
        revDir: false,
        t: (text: string, options?: {[key: string]: string}): string => text
    }
}