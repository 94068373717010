import {PaginationInput} from "../../../../../shared/types";
import {GetPrivateEvidences} from "../../store/actions";
import {useSearchFieldURL} from "../../../../../shared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../shared/hooks/useFilterUrl";
import {useDispatch, useSelector} from "react-redux";
import {
    controls,
    evidences,
    isLoadingEvidences, openAddEvidence, openAttachEvidence, openDeleteEvidence, openEditEvidence, openUnattachEvidence,
    pageInfo,
    selectedControl,
    selectedEvidence,
    selectEvidence
} from "../../store/slice";
import {useRouteMatch} from "react-router";
import {ROOT_CONTROLS, ROOT_EVIDENCES} from "../../constants";
import React, {useEffect, useState} from "react";
import {controlType} from "../../types";
import {useParameters} from "../../../../../shared/hooks/useParameters";


export const useBuilderEvidencesTable = () => {

    const dispatch = useDispatch();
    const {filter} = useParameters();
    const _controls = useSelector(controls);
    const selected = useSelector(selectedControl);
    const selectedEvidenceId = useSelector(selectedEvidence);
    const isLoading = useSelector(isLoadingEvidences);
    const isEvidencesPage = useRouteMatch(ROOT_EVIDENCES)?.isExact;
    const isControlsPage = useRouteMatch<{controlId: string}>(ROOT_CONTROLS)?.isExact;
    const _evidences = useSelector(evidences);
    const _pageInfo = useSelector(pageInfo);

    const [search, setSearch] = useState<string>("");
    const [currentControl, setCurrentControl] = useState<controlType | null>(null);


    const getData = (pageInfo: PaginationInput) => {
        //only for controls page
        dispatch(GetPrivateEvidences({page: pageInfo.page, count: pageInfo.count, filters: pageInfo.filters || {}}));
    };

    const {value, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {
            getData({page: 0, count: 10, filters})}
    });

    useEffect(() => {
        if(!selected) {
            setCurrentControl(null);
            return;
        }
        const currControl = _controls.find(e => e.id === selected);
        if(currControl && JSON.stringify(currControl) !== JSON.stringify(currentControl)) setCurrentControl(currControl);
        //eslint-disable-next-line
    }, [_controls, selected, selectedEvidenceId, _evidences]);

    const onSelect = (id: string | null) => {
        (id && isEvidencesPage) && dispatch(selectEvidence(id));
    };


    const [anchorEl, setAnchorEl] = React.useState<{id: string | null, anchorEl: HTMLButtonElement | null}>({id: null, anchorEl: null});

    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        if(isEvidencesPage){
            dispatch(openAddEvidence(isEvidencesPage || false));
        }else{
            setAnchorEl({
                id: id,
                anchorEl: event.target as HTMLButtonElement,
            });
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl({id: null, anchorEl: null});
    }

    const handleChoose = (type: 'new' | 'exists') => {
        type === 'new' && dispatch(openAddEvidence(isEvidencesPage || false));
        type === 'exists' && dispatch(openAttachEvidence(isEvidencesPage || false));
        handlePopoverClose();
    }

    const handleEditEvidence = (e: any, id: string) => {
        e.stopPropagation();
        dispatch(openEditEvidence({evidenceId: id, isEvidencesPage: isEvidencesPage || false}));
    }

    const handleDeattachEvidence = (e: any, id: string) => {
        e.stopPropagation();
        dispatch(openUnattachEvidence(id));
    }

    const handleDeleteEvidence = (e:any, id: string) => {
        e.stopPropagation();
        dispatch(openDeleteEvidence(id));
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        // dispatch(GetPrivateControls({page: 0, count: parseInt(event.target.value, 10)}));
        dispatch(GetPrivateEvidences({page: 0, count: parseInt(event.target.value, 10), filters: filter}));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        //only for evindeces page
        dispatch(GetPrivateEvidences({page: newPage, count: _pageInfo.count, filters: filter}));
    };

    // const isAbleToAdd: boolean = isEvidencesPage ? true : (currentControl && !currentControl.origin && currentControl.workspaceId ?? '' === (localStorage.getItem('workspaceId') ?? ''));



    useEffect(() => {
        setSearch('');

        // eslint-disable-next-line
    }, []);

    return {
        selectedEvidenceId,
        isLoading,
        isEvidencesPage,
        isControlsPage,
        evidences: _evidences,
        pageInfo: _pageInfo,
        search,
        setSearch,
        currentControl,
        onSelect,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        handleChoose,
        handleEditEvidence,
        handleDeattachEvidence,
        handleDeleteEvidence,
        handleChangeRowsPerPage,
        handleChangePage,

        //filter
        getData,
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    }
}
