import {useDispatch, useSelector} from "react-redux";
import {PaginationInput} from "../../../../../shared/types";
import {extendedLicense, loading, cleanUp, creatableLicense} from "../../store/slice";
import {useEffect, useState} from "react";
import {GetQuickStarts, GetShortLicenses} from "../../store/actions";
import {licenseTabs} from "../../types";

export const useLicenses = () => {
    const dispatch = useDispatch();
    const isLoadingExtendedLicense = useSelector(loading).isLoadingExtendedLicense;
    const extended = useSelector(extendedLicense);
    const editableLicense = useSelector(creatableLicense);

    const getData = (pageInfo: PaginationInput) => {
        dispatch(GetShortLicenses({pageInfo}));
    }

    useEffect(() => {
        dispatch(GetQuickStarts());

        return function clean(){
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if((!isLoadingExtendedLicense && extended) ){
            setTab('wizards');
            //when user loads new license - setting first tab (cleanUp)
        }
        //eslint-disable-next-line
    }, [isLoadingExtendedLicense]);

    const [tab, setTab] = useState<licenseTabs>('wizards');

    const setDefaultTab = () => setTab('wizards');

    return{
        getData,
        isLoadingExtendedLicense: isLoadingExtendedLicense,
        isExtendedLicensePresent: extended !== null,
        isCreateMode: editableLicense!== null,
        tab,
        setTab,
        setDefaultTab
    }
}