import {DEFAULT_PAGINATION, DEFAULT_PAGINATION_LEFT} from "../../constants";

export type TDefaultPagination = 'left' | 'table' | 'frameworksGroups' | 'inventory' | 'analysis' | 'assetsHistory';
export const useDefaultPagination = (type: TDefaultPagination = 'table', minus: number = 0): {page: number, rowsPerPage: number} => {
    if (type === 'table') {
        const add_rows = Math.round((window.innerHeight - 920) / 200 + 0.5);
        return {
            page: DEFAULT_PAGINATION.page,
            rowsPerPage: add_rows >= 1 ? DEFAULT_PAGINATION.rowsPerPage + 5*add_rows : DEFAULT_PAGINATION.rowsPerPage
        }
    }
    if (type === 'left') {
        const add_rows = Math.round((window.innerHeight - 800) / 100);
        return {
            page: DEFAULT_PAGINATION_LEFT.page,
            rowsPerPage: add_rows - minus >= 1 ? DEFAULT_PAGINATION_LEFT.rowsPerPage + add_rows - minus : DEFAULT_PAGINATION_LEFT.rowsPerPage
        }
    }
    if (type === 'frameworksGroups') {
        const add_rows = Math.round((window.innerHeight - 760) / 75);
        return {
            page: DEFAULT_PAGINATION_LEFT.page,
            rowsPerPage: add_rows - minus >= 1 ? DEFAULT_PAGINATION_LEFT.rowsPerPage + add_rows - minus : DEFAULT_PAGINATION_LEFT.rowsPerPage
        }
    }
    if (type === 'inventory') {
        const rows = Math.round((window.innerHeight - 230) / 78);
        return {
            page: DEFAULT_PAGINATION_LEFT.page,
            rowsPerPage: rows - minus >= 1 ? rows - minus : DEFAULT_PAGINATION_LEFT.rowsPerPage
        }
    }
    if (type === 'analysis') {
        const rows = Math.round((window.innerHeight - 230) / 78);
        return {
            page: DEFAULT_PAGINATION_LEFT.page,
            rowsPerPage: rows - minus >= 1 ? rows - minus : DEFAULT_PAGINATION_LEFT.rowsPerPage
        }
    }
    if (type === 'assetsHistory') {
        const rows = Math.round((window.innerHeight - 230) / 25);
        return {
            page: DEFAULT_PAGINATION_LEFT.page,
            rowsPerPage: rows - minus >= 1 ? rows - minus : DEFAULT_PAGINATION_LEFT.rowsPerPage
        }
    }
    return DEFAULT_PAGINATION

};