import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideChangeOrgCollabsRulesDialog, openChangeAvailableCollabsCounterDialog} from "../../../store/slice";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, IconButton, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {organizationCollabsRuleConfig} from "../../../types";
import { Flex } from "../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../shared/components/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Autocomplete} from "@mui/lab";
import {CustomInput} from "../../../../../../shared/components/Inputs";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {UpdateOrgCollabsRules} from "../../../store/actions";

export const UpdateOrganizationCollabsRulesDialog = () => {

    interface collabRule extends organizationCollabsRuleConfig{
        id?: number;
    }

    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeCollabRules} = useSelector(loading);
    const {changeOrgCollabsRulesDialog : {isOpen}} = useSelector(dialogs);
    const [rules, setRules] = useState<collabRule[]>([]);

    const handleClose = () => {
        dispatch(hideChangeOrgCollabsRulesDialog());
    }

    const handleSubmit = () => {
        if(org && !isEqual()){
            const normalizedValues = rules.map(e => {delete e.id; return e});
            dispatch(UpdateOrgCollabsRules({id: org.id, newValue: normalizedValues}));
        }
    }

    const isEqual = () => {
        if(org){
            const indexedRules = org.configuration.availableCollaboratorsRules.map((e, id) => {return {...e, id}});
            return JSON.stringify(indexedRules) === JSON.stringify(rules);
        }
        return true;
    }

    useEffect(() => {
        if(isOpen && org){
            const indexedRules = org.configuration.availableCollaboratorsRules.map((e, id) => {return {...e, id}});
            setRules(indexedRules);
        }
    }, [isOpen, org]);

    //---
    const deleteRule = (id: number) => {
        setRules(rules.filter(e => e.id !== undefined && e.id !== id));
    }

    const handleAddRule = () => {
        setRules([...rules, {type: 'email', email: `____.com-${rules.length}`, count: 0, id: rules.length}]);
    }

    const handleUpdateCounter = (id: number, count: number) => {
        const newRules = rules.map(e => {
            if(e.id !== undefined && e.id === id){
                const availableCollabs = org?.configuration.availableCollaboratorsCount ?? 0;
                const totalCounters = rules.map(t => t.count).reduce((prevC, nextC) => prevC + nextC, 0);
                if(availableCollabs > totalCounters && count >= 0){
                    //if any smaller then total -> making update
                    e.count = count;
                }else if(availableCollabs <= totalCounters && count < e.count && count >= 0){
                    //if any equals or smaller then total but user decrease one of totals - making update
                    e.count = count;
                }
                return e;
            }
            return e;
        });
        setRules(newRules);
    }

    const handleUpdateType = (id: number, type: string) => {
        const newRules = rules.map(e => e.id !== undefined && e.id === id ? {...e, type} : e);
        setRules(newRules);
    }

    const handleUpdateEmail = (id: number, email: string) => {
        const newRules = rules.map(e => e.id !== undefined && e.id === id ? {...e, email} : e);
        setRules(newRules);
    }

    const handleOpenChangeCollabsCounter = () => {
        dispatch(openChangeAvailableCollabsCounterDialog());
    };

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Update Collabs Rules</DialogTitle>

            <DialogContent>
                <Flex w={'100%'} direction={'column'} ai={'center'} m={'5px 0 0 0'}>
                    <Flex w={'100%'} ai={'center'} jc={'space-between'} box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'} p={'5px'} br={'5px'}>
                        <Typo>available collaborators counter: {org?.configuration.availableCollaboratorsCount ?? 0}</Typo>
                        <IconButton onClick={() => handleOpenChangeCollabsCounter()}>
                            <OpenInNewIcon />
                        </IconButton>
                    </Flex>
                    {rules.map((e) => {
                        return(
                            <Flex w={'100%'} jc={'space-between'} box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'} p={'5px'} m={'10px 0 0 0'} br={'5px'}>
                                <Flex w={'90%'} jc={'space-between'}>
                                    <Autocomplete
                                        sx={{width: '30%'}}
                                        autoComplete
                                        id="combo-box-demo"
                                        options={['email', 'any']}
                                        disabled={true}
                                        value={e.type}
                                        onChange={(event, value) => {value && e.id !== undefined && handleUpdateType(e.id, value)}}
                                        renderInput={(params) => <TextField {...params} label="Type" />}
                                    />

                                    <Flex w={'45%'}>
                                        <CustomInput
                                            disabled={e.type === 'any'}
                                            value={e.email}
                                            label={'Email'}
                                            onChange={(event) => {e.id !== undefined && handleUpdateEmail(e.id, event.target.value as string)}}
                                        />
                                    </Flex>

                                    <Flex w={'20%'}>
                                        <CustomInput
                                            type={'number'}
                                            value={e.count}
                                            label={'Count'}
                                            onChange={(event) => {e.id !== undefined && handleUpdateCounter(e.id, parseInt(event.target.value))}}
                                        />
                                    </Flex>
                                </Flex>

                                {e.type !== 'any' &&
                                    <IconButton onClick={() => {e.id !== undefined && deleteRule(e.id)}}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            </Flex>
                        )
                    })}
                </Flex>

                <Flex w={'100%'}>
                    <IconButton onClick={handleAddRule}>
                        <AddCircleIcon />
                    </IconButton>
                </Flex>

            </DialogContent>

            {isLoadingChangeCollabRules ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org || (org && isEqual())} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}