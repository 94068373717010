import {createSelector, createSlice} from "@reduxjs/toolkit";

//types
import {TAssetDialogs, TAssetField, TAssets, TJobDescription, TStatus} from "../types";
import {AppState} from "../../../../shared/redux/rootReducer";
import {
    addAssetSettingsAction,
    getAssetSettingsAction, getDataForCreateJobTitleAction,
    removeAssetSettingsAction,
    updateAssetSettingsAction
} from "./actions";

//actions

export type TAssetsSettingsSlice = {
    page: TAssets,

    fields: {
        selectedField: string | null,
        fields: TAssetField[],
    },
    jobTypeVariants: string[] | null,

    isLoading: {
        isLoadingFields: boolean,
        isAddingField: boolean,
        isUpdate: boolean,
        isDeletingField: boolean,
        dataForDialog: boolean,
    }

    dialogs: TAssetDialogs,
}

const initialState: TAssetsSettingsSlice = {
    page: 'employees',
    fields: {
        selectedField: null,
        fields: [],
    },
    jobTypeVariants: null,

    isLoading: {
        isLoadingFields: false,
        isAddingField: false,
        isUpdate: false,
        isDeletingField: false,
        dataForDialog: false,
    },
    dialogs: {
        createAssetSetting: false,
        editAssetSetting: {
            isOpen: false,
            oldTitle: null,
            jobDescription: null,
            status: null,
        },
        deleteAssetSetting: {
            isOpen: false,
            name: null,
            id: null,
        },
    }

}


const AssetsSettingsVariants = createSlice({
    name: "AssetsSettingsVariants",
    initialState,
    reducers: {
        setPageAction: (slice: TAssetsSettingsSlice, {payload}: {payload: TAssets}) => {
            slice.page = payload;
        },

        setSelectedFieldAction: (slice: TAssetsSettingsSlice, {payload}: {payload: TAssetsSettingsSlice["fields"]["selectedField"]}) => {
            slice.fields.selectedField = payload;
        },

        cleanUpAction(){
            return initialState;
        },
        cleanAction: (slice: TAssetsSettingsSlice, {payload}: {payload: ('page' | 'fields' | 'isLoading')[]}) => {
            if (payload.includes("page")) slice.page = initialState.page;
            if (payload.includes("fields")) slice.fields = initialState.fields;
            if (payload.includes("isLoading")) slice.isLoading = initialState.isLoading;
        },
        openCreateAssetSetting: (slice) => {
            slice.dialogs.createAssetSetting = true;
        },
        hideCreateAssetSetting: (slice) => {
            slice.dialogs.createAssetSetting = false;
        },

        openEditAssetSetting: (slice, {payload}: {payload: { oldTitle: string, jobDescription?: TJobDescription, status?: TStatus}}) => {
            if (payload.oldTitle !== undefined) {
                slice.dialogs.editAssetSetting.oldTitle = payload.oldTitle;
                slice.dialogs.editAssetSetting.isOpen = true;
            }
            if (payload.jobDescription !== undefined) {
                slice.dialogs.editAssetSetting.jobDescription = payload.jobDescription;
                slice.dialogs.editAssetSetting.isOpen = true;
            }
            if (payload.status !== undefined) {
                slice.dialogs.editAssetSetting.status = payload.status;
                slice.dialogs.editAssetSetting.isOpen = true;
            }

        },
        hideEditAssetSetting: (slice) => {
            slice.dialogs.editAssetSetting = initialState.dialogs.editAssetSetting
        },

        openDeleteAssetSetting: (slice, {payload}: {payload: { id: string, name: string }}) => {
            if (payload.id !== undefined && payload.name !== undefined) {
                slice.dialogs.deleteAssetSetting.id = payload.id;
                slice.dialogs.deleteAssetSetting.name = payload.name;
                slice.dialogs.deleteAssetSetting.isOpen = true;
            }
        },
        hideDeleteAssetSetting: (slice) => {
            slice.dialogs.deleteAssetSetting = initialState.dialogs.deleteAssetSetting;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssetSettingsAction.pending, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isLoadingFields = true;
                slice.fields = initialState.fields;
            })
            .addCase(getAssetSettingsAction.rejected, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isLoadingFields = false;
            })
            .addCase(getAssetSettingsAction.fulfilled, (slice: TAssetsSettingsSlice, {payload}) => {
                slice.isLoading.isLoadingFields = false;
                slice.fields.fields = payload;
                if (payload[0]) slice.fields.selectedField = payload[0].name;
            })

            .addCase(addAssetSettingsAction.pending, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isAddingField = true;
            })
            .addCase(addAssetSettingsAction.rejected, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isAddingField = false;
            })
            .addCase(addAssetSettingsAction.fulfilled, (slice: TAssetsSettingsSlice, {payload}) => {
                slice.isLoading.isAddingField = false;
                slice.dialogs.createAssetSetting = initialState.dialogs.createAssetSetting;
                slice.fields.fields = payload;
            })
            .addCase(updateAssetSettingsAction.pending, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isUpdate = true;
            })
            .addCase(updateAssetSettingsAction.rejected, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isUpdate = false;
            })
            .addCase(updateAssetSettingsAction.fulfilled, (slice: TAssetsSettingsSlice, {payload}) => {
                slice.dialogs.editAssetSetting = initialState.dialogs.editAssetSetting;
                slice.isLoading.isUpdate = false;
                slice.fields.fields = payload;
            })

            .addCase(removeAssetSettingsAction.pending, (slice: TAssetsSettingsSlice, {meta: {arg}}) => {
                slice.isLoading.isDeletingField = true;
            })
            .addCase(removeAssetSettingsAction.rejected, (slice: TAssetsSettingsSlice) => {
                slice.isLoading.isDeletingField = false;
            })
            .addCase(removeAssetSettingsAction.fulfilled, (slice: TAssetsSettingsSlice, {payload}) => {
                slice.dialogs.deleteAssetSetting = initialState.dialogs.deleteAssetSetting;
                slice.isLoading.isDeletingField = false;
                slice.fields.fields = payload;
            })
            //getJobTitleVariants
            .addCase(getDataForCreateJobTitleAction.pending, (slice) => {
                slice.isLoading.dataForDialog = true;
            })
            .addCase(getDataForCreateJobTitleAction.rejected, (slice) => {
                slice.isLoading.dataForDialog = false;
            })
            .addCase(getDataForCreateJobTitleAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.dataForDialog = false;
                if (payload.jobTypeVariants !== undefined) slice.jobTypeVariants = payload.jobTypeVariants;
            })
    }
});

export const AssetsSettingsVariantsReducer = AssetsSettingsVariants.reducer;

//actions
export const {
    setPageAction,
    setSelectedFieldAction,
    openCreateAssetSetting,
    hideCreateAssetSetting,
    openEditAssetSetting,
    hideEditAssetSetting,
    openDeleteAssetSetting,
    hideDeleteAssetSetting,

    cleanUpAction, cleanAction,
} = AssetsSettingsVariants.actions;

//selectors
const selectSelf = (state: AppState): TAssetsSettingsSlice => state.assetsSettingsVariants;

export const assetsVariantPageSelector = createSelector(selectSelf, state => state.page);
export const assetsVariantFieldsSelector = createSelector(selectSelf, state => state.fields);
export const jobTypeVariantsSelector = createSelector(selectSelf, state => state.jobTypeVariants);
export const assetsVariantIsLoadingsSelector = createSelector(selectSelf, state => state.isLoading);
export const assetsDialogsSelector = createSelector(selectSelf, state => state.dialogs);



