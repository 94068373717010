import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ComponentProps, FC} from "react";
import {DialogProps} from "@mui/material/Dialog/Dialog";


export const CustomDialog: FC<DialogProps> = ({...props}) => {

    return (
        <Dialog sx={{
            '& .MuiPaper-root': {
                borderRadius: '3px',
                width: '600px',
                minHeight: '158px',
                boxShadow: ' 0px 8px 20px rgba(125, 151, 174, 0.16), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            }
        }}
                {...props}/>
    )
}

export const CustomDialogTitle: FC<ComponentProps<typeof DialogTitle>> = ({...props}) => {

    return (
        <DialogTitle sx={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: '600',
            color: '#172B4D',
            padding: '24px 24px 6px',
            letterSpacing: '0.15px'
        }}
                {...props}/>
    )
}

export const CustomDialogContent: FC<ComponentProps<typeof DialogContent>> = ({...props}) => {

    return (
        <DialogContent sx={{
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '400',
            letterSpacing: '0.15px',
            color: '#172B4D',
            padding: '0 24px',
        }}
                     {...props}/>
    )
}

export const CustomDialogActions: FC<ComponentProps<typeof DialogActions>> = ({...props}) => {

    return (
        <DialogActions sx={{padding: '24px'}}  {...props}/>
    )
}