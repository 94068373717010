import {ManageOrganizationInitialState, TExtendedCollaborator} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../shared/redux/rootReducer";
import {
    CancelOrganizationInvite, DeleteOrganizationCollaborator,
    GetOrganizationWithExtendedCollabsAndWorkspacesByID,
    InviteMemberIntoOrg,
    ResendOrganizationInvite, TransferOrganizationOwnership,
    UpdateCollabRole,
    UpdateInviteRole,
    UpdateOrganizationLogo,
    UpdateOrganizationName
} from "./actions";

const initialState: ManageOrganizationInitialState = {
    organization: null,
    isLoadingOrganization: true,
    otherLoading: {
        isLoadingChangeName: false,
        isLoadingChangeLogo: false,
        isLoadingInviteUser: false,
        isLoadingChangeCollabRole: {
            isLoading: false,
            prevRole: null,
            publicId: null,
        },
        isLoadingChangeInviteRole: {
            isLoading: false,
            prevRole: null,
            email: null,
        },
        isLoadingResendInvite: {
            isLoading: false,
            email: null
        },
        isLoadingCancelInvite: {
            isLoading: false,
            email: null
        },
        isLoadingSendInvite: false,
        isLoadingDeleteCollab: false,
        isLoadingTransferOwnership: false,
    },
    deleteCollaboratorDialog: {
        collab: null,
        isOpen: false,
        orgId: null,
    },
    transferOwnershipDialog: {
        collab: null,
        isOpen: false,
        orgId: null,
    }
}

export const ManageOrganizationSlice = createSlice({
    name: 'ManageOrganization',
    initialState,
    reducers: {
        setPrevUserRole: (slice, {payload}: {payload: {prevRole: string, publicId: string, newRole: string}}) => {
            //actions to set it before changing user role
            //to change it on ui and silently send request
            //if reqeust fails - return back old role
            if(slice.organization){
                slice.organization.collaborators = slice.organization.collaborators.map(e => e.publicId === payload.publicId ? {...e, role: payload.newRole} : e);
                slice.otherLoading.isLoadingChangeCollabRole = {
                    isLoading: false,
                    publicId: payload.publicId,
                    prevRole: payload.prevRole
                }
            }
        },
        prevUserRoleRollback: (slice) => {
            if(slice.organization && slice.otherLoading.isLoadingChangeCollabRole.publicId && slice.otherLoading.isLoadingChangeCollabRole.prevRole){
                slice.organization.collaborators = slice.organization.collaborators.map(e => e.publicId === slice.otherLoading.isLoadingChangeCollabRole.publicId  ? {...e, role: slice.otherLoading.isLoadingChangeCollabRole.prevRole! } : e);
                slice.otherLoading.isLoadingChangeCollabRole = {
                    isLoading: false,
                    publicId: null,
                    prevRole: null
                }
            }
        },
        setPrevInviteRole: (slice, {payload}: {payload: {prevRole: string, email: string, newRole: string}}) => {
            //actions to set it before changing invite role
            //to change it on ui and silently send request
            //if reqeust fails - return back old role
            if(slice.organization){
                slice.organization.invites = slice.organization.invites.map(e => e.emailAddress === payload.email ? {...e, role: payload.newRole} : e);
                slice.otherLoading.isLoadingChangeInviteRole = {
                    isLoading: false,
                    email: payload.email,
                    prevRole: payload.prevRole
                }
            }
        },
        prevInviteRoleRollback: (slice) => {
            if(slice.organization && slice.otherLoading.isLoadingChangeInviteRole.email && slice.otherLoading.isLoadingChangeInviteRole.prevRole){
                slice.organization.invites = slice.organization.invites.map(e => e.emailAddress === slice.otherLoading.isLoadingChangeInviteRole.email  ? {...e, role: slice.otherLoading.isLoadingChangeInviteRole.prevRole! } : e);
                slice.otherLoading.isLoadingChangeInviteRole = {
                    isLoading: false,
                    email: null,
                    prevRole: null
                }
            }
        },
        setIsLoadingResendInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.otherLoading.isLoadingResendInvite = {isLoading: true, email: payload.email};
        },
        setIsLoadingCancelInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.otherLoading.isLoadingCancelInvite = {isLoading: true, email: payload.email};
        },
        openDeleteCollaboratorDialog: (slice, {payload} : {payload: {collab: TExtendedCollaborator, orgId: string}}) => {
            slice.deleteCollaboratorDialog = {
                isOpen: true,
                collab: payload.collab,
                orgId: payload.orgId
            }
        },
        hideDeleteCollaboratorDialog: (slice) => {
            slice.deleteCollaboratorDialog = {
                isOpen: false,
                collab: null,
                orgId: null
            }
        },

        openTransferOwnershipDialog: (slice, {payload} : {payload: {collab: TExtendedCollaborator, orgId: string}}) => {
            slice.transferOwnershipDialog = {
                isOpen: true,
                collab: payload.collab,
                orgId: payload.orgId
            }
        },
        hideTransferOwnershipDialog: (slice) => {
            slice.transferOwnershipDialog = {
                isOpen: false,
                collab: null,
                orgId: null
            }
        },
        cleanUp: () => initialState,
    },
    extraReducers: builder => {
        builder
        //GetOrganizationWithExtendedCollabsAndWorkspacesByID
            .addCase(GetOrganizationWithExtendedCollabsAndWorkspacesByID.pending, (slice) => {
                slice.isLoadingOrganization = true;
            })
            .addCase(GetOrganizationWithExtendedCollabsAndWorkspacesByID.rejected, (slice) => {
                slice.isLoadingOrganization = false;
            })
            .addCase(GetOrganizationWithExtendedCollabsAndWorkspacesByID.fulfilled, (slice, {payload}) => {
                slice.isLoadingOrganization = false;
                slice.organization = payload;
            })
        //UpdateOrganizationName
            .addCase(UpdateOrganizationName.pending, (slice) => {
                slice.otherLoading.isLoadingChangeName = true;
            })
            .addCase(UpdateOrganizationName.rejected, (slice) => {
                slice.otherLoading.isLoadingChangeName = false;
            })
            .addCase(UpdateOrganizationName.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeName = false;
                if(slice.organization){
                    slice.organization.name = payload.newName;
                    // slice.organization === {...slice.organization, name: payload.newName};
                }
            })
        //UpdateOrganizationLogo
            .addCase(UpdateOrganizationLogo.pending, (slice) => {
                slice.otherLoading.isLoadingChangeLogo = true;
            })
            .addCase(UpdateOrganizationLogo.rejected, (slice) => {
                slice.otherLoading.isLoadingChangeLogo = false;
            })
            .addCase(UpdateOrganizationLogo.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeLogo = false;
                if(slice.organization){
                    slice.organization.logo = payload.newLogo;
                    // slice.organization === {...slice.organization, name: payload.newName};
                }
            })
        //UpdateCollabRole
            .addCase(UpdateCollabRole.pending, (slice) => {
                slice.otherLoading.isLoadingChangeCollabRole.isLoading = true;
            })
            .addCase(UpdateCollabRole.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeCollabRole.isLoading = false;
            })
            .addCase(UpdateCollabRole.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeCollabRole = {
                    isLoading: false,
                    prevRole: null,
                    publicId: null,
                };
            })
        //UpdateInviteRole
            .addCase(UpdateInviteRole.pending, (slice) => {
                slice.otherLoading.isLoadingChangeInviteRole.isLoading = true;
            })
            .addCase(UpdateInviteRole.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeInviteRole.isLoading = false;
            })
            .addCase(UpdateInviteRole.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingChangeInviteRole = {
                    isLoading: false,
                    prevRole: null,
                    email: null,
                };
            })
        //ResendOrganizationInvite
            .addCase(ResendOrganizationInvite.pending, (slice) => {
                slice.otherLoading.isLoadingResendInvite.isLoading = true;
            })
            .addCase(ResendOrganizationInvite.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingResendInvite.isLoading = false;
            })
            .addCase(ResendOrganizationInvite.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingResendInvite = {
                    isLoading: false,
                    email: null,
                };
                //TODO update invite (if needed) - now sentDate is not visible so not updating
            })
        //CancelOrganizationInvite
            .addCase(CancelOrganizationInvite.pending, (slice) => {
                slice.otherLoading.isLoadingCancelInvite.isLoading = true;
            })
            .addCase(CancelOrganizationInvite.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingCancelInvite.isLoading = false;
            })
            .addCase(CancelOrganizationInvite.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingCancelInvite = {
                    isLoading: false,
                    email: null,
                };
                if(slice.organization){
                    slice.organization.invites = slice.organization.invites.filter(e => e.id !== payload.inviteId);
                }
            })
        //InviteMemberIntoOrg
            .addCase(InviteMemberIntoOrg.pending, (slice) => {
                slice.otherLoading.isLoadingSendInvite = true;
            })
            .addCase(InviteMemberIntoOrg.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingSendInvite = false;
            })
            .addCase(InviteMemberIntoOrg.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingSendInvite = false;
                if(slice.organization){
                    slice.organization.invites.push(payload);
                }
            })
        //DeleteOrganizationCollaborator
            .addCase(DeleteOrganizationCollaborator.pending, (slice) => {
                slice.otherLoading.isLoadingDeleteCollab = true;
            })
            .addCase(DeleteOrganizationCollaborator.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingDeleteCollab = false;
            })
            .addCase(DeleteOrganizationCollaborator.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingDeleteCollab = false;
                slice.deleteCollaboratorDialog = {collab: null, isOpen: false, orgId: null};
                if(slice.organization){
                    slice.organization.collaborators = slice.organization.collaborators.filter(e => e.publicId !== payload.publicId);
                }
            })
        //TransferOrganizationOwnership
            .addCase(TransferOrganizationOwnership.pending, (slice) => {
                slice.otherLoading.isLoadingTransferOwnership = true;
            })
            .addCase(TransferOrganizationOwnership.rejected, (slice, {payload}) => {
                slice.otherLoading.isLoadingTransferOwnership = false;
            })
            .addCase(TransferOrganizationOwnership.fulfilled, (slice, {payload}) => {
                slice.otherLoading.isLoadingTransferOwnership = false;
                slice.transferOwnershipDialog = {collab: null, isOpen: false, orgId: null};
                if(slice.organization){
                    const fromCollab = slice.organization.collaborators.find(e => e.publicId === payload.transferFromPublicId);
                    const toCollab = slice.organization.collaborators.find(e => e.publicId === payload.transferToPublicId);

                    if(fromCollab && toCollab){
                        slice.organization.collaborators = slice.organization.collaborators.map(e => {
                            if(e.publicId === payload.transferFromPublicId){
                                return {...e, role: 'member'};
                            }
                            if(e.publicId === payload.transferToPublicId){
                                return {...e, role: fromCollab.role};
                            }
                            return e;
                        });
                    }
                }
            })
        //InviteMemberIntoWorkspace
    }
});

export const ManageOrganizationReducer = ManageOrganizationSlice.reducer;

export const {
    setPrevUserRole,
    prevUserRoleRollback,
    setPrevInviteRole,
    prevInviteRoleRollback,
    cleanUp,
    setIsLoadingResendInvite,
    setIsLoadingCancelInvite,
    openDeleteCollaboratorDialog,
    hideDeleteCollaboratorDialog,

    openTransferOwnershipDialog,
    hideTransferOwnershipDialog
} = ManageOrganizationSlice.actions;

const selectSelf = (state: AppState): ManageOrganizationInitialState => state.ManageOrganization as ManageOrganizationInitialState;

export const isLoadingOrganization = createSelector(selectSelf, state => state.isLoadingOrganization);
export const organization = createSelector(selectSelf, state => state.organization);
export const otherLoading = createSelector(selectSelf, state => state.otherLoading);
export const deleteCollaboratorDialog = createSelector(selectSelf, state => state.deleteCollaboratorDialog);
export const transferOwnershipDialog = createSelector(selectSelf, state => state.transferOwnershipDialog);






