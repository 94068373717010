import {emailTemplatesInitialState, TEmailTemplate} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {AddEmailTemplatesAdmin, DeleteEmailTemplatesAdmin, GetAllEmailTemplatesAdmin, UpdateEmailTemplatesAdmin} from "./actions";

export const initialState: emailTemplatesInitialState = {
    templates: [],
    selectedTemplate: null,
    dialogs: {
        deleteTemplate: false
    },
    loadings: {
        isLoadingAdd: false,
        isLoadingDelete: false,
        isLoadingGet: false,
        isLoadingUpdate: false
    }
}

export const EmailTemplatesSlice = createSlice({
    name: 'emailTemplates',
    initialState,
    reducers: {
        selectTemplate: (slice, {payload}: {payload: TEmailTemplate}) => {slice.selectedTemplate = payload},
        deselectTemplate: (slice) => {slice.selectedTemplate = null},
        openDeleteTemplateDialog: (slice) => {slice.dialogs.deleteTemplate = true},
        hideDeleteTemplateDialog: (slice) => {slice.dialogs.deleteTemplate = false},
        cleanUp: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllEmailTemplatesAdmin.pending, (slice) => {
                slice.loadings.isLoadingGet = true;
            })
            .addCase(GetAllEmailTemplatesAdmin.rejected, (slice) => {
                slice.loadings.isLoadingGet = false;
            })
            .addCase(GetAllEmailTemplatesAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingGet = true;
                slice.templates = payload;
            })
        //AddEmailTemplatesAdmin
            .addCase(AddEmailTemplatesAdmin.pending, (slice) => {
                slice.loadings.isLoadingAdd = true;
            })
            .addCase(AddEmailTemplatesAdmin.rejected, (slice) => {
                slice.loadings.isLoadingAdd = false;
            })
            .addCase(AddEmailTemplatesAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingAdd = false;
                slice.templates = [...slice.templates, payload]
            })
        //UpdateEmailTemplatesAdmin
            .addCase(UpdateEmailTemplatesAdmin.pending, (slice) => {
                slice.loadings.isLoadingUpdate = true;
            })
            .addCase(UpdateEmailTemplatesAdmin.rejected, (slice) => {
                slice.loadings.isLoadingUpdate = false;
            })
            .addCase(UpdateEmailTemplatesAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUpdate = false;
                slice.templates = slice.templates.map(e => e.id === payload.id ? payload : e)
            })
        //DeleteEmailTemplatesAdmin
            .addCase(DeleteEmailTemplatesAdmin.pending, (slice) => {
                slice.loadings.isLoadingDelete = true;
            })
            .addCase(DeleteEmailTemplatesAdmin.rejected, (slice) => {
                slice.loadings.isLoadingDelete = false;
            })
            .addCase(DeleteEmailTemplatesAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDelete = false;
                slice.templates = slice.templates.filter(e => e.id !== payload.id);
                slice.dialogs.deleteTemplate = false;
            })
    }
})

export const EmailTemplatesReducer = EmailTemplatesSlice.reducer;

export const {
    selectTemplate,
    deselectTemplate,
    openDeleteTemplateDialog,
    hideDeleteTemplateDialog,
    cleanUp
} = EmailTemplatesSlice.actions;

const selectSelf = (state: AppState):emailTemplatesInitialState => state.EmailTemplates as emailTemplatesInitialState;

export const templates = createSelector(selectSelf, state => state.templates);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loading = createSelector(selectSelf, state => state.loadings);
export const selectedTemplate = createSelector(selectSelf, state => state.selectedTemplate);
