import {Dialog, DialogContent as DialogContentBase, styled} from "@mui/material";

export const DialogContent = styled(DialogContentBase)` 
  & .MuiPaper-root {
      width: 100%;
      height: 100%;
      
      
      overflow: hidden;
  }

  padding: 0;
  display: flex;
  flex-direction: column;
  
  //gap: 10px;
`

export const EditorDialogStyled = styled(Dialog)(() => ({
    "& .MuiDialog-paper": {
        width: '100vw',
        height: '100vh',
    },

    '& .MuiPopper-root': {
        zIndex: 200,
    },
}))