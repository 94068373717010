import React, {Fragment, useContext, useMemo} from "react";
import styled, {ThemeContext} from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useHistory, useLocation} from "react-router-dom";
import {buildTitlePathList, hiddenLeftBarMenu} from "../../leftSideBar/helpers";
import {makeVar, useReactiveVar} from "@apollo/client";
import {TTitlePath} from "../../leftSideBar/types";
import {useMainTranslation} from "../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import { FlexAround, FlexStart } from "../../../../shared/components/Layouts";
import {Typo} from "../../../../shared/components/Typography";

export const customTitles = makeVar<TTitlePath[]>([])

const ArrowForwardIosIconStyled = styled(ArrowForwardIosIcon)`
  font-size: 14px !important;
  color: #90A0B7;
`;

const ArrowBackwardIosIconStyled = styled(ArrowBackIosIcon)`
  font-size: 14px !important; 
  color: #90A0B7;
`

const applyCursorPointer = (isPathDefined: boolean, lastIndex: number, currentIndex: number) => {
    return isPathDefined && lastIndex > currentIndex ? {cursor: 'pointer'} : { cursor: 'default'};
}

export const NavigationBar = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'navigationMenu'});

    const {pathname} = useLocation();
    const route = useMemo(() => buildTitlePathList(pathname), [pathname]);
    const customTitlesAndPaths: TTitlePath[] = useReactiveVar(customTitles);
    const history = useHistory();
    const { narrow } = useContext(ThemeContext);
    return (
        <>
            <FlexStart key={pathname}>
                {hiddenLeftBarMenu(pathname)
                || route.titlesAndPaths().filter(({title}) => !!title)
                    .concat(customTitlesAndPaths)
                    .map(({ title, path }, index, list) => (
                        <Fragment key={title + JSON.stringify(list)}>
                            <Typo color={'#90A0B7'}
                                  fontSize={narrow ? '12px' : '18px'}
                                  onClick={() => path && history.push(path)}
                                  shrink={index === list.length - 1 ? true : undefined}
                                  style={index === list.length - 1 && list.length > 1 && title.length > 30 ? {maxWidth: narrow ? '50px' : '150px'} : {}}
                                  {...applyCursorPointer(!!path, list.length - 1, index)}
                            >{path ? t(title) : title}</Typo>
                            {index < (list.length - 1) && (
                                <FlexAround
                                    p={'2px 0 0 0'}
                                    minw={'30px'}
                                    h={'30px'}
                                    w={'40px'}
                                    d={'column'}
                                >
                                    {!revDir ? <ArrowBackwardIosIconStyled /> : <ArrowForwardIosIconStyled/>}
                                </FlexAround>
                            )}
                        </Fragment>
                    ))
                }
            </FlexStart>
        </>
    )
}
