import {createSelector, createSlice} from "@reduxjs/toolkit";
import {TAuthSlice} from "../types";
import {loginAction, SendEmailSupport} from "./actions";
import {AppState} from "../../../../shared/redux/rootReducer";

const initialState: TAuthSlice = {
  isLoading: false,
  isError: false,
  errMessage: '',
  hasToken: false,
  contactDialog: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    openContactDialog(state){ state.contactDialog = true},
    hideContactDialog(state){ state.contactDialog = false},
  },
  extraReducers: builder => {
    builder
        .addCase(loginAction.fulfilled, (slice) => {
      slice.isLoading = false;
    }).addCase(loginAction.pending,(slice) => {
      slice.isLoading = true;
      slice.isError = false;
      slice.errMessage = '';
    }).addCase(loginAction.rejected,(slice, { payload }) => {
      slice.isLoading = false;
      slice.isError = true;
      slice.errMessage = payload as string;
    })
    //SendEmailSupport
        .addCase(SendEmailSupport.fulfilled, (slice) => {
          slice.isLoading = false;
          slice.contactDialog = false;
        }).addCase(SendEmailSupport.pending,(slice) => {
      slice.isLoading = true;
      slice.isError = false;
      slice.errMessage = '';
    }).addCase(SendEmailSupport.rejected,(slice, { payload }) => {
      slice.isLoading = false;
      slice.isError = true;
      slice.errMessage = payload as string;
    })
  }
});

export const authReducer = authSlice.reducer;

export const {
  openContactDialog,
  hideContactDialog,
} = authSlice.actions;

const selectSelf = (state: AppState):TAuthSlice => state.auth as TAuthSlice;

export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const contactDialog = createSelector(selectSelf, state => state.contactDialog);
