import {IKeyIndex, ItemTypes, TEditorActor, TEditorStageApprover} from "../../../../../../types";
import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import debounce from "lodash.debounce";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {ReactComponent as GroupIcon} from "../../../../../../../../../shared/images/documents/group-icon.svg";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField
} from "@mui/material";
import * as S from "../../../styled";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {TypographyBodySmall} from "../../../../../../../Inputs/styled";
import {CommonAvatar} from "../../../../../../../Basic/CommonAvatar";
import {getName} from "../../../../../../../../utils/text";

import {ReactComponent as MessageIcon} from "../../../../../../../../../shared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../../../shared/images/documents/complete-message-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";

interface ApproversGroupProps {
    approver: TEditorStageApprover,
    addedApprovers: string[],
    index: number,
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TEditorStageApprover, index: number} | null
    isApproverCustomOrder: boolean;
    apprGroupIds: string[];
    handleDeleteGroup: (approveId: string) => void;
    handleSetNameGroup: (approveId: string, groupId: string, name: string) => void;
    toggleIsRequired: (approverId: string, groupId: string ) => void;
    handleAddGroupActor: (approverId: string, groupId: string, actor: TEditorActor ) => void;
    handleDeleteGroupActor: (approverId: string, groupId: string, email: string) => void;
    handleOpenAddGroupMessage: (data: {approverId: string, groupId: string, oldMessage: string}) => void;
    isLoadingEmployees: boolean;
    employees: TEditorActor[];
    getEmployees: (value: string) => void;
    isStaff?: boolean;
}
export const ApproverGroupEditor: FC<ApproversGroupProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        approver,
        addedApprovers,
        index,
        apprGroupIds,
        handleDeleteGroup,
        handleSetNameGroup,
        toggleIsRequired,
        handleAddGroupActor,
        handleDeleteGroupActor,
        isApproverCustomOrder,
        employees,
        getEmployees,
        isLoadingEmployees,
        //dnd
        handleOpenAddGroupMessage,
        moveColumn,
        findColumn,
        isStaff
    } = props;

    const [newApprover, setNewApprover] = useState<TEditorActor | null>(null);
    const [newApproverInput, setNewApproverInput] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const isOk = () => {
        if (newApprover) return true;
        else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return newApproverInput.length > 0 && re.test(newApproverInput);
        }
    }


    const handleAdd = (groupId: string) => {
        if (isOk()) {
            approver.id && handleAddGroupActor(approver.id, groupId, newApprover ? newApprover : ({email: newApproverInput, firstName: '', lastName: ''}))
            setNewApproverInput("");
            setNewApprover(null);
        } else {
            setError('Invalid email');
        }
    }


    // eslint-disable-next-line
    useEffect(debounce(() => {
        getEmployees(newApproverInput)
        error && setError(null);
        // debounce(() => {getEmployees(newApproverInput)}, 500);
        // error && setError(null);

        //eslint-disable-next-line
    }, 500), [newApproverInput]);

    //DND
    const searchedColumn = findColumn(approver.id ?? '');
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: approver.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey ?? '', originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [approver.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: IKeyIndex) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== approver.id) {
                    const col = findColumn(approver.id ?? '')
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    );


    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'16px'} width={'100%'} >
            {isApproverCustomOrder && <S.OrderBox>{index + 1}</S.OrderBox>}

            <Box marginLeft={isApproverCustomOrder ? '16px' : 0} width={'100%'}>
                { approver.groups.map((group, index) => (

                    <S.InfoActorContainer
                        key={group.id}
                        isOneApprover={!apprGroupIds.find(grId => grId === group.id) ? true : undefined}
                        isDragging={isDragging ? true : undefined}
                        ref={(node: ConnectableElement) => !isStaff && drag(drop(node))}
                    >
                        <DragIndicatorIcon sx={{opacity: isApproverCustomOrder && !isStaff ? 0 : 1, marginRight: '6px'}}/>

                        { apprGroupIds.find(grId => grId === group.id) ?
                            <Box display={"flex"} alignItems={'center'} width={'100%'} flexDirection={'column'} >
                                <S.InfoActorFlexBox >

                                    <TextField variant={'standard'}
                                               size={"small"}
                                               disabled={isStaff}
                                               value={group.name} placeholder={t('Name this group')}
                                               onChange={(e) => approver.id && group.id &&  handleSetNameGroup(approver.id, group.id, e.target.value)}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <GroupIcon />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />

                                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                        <Checkbox disabled={isStaff} checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                        <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                    </Box>

                                    <S.VerticalDivider />
                                    <IconButton disabled={isStaff} size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})}>
                                        {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                    </IconButton>

                                    <S.VerticalDivider />
                                    <IconButton disabled={isStaff} onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                        <DeleteIcon />
                                    </IconButton>

                                </S.InfoActorFlexBox>
                                {!isStaff &&
                                    <Box display={'flex'} alignItems={'center'} width={'100%'} marginTop={'8px'}>
                                        <S.AutocompleteBox>
                                            <Autocomplete
                                                freeSolo
                                                options={employees.filter(emp => !addedApprovers.find(act => act === emp.email))}
                                                getOptionLabel={(e) => (e as TEditorActor).email}
                                                filterOptions={(options, state) => options
                                                    .filter(emp => emp?.firstName.toLowerCase().includes(newApproverInput)
                                                        || emp?.lastName.toLowerCase().includes(newApproverInput)
                                                        || emp?.email.toLowerCase().includes(newApproverInput))}
                                                renderOption={(props, option, state) => (
                                                    <li {...props}>
                                                        <S.ActorRowBox>
                                                            <CommonAvatar
                                                                name={`${option.firstName ?? option.email} ${option.lastName ?? option.email}`}
                                                                size={"d40"}/>
                                                            <S.ActorDataContainer>
                                                                <S.TypographyName>{`${option.firstName} ${option.lastName}`}</S.TypographyName>
                                                                <S.TypographyEmail>{`${option.email}`}</S.TypographyEmail>
                                                            </S.ActorDataContainer>
                                                        </S.ActorRowBox>
                                                        <S.ActorRowDivider/>
                                                    </li>
                                                )}
                                                sx={{width: '450px', '& label': {top: '0px !important'}}}
                                                value={newApprover}
                                                onChange={(event, value) => value && setNewApprover(value as TEditorActor)}
                                                inputValue={newApproverInput}
                                                onInputChange={(e, value) => setNewApproverInput(value)}

                                                // onInputChange={debounce((e, value) => {getEmployees(value)}, 500)}
                                                loading={isLoadingEmployees}
                                                renderInput={(params) =>
                                                    <TextField {...params} size={'small'} label={t('Email or name')}
                                                               error={Boolean(error)}
                                                               helperText={error ? 'Invalid email' : undefined}
                                                               InputProps={{
                                                                   ...params.InputProps, endAdornment: (
                                                                       <>
                                                                           {isLoadingEmployees ?
                                                                               <CircularProgress color="inherit"
                                                                                                 size={20}/> : null}
                                                                           {params.InputProps.endAdornment}
                                                                       </>),
                                                               }}
                                                    />}
                                            />
                                            <Button size={'small'} sx={{marginLeft: '16px'}}
                                                    onClick={() => group.id && handleAdd(group.id)}>{t('Add')}</Button>
                                        </S.AutocompleteBox>
                                    </Box>
                                }

                                {group.actors.map((actor, index) => (
                                    <S.InfoActorBox key={`actor ${index}`} h={'43px'} mt={'12px'}>
                                        <CommonAvatar name={getName(actor.firstName || actor.email, actor.lastName || '')} size={'d28'} />
                                        <S.ApproverTitle>{actor.email}</S.ApproverTitle>
                                        <DeleteIcon onClick={() => approver.id && group.id && handleDeleteGroupActor(approver.id, group.id, actor.email) } style={{margin: '0 8px'}} />
                                    </S.InfoActorBox>
                                ))}
                            </Box>
                            :
                            <>
                                <CommonAvatar size={'d28'} name={getName(group.actors[0].firstName || group.actors[0].email, group.actors[0].lastName || group.actors[0].email)}/>
                                <S.ApproverTitle>{group.actors[0].email}</S.ApproverTitle>

                                <S.VerticalDivider/>
                                <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                    <Checkbox disabled={isStaff} checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                    <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                </Box>

                                <S.VerticalDivider />
                                <IconButton disabled={isStaff} size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})} >
                                    {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                </IconButton>

                                <S.VerticalDivider />
                                <IconButton disabled={isStaff} onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    </S.InfoActorContainer>
                ))}
            </Box>
        </Box>
    )
}