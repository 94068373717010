import {LABEL} from "../../constants";
import React from "react";
import LogoRegulaitWhite, {ReactComponent as LogoRegulaitWhiteComp} from "../../../shared/images/logo/regulait-logo-white.svg"
import LogoComplicoWhite, {ReactComponent as LogoComplicoWhiteComp} from "../../../shared/images/logo/complico-logo-white.svg"

import LogoRegulaitBlack from "../../../shared/images/logo/regulait-logo-black.svg"
import LogoComplicoBlack from "../../../shared/images/logo/complico-logo-black.svg"


export const TobBarLogo = () => {
    switch (LABEL){
        case "Regulait": return <LogoRegulaitWhiteComp style={{margin: '0 16px'}}/>;
        case "Complico": return <LogoComplicoWhiteComp style={{margin: '0 16px'}}/>;
        default: return <LogoRegulaitWhiteComp style={{margin: '0 16px'}}/>;
    }
}

export const LoginLogo = ({white = false}: {white?: boolean}) => {
    switch (LABEL){
        case "Regulait": {
            if(white){
                return <img src={LogoRegulaitWhite} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }else{
                return <img src={LogoRegulaitBlack} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }
        }
        case "Complico": {
            if(white){
                return <img src={LogoComplicoWhite} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }else{
                return <img src={LogoComplicoBlack} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }
        }
        default: {
            if(white){
                return <img src={LogoComplicoWhite} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }else{
                return <img src={LogoComplicoBlack} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} />
            }
        }
    }
}