import {useDispatch, useSelector} from "react-redux";
import {controls, isLoading, isLoadingControls} from "../../store/slice";
import {useEffect} from "react";
import {GetPrivateControls} from "../../store/actions";
import {useParameters} from "../../../../../shared/hooks/useParameters";

export const useControls = () => {
    const dispatch = useDispatch();
    const _controls = useSelector(controls);
    const {filter} = useParameters();

    useEffect(() => {
        dispatch(GetPrivateControls({page: 0, count: 10, filters: filter || {}}));
        //eslint-disable-next-line
    }, [])

    return {
        _controls,
        isLoading: useSelector(isLoadingControls),
        isLoadingDialogs: useSelector(isLoading),
    }
}
