import {useSelector} from "react-redux";
import {Typo} from "../../../../../shared/components/Typography";
//TABLE
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material/styles";
import {extendedOrganization} from "../../store/slice";
import {useHistory} from "react-router-dom";
import {PATH_ACCOUNTS} from "../../../../BarsENV/shell/constants";
import {makeFirstLetterUpper} from "../../../../../shared/utils/text";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#56657F',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

    '&': {
        padding: 0
    }
}));

export const OrganizationCollabsTable = () => {
    const history = useHistory();
    const extendedOrg = useSelector(extendedOrganization);

    const handleGoToCollab = (id: string) => {
        history.push(PATH_ACCOUNTS + `?page=0&count=5&publicId=${id}`)
    }

    // const handleGoToEmail = (email: string) => {
    //     history.push(PATH_ACCOUNTS + `?page=0&count=5&email=${email}`)
    // }

        return(
            <>
                {!extendedOrg?.collaborators && <Typo>Organization has no collaborators</Typo>}
                {extendedOrg && extendedOrg.collaborators.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700, maxHeight: '60vh', margin: 'dense'}} aria-label="customized table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="left">Email</StyledTableCell>
                                    <StyledTableCell align="left">ID</StyledTableCell>
                                    <StyledTableCell align="left">Role</StyledTableCell>
                                    <StyledTableCell align="left">Status</StyledTableCell>
                                    <StyledTableCell align="left">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {extendedOrg?.collaborators.map((row) => (
                                    <StyledTableRow key={row.publicId} onClick={() => handleGoToCollab(row.publicId)} hover sx={{cursor: 'pointer'}}>
                                        <StyledTableCell align="left">
                                            <Typo>{row.firstName} {row.lastName}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo>{row.email}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo>{row.publicId}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo>{makeFirstLetterUpper(row.role)}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo color={!row.status ? 'red' : 'green'}>{!row.status ? 'Blocked' : 'Active'}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo>- - -</Typo>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}

                                {/*{extendedOrg?.invites.map((row) => (*/}
                                {/*    <StyledTableRow key={row.inviteId} onClick={() => handleGoToEmail(row.email)} hover sx={{cursor: 'pointer'}}>*/}
                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>No name (it's invite)</Typo>*/}
                                {/*        </StyledTableCell>*/}

                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>{row.email}</Typo>*/}
                                {/*        </StyledTableCell>*/}

                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>{row.inviteId} (inviteId)</Typo>*/}
                                {/*        </StyledTableCell>*/}

                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>{makeFirstLetterUpper(row.role)}</Typo>*/}
                                {/*        </StyledTableCell>*/}

                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>{row.status}</Typo>*/}
                                {/*        </StyledTableCell>*/}

                                {/*        <StyledTableCell align="left">*/}
                                {/*            <Typo>- - -</Typo>*/}
                                {/*        </StyledTableCell>*/}
                                {/*    </StyledTableRow>*/}
                                {/*))}*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
        )
}