import {TRole} from "../../types";
import {openDeleteRoleDialog, openEditRoleDialog, openPreviewRoleDialog} from "../../store/slice";
import {useDispatch} from "react-redux";

export const useRoleRow = ({role}: {role: TRole}) => {
    const dispatch = useDispatch();

    const handleEditRole = (role: TRole) => {
        dispatch(openEditRoleDialog(role));
    }

    const handleDeleteRole = (role: TRole) => {
        dispatch(openDeleteRoleDialog(role));
    }

    const handlePreviewRole = (role: TRole) => {
        dispatch(openPreviewRoleDialog(role));
    }

    return{
        actions: {
            handleEditRole,
            handleDeleteRole,
            handlePreviewRole
        }
    }
}