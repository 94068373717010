import {FC, useEffect} from 'react';
import {Flex} from "../../../shared/components/Layouts";
import {Typo} from "../../../shared/components/Typography";
import {assets} from "./constants";
import {useAssetsVariants} from "./hooks/useAssetsVariants";
import {SecondMenuRow} from "../../../shared/components/SecondMenuRow";
import {LeftSecondMenuColumn} from "../../../shared/components/LeftSecondMenuColumn";
import {AssetSettingsExact} from "./components/assetSettingsExact";
import {DeleteAssetSettingDialog} from "./components/dialogs/deleteAssetSetting";
import {EditAssetSettingDialog} from "./components/dialogs/editAssetSetting";
import {AddAssetSettingDialog} from "./components/dialogs/addAssetSetting";

export const AssetsVariants: FC = () => {

    const {
        getFields,
        page, setPage,
    } = useAssetsVariants();

    useEffect(() => {
        getFields(page)

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <Flex w={'100%'} h={'100%'} overflow={'hidden'} p={'6px 12px'}>
            <LeftSecondMenuColumn>
                {assets.map((asset) => (
                    <SecondMenuRow
                        key={asset}
                        onClick={() => setPage(asset)}
                        jc={'center'}
                        ai={'center'}
                        p={'8px 18px'}
                        h={'auto'}
                        active={page === asset}
                    >
                        <Typo fontWeight={600} fontSize={'20px'}>{asset}</Typo>
                    </SecondMenuRow>
                ))}
            </LeftSecondMenuColumn>

            <Flex
                grow={'1'}
                m={'0 0 0 18px'}
                // direction={"column"}
                // p={'18px'}
                br={'12px'}
                box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                background={'#fff'}
            >
                {page === "employees" && <AssetSettingsExact/>}
                {page === "vendors" && <AssetSettingsExact/>}
                {page === "physicalAssets" && <AssetSettingsExact/>}
                {page === "virtual" && <AssetSettingsExact/>}
                {page === "saas" && <AssetSettingsExact/>}
                {page === "certificate" && <AssetSettingsExact/>}
                {page === "software" && <AssetSettingsExact/>}
            </Flex>

            <DeleteAssetSettingDialog />
            <EditAssetSettingDialog />
            <AddAssetSettingDialog />
        </Flex>
    )
} 