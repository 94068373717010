import {gql} from "graphql.macro";
import {NewDocumentFragment} from "./fragments";

export const adminGetDocumentsRefactor = gql`
    query adminGetDocumentsRefactor {
        adminGetDocumentsRefactor {
            ...NewDocumentFragment
        }
    }
    ${NewDocumentFragment}
`;

export const adminGetDocumentByIdRefactor = gql`
    query adminGetDocumentByIdRefactor($id: String!) {
        adminGetDocumentByIdRefactor(id: $id) {
            ...NewDocumentFragment
        }
    }
    ${NewDocumentFragment}
`;

export const adminCreateDocumentsRefactor = gql`
    mutation adminCreateDocumentsRefactor($id: String!, $name: String!) {
        adminCreateDocumentsRefactor(id: $id, name: $name) {
            ...NewDocumentFragment
        }
    }
    ${NewDocumentFragment}
`;

export const adminUpdateDocumentsRefactor = gql`
    mutation adminUpdateDocumentsRefactor($id: String!, $document: NewDocumentInput!) {
        adminUpdateDocumentsRefactor(id: $id, document: $document) {
            ...NewDocumentFragment
        }
    }
    ${NewDocumentFragment}
`;

export const adminDeleteDocumentsRefactor = gql`
    mutation adminDeleteDocumentsRefactor($id: String!) {
        adminDeleteDocumentsRefactor(id: $id) {
            message
        }
    }
`;