import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    loading,
    templates
} from "../../store/slice";
import {
    TEMPLATES_LIST_PATH} from "../../constants";
import { Flex } from "../../../../../shared/components/Layouts";
import {PageTitleAndActions} from "../../../../../shared/components/pageTitleAndActions";
import {useForm} from "../../../../../shared/hooks/useForm";
import {TEmailTemplate} from "../../types";
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
// import CodeEditor from '@uiw/react-textarea-code-editor';
import {html_beautify} from 'js-beautify';
import {AddEmailTemplatesAdmin} from "../../store/actions";
import { TextField } from "@mui/material";


export const EmailTemplatesAdd = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _templates = useSelector(templates);
    const {isLoadingAdd} = useSelector(loading);

    const goBack = () => {
        history.push(TEMPLATES_LIST_PATH);
    }

    //TEMPLATE

    const {form, setForm, handleChange} = useForm<TEmailTemplate>({id: '', template: ''});

    const isOk = form.id.trim().length > 0 && form.template.trim().length > 0 && !_templates.some(e => e.id === form.id);

    const handleSave = () => {
        dispatch(AddEmailTemplatesAdmin({
            // template: {...form, template: form.template.replaceAll('\n', '')},
            template: form,
            onSuccess
        }));
    }

    const onSuccess = (id: string) => {
        history.push(TEMPLATES_LIST_PATH)
    }

    return(
        <Flex direction={'column'} overflow={'hidden'}>
            <PageTitleAndActions
                title={'Add template'}
                handleGoBack={goBack}
                actions={[
                    {
                        title: 'Create',
                        variant: 'contained',
                        color: 'success',
                        onClick: handleSave,
                        disabled: !isOk,
                        loading: isLoadingAdd
                    }
                ]}
            />

            <Flex m={'16px 0 0 0'}>
                <TextField
                    size={'small'}
                    label={'ID'}
                    value={form.id}
                    name={'id'}
                    onChange={handleChange}
                />
            </Flex>

            <Flex w={'100%'} h={'100%'} overflow={'hidden'} m={'16px 0 0 0'} jc={'space-between'} minw={'1200px'}>
                <Flex w={'58%'} h={'100%'} minw={'400px'} maxw={'800px'} overflow={'auto'}>
                    <CodeMirror
                        value={html_beautify(form.template)}
                        height={'100%'}
                        width={'100%'}
                        minWidth={'600px'}
                        extensions={[html()]}
                        onChange={(evn) => setForm({...form, template: evn})}
                    />
                </Flex>
                <Flex border={'1px solid black'} w={'40%'} overflow={'auto'} dangerouslySetInnerHTML={{__html: form?.template.replaceAll('%%', '%') ?? ''}} />
            </Flex>
        </Flex>
    )
}