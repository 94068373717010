import {createAsyncThunk} from "@reduxjs/toolkit";
import {loginApi, logoutApi, sendEmailSupport} from "../api";

import {addSuccessfulSnack} from '../../../BarsENV/snack/store/slice';


//consts
// import {setWorkspaceIdAction} from "../../../shared/hooks/useWorkspace/slice";

export const loginAction = createAsyncThunk(
    'auth/signIn',
    async ({
               username,
               password,
               onSuccess
           }: { username: string, password: string, onSuccess: (overview: boolean) => void }, {
               rejectWithValue,
               dispatch
           }) => (
        loginApi({username, password}).then((data) => {
            onSuccess(data);
            return data;
        })
    ),
);

export const logoutAction = createAsyncThunk(
    'auth/logout',
    async (_, {dispatch}) => {
        logoutApi()
    }
)


export const SendEmailSupport = createAsyncThunk(
    'auth/sendEmail',
    async (data:{email: string, name: string, message:string}, {dispatch}) => {
        sendEmailSupport('WORKER ZONE: ' + data.message, data.email, data.name).then(data => {
            dispatch(addSuccessfulSnack('Message sent!'));
        })
    }
)

//sendEmailSupport
