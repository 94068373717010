import React from "react";
import {evidenceType} from "../../../../types";
import {Box, Dialog, DialogContent, DialogTitle, Divider, TablePagination, Typography} from "@mui/material";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../../shared/components/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {Spinner} from "../../../../../../../shared/components/Spinner";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useSelectExistingEvidence} from "../../../../hooks/dialogs/useSelectExistingEvidence";
import {SearchField} from "../../../../../../../shared/components/CustomInputs";

export const SelectExistingEvidence = () => {

    const {
        isOpen,
        isLoading,
        currentControl,
        fetchedEvidences,
        pageInfo,
        handleClose,
        handleChangePage,
        handleChangeRowsPerPage,
        handleAddEvidence,
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    } = useSelectExistingEvidence();


    return(
        <Dialog maxWidth={'lg'} open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                    <Typo fontSize={'24px'} fontWeight={700} letterSpacing={'1.53px'} margin={'0 50px 0 0'}>{'Select Existing Evidence'}</Typo>
                    <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
                </Flex>
            </DialogTitle>

            <DialogContent style={{paddingBottom: '0'}}>
                <Flex w={'40vw'} direction={'column'} m={'0 0 0 0'} overflow={'hidden'}>

                    <SearchField
                        onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}
                        size={'small'}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={handleChange}
                        value={value}
                        disabled={isLoading}
                        onKeyDown={handleKeyDown}
                    />
                    <Divider sx={{mt: '8px', mb: '8px'}}/>

                    <Flex direction={'column'} w={'100%'} minh={'350px'}  maxh={'70vh'} disableScrollBar overflow={'auto'}>
                        {isLoading && <Spinner />}
                        {fetchedEvidences.map((e) => <EvidenceCard
                            evidence={e}
                            key={e.id}
                            isAdded={(currentControl?.evidences ? currentControl.evidences.some(ev => ev.id === e.id) : false)}
                            handleSelect={() => handleAddEvidence(e)}
                            />)
                        }
                    </Flex>

                    <Divider flexItem/>
                    <Flex w={'100%'} jc={'flex-end'}>
                        <TablePagination
                            sx={{overflow: 'hidden', margin: 'none'}}
                            component="div"
                            count={pageInfo.total}
                            page={pageInfo.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPage={pageInfo.count}
                            rowsPerPageOptions={[10, 50, 100, { value: pageInfo.total, label: 'All' }]}
                            showFirstButton
                            showLastButton
                        />
                    </Flex>
                </Flex>
            </DialogContent>
        </Dialog>
    )
}

const EvidenceCard = ({evidence, isAdded, handleSelect}: {evidence: evidenceType, isAdded: boolean, handleSelect: (id: string) => void}) => {

    return(
        <>
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} mt={'5px'}>
                <Flex direction={'column'} w={'85%'}>
                    <Typography fontSize={'14px'} fontWeight={700} >{evidence.name}</Typography>
                    <Box display={'flex'} width={'60%'} alignItems={'center'} justifyContent={'space-between'} >
                        <Typography fontSize={'14px'} fontWeight={500} >{evidence.id}</Typography>
                        {/*<Typography fontSize={'14px'} fontWeight={300} color={'#A0A2B1'} >{getEvidenceStatus(evidence)}</Typography>*/}
                    </Box>
                    <Typography  fontSize={'12px'} lineHeight={'1.15'} sx={{mt: '3px'}} color={'#A0A2B1'}>{evidence.description}</Typography>
                </Flex>
                <CustomButton
                    size={'small'}
                    variant={'contained'}
                    disabled={isAdded}
                    onClick={() => handleSelect(evidence.id || '')}
                    sx={{width: '100px'}}
                >
                    {isAdded ? 'Selected' : 'Select'}
                </CustomButton>

            </Box>
            <Divider sx={{mt: '8px'}}/>
        </>
    )
}
