import {FC} from 'react';
import {useLicenses} from "./hooks/useLicenses";
import {usePaginationURL} from "../../../shared/hooks/usePaginationURL";
import {Flex} from "../../../shared/components/Layouts";
import {LicensesLeftSideBar} from "./components/leftSideBar";
import {Typo} from "../../../shared/components/Typography";
import {LocalSpinner} from "../../../shared/components/Spinner";
import {LicenseInfoWithActions} from "./components/licenseInfoWithActions";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {ExistLicenseTabs} from "./components/existLicenseTabs";
import {CreatableLicenseTopBar} from "./components/createLicenseTopBar";
import {CreateLicenseTabs} from "./components/createLicenseTabs";
import {ReleaseExistingLicense} from "./components/dialogs/releaseExistingLicense";
import {DeleteLicenseDialog} from "./components/dialogs/deleteLicense";
import {CreateAndReleaseONELicense} from "./components/dialogs/createAndReleaseONELicense";
import {CreateOneLicenseDialog} from "./components/dialogs/createOneLicense";
import {CreateMultiNoReleaseLicense} from "./components/dialogs/createMiltiNoReleaseLicense";
import {CreateMultiANDReleaseLicense} from "./components/dialogs/createMiltiANDReleaseLicense";


export const License:FC = () => {
    const {getData, isExtendedLicensePresent, isLoadingExtendedLicense, isCreateMode, tab, setTab, setDefaultTab} = useLicenses();

    usePaginationURL(({page, count, filter}) => {
        getData({page, count, sort: '', filters: filter})
    }, 'left');

    return(
        <Flex w={'100%'} h={'100%'} p={"25px"}>
            <LicensesLeftSideBar setDefaultTab={setDefaultTab}/>
            <Flex grow={'1'} h={'100%'} m={'0 0 0 30px'} direction={'column'} ai={'center'}>
                {!isCreateMode && !isExtendedLicensePresent && !isLoadingExtendedLicense && <Typo>Select License</Typo>}
                {isLoadingExtendedLicense && <LocalSpinner />}
                {/*TOP BAR*/}
                {!isCreateMode && isExtendedLicensePresent && !isLoadingExtendedLicense && <LicenseInfoWithActions />}
                {isCreateMode && !isLoadingExtendedLicense && <CreatableLicenseTopBar />}

                {/*TABS CONTROL*/}
                {(isCreateMode || isExtendedLicensePresent) && !isLoadingExtendedLicense &&
                    <Flex w={'100%'}>
                        <ToggleButtonGroup
                            color="warning"
                            value={tab}
                            exclusive
                            onChange={(e, value) => {
                                value && setTab(value)
                            }}
                            size={'small'}
                            sx={{margin: '15px 0 15px 0'}}
                        >
                            <ToggleButton value="wizards">Wizards</ToggleButton>
                            <ToggleButton value="workspaces">Workspaces</ToggleButton>
                            <ToggleButton value="collaboratorsRules">Collaborators Rules</ToggleButton>
                            {!isCreateMode && <ToggleButton value="issuedDetails">Issued Details</ToggleButton>}
                            {!isCreateMode && <ToggleButton value="usedDetails">Used Details</ToggleButton>}
                        </ToggleButtonGroup>
                    </Flex>
                }

                {/*TABS*/}
                {!isCreateMode && isExtendedLicensePresent && !isLoadingExtendedLicense && <ExistLicenseTabs tab={tab}/>}
                {isCreateMode && !isLoadingExtendedLicense && <CreateLicenseTabs tab={tab}/>}
            </Flex>

            <ReleaseExistingLicense />
            <DeleteLicenseDialog />

            {/*//ONES*/}
            <CreateAndReleaseONELicense />
            <CreateOneLicenseDialog />

            {/*MULTI*/}
            <CreateMultiNoReleaseLicense />
            <CreateMultiANDReleaseLicense />
        </Flex>
    )
} 