import React, {FC} from "react";
import EditorDialog from "../../../../../../../shared/components/editorUnion";
import {useSnack} from "../../../../../../BarsENV/snack/hooks/useSnack";
import {useGallery} from "../../../../hooks/useGallery";

export const EditorJsDialog: FC = () => {

    const {
        selectedDocument,
        closeEditor,
        updateTemplate,
        isLoading,
    } = useGallery();

    const snack = useSnack();

    return selectedDocument ? (
        <EditorDialog isOpen={true}
                      documentTitle={selectedDocument?.name || ''}
                      content={selectedDocument.data?.editor || []}
                      variables={selectedDocument.data?.variables || []}
                      actors={{
                          approvers: [],
                          recipients: selectedDocument.recipients || [],
                          editors: [],
                      }}

                      currentEmail={'currentEmail'}
                      currentFileStatus={'DRAFT'}
                      execution={undefined}

                      onSave={(data, variables, docTitle, actors) => {
                          updateTemplate(selectedDocument.id, {
                              ...selectedDocument,
                              name: docTitle,
                              data: {
                                  editor: data,
                                  variables: variables,
                              },
                              recipients: actors.recipients,
                          });
                      }}
                      onSend={() => null}
                      onPreviewBeforeSend={() => null}
                      onInvalidate={() => null}
                      onClose={closeEditor}
                      onReturnToDraft={() => null}
                      onRejectSignStage={() => null}
                      onRejectApproveStage={() => null}
                      onApproveApproveStage={() => null}
                      onApproveSignStage={() => null}
                      onFillFields={() => null}
                      onResendInviteApprover={() => null}
                      onSkipApprover={() => null}
                      onResendInviteRecipient={() => null}
                      onChangeEta={() => null}

                      isLoading={{
                          isPrev: false,
                          isSave: isLoading.isUpdatingCurrent,
                          isInvalidating: false,
                          isRejecting: false,
                          isApproving: false,
                          isReinviting: false,
                          isSkipping: false,
                          isFillingField: false,
                          isSending: false,
                          isChangingEtaInProgress: false,
                      }}
                      snack={snack}

                      actualEnvironment={"isAdmin"}
        />
    ) : null;
};



