import {useDispatch, useSelector} from "react-redux";
import {extendedLicense, hideDeleteLicenseDialog, dialogs, loading} from "../../../store/slice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import {Button, DialogContentText} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {DeleteLicense} from "../../../store/actions";

export const DeleteLicenseDialog = () => {
    const dispatch = useDispatch();
    const license = useSelector(extendedLicense);
    const {deleteLicense: {isOpen}} = useSelector(dialogs);
    const {isLoadingDelete} = useSelector(loading);

    const handleClose = () => dispatch(hideDeleteLicenseDialog());

    const handleSendForm = () => {
        if(license){
            dispatch(DeleteLicense({id: license.id}));
        }
    }

    const isOk = () => license !== null;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Delete License</DialogTitle>

            <DialogContent>
                <DialogContentText>Are you sure you want delete this license?</DialogContentText>
            </DialogContent>

            {isLoadingDelete ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSendForm} disabled={!isOk()} color={'success'}>Delete</Button>
                </DialogActions>
            }
        </Dialog>
    )
}