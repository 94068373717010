import React, {FC, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {UploadFileBTN} from "../../../../../../../shared/components/FileBTN/UploadFileBTN";
import {toBase64} from "../../../../../../../shared/utils/base64";
import {Delete} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {
    addControlsAndEvidencesFromFile,
    hideAddControlsAndEvidencesFromFile,
    isLoadingAddControlsAndEvidencesFromFile
} from "../../../../store/slice";
import {TBuilderAdminAddFiles} from "../../../../types";
import {addControlsAndEvidencesFromFilesAction, GetBuilderData} from "../../../../store/actions";
import {LoadingButton} from "@mui/lab";


export const AddControlsAndEvidencesDialog: FC = () => {

    const dispatch = useDispatch();
    const isUploadingFiles = useSelector(isLoadingAddControlsAndEvidencesFromFile);
    const isOpen = useSelector(addControlsAndEvidencesFromFile);

    const defaultEdit = {evidencesFile: null, controlsFile: null, controlEvidencesFile: null, controlFrameworkFile: null};
    const [edit, setEdit] = useState<Record<keyof TBuilderAdminAddFiles, {file: string, fileName: string} | null>>(defaultEdit);

    const addFromFiles = (files: TBuilderAdminAddFiles) => {
        dispatch(addControlsAndEvidencesFromFilesAction({files}));
        dispatch(GetBuilderData());
    };

    const handleSave = () => {
        addFromFiles({
            controlsFile: edit.controlsFile?.file,
            evidencesFile: edit.evidencesFile?.file,
            controlEvidencesFile: edit.controlEvidencesFile?.file,
            controlFrameworkFile: edit.controlFrameworkFile?.file,
        })
    }

    const handleClose = () => {
        if (isUploadingFiles) return;

        dispatch(hideAddControlsAndEvidencesFromFile());
        setEdit(defaultEdit);
    };

    useEffect(() => {
        setEdit(defaultEdit)
        // eslint-disable-next-line
    }, [isOpen])


    return (
        <Dialog open={isOpen} >
            <DialogTitle>
                <Typography fontWeight={600} fontSize={'24px'}>{'Add controls and evidences from files'}</Typography>
            </DialogTitle>
            <DialogContent sx={{gap: '18px', padding: '4px 0', display: 'flex', flexDirection: 'column', p: '10px', width: 'min(500px, 90vw)'}}>
                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Controls File: ' + (edit.controlsFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'controlsFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, controlsFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.controlsFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, controlsFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Evidences File: ' + (edit.evidencesFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'evidencesFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, evidencesFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.evidencesFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, evidencesFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Control-Evidences File: ' + (edit.controlEvidencesFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'controlEvidencesFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, controlEvidencesFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.controlEvidencesFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, controlEvidencesFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Control-Framework File: ' + (edit.controlFrameworkFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'controlFrameworkFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, controlFrameworkFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.controlFrameworkFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, controlFrameworkFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>

            </DialogContent>

            <DialogActions>
                <Button variant={"outlined"} onClick={handleClose} disabled={isUploadingFiles}>Cancel</Button>
                <LoadingButton variant={"contained"} onClick={handleSave} loading={isUploadingFiles}
                               disabled={!edit.controlsFile && !edit.evidencesFile && !edit.controlFrameworkFile && !edit.controlEvidencesFile}>
                    Upload
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
