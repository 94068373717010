import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeletePermissionDialog, loading, roles} from "../../../store/slice";
import {DeletePermissionByAdmin} from "../../../store/actions";

export const useDeletePermissionDialog = () => {
    //root
    const dispatch = useDispatch();
    const _roles = useSelector(roles);
    const {isOpen, permission} = useSelector(dialogs).deletePermission;
    const isLoading = useSelector(loading).permissionsDelete;

    //actions
    const handleClose = () => {
        dispatch(hideDeletePermissionDialog());
    }

    const handleSubmit = () => {
        if(permission){
            dispatch(DeletePermissionByAdmin({permissionName: permission.name}));
        }
    }

    const permissionUsedInRoles = _roles.filter(e => e.permissions.some(p => permission?.name === p))

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        permission,
        permissionUsedInRoles,
        isPermissionUsedInSomeRole: permissionUsedInRoles.length > 0
    }
}