import {
    hideLeftSideBarWhenPaths,
    hideProtectedTopBarPaths,
    rootMenu,
    unprotectedPaths,
    unprotectedTopBarPaths,
} from "../constants"
import {LeftSideBarItem, MenuRoute} from "../types";

const search = (pathname: string, menu: LeftSideBarItem): LeftSideBarItem | undefined => {
    if (!menu.isItComplex() && menu.path && pathname.indexOf(menu.path) > -1) {
        return menu;
    }
    let stop = false;
    const list = menu.getItems() ?? [];
    const length = list.length;
    for (let i = 0; i < length && !stop; i++) {
        const result = search(pathname, list[i]);
        if (result) {
            return result;
        }
    }
}
export const buildTitlePathList = (pathname: string): MenuRoute => {
    let item = search(pathname, rootMenu);
    const route = new MenuRoute();
    if (!item) {
        return route.default(pathname);
    }
    do {
        route.put(item);
        item = item.root as LeftSideBarItem;
    } while(item?.root);
    return route;
}

export const showUnProtectedTopBar = (pathname: string) => unprotectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const hiddenLeftBarMenu = (pathname: string) => hideLeftSideBarWhenPaths.some( el => typeof el === 'string' ? el === pathname : el(pathname));
export const isUnProtectedPaths = (pathname: string) => unprotectedPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const showProtectedTopBar = (pathname: string) => !hideProtectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const hideTopBar = (pathname: string) => !(showProtectedTopBar(pathname) || showUnProtectedTopBar(pathname));

