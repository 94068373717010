import {useDispatch, useSelector} from "react-redux";
import {deletePolicyDialog, hideDeletePolicy, policies} from "../../../../store/slice";
import {Flex} from "../../../../../../../shared/components/Layouts";
import CloseIcon from "@material-ui/icons/Close";
import {DialogContent, TextField} from "@mui/material";
import {Typo} from "../../../../../../../shared/components/Typography";
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {WarningDialog} from "../../../styled";
import React, {useEffect, useState} from "react";
import {policyType} from "../../../../types";
import {DeletePolicy} from "../../../../store/actions";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeletePolicyDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, policyId} = useSelector(deletePolicyDialog);
    const _policies = useSelector(policies);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});


    const [currentPolicy, setCurrentPolicy] = useState<policyType | null>(null);
    const [field, updateField] = useState<string>('');

    useEffect(() => {
        updateField('');
        if(policyId){
            const currPolicy = _policies.find(e => e.id === policyId);
            if(currPolicy) setCurrentPolicy(currPolicy);
        }else{
            setCurrentPolicy(null);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideDeletePolicy());
    }
    
    const handleConfirm = () => {
        if(field.trim().toLowerCase() === 'confirm' && policyId){
            dispatch(DeletePolicy(policyId));
        }
    }

    return((isOpen && policyId && currentPolicy) ?
        <WarningDialog
            // maxWidth={''}
            style={{borderRadius: '25px !important', border: '1px solid red'}}
            open={isOpen}
            onClose={handleClose}
        >
            <Flex w={'100%'} jc={'flex-end'} p={!revDir ? '30px 30px 0 0' : '30px 0 0 30px'}>
                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
            </Flex>

            <DialogContent>
                <Flex w={'100%'} direction={'column'} ai={'center'} talign={'center'} p={'0 50px'}>
                    <Typo fontSize={'25px'} fontWeight={300} letterSpacing={'1.53px'} color={'#1D1D1D'}>{t('Delete Document')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} margin={'27px 0 0 0'} color={'background: rgba(0, 0, 0, 0.54)'}>{t('Any changes will be effects all pipelines that the document has attached!')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'}>Effected on {currentPolicy?.pipeLinesCounter || 0} pipelines</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'} margin={'20px 0 10px 0'}>{t('Please type')} “confirm” {t('to delete')}</Typo>

                    <TextField id="filled-basic" label={t("Confirm")}  variant="outlined" sx={{margin: '0 0 20px 0', width: '400px'}} value={field} onChange={(e) => {updateField(e.target.value as string)}}/>

                    
                    {/* <ButtonWithBackround
                        margin={'0 0 10px 0 '}
                        width={'214px'}
                        height={'37px'}
                        color={field.trim().toLowerCase() === 'confirm' ? '#FF0033' : 'gray'}
                        text={'DELETE'}
                        fontColor={'white'}
                        fontSize={'16px'}
                        fontWeight={600}
                        onClick={handleConfirm}
                    /> */}
                    <CustomButton
                        variant={'contained'}
                        color={'lightError'}
                        disabled={field.trim().toLowerCase() !== 'confirm'}
                        onClick={handleConfirm}
                        sx={{marginBottom: '10px'}}
                    >
                        {t('DELETE')}
                    </CustomButton>
                </Flex>
            </DialogContent>
        </WarningDialog>
            :
            null
    )
}