import {FC} from 'react';
import { Flex } from '../../../shared/components/Layouts';
import { Routes } from './routes';
import {useMainAccessManagement} from "./hooks/useMain";


export const AccessManagement:FC = () => {
    useMainAccessManagement();

    return(
        <Flex w={'100%'} h={'100%'}>
            <Routes />
        </Flex>
    )
} 