import React, {FC} from "react";
import {Popover, Divider} from "@mui/material";
import {FlexAround, FlexStart} from "../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../shared/components/Typography";
import {useMainTranslation} from "../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {UseManageWorkspacesAndOrganizations} from "../../../../../AuthWorkspaceAndCookies/newWorkspaces/hooks/useManageWorkspacesAndOrganizations";

type PropTypes = {
    handleClose: () => void;
    anchorEl: any;
    openChangeLanguageDialog?: (visibility: boolean) => void;
};

export const UserMenu: FC<PropTypes> = (props: PropTypes) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'navigationSettingDropdown'});

    const {logout} = UseManageWorkspacesAndOrganizations();

    const { handleClose, anchorEl } = props;
    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: !revDir ? 'right' : 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: !revDir ? 'right' : 'left',
            }}
            style={{zIndex: 3001}}
        >
            <FlexAround d="column" w={'200px'} p="4px 0px 4px 0">
                <>
                    {/*<FlexStart p={"12px 12px"} onClick={(e) => {*/}
                    {/*    e?.stopPropagation();*/}
                    {/*    history.push(PATH_LOCAL_USER_PROFILE);*/}
                    {/*    handleClose();*/}
                    {/*}}>*/}
                    {/*    <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 0 0" color="#9E9E9E" cursor="pointer">{t('Edit Profile')}</Typo>*/}
                    {/*</FlexStart>*/}
                    {/*<Divider orientation={'horizontal'} style={{width: '100%', borderBottom: '1px solid light-gray'}} light />*/}

                    {/*{SHOW_CHANGE_LANGUAGE_IN_TOPBAR &&*/}
                    {/*<FlexStart p={"12px 10px"} onClick={(e) => {*/}
                    {/*    e?.stopPropagation();*/}
                    {/*    openChangeLanguageDialog(true);*/}
                    {/*    handleClose();*/}
                    {/*}}>*/}
                    {/*    <Typo fontSize="14px" align="left" fontWeight={400} letterSpacing="0.4px" margin="0 0 0 0" color="#9E9E9E" cursor="pointer">{t('Change Language')}</Typo>*/}
                    {/*</FlexStart>*/}
                    {/*}*/}

                    <Divider orientation={'horizontal'} style={{width: '100%', borderBottom: '1px solid light-gray'}} light />


                    {/*<FlexStart p={"12px 10px"} onClick={(e) => {*/}
                    {/*    e?.stopPropagation();*/}
                    {/*    history.push(PATH_LOCAL_MANAGE_WORKSPACES);*/}
                    {/*    handleClose();*/}
                    {/*}}>*/}
                    {/*    <Typo fontSize="14px" align="left" fontWeight={400} letterSpacing="0.4px" margin="0 0 0 0" color="#9E9E9E" cursor="pointer">Change Workspace</Typo>*/}
                    {/*</FlexStart>*/}
                    {/*<Divider orientation={'horizontal'} style={{width: '100%', borderBottom: '1px solid light-gray'}} light />*/}
                    <FlexStart p={"12px 10px"} onClick={(e) => {
                        e?.stopPropagation();
                        logout();
                        handleClose();
                    }}>
                        <Typo fontSize="14px" align="left" fontWeight={400} letterSpacing="0.4px" margin="0 0 0 0" color="#9E9E9E" cursor="pointer">Logout</Typo>
                    </FlexStart>
                </>
            </FlexAround>
        </Popover>
    )
};
