import axiosSync from '../../../../shared/utils/axios/axios';
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import * as GQL from "./query";
import {TTask, TTaskCategory, TTaskFiles, TTaskOrder} from "../types";

export const vicaAdminGetCategoriesApi = async (data = undefined): Promise<{categories: TTaskCategory[], flow: TTaskOrder[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminGetCategories,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminGetCategories;
    })
)

export const vicaAdminGetTasksByCategoryIdApi = async (data: {categoryId: string, frameworkView: boolean}): Promise<TTask[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminGetTasksByCategoryId,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminGetTasksByCategoryId;
    })
)

export const vicaAdminGetTaskByIdApi = async (data: {taskId: string, categoryId: string, frameworkView: boolean}): Promise<TTask> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminGetTaskById,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminGetTaskById;
    })
)

export const vicaAdminCreateTaskApi = async (data: {task: TTask, categoryId: string, frameworkView: boolean}): Promise<TTask> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminCreateTask,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminCreateTask;
    })
)

export const vicaAdminUpdateTaskApi = async (data: {task: TTask, categoryId: string, frameworkView: boolean, moveToCategoryId?: string}): Promise<TTask> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminUpdateTask,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminUpdateTask;
    })
)

export const vicaAdminDeleteTaskApi = async (data: {taskId: string, categoryId: string, frameworkView: boolean}): Promise<{message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminDeleteTask,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminDeleteTask;
    })
)

export const vicaAdminCreateCategoryApi = async (data: {category: TTaskCategory, orders: TTaskOrder[]}): Promise<TTaskCategory> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminCreateCategory,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminCreateCategory;
    })
)

export const vicaAdminUpdateCategoryApi = async (data: {category: TTaskCategory, orders: TTaskOrder[], tasks?: TTask[]}): Promise<TTaskCategory> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminUpdateCategory,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminUpdateCategory;
    })
)

export const vicaAdminChangeCategoriesOrderApi = async (data: {order: TTaskOrder[]}): Promise<TTaskOrder[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminChangeCategoriesOrder,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminChangeCategoriesOrder;
    })
)

export const vicaAdminDeleteCategoryApi = async (data: {categoryId: string}): Promise<{message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminDeleteCategory,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminDeleteCategory;
    })
)

export const vicaAdminAddFromFilesApi = async (data: {files: TTaskFiles}): Promise<{categories: TTaskCategory[], flow: TTaskOrder[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: GQL.vicaAdminAddFromFiles,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.vicaAdminAddFromFiles;
    })
)
