import {useDispatch, useSelector} from "react-redux";
import {Flex} from "../../../../../shared/components/Layouts";
import {
    extendedOrganization,
    openActivateOrgDialog,
    // openAddOrgCollabDialog,
    openBlockOrgDialog,
    openChangeAvailableCollabsCounterDialog,
    openChangeAvailableWSCounterDialog,
    openChangeOrgCollabsRulesDialog,
    openChangeOrgSubDialog,
    openChangeOrgSubExpiryDialog,
    openChangeOrgTrialDialog,
    // openChangeOrgWizardDialog,
    openChangeVulTargetsDialog,
    openDeleteOrgDialog,
    // openTransferOwnershipDialog
} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import {IconButton} from "@mui/material";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {calculateColorByDate} from "../../helpers";
import {handleGetSubColor, handleGetSubNormalName} from "../../../accounts/helpers";
import {parseDate} from "../../../../../shared/utils/dateTools";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const OrgInfoAndActions = () => {
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);

    //DIALOGS
    const openChangeSubDialog = () => dispatch(openChangeOrgSubDialog());
    const openChangeSubExpiryDialog = () => dispatch(openChangeOrgSubExpiryDialog());
    const openChangeWSCounterDialog = () => dispatch(openChangeAvailableWSCounterDialog());
    const openChangeCollabsCounterDialog = () => dispatch(openChangeAvailableCollabsCounterDialog());
    const openChangeTrialDialog = () => dispatch(openChangeOrgTrialDialog());
    const openActivateDialog = () => dispatch(openActivateOrgDialog());
    const openBlockDialog = () => dispatch(openBlockOrgDialog());
    const openDeleteDialog = () => dispatch(openDeleteOrgDialog());
    const openCollabRulesDialog = () => dispatch(openChangeOrgCollabsRulesDialog());
    // const openWizardDialog = () => dispatch(openChangeOrgWizardDialog());
    // const openTransferOwnership = () => dispatch(openTransferOwnershipDialog());
    // const openAddCollab = () => dispatch(openAddOrgCollabDialog());
    const openChangeVulTargetsCounterDialog = () => dispatch(openChangeVulTargetsDialog());

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    return(
        org ?
            <Flex
                minh={'255px'}
                w={'100%'}
                jc={'space-between'}
                p={'18px'}
                br={'12px'}
                box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                background={'#fff'}
            >
                <Flex w={'35%'} direction={'column'} jc={'space-between'}>
                    <Typo margin={'10px 0 0 0'}>{org.name}</Typo>
                    <Typo margin={'15px 0 10px 0'}>{org.id}</Typo>
                    <Flex ai={'center'}>
                        <Typo color={handleGetSubColor(org.subscription)}>{handleGetSubNormalName(org.subscription)}</Typo>
                        <IconButton onClick={openChangeSubDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>

                    <Flex ai={'center'}>
                        <Typo color={calculateColorByDate(org.subscriptionExpiry)}>{parseDate(org.subscriptionExpiry, momentLocale)}</Typo>
                        <IconButton onClick={openChangeSubExpiryDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>
                </Flex>

                <Flex w={'35%'} direction={'column'} jc={'space-between'}>
                    <Flex ai={'center'}>
                        <Typo>Available WS: {org.configuration.availableWorkspaceCount}</Typo>
                        <IconButton onClick={openChangeWSCounterDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>

                    <Flex ai={'center'} >
                        <Typo>Available collabs: {org.configuration.availableCollaboratorsCount}</Typo>
                        <IconButton onClick={openChangeCollabsCounterDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>

                    <Flex ai={'center'} >
                        <Typo>Available vulnerability targets : {org.configuration.availableTargetsVulnerabilityScan}</Typo>
                        <IconButton onClick={openChangeVulTargetsCounterDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>

                    <Flex ai={'center'}>
                        <Typo>Trial: {org.trialUsed ? 'Used' : 'Unused'}</Typo>
                        <IconButton onClick={openChangeTrialDialog} size={'small'}>
                            <SettingsIcon />
                        </IconButton>
                    </Flex>
                    {org.blocked && <Typo margin={'10px 0 0 0'} color={'red'}>Organization blocked with {org.blockedReason ? `reason: ${org.blockedReason}` : 'no reason'}</Typo>}
                </Flex>


                <Flex w={'15%'} direction={'column'} jc={'space-between'}>
                    {org.blocked ?
                        <CustomButton variant={'contained'} color={'success'} size={'small'} onClick={openActivateDialog}>Active</CustomButton>
                        :
                        <CustomButton variant={'contained'} color={'error'} size={'small'} onClick={openBlockDialog}>Block</CustomButton>
                    }
                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'error'} size={'small'} onClick={openDeleteDialog}>Delete</CustomButton>
                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={openCollabRulesDialog}>Collab Rules</CustomButton>
                    {/*<CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={openWizardDialog}>Wizard Settings</CustomButton>*/}
                    {/*<CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={openTransferOwnership}>Transfer</CustomButton>*/}
                    {/*<CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={openAddCollab}>Add Collab</CustomButton>*/}
                </Flex>
            </Flex>
            :
            <></>
    )
}