import {useDispatch} from "react-redux";
import { openPreviewEvidence} from "../../store/slice";
import React from "react";
import {Flex} from "../../../../../shared/components/Layouts";
import {Typo} from "../../../../../shared/components/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Box, Popover} from "@material-ui/core";
import {
    Divider,
    TablePagination,
    Typography
} from "@mui/material";
import {evidenceType} from "../../types";
import EditIcon from "@material-ui/icons/Edit";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import DeleteIcon from "@material-ui/icons/Delete";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {SearchField} from "../../../../../shared/components/CustomInputs";
import {useBuilderEvidencesTable} from "../../hooks/useBuilderEvidencesTable";

export const BuilderEvidencesTable = () => {
    const {revDir} = useMainTranslation('');

    const {
        selectedEvidenceId,
        isLoading,
        isEvidencesPage,
        isControlsPage,
        evidences,
        pageInfo,
        search,
        setSearch,
        currentControl,
        onSelect,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        handleChoose,
        handleEditEvidence,
        handleDeattachEvidence,
        handleDeleteEvidence,
        handleChangeRowsPerPage,
        handleChangePage,
        //filter
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    } = useBuilderEvidencesTable();


    return((currentControl || isEvidencesPage) ?
        <Flex
            w={'49.5%'}
            h={'95%'}
            direction={'column'}
            background={'white'}
            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
            br={'10px'}
            p={isEvidencesPage ? '0' : '0 0 20px 0'}
        >
            <Flex w={'100%'} p={!revDir ? '17px 20px 10px 15px' : '17px 15px 10px 20px'} jc={'space-between'} ai={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Typo fontSize={'24px'} color={'rgba(0, 0, 0, 0.54)'}>{'Evidences'}</Typo>
                    <AddCircleIcon style={{color: '#FAC000', marginLeft: '16px'}} onClick={(event) => handlePopoverOpen(event, 'origin2')}/>
                    <Popover
                        open={anchorEl.id === null ? false : anchorEl.id === 'origin2'}
                        style={{boxShadow: 'none'}}
                        elevation={0}
                        anchorEl={anchorEl.anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Flex direction="column" w="103px" h="87px" border={'0.3px solid #9E9E9E'} br={'4px'}>
                            <Flex direction="column">
                                <Box onClick={() => handleChoose('new')} p={'14px 7px'}>
                                    <Typo fontSize="12px" fontWeight={400} letterSpacing="0.4px" cursor="pointer" color={'#9E9E9E'}>{'Create new'}</Typo>
                                </Box>
                                <Divider flexItem/>
                                <Box onClick={() => handleChoose('exists')} p={'14px 7px'}>
                                    <Typo fontSize="12px" fontWeight={400} letterSpacing="0.4px" cursor={"pointer"} color={'#9E9E9E'}>{'Use existing'}</Typo>
                                </Box>
                            </Flex>
                        </Flex>
                    </Popover>

                </Box>

                {isEvidencesPage ?
                    <SearchField
                        onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}
                        size={'small'}
                        sx={{ml: '10px', width: '320px'}}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={handleChange}
                        value={value}
                        disabled={isLoading}
                        onKeyDown={handleKeyDown}
                    />
                    :
                    <SearchField
                        onClear={() => {setSearch('')}}
                        size={'small'}
                        sx={{ml: '10px', width: '320px'}}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        // disabled={isLoading}
                    />
                }
            </Flex>

            <Divider flexItem style={{ margin: !revDir ? '0 20px 0 15px' : '0 15px 0 20px', borderColor: '#FAC000'}} />

            <Flex w={'100%'} direction={'column'} disableScrollBar overflow={'auto'} h={'100%'} maxh={isEvidencesPage || isControlsPage ? '95vh' : '70vh'}>
                {(!isEvidencesPage && currentControl?.evidences)
                    && currentControl.evidences
                        .filter(e => e.name.toLowerCase().includes(search.toLowerCase()) || e.id.toLowerCase().includes(search.toLowerCase()))
                        .map((e: evidenceType) =>
                            <EvidenceCard
                                key={e.id}
                                evidence={e}
                                currId={selectedEvidenceId || ''}
                                onSelect={onSelect}
                                openEditDialog={handleEditEvidence}
                                handleDeattachEvidence={handleDeattachEvidence}
                                controlOrigin={currentControl.origin}
                                isEvidencesPage={isEvidencesPage || false}
                                deleteEvidence={handleDeleteEvidence}
                            />
                        )}

                {(isEvidencesPage) && evidences.map((e: evidenceType) =>
                    <EvidenceCard
                        key={e.id}
                        evidence={e}
                        currId={selectedEvidenceId || ''}
                        onSelect={onSelect}
                        openEditDialog={handleEditEvidence}
                        handleDeattachEvidence={handleDeattachEvidence}
                        controlOrigin={false}
                        isEvidencesPage={isEvidencesPage || false}
                        deleteEvidence={handleDeleteEvidence}
                    />
                )}


            </Flex>
            {isEvidencesPage &&
            <>
                <Divider flexItem/>
                <Flex w={'100%'} jc={'flex-end'}>
                    <TablePagination
                        sx={{
                            overflow: 'hidden', margin: 'none',
                            '& .MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '0'} : {marginRight: '10px'})},
                            '& .MuiToolbar-root .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '10px'} : {marginRight: '10px'})},
                        }}
                        component="div"
                        count={pageInfo.total}
                        page={pageInfo.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPage={pageInfo.count}
                        rowsPerPageOptions={[10, 50, 100, { value: pageInfo.total, label: 'All' }]}
                        showFirstButton
                        showLastButton
                    />
                </Flex>
            </>
            }
        </Flex>
        :
        <></>
    )
}

const EvidenceCard = ({evidence, currId, onSelect, openEditDialog, handleDeattachEvidence, controlOrigin, isEvidencesPage, deleteEvidence}:
                         {evidence: evidenceType, currId: string | null,
                             onSelect: (id: string | null) => void,
                             openEditDialog: (e: any, id: string) => void,
                             handleDeattachEvidence: (e: any, id: string) => void,
                             controlOrigin: boolean,
                             isEvidencesPage: boolean,
                             deleteEvidence: (e: any, id: string) => void
                         }) => {
    const isSelected = Boolean(currId && currId === evidence.id);
    const isGapExists = Boolean(evidence.gap !== null && evidence.gap !== undefined);
    const isOwn: boolean  = true;
    const dispatch = useDispatch();

    // const { revDir} = useMainTranslation('', {keyPrefix: 'builderPage.BuilderEvidencesTable.row'});
    // console.log(`isSelected: ${isSelected} | isGapExists: ${evidence.gap !== null && evidence.gap !== undefined} | evidence.gap: ${JSON.stringify(evidence.gap)}`);
    // console.log(`(isGapExists && !isSelected) : ${(isGapExists && !isSelected)}`);
    return(
        <>
            <Flex
                w={'100%'}
                jc={'space-between'}
                ai={'center'}
                // direction={revDir ? 'row-reverse': 'row'}
                onClick={() => onSelect(evidence.id)}
                background={isSelected ? '#F1F2F2' : 'white'}
            >
                {(isSelected) ? (isGapExists ? <Flex w={'5px'} h={'90px'} background={'green'} position={'relative'}/> : <Flex w={'5px'} h={'90px'} background={'#14CBFD'} position={'relative'}/>) : null}
                {(isGapExists && !isSelected) && <Flex w={'5px'} h={'90px'} background={'green'} position={'relative'}/>}

                <Flex w={'calc(90% - 5px)'} direction={'column'} p={'8px 0 8px 30px'}>
                    <Typography fontSize={'16px'} fontWeight={400}>{evidence.name} </Typography>
                    <Flex ai={'center'} jc={'space-between'} w={'70%'}>
                        <Typography fontSize={'14px'} fontWeight={300}   >{evidence.id}</Typography>
                        {/*<Typo fontSize={'16px'} fontWeight={300} color={'#A0A2B1'} margin={'7px 0'}>{getEvidenceVisibilityStatus(evidence)}</Typo>*/}
                    </Flex>

                    <Flex ai={'center'} wrap={'wrap'}>
                        <Typography fontSize={'12px'} color={'#A0A2B1'} >{`Attached controls: ${evidence.foundInControlsCounter ?? 0}`}</Typography>
                    </Flex>
                    <Flex ai={'center'} wrap={'wrap'}>
                        {isGapExists && <Typo fontSize={'12px'} fontWeight={300} color={'#A0A2B1'} >{'| gap: '}</Typo>}
                        {Object.keys(evidence.gap ?? {}).map(e => <Flex key={e} background={'green'} m={'0 0 0 5px'} br={'4px'} p={'2px'}><Typo color={'#fff'} fontSize={'10px'}>{e}</Typo></Flex>)}
                    </Flex>

                </Flex>

                <Flex ai={'center'} jc={isOwn ? 'flex-end' : 'space-between'} m={'0 20px 0 0'}>
                        <VisibilityIcon
                            style={{color: '#A0A2B1', cursor: 'pointer', margin: '0 10px 0 0'}}
                            onClick={(e) => dispatch(openPreviewEvidence(evidence))}
                        />
                        <>
                            {isOwn && !isEvidencesPage ?
                                <EditIcon
                                    style={{color: '#A0A2B1', cursor: 'pointer', margin: '0 10px 0 0'}}
                                    onClick={(e) => openEditDialog(e, evidence.id || '')}
                                />
                                :
                                <>
                                    <Flex w={'32px'}/>
                                </>

                            }
                            {isEvidencesPage ?
                                <DeleteIcon
                                    style={{color: '#A0A2B1', cursor: 'pointer'}}
                                    onClick={(e) => deleteEvidence(e,evidence.id || '')}
                                />
                                : <LinkOffIcon style={{color: '#A0A2B1', cursor: 'pointer'}} onClick={(e) => handleDeattachEvidence(e, evidence.id || '')}/>
                            }
                        </>
                </Flex>

                {/*{(isSelected && revDir) && <Flex w={'5px'} h={'90px'} background={'#14CBFD'} position={'absolute'}/>}*/}
            </Flex>

            <Divider flexItem style={{margin: '0 25px 0 20px'}} />
        </>
    )
}
