import React, {FC, useContext} from "react";
import {Boxed, MenuItemStyled, StyledMenu, StyledPop, Titled, Triangle} from "../../../styles";
import {LeftSideBarItem} from "../../../types";
import {ACTIVE_MENU} from "../../../constants";
import {ThemeContext} from "styled-components";

type PropTypes = {
    anchor: HTMLDivElement | null;
    handleClose: () => void;
    handleActive: (item: LeftSideBarItem) => void;
    isOpen: boolean;
    item: LeftSideBarItem | null;
    secondLevel: LeftSideBarItem | undefined;
    opacity: string,
    setOpacity: (name: string, newValue:string) => void;

};

export const PopMenu: FC<PropTypes> = (props: PropTypes) => {
    const {anchor, isOpen, handleClose, item, secondLevel, handleActive, opacity} = props;
    const { bit, short } = useContext(ThemeContext);
    const onClose = () => {
        handleClose();
    }
    return (
        <>
            <StyledMenu
                id="fade-menu"
                anchorEl={anchor}
                open={isOpen}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <StyledPop opacity={opacity}>
                    {item && <Triangle/>}
                    <Boxed>
                        {item?.getItems()?.map(el => (
                            <MenuItemStyled onClick={() => {
                                handleClose();
                                handleActive(el as LeftSideBarItem);
                            }} key={el.title} >
                                <Titled fs={bit? "10px" : short ? '12px' : '14px'}  {...secondLevel?.title === el.title && { color: ACTIVE_MENU}}
                                    primary={el.title}
                                />
                            </MenuItemStyled>))
                        }
                    </Boxed>
                </StyledPop>
            </StyledMenu>
        </>

    )
};
