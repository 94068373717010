import React, {useMemo, useState} from "react";
import {DocumentNode, useLazyQuery} from "@apollo/client";
import debounce from "lodash.debounce";
import {DEBOUNCE_TIMEOUT, LOAD_FILTERS_VALUES_ON_MOUNT} from "../../../constants";
import {store} from "../../../redux";
import {OperationDefinitionNode} from "graphql/language/ast";

const buildVariants = (filterValues: { [key: string]: string[] }, category: string): string[] => {
    return filterValues[category] || []
};

const EMPTY_OBJECT = {};
export const useDownloadCategories = (queryCategory: DocumentNode, category: string, localValues: string[], isVirtual = false) => {
    const [inputObj, setInputObj] = useState<{ [key: string]: string }>(() => ({[category]: ''}));
    const [loadValues, {loading, data}] = useLazyQuery(queryCategory);
    const loadDebounced = useMemo(() => debounce((options: any) => loadValues(options), DEBOUNCE_TIMEOUT)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);
    const queryName = (queryCategory.definitions[0] as OperationDefinitionNode)?.name?.value ?? 'mockFilterData';
    React.useEffect(() => {
        const [category, value] = Object.entries(inputObj).filter(([key, value]) => !!value)[0] ?? [];
        if (category && value && value?.length > 0 && !(localValues.length > 0 || isVirtual)) {
            loadDebounced({
                variables: {
                    workspaceId: store.getState().SharedWorkspacesAndOrganizations.multistore.selectedWorkspace?.id || '',
                    data: {
                        fieldName: category,
                        value
                    }
                },
                skip: localValues.length > 0
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputObj]);
    React.useEffect(() => {
        if (LOAD_FILTERS_VALUES_ON_MOUNT && !(localValues.length > 0 || isVirtual)) {
            loadValues({
                variables: {
                    workspaceId: store.getState().SharedWorkspacesAndOrganizations.multistore.selectedWorkspace?.id || '',
                    data: {
                        fieldName: category,
                        value: 'REGEX_EXPRESSION.'
                    },
                    skip: localValues.length > 0
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const variants = localValues.length > 0 ? localValues : buildVariants((data ? (Array.isArray(data[queryName]) ? data[queryName][0] : data[queryName]) ?? EMPTY_OBJECT : EMPTY_OBJECT), category);
    return useMemo(() => ({
        loading, variants, inputObj, setInputObj
    })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [loading, JSON.stringify(variants), inputObj]);
}
