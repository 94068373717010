import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useDeleteRoleDialog} from "../../../hooks/dialogs/useDeleteRoleDialog";
import {LoadingButton} from "@mui/lab";

export const DeleteRoleDialog = () => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        role,
    } = useDeleteRoleDialog();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Delete role')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography variant={'body2'}>{(`Role ${role?.name} will be deleted. All users with such role will loose their access.`)}</Typography>
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{('Are you sure you want to delete this role?')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: '0 16px 0 0'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    color={'error'}
                >
                    {('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}