import React, {FC, useState} from "react";
import {CompactedItem} from "./CompactedItem";
import {menu, WIDTH_COMPACTED} from "../constants";
import {LeftSideBarItem, TAnchorItem} from "../types";
import {PopMenu} from "./CompactedItem/Popover";
import {useHistory} from "react-router-dom";
import {PATH_ACCOUNTS} from "../../shell/constants";
import {AnimatedLeftSideBar} from "../styles";

type PropTypes = {
    activeRoute: LeftSideBarItem[];
};

const initialAnchor = {
    anchor: null,
    item: null,
};

export const CompactedMenu: FC<PropTypes> = (props: PropTypes) => {
    const {activeRoute: [firstLevel, secondLevel]} = props;
    const [opacity, setOpacity] = useState("0");
    const [{anchor, item}, setAnchor] = useState<TAnchorItem>(initialAnchor);
    const history = useHistory();
    const handleActive = (item: LeftSideBarItem) => {
        if (!(item?.isItComplex() ?? true)) {
            setAnchor(initialAnchor);
            history.push(item?.path ?? PATH_ACCOUNTS)
        }
    }

    const [opacityMainMenu, setOpacityMainMenu] = useState("0.9");
    React.useEffect(() => {
        setTimeout(() => setOpacityMainMenu("1"), 0)
    }, []);

    return (
        <>
            <AnimatedLeftSideBar w={WIDTH_COMPACTED} opacity={opacityMainMenu}>
                {(menu ?? []).map((item) =>
                    <CompactedItem key={`ii:${item.title}`} item={item} isActive={firstLevel.title === item.title}
                                   handleActive={handleActive} setAnchor={setAnchor}
                                   setOpacity={setOpacity}
                    />)}
            </AnimatedLeftSideBar>
            {Boolean(item) && !!item?.isItComplex() && <PopMenu
                key={item.title}
                opacity={opacity}
                setOpacity={setOpacity}
                item={item}
                anchor={anchor}
                handleClose={() => {
                    setAnchor(initialAnchor);
                }}
                handleActive={handleActive}
                isOpen={Boolean(item) && !!item?.isItComplex()}
                secondLevel={secondLevel}
            />
            }
        </>
    )
};
