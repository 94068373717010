import {multiStoreDataType, multiStoreInitState} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../shared/redux/rootReducer";

export const initialState: multiStoreInitState = {
    multistore: {
        selectedOrganization: null,
        selectedWorkspace: null,

        roles: {
            isOrgOwner: false,
            isOrgAdmin: false,
            isOrgMember: false,

            isWsOwner: false,
            isWsAdmin: false,
            isWsEditor: false,
            isWsViewer: false,
            isWsOutsideCollab: false,
        },

        currentUser: null,

        isAuthComplete: false, // - when user reloads page - and this value is false = throwing him out ( auth not complete) even if auth - yes complete (because steel did not put value from cookies inside of store)
        is2FaAuthComplete: false,
        isGoogleAuthConfigComplete: false
    },
    is403: false
}

export const SharedWorkspacesAndOrganizationsSlice = createSlice({
    name: 'SharedWorkspacesAndOrganizations',
    initialState,
    reducers: {
        setSharedWorkspacesAndOrganizationsData: (state, {payload}: {payload: multiStoreDataType}) => {
            // console.log('COOKIE DATA SET');
            state.multistore = payload;
        },
        setAuthNotComplete: (state) => {state.multistore.is2FaAuthComplete = true; state.multistore.isAuthComplete = false},
        setNullToSelectedWorkspaceAndOrg: (state) => {
            // console.log('setNullToSelectedWorkspaceAndOrg');
            state.multistore.selectedOrganization = null;
            state.multistore.selectedWorkspace = null;
            state.multistore.roles = {
                isOrgOwner: false,
                isOrgAdmin: false,
                isOrgMember: false,

                isWsOwner: false,
                isWsAdmin: false,
                isWsEditor: false,
                isWsViewer: false,
                isWsOutsideCollab: false,
            };
        },
        setGot403: (state) => {state.is403 = true},
        eraseGot403: (state) => {state.is403 = false},
        setFirstNameAndLastName: (state, {payload}: {payload: {firstName: string, lastName: string}}) => {
            if(state.multistore.currentUser){
                state.multistore.currentUser.firstName = payload.firstName;
                state.multistore.currentUser.lastName = payload.lastName;
            }
        },
        eraseData: () => initialState,
    }
});

export const SharedWorkspacesAndOrganizationsReducer = SharedWorkspacesAndOrganizationsSlice.reducer;

export const {
    setSharedWorkspacesAndOrganizationsData,
    eraseData,
    setAuthNotComplete,
    setNullToSelectedWorkspaceAndOrg,
    setGot403,
    eraseGot403,
    setFirstNameAndLastName
} = SharedWorkspacesAndOrganizationsSlice.actions;

const selectSelf = (state: AppState): multiStoreInitState => state.SharedWorkspacesAndOrganizations as multiStoreInitState;

export const selectedWorkspace = createSelector(selectSelf, state => state.multistore.selectedWorkspace);
export const selectedOrganization = createSelector(selectSelf, state => state.multistore.selectedOrganization);
export const roles = createSelector(selectSelf, state => state.multistore.roles);
export const user = createSelector(selectSelf, state => state.multistore.currentUser);

export const isAuthComplete = createSelector(selectSelf, state => state.multistore.isAuthComplete);
export const is2FaAuthComplete = createSelector(selectSelf, state => state.multistore.is2FaAuthComplete);

export const allMultiStoreData = createSelector(selectSelf, state => state.multistore);
export const is403 = createSelector(selectSelf, state => state.is403);








