import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {frameworks} from "../../../../../store/slice";
import {frameworkType} from "../../../../../types";
import { Flex } from "../../../../../../../../shared/components/Layouts";

export type createFrameworkIdGapDialogInputProps = {
    isOpen: boolean;
    handleClose: () => void,
    onCreate: (id: string, innerGap: string) => void,
    addedGaps: string[]
}

export const CreateFrameworkIdGap = ({isOpen, onCreate, handleClose, addedGaps}: createFrameworkIdGapDialogInputProps) => {
    const [tab, setTab] = useState<'manual' | 'exists'>('manual');
    const _frameworks = useSelector(frameworks);
    const [form, setForm] = useState<{frameworkId: string, innerGap: string}>({frameworkId: '', innerGap: ''});
    const [selectedFramework, setSelectedFramework] = useState<frameworkType | null>(null);

    const isExistManual = form.frameworkId.length > 0 && addedGaps.some(e => e === form.frameworkId);
    const isExistSelect = selectedFramework !== null && addedGaps.some(e => e === selectedFramework.id);

    const isOk = (): boolean => {
        if(!form.innerGap.trim().length) return false;
        if(tab === 'manual'){
            return form.frameworkId.trim().length > 0 && !isExistManual;
        }
        if(tab === 'exists'){
            return selectedFramework !== null && !isExistSelect
        }
        return false;
    }

    const handleSubmit = () => {
        if(tab === "manual"){
            onCreate(form.frameworkId, form.innerGap);
        }
        if(tab === 'exists'){
            selectedFramework && onCreate(selectedFramework.id, form.innerGap);
        }
        cleanUp();
    }

    const onClose = () => {
        handleClose();
        cleanUp();
    }

    const cleanUp = () => {
        setForm({frameworkId: '', innerGap: ''});
        setSelectedFramework(null);
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Create frameworkId Gap')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} w={'100%'}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue) => setTab(newValue)} aria-label="basic tabs example">
                        <Tab label={'Manual add'} value={'manual'}/>
                        <Tab label={'Select framework'} value={'exists'}/>
                    </Tabs>

                    {tab === 'manual' &&
                        <>
                            <TextField
                                required
                                autoFocus
                                label={('Type framework ID')}
                                value={form.frameworkId}
                                onChange={(e) => setForm({...form, frameworkId: e.target.value as string})}
                                size={'small'}
                                sx={{margin: '8px 0 0 0'}}
                                error={isExistManual}
                                helperText={isExistManual ? ('Such gap already added') : ''}
                            />
                        </>
                    }

                    {tab === 'exists' &&
                        <>
                            <Autocomplete
                                id="combo-box-demo"
                                options={_frameworks}
                                getOptionLabel={(option: frameworkType) => option.name}
                                value={selectedFramework}
                                sx={{margin: '8px 0 0 0', height: '40px'}}
                                onChange={(e, newValue) => newValue && setSelectedFramework(newValue)}
                                renderInput={(params) => <TextField {...params}
                                                                    size="small"
                                                                    label={("Select existing framework")}
                                                                    fullWidth
                                                                    error={isExistSelect}
                                                                    helperText={isExistSelect ? ('Such gap already added') : ''}/>}
                            />
                        </>
                    }

                    <TextField
                        required
                        autoFocus
                        label={('Type inner gap (en)')}
                        value={form.innerGap}
                        onChange={(e) => setForm({...form, innerGap: e.target.value as string})}
                        size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant={'text'}>{('Cancel')}</Button>
                <Button
                    disabled={!isOk()}
                    variant={'contained'}
                    onClick={handleSubmit}
                >{('Save')}</Button>
            </DialogActions>
        </Dialog>
    )

}