import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideTransferOwnershipDialog} from "../../../store/slice";
import {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Autocomplete} from "@mui/lab";
import {smallCollab} from "../../../types";
import {ORGANIZATION_OWNER} from "../../../../../AuthWorkspaceAndCookies/newWorkspaces/constants";
import {TransferOwnership} from "../../../store/actions";

export const TransferOwnershipDialog = () => {
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingTransferOwnership: {isLoading}} = useSelector(loading);
    const {transferOwnershipDialog : {isOpen}} = useSelector(dialogs);
    const [collab, setCollab] = useState<smallCollab | null>(null);

    const handleClose = () => {
        dispatch(hideTransferOwnershipDialog());
        setCollab(null);
    }

    const handleSubmit = () => {
        if(org && collab){
            const currOwner = org.collaborators.find(e => e.role === ORGANIZATION_OWNER);
            if(currOwner){
                dispatch(TransferOwnership({id: org.id, from: currOwner.publicId, to: collab.publicId}));
                setCollab(null);
            }
        }
    }

    const handleSetCollab = (collab: smallCollab | null) => {
        if(collab && org){
            setCollab(collab);
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'xs'} fullWidth>
            <DialogTitle>Transfer Ownership</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{marginTop: '10px'}}
                    autoComplete
                    fullWidth
                    openOnFocus
                    id="combo-box-demo"
                    options={org ? org.collaborators.filter(e => e.role !== ORGANIZATION_OWNER) : []}
                    noOptionsText={'Organization has only owner as collab'}
                    getOptionLabel={(e) => e.email}
                    onChange={(e, value) => {handleSetCollab(value)}}
                    value={collab}
                    renderInput={(params) => <TextField {...params} label="Collab" />}
                />
            </DialogContent>
            {isLoading ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org || !collab} color={'success'}>Transfer</Button>
                </DialogActions>
            }
        </Dialog>
    )
}