import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideChangeOrgWizardDialog, wizards} from "../../../store/slice";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {GetQuickStarts, UpdateOrgWizards} from "../../../store/actions";
import {Typo} from "../../../../../../shared/components/Typography";
import {CustomButton} from "../../../../../../shared/components/Buttons";

export const UpdateOrganizationWizardsDialog = () => {
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeWizards, isLoadingWizards} = useSelector(loading);
    const {changeOrgWizardsDialog : {isOpen}} = useSelector(dialogs);
    const wizs = useSelector(wizards);
    const [choosedWizards, setChoosedWizards] = useState<string[]>([]);
//isLoadingChangeWizards
    const handleClose = () => {
        dispatch(hideChangeOrgWizardDialog());
    }

    const handleSubmit = () => {
        if(org && !isEqual()){
            dispatch(UpdateOrgWizards({id: org.id, wizardIds: choosedWizards}));
        }
    }

    const handleToggleWiz = (id: string) => {
        choosedWizards.some((e) => e === id) ? setChoosedWizards(choosedWizards.filter(e => e !== id)) : setChoosedWizards([...choosedWizards, id]);
    }

    const handleAddAll = () => setChoosedWizards(wizs.map(e => e.id));

    const isEqual = () => {
        if(org){
            return JSON.stringify(org.configuration.availableWizards) === JSON.stringify(choosedWizards);
        }
    }

    useEffect(() => {
        if(isOpen && org){
            setChoosedWizards(org.configuration.availableWizards);
            if(!isLoadingChangeWizards && !wizards.length){
                dispatch(GetQuickStarts());
            }
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'} fullWidth maxWidth={'xs'}>
            <DialogTitle>Update Wizards</DialogTitle>
            <DialogContent>
                {isLoadingWizards && <LocalSpinner />}
                {!isLoadingWizards && !wizs.length && <Typo>No wizards found {org ? `, but user steel has ${org.configuration.availableWizards.length}` : 'And organization not found'}</Typo>}

                {!isLoadingWizards && wizs.length > 0 && <CustomButton onClick={handleAddAll} fullWidth>Select All</CustomButton>}

                <List sx={{ width: '100%', maxWidth: 360 }}>
                    {!isLoadingWizards && wizs.length > 0 && wizs.map((e) => {
                        const labelId = `checkbox-list-label-${e.id}`;
                        return(
                            <ListItem key={e.id} disablePadding>
                                <ListItemButton role={undefined} onClick={() => handleToggleWiz(e.id)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={choosedWizards.some(w => w === e.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={e.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
            {isLoadingChangeWizards ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org || isEqual()} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}