
//getManagedApps
import {createAsyncThunk} from "@reduxjs/toolkit";
import {addApp, deleteManagedApp, getAppsInfos, updateApp} from "../api";
import {AppInfo} from "../types";

export const GetAppsInfos = createAsyncThunk(
    'newApplications/getAppsInfos',
    async (_, {getState, dispatch}) => {
        return await getAppsInfos();
    }
);

//deleteManagedApp
export const DeleteManagedApp = createAsyncThunk(
    'newApplications/deleteManagedApp',
    async (data: {id: string, onSuccess: () => void}, {getState, dispatch}) => {
        await deleteManagedApp(data.id);
        data.onSuccess();
        return data.id;
    }
);

export const AddApp = createAsyncThunk(
    'newApplications/addApp',
    async (data: {app: AppInfo, onSuccess: () => void}, {getState, dispatch}) => {
        await addApp(data.app);
        data.onSuccess();
        return data.app;
    }
);

export const UpdateApp = createAsyncThunk(
    'newApplications/updateApp',
    async (data: {app: AppInfo, onSuccess: () => void}, {getState, dispatch}) => {
        await updateApp(data.app);
        data.onSuccess();
        return data.app;
    }
);