import {useDispatch, useSelector} from "react-redux";
import {addPolicyDialog, isLoading, isLoadingPolicies, policies, selectedPolicy} from "../../store/slice";
import {useEffect} from "react";
import {GetPrivatePolicies} from "../../store/actions";

export const usePolicies = () => {
    const dispatch = useDispatch();
    const _policies = useSelector(policies);

    useEffect(() => {
        dispatch(GetPrivatePolicies({page: 0, count: 10}));
        //eslint-disable-next-line
    }, [])

    return {
        _policies,
        isLoading: useSelector(isLoadingPolicies),
        isLoadingDialogs: useSelector(isLoading),
        isOpenAdd: useSelector(addPolicyDialog),
        selectedPolicy: useSelector(selectedPolicy),
    }
}