import {Button, MenuItem, TextField} from "@mui/material";
import {LoadingButton} from "../../../../../../shared/components/Buttons";
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle
} from "../../../../../../shared/components/CustomDialog";

import {useEditAssetSetting} from "../../../hooks/dialogs/useEditAssetSetting";


export const EditAssetSettingDialog = () => {

    const {
        isOk,
        isOpen,
        isLoading,
        isLoadingDataForDialog,
        job,
        status,
        jobTypeVariants,
        title, setTitle,
        isTitleExist,

        handleClose,
        handleConfirm,
        handleChange,
        handleChangeStatus,
        handleSetJobType,
    } = useEditAssetSetting();


    return (
        <CustomDialog open={isOpen} onClose={handleClose} >
            <CustomDialogTitle >{'Edit row'}</CustomDialogTitle>

            <CustomDialogContent >
                {!!job &&
                    <>
                        <TextField label={'Title'} value={job?.title} size={'small'}
                                   required
                                   sx={{mt: '12px', width: '100%', '& label': {top: '0 !important'}}}
                                   helperText={isTitleExist ? 'Already exist' : undefined}
                                   error={isTitleExist}
                                   onChange={handleChange} name={'title'} />

                        <TextField label={'Job type'} select size={'small'}
                                   value={job?.type || ''}
                                   required
                                   sx={{width: '100%', mt: '12px', '& label': {top: '0 !important'}}}
                                   disabled={isLoading || isLoadingDataForDialog}
                        >
                            {jobTypeVariants ?
                                jobTypeVariants.map((e) => (<MenuItem key={e} value={e} onClick={() => {handleSetJobType(e)}}>{e}</MenuItem>))
                                : <MenuItem />
                            }
                        </TextField>

                        <TextField multiline minRows={3}
                                         value={job?.description ?? ''} onChange={handleChange}
                                         name={'description'} label={'Description'}
                                         disabled={isLoading}
                                         sx={{mt: '12px', width: '100%',}}/>
                        <TextField multiline minRows={3}
                                         value={job?.requirements ?? ''} onChange={handleChange}
                                         name={'requirements'} label={'Requirements'}
                                         disabled={isLoading}
                                         sx={{mt: '12px', width: '100%'}}/>
                    </>
                }

                {!!status &&
                    <>
                        <TextField label={'New variant'} value={status?.title} size={'small'}
                                   required
                                   sx={{mt: '8px', width: '100%', '& label': {top: '0 !important'}}}
                                   helperText={isTitleExist ? 'Already exist' : undefined}
                                   error={isTitleExist}
                                   onChange={handleChangeStatus} name={'title'} />
                        <TextField label={'Text color'} value={status?.textColor} size={'small'}
                                   sx={{mt: '8px', width: '100%', '& label': {top: '0 !important'}}}
                                   onChange={handleChangeStatus} name={'textColor'} />
                        <TextField label={'Background color'} value={status?.backgroundColor} size={'small'}
                                   sx={{mt: '8px', width: '100%', '& label': {top: '0 !important'}}}
                                   onChange={handleChangeStatus} name={'backgroundColor'} />

                    </>
                }

                {!job && !status &&
                    <TextField label={'Title'} value={title} size={'small'}
                               required
                               sx={{mt: '12px', width: '100%', '& label': {top: '0 !important'}}}
                               helperText={isTitleExist ? 'Already exist' : undefined}
                               error={isTitleExist}
                               onChange={(e) => setTitle(e.target.value)} name={'title'} />
                }

            </CustomDialogContent>

            <CustomDialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>{'Cancel'}</Button>
                <LoadingButton disabled={!isOk} loading={isLoading} onClick={handleConfirm} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>
                    {'Confirm'}
                </LoadingButton>
            </CustomDialogActions>
        </CustomDialog>
    )
}