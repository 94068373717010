import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";

export const isPerformingLoginSelector = createSelector(
  (state: AppState) => state.auth.isLoading,
  x => x
);

export const loginErrorMessageSelector = createSelector(
  (state: AppState) => state.auth.errMessage,
  x => x
)

export const hasTokenSelector = createSelector(
  (state: AppState) => state.auth.hasToken,
  x => x
);
