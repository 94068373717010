import React, {FC, RefObject, useEffect, useState} from "react";
import {
    EditorFillableFieldItemType,
    IStatusProps,
    TEditorActor,
    TEditorDragElementFillableFieldData,
    TEditorFillableBlockData
} from "../../types";
import {EditorFillableBlockResizeLine, EditorFillableBlockStyled} from "./styled";
import {ConnectableElement, useDrag} from "react-dnd";
import {Checkbox, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {Check, Delete} from "@mui/icons-material";
import {createEventAddFillableField, randomHSLA} from "../../helpers";
import {EditorItemPage} from "../editorItemPage";
import {DEFAUTL_ACTOR, editorFillableFields, needUpdateActorsEventName} from "../../constants";
import colors from "../../../../theme/colors";
import {SignDialog} from "./components/signDialog";
import {FillableFieldDialog} from "./components/fillableFieldDialog";
import {FlexRow} from "../editorTitleWithActionsRow/styled";
import {EditorItemPdfPage} from "../editorItemPdfPage";
import FormControlLabel from "@mui/material/FormControlLabel";

interface Props extends IStatusProps {
    element: TEditorFillableBlockData;

    parentRef: RefObject<EditorItemPage>["current"] | RefObject<EditorItemPdfPage>["current"];
    parentDiv: RefObject<HTMLDivElement>;

    onResize: (size: {width: number, height: number}, position: {x: number, y: number}) => void;
    onChangeEmail: (actor: TEditorActor, role: string) => void;

    handleSubmitSign: (sign: string, insertEverywhere: boolean) => void;
    handleSubmitText: (text: string) => void;
}

export const EditorFillableBlock: FC<Props> = (
    {
        element, readonly, status,
        onChangeEmail, onResize,
        parentDiv, parentRef,
        handleSubmitSign, handleSubmitText,
    }
) => {
    const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
    const [isOpenDialog, setIsOpenDialog] = useState<null | 'text' | 'sign'>(null);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: element.type,
        item: element satisfies TEditorDragElementFillableFieldData,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    }), [element]);

    const closeMenu = () => {
        setPopperRef(null);
    };

    const recipients = parentRef?.props.parentRef?.state.sideMenuRef.current?.state.actorsConfig.recipients || [];

    const actorIndex: number = recipients.findIndex((e) => ('' + e.actor.email + e.role) === element.sourceEmailRole);
    const isFilled: boolean = element.data.length > 0;
    const isActiveUser: boolean | undefined = status.isSigning
        ? status.isUserCurrentActor && status.currentEmail === element.actor?.email
        : undefined;

    const color = element.actor?.email.length || element.role
        ? randomHSLA(
            `${element.actor?.lastName} ${element.actor?.email} ${element.actor?.firstName} ${element.sourceEmailRole}`,
            actorIndex, isFilled, isActiveUser
        )
        : {background: 'white', fontColor: colors.decorative.violet};


    /// RESIZE
    const mouseMoveHandler = (e: MouseEvent) => {
        if (!wrapperRef) return;
        wrapperRef.draggable = false;

        const orientation = wrapperRef.getAttribute('resizing')!;
        if (!orientation) return;

        const rectLeft = element.position.x;
        const rectTop = element.position.y;
        const {width, height} = element.size;

        const x = rectLeft + (orientation.endsWith('left') ? 0 : element.size.width) + parentDiv.current!.getBoundingClientRect()!.left;
        const y = rectTop + (orientation.endsWith('top') ? 0 : element.size.height) + parentDiv.current!.getBoundingClientRect()!.top;

        const {clientX, clientY} = e;
        const dx = orientation.startsWith('vertical') ? clientX - x : 0;
        const dy = orientation.startsWith('horizontal') ? clientY - y : 0;

        const newWidth = orientation.endsWith('right') ? width + dx : width - dx;
        const newHeight = orientation.endsWith('bottom') ? height + dy : height - dy;

        const top = orientation === 'horizontal-top'
            ? rectTop + dy
            : rectTop;

        const left = orientation === 'vertical-left'
            ? rectLeft + dx
            : rectLeft;

        wrapperRef.style.width = Math.max(newWidth, 50) + 'px';
        wrapperRef.style.height = Math.max(newHeight, 40) + 'px';
        wrapperRef.style.top = top + 'px';
        wrapperRef.style.left = left + 'px';
    };

    const mouseUpHandler = (e: MouseEvent) => {
        e.stopPropagation();

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        if (!wrapperRef) return;
        if (!wrapperRef.getAttribute('resizing')) return;
        if (!wrapperRef.style.width || !wrapperRef.style.height || !wrapperRef.style.top || !wrapperRef.style.left) return;

        wrapperRef.draggable = true;
        wrapperRef.removeAttribute('resizing');

        onResize({
            width: Number(wrapperRef.style.width.replace('px', '')),
            height: Number(wrapperRef.style.height.replace('px', ''))
        }, {
            x: Number(wrapperRef.style.left.replace('px', '')),
            y: Number(wrapperRef.style.top.replace('px', '')),
        });
    };

    const onMouseDown = (orientation: string) => (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (!wrapperRef) return;

        wrapperRef.setAttribute('resizing', orientation);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const [actors, setActors] = useState(
        parentRef?.props.parentRef?.state.sideMenuRef.current?.getActorsData().recipients || []
    );
    const refreshActors = () => {
        setActors(parentRef?.props.parentRef?.state.sideMenuRef.current?.getActorsData().recipients || []);
    };

    useEffect(() => {
        document.addEventListener(needUpdateActorsEventName, refreshActors);
        return () => {
            document.removeEventListener(needUpdateActorsEventName, refreshActors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const maybeNotRequired = editorFillableFields.find(e => e.type === element.type)?.maybeNotRequired;

    const handleChangeIsRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const {checked} = e.target;
        maybeNotRequired && createEventAddFillableField({
            ...element, isRequired: checked, withoutDxDy: true
        });
    };

    const isUnassigned = element.actor.email === '' && element.role === '';
    return (
        <>
            <EditorFillableBlockStyled
                ref={ref => {
                    !readonly && drag(ref as ConnectableElement);
                    !readonly && setWrapperRef(ref as HTMLDivElement);
                }}
                isDragging={isDragging} id={element.id} isActiveUser={isActiveUser}
                haveTextData={element.data.length > 0 && element.type === EditorFillableFieldItemType["TEXT"]}
                x={element.position.x} y={element.position.y}
                width={element.size.width} height={element.size.height}
                backgroundColor={color.background} borderColor={color.fontColor}
                onMouseUp={!readonly ? (e) => mouseUpHandler(e.nativeEvent) : undefined}
                onClick={
                    status.isVerifyPdf && status.isDraft
                        ? undefined
                        : !readonly ? (
                            (e) => {setPopperRef(e.currentTarget)}
                        ) : (
                            (e) => {
                                element.actor?.email === status.currentEmail && setIsOpenDialog(element.type === EditorFillableFieldItemType["SIGN"]
                                    ? 'sign'
                                    : element.type === EditorFillableFieldItemType["TEXT"]
                                        ? 'text'
                                        : null
                                );
                            }
                        )
                }>
                {!readonly && (
                    <>
                        <EditorFillableBlockResizeLine width={element.size.width} height={element.size.height}
                                                       onMouseDown={onMouseDown('horizontal-top')} className={'horizontal-top'}/>
                        <EditorFillableBlockResizeLine width={element.size.width} height={element.size.height}
                                                       onMouseDown={onMouseDown('horizontal-bottom')} className={'horizontal-bottom'}/>
                        <EditorFillableBlockResizeLine width={element.size.width} height={element.size.height}
                                                       onMouseDown={onMouseDown('vertical-left')} className={'vertical-left'}/>
                        <EditorFillableBlockResizeLine width={element.size.width} height={element.size.height}
                                                       onMouseDown={onMouseDown('vertical-right')} className={'vertical-right'}/>
                    </>
                )}

                {element.isRequired && element.data.length === 0 && (
                    <Typography sx={{position: 'absolute', top: '6px', right: '6px', fontWeight: 600}}>*</Typography>
                )}

                {element.type === EditorFillableFieldItemType["SIGN"] && (
                    element.data.length === 0 ? (
                        <span>{'Signature'}</span>
                    ) : (
                        <img src={element.data} alt={'sign'} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    )
                )}

                {element.type === EditorFillableFieldItemType["TEXT"] && (
                    element.data.length === 0 ? (
                        <span>{'Text field'}</span>
                    ) : (
                        <span>{element.data}</span>
                    )
                )}
            </EditorFillableBlockStyled>

            <Menu open={!!popperRef} anchorEl={popperRef} onClose={() => setPopperRef(null)}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  transformOrigin={{vertical: 'top', horizontal: 'center'}}
                  sx={{
                      mt: '8px',
                      '& .MuiList-root': {paddingBottom: 0, paddingTop: 0},
                      '& .MuiMenuItem-root': {
                          paddingBlock: '8px',

                      },
            }}
            >
                <MenuItem selected={isUnassigned} onClick={() => {
                    closeMenu();
                    onChangeEmail(DEFAUTL_ACTOR, '')
                }}>
                    <FlexRow sx={{justifyContent: 'space-between', alignItems: 'center', gap: '14px', width: '100%'}}>
                        <span>{'Not assigned'}</span>
                        {isUnassigned && (
                            <Check/>
                        )}
                    </FlexRow>
                </MenuItem>

                {actors.filter(e => e.actor.email.length || e.role?.length).map((actor) => {
                    const isChecked = !isUnassigned && (actor.actor.email + actor.role) === ('' + element.actor.email + element.role);
                    // console.log('actor', actors, actor)

                    return (
                        <MenuItem selected={isChecked}
                                  key={'' + actor.actor.email + actor.role}
                                  onClick={() => {
                                      closeMenu();
                                      onChangeEmail(actor.actor, actor.role || '')
                                  }}>
                            <FlexRow sx={{justifyContent: 'space-between', alignItems: 'center', gap: '14px', width: '100%'}}>
                                <span>{actor.actor.email.length ? actor.actor.email : actor.role}</span>
                                {isChecked && (
                                    <Check/>
                                )}
                            </FlexRow>
                        </MenuItem>
                    )
                })}

                <FlexRow sx={{justifyContent: 'space-between', backgroundColor: colors.backgrounds.grey_light, minWidth: '200px', gap: '10px', pl: '6px'}}>
                    <FormControlLabel
                        disabled={!maybeNotRequired}
                        checked={!!element.isRequired}
                        control={<Checkbox onChange={handleChangeIsRequired}/>}
                        label={'Required'}
                    />

                    <IconButton size={"small"} onClick={(event) => {
                        closeMenu();
                        createEventAddFillableField({
                            pageId: '', id: element.id,
                            type: element.type, position: element.position,
                            size: element.size, data: element.data, actor: DEFAUTL_ACTOR,
                            sourceEmailRole: element.sourceEmailRole, role: element.role,
                        })
                    }}>
                        <Delete/>
                    </IconButton>
                </FlexRow>
            </Menu>

            {status.isStaff && status.isUserRecipient && status.isUserCurrentActor && status.isSigning && (
                <>
                    <SignDialog isOpen={isOpenDialog === 'sign'} handleClose={() => setIsOpenDialog(null)}
                                handleSubmit={handleSubmitSign}/>

                    <FillableFieldDialog isOpen={isOpenDialog === 'text'} handleClose={() => setIsOpenDialog(null)}
                                         handleSubmit={handleSubmitText} initialValue={element.data || ''}/>
                </>
            )}
        </>
    );
}
