import React, {FC, useContext, useState} from "react";
import {Flex, FlexEnd} from "../../../../../shared/components/Layouts";
import {Avatar} from "../../../../../shared/components/Avatar";
import {Typo} from "../../../../../shared/components/Typography";
import {UserMenu} from "./Menu";
import {ThemeContext} from "styled-components";
import {UseManageWorkspacesAndOrganizations} from "../../../../AuthWorkspaceAndCookies/newWorkspaces/hooks/useManageWorkspacesAndOrganizations";


type PropTypes = {
    reference: any;
    revDir: boolean;
};

export const UserInfo: FC<PropTypes> = (props: PropTypes) => {
    const {reference, revDir} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const {narrow} = useContext(ThemeContext);

    const handleClick = () => {
        setAnchorEl(reference.current);
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const {currData} = UseManageWorkspacesAndOrganizations();

    // const {toggleVisibility, isOpen} = ChangeLanguageDialog();

    return (
        <>
            <FlexEnd w={'auto'} p={!revDir ? '0 10px 0 0' : '0 0 0 10px'} shrink={"0"}>
                <Avatar initials={`${currData?.currentUser?.firstName ?? ''} ${currData?.currentUser?.lastName ?? ''}`} avatar={undefined} fs="1.09em" color={'avatar'} cursor={'pointer'}
                        onClick={handleClick}/>
                <Flex h={'36px'} ai={'center'} m={!revDir ? '0 0 0 10px'  : '0 10px 0 0'} onClick={handleClick} jc={narrow ? 'center' : 'space-between'}>
                    <Typo color={'#FFFFFF'} fontSize={narrow ? '10px' : '14px'} cursor={'pointer'}>{currData?.currentUser?.firstName ?? ''} {currData?.currentUser?.lastName ?? ''}</Typo>
                    {/*{!narrow && <Typo shrink color={'#90A0B7'} fontSize={'11px'} cursor={'pointer'}*/}
                    {/*                 style={{maxWidth: '17vw'}}*/}
                    {/*>{workspaceName}</Typo>}*/}
                </Flex>
            </FlexEnd>

            <UserMenu anchorEl={anchorEl} handleClose={handleClose} />
            {/*<LanguageChangeDialog isOpen={isOpen} handleClose={toggleVisibility}/>*/}
        </>
    )
};
