import React from "react";
import {controlType} from "../../../../types";
import {Box, Dialog, DialogContent, DialogTitle, Divider, TablePagination, Typography} from "@mui/material";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../../shared/components/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {Spinner} from "../../../../../../../shared/components/Spinner";
import { shortString} from "../../../../helpers";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {SearchField} from "../../../../../../../shared/components/CustomInputs";
import {useSelectExistingControl} from "../../../../hooks/dialogs/useSelectExistingControl";

export const SelectExistingControl = () => {

    const {
        isOpen,
        isLoadingList,
        controls,
        fetchedControls,
        pageInfo,
        handleClose,
        handleChangePage,
        handleAddControl,
        handleChangeRowsPerPage,
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    } = useSelectExistingControl();


    return(
        <Dialog maxWidth={'lg'} open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                    <Typo fontSize={'24px'} fontWeight={700} letterSpacing={'1.53px'} margin={'0 50px 0 0'}>{'Select Existing Control'}</Typo>
                    <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
                </Flex>
            </DialogTitle>

            <DialogContent sx={{pb: '0'}}>
                <Flex w={'40vw'} direction={'column'} m={'0'} overflow={'hidden'}>

                    <SearchField
                        onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}
                        size={'small'}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={handleChange}
                        value={value}
                        disabled={isLoadingList}
                        onKeyDown={handleKeyDown}
                    />
                    <Divider sx={{mt: '8px', mb: '8px'}}/>
                    {/*<Flex w={'100%'} jc={'space-between'} ai={'center'}>*/}
                    {/*    /!* <CustomOutlinedInput*/}
                    {/*        fullWidth */}
                    {/*        id="outlined-adornment-password"*/}
                    {/*        value={searchField}*/}
                    {/*        onChange={handleFieldChange}*/}
                    {/*        startAdornment={*/}
                    {/*            <InputAdornment position="start" style={{margin: '0 10px 0 -15px'}}>*/}
                    {/*                <IconButton edge="end">*/}
                    {/*                    <SearchIcon />*/}
                    {/*                </IconButton>*/}
                    {/*            </InputAdornment>*/}
                    {/*        }*/}
                    {/*    /> *!/*/}
                    {/*    <SearchInput*/}
                    {/*        dir={'ltr'}*/}
                    {/*        value={searchField}*/}
                    {/*        label={t('Search')}*/}
                    {/*        onChange={handleFieldChange}*/}
                    {/*    />*/}

                    {/*    /!* <ButtonWithBackround*/}
                    {/*        width={'82px'}*/}
                    {/*        height={'24px'}*/}
                    {/*        color={searchField.trim().length > 0 ? '#FAC000' : 'gray'}*/}
                    {/*        text={'SEARCH'}*/}
                    {/*        fontColor={'white'}*/}
                    {/*        fontSize={'10px'}*/}
                    {/*        fontWeight={600}*/}
                    {/*        onClick={() => getControls(0, 10, step, searchField)}*/}
                    {/*        margin={'0 0 0 15px'}*/}
                    {/*    /> *!/*/}
                    {/*    <CustomButton */}
                    {/*        variant={'contained'}*/}
                    {/*        disabled={searchField.trim().length <= 0}*/}
                    {/*        // size={'small'}*/}
                    {/*        sx={{margin: !revDir ? '0 0 0 15px' : '0 15px 0 0'}}*/}
                    {/*        onClick={() => getControls(0, 10, step, searchField)}*/}
                    {/*    >{t('SEARCH')}</CustomButton>*/}
                    {/*</Flex>*/}

                    <Flex direction={'column'} w={'100%'} minh={'350px'} maxh={'70vh'} disableScrollBar overflow={'auto'}>
                        {isLoadingList && <Spinner />}
                        {fetchedControls.map((e) => <ControlCard
                            control={e}
                            key={e.id}
                            isAdded={controls.some(c => c.id === e.id)}
                            handleSelect={() => handleAddControl(e)}
                            />)
                        }
                    </Flex>

                    <Divider flexItem/>
                    <Flex w={'100%'} jc={'flex-end'}>
                        <TablePagination
                            sx={{overflow: 'hidden', margin: 'none'}}
                            component="div"
                            count={pageInfo.total}
                            page={pageInfo.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPage={pageInfo.count}
                            rowsPerPageOptions={[10, 50, 100, { value: pageInfo.total, label: 'All' }]}
                            showFirstButton
                            showLastButton
                        />
                    </Flex>
                </Flex>
            </DialogContent>
        </Dialog>
    )
}

const ControlCard = ({control, isAdded, handleSelect}: {control: controlType, isAdded: boolean, handleSelect: (id: string) => void}) => {

    return(
        <>
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} mt={'8px'}>
                <Flex direction={'column'} w={'85%'}>
                    <Typo fontSize={'14px'} fontWeight={700} >{control.name}</Typo>
                    <Box display={'flex'} width={'60%'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: '5px'}}>
                        <Typography fontSize={'14px'} fontWeight={500} >{control.id}</Typography>
                        {/*<Typography fontSize={'14px'} fontWeight={300} color={'#A0A2B1'} >{getControlStatus(control)}</Typography>*/}
                    </Box>

                    {control.description.length === 1 && <Typo  fontSize={'12px'} fontWeight={400} color={'#A0A2B1'} margin={'7px 0 0 0'}>{shortString(control.description[0], 140)}</Typo>}
                    {control.description.length > 1 && control.description.slice(0, 2).map(e => <Typo  fontSize={'12px'} fontWeight={400} color={'#A0A2B1'} margin={'7px 0 0 0'}>{shortString(e, 70)}</Typo>) }
                    {control.description.length > 2 && <Typo fontSize={'12px'} fontWeight={400} color={'#A0A2B1'} margin={'7px 0 0 0'}>{'and'} {control.description.length - 2} {'more'}</Typo> }
                </Flex>
                <CustomButton
                    size={'small'}
                    variant={'contained'}
                    disabled={isAdded}
                    onClick={() => handleSelect(control.id || '')}
                >{isAdded ? 'Selected' : 'Select'}</CustomButton>
            </Box>
            <Divider sx={{mt: '8px'}}/>
        </>
    )
}
