import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    hideCreateRoleDialog,
    loading,
    openManagePermissionsDialog,
    openPreviewRoleDialog,
    permissions,
    roles
} from "../../../store/slice";
import {useForm} from "../../../../../../shared/hooks/useForm";
import {TRoleCreateModel} from "../../../types";
import {addInfoSnack} from "../../../../../BarsENV/snack/store/slice";
import {CreateAdminRole} from "../../../store/actions";

export const useCreateRole = () => {
    const dispatch = useDispatch();

    const _roles = useSelector(roles);
    const _permissions = useSelector(permissions);
    const {createRole} = useSelector(dialogs);
    const {rolesCreate} = useSelector(loading);

    const handleClose = () => {
        dispatch(hideCreateRoleDialog());
        cleanUp();
    }

    const handleSubmit = () => {
        if(_roles.some(e => e.name === form.name)){
            dispatch(addInfoSnack(`Role with name ${form.name} already exists`));
            return;
        }
        if(form.name.trim().length > 0 && form.permissions.length > 0){
            dispatch(CreateAdminRole({role: form}));
            cleanUp();
        }
    }

    const {form, handleChange, setForm} = useForm<TRoleCreateModel>({
        name: '',
        permissions: [],
        system: true,
        origin: true
    });

    //actions
    const selectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: [...form.permissions, permission]
        })
    }

    const selectAllPermission = (permissions: string[]) => {
        // console.log(`SELECT ALL: ${permissions}`);
        setForm({
            ...form,
            permissions: Array.from(new Set([...form.permissions, ...permissions]))
        })
    }

    const deselectAllPermissions = (permissions: string[]) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => !permissions.some(p => p === e))
        })
    }
    const deselectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => e !== permission)
        })
    }

    //
    const handlePreviewSelectedPermissions = () => {
        dispatch(openPreviewRoleDialog({...form, id: '', workspaceId: null, system: false, createDate: '', lastUpdateDate: '', origin: true}))
    }

    //

    const handleOpenManagePermissionDialog = () => {
        dispatch(openManagePermissionsDialog());
    }

    const handleToggleSystem = () => {
        setForm({...form, system: !form.system});
    }

    const cleanUp = () => {
        setForm({
            name: '',
            permissions: [],
            system: true,
            origin: true
        })
    }

    return{
        isOpen: createRole,
        isLoading: rolesCreate,
        handleClose,
        handleSubmit,
        isOk: form.name.trim().length > 0 && form.permissions.length > 0,
        form,
        handleChange,
        actions: {
            selectPermission,
            selectAllPermission,
            deselectAllPermissions,
            deselectPermission,
            handlePreviewSelectedPermissions,
            handleOpenManagePermissionDialog
        },
        permissions: _permissions,
        handleToggleSystem
    }
}