export const Logging = {
    log: (...obj: any[]) => {
        console.log('----------------------------------------------')
        if (window.config?.NODE_ENV === 'development') {
            console.log(`LOGGER: [${new Date().toISOString()}]`, ...obj);
            console.log('----------------------------------------------')
        }
    },
    error: (obj: any) => {
        if (window.config?.NODE_ENV === 'development') {
            console.error(`LOGGER: [${new Date().toISOString()}]`, ...obj);
        }
    }
}
