import {EditorItemPage} from "./components/editorItemPage";
import {EditorItemBlock} from "./components/editorItemBlock";
import {EditorItemColumn} from "./components/editorItemColumn";
import {EditorItemRow} from "./components/editorItemRow";
import {TextComponent} from "./components/TextComponent";
import {EditorItemTable} from "./components/editorItemTable";
import {
    APPROVAL_STATUS,
    APPROVED_STATUS,
    CommonRefObject,
    COMPLETED_STATUS,
    DRAFT_STATUS,
    EditorListLiData,
    IN_PROGRESS_STATUS,
    PENDING_STATUS,
    REJECTED_STATUS,
    REMARKS_STATUS,
    SHARED_DRAFT_STATUS,
    SIGNING_STATUS,
    SKIPPED_STATUS,
    TAddFillableFieldEventData,
    TBlockCreatedByDropEventData,
    TChangeVariableEventData,
    TCommonDialogOpenEventData,
    TDeleteBlockEventData,
    TDeleteVariableEventData,
    TDocumentExecutorStatus,
    TDocumentStatus,
    TEditorActor,
    TEditorBlockElementData,
    TEditorColumnElementData,
    TEditorElementData,
    TEditorPageElementData,
    TEditorPdfPageElementData,
    TEditorRecipient,
    TEditorVariableData,
    TERMINATED_STATUS,
    TOpenDialogEventData,
    TSetActiveBlockEventData,
    TSetForceHighlightedBlocksEventData,
    TSetHighlightedVariableEventData,
    TSetListElementActiveEventData,
    TSetPagePropertiesMenuEventData,
    TUpdateDataInFillableFieldEventData,
    TUpdateRecipientEventData
} from "./types";
import {
    addFillableFieldVariableEventName,
    blockCreatedByDropEventName,
    changeVariableByTextComponentEventName,
    changeVariableEventName,
    commonDialogOpenEventName,
    deleteBlockEventName,
    deleteVariableEventName,
    needUpdateActorsEventName,
    openManageActorsDialogEventName,
    openManageEditorsDialogEventName,
    setActiveBlockEventName,
    setForceHighlightedEventName,
    setHighlightedVariableEventName,
    setListElementActiveEventName,
    setPagePropertiesMenuVariableEventName,
    unfocusAllBlocksEventName,
    updateDataInFillableFieldEventName,
    updateEditorsEventName,
    updateRecipientEventName,
    updateVariableUsagesEventName
} from "./constants";
import {debounce} from "lodash";
import {EditorItemImage} from "./components/editorItemImage";
import {EditorItemList} from "./components/editorItemList";
import {EditorItemListLi} from "./components/editorItemList/components/editorItemListLi";
import {uuid} from "../../utils";
//@ts-ignore
import {getPastelColor} from 'pastel-color';
import {EditorItemPagebreak} from "./components/editorItemPagebreak";
import {EditorItemPdfPage} from "./components/editorItemPdfPage";
import colors from "../../theme/colors";
import {getName} from "../../utils/text";

export const isBlockPage = (page: unknown): page is TEditorPageElementData => {
    return typeof page === 'object' && page !== null
        && 'type' in page && typeof page.type === 'string' && page.type === 'page'
}

export const isBlockPdfPage = (page: unknown): page is TEditorPdfPageElementData => {
    return typeof page === 'object' && page !== null
        && 'type' in page && typeof page.type === 'string' && page.type === 'pdfPage'
}

export const isBlockBlock = (page: unknown): page is TEditorBlockElementData => {
    return typeof page === 'object' && page !== null
        && 'type' in page && typeof page.type === 'string' && page.type === 'block'
}

export const isBlockColumn = (page: unknown): page is TEditorColumnElementData => {
    return typeof page === 'object' && page !== null
        && 'type' in page && typeof page.type === 'string' && page.type === 'column'
}

export const isRefPage = (page: unknown): page is EditorItemPage => {
    return typeof page === 'object' && page !== null
        && 'state' in page && typeof page.state === 'object' && page.state !== null
        && 'type' in page.state && typeof page.state.type === 'string' && page.state.type === 'page'
}

export const isRefPdfPage = (page: unknown): page is EditorItemPdfPage => {
    return typeof page === 'object' && page !== null
        && 'state' in page && typeof page.state === 'object' && page.state !== null
        && 'type' in page.state && typeof page.state.type === 'string' && page.state.type === 'pdfPage'
}

export const isRefRow = (row: unknown): row is EditorItemRow => {
    return typeof row === 'object' && row !== null
        && 'state' in row && typeof row.state === 'object' && row.state !== null
        && 'type' in row.state && typeof row.state.type === 'string' && row.state.type === 'row'
}

export const isRefPageBreak = (row: unknown): row is EditorItemPagebreak => {
    return typeof row === 'object' && row !== null
        && 'state' in row && typeof row.state === 'object' && row.state !== null
        && 'type' in row.state && typeof row.state.type === 'string' && row.state.type === 'pagebreak'
}

export const isRefColumn = (column: unknown): column is EditorItemColumn => {
    return typeof column === 'object' && column !== null
        && 'state' in column && typeof column.state === 'object' && column.state !== null
        && 'type' in column.state && typeof column.state.type === 'string' && column.state.type === 'column'
}

export const isRefBlock = (block: unknown): block is EditorItemBlock => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'block'
}

////// if inside editor blocks
export const isRefText = (block: unknown): block is TextComponent => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'text'
}

export const isRefTable = (block: unknown): block is EditorItemTable => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'table'
}

export const isRefImage = (block: unknown): block is EditorItemImage => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'image'
}

export const isRefList = (block: unknown): block is EditorItemList => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'list'
}

export const isRefListLi = (block: unknown): block is EditorItemListLi => {
    return typeof block === 'object' && block !== null
        && 'state' in block && typeof block.state === 'object' && block.state !== null
        && 'type' in block.state && typeof block.state.type === 'string' && block.state.type === 'listElement'
}




//////
export const onSaveRepeat = (content: TEditorElementData[], refs: Record<string, CommonRefObject>): TEditorElementData<any>[] => {
    //TODO: Fill content from refs ?recursive?
    const data: TEditorElementData<any>[] = [];

    content.forEach(block => {
        const updatedBlock = JSON.parse(JSON.stringify(block));
        const ref = refs[block.id!]?.current;
        updatedBlock.data = JSON.parse(JSON.stringify(ref?.state.data! || updatedBlock.data));
        updatedBlock.type = ref?.state.type! || updatedBlock.type;
        updatedBlock.id = ref?.state.id! || updatedBlock.id;

        //if page
        if (isRefPage(ref)) {
            const page = ref.state;
            if (updatedBlock.data?.header && updatedBlock.data.header.length > 0)   {updatedBlock.data.header  = onSaveRepeat(updatedBlock.data.header  || [], page.refs)}
            if (updatedBlock.data?.footer && updatedBlock.data.footer.length > 0)   {updatedBlock.data.footer  = onSaveRepeat(updatedBlock.data.footer  || [], page.refs)}
            if (updatedBlock.data?.content && updatedBlock.data.content.length > 0) {updatedBlock.data.content = onSaveRepeat(updatedBlock.data.content || [], page.refs)}

            updatedBlock.data.headerHeight = page.headerRef.current ? (page.headerRef.current?.offsetHeight - page.data.marginTop) : 0;
            updatedBlock.data.footerHeight = page.footerRef.current ? (page.footerRef.current?.offsetHeight - page.data.marginBottom) : 0;
        }

        if (isRefPdfPage(ref)) {
            const page = ref.state;
            updatedBlock.data = page.data;
        }

        if (isRefRow(ref)) {
            const row = ref.state;
            if (updatedBlock.data.content) {updatedBlock.data.content = onSaveRepeat(updatedBlock.data.content || [], row.refs)}
        }

        if (isRefColumn(ref)) {
            const column = ref.state;
            if (updatedBlock.data.content) {updatedBlock.data.content = onSaveRepeat(updatedBlock.data.content || [], column.refs)}
        }

        if (isRefPageBreak(ref)) {
            const block = ref.state;
            updatedBlock.data.positionTop = block.wrapperRef.current?.offsetTop || 0;
        }

        if (isRefBlock(ref)) {
            // const block = ref.state;
            const getBlock = ref.getData();
            updatedBlock.data = getBlock.data;
            updatedBlock.id = getBlock.id;
            updatedBlock.type = getBlock.type;
        }

        // //if block
        // if (isRefText(ref) || isRefTable(ref)) {
        //     // const block = ref.state;
        //     updatedBlock.data = ref.getData();
        // }


        // if (['image', 'table', 'text', 'column'].includes(ref?.state.type)) {
        //     const width = ref?.state.ref?.current.style.flexBasis ? Number(ref.state.ref.current.style.flexBasis.replace('%', '')) : 100;
        //     if (ref) {updatedBlock.data.width = width}
        //
        //     if (ref.state.imgRef.current) {updatedBlock.data.widthPx = ref.state.imgRef.current.offsetWidth}
        //     if (ref.state.imgRef.current) {updatedBlock.data.heightPx = ref.state.imgRef.current.offsetHeight}
        // }

        data.push(updatedBlock);
    });

    return data
};

export const changeOldIdToNewId: <T extends TEditorElementData>(data: T) => T = (data)  => {
    data.id = data.type + uuid();
    data.data.content?.forEach(el => {
        el = changeOldIdToNewId(el);
    });

    if ((data.data as EditorListLiData).textBlock) {
        (data.data as EditorListLiData).textBlock! = changeOldIdToNewId((data.data as EditorListLiData).textBlock!)
    }

    return data;
};

export const getStatusTitleByCode = (code: string): string => {
    switch (code) {
        case 'DRAFT': return 'Draft';
        case 'SHARED_DRAFT': return 'Shared draft';
        case 'REMARKS': return 'Remarks';
        case 'IN_PROGRESS': return 'In progress';
        case 'COMLETED': return 'Completed';
        case 'TERMINATED': return 'Terminated';
        default: return code;
    }
};

type backAndFontColorType = {background: string, fontColor: string};
export function randomHSLA(code: string, actorIndex: number, isFilled: boolean, isActiveUser?: boolean): backAndFontColorType {
    if (isActiveUser && !isFilled) return {background: `hsl(${40}, 76%, 96%)`, fontColor: `hsl(${40}, 47%, 68%)`};
    if (isActiveUser && isFilled) return {background: `hsl(${100}, 76%, 96%)`, fontColor: `hsl(${100}, 47%, 68%)`};

    if (isActiveUser === false) return {background: 'white', fontColor: colors.decorative.violet};

    if (actorIndex < 19) {
        const number = 40 * actorIndex;
        const finalNumber = number > 360 ? number - 340 : number;

        return {
            background: `hsl(${finalNumber}, 76%, 96%)`,
            fontColor: `hsl(${finalNumber}, 47%, 68%)`
        }
    }

    const background: string | number[] = getPastelColor(`SOME_TEXT6669_${code}`, {alpha: '1'}).hslRaw; //hslRaw: [ 288, '70%', '70%' ]
    const hue: number = background[0] as number;
    return {
        background: `hsl(${hue}, 76%, 96%)`,
        fontColor: `hsl(${hue}, 47%, 68%)`
    }
}

export const getStatusColor = (status?: TDocumentStatus | TDocumentExecutorStatus): string => {
    switch (status) {
        case DRAFT_STATUS: return colors.text.blue;
        case SHARED_DRAFT_STATUS: return colors.text.blue;
        case APPROVAL_STATUS: return colors.decorative.additional;
        case REMARKS_STATUS: return colors.text.warning;
        case SIGNING_STATUS: return colors.decorative.additional_3;
        case COMPLETED_STATUS: return colors.text.success;
        case TERMINATED_STATUS: return colors.decorative.orange;
        //
        case PENDING_STATUS: return colors.primary.blue_dark;
        case IN_PROGRESS_STATUS: return colors.decorative.violet;
        case REJECTED_STATUS: return colors.warningActions.red;
        case APPROVED_STATUS: return colors.decorative.green_dark;
        case SKIPPED_STATUS: return colors.text.grey_dark;
        default: return colors.text.blue;
    }
}

export const getNameFromOption = (option: TEditorRecipient): string => {
    if (option.actor.email === 'button') return 'Manage';
    const name = option.actor?.firstName.trim().length ? getName(option.actor?.firstName, option.actor?.lastName) : option.actor?.email;
    return name?.length ? name : option.role || '';
}

export const getExpiredTitleColor = (diff: number, t: (str: string) => string = (str) => str): {title: string, color: string} => {
    if (diff < 0) return {title: t('Expired'), color: colors.text.red};
    if (diff === 0) return {title: t('Today'), color: colors.text.dark};
    if (diff === 1) return {title: `1 ${t('day')}`, color: colors.text.dark};
    if (diff > 1) return {title: `${diff} ${t('days')}`, color: colors.text.dark};

    return {title: '-', color: colors.text.dark};
}

/////
export const createEvent = <T>(eventName: string, data: T) => {
    return new CustomEvent<T>(eventName, {
        detail: data,
    });
}

const developMode = false;
export const WAIT = 1;
export const dispatchCustomEvent: <T>(eventName: string) => (data: T) => void =
    (eventName: string) => debounce((data) => {
        developMode && console.log('dispatchCustomEvent', eventName, data);
        document.dispatchEvent(
            createEvent(eventName, data)
        )
    }, WAIT); 
// export const dispatchCustomEvent: <T>(eventName: string) => (data: T) => void =
//     function (eventName: string) {
//         return debounce((data) => {
//             developMode && console.log('dispatchCustomEvent', eventName, data)
//             document.dispatchEvent(
//                 createEvent(eventName, data)
//             )
//         }, WAIT)}

export const createEventChangeVariable =  debounce((data: TChangeVariableEventData) => {
    developMode && console.log('dispatchCustomEvent', changeVariableEventName, data);
    document.dispatchEvent(
        createEvent(changeVariableEventName, data)
    )
}, WAIT);
export const createEventSetActiveBlock =  debounce((data: TSetActiveBlockEventData) => {
    developMode && console.log('dispatchCustomEvent', setActiveBlockEventName, data);
    document.dispatchEvent(
        createEvent(setActiveBlockEventName, data)
    )
}, WAIT);
export const createEventSetHighlightedBlocks =  debounce((data: TSetForceHighlightedBlocksEventData) => {
    developMode && console.log('dispatchCustomEvent', setForceHighlightedEventName, data);
    document.dispatchEvent(
        createEvent(setForceHighlightedEventName, data)
    )
}, WAIT);
export const createEventSetHighlightedVariable =  debounce((data: TSetHighlightedVariableEventData) => {
    developMode && console.log('dispatchCustomEvent', setHighlightedVariableEventName, data);
    document.dispatchEvent(
        createEvent(setHighlightedVariableEventName, data)
    )
}, WAIT);
export const createEventAddFillableField =  debounce((data: TAddFillableFieldEventData) => {
    developMode && console.log('dispatchCustomEvent', addFillableFieldVariableEventName, data);
    document.dispatchEvent(
        createEvent(addFillableFieldVariableEventName, data)
    )
}, WAIT);
export const createEventSetPagePropertiesMenu =  debounce((data: TSetPagePropertiesMenuEventData) => {
    developMode && console.log('dispatchCustomEvent', setPagePropertiesMenuVariableEventName, data);
    document.dispatchEvent(
        createEvent(setPagePropertiesMenuVariableEventName, data)
    )
}, WAIT);
export const createEventChangeVariableByTextComponent =  debounce((data: TEditorVariableData) => {
    developMode && console.log('dispatchCustomEvent', changeVariableByTextComponentEventName, data);
    document.dispatchEvent(
        createEvent(changeVariableByTextComponentEventName, data)
    )
}, WAIT);
export const createEventSetListElementActive =  debounce((data: TSetListElementActiveEventData) => {
    developMode && console.log('dispatchCustomEvent', setListElementActiveEventName, data);
    document.dispatchEvent(
        createEvent(setListElementActiveEventName, data)
    )
}, WAIT);
export const createEventDeleteVariable =  debounce((data: TDeleteVariableEventData) => {
    developMode && console.log('dispatchCustomEvent', deleteVariableEventName, data);
    document.dispatchEvent(
        createEvent(deleteVariableEventName, data)
    )
}, WAIT);
export const createEventOpenManageActorsDialog =  debounce((data: TOpenDialogEventData) => {
    developMode && console.log('dispatchCustomEvent', openManageActorsDialogEventName, data);
    document.dispatchEvent(
        createEvent(openManageActorsDialogEventName, data)
    )
}, WAIT);
export const createEventOpenManageEditorsDialog =  debounce((data: TOpenDialogEventData) => {
    developMode && console.log('dispatchCustomEvent', openManageEditorsDialogEventName, data);
    document.dispatchEvent(
        createEvent(openManageEditorsDialogEventName, data)
    )
}, WAIT);
export const createEventUpdateRecipient =  debounce((data: TUpdateRecipientEventData) => {
    developMode && console.log('dispatchCustomEvent', updateRecipientEventName, data);
    document.dispatchEvent(
        createEvent(updateRecipientEventName, data)
    )
}, WAIT);
export const createEventUnfocusAllBlocks =  debounce((data: {}) => {
    developMode && console.log('dispatchCustomEvent', unfocusAllBlocksEventName, data);
    document.dispatchEvent(
        createEvent(unfocusAllBlocksEventName, data)
    )
}, WAIT);
export const createEventBlockCreatedByDrop =  debounce((data: TBlockCreatedByDropEventData) => {
    developMode && console.log('dispatchCustomEvent', blockCreatedByDropEventName, data);
    document.dispatchEvent(
        createEvent(blockCreatedByDropEventName, data)
    )
}, WAIT);
export const createEventNeedUpdateActors =  debounce((data: {}) => {
    developMode && console.log('dispatchCustomEvent', needUpdateActorsEventName, data);
    document.dispatchEvent(
        createEvent(needUpdateActorsEventName, data)
    )
}, WAIT);
export const createEventUpdateDataInFillableField =  debounce((data: TUpdateDataInFillableFieldEventData) => {
    developMode && console.log('dispatchCustomEvent', updateDataInFillableFieldEventName, data);
    document.dispatchEvent(
        createEvent(updateDataInFillableFieldEventName, data)
    )
}, WAIT);
export const createEventCommonDialogOpen =  debounce((data: TCommonDialogOpenEventData) => {
    developMode && console.log('dispatchCustomEvent', commonDialogOpenEventName, data);
    document.dispatchEvent(
        createEvent(commonDialogOpenEventName, data)
    )
}, WAIT);
export const createEventUpdateEditorsEventName =  debounce((data: TEditorActor[]) => {
    developMode && console.log('dispatchCustomEvent', updateEditorsEventName, data);
    document.dispatchEvent(
        createEvent(updateEditorsEventName, data)
    )
}, WAIT);
export const createEventDeleteBlock =  debounce((data: TDeleteBlockEventData) => {
    developMode && console.log('dispatchCustomEvent', deleteBlockEventName, data);
    document.dispatchEvent(
        createEvent(deleteBlockEventName, data)
    )
}, WAIT);
export const createEventUpdateVariableUsages =  debounce((data: {}) => {
    developMode && console.log('dispatchCustomEvent', updateVariableUsagesEventName, data);
    document.dispatchEvent(
        createEvent(updateVariableUsagesEventName, data)
    )
}, WAIT);
