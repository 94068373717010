import {Button, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteTemplateDialog, loading, selectedTemplate} from "../../../store/slice";
import {DeleteEmailTemplatesAdmin} from "../../../store/actions";
import {useHistory} from "react-router-dom";
import {TEMPLATES_LIST_PATH} from "../../../constants";
import {CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../../../../../../shared/components/CustomDialog";
import { LoadingButton } from "../../../../../../shared/components/Basic/CommonButtons";


export const DeleteTemplateDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpen = useSelector(dialogs).deleteTemplate;
    const isLoading = useSelector(loading).isLoadingDelete;
    const template = useSelector(selectedTemplate);

    const handleClose = () => dispatch(hideDeleteTemplateDialog());

    const handleConfirm = () => {
        template && dispatch(DeleteEmailTemplatesAdmin({template, onSuccess}))
    }

    const onSuccess = () => {
        history.push(TEMPLATES_LIST_PATH);
    }
    return (
        <CustomDialog open={isOpen} onClose={handleClose} >
            <CustomDialogTitle >{'Delete template '} {template?.id}</CustomDialogTitle>

            <CustomDialogContent >
                {<Typography >{`Are you sure you want to delete?`}</Typography>}
            </CustomDialogContent>

            <CustomDialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>{'Cancel'}</Button>
                <LoadingButton loading={isLoading} onClick={handleConfirm} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>
                    {'Confirm'}
                </LoadingButton>
            </CustomDialogActions>
        </CustomDialog>
    )
}