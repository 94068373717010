//core
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//components
import {Flex} from '../../../../../shared/components/Layouts';
import {Typo} from '../../../../../shared/components/Typography';

//redux
import {contactDialog, hideContactDialog, isLoading} from '../../store/slice';
import {useForm} from "../../../../../shared/hooks/useForm";
import {SendEmailSupport} from "../../store/actions";
import {Spinner} from "../../../../../shared/components/Spinner";
import {CustomInput, TextArea} from "../../../../../shared/components/Inputs";
import {Dialog, DialogContent} from "@mui/material";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";


export const ContactDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'loginPageSupportDialog'});

    const dispatch = useDispatch();
    const isOpen = useSelector(contactDialog);
    const _isLoading = useSelector(isLoading);

    useEffect(() => {
        // updateWorkspace(workspace);
    }, [isOpen]);

    const {form: { email, name, message}, handleChange} = useForm({email: '', message: '', name: ''});
    const isActive = (): boolean => {
        return email.trim().length > 0 && message.trim().length > 0 && name.trim().length > 0;
    }

    const handleSend = () => {
        dispatch(SendEmailSupport({email, name, message}));
    }

    const handleCloseButton = () => {
        dispatch(hideContactDialog());
    }

    return(
        <Dialog
            open={(isOpen)}
            maxWidth={"sm"}
            onClose={handleCloseButton}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Flex ai={'center'} jc={'center'} w={'100%'} m={'15px 0 0 0'}>
                <Typo variant="h1" fontSize="30px" fontWeight={600} letterSpacing="0.1" cursor="default" alignSelf={'center'}>{t('Contact Us')}</Typo>
            </Flex>

            <DialogContent sx={{overflowX: 'hidden'}}>
                <CustomInput
                    label={t('Email')}
                    onChange={handleChange}
                    fullWidth={true}
                    value={email}
                    name="email"
                    style={{margin: '0 0 15px 0'}}
                />

                <CustomInput
                    label={t('Full Name')}
                    value={name}
                    onChange={handleChange}
                    fullWidth
                    name="name"
                    style={{margin: '0 0 15px 0'}}

                />

                <TextArea
                    label={t('Describe your problem')}
                    fullWidth
                    value={message}
                    name="message"
                    onChange={handleChange}
                    style={{margin: '0 0 15px 0'}}
                />

                <CustomButton
                    variant={'contained'}
                    active={isActive()}
                    disabled={!isActive()}
                    onClick={() => handleSend()}
                    sx={{width: '214px'}}>
                    {t('Send')}
                </CustomButton>
            </DialogContent>
            {_isLoading && <Spinner />}
        </Dialog>
    );
}
