import React, {FC, ReactNode} from "react";
import {Grid} from "@material-ui/core";
import background from "../../images/login/background.png";
import {Box} from "../Layouts";

type PropsType = {
    children: ReactNode,
    revDir: boolean

}
export const SignPage: FC<PropsType> = ({children, revDir}: PropsType) => {

    return (
        <Box h={'100vh'} w={'100vw'} overflowy={'auto'}>
            <Grid container spacing={0} style={{height: '100%'}}>
                <Grid xs={false} sm={false} md={6}
                      style={
                          {
                              height: '100%',
                              backgroundImage: `url(${background})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              transform: revDir ? 'scaleX(-1)' : 'none',
                          }
                      }/>

                <Grid sm={8} md={6}
                      container
                      direction={"column"}
                      alignItems={"center"}
                      justify={"flex-end"}
                      style={
                          {
                              height: '100%'
                          }
                      }
                >
                        {children}
                </Grid>
            </Grid>
        </Box>
    )
}
