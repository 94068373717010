import React, {FC} from "react";
import {Box, FlexAround, FlexBetween, FlexEnd} from "../../../../shared/components/Layouts";
import {TOP_BAR_PERCENT} from "../constants";
import {NavigationBar} from "../navigation";
import {IconButton} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {WHITE_TEXT} from "../../leftSideBar/constants";
import {Typo} from "../../../../shared/components/Typography";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_LOGIN} from "../../shell/constants";
import {useMainTranslation} from "../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {TopBarSettings} from "../settings";

type PropTypes = {
    reference: React.Ref<any>
};
const forwardStyle = {color: WHITE_TEXT};
export const TopBarProtectedRightSide: FC<PropTypes> = (props: PropTypes) => {
    const {reference} = props;

    return (
        <FlexBetween w={`calc(100% - ${TOP_BAR_PERCENT}%)`}>
            <NavigationBar/>
            <TopBarSettings reference={reference}/>
        </FlexBetween>
    )
};

export const TopBarUnProtectedRightSide: FC<PropTypes> = (props: PropTypes) => {
    const history = useHistory();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'navigationMenu'});

    return (
        <FlexBetween w={`calc(100% - ${TOP_BAR_PERCENT}%)`}>
            <NavigationBar/>
            <Box w={'10%'}>
                <FlexEnd>
                    <Box m={!revDir ? '0 1em 0 0' : '0 0 0 1em'}>
                        <IconButton
                            onClick={() => {}}
                            aria-label="Menu header"
                            style={forwardStyle}
                        >
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Box>
                    <FlexAround d={'column'} onClick={() => {
                        history.push(PATH_LOCAL_LOGIN);
                    }}>
                        <Typo fontSize={"14px"} fontWeight={300} color="white" cursor="pointer">{t('LOGIN')}</Typo>
                    </FlexAround>
                </FlexEnd>
            </Box>
        </FlexBetween>
    )
};
