import {useDispatch, useSelector} from "react-redux";
import {extendedOrganization, openChangeWSAccountMappingDialog, openChangeWSProfileMappingDialog, openDeleteWorkspaceDialog} from "../../store/slice";
import {Flex} from "../../../../../shared/components/Layouts";
import {Typo} from "../../../../../shared/components/Typography";
import {IconButton} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {organizationWorkspace} from "../../types";
import {OrganizationWorkspaceCollabsTable} from "../organizationWorkspaceCollabsTable";
import {CustomButton} from "../../../../../shared/components/Buttons";

export const WorkspacesList = () => {
    const dispatch = useDispatch();
    const extended = useSelector(extendedOrganization);

    const openChangeAccountMappingScheme = (ws: organizationWorkspace) => {
        dispatch(openChangeWSAccountMappingDialog({workspaceId: ws.id, prevConfig: ws.config}));
    }

    const openChangeProfileMappingScheme = (ws: organizationWorkspace) => {
        dispatch(openChangeWSProfileMappingDialog({workspaceId: ws.id, prevConfig: ws.config}));
    }

    const openDeleteWs = (id: string) => {
        dispatch(openDeleteWorkspaceDialog(id));
    }

    return(
        extended ?
            <Flex w={'100%'} overflowy={'auto'} direction={'column'} p={'0 5px 5px 5px'} maxh={'60vh'}>
                {extended.workspaces.map((e, id) => {
                    return(
                        <Flex
                            key={e.id}
                            m={!id ? '5px 0 0 0' : '15px 0 0 0'}
                            w={'100%'}
                            jc={'space-between'}
                            p={'18px'}
                            br={'12px'}
                            box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                            background={'#fff'}
                            direction={'column'}
                        >
                            <Flex jc={'space-between'} w={'100%'} >
                                <Flex w={'45%'} direction={'column'} >
                                    <Typo>{e.name}</Typo>
                                    <Typo margin={'10px 0 0 0'}>{e.id}</Typo>
                                </Flex>

                                <Flex w={'45%'} direction={'column'}>
                                    <Flex ai={'center'}>
                                        <Typo>Account Mapping scheme</Typo>
                                        <IconButton onClick={() => openChangeAccountMappingScheme(e)} size={'small'}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Flex>

                                    <Flex ai={'center'}>
                                        <Typo>Profile Mapping scheme</Typo>
                                        <IconButton onClick={() => openChangeProfileMappingScheme(e)} size={'small'}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Flex>
                                </Flex>

                                <Flex>
                                    <CustomButton variant={'contained'} color={'error'} size={'small'} onClick={() => openDeleteWs(e.id)}>Delete</CustomButton>
                                </Flex>
                            </Flex>

                            <OrganizationWorkspaceCollabsTable workspace={e}/>
                        </Flex>
                    )
                })}
            </Flex>
            :
            <></>
    )
}