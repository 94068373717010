import {Redirect, Route, Switch} from "react-router"
import {PATH_LOCAL_APPS_CREATE, PATH_LOCAL_APPS_EXACT, PATH_LOCAL_APPS_LIST} from "./constants";
import {AppsList} from "./pages/list";
import {ExactApp} from "./pages/exact";
import {CreateApp} from "./pages/create";

export const Routes = () => {
    return(
        <Switch>
            <Route exact path={PATH_LOCAL_APPS_LIST} component={AppsList}/>
            <Route exact path={PATH_LOCAL_APPS_EXACT} component={ExactApp} />
            <Route exact path={PATH_LOCAL_APPS_CREATE} component={CreateApp} />

            <Redirect to={PATH_LOCAL_APPS_LIST}/>
        </Switch>
    )
}