import {useDispatch, useSelector} from "react-redux";
import {roles, loading, openCreateRoleDialog, openCreatePermissionDialog, permissions} from "../../store/slice";
import {useEffect, useState} from "react";
import {TPermission} from "../../types";
import {useForm} from "../../../../../shared/hooks/useForm";

export const useRolesList = () => {
    const dispatch = useDispatch();
    const _roles = useSelector(roles);
    const _permissions = useSelector(permissions);
    const {rolesList, permissionsCreate, permissionsDelete, permissionsUpdate} = useSelector(loading);

    useEffect(() => {
        setForm({search: ''});
        setFilteredPermission(_permissions);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!rolesList && !filteredPermission.length){
            doFilter();
        }
        //eslint-disable-next-line
    }, [rolesList]);

    useEffect(() => {
        //when list is updated - rerender
        if(!permissionsUpdate || !permissionsCreate || !permissionsDelete){
            doFilter();
        }
        //eslint-disable-next-line
    }, [permissionsDelete, permissionsUpdate, permissionsCreate]);

    //tabs
    const [tab, setTab] = useState<'roles' | 'permissions'>('roles');

    const handleOpenCreateRole = () => {
        dispatch(openCreateRoleDialog());
    }

    const handleOpenCreatePermission = () => {
        dispatch(openCreatePermissionDialog());
    }

    //permissions
    const [filteredPermission, setFilteredPermission] = useState<TPermission[]>([]);

    const {form, setForm, handleChange} = useForm<{search: string}>({search: ''});

    const handleCleanSearch = () => {
        setForm({search: ''});
        setFilteredPermission(_permissions);
    }

    const doFilter = () => {
        setFilteredPermission(_permissions.filter((e, id) =>
            e.name.trim().toLowerCase().startsWith(form.search.trim().toLowerCase()) ||
            e.tags.some(e => e.trim().toLowerCase().startsWith(form.search.trim().toLowerCase())) ||
            id.toString() === form.search.trim().toLowerCase()
        ));
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            setFilteredPermission(_permissions);
        }
        if(e.keyCode === 13 && form.search.trim().length > 0){
            doFilter();
        }
    }

    return{
        roles: _roles,
        isLoading: rolesList,
        tab,
        setTab,
        handleOpenCreatePermission,
        handleOpenCreateRole,
        _permissions: filteredPermission,
        search: {
            handleChange,
            search: form.search,
            handleEnterKey,
            handleCleanSearch
        },
    }
}

