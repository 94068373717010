import moment from "moment";
import {store} from "../../../../shared/redux";
import { addInfoSnack } from "../../../BarsENV/snack/store/slice";

export const parseDate = (date: string, locale: string): string => {
    // console.log(locale);
    if(!date || !date.length) return '- - -';
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    return moment(parsedDate).locale(locale).format('LL');
    // dateFns
    // return parsedDate.toLocaleDateString() + ' ' + parsedDate.toLocaleTimeString(undefined, { hour12: false})
}

export const isExpired = (date: string, locale: string): boolean => {
    if(!date || !date.length) return true;
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    // console.log(`EXPIRES ON: ${moment(parsedDate)} - - - NOW: ${moment(new Date(Date.now()))}`);
    return moment(parsedDate).locale(locale).isBefore(moment(new Date(Date.now())));

}

export const getSub = (subscription: string) => {
    switch(subscription){
        case 'SUBSCRIPTION_BUSINESS': return 'Business';
        case 'SUBSCRIPTION_BUSINESS_PLUS': return 'Business+';
        case 'SUBSCRIPTION_CONSULTANT': return 'Consultant';
        case 'SUBSCRIPTION_AUDITOR': return 'Auditor';
        default: return 'Subscription is not defined...'
    }
}


export const copyTextToClipBoard = (text: string, snack?: string) => {
    navigator.clipboard.writeText(text);
    snack && store.dispatch(addInfoSnack(snack));
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

