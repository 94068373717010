//core
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

//components
//redux
import {frameworkType} from "../../../../types";
import {deleteFrameworkDialog, hideDeleteFramework} from "../../../../store/slice";
import {DeleteFramework} from "../../../../store/actions";
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {Typo} from "../../../../../../../shared/components/Typography";
import {Dialog, DialogContent} from "../../../styled";
import {Box, Flex} from '../../../../../../../shared/components/Layouts';
import {CustomButton} from '../../../../../../../shared/components/Buttons';
import {CustomInput} from '../../../../../../../shared/components/Inputs';
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";


export const DeleteFrameworkDialog = ({framework}: {framework: frameworkType}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const dispatch = useDispatch();
    const {isOpen} = useSelector(deleteFrameworkDialog);

    const [field, setField] = React.useState<string>("");

    const handleChange = (e: React.ChangeEvent<{name?:string | undefined; value?:unknown}>) => {
        setField(e.target.value as string);
    }

    const handleClick = () => {
        if(field.trim() === "confirm"){
            dispatch(DeleteFramework(framework.id));
            setField("");
        }
    }

    const handleCloseButton = () => {
        setField("");
        dispatch(hideDeleteFramework());
    }

    return(
        <Dialog
            open={isOpen}
            maxWidth={"md"}
            onClose={handleCloseButton}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent style={{backgroundColor:"#FBFBFB"}}>
                {/* <Flex ai="center" jc="flex-end">
                    <CloseIcon onClick={() => props.handleClose()}/>
                </Flex> */}

                <Flex  m="22px 0 0 0" direction="column" ai="center">
                    <Typo fontSize="25px" fontWeight={300} letterSpacing="0.57px" color="#1D1D1D" margin="0 0 20px 0">Delete Framework {framework.name}</Typo>

                    <Box talign="center" >
                        <Typo fontSize="12px" fontWeight={400} letterSpacing="0.4px" margin="0 0 31px 0" color="rgba(0,0,0, 0.54)">{t('Warning! All pipelines using this framework as evidence source will be stopped!')}</Typo>
                    </Box>
                    <Typo fontSize="13px" fontWeight={600} letterSpacing="0.4px" margin="0 0 31px 0" color="rgba(0,0,0, 0.54)">{t('Please type')} "<b style={{fontWeight:800}}>confirm</b>" {t('to delete')}</Typo>
                    {/* <DeleteDialogInput
                        style={{margin: '35px 0'}}
                        label={<Typo fontSize="13px" fontWeight={600} letterSpacing="0.4px" margin="0 0 31px 0" color="rgba(0,0,0, 0.54)">Please type "<b style={{fontWeight:800}}>confirm</b>" to delete</Typo>}
                        value={field}
                        onChange={(e) => handleChange(e)}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        name="verify"/> */}
                    <CustomInput
                        label={"Confirm"}
                        value={field}
                        onChange={(e) => handleChange(e)}
                        name="verify"
                        sx={{margin: '0 0 35px 0'}}
                    />
                    <CustomButton variant={'contained'} disabled={field.trim() !== "confirm"} color={'lightError'} onClick={handleClick} sx={{marginBottom: '30px'}}>{t('Delete')}</CustomButton>
                    {/* <ButtonWithBackround text="DELETE" fontSize="20px" fontWeight={600} letterSpacing="1.23px" color={field.trim() === "confirm" ? "red" : "grey"} width="80%" height="40px" paddingLeft="0" margin="30px 0" onClick={handleClick} /> */}
                </Flex>

            </DialogContent>
        </Dialog>
    );
}
