import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    vicaAdminAddFromFilesApi, vicaAdminChangeCategoriesOrderApi,
    vicaAdminCreateCategoryApi,
    vicaAdminCreateTaskApi,
    vicaAdminDeleteCategoryApi,
    vicaAdminDeleteTaskApi,
    vicaAdminGetCategoriesApi,
    vicaAdminGetTaskByIdApi,
    vicaAdminGetTasksByCategoryIdApi,
    vicaAdminUpdateCategoryApi,
    vicaAdminUpdateTaskApi
} from "../api";
import {TTask, TTaskCategory, TTaskFiles, TTaskOrder} from "../types";
import {addSuccessfulSnack} from "../../../BarsENV/snack/store/slice";

export const vicaAdminGetCategoriesAction = createAsyncThunk(
    'VicaTasks/getCategories',
    async (data: {}, {dispatch}) => {
        const resp = await vicaAdminGetCategoriesApi();
        return resp;
    }
)

export const vicaAdminGetTasksByCategoryIdAction = createAsyncThunk(
    'VicaTasks/getTasksByCategoryId',
    async (data: {categoryId: string, frameworkView: boolean}, {dispatch}) => {
        const resp = await vicaAdminGetTasksByCategoryIdApi(data);
        return resp;
    }
)

export const vicaAdminGetTaskByIdAction = createAsyncThunk(
    'VicaTasks/getTaskById',
    async (data: {taskId: string, categoryId: string, frameworkView: boolean}, {dispatch}) => {
        const resp = await vicaAdminGetTaskByIdApi(data);
        return resp;
    }
)


export const vicaAdminCreateTaskAction = createAsyncThunk(
    'VicaTasks/createTask',
    async (data: {task: TTask, categoryId: string, frameworkView: boolean}, {dispatch}) => {
        const resp = await vicaAdminCreateTaskApi(data);
        return resp;
    }
)


export const vicaAdminUpdateTaskAction = createAsyncThunk(
    'VicaTasks/updateTask',
    async (data: {task: TTask, categoryId: string, frameworkView: boolean, moveToCategoryId?: string}, {dispatch}) => {
        const resp = await vicaAdminUpdateTaskApi(data);
        return {task: resp, moveToCategoryId: data.moveToCategoryId};
    }
)


export const vicaAdminDeleteTaskAction = createAsyncThunk(
    'VicaTasks/deleteTask',
    async (data: {taskId: string, categoryId: string, frameworkView: boolean}, {dispatch}) => {
        const resp = await vicaAdminDeleteTaskApi(data);
        return resp;
    }
)


export const vicaAdminCreateCategoryAction = createAsyncThunk(
    'VicaTasks/createCategory',
    async (data: {category: TTaskCategory, orders: TTaskOrder[]}, {dispatch}) => {
        const resp = await vicaAdminCreateCategoryApi({
            orders: data.orders,
            category: {id: data.category.id, name: data.category.name, description: data.category.description}
        });
        return resp;
    }
)


export const vicaAdminUpdateCategoryAction = createAsyncThunk(
    'VicaTasks/updateCategory',
    async (data: {category: TTaskCategory, orders: TTaskOrder[], tasks?: TTask[]}, {dispatch}) => {
        const resp = await vicaAdminUpdateCategoryApi({
            orders: data.orders,
            category: {id: data.category.id, name: data.category.name, description: data.category.description},
            tasks: data.tasks,
        });
        return resp;
    }
)

export const vicaAdminChangeCategoriesOrderAction = createAsyncThunk(
    'VicaTasks/changeCategoriesOrder',
    async (data: {order: TTaskOrder[]}, {dispatch}) => {
        const resp = await vicaAdminChangeCategoriesOrderApi({
            order: data.order,
        });
        return resp;
    }
)


export const vicaAdminDeleteCategoryAction = createAsyncThunk(
    'VicaTasks/deleteCategory',
    async (data: {categoryId: string}, {dispatch}) => {
        const resp = await vicaAdminDeleteCategoryApi(data);
        return resp;
    }
)

export const vicaAdminAddFromFilesAction = createAsyncThunk(
    'VicaTasks/addFromFiles',
    async (data: {files: TTaskFiles}, {dispatch}) => {
        const resp = await vicaAdminAddFromFilesApi(data);

        dispatch(addSuccessfulSnack('Successfully added tasks from files'))
        return resp;
    }
)


