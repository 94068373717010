import {useDispatch, useSelector} from "react-redux";
import {dialogs, extendedOrganization, loading, hideChangeOrgTrialDialog} from "../../../store/slice";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Autocomplete} from "@mui/lab";
import {UpdateOrgTrialUsed} from "../../../store/actions";

export const UpdateOrganizationTrialUsedDialog = () => {
    type trialType = 'Used' | 'Unused';
    const dispatch = useDispatch();
    const org = useSelector(extendedOrganization);
    const {isLoadingChangeTrail} = useSelector(loading);
    const {changeOrgTrialDialog : {isOpen}} = useSelector(dialogs);
    const [trialUsed, setTrialUsed] = useState<trialType>('Used');

    const handleClose = () => {
        dispatch(hideChangeOrgTrialDialog());
    }

    const handleSubmit = () => {
        if(org && !isEqual(trialUsed, org.trialUsed)){
            dispatch(UpdateOrgTrialUsed({id: org.id, isUsed: trialUsed}));
        }
    }

    const isEqual = (trailUsed: string, boolTrial: boolean) => {
        return (boolTrial && trailUsed === 'Used') || (!boolTrial && trailUsed === 'Unused');
    }

    useEffect(() => {
        if(isOpen && org){
            setTrialUsed(org.trialUsed ? 'Used' : 'Unused');
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle>Update Trial status</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{marginTop: '15px'}}
                    autoComplete
                    fullWidth
                    openOnFocus
                    id="combo-box-demo"
                    value={trialUsed}
                    options={['Used', 'Unused']}
                    onChange={(e, value) => {value && setTrialUsed(value as trialType)}}
                    renderInput={(params) => <TextField {...params} label="Trial" />}
                />
            </DialogContent>
            {isLoadingChangeTrail ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!org || (org && isEqual(trialUsed, org.trialUsed))} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}