import {createAsyncThunk} from "@reduxjs/toolkit";
import {PaginationInput} from "../../../../shared/types";
import {addInfoSnack, addSuccessfulSnack } from "../../../BarsENV/snack/store/slice";
import {
    activateOrganization,
    blockOrganization,
    deleteOrganization,
    deleteWorkspace,
    getExtendedOrganization,
    getQuickStarts,
    getShortOrganizations,
    transferOwnership,
    updateAvailableCollabsCounter,
    updateAvailableVulTargetsCounter,
    updateAvailableWorkspacesCounter,
    updateOrganizationWizards,
    updateOrgCollabsRules,
    updateOrgSubscription,
    updateOrgSubscriptionExpiry,
    updateOrgTrialUsed,
    updateWorkspaceAccountMappingConfig,
    updateWorkspaceProfileMappingConfig
} from "../api";
import {organizationCollabsRuleConfig, workspaceConfigProfileMappingType} from "../types";

export const GetShortOrganizations = createAsyncThunk(
    'Organizations/getShortOrganizations',
    async (data: {pageInfo: PaginationInput}) => {
        return await getShortOrganizations(data.pageInfo);
    }
);

//getExtendedOrganization
export const GetExtendedOrganization = createAsyncThunk(
    'Organizations/getExtendedOrganization',
    async (data: {id: string}) => {
        return await getExtendedOrganization(data.id);
    }
);

//getQuickStarts
export const GetQuickStarts = createAsyncThunk(
    'Organizations/getQuickStarts',
    async () => {
        return await getQuickStarts();
    }
);

//activateOrganization
export const ActivateOrganization = createAsyncThunk(
    'Organizations/activateOrganization',
    async (data: {id: string}, {dispatch}) => {
        const mes = await activateOrganization(data.id);
        dispatch(addSuccessfulSnack(mes));
        return data.id;
    }
);

// //addCollabToOrganization
// export const AddCollabToOrganization = createAsyncThunk(
//     'Organizations/addCollabToOrganization',
//     async (data: {id: string, email: string, role: string}, {dispatch}) => {
//         const res = await addCollabToOrganization(data.id, data.email, data.role);
//         dispatch(addSuccessfulSnack(`${data.email} added!`));
//         return {res, orgId: data.id};
//     }
// );

//blockOrganization
export const BlockOrganization = createAsyncThunk(
    'Organizations/blockOrganization',
    async (data: {id: string, reason: string}, {dispatch}) => {
        const mes = await blockOrganization(data.id, data.reason);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//deleteOrganization
export const DeleteOrganization = createAsyncThunk(
    'Organizations/deleteOrganization',
    async (data: {id: string}, {dispatch}) => {
        const mes = await deleteOrganization(data.id);
        dispatch(addInfoSnack(mes));
        return data.id;
    }
);

//deleteWorkspace
export const DeleteWorkspace = createAsyncThunk(
    'Organizations/deleteWorkspace',
    async (data: {id: string}, {dispatch}) => {
        const mes = await deleteWorkspace(data.id);
        dispatch(addInfoSnack(mes));
        return data.id;
    }
);

//transferOwnership
export const TransferOwnership = createAsyncThunk(
    'Organizations/transferOwnership',
    async (data: {id: string, from: string, to: string}, {dispatch}) => {
        const mes = await transferOwnership(data.id, data.from, data.to);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateWorkspaceAccountMappingConfigByAdmin
export const UpdateWorkspaceAccountMappingConfig = createAsyncThunk(
    'Organizations/updateWorkspaceAccountMappingConfig',
    async (data: {id: string, newValue: string}, {dispatch}) => {
        const mes = await updateWorkspaceAccountMappingConfig(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateWorkspaceProfileMappingConfig
export const UpdateWorkspaceProfileMappingConfig = createAsyncThunk(
    'Organizations/updateWorkspaceProfileMappingConfig',
    async (data: {id: string, newValue: workspaceConfigProfileMappingType[]}, {dispatch}) => {
        const mes = await updateWorkspaceProfileMappingConfig(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateAvailableCollabsCounter
export const UpdateAvailableCollabsCounter = createAsyncThunk(
    'Organizations/updateAvailableCollabsCounter',
    async (data: {id: string, newValue: number}, {dispatch}) => {
        const mes = await updateAvailableCollabsCounter(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateOrgCollabsRules
export const UpdateOrgCollabsRules = createAsyncThunk(
    'Organizations/updateOrgCollabsRules',
    async (data: {id: string, newValue: organizationCollabsRuleConfig[]}, {dispatch}) => {
        const mes = await updateOrgCollabsRules(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateOrgSubscription
export const UpdateOrgSubscription = createAsyncThunk(
    'Organizations/updateOrgSubscription',
    async (data: {id: string, subscription: string}, {dispatch}) => {
        const mes = await updateOrgSubscription(data.id, data.subscription);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateOrgSubscriptionExpiry
export const UpdateOrgSubscriptionExpiry = createAsyncThunk(
    'Organizations/updateOrgSubscriptionExpiry',
    async (data: {id: string, expiry: string}, {dispatch}) => {
        const mes = await updateOrgSubscriptionExpiry(data.id, data.expiry);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateOrgTrialUsed
export const UpdateOrgTrialUsed = createAsyncThunk(
    'Organizations/updateOrgTrialUsed',
    async (data: {id: string, isUsed: string}, {dispatch}) => {
        const mes = await updateOrgTrialUsed(data.id, data.isUsed);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateOrgWizards
export const UpdateOrgWizards = createAsyncThunk(
    'Organizations/updateOrgWizards',
    async (data: {id: string, wizardIds: string[]}, {dispatch}) => {
        const mes = await updateOrganizationWizards(data.id, data.wizardIds);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateAvailableWorkspacesCounter
export const UpdateAvailableWorkspacesCounter = createAsyncThunk(
    'Organizations/updateAvailableWorkspacesCounter',
    async (data: {id: string, newValue: number}, {dispatch}) => {
        const mes = await updateAvailableWorkspacesCounter(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//updateAvailableVulTargetsCounter
export const UpdateAvailableVulTargetsCounter = createAsyncThunk(
    'Organizations/updateAvailableVulTargetsCounter',
    async (data: {id: string, newValue: number}, {dispatch}) => {
        const mes = await updateAvailableVulTargetsCounter(data.id, data.newValue);
        dispatch(addInfoSnack(mes));
        return data;
    }
);