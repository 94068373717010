import React, {FC} from 'react';
import {Routes} from "./routes";
import {useMain} from "./hooks/useMain";
import {LeftSideBar} from "./components/leftSideBar";
import {Flex} from '../../../shared/components/Layouts';
import {AddFrameworkDialog} from "./components/dialogs/frameworks/addFramework";
import {PreviewEvidenceDialog} from "./components/dialogs/evidences/previewEvidence";
import {AddControlsAndEvidencesDialog} from "./components/dialogs/addFromFiles/addControlsAndEvidences";
import {AddGapDialog} from "./components/dialogs/addFromFiles/addGap";
import {EditorJsDialog} from "./components/dialogs/gallery/editorJsDialog";
import {CreateTemplateIdDialog} from "./components/dialogs/gallery/createTemplateIdDialog";


export const Builder:FC = () => {
    useMain();
    return (
        <Flex w={'100%'} h={'100% '} jc={'space-between'} ai={'center'}  p={'16px 50px 0 50px'}>
            <LeftSideBar />
            <Routes />

            <AddFrameworkDialog />
            <PreviewEvidenceDialog />
            <AddControlsAndEvidencesDialog />
            <AddGapDialog />
            {/*<EditorDialog />*/}
            <EditorJsDialog />
            <CreateTemplateIdDialog />
        </Flex>
    )
}
