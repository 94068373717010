import Divider from "@mui/material/Divider";
import styled from "styled-components";

export type customTableRowPropsType = {
    onClick?: () => void;
    disableHover?: boolean;
    width?: string | undefined;
    innerWrapperWidth?: string | undefined;
    innerWrapperJc?: string | undefined;
    minWidth?: string | undefined;
    divider?: boolean;
    children: JSX.Element[]
}

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    display: flex;
   
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: #F7F7F7;
            cursor: pointer;
        }
    `}
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string, width?: string}>`
    width: ${props => props.width ?? '90%'};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: #F7F7F7;
            cursor: pointer;
        }
    `}

`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 12px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const CustomTableRow = ({onClick = () => null, disableHover, width, innerWrapperWidth, innerWrapperJc, minWidth, divider, children}: customTableRowPropsType) => {
    return(
        <>
            <MainTableRowWrapper onClick={onClick} width={width} minWidth={minWidth} disableHover={disableHover}>
                <TableRowWrapper width={innerWrapperWidth} jc={innerWrapperJc} disableHover={disableHover}>
                    {children}

                    {/*<S.TableRowItemWrapper width={'25%'}>*/}
                    {/*    <Typography sx={{color: colors.darkText, fontSize: '16px', fontWeight: 400}}>{app.name}</Typography>*/}
                    {/*</S.TableRowItemWrapper>*/}
                </TableRowWrapper>
            </MainTableRowWrapper>
            {divider && <Divider flexItem sx={{bgcolor: '#E0E0E0', borderColor: '#E0E0E0'}}/>}
        </>
    )
}