import {IKeyIndex, ItemTypes, TEditorActor} from "../../../../../../types";
import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import debounce from "lodash.debounce";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import * as S from '../../../styled';
import {Autocomplete, Box, CircularProgress, IconButton, TextField} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {TypographyBody2, TypographyBodySmall} from "../../../../../../../Inputs/styled";
import {CommonAvatar} from "../../../../../../../Basic/CommonAvatar";
import {ReactComponent as MessageIcon} from "../../../../../../../../../shared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../../../shared/images/documents/complete-message-icon.svg";
import {ReactComponent as RecipientIcon} from "../../../../../../../../../shared/images/documents/recipient-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import {TForm, TIdEditorRecipient} from "../index";


interface RecipientItemProps {
    form: TForm,
    isRecipientsCustomOrder: boolean,
    index: number,
    recipient: TIdEditorRecipient;
    handleDelete: (recipientId: string) => void;
    handleOpenAddMessage: (data: {recipientId: string, oldMessage: string}) => void;
    handleSetActorRecipient: (recipientId: string, actor: TEditorActor) => void;
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TIdEditorRecipient, index: number} | null
    isLoadingEmployees: boolean;
    employees: TEditorActor[];
    getEmployees: (value: string) => void;
    isStaff?: boolean;
}
export const RecipientItemEditor: FC<RecipientItemProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});

    const [newRecipient, setNewRecipient] = useState<TEditorActor | null>(null);
    const [newRecipientInput, setNewRecipientInput] = useState<string>('');

    const {
        form,
        index,
        recipient,
        isRecipientsCustomOrder,
        handleDelete,
        handleSetActorRecipient,

        isLoadingEmployees,
        employees,
        getEmployees,
        handleOpenAddMessage,

        findColumn,
        moveColumn,
        isStaff
    } = props;

    // useEffect(() => {
    //     if (recipient) {
    //         setNewRecipient(recipient.actor)
    //         setNewRecipientInput(recipient.actor.email);
    //     }
    //     // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        if (recipient) {
            setNewRecipient(recipient.actor)
            setNewRecipientInput(recipient.actor.email);
        }
        // eslint-disable-next-line
    }, [recipient.sourceEmailRole])



    //eslint-disable-next-line
    useEffect(debounce(() => {
        getEmployees(newRecipientInput);
        if (newRecipientInput === newRecipient?.email) {
            handleSetActorRecipient(recipient.id, newRecipient)
        } else {
            handleSetActorRecipient(recipient.id, { email: newRecipientInput, lastName: '', firstName: '' });
        }
        //eslint-disable-next-line
    }, 500), [newRecipientInput]);

    const searchedColumn = findColumn(recipient.id);
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: recipient.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [recipient.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: IKeyIndex) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== recipient.id) {
                    const col = findColumn(recipient.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    )



    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'12px'} width={'100%'} >
            {isRecipientsCustomOrder && <S.OrderBox>{index+1}</S.OrderBox>}

            <S.RecipientsContainer
                isDragging={isDragging ? true : undefined}
                isOrdered={isRecipientsCustomOrder ? true : undefined}
                ref={(node: ConnectableElement) => !isStaff && drag(drop(node))}
            >
                <DragIndicatorIcon sx={{opacity: isRecipientsCustomOrder && !isStaff ? 0 : 1, marginRight: '6px'}}/>

                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <S.InfoActorFlexBox>
                        <RecipientIcon />
                        <TypographyBodySmall sx={{flexGrow: 1}} >{recipient.role || `${t('Recipient')} ${index + 1}`}</TypographyBodySmall>

                        <IconButton disabled={isStaff} size={'small'} onClick={() => handleOpenAddMessage({recipientId: recipient.id, oldMessage: recipient.message || ''})}>
                            {recipient.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                        </IconButton>

                        <S.VerticalDivider />
                        <IconButton disabled={isStaff} onClick={() => handleDelete(recipient.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </S.InfoActorFlexBox>

                    {!isStaff ?
                        <Autocomplete
                            freeSolo
                            options={employees.filter(emp => !form.recipients.map(r => r.actor).find(act => act.email === emp.email))}

                            filterOptions={(options, state) => options
                                .filter(emp => emp.firstName.toLowerCase().includes(newRecipientInput)
                                    || emp.lastName.toLowerCase().includes(newRecipientInput)
                                    || emp.email.toLowerCase().includes(newRecipientInput))}
                            getOptionLabel={(e) => (e as TEditorActor).email}
                            renderOption={(props, option, state) => (
                                <li {...props}>
                                    <S.ActorRowBox>
                                        <CommonAvatar name={`${option.firstName ?? option.email} ${option.lastName ?? option.email}`} size={"d40"}/>
                                        <S.ActorDataContainer>
                                            <S.TypographyName>{`${option.firstName} ${option.lastName}`}</S.TypographyName>
                                            <S.TypographyEmail>{`${option.email}`}</S.TypographyEmail>
                                        </S.ActorDataContainer>
                                    </S.ActorRowBox>
                                    <S.ActorRowDivider />
                                </li>
                            )}
                            sx={{width: '100%', '& label': {top: '0px !important'}}}
                            value={newRecipient}
                            onChange={(event, value) => value && setNewRecipient(value as TEditorActor)}
                            inputValue={newRecipientInput}
                            onInputChange={(e, value) => setNewRecipientInput(value)}

                            // onInputChange={debounce((e, value) => {getEmployees(value)}, 500)}
                            loading={isLoadingEmployees}
                            renderInput={(params) =>
                                <TextField {...params} size={'small'} label={t('Email or name')} //error={Boolean(error)} helperText={error}
                                           InputProps={{ ...params.InputProps, endAdornment: (
                                                   <>
                                                       { isLoadingEmployees ? <CircularProgress color="inherit" size={20}/> : null}
                                                       {params.InputProps.endAdornment}
                                                   </>),
                                           }}
                                />}
                        />
                        :
                        <TypographyBody2>{recipient.actor.email}</TypographyBody2>
                    }

                </Box>
            </S.RecipientsContainer>
        </Box>
    )

}