import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {TEmailTemplate} from "../types";


export const getAllEmailTemplatesAdmin = async (): Promise<TEmailTemplate[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getAllEmailTemplatesAdmin{
                  getAllEmailTemplatesAdmin{
                    id
                    template
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAllEmailTemplatesAdmin;
    })
);

export const addEmailTemplatesAdmin = async (template: TEmailTemplate): Promise<TEmailTemplate> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addEmailTemplatesAdmin($template: AdminEmailTemplateInput!){
                  addTemplate(template:$template){
                    id
                    template
                  }
                }
                `,
                variables: {
                    template
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addTemplate;
    })
);

export const updateEmailTemplatesAdmin = async (template: TEmailTemplate): Promise<TEmailTemplate> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateEmailTemplatesAdmin($template: AdminEmailTemplateInput!){
                  updateTemplate(template:$template){
                    id
                    template
                  }
                }
                `,
                variables: {
                    template
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateTemplate;
    })
);

export const deleteEmailTemplatesAdmin = async (template: TEmailTemplate): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteEmailTemplatesAdmin($id: String!){
                  deleteTemplate(id:$id){
                    message
                  }
                }
                `,
                variables: {
                    id: template.id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteTemplate.message;
    })
);