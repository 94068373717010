import {gql} from "@apollo/react-hooks";

export const downloadImageInEditor = gql`
    query downloadImageInEditor($workspaceId: String!, $id: String!){
        downloadFile(id: $id, workspaceId: $workspaceId) {
            file
        }
    }
`

export const uploadImageInEditor = gql`
    mutation uploadImageInEditor($workspaceId: String!, $file: String!){
        uploadFile(file: $file, workspaceId: $workspaceId) {
            message
        }
    }
`

export const downloadImageInEditorStaff = gql`
    query downloadImageInEditorStaff($id:String!){
        workerDownloadFile(id:$id){
            file
        }
    }
`

export const uploadImageInEditorStaff = gql`
    mutation uploadImageInEditorStaff($file:String!){
        workerUploadFile(file:$file){
            message
        }
    }
`