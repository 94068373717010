import {useSelector} from "react-redux";
import {extendedLicense, loading, wizards} from "../../store/slice";
import {licenseTabs} from "../../types";
import {Flex} from "../../../../../shared/components/Layouts";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {Typo} from "../../../../../shared/components/Typography";
import {Divider} from "@mui/material";
import {PATH_ACCOUNTS, PATH_ORGANIZATIONS} from "../../../../BarsENV/shell/constants";
import {useHistory} from "react-router-dom";
import {parseDate} from "../../../../../shared/utils/dateTools";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const ExistLicenseTabs = ({tab}: {tab: licenseTabs}) => {
    const history = useHistory();
    const license = useSelector(extendedLicense);
    const wizs = useSelector(wizards);
    const {isLoadingWizards} = useSelector(loading);

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    const handleGoUser = (id: string) => {
        history.push(PATH_ACCOUNTS + `?page=0&count=5&publicId=${id}`);
    }

    const handleGoToOrg = (id: string) => {
        history.push(PATH_ORGANIZATIONS + `?page=0&count=5&id=${id}`);
    }

    if(!license) return  <></>;
    switch(tab){
        case "wizards": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {isLoadingWizards && <LocalSpinner />}
                    {!license.wizardId.length && <Typo color={'red'}>License has no wizards.</Typo>}
                    {!isLoadingWizards && license.wizardId.map(e => {
                        const currWiz = wizs.find(w => w.id === e);
                        return(
                            <>
                                <Typo margin={'0 0 15px 0'} key={e} color={currWiz ? 'black' : 'red'}>{currWiz?.name ?? `${e} (wizards not found)`}</Typo>
                            </>
                        )
                    })}
                </Flex>
            )
        }
        case "workspaces": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {!license.workspaces.length && <Typo color={'red'}>License has no workspaces.</Typo>}
                    {license.workspaces.map((e, id) => {
                        return(
                            <Flex direction={'column'} key={e.name + id}>
                                <Typo margin={'0 0 15px 0'}>Name: {e.name}</Typo>
                                <Flex w={'100%'} jc={'space-between'}>
                                    <Typo>Name</Typo>
                                    <Typo>Email</Typo>
                                    <Typo>PublicId</Typo>
                                </Flex>
                                <Divider flexItem/>
                                {e.collaborators.map((c) => {
                                    return(
                                        <>
                                            <Flex cursor={'pointer'} p={'5px 0'} w={'100%'} jc={'space-between'} key={c.publicId} m={'15px 0 0 0'} hoverStyles={'background-color: #c0c0c0;'} onClick={() => handleGoUser(c.publicId)}>
                                                <Typo>{c.firstName} {c.lastName}</Typo>
                                                <Typo>{c.email}</Typo>
                                                <Typo>{c.publicId}</Typo>
                                            </Flex>
                                            <Divider flexItem/>
                                        </>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
            )
        }
        case "collaboratorsRules": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {!license.collaboratorRules.length && <Typo color={'red'}>License has no collaborator rules.</Typo>}
                    {license.collaboratorRules.length > 0 &&
                        <>
                            <Flex ai={'center'} jc={'space-between'} w={'50%'} m={'0 0 5px 0'}>
                                <Typo>Type</Typo>
                                <Typo>Email</Typo>
                                <Typo>Count</Typo>
                            </Flex>
                            <Divider flexItem style={{width: '50%'}}/>
                        </>

                    }
                    {license.collaboratorRules.map(e => {
                        return(
                            <>
                                <Flex ai={'center'} jc={'space-between'} key={JSON.stringify(e)} w={'50%'} m={'10px 0 0 0'}>
                                    <Typo>{e.type}</Typo>
                                    <Typo>{e.email}</Typo>
                                    <Typo>{e.count}</Typo>
                                </Flex>
                                <Divider flexItem style={{width: '50%'}}/>
                            </>
                        )
                    })}
                </Flex>
            )
        }
        case "issuedDetails": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {(!license.issued || !license.issuedDetails) && <Typo color={'red'}>License is not issued.</Typo>}
                    {license.issuedDetails &&
                        <Flex direction={'column'}>
                            <Typo margin={'0 0 15px 0'}>Release Date: {parseDate(license.issuedDetails.issuedDate, momentLocale)}</Typo>
                            <Typo
                                margin={'0 0 15px 0'}
                                cursor={'pointer'}
                                color={'blue'}
                                textDecoration={'underline'}
                                onClick={() => license && license.issuedDetails && handleGoUser(license.issuedDetails.issuedBy)}
                            >
                                Released By: {`${license.issuedDetails.firstName} ${license.issuedDetails.lastName}`}
                            </Typo>
                            <Typo>Notes: {license.notes}</Typo>
                        </Flex>
                    }
                </Flex>
            )
        }
        case "usedDetails": {
            return(
                <Flex
                    w={'100%'}
                    direction={'column'}
                    p={'18px'}
                    br={'12px'}
                    box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                    background={'#fff'}
                    overflowy={'auto'}
                >
                    {(!license.used || !license.usedDetails) && <Typo color={'red'}>License is not used.</Typo>}
                    {license.usedDetails &&
                    <Flex direction={'column'}>
                        <Typo margin={'0 0 15px 0'}>Used Date: {parseDate(license.usedDetails.usedDate, momentLocale)}</Typo>
                        <Typo
                            margin={'0 0 15px 0'}
                            cursor={'pointer'}
                            onClick={() => license && license.usedDetails && handleGoUser(license.usedDetails.usedBy)}
                        >
                            Used By: {license.usedDetails.firstName} {license.usedDetails.lastName} | {license.usedDetails.email} | {license.usedDetails.usedBy}
                        </Typo>

                        <Typo
                            margin={'0 0 15px 0'}
                            cursor={'pointer'}
                            onClick={() => license && license.usedDetails && handleGoToOrg(license.usedDetails.usedOrganizationId)}
                        >
                            Organization: {`${license.usedDetails.usedOrganizationName}`} | {`${license.usedDetails.usedOrganizationId}`}
                        </Typo>
                    </Flex>
                    }
                </Flex>
            )
        }
        default: return <div>Tab not found!</div>;
    }
}