import React, {FC, useEffect} from 'react';
import {Flex} from '../../../shared/components/Layouts';
import {Routes} from './routes';
import {GetAllEmailTemplatesAdmin} from "./store/actions";
import {cleanUp} from "./store/slice";
import {useDispatch} from "react-redux";


export const EmailTemplates:FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllEmailTemplatesAdmin());
        return () => {
            dispatch(cleanUp());
        }
        // eslint-disable-next-line
    }, []);

    return(
        <Flex w={'100%'} h={'100%'} p={'16px'}>
            <Routes />
        </Flex>
    )
} 