import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideForceMfaDialog, mfaForceResult} from "../../../store/slice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Flex } from "../../../../../../shared/components/Layouts";
import { Typo } from "../../../../../../shared/components/Typography";
import {LABEL} from "../../../../../../shared/constants";

export const ForceMfaResult = () => {
    const dispatch = useDispatch();
    const {forceMfaResultDialog} = useSelector(dialogs);
    const res = useSelector(mfaForceResult);

    const handleClose = () => dispatch(hideForceMfaDialog());

    //
    function download(filename: string, text: string) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const handleDownLoadRecoveryCodes = () => {
        download(`${LABEL} recovery codes.txt`, res.join(`\n`));
    }
    //
    return(
        <Dialog open={forceMfaResultDialog} onClose={handleClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>Recovery codes</DialogTitle>

            <DialogContent>
                <Flex w={'80%'} jc={'space-between'} ai={'center'} m={'16px 0 0 0'}>
                    <Flex w={'100%'} direction={'column'}>
                        {res.slice(0, 8).map(e => {
                            return(
                                <Flex w={'100%'} jc={'space-between'}>
                                    <Typo fontSize={'16px'} fontWeight={300}>{e}</Typo>
                                </Flex>
                            )
                        })}
                    </Flex>

                    <Flex w={'100%'} direction={'column'}>
                        {res.slice(8, 16).map(e => {
                            return(
                                <Flex w={'100%'} jc={'space-between'}>
                                    <Typo fontSize={'16px'} fontWeight={300}>{e}</Typo>
                                </Flex>
                            )
                        })}
                    </Flex>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color={'warning'}>Close</Button>
                <Button onClick={handleDownLoadRecoveryCodes} disabled={!res.length} color={'success'}>Download as TXT</Button>
            </DialogActions>
        </Dialog>
    )
}