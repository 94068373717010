import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteWorkspaceDialog, extendedOrganization, loading} from "../../../store/slice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import {DeleteWorkspace} from "../../../store/actions";

export const DeleteWorkspaceDialog = () => {
    const dispatch = useDispatch();
    const {deleteWorkspaceDialog: {isOpen, workspaceId}} = useSelector(dialogs);
    const org = useSelector(extendedOrganization);
    const {isLoadingDeleteWorkspace: {isLoading}} = useSelector(loading);

    const handleClose = () => {
        dispatch(hideDeleteWorkspaceDialog());
    };

    const handleDeleteWorkspace = () => {
        if(workspaceId){
            dispatch(DeleteWorkspace({id: workspaceId}));
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Delete Workspace</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want delete this workspace and all related data?
                </DialogContentText>
            </DialogContent>
            {isLoading ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleDeleteWorkspace} disabled={!org} color={'success'}>Delete</Button>
                </DialogActions>
            }
        </Dialog>
    )
}