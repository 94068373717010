import React, { useState } from 'react';

export function useForm<T>(initialValues:T){
    const [form, setForm] = useState(initialValues);

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value,
        }));
    };

    return {
        handleChange,
        form,
        setForm
    }
}