import {styled} from "@mui/material";
import {Flex} from "../Layouts";
import colors from "../../theme/colors";


export const SecondMenuRow = styled(Flex)<{active?: boolean, w?: string, m?: string, p?: string, jc?: string, h?: string}>`
  background: white;
  justify-content: ${({jc}) => jc ? jc : "center"};
  
  height: ${props => props.h || '90px'};
  width: ${props => props.w || 'calc(100% - 10px)'};
  padding: ${props => props.p || '18px 23px'};
  
  border-radius: 12px;
  border: 1px solid ${({active}) => active ? colors.yellow : 'rgba(0, 0, 0, 0)'};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  
  &:hover {
    border: 1px solid #14CBFD;
  }

  cursor: pointer !important;
  & * {
    cursor: pointer !important;
  }
`
SecondMenuRow.defaultProps = {
    m: '2px 2px 12px 2px'
}