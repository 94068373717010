import {organizationsInitialState, smallCollab, workspaceConfig} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {
    ActivateOrganization,
    // AddCollabToOrganization,
    BlockOrganization,
    DeleteOrganization,
    DeleteWorkspace,
    GetExtendedOrganization,
    GetQuickStarts,
    GetShortOrganizations,
    TransferOwnership,
    UpdateAvailableCollabsCounter, UpdateAvailableVulTargetsCounter, UpdateAvailableWorkspacesCounter,
    UpdateOrgCollabsRules, UpdateOrgSubscription, UpdateOrgSubscriptionExpiry, UpdateOrgTrialUsed, UpdateOrgWizards,
    UpdateWorkspaceAccountMappingConfig, UpdateWorkspaceProfileMappingConfig
} from "./actions";
import {
    ORGANIZATION_MEMBER,
    ORGANIZATION_OWNER,
    WORKSPACE_OWNER, WORKSPACE_VIEWER
} from "../../../AuthWorkspaceAndCookies/newWorkspaces/constants";

export const initialState: organizationsInitialState = {
    smallOrganizations: [],
    pageInfo: {
        page: 0,
        total: 0,
        count: 10,
        sort: ''
    },
    extendedOrganization: null,
    wizards: [],

    loadings: {
        isLoadingAddOrgCollab: false,
        isLoadingWizards: false,
        isLoadingSmallOrgs: false,
        isLoadingExtendedOrg: false,
        isLoadingChangeSub: false,
        isLoadingChangeSubExpiry: false,
        isLoadingBlockOrg: false,
        isLoadingActivateOrg:false,
        isLoadingDeleteOrg: false,
        isLoadingChangeCollabRules: false,
        isLoadingChangeWizards: false,
        isLoadingChangeWorkspaceCounter: false,
        isLoadingChangeCollabsCounter: false,
        isLoadingChangeTrail: false,
        isLoadingChangeWorkspaceAccountMapping: {
            isLoading: false,
        },
        isLoadingChangeWorkspaceProfileMapping: {
            isLoading: false,
        },
        isLoadingDeleteWorkspace: {
            isLoading: false,
        },
        isLoadingTransferOwnership: {
            isLoading: false,
        },
        isLoadingChangeVulTargetsCount: {
            isLoading: false,
        }
    },

    dialogs: {
        // addOrgCollabDialog: {
        //     isOpen: false,
        // },
        blockOrganizationDialog: {
            isOpen: false,
        },
        activateOrganizationDialog: {
            isOpen: false,
        },
        deleteOrganizationDialog: {
            isOpen: false,
        },
        changeOrganizationSubDialog: {
            isOpen: false,
        },
        changeOrganizationSubExpiryDialog:{
            isOpen: false,
        },
        changeAvailableWorkspaceDialog: {
            isOpen: false,
        },
        changeAvailableCollabsDialog: {
            isOpen: false,
        },
        changeOrgTrialDialog: {
            isOpen: false,
        },
        changeOrgCollabsRulesDialog: {
            isOpen: false,
        },
        changeOrgVulTargetsDialog: {
            isOpen: false,
        },
        changeOrgWizardsDialog: {
            isOpen: false,
        },
        transferOwnershipDialog: {
            isOpen: false,
        },
        //WS
        deleteWorkspaceDialog: {
            isOpen: false,
            workspaceId: null,
        },
        changeWorkspaceAccountMappingDialog: {
            isOpen: false,
            prevConfig: null,
            workspaceId: null,
        },
        changeWorkspaceProfileMappingDialog: {
            isOpen: false,
            prevConfig: null,
            workspaceId: null,
        }
    }
}

export const OrganizationsSlice = createSlice({
    name: 'Organizations',
    initialState,
    reducers: {
        //DIALOGS
        // openAddOrgCollabDialog: (slice) => {slice.dialogs.addOrgCollabDialog.isOpen = true},
        // hideAddOrgCollabDialog: (slice) => {slice.dialogs.addOrgCollabDialog.isOpen = false},

        openBlockOrgDialog: (slice) => {slice.dialogs.blockOrganizationDialog.isOpen = true},
        hideBlockOrgDialog: (slice) => {slice.dialogs.blockOrganizationDialog.isOpen = false},

        openActivateOrgDialog: (slice) => {slice.dialogs.activateOrganizationDialog.isOpen = true},
        hideActivateOrgDialog: (slice) => {slice.dialogs.activateOrganizationDialog.isOpen = false},

        openDeleteOrgDialog: (slice) => {slice.dialogs.deleteOrganizationDialog.isOpen = true},
        hideDeleteOrgDialog: (slice) => {slice.dialogs.deleteOrganizationDialog.isOpen = false},

        openChangeOrgSubDialog: (slice) => {slice.dialogs.changeOrganizationSubDialog.isOpen = true},
        hideChangeOrgSubDialog: (slice) => {slice.dialogs.changeOrganizationSubDialog.isOpen = false},

        openChangeOrgSubExpiryDialog: (slice) => {slice.dialogs.changeOrganizationSubExpiryDialog.isOpen = true},
        hideChangeOrgSubExpiryDialog: (slice) => {slice.dialogs.changeOrganizationSubExpiryDialog.isOpen = false},

        openChangeAvailableWSCounterDialog: (slice) => {slice.dialogs.changeAvailableWorkspaceDialog.isOpen = true},
        hideChangeAvailableWSCounterDialog: (slice) => {slice.dialogs.changeAvailableWorkspaceDialog.isOpen = false},

        openChangeAvailableCollabsCounterDialog: (slice) => {slice.dialogs.changeAvailableCollabsDialog.isOpen = true},
        hideChangeAvailableCollabsCounterDialog: (slice) => {slice.dialogs.changeAvailableCollabsDialog.isOpen = false},

        openChangeOrgTrialDialog: (slice) => {slice.dialogs.changeOrgTrialDialog.isOpen = true},
        hideChangeOrgTrialDialog: (slice) => {slice.dialogs.changeOrgTrialDialog.isOpen = false},

        openChangeOrgCollabsRulesDialog: (slice) => {slice.dialogs.changeOrgCollabsRulesDialog.isOpen = true},
        hideChangeOrgCollabsRulesDialog: (slice) => {slice.dialogs.changeOrgCollabsRulesDialog.isOpen = false},

        openChangeOrgWizardDialog: (slice) => {slice.dialogs.changeOrgWizardsDialog.isOpen = true},
        hideChangeOrgWizardDialog: (slice) => {slice.dialogs.changeOrgWizardsDialog.isOpen = false},

        openTransferOwnershipDialog: (slice) => {slice.dialogs.transferOwnershipDialog.isOpen = true},
        hideTransferOwnershipDialog: (slice) => {slice.dialogs.transferOwnershipDialog.isOpen = false},

        openChangeVulTargetsDialog: (slice) => {slice.dialogs.changeOrgVulTargetsDialog.isOpen = true},
        hideChangeVulTargetsDialog: (slice) => {slice.dialogs.changeOrgVulTargetsDialog.isOpen = false},

        //WS DIALOGS
        openDeleteWorkspaceDialog: (slice, {payload}: {payload: string}) => {slice.dialogs.deleteWorkspaceDialog = {isOpen: true, workspaceId: payload}},
        hideDeleteWorkspaceDialog: (slice) => {slice.dialogs.deleteWorkspaceDialog = {isOpen: false, workspaceId: null}},

        openChangeWSAccountMappingDialog: (slice, {payload}: {payload: {workspaceId: string, prevConfig: workspaceConfig}}) => {slice.dialogs.changeWorkspaceAccountMappingDialog = {isOpen: true, workspaceId: payload.workspaceId, prevConfig: payload.prevConfig}},
        hideChangeWSAccountMappingDialog: (slice) => {slice.dialogs.changeWorkspaceAccountMappingDialog = {isOpen: false, workspaceId: null, prevConfig: null}},

        openChangeWSProfileMappingDialog: (slice, {payload}: {payload: {workspaceId: string, prevConfig: workspaceConfig}}) => {slice.dialogs.changeWorkspaceProfileMappingDialog = {isOpen: true, workspaceId: payload.workspaceId, prevConfig: payload.prevConfig}},
        hideChangeWSProfileMappingDialog: (slice) => {slice.dialogs.changeWorkspaceProfileMappingDialog = {isOpen: false, workspaceId: null, prevConfig: null}},

        cleanUp: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            //GetShortOrganizations
            .addCase(GetShortOrganizations.pending, (slice) => {
                slice.loadings.isLoadingSmallOrgs = true;
            })
            .addCase(GetShortOrganizations.rejected, (slice) => {
                slice.loadings.isLoadingSmallOrgs = false;
            })
            .addCase(GetShortOrganizations.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSmallOrgs = false;
                slice.smallOrganizations = payload.orgs;
                slice.pageInfo = payload.pageInfo;
            })
        //GetExtendedOrganization
            .addCase(GetExtendedOrganization.pending, (slice) => {
                slice.loadings.isLoadingExtendedOrg = true;
            })
            .addCase(GetExtendedOrganization.rejected, (slice) => {
                slice.loadings.isLoadingExtendedOrg = false;
            })
            .addCase(GetExtendedOrganization.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExtendedOrg = false;
                slice.extendedOrganization = payload;
            })
        //GetQuickStarts
            .addCase(GetQuickStarts.pending, (slice) => {
                slice.loadings.isLoadingWizards = true;
            })
            .addCase(GetQuickStarts.rejected, (slice) => {
                slice.loadings.isLoadingWizards = false;
            })
            .addCase(GetQuickStarts.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingWizards = false;
                slice.wizards = payload;
            })
        //ActivateOrganization
            .addCase(ActivateOrganization.pending, (slice) => {
                slice.loadings.isLoadingActivateOrg = true;
            })
            .addCase(ActivateOrganization.rejected, (slice) => {
                slice.loadings.isLoadingActivateOrg = false;
            })
            .addCase(ActivateOrganization.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingActivateOrg = false;
                slice.dialogs.activateOrganizationDialog.isOpen = false;
                if(slice.extendedOrganization && slice.extendedOrganization.id === payload){
                    slice.extendedOrganization.blocked = false;
                    slice.extendedOrganization.blockedReason = null;
                }
                slice.smallOrganizations = slice.smallOrganizations.map(e => e.id === payload ? {...e, blocked: false} : e);
            })
        //AddCollabToOrganization
        //     .addCase(AddCollabToOrganization.pending, (slice) => {
        //         slice.loadings.isLoadingAddOrgCollab = true;
        //     })
        //     .addCase(AddCollabToOrganization.rejected, (slice) => {
        //         slice.loadings.isLoadingAddOrgCollab = false;
        //     })
        //     .addCase(AddCollabToOrganization.fulfilled, (slice, {payload}) => {
        //         slice.loadings.isLoadingAddOrgCollab = false;
        //         slice.dialogs.addOrgCollabDialog.isOpen = false;
        //         if(slice.extendedOrganization && slice.extendedOrganization.id === payload.orgId){
        //             slice.extendedOrganization.collaborators.push(payload.res)
        //         }
        //     })
        //BlockOrganization
            .addCase(BlockOrganization.pending, (slice) => {
                slice.loadings.isLoadingBlockOrg = true;
            })
            .addCase(BlockOrganization.rejected, (slice) => {
                slice.loadings.isLoadingBlockOrg = false;
            })
            .addCase(BlockOrganization.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingBlockOrg = false;
                slice.dialogs.blockOrganizationDialog.isOpen = false;
                if(slice.extendedOrganization && slice.extendedOrganization.id === payload.id){
                    slice.extendedOrganization.blocked = true;
                    slice.extendedOrganization.blockedReason = payload.reason;
                }
                slice.smallOrganizations = slice.smallOrganizations.map(e => e.id === payload.id ? {...e, blocked: true} : e);
            })
        //DeleteOrganization
            .addCase(DeleteOrganization.pending, (slice) => {
                slice.loadings.isLoadingDeleteOrg = true;
            })
            .addCase(DeleteOrganization.rejected, (slice) => {
                slice.loadings.isLoadingDeleteOrg = false;
            })
            .addCase(DeleteOrganization.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDeleteOrg = false;
                slice.dialogs.deleteOrganizationDialog.isOpen = false;
                if(slice.extendedOrganization && slice.extendedOrganization.id === payload){
                    slice.extendedOrganization = null;
                }
                slice.smallOrganizations = slice.smallOrganizations.filter(e => e.id !== payload);
            })
        //DeleteWorkspace
            .addCase(DeleteWorkspace.pending, (slice) => {
                slice.loadings.isLoadingDeleteWorkspace.isLoading = true;
            })
            .addCase(DeleteWorkspace.rejected, (slice) => {
                slice.loadings.isLoadingDeleteWorkspace.isLoading = false;
            })
            .addCase(DeleteWorkspace.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDeleteWorkspace.isLoading = false;
                slice.dialogs.deleteWorkspaceDialog = {isOpen: false, workspaceId: null};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.workspaces = slice.extendedOrganization.workspaces.filter(e => e.id !== payload);
                }
            })
        //TransferOwnership
            .addCase(TransferOwnership.pending, (slice) => {
                slice.loadings.isLoadingTransferOwnership.isLoading = true;
            })
            .addCase(TransferOwnership.rejected, (slice) => {
                slice.loadings.isLoadingTransferOwnership.isLoading = false;
            })
            .addCase(TransferOwnership.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTransferOwnership.isLoading = false;
                slice.dialogs.transferOwnershipDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.collaborators = slice.extendedOrganization.collaborators.map(e => e.publicId === payload.from ? {...e, role: ORGANIZATION_MEMBER} : (e.publicId === payload.to ? {...e, role: ORGANIZATION_OWNER} : e));
                    slice.extendedOrganization.workspaces = slice.extendedOrganization.workspaces.map(e => {
                        const transferToCollab: smallCollab | undefined = slice.extendedOrganization!.collaborators.find(e => e.publicId === payload.to);
                        const isTransferToIncluded = e.collaborators.some(e => e.publicId === payload.to); // is user we transfer to is part of workspace
                        if(transferToCollab){
                            return{
                                ...e,
                                collaborators: isTransferToIncluded
                                    ?
                                    e.collaborators.map(e => e.publicId === payload.from ? {...e, role: WORKSPACE_VIEWER} : (e.publicId === payload.to ? {...e, role: WORKSPACE_OWNER} : e))
                                    :
                                    [...e.collaborators.map(e => e.publicId === payload.from ? {...e, role: WORKSPACE_VIEWER} : e), {...(transferToCollab as smallCollab), role: WORKSPACE_OWNER}]
                            }
                        }
                        return e;
                    })
                }
            })
        //UpdateWorkspaceAccountMappingConfig
            .addCase(UpdateWorkspaceAccountMappingConfig.pending, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceAccountMapping.isLoading = true;
            })
            .addCase(UpdateWorkspaceAccountMappingConfig.rejected, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceAccountMapping.isLoading = false;
            })
            .addCase(UpdateWorkspaceAccountMappingConfig.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeWorkspaceAccountMapping.isLoading = false;
                slice.dialogs.changeWorkspaceAccountMappingDialog = {isOpen: false, workspaceId: null, prevConfig: null};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.workspaces = slice.extendedOrganization.workspaces.map(e => e.id === payload.id ? {...e, config: {...e.config, accountsMappingSchema: payload.newValue}} : e);
                }
            })
        // UpdateWorkspaceProfileMappingConfig
            .addCase(UpdateWorkspaceProfileMappingConfig.pending, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceProfileMapping.isLoading = true;
            })
            .addCase(UpdateWorkspaceProfileMappingConfig.rejected, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceProfileMapping.isLoading = false;
            })
            .addCase(UpdateWorkspaceProfileMappingConfig.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeWorkspaceProfileMapping.isLoading = false;
                slice.dialogs.changeWorkspaceProfileMappingDialog = {isOpen: false, workspaceId: null, prevConfig: null};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.workspaces = slice.extendedOrganization.workspaces.map(e => e.id === payload.id ? {...e, config: {...e.config, profilesMappingSchema: payload.newValue}} : e);
                }
            })
        ///UpdateAvailableCollabsCounter
            .addCase(UpdateAvailableCollabsCounter.pending, (slice) => {
                slice.loadings.isLoadingChangeCollabsCounter = true;
            })
            .addCase(UpdateAvailableCollabsCounter.rejected, (slice) => {
                slice.loadings.isLoadingChangeCollabsCounter = false;
            })
            .addCase(UpdateAvailableCollabsCounter.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeCollabsCounter = false;
                slice.dialogs.changeAvailableCollabsDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.configuration.availableCollaboratorsCount = payload.newValue;
                }
            })
        //UpdateOrgCollabsRules
            .addCase(UpdateOrgCollabsRules.pending, (slice) => {
                slice.loadings.isLoadingChangeCollabRules = true;
            })
            .addCase(UpdateOrgCollabsRules.rejected, (slice) => {
                slice.loadings.isLoadingChangeCollabRules = false;
            })
            .addCase(UpdateOrgCollabsRules.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeCollabRules = false;
                slice.dialogs.changeOrgCollabsRulesDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.configuration.availableCollaboratorsRules = payload.newValue;
                }
            })
        //UpdateOrgSubscription
            .addCase(UpdateOrgSubscription.pending, (slice) => {
                slice.loadings.isLoadingChangeSub = true;
            })
            .addCase(UpdateOrgSubscription.rejected, (slice) => {
                slice.loadings.isLoadingChangeSub = false;
            })
            .addCase(UpdateOrgSubscription.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeSub = false;
                slice.dialogs.changeOrganizationSubDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.subscription = payload.subscription;
                }
                slice.smallOrganizations = slice.smallOrganizations.map(e => e.id === payload.id ? {...e, subscription: payload.subscription} : e);
            })
        //UpdateOrgSubscriptionExpiry
            .addCase(UpdateOrgSubscriptionExpiry.pending, (slice) => {
                slice.loadings.isLoadingChangeSubExpiry = true;
            })
            .addCase(UpdateOrgSubscriptionExpiry.rejected, (slice) => {
                slice.loadings.isLoadingChangeSubExpiry = false;
            })
            .addCase(UpdateOrgSubscriptionExpiry.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeSubExpiry = false;
                slice.dialogs.changeOrganizationSubExpiryDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.subscriptionExpiry = payload.expiry;
                }
                slice.smallOrganizations = slice.smallOrganizations.map(e => e.id === payload.id ? {...e, subscriptionExpiry: payload.expiry} : e);
            })
        //UpdateOrgTrialUsed
            .addCase(UpdateOrgTrialUsed.pending, (slice) => {
                slice.loadings.isLoadingChangeTrail = true;
            })
            .addCase(UpdateOrgTrialUsed.rejected, (slice) => {
                slice.loadings.isLoadingChangeTrail = false;
            })
            .addCase(UpdateOrgTrialUsed.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeTrail = false;
                slice.dialogs.changeOrgTrialDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.trialUsed = payload.isUsed === 'Used';
                }
            })
        //UpdateOrgWizards
            .addCase(UpdateOrgWizards.pending, (slice) => {
                slice.loadings.isLoadingChangeWizards = true;
            })
            .addCase(UpdateOrgWizards.rejected, (slice) => {
                slice.loadings.isLoadingChangeWizards = false;
            })
            .addCase(UpdateOrgWizards.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeWizards = false;
                slice.dialogs.changeOrgWizardsDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.configuration.availableWizards = payload.wizardIds;
                }
            })
        //UpdateAvailableWorkspacesCounter
            .addCase(UpdateAvailableWorkspacesCounter.pending, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceCounter = true;
            })
            .addCase(UpdateAvailableWorkspacesCounter.rejected, (slice) => {
                slice.loadings.isLoadingChangeWorkspaceCounter = false;
            })
            .addCase(UpdateAvailableWorkspacesCounter.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeWorkspaceCounter = false;
                slice.dialogs.changeAvailableWorkspaceDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.configuration.availableWorkspaceCount = payload.newValue;
                }
            })
        //UpdateAvailableVulTargetsCounter
            .addCase(UpdateAvailableVulTargetsCounter.pending, (slice) => {
                slice.loadings.isLoadingChangeVulTargetsCount.isLoading = true;
            })
            .addCase(UpdateAvailableVulTargetsCounter.rejected, (slice) => {
                slice.loadings.isLoadingChangeVulTargetsCount.isLoading = false;
            })
            .addCase(UpdateAvailableVulTargetsCounter.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingChangeVulTargetsCount.isLoading = false;
                slice.dialogs.changeOrgVulTargetsDialog = {isOpen: false};
                if(slice.extendedOrganization){
                    slice.extendedOrganization.configuration.availableTargetsVulnerabilityScan = payload.newValue;
                }
            })
    }
});

export const OrganizationsReducer = OrganizationsSlice.reducer;

export const {
    //ORG DIALOGS
    // openAddOrgCollabDialog,
    // hideAddOrgCollabDialog,

    openBlockOrgDialog,
    hideBlockOrgDialog,

    openActivateOrgDialog,
    hideActivateOrgDialog,

    openDeleteOrgDialog,
    hideDeleteOrgDialog,

    openChangeOrgSubDialog,
    hideChangeOrgSubDialog,

    openChangeOrgSubExpiryDialog,
    hideChangeOrgSubExpiryDialog,

    openChangeAvailableWSCounterDialog,
    hideChangeAvailableWSCounterDialog,

    openChangeAvailableCollabsCounterDialog,
    hideChangeAvailableCollabsCounterDialog,

    openChangeOrgTrialDialog,
    hideChangeOrgTrialDialog,

    openChangeOrgCollabsRulesDialog,
    hideChangeOrgCollabsRulesDialog,

    openChangeOrgWizardDialog,
    hideChangeOrgWizardDialog,

    openTransferOwnershipDialog,
    hideTransferOwnershipDialog,

    openChangeVulTargetsDialog,
    hideChangeVulTargetsDialog,

    //WS DIALOGS
    openDeleteWorkspaceDialog,
    hideDeleteWorkspaceDialog,

    openChangeWSAccountMappingDialog,
    hideChangeWSAccountMappingDialog,

    openChangeWSProfileMappingDialog,
    hideChangeWSProfileMappingDialog,

    cleanUp
} = OrganizationsSlice.actions;

const selectSelf = (state: AppState):organizationsInitialState => state.Organizations as organizationsInitialState;

export const loading = createSelector(selectSelf, state => state.loadings);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const extendedOrganization = createSelector(selectSelf, state => state.extendedOrganization);
export const smallOrganizations = createSelector(selectSelf, state => state.smallOrganizations);
export const wizards = createSelector(selectSelf, state => state.wizards);
