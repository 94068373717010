import {Flex} from "../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../shared/components/Typography";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useDispatch} from "react-redux";
import {openAddFramework} from '../../../store/slice'
import {useMainTranslation} from "../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";


export const AddFrameworkCard = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.leftSideBar'});

    const dispatch = useDispatch();
    const openAddDialog = () => dispatch(openAddFramework());

    return(
        <Flex
            w={'100%'}
            br={'12px'}
            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
            ai={'center'}
            p={'18px 25px'}
            // m={'0 0 12px 0'}
            background={'white'}
            onClick={openAddDialog}
            style={{gap: '16px'}}
        >
            <Flex ai={'center'} jc={'center'}>
                <Typo fontWeight={500} fontSize={'14px'} >{t('Create New Framework')}</Typo>
            </Flex>
            <AddCircleIcon style={{color: '#FAC000'}}/>
        </Flex>
    )
}