import {PaginationInput, TPageInfo} from "../../../../shared/types";
import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {licenseType, smallLicenseType, workspaceCollabType} from "../types";


export const getShortLicenses = async (pageInfo: PaginationInput): Promise<{licenses: smallLicenseType[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getLicensesByAdmin($pageInfo:PaginationInput!){
                      getLicensesByAdmin(pageInfo:$pageInfo){
                        licenses{
                          id
                          subscription
                          issued
                          used
                          createdDate
                        }
                        pageInfo{
                            count
                            total
                            sort
                            page
                        }
                      }
                    }
                `,
                variables: {
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getLicensesByAdmin;
    })
)

export const getExtendedLicense = async (id: string): Promise<licenseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getExtendedLicenseByAdmin($id:String!){
                      getExtendedLicenseByAdmin(id:$id){
                        id
                        subscription
                        issued
                        used
                        createdDate
                        availableCollaborators
                        availableWorkspaces
                        availableTargetsVulnerabilityScan
                        collaboratorRules{
                          count
                          type
                          email
                        }
                        creatorId
                        creatorDetails{
                          firstName
                          lastName
                        }
                        expiry
                        expiryUnit
                        notes
                        usedDetails{
                          usedBy
                          usedDate
                          usedOrganizationId
                          usedOrganizationName
                          firstName
                          lastName
                          email
                          publicId
                        }
                        wizardId
                        workspaces{
                          collaborators{
                            firstName
                            lastName
                            email
                            publicId
                          }
                          name
                        }
                        issuedDetails{
                          issuedBy
                          issuedDate
                          firstName
                          lastName
                        }
                      }
                    }
                `,
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getExtendedLicenseByAdmin;
    })
)

export const releaseLicense = async (id: string, note: string, email: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation releaseLicense($id:String!, $note:String!, $email:String!){
                      releaseLicense(id:$id, note:$note, email:$email){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    note,
                    email
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.releaseLicense.message;
    })
)

export const deleteLicense = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation deleteLicense($id:String!){
                      deleteLicense(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteLicense.message;
    })
)

export const createOneLicense = async (license: licenseType, email?:string): Promise<{id: string, collabs: workspaceCollabType[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation licenseCreateOne($license:ExtendedLicenseInput!, $email: String!){
                      licenseCreateOne(license:$license, email: $email){
                        id
                        collabs{
                            firstName
                            lastName
                            email
                            publicId
                        }
                      }
                    }
                `,
                variables: {
                    license,
                    email: email ?? ''
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.licenseCreateOne;
    })
)

export const createMultiLicense = async (license: licenseType, count: string): Promise<string[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation licenseCreateMulti($license:ExtendedLicenseInput!, $count: String!){
                      licenseCreateMulti(license:$license, count: $count){
                        ids
                      }
                    }
                `,
                variables: {
                    license,
                    count
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.licenseCreateMulti.ids;
    })
)