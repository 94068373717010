import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    createAdminRole,
    createPermissionByAdmin, deletePermissionByAdmin,
    deleteRoleByAdmin,
    editAdminRole,
    getAdminRoles,
    updatePermissionByAdmin
} from "../api";
import {TPermission, TRole, TRoleCreateModel, TRoleEditModel} from "../types";

//getAdminRoles
export const GetAdminRoles = createAsyncThunk(
    'accessManagement/getAdminRoles',
    async () => {
        return await getAdminRoles();
    }
);

//createAdminRole
export const CreateAdminRole = createAsyncThunk(
    'accessManagement/createAdminRole',
    async ({role}: {role: TRoleCreateModel}): Promise<TRole> => {
        const res = await createAdminRole(role);
        const currDate = new Date(Date.now()).toISOString();
        return {
            ...role,
            workspaceId: null,
            createDate: currDate,
            lastUpdateDate: currDate,
            id: res,
        }
    }
);

//deleteRoleByAdmin
export const DeleteRoleByAdmin = createAsyncThunk(
    'accessManagement/deleteRoleByAdmin',
    async ({id}: {id: string}): Promise<string> => {
        await deleteRoleByAdmin(id);
        return id;
    }
);

//editAdminRole
export const EditAdminRole = createAsyncThunk(
    'accessManagement/editAdminRole',
    async ({role}: {role: TRoleEditModel}): Promise<TRole> => {
        await editAdminRole({
            name: role.name,
            id: role.id,
            permissions: role.permissions,
            system: role.system,
            origin: role.origin
        });
        const currDate = new Date(Date.now()).toISOString();
        return {
            ...role,
            workspaceId: null,
            createDate: currDate,
            lastUpdateDate: currDate,
        }
    }
);

//createPermissionByAdmin
export const CreatePermissionByAdmin = createAsyncThunk(
    'accessManagement/createPermissionByAdmin',
    async ({permission}: {permission: TPermission}): Promise<TPermission> => {
        await createPermissionByAdmin(permission);
        return permission;
    }
);

//createPermissionByAdmin
export const UpdatePermissionByAdmin = createAsyncThunk(
    'accessManagement/updatePermissionByAdmin',
    async ({permission}: {permission: TPermission}): Promise<TPermission> => {
        await updatePermissionByAdmin(permission);
        return permission;
    }
);


export const DeletePermissionByAdmin = createAsyncThunk(
    'accessManagement/deletePermissionByAdmin',
    async ({permissionName}: {permissionName: string}): Promise<string> => {
        await deletePermissionByAdmin(permissionName);
        return permissionName;
    }
);