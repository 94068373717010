import {IconSvg} from "../../../shared/types";
import {exception, pathNameExceptionsMap} from "./constants";

export class LeftSideBarItem {
    icon?: { active?: IconSvg, inactive: IconSvg };
    title: string;
    path?: string;
    root: LeftSideBarItem | null;
    items?: LeftSideBarItem[];
    private readonly self: LeftSideBarItem;
    constructor(title: string, path?: string, items: LeftSideBarItem[] = [] as LeftSideBarItem[], icon?: { active?: IconSvg, inactive: IconSvg }) {
        this.title = title;
        this.root = null;
        this.icon = icon;
        this.path = path;
        this.items = items;
        this.self = this;
    };

    addItem(item: LeftSideBarItem) {
        if (!this.items) this.items = [];
        item.root = this;
        this.items.push(item)
        return this;
    }

    getItems(): LeftSideBarItem[] | undefined {
        return this.items
    }

    addItems(items: LeftSideBarItem[]) {
        items.forEach(item => {
            item.root = this;
        })
        this.items = items;
    }

    isItComplex() {
        //eslint-disable-next-line no-self-compare
        return this.getItems()?.length ?? 0 > 0;
    }

    route() {
        let item = this.self as LeftSideBarItem;
        const tmp = [];
        do {
            tmp.unshift(item);
            item = item.root as LeftSideBarItem;
        } while(item?.root);
        return tmp;
    }
}

export type TAnchorItem = {
    anchor: HTMLDivElement | null;
    item: LeftSideBarItem | null;
}

export type TAccordion = { accordion: string | null; expanded: boolean };

export type TTitlePath = {
    title: string;
    path?: string;
}

export class MenuRoute {
    path: string;
    items: LeftSideBarItem[];
    constructor(path: string = '', items: LeftSideBarItem[] = []) {
        this.path = path;
        this.items = items;
    }
    titlesAndPaths(): TTitlePath[] {
        return this.items.map(({title, path}) => ({title, path}))
    }
    put(item: LeftSideBarItem) {
        if (!item.isItComplex() && item.path) {
            this.path = item.path;
        }
        this.items.unshift(item);
    }
    default(exception_path?: string) {
        const menu = pathNameExceptionsMap.get(exception_path ?? '');
        if (menu) {
            return new MenuRoute(exception_path, [menu]);
        }
        return new MenuRoute('', [exception]);
    }
}
