import { Flex } from "../../../../../shared/components/Layouts";
import {Typo} from "../../../../../shared/components/Typography";

export const PermissionsTableHeader = () => {
    return(
        <Flex w={'100%'} p={'16px 8px'} background={'#fff'} ai={'center'} br={'5px 5px 0 0 '}>
            <Flex w={'10%'}>
                <Typo fontWeight={400}>Index</Typo>
            </Flex>

            <Flex w={'25%'}>
                <Typo>Name</Typo>
            </Flex>

            <Flex w={'55%'}>
                <Typo>Tags</Typo>
            </Flex>

            <Flex w={'10%'}>
                <Typo>Actions</Typo>
            </Flex>
        </Flex>
    )
}