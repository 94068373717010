import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteCollabDialog, extendedCollab, loading} from "../../../store/slice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import {DeleteUser} from "../../../store/actions";

export const DeleteUserDialog = () => {
    const dispatch = useDispatch();
    const {deleteCollabDialog} = useSelector(dialogs);
    const collab = useSelector(extendedCollab);
    const {isLoadingDeleteCollab} = useSelector(loading);

    const handleClose = () => {
        dispatch(hideDeleteCollabDialog());
    };

    const handleDeleteUser = () => {
        if(collab){
            dispatch(DeleteUser({id: collab.publicId}));
        }
    }

    return(
        <Dialog open={deleteCollabDialog.isOpen} onClose={handleClose}>
            <DialogTitle>Block user</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want delete this user and all related data?
                </DialogContentText>
            </DialogContent>
            {isLoadingDeleteCollab ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleDeleteUser} disabled={!collab} color={'success'}>Delete</Button>
                </DialogActions>
            }
        </Dialog>
    )
}