import {useDispatch, useSelector} from "react-redux";
import {apps, loadings, selectApp} from "../../store/slice";
import {useEffect} from "react";
import {GetAppsInfos} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_APPS_CREATE, PATH_LOCAL_APPS_EXACT} from "../../constants";
import {AppInfo} from "../../types";

export const useAppsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _apps = useSelector(apps);
    const {list} = useSelector(loadings);

    useEffect(() => {
        dispatch(GetAppsInfos());
        //eslint-disable-next-line
    }, []);

    return{
        isLoading: list,
        apps: _apps,
        handleGoToCreateApp: () => history.push(PATH_LOCAL_APPS_CREATE),
        handleGoToApp: (app: AppInfo) => {
            dispatch(selectApp(app));
            history.push(PATH_LOCAL_APPS_EXACT.replace(':id', app.id));
        }
    }
}