import {Flex} from "../../../../../shared/components/Layouts"
import {AddFrameworkCard} from "./addFrameworkCard";
import {Typo} from "../../../../../shared/components/Typography";
import {useDispatch, useSelector} from "react-redux";
import {
    allControlsCounter,
    allEvidencesCounter,
    frameworks,
    isLoading, openAddControlsAndEvidencesFromFile, openAddGapFromFile,
    selectFramework
} from "../../store/slice";
import {Box, Divider} from "@mui/material";
import React, {useEffect} from "react";
import {frameworkType} from "../../types";
import {FrameworkCard} from "./frameworkCard";
import {
    CONTROLS,
    EVIDENCES,
    // POLICIES,
    ROOT_CONTROLS,
    ROOT_EVIDENCES,
    ROOT_GALLERY,
    // ROOT_POLICIES
} from "../../constants";
import {useRouteMatch} from "react-router";
import {useHistory} from "react-router-dom";
import {Spinner} from "../../../../../shared/components/Spinner";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const LeftSideBar = () => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'builderPage.leftSideBar'});
    const history = useHistory();
    const dispatch = useDispatch();

    const _frameworks = useSelector(frameworks);
    const _allControlsCounter = useSelector(allControlsCounter);
    const _allEvidencesCounter = useSelector(allEvidencesCounter);
    // const _allPoliciesCounter = useSelector(allPoliciesCounter);
    const _isLoading = useSelector(isLoading);


    //PATHS
    let rootControlsPath = useRouteMatch(ROOT_CONTROLS);
    let rootGalleryPath = useRouteMatch(ROOT_GALLERY);
    let controlsPath = useRouteMatch(CONTROLS);

    const matchesControls = rootControlsPath?.isExact || controlsPath?.isExact;
    const matchesGallery = rootGalleryPath?.isExact;

    let rootEvidencesPath = useRouteMatch(ROOT_EVIDENCES);
    let evidencesPath = useRouteMatch(EVIDENCES);

    const matchesEvidences = rootEvidencesPath?.isExact || evidencesPath?.isExact;

    // let rootPoliciesPath = useRouteMatch(ROOT_POLICIES);
    // let policiesPath = useRouteMatch(POLICIES);

    // const matchesPolicies = rootPoliciesPath?.isExact || policiesPath?.isExact;

    useEffect(() => {}, [_frameworks]);

    const goToControls = () => {
        history.push(ROOT_CONTROLS);
        dispatch(selectFramework(null));
    };
    const goToEvidences = () => {
        history.push(ROOT_EVIDENCES);
        dispatch(selectFramework(null));
    };
    // const goToPolicies = () => {
    //     history.push(ROOT_POLICIES);
    //     dispatch(selectFramework(null));
    // };

    const goToGallery = () => {
        history.push(ROOT_GALLERY);
        dispatch(selectFramework(null));
    };

    const handleOpenAddControlsAndEvidencesFromFile = () => {
        dispatch(openAddControlsAndEvidencesFromFile());
    }

    const handleOpenAddGapFromFile = () => {
        dispatch(openAddGapFromFile());
    }

    return(
        <Flex minw={'380px'} h={'100%'} direction={'column'} m={!revDir ? '0 10px 0 0' : '0 0 0 10px'} p={'5px'}>
            <AddFrameworkCard />

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex
                    w={'49%'}
                    h={'50px'}
                    box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
                    m={'10px 0 0 0'}
                    ai={'center'}
                    jc={'center'}
                    style={{textAlign: 'center'}}
                    p={'15px'}
                    background={'white'}
                    br={'12px'}
                    onClick={handleOpenAddControlsAndEvidencesFromFile}
                >
                    <Typo fontSize={'14px'} fontWeight={500}>Add controls and evidences from file</Typo>
                </Flex>

                <Flex
                    w={'49%'}
                    h={'50px'}
                    box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
                    m={'10px 0 0 0'}
                    ai={'center'}
                    jc={'center'}
                    p={'15px'}
                    background={'white'}
                    br={'12px'}
                    onClick={handleOpenAddGapFromFile}
                >
                    <Typo fontSize={'14px'} fontWeight={500}>Add GAP from file</Typo>
                </Flex>
            </Box>

            <Flex
                w={'100%'}
                h={'50px'}
                box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
                border={matchesControls ? '1px solid #14CBFD' : ''}
                m={'10px 0 0 0'}
                ai={'center'}
                p={'15px'}
                background={'white'}
                br={'12px'}
                onClick={goToControls}
            >
                <Typo fontSize={'14px'} fontWeight={300}>Controls {_allControlsCounter}</Typo>
            </Flex>

            <Flex
                w={'100%'}
                h={'50px'}
                box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
                border={matchesEvidences ? '1px solid #14CBFD' : ''}
                ai={'center'}
                m={'10px 0 0 0'}
                p={'15px'}
                background={'white'}
                br={'12px'}
                onClick={goToEvidences}
            >
                <Typo fontSize={'14px'} fontWeight={300}>Evidences {_allEvidencesCounter}</Typo>
            </Flex>

            <Flex
                w={'100%'}
                h={'50px'}
                box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
                border={matchesGallery ? '1px solid #14CBFD' : ''}
                ai={'center'}
                m={'10px 0 0 0'}
                p={'15px'}
                background={'white'}
                br={'12px'}
                onClick={goToGallery}
            >
                <Typo fontSize={'14px'} fontWeight={300}>Gallery </Typo>
            </Flex>

            {/*<Flex*/}
            {/*    w={'100%'}*/}
            {/*    h={'50px'}*/}
            {/*    box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}*/}
            {/*    border={matchesPolicies ? '1px solid #14CBFD' : ''}*/}
            {/*    ai={'center'}*/}
            {/*    p={'15px'}*/}
            {/*    background={'white'}*/}
            {/*    m={'10px 0 0 0'}*/}
            {/*    br={'12px'}*/}
            {/*    onClick={goToPolicies}*/}
            {/*>*/}
            {/*    <Typo fontSize={'14px'} fontWeight={300}>Documents {_allPoliciesCounter}</Typo>*/}
            {/*</Flex>*/}

            <Divider flexItem style={{borderColor: '#FAC000', margin: '16px 0 0 0'}} />

            <Flex p={'5px'} m={'8px 0 0 0'} w={'100%'} direction={'column'} disableScrollBar overflow={'auto'}>
                {_frameworks.map((e: frameworkType) => <FrameworkCard framework={e} key={e.id}/>)}
            </Flex>

            {_isLoading && <Spinner />}
        </Flex>
    )
}