import {styled} from "@mui/material";
import {FlexColumn, FlexRow} from "../../editorTitleWithActionsRow/styled";
import colors from "../../../../../theme/colors";

export const EditorWorkzoneStyled = styled(FlexColumn)`
  flex-grow: 1;
  overflow: hidden;
  
  width: 100%;
  border-top: 1px solid #ccc;
`;

export const EditorPagesWithSideMenuStyled = styled(FlexRow)`
    flex-grow: 1;
  overflow: hidden;
  
    background-color: ${colors.backgrounds.grey_dark};
`;
EditorPagesWithSideMenuStyled.defaultProps = {
    id: 'workzoneIDDDD',
}

export const EditorPagesAreaStyled = styled(FlexColumn)`
  flex-grow: 1;
  line-height: 1 !important;
  align-items: center;
  overflow: auto;
  gap: 10px;
  padding-block: 10px;
  font-size: 10px;
  //font-style: normal;
  //font-variant-caps: normal;
  //font-variant-ligatures: normal;
  //font-variant-numeric: normal;
  //font-variant-east-asian: normal;
  //font-variant-alternates: normal;
  //font-kerning: auto;
  //font-optical-sizing: none;
  //font-feature-settings: normal;
  //font-variation-settings: normal;
  //font-weight: 400;
  //font-stretch: normal;
  //-webkit-font-smoothing: antialiased;
  //will-change: transform;
  //-webkit-text-size-adjust: 100%;
  //font: 400 14px / 1.4 Graphik, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
`;