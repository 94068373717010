import {Flex} from "../../../../../shared/components/Layouts";
import {Typo} from "../../../../../shared/components/Typography";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useDispatch} from "react-redux";
import {SecondMenuRow} from "../../../../../shared/components/SecondMenuRow";
import colors from "../../../../../shared/theme/colors";
import React from "react";
import {getInitialLicense} from "../../helpers";
import {UseManageWorkspacesAndOrganizations} from "../../../../AuthWorkspaceAndCookies/newWorkspaces/hooks/useManageWorkspacesAndOrganizations";
import { createLicense } from "../../store/slice";


export const AddLicenseCard = ({setDefaultTab}: {setDefaultTab: () => void}) => {
    const dispatch = useDispatch();
    const {user} = UseManageWorkspacesAndOrganizations();

    const handleAddLicense = () => {
        if(user){
            const adableLicense = getInitialLicense(user.publicId, user.firstName, user.lastName);
            dispatch(createLicense(adableLicense));
            setDefaultTab();
        }
    }

    return(
        <SecondMenuRow onClick={handleAddLicense}>
            <Flex direction={"column"} ai={"center"}>
                <Typo fontSize={'14px'} fontWeight={300} letterSpacing={'1.53px'}>Add License</Typo>
                <AddCircleIcon fontSize={"large"} style={{fill: colors.yellow}}/>
            </Flex>
        </SecondMenuRow>
    )
}