import {useDispatch, useSelector} from "react-redux";
import {Flex} from "../../../../../shared/components/Layouts";
import {createLicense, extendedLicense, openDeleteLicenseDialog, openReleaseLicenseDialog} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {handleGetSubNormalName} from "../../../accounts/helpers";
import {useHistory} from "react-router-dom";
import {PATH_ACCOUNTS} from "../../../../BarsENV/shell/constants";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../AuthWorkspaceAndCookies/newWorkspaces/hooks/useManageWorkspacesAndOrganizations";
import {transformLicenseToAddable} from "../../helpers";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";

export const LicenseInfoWithActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const license = useSelector(extendedLicense);
    const {user} = UseManageWorkspacesAndOrganizations();

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    const releaseLicense = () => {
        dispatch(openReleaseLicenseDialog());
    }

    const deleteLicense = () => {
        dispatch(openDeleteLicenseDialog());
    }

    const useAsTemplate = () => {
        if(license && user){
            const creatable = transformLicenseToAddable(license, user.publicId, user.firstName, user.lastName);
            dispatch(createLicense(creatable));
        }
    }

    const handleGoUser = (id: string) => {
        history.push(PATH_ACCOUNTS + `?page=0&count=5&publicId=${id}`);
    }

    return(
        license ?
            <Flex
                w={'100%'}
                jc={'space-between'}
                p={'18px'}
                br={'12px'}
                box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                background={'#fff'}
            >
                <Flex w={'70%'} direction={'column'}>
                    <Typo margin={'0 0 10px 0'}>License ID: {license.id}</Typo>
                    <Typo margin={'0 0 10px 0'}>Subscription: {handleGetSubNormalName(license.subscription)}</Typo>
                    <Typo margin={'0 0 10px 0'}>Created At: {parseDate(license.createdDate, momentLocale)}</Typo>
                    <Typo margin={'0 0 10px 0'} cursor={'pointer'} textDecoration={'underline'} color={'blue'} onClick={() => handleGoUser(license.creatorId)}>Created By: {license.creatorDetails.firstName} {license.creatorDetails.lastName}</Typo>
                    <Typo margin={'0 0 10px 0'}>Available Collabs: {license.availableCollaborators}</Typo>
                    <Typo margin={'0 0 10px 0'}>Available Workspaces: {license.availableWorkspaces}</Typo>
                    <Typo margin={'0 0 10px 0'}>Available Targets: {license.availableTargetsVulnerabilityScan ?? 'No value (maybe old one)'}</Typo>
                    <Typo margin={'0 0 10px 0'}>Period: {license.expiry} {license.expiryUnit}</Typo>
                </Flex>

                <Flex w={'15%'} direction={'column'}>
                    {!license.issued && <CustomButton variant={'contained'} color={'success'} size={'small'} onClick={releaseLicense}>Release</CustomButton>}
                    <CustomButton sx={{marginTop: !license.issued ? '15px' : ''}} variant={'contained'} color={'error'} size={'small'} onClick={deleteLicense}>Delete</CustomButton>
                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={useAsTemplate}>Use as Template</CustomButton>
                    <Typo margin={'15px 0 0 0'} color={license.issued ? 'green' : 'red'}>{license.issued ? 'ISSUED' : 'NOT ISSUED'}</Typo>
                    <Typo margin={'15px 0 0 0'} color={license.used ? 'RED' : 'GREEN'}>{license.used ? 'USED' : 'NOT USED'}</Typo>
                </Flex>
            </Flex>
        :
            <></>
    )
}