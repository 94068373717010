import moment from "moment";


export const calculateColorByDate = (date: string): string => {
    if(!date || !date.length) return 'black';
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    if(moment(parsedDate).isAfter(Date.now())){
        //if not expired
        if(moment(parsedDate).isBefore(Date.now() + 604800*1000)){
            // if expire less then in a 1 week
            return '#FAC000';
        }else{
            //expires in more then a week
            return 'green';
        }
    }else {
        //alredy expired
        return 'red';
    }
}