import React, {FC} from "react";
import {FlexStart} from "../../../../shared/components/Layouts";
import {useLocation} from "react-router-dom";
import {TOP_BAR_PERCENT} from "../constants";
import {hiddenLeftBarMenu} from "../../leftSideBar/helpers";
import {IconButton} from "@mui/material";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
import {WHITE_TEXT} from "../../leftSideBar/constants";
import {useMainTranslation} from "../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {TobBarLogo} from "../../../../shared/components/TobBarLogo";

type PropTypes = {
    expanded: boolean;
    expand: () => void;
};
const burgerStyle = {color: WHITE_TEXT, margin: '0 3px'};
const burgerHideStyle = {color: '#0C1629', margin: '0 3px'};

export const TopBarProtectedLeftSide: FC<PropTypes> = (props: PropTypes) => {
    const {revDir} = useMainTranslation();

    const {expand, expanded} = props;
    const { pathname } = useLocation();
    const disable = hiddenLeftBarMenu(pathname);
    return (
        <FlexStart w={`${TOP_BAR_PERCENT}%`} shrink={'0'} minw={'190px'}  m={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>
            <IconButton
                onClick={expand}
                aria-label="Menu header"
                style={disable ? burgerHideStyle : burgerStyle}
                disabled={disable}
            >
                {expanded && !disable ? <MenuOpenIcon/> : <MenuIcon/>}
            </IconButton>
            <TobBarLogo />
            {/*<LogoText goToOverview={goToOverview} revDir={revDir}/>*/}
        </FlexStart>
    )
};


export const TopBarUnProtectedLeftSide: FC<PropTypes> = () => {

    return (
        <FlexStart w={`${TOP_BAR_PERCENT}%`} shrink={'0'} minw={'190px'}  m={'0 5px 0 0'}>
            <IconButton
                onClick={() => {}}
                aria-label="Menu header"
                style={burgerHideStyle}
                disabled={true}
            >
                <MenuIcon/>
            </IconButton>
            <TobBarLogo />
            {/*<LogoText goToOverview={() => {}} revDir={revDir}/>*/}
        </FlexStart>
    )
};