import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useCreateRole} from "../../../hooks/dialogs/useCreateRole";
import Dialog from "@mui/material/Dialog";
import {LoadingButton} from "@mui/lab";
import colors from "../../../../../../shared/theme/colors";
import { Flex } from "../../../../../../shared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import {ManagePermissionsDialog} from "../managePermissions";


export const CreateRoleDialog = () => {
    const {
         isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        isOk,
        actions,
        form,
        handleChange,
        permissions,
        handleToggleSystem
    } = useCreateRole();

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth scroll={'body'}>
            <DialogTitle>{('Create role')}</DialogTitle>

            <DialogContent>
                <Flex w={'80%'} ai={'center'} m={'8px 0 0 0'}>
                    <TextField
                        value={form.name}
                        name={'name'}
                        onChange={handleChange}
                        placeholder={('Name')}
                        label={'Role name'}
                        // size={'small'}
                        sx={{margin: '0 8px 0 0'}}
                        fullWidth
                    />

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={form.system} onClick={handleToggleSystem}/>} label="System" />
                    </FormGroup>
                </Flex>

                {<Divider flexItem sx={{bgcolor: colors.skip, borderColor: colors.skip, margin: '8px 0 0 0'}}/>}

                <Flex w={'80%'} direction={'column'} m={'16px 0 0 0'}>
                    {/*<Button*/}
                    {/*    variant={'contained'}*/}
                    {/*    size={'small'}*/}
                    {/*    disabled={!form.permissions.length}*/}
                    {/*    onClick={actions.handlePreviewSelectedPermissions}*/}
                    {/*    sx={{width: '50%'}}*/}
                    {/*>{('Preview selected permissions')}</Button>*/}

                    <Typography variant={'body1'} sx={{margin: '16px 0 0 0'}}>{('Permissions')}</Typography>

                    <Button
                        variant={'text'}
                        startIcon={<AddIcon fontSize="small"/>}
                        onClick={actions.handleOpenManagePermissionDialog}
                        sx={{margin: '8px 0 0 0', width: '50%'}}
                    >{('Manage permissions')}</Button>

                    {!form.permissions.length && <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{('No permissions selected')}</Typography>}
                    <Flex overflowy={'auto'} w={'100%'} h={'100%'} maxh={'300px'} direction={'column'}>
                        {form.permissions.slice().sort().map(e => <Typography key={e} variant={'body2'} sx={{margin: '8px 0 0 0'}}>{e}</Typography>)}
                    </Flex>
                </Flex>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: '0 16px 0 0'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    color={'success'}
                    disabled={!isOk}
                >
                    {('Create')}
                </LoadingButton>
            </DialogActions>

            <ManagePermissionsDialog
                availablePermissions={permissions}
                selectedPermissions={form.permissions}
                deselectPermission={actions.deselectPermission}
                selectPermission={actions.selectPermission}
                roleName={form.name}
                selectAllPermission={actions.selectAllPermission}
                deselectAllPermissions={actions.deselectAllPermissions}
            />
        </Dialog>
    )
}