import {useDispatch, useSelector} from "react-redux";
import {
    isLoadingPolicies,
    openAddPolicy,
    openDeletePolicy,
    pageInfo,
    policies,
    selectedPolicy,
    selectPolicy
} from "../../store/slice";
import React from "react";
import {Flex} from "../../../../../shared/components/Layouts";
import {Typo} from "../../../../../shared/components/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Divider, TablePagination} from "@mui/material";
import {policyType} from "../../types";
import DeleteIcon from "@material-ui/icons/Delete";
import {GetPrivatePolicies} from "../../store/actions";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {getPolicyVisibilityStatus} from "../../helpers";

export const BuilderPoliciesTable = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.evidencesPage'});

    const dispatch = useDispatch();
    const _policies = useSelector(policies);
    const selected = useSelector(selectedPolicy);
    const _pageInfo = useSelector(pageInfo);
    const isLoading = useSelector(isLoadingPolicies);

    const onSelect = (id: string | null) => {
        dispatch(selectPolicy(id));
    };

    const handleOpenAddPolicy = () => {
        dispatch(openAddPolicy());
    }

    const handleDeleteEvidence = (e:any, id: string) => {
        e.stopPropagation();
        dispatch(openDeletePolicy(id));
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        dispatch(GetPrivatePolicies({page: newPage, count: 10}));
    };

    return(
        <Flex
            w={'49.5%'}
            h={'95%'}
            direction={'column'}
            background={'white'}
            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
            br={'10px'}
        >
            <Flex w={'100%'} p={!revDir ? '17px 20px 10px 15px' : '17px 15px 10px 20px'} jc={'space-between'} ai={'center'}>
                <Typo fontSize={'24px'} color={'rgba(0, 0, 0, 0.54)'}>{t('Documents')}</Typo>
                <AddCircleIcon style={{color: '#FAC000'}} onClick={handleOpenAddPolicy}/>
            </Flex>

            <Divider flexItem style={{ margin: !revDir ? '0 20px 0 15px' : '0 15px 0 20px', borderColor: '#FAC000'}} />

            <Flex w={'100%'} direction={'column'} disableScrollBar overflow={'auto'} h={'100%'} maxh={'95vh'}>
                {!isLoading && _policies.map((e: policyType) =>
                    <PolicyCard
                        key={e.id}
                        policy={e}
                        currId={selected || ''}
                        onSelect={onSelect}
                        deleteEvidence={handleDeleteEvidence}
                    />
                )}
            </Flex>
            <Divider flexItem/>
            <Flex w={'100%'} jc={'flex-end'}>
                <TablePagination
                    // sx={{overflow: 'hidden', margin: 'none'}}
                    sx={{
                        overflow: 'hidden', margin: 'none',
                        '& .MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '10px'} : {marginRight: '10px'})},
                        '& .MuiToolbar-root .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '10px'} : {marginRight: '10px'})},
                    }}
                    component="div"
                    count={_pageInfo.total}
                    page={_pageInfo.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={_pageInfo.count}
                    rowsPerPageOptions={[]}
                    showFirstButton
                    showLastButton
                />
            </Flex>
        </Flex>
    )
}

const PolicyCard = ({policy, currId, onSelect, deleteEvidence}:
                         {
                             policy: policyType,
                             currId: string | null,
                             onSelect: (id: string | null) => void,
                             deleteEvidence: (e: any, id: string) => void
                         }) => {
    const isSelected = (currId && currId === policy.id);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.evidencesPage.row'});

    return(
        <>
            <Flex
                w={'100%'}
                h={'90px'}
                jc={'space-between'}
                ai={'center'}
                onClick={() => onSelect(policy.id)}
                background={isSelected ? '#F1F2F2' : 'white'}
            >
                {isSelected && <Flex w={'5px'} h={'90px'} background={'#14CBFD'} position={'relative'}/>}

                <Flex w={'calc(90% - 5px)'} direction={'column'} p={!revDir ? '8px 0 8px 30px' : '8px 30px 8px 0'}>
                    <Typo fontSize={'16px'} fontWeight={300} >{policy.name}</Typo>
                    <Typo fontSize={'16px'} fontWeight={300} color={'#A0A2B1'} margin={'7px 0'}>{t(getPolicyVisibilityStatus(policy))}</Typo>
                    <Flex w={'80%'} jc={'space-between'}>
                        <Typo fontSize={'12px'} fontWeight={300} color={'#A0A2B1'}>Pipelines: {policy.pipeLinesCounter} </Typo>
                        <Typo fontSize={'12px'} fontWeight={300} color={'#A0A2B1'}>Templates: {policy.templates.length} </Typo>
                    </Flex>
                </Flex>

                <Flex ai={'center'} jc={'flex-end'} m={!revDir ? '0 20px 0 0' : '0 0 0 20px'}>
                    <DeleteIcon
                        style={{color: '#A0A2B1', cursor: 'pointer'}}
                        onClick={(e) => deleteEvidence(e,policy.id || '')}
                    />
                </Flex>
            </Flex>

            <Divider flexItem style={{margin: !revDir ? '0 25px 0 20px' : '0 20px 0 25px'}} />

        </>
    )
}