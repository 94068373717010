import {Box, Flex} from "../../../../../shared/components/Layouts";
import {useAppsList} from "../../hooks/useAppsList/useAppsList";
import {Button, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {CustomTableHeader} from "../../../../../shared/components/customTableHeader";
import {CustomTableRow, TableRowItemWrapper} from "../../../../../shared/components/customTableRow";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import React from "react";
import {Typo} from "../../../../../shared/components/Typography";
import {AVAILABLE_STATUS, STAGING_STATUS, UNAVAILABLE_STATUS} from "../../constants";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";


export const AppsList = () => {
    const {apps, isLoading, handleGoToCreateApp, handleGoToApp} = useAppsList();

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <Flex ai={'center'}>
                <Typography
                    variant={'h3'}
                    fontWeight={400}
                    sx={{margin: '0 32px 0 11px'}}
                >{('Integrations')}
                </Typography>
                <Button onClick={handleGoToCreateApp} variant="outlined" startIcon={<AddIcon fontSize={'small'}/>}>
                    {('add')}
                </Button>
            </Flex>

            <CustomTableHeader
                width={'100%'}

                hideIf={false}
                columns={[{name: 'Name', width: '25%'}, {name: 'EMN', width: '25%'}, {name: 'Status', width: '25%'}, {name: 'Config', width: '25%'}]}
            />

            <LocalSpinner isLoading={isLoading}/>

            <Flex w={'100%'} h={'90%'} overflowy={'auto'} style={{flexShrink: 1}}>
                <Flex w={'100%'} direction={'column'}>
                    {apps.map(e => {
                        return(
                            <CustomTableRow divider width={'100%'} innerWrapperWidth={'100%'} key={e.id} onClick={() => handleGoToApp(e)}>
                                <TableRowItemWrapper width={'25%'}>
                                    {e.logo !== null &&
                                        <Box w="calc(100% / 8)" maxw="34px" maxh="34px" m="0 15px 0 10px">
                                            <img src={e.logo} alt="google" width="80%" height="50%" style={{filter: e.status === "unavailiable" ? "grayscale(100%)" : "none"}}/>
                                        </Box> }
                                    <Typography sx={{fontSize: '16px', fontWeight: 400}} color={e.status === UNAVAILABLE_STATUS ? "#B1B1B1" : "black"}>{e.name}</Typography>
                                </TableRowItemWrapper>

                                <TableRowItemWrapper  width={'25%'}>
                                    <Typography sx={{fontSize: '16px', fontWeight: 400, color: '#343440'}}>{e.endpointMappingName}</Typography>
                                </TableRowItemWrapper>

                                <TableRowItemWrapper  width={'25%'}>
                                    <Typo fontSize="16px" fontWeight={400} letterSpacing="0.79" color={e.status === AVAILABLE_STATUS ? "green" : "#e38b8a"} >{e.status} {(e.status === STAGING_STATUS && e.releaseDate) ? parseDate(e.releaseDate, momentLocale) : ''}</Typo>
                                </TableRowItemWrapper>

                                <TableRowItemWrapper  width={'25%'}>
                                    <Typo fontSize="16px" fontWeight={400} letterSpacing="0.79" color={e.config !== null ? "green" : "#e38b8a"} >{e.config !== null ? 'Yes' : 'No'}</Typo>
                                </TableRowItemWrapper>
                            </CustomTableRow>
                        )
                    })}
                </Flex>
            </Flex>
        </Flex>
    )
}