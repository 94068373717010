//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';

//style
import {Flex} from "../../../shared/components/Layouts";
import {Typo} from "../../../shared/components/Typography";

//Components
import {SignIn} from "../../AuthWorkspaceAndCookies/signin";
import {
    ASSETS_SETTINGS_VARIANTS, EMAIL_TEMPLATES_PATH,
    PATH_ACCOUNTS,
    PATH_LICENSE,
    PATH_LOCAL_LOGIN,
    PATH_ORGANIZATIONS,
    VICA_TASKS
} from "./constants";
import {Builder} from "../../Components/builder";
import {PATH_LOCAL_2FA, ROOT_BUILDER} from "../../Components/builder/constants";
import {SignIn2FA} from "../../AuthWorkspaceAndCookies/signIn2FA";
import {Accounts} from "../../Components/accounts";
import {Organizations} from "../../Components/organizations";
import {License} from "../../Components/License";
import {LOCAL_ACCESS_MANAGEMENT_PATH} from "../../Components/AccessManagement/constants";
import {AccessManagement} from "../../Components/AccessManagement";
import {AssetsVariants} from "../../Components/assetsVariants";
import {NewApplications} from "../../Components/newApplications";
import {NEW_APPLICATIONS_ROOT} from "../../Components/newApplications/constants";
import {VicaTasks} from "../../Components/vicaTasks";
import {EmailTemplates} from "../../Components/emailTemplates";

export const InDevelopment = () => {
    return <Flex w="100vw" h="100vh" style={{position: "relative"}}>
                <Flex ai="center" jc="center" style={{position: "absolute", left:"0", right:"0", top: "0", bottom: "0", backgroundColor:"rgba(255,255,255,0.7)"}} onClick={(e) => {e.stopPropagation()}}>
                    <Typo fontSize="30px" fontWeight={600} letterSpacing="1.23px">In Development</Typo>
                </Flex>
            </Flex>
}

export const Routes: FC = () => {
    return <Switch>
        {/*<Route path="/" exact>*/}
        {/*    <Redirect to={PATH_ACCOUNTS}/>*/}
        {/*</Route>*/}

        <Route exact path={PATH_LOCAL_LOGIN} component={SignIn}/>
        <Route exact path={PATH_LOCAL_2FA} component={SignIn2FA}/>

        <Route path={PATH_ACCOUNTS} component={Accounts}/>
        <Route path={LOCAL_ACCESS_MANAGEMENT_PATH} component={AccessManagement}/>


        <Route path={PATH_ORGANIZATIONS} component={Organizations}/>
        <Route path={PATH_LICENSE} component={License}/>
        <Route path={NEW_APPLICATIONS_ROOT} component={NewApplications}/>
        <Route path={ROOT_BUILDER} component={Builder}/>
        {/*<Route path={'/test'} component={() => <InDevelopment />}/>*/}

        <Route path={ASSETS_SETTINGS_VARIANTS} component={AssetsVariants}/>
        <Route path={VICA_TASKS} component={VicaTasks}/>
        <Route path={EMAIL_TEMPLATES_PATH} component={EmailTemplates} />
        {/*<Route path={ASSETS_SETTINGS_VARIANTS} component={InDevelopment}/>*/}

        <Redirect to={PATH_ACCOUNTS} />
    </Switch>;
}
