// Core
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {PATH_SERVER} from "../constants";
import {Logging} from "../utils/log";
import {snackError} from "../utils/snacks";

// GraphQL Server
const httpLink = createHttpLink({
    uri: PATH_SERVER,
    credentials: 'include'
});

//exclude __typename from variables
const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

const logoutLink = onError(({networkError, graphQLErrors}) => {
    if (networkError) {
        Logging.log('NetworkError', networkError);
        snackError(`GraphQL(${PATH_SERVER}): Network Error`);
        return
    }
    if (graphQLErrors?.some(x => x?.extensions?.exception?.status === 401)) {
        snackError('Unauthorized');
    } else {
        Logging.log('GraphQLErrors', graphQLErrors);
        graphQLErrors?.forEach(x => x?.message && snackError(x?.message))
    }
})

const link = ApolloLink.from([logoutLink, cleanTypeName, httpLink]);

export const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache({
        typePolicies: {
            PostureAppDetailsResponse: {
                keyFields: ['appId', 'tabName', 'filter', 'pageInfo', ['count', 'page']]
            },
            PostureTabItem: {
                keyFields: ['tid']
            }
        }
    }),
});
