import React, {ChangeEvent, Component, createRef, MouseEvent, ReactElement} from "react";
import {
    CategoryText,
    EditorSideMenuCurrentColumnTabStyled,
    EditorSideMenuDescriptionStyled,
    EditorSideMenuETAFieldStyled,
    EditorSideMenuStyled,
    EditorSideMenuSwitchStyled,
    EditorSideMenuTabsStyled,
    EditorSideMenuTabStyled,
    EditorSideMenuTitleRowStyled,
    EditorSideMenuTitleStyled,
    HistoryContainer,
    HistoryTitleAndCloseButtonContainer,
    HistoryTitleText
} from "./styled";
import {EditorDragCreateBlockItem} from "../editorDragCreateBlockItem";
import {
    changeVariableByTextComponentEventName,
    DEFAUTL_ACTOR,
    editorBlocks,
    editorFillableFields,
    editorSideMenuTabs,
    setPagePropertiesMenuVariableEventName,
    updateVariableUsagesEventName
} from "../../constants";
import {
    IStatusProps,
    TChangeVariableEventData,
    TEditorActor,
    TEditorActorsConfigFromDB,
    TEditorActorsConfigTemporary,
    TEditorRecipient,
    TEditorVariableData,
    TSetPagePropertiesMenuEventData,
    TUpdateRecipientEventData
} from "../../types";
import {Autocomplete, Box, Button, Collapse, Divider, IconButton, Switch, TextField, Typography} from "@mui/material";
import {Add, Close, ManageAccounts} from "@mui/icons-material";
import {
    createEventChangeVariable,
    createEventCommonDialogOpen,
    createEventDeleteVariable,
    createEventNeedUpdateActors,
    createEventOpenManageActorsDialog,
    createEventSetHighlightedVariable,
    createEventUpdateEditorsEventName,
    createEventUpdateRecipient,
    getNameFromOption
} from "../../helpers";
import {PagePropertiesSideMenu} from "../editorItemPage/components/pagePropertiesSideMenu";
import {AddVariableDialog} from "../dialogs/addVariableDialog";
import {EditorDragCreateFillableField} from "../editorDragCreateFillableField";
import {copyTextToClipBoard} from "../../../../utils/text";
import colors from "../../../../theme/colors";
import {uuid} from "../../../../utils";
import {ApproverStep, TApproverStepProps} from "./components/approverStep";
import {RecipientStep, TRecipientStepProps} from "./components/recipientStep";
import {ResendInviteToRecipientDialog} from "../dialogs/resendInviteToRecipientDialog";
import {ResendInviteToGroupDialog} from "../dialogs/resendInviteToGroupDialog";
import {
    DocumentExecutorGroupModel,
    DocumentExecutorRecipientModel,
    DocumentExecutorStepModel,
    NewDocDataVariableModel
} from "../../../../GQLTypes";
import {ManageActorsDialog} from "../dialogs/manageActorsDialog";
import {EditorTooltip} from "../commonComponents/editorTooltip";
import {VariableCard} from "./components/variableCard";
import {VARIABLE_ID_DATA_ATTRIBUTE_NAME} from "../TextComponent/constants";
import {VariablesUsedUnusedDropdown} from "./components/variablesUsedUnusedDropdown";
import {FlexColumn, FlexRow} from "../editorTitleWithActionsRow/styled";
import {getDaysFromMs, getMsFromDays} from "../../../../utils/dateTools";
import {EditorFullProps} from "../editorDialog";
import {SetEtaDialog} from "./components/setEtaDialog";

interface Props extends IStatusProps {
    variables: NewDocDataVariableModel[];
    actorsConfig: TEditorActorsConfigFromDB;

    onResendInviteApprover: TApproverStepProps["handleResendInvite"];
    onSkipApprover: TApproverStepProps["handleSkip"];
    onResendInviteRecipient: TRecipientStepProps["handleResendInvite"];
    onChangeEta: EditorFullProps["onChangeEta"];
}
export interface EditorSideMenuState {
    tab: 'blocks' | 'variables' | 'pageProperties' | 'history' | 'comments' | 'verifyAndSend' | undefined;
    lastTab: EditorSideMenuState["tab"];
    variables: TEditorVariableData[];

    showVariables: 'used' | 'unused' | 'all';

    dialogs: {
        addEditVariable: {isOpen: boolean, variable?: TEditorVariableData};
        manageActors: {
            // ref: React.RefObject<EditorManageActorsDialog>;
        },

        resendInviteToGroup: {
            step: DocumentExecutorStepModel | null,
            group: DocumentExecutorGroupModel | null
        };

        resendInviteToRecipient: {
            step: DocumentExecutorStepModel | null,
            recipient: DocumentExecutorRecipientModel | null,
        };

        setNewEta: {
            stepId: string | null,
        }
    };

    autocompleteRef: React.RefObject<HTMLDivElement>;

    actor: TEditorRecipient | null;
    actorsConfig: TEditorActorsConfigFromDB;

    eta: {
        showApproverETA: boolean;
        approvers: Record<string, number>;

        showRecipientETA: boolean;
        recipients: Record<string, number>;
    }
}

export class EditorSideMenu extends Component<Props, EditorSideMenuState> {
    constructor(props: EditorSideMenu["props"]) {
        super(props);

        this.state = {
            tab: this.getTab(),
            lastTab: undefined,
            variables: props.variables.map((v): TEditorVariableData => ({...v, count: this.getVariableUsagesById(v.id, v.value !== '' ? v.value || v.title : v.title)})),

            showVariables: 'all',

            dialogs: {
                addEditVariable: {
                    isOpen: false
                },

                manageActors: {
                    // ref: createRef<EditorManageActorsDialog>()
                },

                resendInviteToGroup: {
                    step: null,
                    group: null
                },

                resendInviteToRecipient: {
                    step: null,
                    recipient: null
                },

                setNewEta: {
                    stepId: null,
                }
            },

            autocompleteRef: createRef<HTMLDivElement>(),

            actor: props.actorsConfig.recipients[0],
            actorsConfig: props.actorsConfig,

            eta: {
                showApproverETA: false,
                approvers: {},

                showRecipientETA: false,
                recipients: {}
            }
        }
    }

    getVariableUsagesById = (id: string, value: string): number => {
        const fullCount = document.querySelectorAll(`[${VARIABLE_ID_DATA_ATTRIBUTE_NAME}="${id}"]`).length;
        return Math.floor(fullCount / value.split(' ').length);
    };

    getTab = () => {
        return !this.props.readonly
            ? this.props.status.isVerifyPdf
                ? 'verifyAndSend'
                : this.props.status.isPdf || !this.props.variables.length
                    ? 'blocks'
                    : 'variables'
            : "history"
    }

    componentDidMount() {
        document.addEventListener(setPagePropertiesMenuVariableEventName, this.handleSetPropertiesByEvent);
        document.addEventListener(changeVariableByTextComponentEventName, this.handleSetVariableValueByEvent);
        document.addEventListener(updateVariableUsagesEventName, this.handleUpdateVariableUsagesByEvent);
    }
    componentWillUnmount() {
        document.removeEventListener(setPagePropertiesMenuVariableEventName, this.handleSetPropertiesByEvent);
        document.removeEventListener(changeVariableByTextComponentEventName, this.handleSetVariableValueByEvent);
        document.removeEventListener(updateVariableUsagesEventName, this.handleUpdateVariableUsagesByEvent);
    }

    handleUpdateVariableUsagesByEvent = (ev: Event) => {
        // const {detail} = ev as CustomEvent<{}>;
        // if (detail?.id && this.state.tab === "pageProperties") return;

        if (this.state.variables.some(v => v.count !== this.getVariableUsagesById(v.id, v.value !== '' ? v.value || v.title : v.title))) {
            this.setState(prev => ({
                ...prev,
                variables: prev.variables.map((v): TEditorVariableData => ({...v, count: this.getVariableUsagesById(v.id, v.value !== '' ? v.value || v.title : v.title)}))
            }))
        }
    };

    handleSetPropertiesByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TSetPagePropertiesMenuEventData>;
        if (detail?.id && this.state.tab === "pageProperties") return;

        if (detail) this.setState(prev => ({...prev, tab: "pageProperties", lastTab: prev.tab}))
        else this.setState(prev => ({...prev, tab: prev.lastTab}));
    };

    handleSetVariableValueByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TEditorVariableData>;
        if (!detail) return;

        this.setState(prev => ({
            ...prev,
            variables: prev.variables.map(v => ({...v, value: v.id === detail.id ? detail.value : v.value}))
        }));
        this.handleChange(detail.id)({target: {value: detail.value || ''}});
    };

    selectTab = (event: any, tab: EditorSideMenuState["tab"]) => {
        this.setState(prev => ({...prev, tab, lastTab: prev.tab}));
    }

    createChangeVariableEvent = (data: TChangeVariableEventData) => {
        createEventChangeVariable(data);
    }

    getActorsData = (): TEditorActorsConfigFromDB => {
        return {
            editors: this.state.actorsConfig.editors,
            recipients: this.state.actorsConfig.recipients.map(r => ({
                ...r,
                eta: (this.state.eta.showRecipientETA && this.state.eta.recipients[r.actor.email]) || r.eta,
            })),
            approvers: this.state.actorsConfig.approvers.map(a => ({
                ...a,
                eta: (this.state.eta.showApproverETA && this.state.eta.approvers[JSON.stringify(a.groups)]) || a.eta,
            })),
        };
    }
    setActorsConfig = (actorsConfig: TEditorActorsConfigTemporary) => {
        const needUpdate: TUpdateRecipientEventData = [];
        this.state.actorsConfig.recipients.forEach(oldRecipient => {
            const oldRecipientSourceEmailRole = '' + oldRecipient.actor.email + oldRecipient.role;
            const newRecipient = actorsConfig.recipients.find(newRecipient => newRecipient.sourceEmailRole === oldRecipientSourceEmailRole);

            if (!newRecipient) {
                needUpdate.push({sourceEmailRole: oldRecipientSourceEmailRole, actor: DEFAUTL_ACTOR, role: ''});
            }

            if (newRecipient && (
                newRecipient.role !== oldRecipient.role ||
                newRecipient.actor.email !== oldRecipient.actor.email ||
                newRecipient.actor.firstName !== oldRecipient.actor.firstName ||
                newRecipient.actor.lastName !== oldRecipient.actor.lastName
            )) {
                needUpdate.push({sourceEmailRole: oldRecipientSourceEmailRole, actor: newRecipient.actor, role: newRecipient.role || ''});
            }
        })
        needUpdate.length && createEventUpdateRecipient(needUpdate);

        this.setState(prev => {
            const newActorsConfig = {
                ...prev.actorsConfig,
                ...actorsConfig,
                recipients: actorsConfig.recipients.map(r => ({...r, sourceEmailRole: '' + r.actor.email + r.role}))
            };

            const newActor =
                actorsConfig.recipients
                    .find(e => e.sourceEmailRole === ('' + this.state.actor?.actor.email + this.state.actor?.role)) ||
                newActorsConfig.recipients[0] ||
                {actor: DEFAUTL_ACTOR, role: ''};
            newActor.sourceEmailRole = '' + newActor.actor.email + newActor.role;

            return {
                ...prev,
                actorsConfig: newActorsConfig,
                actor: newActor,
            }
        });
        createEventNeedUpdateActors({});
    }

    //////
    handleChange = (id: string) => (e: ChangeEvent<HTMLInputElement> | {target: {value: string}}) => {
        const variable = this.state.variables.find(v => v.id === id);
        if (!variable) return;

        this.createChangeVariableEvent({id, value: (
                e.target.value.length > 0
                    ? e.target.value
                    : variable?.title.trim() || ''
            )});

        this.setState(prev => {
            return {...prev, variables: prev.variables.map(v => ({...v, value: v.id === id ? e.target.value : v.value}))};
        });
    };
    handleVariableEdit = (variable: TEditorVariableData) => {
        this.createChangeVariableEvent({id: variable.id, value: (
                variable.value?.length
                    ? variable.value
                    : variable.title.trim() || ''
            )});

        this.setState(prev => {
            return {...prev, variables: prev.variables.map(v => v.id === variable.id ? variable : v), dialogs: {...prev.dialogs, addEditVariable: {isOpen: false}}};
        });
    };

    handleAddVariable = (variable: TEditorVariableData) => {
        this.setState(prev => {
            return {
                ...prev,
                variables: [...prev.variables, {id: uuid(), type: 'TEXT', title: variable.title, description: variable.description, value: variable.value, order: variable.order - 0.5, count: variable.count}]
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((e, i) => ({...e, order: i})),
                dialogs: {
                    ...prev.dialogs,
                    addEditVariable: {isOpen: false},
                },
            };
        });
    };

    setAddVariableDialog = (fn: (prev: EditorSideMenuState["dialogs"]["addEditVariable"]) => EditorSideMenuState["dialogs"]["addEditVariable"]) => {
        this.setState(prev => ({...prev, dialogs: {...prev.dialogs, addEditVariable: fn(prev.dialogs.addEditVariable)}}));
    }

    handleCopy = (id: string, value: string, title: string) => {
        const variable = {
            id,
            value: value.length > 0 ? value : title,
        }
        copyTextToClipBoard(`$${JSON.stringify(variable)}`, 'Copied');
    }

    onFocusVariable = (id: string | null) => () => {
        createEventSetHighlightedVariable({id});
    }

    deleteVariable = (id: string) => () => {
        this.setState(prev => ({...prev, variables: prev.variables.filter(v => v.id !== id)}));
        createEventDeleteVariable({id});
    }

    setActor = (actor: TEditorRecipient | null) => {
        this.setState(prev => ({...prev, actor}));
    }

    onResendInviteApprover = (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => {
        createEventCommonDialogOpen({type: 'resendInviteGroup', isOpen: true, data: group.actors.map(e => e.email)});
        this.setState(prev => ({...prev, dialogs: {...prev.dialogs, resendInviteToGroup: {step, group}}}));
    }
    onResendInviteRecipient = (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => {
        createEventCommonDialogOpen({type: 'resendInviteRecipient', isOpen: true, data: [recipient.email]});
        this.setState(prev => ({...prev, dialogs: {...prev.dialogs, resendInviteToRecipient: {step, recipient}}}));
    }

    //
    handleChangeEtaDialog = (data: EditorSideMenuState["dialogs"]["setNewEta"]) => {
        this.setState(prev => ({...prev, dialogs: {...prev.dialogs, setNewEta: data}}));
    }

    //
    handleUpdateEditors = (editors: TEditorActor[]) => {
        this.setState((prev) => {
            return{
                ...prev,
                actorsConfig: {...prev.actorsConfig, editors}
            }
        })
        createEventUpdateEditorsEventName(editors);
    }

    handleClickShowVariable = (type: EditorSideMenuState["showVariables"]) => {
        this.setState(prev => ({...prev, showVariables: type}));
    }

    canViewTab = (disabledForReadOnly: boolean, disabledForPdf: boolean, disabledForVerify: boolean, showOnlyOnVerify: boolean, disabledForAdminPanel: boolean): boolean => {
        let canView = true;
        if (disabledForAdminPanel && this.props.status.isAdmin) canView = false;

        if (this.props.readonly) {
            canView = !disabledForReadOnly;
        } else if (this.props.status.isVerifyPdf) {
            canView = !disabledForVerify;
        } else if (this.props.status.isPdf) {
            canView = !disabledForPdf;
        }

        if (showOnlyOnVerify) canView = this.props.status.isVerifyPdf && this.props.status.isDraft;
        return canView;
    }

    setShowEta = (name: 'showApproverETA' | 'showRecipientETA') => () => {
        this.setState(prev => ({
            ...prev,
            eta: {
                ...prev.eta,
                [name]: !prev.eta[name],
            }
        }))
    }

    render() {
        if (
            (this.state.tab !== 'verifyAndSend' && this.props.status.isDraft && this.props.status.isVerifyPdf) ||
            (this.state.tab === 'verifyAndSend' && (!this.props.status.isDraft || !this.props.status.isVerifyPdf)) ||
            ((this.state.tab === 'variables' || this.state.tab === 'blocks') && this.props.readonly)
        ) {
            this.setState((prev) => ({...prev, tab: this.getTab()}));
        }

        return (
            <Collapse orientation="horizontal" in={!!this.state.tab} collapsedSize={41}>
                <EditorSideMenuStyled>
                    {this.state.tab !== "pageProperties" && (
                        <EditorSideMenuTabsStyled value={this.state.tab || false} onChange={this.selectTab}>
                            {editorSideMenuTabs.map(e =>
                                this.canViewTab(e.disabledForReadOnly, e.disabledForPdf, e.disabledForVerify, !!e.showOnlyOnVerify, !!e.disabledForAdminPanel) && (
                                    <EditorSideMenuTabStyled key={e.tab} value={e.tab} disabled={e.disabled}
                                                             isActive={this.state.tab === e.tab || undefined}
                                                             label={(
                                                                 <EditorTooltip title={e.title} key={e.tab} placement={"left"}>
                                                                     {e.icon as ReactElement}
                                                                 </EditorTooltip>
                                                             )}/>
                                ))}
                        </EditorSideMenuTabsStyled>
                    )}

                    <EditorSideMenuCurrentColumnTabStyled fullWidth={this.state.tab === "pageProperties" || undefined}>

                        {!this.props.readonly && (
                            <PagePropertiesSideMenu/>
                        )}

                        {!this.props.readonly && this.state.tab === 'blocks' && (
                            <>
                                {!this.props.status.isPdf && (
                                    <>
                                        <EditorSideMenuTitleRowStyled>
                                            <EditorSideMenuTitleStyled>{'Content blocks'}</EditorSideMenuTitleStyled>
                                            <IconButton onClick={() => this.selectTab(undefined, undefined)}><Close/></IconButton>
                                        </EditorSideMenuTitleRowStyled>
                                        <EditorSideMenuDescriptionStyled>{'You can drag and drop the items to your editing area.'}</EditorSideMenuDescriptionStyled>

                                        {editorBlocks.map(e => (
                                            <EditorDragCreateBlockItem key={e.type} type={e.type} icon={e.icon} title={e.title}/>
                                        ))}

                                        <Box sx={{width: '100%', height: '20px'}}/>
                                    </>
                                )}

                                <EditorSideMenuTitleRowStyled ref={this.state.autocompleteRef}>
                                    <EditorSideMenuTitleStyled>{'Fillable fields'}</EditorSideMenuTitleStyled>
                                    {/*<IconButton onClick={() => this.selectTab(undefined, undefined)}><Close/></IconButton>*/}
                                </EditorSideMenuTitleRowStyled>
                                <EditorSideMenuDescriptionStyled sx={{mt: '10px'}}>{'You can drag and drop the items to your editing area.'}</EditorSideMenuDescriptionStyled>

                                <Autocomplete<TEditorRecipient>
                                    size={"small"}
                                    componentsProps={{
                                        paper: {sx: {'& .MuiAutocomplete-listbox': {paddingBottom: 0}}}
                                    }}
                                    options={[
                                        ...this.state.actorsConfig.recipients.filter(e => e.actor.email.length || e.role?.length),
                                        {actor: {email: 'button', firstName: '', lastName: ''}, fields: [], order: 0, message: ''}
                                    ]}
                                    value={this.state.actorsConfig.recipients.find(e => (e.actor.email + e.role) === ('' + this.state.actor?.actor.email + this.state.actor?.role)) || null}
                                    getOptionLabel={(option) => getNameFromOption(option)}
                                    renderOption={(props, option) => option.actor.email !== 'button' ? (
                                        <li key={'' + option.actor?.email + option.role} {...props} style={{borderBottom: '1px solid ' + colors.stroke.grey}}>
                                            { getNameFromOption(option) }
                                        </li>
                                    ) : (
                                        <Button key={option.actor?.email || 'button'} fullWidth startIcon={<ManageAccounts/>}
                                                sx={{textTransform: 'none !important'}} variant={"text"}
                                                onClick={(event) => {
                                                    props.onClick?.(event as unknown as MouseEvent<HTMLLIElement>)
                                                    this.state.autocompleteRef.current?.focus()
                                                    createEventOpenManageActorsDialog({isOpen: true});
                                                }}>
                                            { getNameFromOption(option) }
                                        </Button>
                                    )}
                                    onChange={(e, value) => {this.setActor(value)}}
                                    renderInput={(params) => (<TextField {...params} placeholder={'Select recipient'}/>)}
                                />

                                {editorFillableFields.map(e => (
                                    <EditorDragCreateFillableField key={e.type} type={e.type} icon={e.icon} title={e.title}
                                                                   actor={this.state.actor?.actor || DEFAUTL_ACTOR}
                                                                   role={this.state.actor?.role || undefined}/>
                                ))}
                            </>
                        )}

                        {!this.props.readonly && this.state.tab === 'variables' && !this.props.status.isPdf && (
                            <>
                                <EditorSideMenuTitleRowStyled>
                                    <EditorSideMenuTitleStyled>{'Variables'}</EditorSideMenuTitleStyled>
                                    <IconButton onClick={() => this.selectTab(undefined, undefined)}><Close/></IconButton>
                                </EditorSideMenuTitleRowStyled>
                                <EditorSideMenuDescriptionStyled>{'You can add and fill variables for your document.'}</EditorSideMenuDescriptionStyled>

                                <Button startIcon={<Add/>} onClick={() => {this.setAddVariableDialog(() => ({isOpen: true}))}}>
                                    {'Add variable'}
                                </Button>

                                <VariablesUsedUnusedDropdown active={this.state.showVariables} onSelect={this.handleClickShowVariable}/>

                                {this.state.variables
                                    .slice()
                                    .sort((a,b) =>
                                        a.count > 0 && b.count === 0 ? -1
                                            : a.count === 0 && b.count > 0 ? 1
                                                : a.order - b.order
                                    )
                                    .filter(v => this.state.showVariables === 'used' ? v.count > 0 : this.state.showVariables === 'unused' ? v.count === 0 : true)
                                    .map((v) => (
                                        <VariableCard key={v.id} v={v}
                                                      onFocus={this.onFocusVariable(v.id)}
                                                      onBlur={this.onFocusVariable(null)}
                                                      onChange={this.handleChange(v.id)}
                                                      deleteVariable={this.deleteVariable(v.id)}
                                                      handleCopy={this.handleCopy}
                                                      handleEditClick={() => {
                                                          this.setAddVariableDialog(prev => ({...prev, isOpen: true, variable: v}))
                                                      }}
                                        />
                                    ))}

                                <AddVariableDialog isOpen={this.state.dialogs.addEditVariable.isOpen} initValue={this.state.dialogs.addEditVariable.variable}
                                                   variableNames={this.state.variables.map(v => v.title.trim()).filter(e => e !== this.state.dialogs.addEditVariable.variable?.title)}
                                                   onSave={(variable) => {
                                                       if (this.state.dialogs.addEditVariable.variable) {
                                                           this.handleVariableEdit(variable)
                                                       } else {
                                                           this.handleAddVariable(variable)
                                                       }
                                                   }}
                                                   onCancel={() => {this.setAddVariableDialog(prev => ({...prev, isOpen: false}))}}
                                />
                            </>
                        )}

                        {!this.props.readonly && this.state.tab === 'verifyAndSend' && (
                            <>
                                <EditorSideMenuTitleRowStyled>
                                    <EditorSideMenuTitleStyled>{'Verify & Send'}</EditorSideMenuTitleStyled>
                                    <IconButton onClick={() => this.selectTab(undefined, undefined)}><Close/></IconButton>
                                </EditorSideMenuTitleRowStyled>
                                <EditorSideMenuDescriptionStyled>
                                    {
                                        'Please note that when converting a document with fillable fields to PDF format, there is a possibility that the positions of these fields may shift or be altered in the resulting PDF file.\n' +
                                        'Review and verify the layout and positioning of fillable fields in the converted PDF file to ensure accuracy and usability. Adjustments may be required to realign the fields if necessary.'
                                    }
                                </EditorSideMenuDescriptionStyled>

                                <Typography variant={"*bodyText1_semibold"} sx={{color: colors.text.dark}}>
                                    ETA (Estimated Time of Completion)
                                </Typography>

                                <Typography variant={"*bodyText2"} sx={{color: colors.text.dark}}>
                                    Default ETA is 7 days. You can change it for each approver or recipient.
                                </Typography>

                                <EditorSideMenuSwitchStyled control={<Switch/>} label={'Change ETA for approvers'}
                                                            checked={this.state.eta.showApproverETA} disabled={this.state.actorsConfig.approvers.length === 0}
                                                            onChange={this.setShowEta("showApproverETA")}/>
                                <Collapse in={this.state.eta.showApproverETA}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>

                                        {this.state.actorsConfig.approvers.map((step) => {
                                            return (
                                                <Box key={JSON.stringify(step)}
                                                     sx={{
                                                         display: 'flex', flexDirection: 'column',
                                                         gap: '10px'
                                                     }}>

                                                    {step.groups.map(group => (
                                                        <FlexColumn gap={'10px'} key={JSON.stringify(group)}>
                                                            <Typography variant={"body2"} fontWeight={600} sx={{color: colors.text.dark}}>
                                                                {group.actors.length > 1 ? 'Approvers group' : 'Approver'}
                                                            </Typography>

                                                            <FlexColumn gap={'6px'}>
                                                                {group.actors.map(actor => (
                                                                    <Typography key={actor.email} variant={"body2"} sx={{color: colors.text.blue}}>{actor.email}</Typography>
                                                                ))}
                                                            </FlexColumn>

                                                            {step.order !== 0 && (
                                                                <FlexRow alignItems={'center'} gap={'10px'}>
                                                                    <EditorSideMenuETAFieldStyled
                                                                               value={getDaysFromMs(this.state.eta.approvers[JSON.stringify(step.groups)] ?? step.eta ?? 0)}
                                                                               onChange={e => {
                                                                                   this.setState(prev => ({
                                                                                       ...prev,
                                                                                       eta: {
                                                                                           ...prev.eta,
                                                                                           approvers: {
                                                                                               ...prev.eta.approvers,
                                                                                               [JSON.stringify(step.groups)]: getMsFromDays(Number(e.target.value))
                                                                                           }
                                                                                       }
                                                                                   }))
                                                                               }}/>

                                                                    <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to complete'}</Typography>
                                                                </FlexRow>
                                                            )}

                                                            {step.order !== 0 && <Divider flexItem/>}
                                                        </FlexColumn>
                                                    ))}
                                                </Box>
                                            )
                                        })}

                                        {this.state.actorsConfig.approvers.every(r => r.order === 0) && (
                                            <FlexRow alignItems={'center'} gap={'10px'}>
                                                <EditorSideMenuETAFieldStyled
                                                           value={getDaysFromMs(this.state.eta.approvers[JSON.stringify(this.state.actorsConfig.approvers[0]?.groups)] ?? this.state.actorsConfig.approvers[0]?.eta ?? 0)}
                                                           onChange={e => {
                                                               this.setState(prev => {
                                                                   const approvers = prev.eta.approvers;
                                                                   prev.actorsConfig.approvers.map(e => JSON.stringify(e.groups)).forEach(key => {
                                                                       approvers[key] = getMsFromDays(Number(e.target.value))
                                                                   })

                                                                   return {
                                                                       ...prev,
                                                                       eta: {
                                                                           ...prev.eta,
                                                                           approvers,
                                                                       }
                                                                   }
                                                               })
                                                           }}/>

                                                <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to complete'}</Typography>
                                            </FlexRow>
                                        )}
                                    </Box>
                                </Collapse>

                                <EditorSideMenuSwitchStyled control={<Switch/>} label={'Change ETA for recipients'} sx={{mt: '10px'}}
                                                            checked={this.state.eta.showRecipientETA}
                                                            onChange={this.setShowEta("showRecipientETA")}/>
                                <Collapse in={this.state.eta.showRecipientETA}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                        {this.state.actorsConfig.recipients.every(r => r.order === 0) && (
                                            <Typography variant={"body2"} fontWeight={600} sx={{color: colors.text.dark}}>{'Recipients'}</Typography>
                                        )}

                                        {this.state.actorsConfig.recipients.map((step) => {
                                            return (
                                                <Box key={JSON.stringify(step)}
                                                     sx={{
                                                         display: 'flex', flexDirection: 'column',
                                                         gap: '10px'
                                                     }}>
                                                    {step.order !== 0 && (
                                                        <Typography variant={"body2"} fontWeight={600} sx={{color: colors.text.dark}}>{'Recipient'}</Typography>
                                                    )}

                                                    <Typography key={step.actor.email} variant={"body2"} sx={{color: colors.text.blue}}>{step.actor.email}</Typography>

                                                    {step.order !== 0 && (
                                                        <FlexRow alignItems={'center'} gap={'10px'}>
                                                            <EditorSideMenuETAFieldStyled
                                                                       value={getDaysFromMs(this.state.eta.recipients[step.actor.email] ?? step.eta ?? 0)}
                                                                       onChange={e => {
                                                                           this.setState(prev => ({
                                                                               ...prev,
                                                                               eta: {
                                                                                   ...prev.eta,
                                                                                   recipients: {
                                                                                       ...prev.eta.recipients,
                                                                                       [step.actor.email]: getMsFromDays(Number(e.target.value))
                                                                                   }
                                                                               }
                                                                           }))
                                                                       }}/>

                                                            <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to complete'}</Typography>
                                                        </FlexRow>
                                                    )}

                                                    {step.order !== 0 && <Divider flexItem/>}
                                                </Box>
                                            )
                                        })}

                                        {this.state.actorsConfig.recipients.every(r => r.order === 0) && (
                                            <FlexRow alignItems={'center'} gap={'10px'}>
                                                <EditorSideMenuETAFieldStyled
                                                           value={getDaysFromMs(this.state.eta.recipients[this.state.actorsConfig.recipients[0].actor.email] ?? this.state.actorsConfig.recipients[0].eta ?? 0)}
                                                           onChange={e => {
                                                               this.setState(prev => {
                                                                   const recipients = prev.eta.recipients;
                                                                   prev.actorsConfig.recipients.map(e => e.actor.email).forEach(key => {
                                                                          recipients[key] = getMsFromDays(Number(e.target.value))
                                                                   })

                                                                   return {
                                                                       ...prev,
                                                                       eta: {
                                                                           ...prev.eta,
                                                                           recipients
                                                                       }
                                                                   }
                                                               })
                                                           }}/>

                                                <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to complete'}</Typography>
                                            </FlexRow>
                                        )}
                                    </Box>
                                </Collapse>
                            </>
                        )}

                        {this.state.tab === 'history' &&
                            <HistoryContainer>
                                <HistoryTitleAndCloseButtonContainer>
                                    <HistoryTitleText>{('Document process history')}</HistoryTitleText>
                                    <IconButton onClick={() => this.selectTab(undefined, undefined)}><Close/></IconButton>
                                </HistoryTitleAndCloseButtonContainer>

                                <CategoryText>{('APPROVERS')}</CategoryText>

                                {this.props.execution?.approveSteps.map((e, id) => <ApproverStep
                                        step={e}
                                        key={e.id}
                                        id={id}
                                        actorsConfig={this.props.actorsConfig}
                                        isAbleToResendInvite={this.props.status.isAbleToResendInvite}
                                        isAbleToSkip={this.props.status.isAbleToSkip}
                                        handleResendInvite={this.onResendInviteApprover}
                                        handleSkip={this.props.onSkipApprover}
                                        onChangeEta={() => this.handleChangeEtaDialog({stepId: e.id})}
                                        status={this.props.isLoading}
                                    />
                                )}

                                <CategoryText>{('RECIPIENTS')}</CategoryText>

                                {this.props.execution?.recipientSteps.map((e, id) => <RecipientStep
                                        step={e}
                                        key={e.id}
                                        id={id}
                                        isAbleToResendInvite={this.props.status.isAbleToResendInvite}
                                        handleResendInvite={this.onResendInviteRecipient}
                                        onChangeEta={() => this.handleChangeEtaDialog({stepId: e.id})}
                                        status={this.props.isLoading}
                                    />
                                )}

                                <SetEtaDialog open={!!this.state.dialogs.setNewEta.stepId}
                                              onConfirm={days => {
                                                  this.state.dialogs.setNewEta.stepId &&
                                                  this.props.onChangeEta(this.state.dialogs.setNewEta.stepId, days * 24 * 60 * 60 * 1000)
                                              }}
                                              onClose={() => {this.handleChangeEtaDialog({stepId: null})}}
                                />
                            </HistoryContainer>
                        }
                    </EditorSideMenuCurrentColumnTabStyled>

                    <ResendInviteToRecipientDialog onConfirm={() => {
                        if (this.state.dialogs.resendInviteToRecipient.step && this.state.dialogs.resendInviteToRecipient.recipient) {
                            this.props.onResendInviteRecipient(this.state.dialogs.resendInviteToRecipient.step, this.state.dialogs.resendInviteToRecipient.recipient)
                        }
                    }} isLoading={this.props.isLoading.isReinviting}/>
                    <ResendInviteToGroupDialog onConfirm={() => {
                        // console.log(`ResendInviteToGroupDialog`, this.state.dialogs.resendInviteToGroup.step, this.state.dialogs.resendInviteToGroup.group)
                        if (this.state.dialogs.resendInviteToGroup.step && this.state.dialogs.resendInviteToGroup.group) {
                            this.props.onResendInviteApprover(this.state.dialogs.resendInviteToGroup.step, this.state.dialogs.resendInviteToGroup.group)
                        }
                    }} isLoading={this.props.isLoading.isReinviting}/>

                    {!this.props.readonly && (
                        <ManageActorsDialog
                            parentRef={this}
                            status={this.props.status}
                        />
                    )}

                    {/*{!this.props.readonly && (*/}
                    {/*    <ManageEditorsDialog actors={this.state.actorsConfig}*/}
                    {/*                         handleSubmit={this.handleUpdateEditors}*/}
                    {/*    />*/}
                    {/*)}*/}
                </EditorSideMenuStyled>
            </Collapse>
        );
    }
}
