import {useState} from "react";

export const useOpenDialog = <T>() => {
    const [isOpen, setIsOpen] = useState<T | null>(null);

    const openDialog = (data: T) => setIsOpen(data);
    const closeDialog = () => setIsOpen(null);

    return {
        openDialog,
        closeDialog,
        isOpen: isOpen !== null,
        dialogData: isOpen,
    };
}