import React, {FC} from "react";
import {Box} from "@mui/material";
import {BuilderFilesList} from "../../components/builderGallery/FilesList";


export const GalleryPage: FC = () => {


    return (
        <Box display={'flex'} width={'100%'} height={'100%'} flexDirection={'column'} sx={{mt: '10px'}}>
            <BuilderFilesList />

        </Box>
    )

}
