import {Flex} from "../../../../../shared/components/Layouts"
import {useDispatch, useSelector} from "react-redux";
import {
    openDeleteControl,
    openEditControl,
    selectControl,
    selectedControl,
    selectedFramework
} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Divider, TablePagination, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {controlType} from "../../types";

import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {useHistory} from "react-router-dom";
import {ROOT_BUILDER, ROOT_CONTROLS} from "../../constants";
import {Box, Popover} from "@material-ui/core";
import {DeattachControl} from "../../store/actions";
import DeleteIcon from "@material-ui/icons/Delete";
import {useRouteMatch} from "react-router";
import {FixedSizeList as List, ListChildComponentProps} from 'react-window';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';
import {SearchField} from "../../../../../shared/components/CustomInputs";
import {useBuilderControlsTable} from "../../hooks/useBuilderControlsTable";
import {TIdName} from "../../../../../shared/types";
// import List from 'react-virtualized-auto-sizer';
// import 'react-virtualized-auto-sizer/styles.css'; // only needs to be imported once

export const BuilderControlsTable = () => {

    const {
        selected,
        pageInfo,
        isLoadingList,
        isLoadingControlsByFrameworkId,
        isControlsPage,
        search,
        setSearch,
        filteredControls,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        handleChoose,
        handleChangeRowsPerPage,
        handleChangePage,
        handleSetSearch,

        //filter
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    } = useBuilderControlsTable();


    return(
        <Flex
            w={'49.5%'}
            h={'95%'}
            direction={'column'}
            background={'white'}
            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}
            br={'10px'}
            p={isControlsPage ? '0' : '0 0 20px 0'}
        >
            <Flex w={'100%'} p={'17px 20px 10px 15px'} jc={'space-between'} ai={'center'} overflow={'hidden'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Typo fontSize={'24px'} color={'rgba(0, 0, 0, 0.54)'}>{'Controls'}</Typo>

                    <AddCircleIcon style={{color: '#FAC000', marginLeft: '16px'}} onClick={(event) => handlePopoverOpen(event, 'origin')}/>
                </Box>



                <Popover
                    open={anchorEl.id === null ? false : anchorEl.id === 'origin'}
                    style={{boxShadow: 'none'}}
                    elevation={0}
                    anchorEl={anchorEl.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Flex direction="column" w="103px" h="87px" border={'0.3px solid #9E9E9E'} br={'4px'}>
                        <Flex direction="column">
                            <Box onClick={() => handleChoose('new')} p={'14px 7px'}>
                                <Typo fontSize="12px" fontWeight={400} letterSpacing="0.4px" cursor="pointer" color={'#9E9E9E'}>{'Create new'}</Typo>
                            </Box>
                            <Divider flexItem/>
                            <Box onClick={() => handleChoose('exists')} p={'14px 7px'}>
                                <Typo fontSize="12px" fontWeight={400} letterSpacing="0.4px" cursor={"pointer"} color={'#9E9E9E'}>{'Use existing'}</Typo>
                            </Box>
                        </Flex>
                    </Flex>
                </Popover>

                {isControlsPage ?
                    <SearchField
                        onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}
                        size={'small'}
                        sx={{
                            ml: '10px',
                            width: '320px',
                    }}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={handleChange}
                        value={value}
                        disabled={isLoadingList}
                        onKeyDown={handleKeyDown}
                    />
                    :
                    <SearchField
                        onClear={() => {setSearch("")}}
                        size={'small'}
                        sx={{
                            ml: '10px',
                            width: '320px',
                    }}
                        variant={'outlined'}
                        placeholder={'Search'}
                        onChange={(e) => handleSetSearch(e.target.value)}
                        value={search || ''}
                        disabled={isLoadingControlsByFrameworkId}
                    />
                }


            </Flex>

            <Divider flexItem style={{ margin: '0 20px 0 15px', borderColor: '#FAC000'}} />

            <Flex w={'100%'} h={'100%'} direction={'column'} jc={'space-between'}>
                <AutoSizer>
                    {({height, width}: Size) => (
                        <ListRowControls width={width ?? 0} height={height ?? 0}
                                         controls={filteredControls}
                                         isControlsPage={isControlsPage || false} selected={selected}/>
                    )}
                </AutoSizer>

                {isControlsPage &&
                <div>
                    <Divider flexItem/>
                    <Flex w={'100%'} jc={'flex-end'}>
                        <TablePagination
                            // sx={{overflow: 'hidden', margin: 'none', direction: 'ltr'}}
                            sx={{
                                overflow: 'hidden', margin: 'none',
                                '& .MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {width: '24px', height: '24px', ml: '0'},
                                '& .MuiToolbar-root .MuiButtonBase-root': {width: '24px', height: '24px', ml: '10px'},
                            }}
                            component="div"
                            count={pageInfo.total}
                            page={pageInfo.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPage={pageInfo.count}
                            rowsPerPageOptions={[10, 50, 100, { value: pageInfo.total, label: 'All' }]}
                            showFirstButton
                            showLastButton
                        />
                    </Flex>
                </div>
                }
            </Flex>



            {/*<Flex w={'100%'} direction={'column'} disableScrollBar overflow={'auto'} h={'100%'} maxh={isControlsPage ? '95vh' : '60vh'}>*/}
            {/*    {_controls.map((e: controlType) =>*/}
            {/*        <ControlCard*/}
            {/*            key={e.id}*/}
            {/*            control={e}*/}
            {/*            currId={selected}*/}
            {/*            onSelect={onSelect}*/}
            {/*            reference={ref}*/}
            {/*            openEditDialog={handleEditControl}*/}
            {/*            handleDeattachControl={handleDeattachControl}*/}
            {/*            isControlsPage={isControlsPage || false}*/}
            {/*            deleteControl={handleDeleteControl}*/}
            {/*        />)}*/}
            {/*</Flex>*/}
        </Flex>
    )
}

const ListRowControls = ({width, height, isControlsPage, selected, controls} : {width: number, height: number, isControlsPage: boolean, selected: string | null, controls: controlType[]}) => {
    const listRef = useRef<List | null>(null);

    useEffect(() => {
        if(!isControlsPage) {
            if (listRef && listRef.current && selected) {
                const currControl = controls.find(e => e.id === selected);
                if (currControl) {
                    const key = controls.indexOf(currControl);
                    if (key !== -1) listRef.current.scrollToItem(key);
                }
            }
        }
        //eslint-disable-next-line
    }, []);

    return (
        <List
            itemCount={controls.length}
            itemSize={107}
            height={isControlsPage ? height-55 : height}
            width={width}
            ref={listRef}
            itemData={controls}
        >
            {Row}
        </List>
    )
}

const Row = ({ index, style, data: _controls }: ListChildComponentProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    // const _controls = useSelector(controls);
    const selected = useSelector(selectedControl);
    const _selectedFramework = useSelector(selectedFramework);

    const isControlsPage = useRouteMatch<{controlId: string}>(ROOT_CONTROLS)?.isExact;

    if(index > _controls.length - 1) return <></>;

    const control = _controls[index];

    if(!control) return <></>;

    const isSelected = (selected && selected === control.id);
    const isOwn = true;

    const onSelect = (id: string | null) => {
        id && dispatch(selectControl(id));
        (id && !isControlsPage) && history.push(ROOT_BUILDER + `/framework/${_selectedFramework}/control/${id}`);
    };

    const openEditDialog = (e: any, id: string) => {
        e.stopPropagation();
        dispatch(openEditControl(id));
    }

    const handleDeattachControl = (e: any, id: string) => {
        e.stopPropagation();
        const onSuccess = () => history.push(ROOT_BUILDER + `/framework/${_selectedFramework}`)
        dispatch(DeattachControl({frameworkId: _selectedFramework || '', controlId: id, onSuccess: !isControlsPage ? onSuccess : () => null}));
    }

    const deleteControl = (e: any, id: string) => {
        e.stopPropagation();
        dispatch(openDeleteControl(id));
    }

    return(
        <div style={style} dir={'ltr'}>
            <Flex
                // ref={isSelected ? reference : null}
                w={'100%'}
                h={'105px'}
                jc={'space-between'}
                ai={'center'}
                // direction={revDir ? 'row-reverse': 'row'}
                onClick={() => onSelect(control.id)}
                background={isSelected ? '#F1F2F2' : 'white'}
            >
                {(isSelected) && <Flex w={'5px'} h={'100%'} background={'#14CBFD'} position={'absolute'}/>}

                <Flex w={'calc(90% - 15px)'} direction={'column'} p={'8px 0 8px 30px'} >
                    <Tooltip title={control.name} placement="bottom-start">
                        <Typography fontSize={'16px'} fontWeight={400} sx={{whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}} >{control.name}</Typography>
                    </Tooltip>

                    <Flex w={'70%'} jc={'space-between'} ai={'center'}>
                        <Typography fontSize={'14px'} fontWeight={300} >{control.id}</Typography>
                    </Flex>
                    <Flex ai={'center'} wrap={'wrap'} >
                        {isControlsPage &&
                            <>
                                <Typography fontSize={'12px'} color={'#A0A2B1'}>Attached Frameworks:</Typography>
                                {control.foundInFrameworksCounter > 0 ?
                                    (control.foundInFrameworks ?? []).map((fr: TIdName) => fr && <Typography key={fr.id} fontSize={'12px'} color={'#A0A2B1'} sx={{ml: '3px', whiteSpace: 'nowrap', maxWidth: '70%', textOverflow: 'ellipsis', overflow: 'hidden'}}>{`${fr.id} ${fr.name}`}</Typography>)
                                    :
                                    <Typography fontSize={'12px'} color={'#A0A2B1'} sx={{ml: '3px'}}>{'0'}</Typography>

                                }

                            </>
                        }

                    </Flex>
                    <Typography fontSize={'12px'} color={'#A0A2B1'} sx={{mt: !isControlsPage ? '12px' : undefined}} >Evidence count: {control.evidences?.length || 0}  </Typography>
                </Flex>

                <Flex ai={'center'} jc={isOwn ? 'flex-end' : 'space-between'} m={'0 20px 0 0'}>
                    {isOwn ?
                        <EditIcon
                            style={{color: '#A0A2B1', cursor: 'pointer', margin: '0 10px 0 0'}}
                            onClick={(e) => openEditDialog(e, control.id || '')}
                        />
                        :
                        <Flex w={'32px'}/>
                    }
                    {isControlsPage ?
                        <DeleteIcon
                            style={{color: '#A0A2B1', cursor: 'pointer'}}
                            onClick={(e) => deleteControl(e,control.id || '')}
                        />
                        :
                        <LinkOffIcon
                            style={{color: '#A0A2B1', cursor: 'pointer'}}
                            onClick={(e) => handleDeattachControl(e, control.id || '')}
                        />
                    }
                </Flex>
            </Flex>
            <Divider flexItem style={{margin: '0 25px 0 20px'}} />
        </div>
    )
}
