import React, {FC, useRef, useState} from "react";
import {ActiveBox, Anchor, MenuIconBox, MenuItemWrapper} from "../../styles";
import {LeftSideBarItem, TAnchorItem} from "../../types";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

type PropTypes = {
    item: LeftSideBarItem;
    isActive: boolean;
    setAnchor?: (obj: TAnchorItem) => void;
    handleActive: (item: LeftSideBarItem) => void;
    setOpacity: (opacity: string) => void;
};

export const CompactedItem: FC<PropTypes> = (props: PropTypes) => {
    const {revDir} = useMainTranslation();

    const {item, isActive, handleActive, setAnchor, setOpacity} = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const timeOutHandler = useRef<any>();
    const w = isActive ? 'calc((100%) - 5.1px)' : 'calc((100%) - 0.1px)';
    const [hover, setHover] = useState<{[key: string]: boolean}>({});
    // const { bit } = useContext(ThemeContext);
    React.useEffect(() => () => { timeOutHandler.current && clearTimeout(timeOutHandler.current)}, []);

    return (
        <>
            <MenuItemWrapper ai={'flex-between'} >
                <ActiveBox active={isActive} revDir={revDir}/>
                <MenuIconBox
                    revDir={revDir}
                    onClick={() => handleActive(item)}
                    active={isActive}
                    isHover={!isActive && hover[item.title]}
                    onMouseEnter={() => {
                        setHover({[item.title]: true});
                        if (item.isItComplex() && setAnchor) {
                            timeOutHandler.current = setTimeout(() => {
                                setAnchor({anchor: ref.current, item});
                                item?.title && setOpacity("1")
                            }, 200)
                        }
                    }}
                    onMouseLeave={() => {
                        setHover({[item.title]: false});
                        timeOutHandler.current && clearTimeout(timeOutHandler.current)
                    }}
                    w={w}
                    minw={w}
                >
                    {item.icon && (
                        isActive && item.icon.active
                            ? <item.icon.active onClick={() => {}} />
                            : <item.icon.inactive onClick={() => {}} />

                    )}
                </MenuIconBox>
                <Anchor ref={ref}/>
            </MenuItemWrapper>
        </>
    )
};
