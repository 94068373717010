import {gql} from "graphql.macro";

export const NewDocumentFragment = gql`
    fragment NewDocumentFragment on NewDocumentModel {
        editors {
            firstName
            lastName
            email
        }
        approvers {
            id
            order
            eta
            groups {
                actors {
                    firstName
                    lastName
                    email
                }
                id
                isRequired
                message
                name
            }
        }
        recipients {
            role
            eta
            actor {
                firstName
                lastName
                email
            }
            fields {
                id
                isRequired
                pageId
                position {
                    x
                    y
                    metaPageHeight
                }
                size {
                    width
                    height
                }
                type
            }
            message
            order
        }

        accessRules {
            rule
        }
        updater {
            firstName
            lastName
            email
        }

        createdDate
        creatorId
        deleted
        deletedDate
        documentId
        folderId
        generatedFileId
        id
        lastModified
        latest
        name
        origin
        status
        tags
        type
        version
        visible
        workspaceId

        data {
            editor
            innerHtml
            pdfFileId
            variables {
                description
                id
                order
                title
                type
                value
            }
        }
    }`;