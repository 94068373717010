import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    PATH_ACCOUNTS,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_NEW_WORKSPACES
} from "../../../../BarsENV/shell/constants";
import {multiStoreDataType} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {
    allMultiStoreData,
    eraseData,
    setSharedWorkspacesAndOrganizationsData
} from "../../store/sharedWorkspacesAndOrganizations/slice";
import {isUnProtectedPaths} from "../../../../BarsENV/leftSideBar/helpers";
import {
    isPathAvailableForEveryone,
    isPathAvailableWithNoWorkspace,
    SELECT_WORKSPACES_PATH
} from "../../constants";
import {UseManageWorkspacesAndOrganizations} from "../useManageWorkspacesAndOrganizations";
import {MAIN_DATA_COOKIE_NAME} from "../../../../../shared/constants";
import {logoutAction} from "../../../signin/store/actions";
import {Logging} from "../../../../../shared/utils/log";

export const useWorkspacesAndOrganizations = () => {
    //this hook is used to keep workspaces, organizations and user data up to date
    //this hook looks for updated in cookies + sending to login/workspaces page if no data / or workkspace not selected
    const {getCookieData, deleteCookieByName, setCookieData} = UseManageWorkspacesAndOrganizations();
    const [isLoadingCookieData, setIsLoadingCookieData] = useState<boolean>(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    //current saved data
    // const _is403 = useSelector(is403);
    const multiStore = useSelector(allMultiStoreData) as multiStoreDataType;
    const cookiesData = getCookieData();
    //useEffect init
        // try get all data -> if no data present going to login
        //else if only workspaces and organizations present going to workspaces page for choose //updating store
        //if all the data present -> updating store
    //useEffect checking cookies
        //if new data present -> update

    useEffect(() => {
        Logging.log(`useEffect(multiStore) - init`);
        if(JSON.stringify(multiStore) !== JSON.stringify(cookiesData) && !isLoadingCookieData){
            Logging.log(`useEffect(multiStore)  - init: multiStore ${JSON.stringify(multiStore)} is NOT equel to cookies - updating cookies`);
            setCookieData(multiStore);
        }
        //used for user profile updates - if user set new first/lastName - updating store in actions
        //updating cookies by changing store
        //eslint-disable-next-line
    }, [multiStore]);

    useEffect(() => {
        Logging.log(`useEffect(multiStore) 2 - init`);
        if(multiStore.currentUser){
            Logging.log(`useEffect(multiStore) 2 - currentUser FOUND - set loading FALSE`);
            setIsLoadingCookieData(false);
        }
    }, [multiStore]);

    useEffect(() => {
        Logging.log(`isLoadingCookieData: ${isLoadingCookieData}`);
    }, [isLoadingCookieData]);

    useEffect(() => {

        //init useEffect - used to get data from cookies and put in inner store
        Logging.log(`useWorkspacesAndOrganizations - init`);
        if(cookiesData){
            if(!cookiesData.is2FaAuthComplete || !cookiesData.isAuthComplete){
                setIsLoadingCookieData(false);
                history.push(PATH_LOCAL_LOGIN); //user has no cookies - sending to login
                deleteCookieByName(MAIN_DATA_COOKIE_NAME);
                return
            }
            Logging.log(`useWorkspacesAndOrganizations - init - setting cookie data`);
            dispatch(setSharedWorkspacesAndOrganizationsData(cookiesData));
            if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && (!cookiesData.selectedWorkspace || !cookiesData.selectedOrganization) && !isPathAvailableWithNoWorkspace(pathname)){
                //if cookies r there and workspace ot org not selected and is secured path -> going to workspaces
                history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH);
                Logging.log(`useWorkspacesAndOrganizations - init - no ws | no org | protected path (${pathname}) | path not available with now ws and org - going to select worksapce`);
            }
            if(isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && cookiesData.selectedWorkspace && cookiesData.selectedOrganization){
                //if cookies t there and selected org and workspace and its unsecured path - going to frameworks (it was unprotected path like login)
                history.push(PATH_ACCOUNTS);
                Logging.log(`useWorkspacesAndOrganizations - init - ws | org | UnProtected path (${pathname}) - going to frameworks`);

            }
        }else{
            setIsLoadingCookieData(false);
            if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname)){
                history.push(PATH_LOCAL_LOGIN); //user has no cookies - sending to login
                Logging.log(`useWorkspacesAndOrganizations - init - no cookies - going to login`);
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData)`);
        if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && !cookiesData) {
            history.push(PATH_LOCAL_LOGIN);
            Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData) - protected path (${pathname}) | no cookies - going to login`);
            return;
            //if on protected path and has no cookies -> going to login
        }else{
            if(cookiesData && !isUnProtectedPaths(pathname)){
                Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData): PROTECTED PATH + cookiesData: ${JSON.stringify(cookiesData)}`);
                //user has cookies data and its protected path
                if((!cookiesData.selectedWorkspace || !cookiesData.selectedOrganization) && !isPathAvailableWithNoWorkspace(pathname)){
                    Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData) - no ws | no org | path not available with no ws (${pathname}) - going to select workspace`);
                    history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH);
                    //user has data -> but not selected workspace -> going to workspaces
                    dispatch(setSharedWorkspacesAndOrganizationsData({
                        ...cookiesData,
                        selectedWorkspace: null,
                        selectedOrganization: null,
                        roles: {
                            isOrgOwner: false,
                            isOrgMember: false,
                            isOrgAdmin: false,
                            isWsOwner: false,
                            isWsAdmin: false,
                            isWsEditor: false,
                            isWsViewer: false,
                            isWsOutsideCollab: false,
                        },
                    })
                    );
                }
            }
        }
        //eslint-disable-next-line
    }, [cookiesData]);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname)`);

        //this useEffect is used to watch after pathname - if user signed in and on unprotected path - going to framewroks
        if(isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && cookiesData && cookiesData.isAuthComplete && cookiesData.is2FaAuthComplete && cookiesData.selectedWorkspace && cookiesData.selectedOrganization){
            history.push(PATH_ACCOUNTS);
            Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname) - uprotected path (${pathname}) | isPathAvailableForEveryone: false | cookies present | isAuthComplete: true | is2FaAuthComplete: true | selectedWorkspace | selectedOrganization - going to frameworks`);
        }
        if(isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && cookiesData && cookiesData.isAuthComplete && cookiesData.is2FaAuthComplete && !cookiesData.selectedWorkspace && !cookiesData.selectedOrganization){
            history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH);
            Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname) - uprotected path (${pathname}) |  isPathAvailableForEveryone: false | cookies present | isAuthComplete: true | is2FaAuthComplete: true | not selectedWorkspace | nott selectedOrganization - going to select workspace`);
        }
        if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && (!cookiesData || !cookiesData.isAuthComplete || !cookiesData.is2FaAuthComplete)){
            dispatch(eraseData()); //clean inner store
            history.push(PATH_LOCAL_LOGIN);
            setIsLoadingCookieData(false);
            Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname) - protected path (${pathname}) |  isPathAvailableForEveryone: false | NO cookies or isAuthComplete: false or is2FaAuthComplete: false - going to login`);
        }

        // if(!isUnProtectedPaths(pathname) && (!cookiesData?.selectedOrganization || !cookiesData?.selectedWorkspace ) && !PATHS_AVAILABLE_WITH_NO_WORKSPACES.some(e => e === pathname)){
        //     //if protected path and not org or workspace and its not good path
        //     history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH);
        // }
        //eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(multiStore.isAuthComplete, multiStore.is2FaAuthComplete)`);
        if(!multiStore.isAuthComplete && !isPathAvailableForEveryone(pathname) && !isLoadingCookieData){
            Logging.log(`useWorkspacesAndOrganizations - useEffect(multiStore.isAuthComplete, multiStore.is2FaAuthComplete) - isAuthComplete: false  - LOGOUT (401?)`);
            //when user signs in - setting isAuthComplete & is2FaAuthComplete to true
            //if seeing that its false - looks like user got 401 and have to logout him
            dispatch(eraseData()); //clean inner store
            deleteCookieByName(MAIN_DATA_COOKIE_NAME); //clean cookies
            dispatch(logoutAction()); //send request to back to logout and clean auth cookie
            history.push(PATH_LOCAL_LOGIN); //go to login
            setIsLoadingCookieData(false);
        }
        //eslint-disable-next-line
    }, [multiStore.isAuthComplete, multiStore.is2FaAuthComplete]);

    //CAN'T HANDLE 403 HERE - NO CHOOSE WORKSPACE PAGE
    // useEffect(() => {
    //     console.log(`useWorkspacesAndOrganizations - useEffect(_is403)`);
    //     //1 - user reloaded page - cookies has data - store not
    //     //2 - user gets 403 - erasing inner data - cookies has data - store not
    //     if(_is403){
    //         console.log(`useWorkspacesAndOrganizations - useEffect(_is403) - !selectedOrganization | !selectedWorkspace - going to select workspace`);
    //         //checking selectedOrganization & selectedWorkspace both in inner store and in cookies - when user reloads page - there is moment when there is nulls and it throes user to select ws and org - but in second it will be filled - so checking if cookies has nulls too
    //         //when user gets 403 - there is dispatch to Shared store that arase selectedOrg and workspace
    //         //here we looking after those changes and updating cookies + sending user to select workspace or org
    //         const newData = {
    //             ...multiStore,
    //             selectedOrganization: null,
    //             selectedWorkspace: null,
    //             roles:{
    //                 isOrgOwner: false,
    //                 isOrgAdmin: false,
    //                 isOrgMember: false,
    //
    //                 isWsOwner: false,
    //                 isWsAdmin: false,
    //                 isWsEditor: false,
    //                 isWsViewer: false,
    //                 isWsOutsideCollab: false,
    //             },
    //         };
    //
    //         dispatch(eraseGot403());
    //         setCookieData(newData);
    //         dispatch(setSharedWorkspacesAndOrganizationsData(newData));
    //         setIsLoadingCookieData(false);
    //         history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH);
    //         //update cookies
    //     }
    //     //eslint-disable-next-line
    // }, [_is403]);

    return{
        isDisabledProtectedTopBar: !multiStore.selectedWorkspace || !multiStore.selectedOrganization,
        isLoadingCookieData
    }
}