import {useDispatch, useSelector} from "react-redux";
import {dialogs, hidePreviewRoleDialog} from "../../../store/slice";

export const usePreviewRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const {previewRole} = useSelector(dialogs);

    //actions
    const handleClose = () => {
        dispatch(hidePreviewRoleDialog());
    }

    return{
        isOpen: previewRole.isOpen,
        handleClose,
        role: previewRole.role
    }
}