import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    deselectTemplate,
    loading,
    openDeleteTemplateDialog,
    selectedTemplate,
    selectTemplate,
    templates
} from "../../store/slice";
import {useEffect} from "react";
import { TEMPLATES_LIST_PATH} from "../../constants";
import {addErrorSnack} from "../../../../BarsENV/snack/store/slice";
import { Flex } from "../../../../../shared/components/Layouts";
import {PageTitleAndActions} from "../../../../../shared/components/pageTitleAndActions";
import {useForm} from "../../../../../shared/hooks/useForm";
import {TEmailTemplate} from "../../types";
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
// import CodeEditor from '@uiw/react-textarea-code-editor';
import {html_beautify} from 'js-beautify';
import {UpdateEmailTemplatesAdmin} from "../../store/actions";
import {DeleteTemplateDialog} from "../../components/dialogs/deleteTemplate";


export const EmailTemplatesExact = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const template = useSelector(selectedTemplate);
    const _templates = useSelector(templates);
    const { isLoadingUpdate} = useSelector(loading);
    const id = useParams<{id: string}>().id;

    const goBack = () => {
        dispatch(deselectTemplate());
        history.push(TEMPLATES_LIST_PATH);
    }

    useEffect(() => {
        if(id && !template && _templates.length > 0){
            const __template = _templates.find(e => e.id === id);
            if(!__template) {
                dispatch(addErrorSnack(`Template with id ${id} not found!`));
                goBack();
            }else {
                dispatch(selectTemplate(__template));
                setLocalTemplate(__template);
            }
        }else{
            template && setForm(template);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(`useEffect[_templates]`)
        if(_templates.length > 0){
            const __template = _templates.find(e => e.id === id);
            if(!__template) {
                dispatch(addErrorSnack(`Template with id ${id} not found!`));
                goBack();
            }else{
                setLocalTemplate(__template);
                dispatch(selectTemplate(__template));
            }
        }
        //eslint-disable-next-line
    }, [_templates]);

    //TEMPLATE

    const {form, setForm} = useForm<TEmailTemplate>({id: '', template: ''});

    const setLocalTemplate = (_template: TEmailTemplate) => {
        setForm(_template)
    }

    const isOk = JSON.stringify(template) !== JSON.stringify(form);

    const handleSave = () => {
        dispatch(UpdateEmailTemplatesAdmin({template: form}));
    }

    return(
        <Flex direction={'column'} overflow={'hidden'}>
            <PageTitleAndActions
                title={template?.id}
                handleGoBack={goBack}
                actions={[
                    {
                        title: 'Delete template',
                        variant: 'contained',
                        color: 'error',
                        onClick: () => dispatch(openDeleteTemplateDialog()),
                        disabled: !template
                    },
                    {
                        title: 'Save changes',
                        variant: 'contained',
                        color: 'success',
                        onClick: handleSave,
                        disabled: !isOk,
                        loading: isLoadingUpdate
                    }
                ]}
            />

            <Flex w={'100%'} h={'100%'} overflow={'hidden'} m={'16px 0 0 0'} jc={'space-between'}>
                <Flex w={'58%'} h={'100%'} overflow={'auto'}>
                    <CodeMirror
                        value={html_beautify(form.template)}
                        height={'100%'}
                        extensions={[html()]}
                        onChange={(evn) => setForm({...form, template: evn})}
                    />
                </Flex>
                <Flex border={'1px solid black'} w={'40%'} overflow={'auto'} dangerouslySetInnerHTML={{__html: form?.template.replaceAll('%%', '%') ?? ''}} />
            </Flex>

            <DeleteTemplateDialog />
        </Flex>
    )
}