export const PATH_LOCAL_LOGIN = "/login";

export const pathMapper = new Map([
])


export const WIDTH_EXPANDED = '9.52%';
export const WIDTH_COMPACTED =  '60px';

export const PATH_LOCAL_NEW_WORKSPACES = "/newWorkspaces";

export const PATH_ACCOUNTS = '/accounts';
export const PATH_ORGANIZATIONS = '/organizations';
export const PATH_APPLICATIONS = '/applications';
export const PATH_LICENSE = '/license';


export const ASSETS_SETTINGS_VARIANTS = '/assetssettings';
export const VICA_TASKS = '/vicatasks';
export const EMAIL_TEMPLATES_PATH = '/emailTemplates';