import {Typography} from "@mui/material";
import styled from "styled-components";

export type customTableHeader = {
    columns: {name: string, width?: string}[];
    width?: string | undefined;
    hideIf: boolean;
    padding?: string;
}

export const AutomationsTableHeaderWrapper = styled.div<{minWidth?: string, width?: string, p?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: ${props => props.width ? props.width : '90%'};
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.p ? props.p : '25px 0 24px 0'};
`;

export const TableHeaderItemWrapper = styled.div<{ width?: string, ai?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const CustomTableHeader = ({columns, width, hideIf, padding}: customTableHeader) => {
    return(
        hideIf ? <></> :
        <AutomationsTableHeaderWrapper width={width} p={padding}>
            {columns.map(e => {
                return(
                    <TableHeaderItemWrapper width={e.width ?? `${100 / columns.length}%`} key={e.name}>
                        <Typography sx={{fontSize: '16px', fontWeight: 700}}>{e.name}</Typography>
                    </TableHeaderItemWrapper>
                )
            })}
        </AutomationsTableHeaderWrapper>
    )
}