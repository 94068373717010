import {ChipProps} from "../../types";
import {useMemo, useState} from "react";

export const useChipObject = (initialState: ChipProps[]) => {
    const [chips, setChips] = useState<ChipProps[]>(initialState || []);
    const setSpecificChips = (chips: ChipProps[]) => {
        setChips(chips)
    };
    const addChip = (category: string, value: string) => {
        if (!chips.some(chip => chip.filterName === category && chip.value === value)) {
            setChips((prev) => (
                [...prev, {filterName: category, value} as ChipProps])
            );
        }
    };
    const deleteChip = (category: string, value: string) => {
        setChips((prev) => prev.filter(chip => !(chip.filterName === category && chip.value === value)));
    };
    const updateChip = (category: string, value: string) => {
        setChips((prev) => prev.map(
            chip => chip.filterName === category ? {filterName: category, value} as ChipProps : chip));
    }
    const setInitialChips = () => setChips([]);
    const chipsMapToChipProps = (chipsMap: {[key: string]: string[]}) => (
        Object.entries(chipsMap).flatMap(([key, values]) => (
            values.map( value => ({
                filterName: key,
                value
            }))
        ))
    );
    return useMemo(() => ({
        chipsMap: [...new Set(chips.map(c => c.filterName))].reduce((acc, key) => ({
            ...acc,
            [key]: chips.filter(({filterName}) => filterName === key).map(({value}) => value),
        }), {}),
        setSpecificChips,
        addChip,
        deleteChip,
        updateChip,
        chips,
        setInitialChips,
        chipsMapToChipProps
    })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [chips]);
}
