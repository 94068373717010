//getShortAutomations
import {createAsyncThunk} from "@reduxjs/toolkit";
import {PaginationInput} from "../../../../shared/types";
import {addInfoSnack, addSuccessfulSnack} from "../../../BarsENV/snack/store/slice";
import {
    activateUser,
    adminGenerateRecoveryCodesByPublicId,
    blockUser,
    deleteUser,
    getExtendedCollab,
    getShortCollabs
} from "../api";

export const GetShortCollabs = createAsyncThunk(
    'Accounts/GetShortCollabs',
    async (data: {pageInfo: PaginationInput}) => {
        return await getShortCollabs(data.pageInfo);
    }
);

//getExtendedCollab
export const GetExtendedCollab = createAsyncThunk(
    'Accounts/getExtendedCollab',
    async (data: {id: string}) => {
        return await getExtendedCollab(data.id);
    }
);

//blockUser
export const BlockUser = createAsyncThunk(
    'Accounts/blockUser',
    async (data: {id: string, reason: string}, {dispatch}) => {
        const mes = await blockUser(data.id, data.reason);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//activateUser
export const ActivateUser = createAsyncThunk(
    'Accounts/activateUser',
    async (data: {id: string}, {dispatch}) => {
        const mes = await activateUser(data.id);
        dispatch(addSuccessfulSnack(mes));
        return data;
    }
);

//deleteUser
export const DeleteUser = createAsyncThunk(
    'Accounts/deleteUser',
    async (data: {id: string}, {dispatch}) => {
        const mes = await deleteUser(data.id);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//adminGenerateRecoveryCodesByPublicId
export const AdminGenerateRecoveryCodesByPublicId = createAsyncThunk(
    'Accounts/adminGenerateRecoveryCodesByPublicId',
    async (data: {id: string}, {dispatch}):Promise<string[]> => {
        return await adminGenerateRecoveryCodesByPublicId(data.id);
    }
);