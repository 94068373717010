import {useDispatch, useSelector} from "react-redux";
import { Flex } from "../../../../../shared/components/Layouts";
import {PageTitleAndActions} from "../../../../../shared/components/pageTitleAndActions";
import { loading, selectTemplate, templates} from "../../store/slice";
import {useHistory} from "react-router-dom";
import {TEMPLATES_CREATE_PATH, TEMPLATES_EXACT_PATH} from "../../constants";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {TEmailTemplate} from "../../types";

export const EmailTemplatesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _templates = useSelector(templates);
    const isLoading = useSelector(loading).isLoadingGet;

    const onRowClick = (row: TEmailTemplate) => {
        dispatch(selectTemplate(row));
        history.push(TEMPLATES_EXACT_PATH.replace(':id', row.id));
    }

    return(
        <Flex direction={'column'}>
            <PageTitleAndActions
                title={'Email templates'}
                actions={[
                    {
                        title: 'Add template',
                        variant: 'contained',
                        onClick: () => history.push(TEMPLATES_CREATE_PATH)
                    }
                ]}
            />

            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_templates.map((row) => (
                            <TableRow
                                hover
                                onClick={() => onRowClick(row)}
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                            </TableRow>
                        ))}
                        {!_templates.length && isLoading && <LocalSpinner />}
                        {!_templates.length && !isLoading && <Typography>No templates found</Typography>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Flex>
    )
}