import React, {FC, useCallback, useEffect, useState} from "react";
import {useVicaTasks} from "./hooks/useVicaTasks";
import {Box, Button, Divider, Icon, IconButton, Typography} from "@mui/material";
import {CategoryRow} from "./components/categoryRow";
import {SelectedVicaCategory} from "./components/selectedVicaCategory";
import {EditTaskDialog} from "./components/dialogs/editTaskDialog";
import {AddFilesDialog} from "./components/dialogs/addFilesDialog";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import colors from "../../../shared/theme/colors";
import EditIcon from "@material-ui/icons/Edit";
import {Item, TCategoryWithTasks} from "./types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { LoadingButton } from "../../../shared/components/Basic/CommonButtons";


export const ItemTypes = {
    CARD: 'card',
}
export const VicaTasks: FC = () => {

    const {
        categories: {categories, order},
        isLoading: {isLoadingCategories, isEditingCategoriesOrder: isLoadingChangeOrder}, clean,
        getCategories,
        selectedCategoryId,
        updateCategoryOrder,
    } = useVicaTasks();

    const [editCategories, setEditCategories] = useState<boolean>();
    const [_categories, _setCategories] = useState<TCategoryWithTasks[]>(categories ?? []);

    useEffect(() => {
        const div = selectedCategoryId ? document.getElementById(selectedCategoryId) : undefined;
        div?.scrollIntoView({behavior: 'smooth', block: 'nearest'})

        // eslint-disable-next-line
    }, [selectedCategoryId])

    useEffect(() => {
        getCategories();
        setEditCategories(false);

        return () => {
            clean();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        (editCategories && categories) && _setCategories(categories);

        // eslint-disable-next-line
    }, [editCategories])

    useEffect(() => {
        !isLoadingChangeOrder && setEditCategories(false);

        // eslint-disable-next-line
    }, [isLoadingChangeOrder])

    //dnd
    const setCategories = useCallback(
        (cols: TCategoryWithTasks[]) => {
            _setCategories(cols.map((e,i) => ({...e, order: i})));
        }, []
    )
    const findColumn: (id: string) => {column: TCategoryWithTasks, index: number} | null = useCallback(
        (id: string) => {
            const column = _categories.find(e => e.id === id);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: _categories.indexOf(column)
            } : null;
        },
        [_categories],
    )

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(_categories));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setCategories(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, _categories, setCategories],
    )


    const isChangedOrder = JSON.stringify(order) !== JSON.stringify(_categories.map((e, i) => ({id: e.id, name: e.name, order: i})));

    const handleSave = () => {
        if (!editCategories) return;

        isChangedOrder && updateCategoryOrder(_categories.map((e,i) => ({id: e.id, name: e.name, order: i})));
    }




    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={'hidden'} gap={'6px'}>
            <Box display={"flex"} flexDirection={'column'} width={'min(30%, 300px)'} height={'100%'} overflow={'auto'} gap={'14px'} p={'20px'}>
                {!isLoadingCategories && <CategoryRow category={null} newItem/>}
                {!isLoadingCategories && <CategoryRow category={null} addFiles/>}
                {(!isLoadingCategories && !editCategories) &&
                    <Box display={"flex"} padding={'18px'}
                         onClick={() => setEditCategories(true)}
                         sx={{
                             boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
                             border: '1px solid ' + colors.yellow,
                             borderRadius: '8px',
                             backgroundColor: 'white',
                             '&:hover': {
                                 border: `1px solid ${colors.yellow}`,
                                 cursor: 'pointer',
                             },
                         }}>
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} justifyContent={"center"}>
                            <Icon color={"primary"}><EditIcon/></Icon>
                            <Typography variant={'h4'} fontWeight={500}>{"Edit Categories"}</Typography>
                        </Box>

                    </Box>
                }

                {(!isLoadingCategories && editCategories) &&
                    <Box display={"flex"} alignItems={"center"} justifyContent={'space-between'}>
                        <Button variant={'contained'} sx={{width: '48%'}} onClick={() => setEditCategories(false)}>{'Cancel'}</Button>
                        <LoadingButton variant={'contained'}
                                       disabled={!isChangedOrder}
                                       sx={{width: '48%'}}
                                       onClick={handleSave} loading={isLoadingChangeOrder }
                                       loadingPosition={isLoadingChangeOrder ? 'end' : undefined}
                                       endIcon={isLoadingChangeOrder ? <div style={{width: '10px', height: '10px', marginRight: '12px'}}/> : undefined}
                        >
                            {'Save'}
                        </LoadingButton>
                    </Box>
                }

                {isLoadingCategories && (
                    <>
                        <CategoryRow category={null}/>
                        <CategoryRow category={null}/>
                        <CategoryRow category={null}/>
                    </>
                )}
                {editCategories ?
                    <div ref={drop}>
                        {_categories.map(c => (
                            <ColumnDraggableRow key={c.id}
                                                selectedCategoryId={selectedCategoryId}
                                                category={c}
                                // disabled={_categories.length < 2}
                                                moveColumn={moveColumn}
                                                findColumn={findColumn}
                            />
                        ))}
                    </div>
                    :
                    <>
                        {categories.map(category => <CategoryRow key={category.id} category={category}/>)}
                    </>
                }
            </Box>

            <Divider flexItem orientation={'vertical'} variant={"middle"}/>

            {selectedCategoryId && <SelectedVicaCategory/>}

            <EditTaskDialog/>
            <AddFilesDialog/>
        </Box>
    );
}



export const ColumnDraggableRow = (
    {category, selectedCategoryId, findColumn, moveColumn}: {
        category: TCategoryWithTasks,
        selectedCategoryId: string | null,
        // disabled: boolean,
        moveColumn: (key: string, atIndex: number) => void,
        findColumn: (key: string) => {column: TCategoryWithTasks, index: number} | null
    }) => {
    const searchedColumn = findColumn(category.id);
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: category.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [category.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== category.id) {
                    const col = findColumn(category.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    )

    const opacity = isDragging ? 0 : 1

    return(
        <Box display={'flex'} alignItems={'center'} gap={'8px'}
             ref={(node: ConnectableElement) => drag(drop(node))}
             padding={'10px 5px'} id={category?.id}
             sx={{
                 boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
                 border: '1px solid ' + colors.yellow,
                 borderRadius: '8px',
                 opacity: opacity.toString(),
                 backgroundColor: category && selectedCategoryId === category?.id ? `1px solid ${colors.active}` :  "white",
                 '&:hover': {
                     border: `1px solid ${colors.yellow}`,
                     cursor: 'pointer',
                 },
             }}
             marginBottom={'10px'}
        >
            <IconButton>
                <DragIndicatorIcon />
            </IconButton>
            <Typography variant={'h4'} fontWeight={500} >{category.name}</Typography>

        </Box>
    )
}
