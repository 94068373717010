import {Flex} from "../../../../../../../shared/components/Layouts"
import {useDispatch, useSelector} from "react-redux";
import {addControlDialog, hideAddControl, isLoading, selectedFramework} from "../../../../store/slice";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {Typo} from "../../../../../../../shared/components/Typography";
import CloseIcon from '@material-ui/icons/Close';
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {controlInputType} from "../../../../types";
import React, {useEffect, useState} from "react";
import {AddControl} from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import {ROOT_BUILDER, ROOT_CONTROLS} from "../../../../constants";
import {useRouteMatch} from "react-router";
import {LoadingButton} from "../../../../../../../shared/components/Basic/CommonButtons";


export const AddControlDialog = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {isOpen} = useSelector(addControlDialog);
    const isLoadingCreate = useSelector(isLoading);
    const _selectedFramework = useSelector(selectedFramework);
    const isControlsPage = useRouteMatch<{controlId: string}>(ROOT_CONTROLS)?.isExact;

    const emptyControl: controlInputType = {
        name: '',
        group: '',
        category: '',
        description: [],
        id: null,
        origin: true,
        organizationId: null,
        visibility: null,
        frameworkId: _selectedFramework || '',
    }

    const [form, updateForm] = useState<controlInputType>(emptyControl);

    useEffect(() => {
        if(isOpen) updateForm(emptyControl);
        // eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideAddControl());
        updateForm(emptyControl);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleChangeDesc = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            description: [event.target.value as string],
        }));
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     updateForm((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

    const validateForm = (): boolean => {
        return(
            form.name.trim().length > 0 &&
            // form.group.trim().length > 0 &&
            // form.category.trim().length > 0 &&
            form.description.length > 0
        )
    }

    const onSave = () => {
        const onSuccess = (id: string) => history.push(ROOT_BUILDER + `/framework/${_selectedFramework}/control/${id}`);
        if(validateForm()) dispatch(AddControl({control: form, onSuccess: isControlsPage ? () => null : onSuccess}));
    }

    return(
        <Dialog
            maxWidth={'xl'}
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                    <Typo fontSize={'24px'} fontWeight={700} letterSpacing={'1.53px'} margin={'0 50px 0 0'}>{'Create Control'}</Typo>
                    <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
                </Flex>
            </DialogTitle>

            <DialogContent>
                <Flex w={'40vw'} direction={'column'} m={'20px 0 0 0'}>
                    <TextField id="filled-basic" label={'Name'} required variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={form.name} onChange={handleChange}/>
                    <TextField id="filled-basic" label={'Group'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'group'} value={form.group} onChange={handleChange}/>
                    <TextField id="filled-basic" label={'Category'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'category'} value={form.category} onChange={handleChange}/>
                    <TextField id="filled-basic" label={'Description'} required variant="outlined" multiline minRows={5} sx={{margin: '0 0 28px 0'}} value={form.description[0] || ''} onChange={handleChangeDesc}/>
                    <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                        {/*<RadioGroup row value={form.visibility} onChange={handleSelectVisibility}>*/}
                        {/*    <FormControlLabel value="PRIVATE" control={<Radio />} label={t("Private") as string} />*/}
                        {/*    <FormControlLabel value="PUBLIC" control={<Radio />} label={t("Public") as string} />*/}
                        {/*    <FormControlLabel value="RESTRICTED" control={<Radio />} label={t("Restricted") as string} />*/}
                        {/*</RadioGroup>*/}

                        {/* <ButtonWithBackround
                            width={'154px'}
                            height={'40px'}
                            color={validateForm() ? '#FFA000' : 'gray'}
                            text={'SAVE'}
                            fontColor={'white'}
                            fontSize={'16px'}
                            fontWeight={600}
                            onClick={onSave}
                        /> */}
                        <LoadingButton disabled={!validateForm()} loading={isLoadingCreate} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px', ml: 'auto'}} onClick={onSave}>
                            {'Save'}
                        </LoadingButton>
                    </Flex>
                </Flex>
            </DialogContent>
        </Dialog>
    )
}
