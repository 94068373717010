import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {creatableLicenseType, licenseInitialState} from "../types";
import {
    CreateAndReleaseOneLicense, CreateMultiLicense, CreateMultiLicenseWithRelease, CreateOneLicense,
    DeleteLicense,
    GetExtendedLicense,
    GetQuickStarts,
    GetShortLicenses,
    ReleaseLicense
} from "./actions";

export const initialState: licenseInitialState = {
    smallLicense: [],
    pageInfo: {
        page: 0,
        count: 5,
        total: 0,
        sort: ''
    },
    extendedLicense: null,
    wizards: [],

    creatableLicense: null,

    loading: {
        isLoadingSmallLicenses: false,
        isLoadingExtendedLicense: false,
        isLoadingWizards: false,
        isLoadingDelete: false,
        isLoadingRelease: false,
        isLoadingCreateAndReleaseOne: false,
        isLoadingCreateAndReleaseMulti: {
            isLoading: false,
            result: [],
        },
        isLoadingCreateOne: false,
        isLoadingCreateMulti: {
            isLoading: false,
            result: [],
        },
    },

    dialogs: {
        releaseLicense: {
            isOpen: false,
        },
        deleteLicense: {
            isOpen: false,
        },

        createOneRelease: {
            isOpen: false,
        },
        createMultiRelease: {
            isOpen: false,
        },
        createOneNoRelease: {
            isOpen: false,
        },
        createMultiNoRelease: {
            isOpen: false,
        },
    },
}

export const LicenseSLice = createSlice({
    name: 'License',
    initialState,
    reducers: {
        //CREATE
        createLicense: (state, {payload}: {payload: creatableLicenseType}) => {
            state.creatableLicense = payload;
            state.extendedLicense = null;
        },
        updateCreatableLicense: (state, {payload}: {payload: creatableLicenseType}) => {state.creatableLicense = payload},
        deleteCreatableLicense: (state) => {state.creatableLicense = null},
        //CREATE DIALOGS
        openCreateOneReleaseDialog: (state) => {state.dialogs.createOneRelease.isOpen = true},
        hideCreateOneReleaseDialog: (state) => {state.dialogs.createOneRelease.isOpen = false},

        openCreateMultiReleaseDialog: (state) => {state.dialogs.createMultiRelease.isOpen = true},
        hideCreateMultiReleaseDialog: (state) => {
            state.dialogs.createMultiRelease.isOpen = false;
            state.loading.isLoadingCreateAndReleaseMulti.result = [];
        },

        openCreateOneNoReleaseDialog: (state) => {state.dialogs.createOneNoRelease.isOpen = true},
        hideCreateOneNoReleaseDialog: (state) => {state.dialogs.createOneNoRelease.isOpen = false},

        openCreateMultiNoReleaseDialog: (state) => {state.dialogs.createMultiNoRelease.isOpen = true},
        hideCreateMultiNoReleaseDialog: (state) => {
            state.dialogs.createMultiNoRelease.isOpen = false;
            state.loading.isLoadingCreateMulti.result = [];
        },

        //UPDATE EXISTING DIALOGS
        openReleaseLicenseDialog: (state) => {state.dialogs.releaseLicense.isOpen = true},
        hideReleaseLicenseDialog: (state) => {state.dialogs.releaseLicense.isOpen = false},

        openDeleteLicenseDialog: (state) => {state.dialogs.deleteLicense.isOpen = true},
        hideDeleteLicenseDialog: (state) => {state.dialogs.deleteLicense.isOpen = false},

        cleanUp: () => initialState,
    },
    extraReducers: builder => {
        builder
        //GetShortLicenses
            .addCase(GetShortLicenses.pending, (slice) => {
                slice.loading.isLoadingSmallLicenses = true;
            })
            .addCase(GetShortLicenses.rejected, (slice) => {
                slice.loading.isLoadingSmallLicenses = false;
            })
            .addCase(GetShortLicenses.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingSmallLicenses = false;
                slice.smallLicense = payload.licenses;
                slice.pageInfo = payload.pageInfo;
            })
        //GetExtendedLicense
            .addCase(GetExtendedLicense.pending, (slice) => {
                slice.loading.isLoadingExtendedLicense = true;
                slice.creatableLicense = null;
            })
            .addCase(GetExtendedLicense.rejected, (slice) => {
                slice.loading.isLoadingExtendedLicense = false;
            })
            .addCase(GetExtendedLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingExtendedLicense = false;
                slice.extendedLicense = payload;
            })
        //GetQuickStarts
            .addCase(GetQuickStarts.pending, (slice) => {
                slice.loading.isLoadingWizards = true;
            })
            .addCase(GetQuickStarts.rejected, (slice) => {
                slice.loading.isLoadingWizards = false;
            })
            .addCase(GetQuickStarts.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingWizards = false;
                slice.wizards = payload;
            })
        //ReleaseLicense
            .addCase(ReleaseLicense.pending, (slice) => {
                slice.loading.isLoadingRelease = true;
            })
            .addCase(ReleaseLicense.rejected, (slice) => {
                slice.loading.isLoadingRelease = false;
            })
            .addCase(ReleaseLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingRelease = false;
                slice.dialogs.releaseLicense.isOpen = false;
                if(slice.extendedLicense){
                    slice.extendedLicense.issued = true;
                }
                slice.smallLicense = slice.smallLicense.map(e => e.id === payload ? {...e, issued: true} : e);
            })
        //DeleteLicense
            .addCase(DeleteLicense.pending, (slice) => {
                slice.loading.isLoadingDelete = true;
            })
            .addCase(DeleteLicense.rejected, (slice) => {
                slice.loading.isLoadingDelete = false;
            })
            .addCase(DeleteLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingDelete = false;
                slice.dialogs.deleteLicense.isOpen = false;
                if(slice.extendedLicense){
                    slice.extendedLicense = null;
                }
                slice.smallLicense = slice.smallLicense.filter(e => e.id !== payload);
            })
        //CreateAndReleaseOneLicense
            .addCase(CreateAndReleaseOneLicense.pending, (slice) => {
                slice.loading.isLoadingCreateAndReleaseOne = true;
            })
            .addCase(CreateAndReleaseOneLicense.rejected, (slice) => {
                slice.loading.isLoadingCreateAndReleaseOne = false;
            })
            .addCase(CreateAndReleaseOneLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingCreateAndReleaseOne = false;
                slice.dialogs.createOneRelease.isOpen = false;
                if(slice.creatableLicense){
                    slice.creatableLicense = null;
                }
                slice.extendedLicense = payload;
                slice.smallLicense = [
                    {
                        id: payload.id,
                        subscription: payload.subscription,
                        issued: true,
                        used: false,
                        createdDate: payload.createdDate,
                    }
                , ...slice.smallLicense];
            })
        //CreateOneLicense
            .addCase(CreateOneLicense.pending, (slice) => {
                slice.loading.isLoadingCreateOne = true;
            })
            .addCase(CreateOneLicense.rejected, (slice) => {
                slice.loading.isLoadingCreateOne = false;
            })
            .addCase(CreateOneLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingCreateOne = false;
                slice.dialogs.createOneNoRelease.isOpen = false;
                if(slice.creatableLicense){
                    slice.creatableLicense = null;
                }
                slice.extendedLicense = payload;
                slice.smallLicense = [
                    {
                        id: payload.id,
                        subscription: payload.subscription,
                        issued: false,
                        used: false,
                        createdDate: payload.createdDate,
                    }
                    , ...slice.smallLicense];
            })
        //CreateMultiLicenseWithRelease
            .addCase(CreateMultiLicenseWithRelease.pending, (slice) => {
                slice.loading.isLoadingCreateAndReleaseMulti.isLoading = true;
            })
            .addCase(CreateMultiLicenseWithRelease.rejected, (slice) => {
                slice.loading.isLoadingCreateAndReleaseMulti.isLoading = false;
            })
            .addCase(CreateMultiLicenseWithRelease.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingCreateAndReleaseMulti = {isLoading: false, result: payload};
            })
        //CreateMultiLicense
            .addCase(CreateMultiLicense.pending, (slice) => {
                slice.loading.isLoadingCreateMulti.isLoading = true;
            })
            .addCase(CreateMultiLicense.rejected, (slice) => {
                slice.loading.isLoadingCreateMulti.isLoading = false;
            })
            .addCase(CreateMultiLicense.fulfilled, (slice, {payload}) => {
                slice.loading.isLoadingCreateMulti = {isLoading: false, result: payload};
            })
    }
});

export const LicenseReducer = LicenseSLice.reducer;

export const {
    //CREATE
    createLicense,
    updateCreatableLicense,
    deleteCreatableLicense,
    //CREATE DIALOGS
    openCreateOneReleaseDialog,
    hideCreateOneReleaseDialog,

    openCreateMultiReleaseDialog,
    hideCreateMultiReleaseDialog,

    openCreateOneNoReleaseDialog,
    hideCreateOneNoReleaseDialog,

    openCreateMultiNoReleaseDialog,
    hideCreateMultiNoReleaseDialog,
    //UPDATE EXISTING DIALOGS
    openReleaseLicenseDialog,
    hideReleaseLicenseDialog,

    openDeleteLicenseDialog,
    hideDeleteLicenseDialog,

    cleanUp,
} = LicenseSLice.actions;

const selectSelf = (state: AppState):licenseInitialState => state.License as licenseInitialState;


export const loading = createSelector(selectSelf, state => state.loading);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const extendedLicense = createSelector(selectSelf, state => state.extendedLicense);
export const smallLicenses = createSelector(selectSelf, state => state.smallLicense);
export const creatableLicense = createSelector(selectSelf, state => state.creatableLicense);
export const wizards = createSelector(selectSelf, state => state.wizards);
