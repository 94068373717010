import {store} from "../redux";
import {addInfoSnack} from "../../domain/BarsENV/snack/store/slice";

export const makeFirstLetterUpper = (text: string) => text.split('').map((e, id) => id === 0 ? e.toUpperCase() : e.toLowerCase()).join('');

export const copyTextToClipBoard = (text: string, snack?: string) => {
    navigator.clipboard.writeText(text);
    snack && store.dispatch(addInfoSnack(snack));
}

export function validateNotEmpty(str: string): boolean {
    return str.trim().length > 0;
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validatePassword(password: string): boolean {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return re.test(password);
}

export const getName = (firstName: string, lastName: string, middleName?: string): string => {
    if (middleName) {
        return `${firstName ?? ''} ${middleName?.trim() ?? ''} ${lastName ?? ''}`.split(' ').filter(e => e !== '').join(' ');
    } else {
        return `${firstName ?? ''} ${lastName ?? ''}`.split(' ').filter(e => e !== '').join(' ');
    }
};

export const toInitials = (str?: string | null): string => {
    return (str ?? 'N N').split(' ')
        .filter(x => x !== '')
        .filter((_ , index)  => index < 2 )
        .map(x => x.charAt(0).toUpperCase())
        .join('')
}

export const getAvatarInitials = (name: string) => {
    if(!name.length) return '';
    if(name.length === 1) return name;
    name = name.replaceAll('  ', ' '); //replacing double space to single space
    let t = name.split(' ');
    if(t.length > 1){
        //abra kadabra -> A K
        return `${t[0].split('')[0].toUpperCase()}${t[1].split('')[0].toUpperCase()}`;
    }else{
        //abrakadabra -> A B
        return `${name.split('')[0].toUpperCase()}${name.split('')[1].toUpperCase()}`;
    }
}

export function downloadAsTXT(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const shortString = (text: string, howMuch: number) => text.split('').length >= howMuch ? text.split('').slice(0, howMuch).concat('.', '.', '.').join('') : text;