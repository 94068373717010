import {useDispatch, useSelector} from "react-redux";
import {dialogs, loading, hideChangeWSAccountMappingDialog} from "../../../store/slice";
import {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Autocomplete} from "@mui/lab";
import {UpdateWorkspaceAccountMappingConfig} from "../../../store/actions";

export const UpdateAccountMappingDialog = () => {
    type accountMappingConfigType = 'EMAIL' | 'FIRST_NAME_AND_LAST_NAME' | 'FIRST_NAME_AND_LAST_NAME_AND_EMAIL' | 'FIRST_NAME_AND_LAST_NAME_OR_EMAIL';

    const dispatch = useDispatch();
    const {isLoadingChangeWorkspaceAccountMapping: {isLoading}} = useSelector(loading);
    const {changeWorkspaceAccountMappingDialog : {isOpen, prevConfig, workspaceId}} = useSelector(dialogs);
    const [config, setConfig] = useState<accountMappingConfigType>('EMAIL');
    const values: accountMappingConfigType[] = ['EMAIL', 'FIRST_NAME_AND_LAST_NAME', 'FIRST_NAME_AND_LAST_NAME_AND_EMAIL', 'FIRST_NAME_AND_LAST_NAME_OR_EMAIL'];

    const handleClose = () => {
        dispatch(hideChangeWSAccountMappingDialog());
    }

    const handleSubmit = () => {
        if(prevConfig && workspaceId && prevConfig.accountsMappingSchema !== config){
            dispatch(UpdateWorkspaceAccountMappingConfig({id: workspaceId, newValue: config}));
        }
    }

    useEffect(() => {
        if(isOpen && prevConfig){
            setConfig(prevConfig.accountsMappingSchema as accountMappingConfigType);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const getNormalizedConfig = (value: accountMappingConfigType): string => {
        switch (value){
            case 'EMAIL': return 'Email';
            case 'FIRST_NAME_AND_LAST_NAME': return 'First Name & Last Name';
            case 'FIRST_NAME_AND_LAST_NAME_AND_EMAIL': return 'First Name & Last Name & Email';
            case 'FIRST_NAME_AND_LAST_NAME_OR_EMAIL': return 'First Name & Last Name | Email';
            default: return 'Value not found in getNormalizedConfig';
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle>Update Account Mapping Scheme</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{marginTop: '15px'}}
                    autoComplete
                    fullWidth
                    openOnFocus
                    id="combo-box-demo"
                    options={values}
                    value={config}
                    onChange={(e, value) => {value && setConfig(value)}}
                    getOptionLabel={getNormalizedConfig}
                    renderInput={(params) => <TextField {...params} label="Config" />}
                />
            </DialogContent>
            {isLoading ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!prevConfig || !workspaceId || prevConfig.accountsMappingSchema === config} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}