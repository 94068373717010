import {FC, useCallback, useEffect, useState} from "react";
import {Box, Button, Divider, Icon, IconButton, TextField, Typography} from "@mui/material";
import {useVicaTasks} from "../../hooks/useVicaTasks";
import {Item, TCategoryWithTasks, TTask, TTaskOrder} from "../../types";
import colors from "../../../../../shared/theme/colors";
import {LoadingButton, Skeleton} from "@mui/lab";
import {Add} from "@mui/icons-material";
import {DeleteDialogButton} from "../../../../../shared/components/deleteDialogButton";
import {useOpenDialog} from "../../../../../shared/hooks/useOpenDialog";
import EditIcon from "@material-ui/icons/Edit";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";


export const ItemTypes = {
    CARD: 'card',
}

export const SelectedVicaCategory: FC = () => {

    const {
        categories: {order},
        getTasksByCategoryId,
        selectedCategoryId, setSelectedTask,
        selectedCategory, isLoading: {isLoadingTasks, isEditingCategory, isDeletingCategory},
        createCategory, updateCategory, deleteCategory,
    } = useVicaTasks();

    const isNew = selectedCategoryId === 'new';
    const [edit, setEdit] = useState<TCategoryWithTasks & {order: number} | null>(isNew ? {id: 'new', tasks: [], name: '', description: '', order: -1} : null);
    const [tasks, _setTasks] = useState<TTask[]>([]);

    useEffect(() => {
        if (isNew) {
            setEdit({id: 'new', tasks: [], name: '', description: '', order: -1});
        } else {
            selectedCategoryId && (selectedCategory?.tasks.length || 0) === 0 && getTasksByCategoryId(selectedCategoryId);
        }

        // eslint-disable-next-line
    }, [selectedCategoryId])
    useEffect(() => {
        setEdit(isNew ? {id: 'new', tasks: [], name: '', description: '', order: -1} : null);

        // eslint-disable-next-line
    }, [selectedCategory]);

    useEffect(() => {
        selectedCategory && _setTasks(selectedCategory.tasks)
        //eslint-disable-next-line
    }, [selectedCategory]);

    // const saveRequired = JSON.stringify(edit) !== JSON.stringify(selectedCategory);

    const handleSave = () => {
        if (!edit) return;

        if (isNew) {
            const orders: TTaskOrder[] = [...order, {id: 'new', name: edit.name, order: edit.order - 0.5}]
                .filter(e => e.order > -1)
                .sort((a, b) => a.order - b.order)
                .map((e, i) => ({...e, order: i}));

            createCategory(edit, orders);
        } else {
            let tmp: TTaskOrder[] = JSON.parse(JSON.stringify(order));

            const oldOrder = tmp.find(e => e.id === edit.id)?.order ?? -2;
            if (oldOrder > -2) {
                tmp = tmp.map(e => e.id === edit.id ? {id: edit.id, name: edit.name, order: edit.order + (edit.order > oldOrder ? 0.5 : -0.5)} : e)
            }
            else {
                tmp.push({id: edit.id, name: edit.name, order: edit.order - 0.5})
            }

            const orders: TTaskOrder[] = tmp
                .filter(e => e.order > -1)
                .sort((a, b) => a.order - b.order)
                .map((e, i) => ({...e, order: i}));

            const isChangedTasksOrder = JSON.stringify(tasks) !== JSON.stringify(selectedCategory?.tasks);

            updateCategory(edit, orders, isChangedTasksOrder ? tasks : undefined);
        }
    };

    const setTasks = useCallback(
        (cols: TTask[]) => {
            _setTasks(cols.map((e,i) => ({...e, order: i})));
        }, []
    )

    const {isOpen, openDialog, closeDialog, dialogData} = useOpenDialog<string>();
    //dnd
    const findColumn: (id: string) => {column: TTask, index: number} | null = useCallback(
        (id: string) => {
            const column = tasks.find(e => e.id === id);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: tasks.indexOf(column)
            } : null;
        },
        [tasks],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(tasks));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setTasks(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, tasks, setTasks],
    )
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

    return (
        <Box display={"flex"} flexDirection={'column'} flexGrow={1} height={'100%'} overflow={'hidden'} p={'10px'} gap={'8px'}>
            <Box display={"flex"} width={'100%'} alignItems={"start"} gap={'14px'} p={'10px'} justifyContent={'space-between'}>
                {!edit ? (
                    <>
                        <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={'12px'} maxWidth={'800px'}>
                            <Typography fontWeight={600} fontSize={'28px'}>{selectedCategory?.name}</Typography>
                            <Typography fontWeight={400} fontSize={'18px'}>{selectedCategory?.description}</Typography>
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} gap={'14px'}>
                            <Button variant={"contained"} size={"large"} sx={{mt: '5px'}} disabled={isDeletingCategory}
                                    onClick={() => setEdit(selectedCategory ? {
                                        ...selectedCategory,
                                        order: order.find(e => e.id === selectedCategoryId)?.order ?? -1
                                    } : null)}>Edit</Button>

                            <LoadingButton loading={isDeletingCategory} variant={"outlined"} size={"large"} color={"error"} onClick={selectedCategoryId ? () => {openDialog(selectedCategoryId)} : () => {}}>Delete</LoadingButton>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={'14px'} maxWidth={'800px'}>
                            <TextField label={'Name'} error={edit.name.trim().length === 0}
                                       value={edit.name} onChange={e => setEdit(prevState => ({...prevState!, name: e.target.value}))}/>
                            <TextField label={'Description'}
                                       value={edit.description} onChange={e => setEdit(prevState => ({...prevState!, description: e.target.value}))}
                                       multiline minRows={2}/>
                            <TextField label={'Order'} type={'number'} onChange={e => setEdit(prevState => ({...prevState!, order: parseInt(e.target.value)}))}
                                       InputProps={{inputProps: {min: -1, max: order.length}}} value={edit.order}/>
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} gap={'14px'}>
                            <LoadingButton variant={"contained"} size={"large"} onClick={handleSave} loading={isEditingCategory}>Save</LoadingButton>
                            <Button variant={"outlined"}  size={"large"} onClick={selectedCategory ? () => {setEdit(null)} : () => {setSelectedTask(null)}}>Cancel</Button>
                        </Box>
                    </>
                )}
            </Box>

            <Divider flexItem orientation={'horizontal'} sx={{borderBottom: '2px solid ' + colors.yellow}}/>

            <Box display={"flex"} flexDirection={"column"} flexGrow={1} overflow={'auto'} gap={'8px'}>
                {isLoadingTasks.includes(selectedCategoryId || '') && (
                    <>
                        <Box display={"flex"} flexDirection={"column"} gap={'8px'} p={'10px'} sx={{border: '1px solid ' + colors.grey, borderRadius: '8px'}}>
                            <Typography fontWeight={600} fontSize={'18px'}><Skeleton width={'200px'}/></Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={'8px'} p={'10px'} sx={{border: '1px solid ' + colors.grey, borderRadius: '8px'}}>
                            <Typography fontWeight={600} fontSize={'18px'}><Skeleton width={'200px'}/></Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={'8px'} p={'10px'} sx={{border: '1px solid ' + colors.grey, borderRadius: '8px'}}>
                            <Typography fontWeight={600} fontSize={'18px'}><Skeleton width={'200px'}/></Typography>
                        </Box>
                    </>
                )}
                {!isLoadingTasks.includes(selectedCategoryId || '') && selectedCategory && (
                    <>
                        <Box onClick={() => setSelectedTask(selectedCategoryId, 'new')}
                             display={"flex"} alignItems={"center"} gap={'8px'} p={'10px'} sx={{border: '1px dashed ' + colors.grey, borderRadius: '8px', cursor: 'pointer'}}>
                            <Icon><Add/></Icon>
                            <Typography fontWeight={600} fontSize={'18px'} sx={{cursor: 'inherit'}}>Add new Task</Typography>
                        </Box>
                    </>
                )}

                {edit ?
                    <div ref={drop}>
                        {tasks.slice()
                            .sort((a, b) => (a.order ?? 999999) - (b.order ?? -1))
                            .map(e => (
                                <ColumnDraggableRow key={e.id}
                                                    task={e}
                                                    selectedCategoryId={selectedCategoryId}
                                                    handleOpenEdit={setSelectedTask}
                                                    disabled={tasks.length < 2}
                                                    moveColumn={moveColumn}
                                                    findColumn={findColumn}
                                />
                            ))}
                    </div>
                    :
                    <>
                        {selectedCategory?.tasks.slice()
                            .sort((a, b) => (a.order ?? 999999) - (b.order ?? -1))
                            .map(task => (
                                <Box onClick={() => setSelectedTask(selectedCategoryId, task.id)}
                                     key={task.id} display={"flex"} alignItems={"center"} gap={'8px'} p={'0 15px'}
                                     sx={{
                                         border: '1px solid ' + colors.grey, borderRadius: '8px',
                                         '&:hover': {cursor: 'pointer'}
                                     }}>
                                    <Typography fontWeight={600} fontSize={'18px'}>{task.id}</Typography>
                                    <Typography fontWeight={600} fontSize={'18px'} sx={{ml: '12px'}} >{task.name}</Typography>
                                    <IconButton onClick={() => selectedCategoryId && setSelectedTask(selectedCategoryId, task.id)} sx={{ml: 'auto'}}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            ))
                        }
                    </>
                }

            </Box>

            <DeleteDialogButton isOpen={isOpen} handleClose={closeDialog} isLoading={isDeletingCategory}
                                title={'Delete category'} message={'Are you sure you want to delete this category?'}
                                handleConfirm={() => dialogData && deleteCategory(dialogData)}/>
        </Box>
    );
}


export const ColumnDraggableRow = (
    {task, selectedCategoryId, handleOpenEdit, disabled, findColumn, moveColumn}: {
        task: TTask,
        selectedCategoryId: string | null,
        handleOpenEdit: (selectedCategoryId: string, taskId: string) => void,
        disabled: boolean,
        moveColumn: (key: string, atIndex: number) => void,
        findColumn: (key: string) => {column: TTask, index: number} | null
    }) => {
    const searchedColumn = findColumn(task.id);
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: task.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [task.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== task.id) {
                    const col = findColumn(task.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    )

    const opacity = isDragging ? 0 : 1

    // console.log(`
    //     key: ${column.key} \n
    //     isDragging: ${isDragging} \n
    //     isOverCurrent: ${isOverCurrent} \n
    //     isOver: ${isOver} \n
    //     isActive: ${isActive}
    // `)
    return(
        <Box display={'flex'}
             ref={(node: ConnectableElement) => drag(drop(node))}
             alignItems={'center'}
             gap={'8px'}
             sx={{
                 border: '1px solid ' + colors.grey,
                 borderRadius: '8px',
                 pr: '10px',
                 background: colors.backgrounds.blue_light_3,
                 opacity: opacity.toString(),
                 '&:hover': {cursor: 'pointer'}
             }}
            marginBottom={'10px'}
        >
            <IconButton>
                <DragIndicatorIcon />
            </IconButton>
            <Typography fontWeight={600} fontSize={'18px'}>{task.id}</Typography>
            <Typography fontWeight={600} fontSize={'18px'} sx={{ml: '12px'}} >{task.name}</Typography>

            <IconButton onClick={() => selectedCategoryId && handleOpenEdit(selectedCategoryId, task.id)} sx={{ml: 'auto'}} disabled={disabled}>
                <EditIcon />
            </IconButton>
        </Box>
    )
}
