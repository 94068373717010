import {useControls} from "../../hooks/useControls";
import {BuilderControlsTable} from "../../components/builderControlsTable";
import {BuilderEvidencesTable} from "../../components/builderEvidencesTable";
import {Flex} from "../../../../../shared/components/Layouts";
import {AddControlDialog} from "../../components/dialogs/controls/addControl";
import {UpdateControlDialog} from "../../components/dialogs/controls/updateControl";
import {SelectExistingControl} from "../../components/dialogs/controls/selectExistingControl";
import {AddEvidenceDialog} from "../../components/dialogs/evidences/addEvidence";
import {UpdateEvidenceDialog} from "../../components/dialogs/evidences/updateEvidence";
import {DetachEvidenceDialog} from "../../components/dialogs/evidences/detachEvidence";
import {SelectExistingEvidence} from "../../components/dialogs/evidences/selectExistingEvidence";
import {Spinner} from "../../../../../shared/components/Spinner";
import {DeleteControlDialog} from "../../components/dialogs/controls/deleteControl";

export const Controls = () => {
    const {isLoading, isLoadingDialogs} = useControls();

    return(
        <Flex w={'100%'} h={'100%'} jc={'space-between'} m={'10px 0 0 0'}>
            <BuilderControlsTable/>
            <BuilderEvidencesTable/>


            {/*DIALOGS*/}
            <DeleteControlDialog />
            <AddControlDialog />
            <UpdateControlDialog />
            <SelectExistingControl />
            <AddEvidenceDialog />
            <UpdateEvidenceDialog />
            <DetachEvidenceDialog />
            <SelectExistingEvidence />
            {(isLoading || isLoadingDialogs) && <Spinner />}
        </Flex>
    )
}