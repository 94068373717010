import {useDispatch, useSelector} from "react-redux";
import {dialogs, loading, hideChangeWSProfileMappingDialog} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, Divider, IconButton} from "@mui/material";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {workspaceConfigProfileMappingType} from "../../../types";
import { Flex } from "../../../../../../shared/components/Layouts";
import {Typo} from "../../../../../../shared/components/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {CustomInput} from "../../../../../../shared/components/Inputs";
import {ChipItem} from "../../../../../../shared/components/ChipItem";
import {copyTextToClipBoard} from "../../../../../AuthWorkspaceAndCookies/newWorkspaces/helpers";
import {UpdateWorkspaceProfileMappingConfig} from "../../../store/actions";

export const UpdateProfileMappingDialog = () => {
    const dispatch = useDispatch();
    const {isLoadingChangeWorkspaceProfileMapping: {isLoading}} = useSelector(loading);
    const {changeWorkspaceProfileMappingDialog : {isOpen, prevConfig, workspaceId}} = useSelector(dialogs);
    const [config, setConfig] = useState<workspaceConfigProfileMappingType[]>([]);
    const [addableConfig, setAddableConfig] = useState<{name: string, values: string}>({name: '', values: ''});

    const handleClose = () => {
        dispatch(hideChangeWSProfileMappingDialog());
        setAddableConfig({name: '', values: ''});
        setConfig([]);
    }

    const handleSubmit = () => {
        if(prevConfig && workspaceId && !isEqual()){
            dispatch(UpdateWorkspaceProfileMappingConfig({id: workspaceId, newValue: config}));
            setAddableConfig({name: '', values: ''});
            setConfig([]);
        }
    }

    const isEqual = () => {
        return JSON.stringify(config) === JSON.stringify(prevConfig);
    }

    useEffect(() => {

        if(isOpen && prevConfig){
            console.log(JSON.stringify(prevConfig));
            setConfig(prevConfig.profilesMappingSchema);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleDeleteItem = (name: string) => {
        setConfig([...config.filter(e => e.appName !== name)]);
    }

    const handleAddScheme = () => {
        if(addableConfig.name.trim().length > 0 && addableConfig.values.trim().length > 0 && !config.some(e => e.appName === addableConfig.name)){
            let values = addableConfig.values.split(', ');
            values = values.map(e => e.endsWith(',') ? e.substring(0, e.length - 2) : e);
            setConfig([...config, {appName: addableConfig.name, groupsName: values}]);
            setAddableConfig({name: '', values: ''});
        }
    }

    const handleDeleteGroup = (appName: string, group: string) => {
        setConfig([...config.map(e => e.appName === appName ? {...e, groupsName: [...e.groupsName.filter(g => g !== group)]} : e)]);
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>Update Profile Mapping Scheme</DialogTitle>

            <DialogContent>
                <Flex w={'100%'} ai={'center'}>
                    <Flex w={'40%'}>
                        <Typo>App name</Typo>
                    </Flex>

                    <Flex w={'50%'}>
                        <Typo>Group names</Typo>
                    </Flex>
                </Flex>
                
                <Divider flexItem style={{borderColor: '#FAC000', marginTop: '15px'}}/>

                {config.map((e) => {
                    return(
                        <>
                            <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'10px 0 0 0'}>
                                <Flex w={'30%'}>
                                    <Typo>{e.appName}</Typo>
                                </Flex>

                                <Flex br={'4px'} w={'50%'} wrap={'wrap'} hoverStyles={'background-color: #c0c0c0;'} onClick={() => copyTextToClipBoard(e.groupsName.join(', '), 'Group names copied!')}>
                                    {e.groupsName.map(g => <ChipItem label={g} onDelete={e.groupsName.length > 1 ? () => handleDeleteGroup(e.appName, g) : undefined} />)}
                                    {/*<Typo>{e.groupsName.join(', ')}</Typo>*/}
                                </Flex>

                                <IconButton onClick={() => handleDeleteItem(e.appName)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Flex>

                            <Divider flexItem style={{borderColor: '#FAC000'}}/>
                        </>
                    )
                })}

                <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'15px 0 0 0'}>
                    <CustomInput
                        value={addableConfig.name}
                        onChange={(event) => {setAddableConfig({...addableConfig, name: event.target.value as string})}}
                        label={'App Name'}
                    />

                    <CustomInput
                        value={addableConfig.values}
                        onChange={(event) => {setAddableConfig({...addableConfig, values: event.target.value as string})}}
                        label={'Values (separated with `, `)'}
                    />

                    <IconButton onClick={() => handleAddScheme()}>
                        <AddCircleIcon />
                    </IconButton>
                </Flex>
            </DialogContent>

            {isLoading ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!prevConfig || !workspaceId || isEqual()} color={'success'}>Update</Button>
                </DialogActions>
            }
        </Dialog>
    )
}