import {Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import { Flex } from "../../../../../../shared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import {useEditPermission} from "../../../hooks/dialogs/useEditPermission";

export const EditPermissionDialog = () => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        isOk,

        form,
        handleChange,
        handleAddTag,
        handleDeleteTag,
        _permissions,
        handleAddAutoCompletedAddTag
    } = useEditPermission();

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth scroll={'body'}>
            <DialogTitle>{(`Edit ${form.name} permission`)}</DialogTitle>

            <DialogContent>
                <TextField
                    value={form.name}
                    name={'name'}
                    onChange={handleChange}
                    placeholder={('Name')}
                    label={'Permission name'}
                    // size={'small'}
                    sx={{margin: '8px 0 0 0'}}
                    fullWidth
                    disabled
                />

                <Flex w={'100%'} jc={'space-between'} m={'16px 0 0 0'}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={Array.from(new Set([..._permissions.flatMap(e => e.tags)]))} //getting already used tags
                        value={form.autocompleteInput}
                        onChange={(e, newValue: string | null) => newValue && handleAddAutoCompletedAddTag(newValue)}
                        sx={{ width: '70%' }}
                        renderInput={(params) => <TextField {...params} label={("Tag or csv")} size={'small'} onChange={handleChange} name={'autocompleteInput'}/>}
                    />

                    <Button
                        variant={'text'}
                        startIcon={<AddIcon fontSize="small"/>}
                        disabled={!form.autocompleteInput.trim().length}
                        onClick={handleAddTag}
                        sx={{width: '30%'}}
                    >{('Add tag')}</Button>
                </Flex>

                <Flex w={'100%'} wrap={'wrap'} m={'16px 0 0 0'}>
                    {form.tags.map(e => <Chip
                        label={e}
                        key={e}
                        onDelete={() => handleDeleteTag(e)}
                        sx={{lineHeight: 'normal', margin: '8px'}}
                        />
                    )}
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: '0 16px 0 0'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    color={'success'}
                    disabled={!isOk}
                >
                    {('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}