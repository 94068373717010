//core
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {addFrameworkDialog, frameworks, hideAddFramework} from "../../../../store/slice";

//material
import {Avatar, Button, Dialog, DialogActions, DialogContent,} from "@mui/material";
import {frameworkInputType} from "../../../../types";
import {Typo} from "../../../../../../../shared/components/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {UploadFileBTN} from "../../../../../../../shared/components/FileBTN/UploadFileBTN";
import {toBase64} from "../../../../../../../shared/utils/base64";
import {addErrorSnack} from "../../../../../../BarsENV/snack/store/slice";
import {LOGO_MAX_SIZE, ROOT_BUILDER} from "../../../../constants";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import {AddFramework} from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import {CustomInput, TextArea} from "../../../../../../../shared/components/Inputs";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

//types
const initialFramework: frameworkInputType = {
    id: '',
    name: '',
    description: '',
    logo: '',
    origin: false,
    type: '',
    visibility: null
};

export const AddFrameworkDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const dispatch = useDispatch();
    const history = useHistory();
    const _frameworks = useSelector(frameworks);
    const {isOpen} = useSelector(addFrameworkDialog);

    const [framework, setFramework] = useState<frameworkInputType>(initialFramework);

    useEffect(() => {
        if(!isOpen) setFramework(initialFramework);
    }, [isOpen]);

    const handleSelect = () => {
        if(validate()){
            const onSuccess = (id: string) => history.push(ROOT_BUILDER + `/framework/${id}`);
            dispatch(AddFramework({framework, onSuccess}));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideAddFramework());
        }
    };

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFramework((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const validate = (): boolean => {
        return (
            framework.id.trim().length > 0 &&
            !_frameworks.some(f => f.id.toLowerCase() === framework.id.toLowerCase()) &&
            framework.name.trim().length > 0 &&
            framework.logo.trim().length > 0 &&
            framework.description.trim().length > 0 &&
            framework.type.trim().length > 0
        )
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     setFramework((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

     const setLogo = async(file: any) => {
        if(file && file.size > LOGO_MAX_SIZE){
            dispatch(addErrorSnack(t('Logo is too big!')));
            return;
        }
         const fileBase64: string = await toBase64(file) as string;
         setFramework((prev) => {return {...prev, logo: fileBase64}});
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'} style={{maxHeight: '700px', margin: '30px 0 0 0'}}>
            <Flex w={'100%'} jc={'space-between'} ai={'center'} p={'20px 20px 0 20px'}>
                <Typo fontSize={'24px'} fontWeight={700} letterSpacing={'1.53px'}>{t('Add Framework')}</Typo>
                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
            </Flex>
            <DialogContent style={{width: '30vw'}}>
                {/* <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="filled"
                    value={framework.name}
                    name={'name'}
                    onChange={handleChange}
                /> */}
                <CustomInput
                    margin="dense"
                    id="id"
                    label={t("ID")}
                    type="text"
                    value={framework.id}
                    name={'id'}
                    onChange={handleChange}
                />

                <CustomInput
                    margin="dense"
                    id="name"
                    label={t("Name")}
                    type="text"
                    value={framework.name}
                    name={'name'}
                    onChange={handleChange}
                />

                {/* <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Type"
                    type="text"
                    fullWidth
                    variant="filled"
                    value={framework.type}
                    name={'type'}
                    onChange={handleChange}
                /> */}
                <CustomInput
                    margin="dense"
                    id="name"
                    label={t("Type")}
                    type="text"
                    value={framework.type}
                    name={'type'}
                    onChange={handleChange}
                />

                {/* <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="filled"
                    value={framework.description}
                    name={'description'}
                    minRows={3}
                    multiline
                    onChange={handleChange}
                /> */}
                <TextArea
                    margin="dense"
                    id="name"
                    label={t("Description")}
                    type="text"
                    value={framework.description}
                    name={'description'}
                    onChange={handleChange}
                    rows={5}
                />

                {/*<FormControl style={{margin: '15px 0 0 0'}}>*/}
                {/*    <FormLabel id="demo-radio-buttons-group-label">{t('Framework visibility')}</FormLabel>*/}
                {/*    <RadioGroup row value={framework.visibility} onChange={handleSelectVisibility}>*/}
                {/*        <FormControlLabel value="PRIVATE" control={<Radio />} label={t("Private") as string || ''} />*/}
                {/*        <FormControlLabel value="PUBLIC" control={<Radio />} label={t("Public") as string || ''} />*/}
                {/*        <FormControlLabel value="RESTRICTED" control={<Radio />} label={t("Restricted") as string || ''} />*/}
                {/*    </RadioGroup>*/}
                {/*</FormControl>*/}

                <Typo fontSize={'1rem'} fontWeight={500} color={'#878BA1'} margin={'10px 0 15px 0'}>{t('Logo (rectangle image up to 1mb)')} </Typo>
                <Flex w={'50%'} jc={'space-between'} ai={'center'}>
                    <Avatar
                        src={(framework.logo.length > 0) ? framework.logo : undefined}
                        sx={{width: '64px', height: '64px', margin: !revDir ? '0 15px 0 0 ' : '0 0 0 15px'}}
                        children={<CropOriginalIcon style={{width: '32px', height: '32px'}}/>}
                    />
                    <UploadFileBTN accept={'image/*'} id={'frameLogo'} onChange={(e) => {
                        setLogo(e.currentTarget.files![0]);
                    }}/>
                </Flex>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleSelect} disabled={!validate()}>{t('SAVE')}</Button>
            </DialogActions>
        </Dialog>
    );
}