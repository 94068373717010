// 72 dpi (web) = 595 X 842 pixels
import {
    EditorBlockItemType,
    EditorFillableFieldItemType,
    TEditorFillableFieldItemType,
    TEditorPageElementData,
    TPageOrientation,
    TPageSizeTitle
} from "./types";
import {
    CommentOutlined,
    DataObjectOutlined,
    History,
    InsertPageBreakOutlined,
    List,
    PhotoSizeSelectActualOutlined,
    SvgIconComponent,
    TableChartOutlined,
    TaskOutlined,
    Title,
    WidgetsOutlined,
    WifiChannelOutlined
} from "@mui/icons-material";
import {EditorSideMenuState} from "./components/editorSideMenu";
import {TabTypeMap} from "@mui/material/Tab/Tab";

export const changeVariableEventName = 'changeVariable';
export const setActiveBlockEventName = 'setActiveBlock';
export const setForceHighlightedEventName = 'startResizingBlocks';
export const setHighlightedVariableEventName = 'setHighlightedVariable';
export const addFillableFieldVariableEventName = 'addFillableFieldVariable';
export const setPagePropertiesMenuVariableEventName = 'setPagePropertiesMenuVariable';
export const changeVariableByTextComponentEventName = 'changeVariableEventNameByTextComponent';
export const setListElementActiveEventName = 'setListElementActive';
export const deleteVariableEventName = 'deleteVariable';
export const openManageActorsDialogEventName = 'openManageActorsDialog';
export const openManageEditorsDialogEventName = 'openManageEditorsDialog';
export const updateRecipientEventName = 'updateRecipient';
export const unfocusAllBlocksEventName = 'unfocusAllBlocks';
export const blockCreatedByDropEventName = 'blockCreatedByDrop';
export const needUpdateActorsEventName = 'needUpdateActors';
export const updateDataInFillableFieldEventName = 'updateDataInFillableField';
export const commonDialogOpenEventName = 'commonDialogOpen';
export const updateEditorsEventName = 'updateEditors';
export const deleteBlockEventName = 'deleteBlock';
export const updateVariableUsagesEventName = 'updateVariableUsages';

export const DPI = 72;
export const PAGE_SIZES: Record<TPageSizeTitle, [number, number]> = {
    // 'Letter': [8.5, 11],
    // 'A2': [1587, 2245],
    // 'A3': [11.69, 16.54],
    'A4': [8.27, 11.69],
    // 'A5': [559, 794],
    // 'A6': [397, 559],
    // 'A7': [280, 397],
    // 'A8': [197, 280],
};
export const DEFAULT_PAGE_MARGINS = {
    top: 0.4,
    bottom: 0.4,
    left: 0.6,
    right: 0.6,
};

export const getScalesByOrientation = (sizeTitle: TPageSizeTitle, orientation: TPageOrientation) => {
    const width = orientation === 'portrait' ? PAGE_SIZES[sizeTitle][0] * DPI : PAGE_SIZES[sizeTitle][1] * DPI;
    const height = orientation === 'portrait' ? PAGE_SIZES[sizeTitle][1] * DPI : PAGE_SIZES[sizeTitle][0] * DPI;

    return {width, height}
};

/////////
export const DEFAULT_PAGE_DATA: TEditorPageElementData["data"] = {
    orientation: 'portrait',
    size: 'A4',

    content: [],
    footer: [],
    header: [],

    headerHeight: 0,
    footerHeight: 0,
    marginTop: DEFAULT_PAGE_MARGINS.top * DPI,
    marginBottom: DEFAULT_PAGE_MARGINS.bottom * DPI,
    marginLeft: DEFAULT_PAGE_MARGINS.left * DPI,
    marginRight: DEFAULT_PAGE_MARGINS.right * DPI,
};

////////
export const editorBlocks: {title: string, type: string, icon: SvgIconComponent}[] = [
    {
        type: EditorBlockItemType.TEXT,
        title: 'Text',
        icon: Title
    },
    {
        type: EditorBlockItemType.LIST,
        title: 'List',
        icon: List
    },
    {
        type: EditorBlockItemType.IMAGE,
        title: 'Image',
        icon: PhotoSizeSelectActualOutlined
    },
    {
        type: EditorBlockItemType.TABLE,
        title: 'Table',
        icon: TableChartOutlined
    },
    {
        type: EditorBlockItemType.PAGEBREAK,
        title: 'Page break',
        icon: InsertPageBreakOutlined
    },
];

export const editorFillableFields: {title: string, type: TEditorFillableFieldItemType[keyof TEditorFillableFieldItemType], icon: SvgIconComponent, maybeNotRequired: boolean}[] = [
    {
        type: EditorFillableFieldItemType.TEXT,
        title: 'Text',
        icon: Title,
        maybeNotRequired: true,
    },
    {
        type: EditorFillableFieldItemType.SIGN,
        title: 'Signature',
        icon: WifiChannelOutlined,
        maybeNotRequired: false,
    },
    // {
    //     type: EditorFillableFieldItemType.DATE,
    //     title: 'Date',
    //     icon: CalendarTodayOutlined
    // },
    // {
    //     type: EditorFillableFieldItemType.CHECKBOX,
    //     title: 'Checkbox',
    //     icon: CheckBoxOutlined,
    // },
    // {
    //     type: EditorFillableFieldItemType.RADIO,
    //     title: 'Radio button',
    //     icon: RadioButtonCheckedOutlined,
    // },
    // {
    //     type: EditorFillableFieldItemType.DROPDOWN,
    //     title: 'Dropdown',
    //     icon: ArrowDropDownOutlined,
    // },
];

////////
export const editorSideMenuTabs: {
    tab: EditorSideMenuState['tab'],
    title: string,
    icon: TabTypeMap['props']['icon'],
    disabled?: boolean,

    disabledForReadOnly: boolean,
    disabledForPdf: boolean,
    disabledForVerify: boolean,
    showOnlyOnVerify?: boolean,
    disabledForAdminPanel?: boolean,
}[] = [
    {
        tab: "verifyAndSend",
        title: 'Verify & Send',
        icon: <TaskOutlined/>,

        disabledForReadOnly: true,
        disabledForPdf: true,
        disabledForVerify: false,
        showOnlyOnVerify: true,
        disabledForAdminPanel: true,
    },
    {
        tab: "blocks",
        title: 'Blocks',
        icon: <WidgetsOutlined/>,

        disabledForReadOnly: true,
        disabledForPdf: false,
        disabledForVerify: true,
    },
    {
        tab: "variables",
        title: 'Variables',
        icon: <DataObjectOutlined/>,

        disabledForReadOnly: true,
        disabledForPdf: true,
        disabledForVerify: true,
    },
    {
        tab: "history",
        title: 'History',
        icon: <History/>,

        disabledForReadOnly: false,
        disabledForPdf: false,
        disabledForVerify: false,
        disabledForAdminPanel: true,
    },
    {
        tab: "comments",
        title: 'Comments',
        icon: <CommentOutlined/>,

        disabled: true,
        disabledForReadOnly: false,
        disabledForPdf: false,
        disabledForVerify: false,
        disabledForAdminPanel: true,
    },
]

export const DEFAUTL_ACTOR = {email: '', firstName: '', lastName: ''}