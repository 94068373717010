import React, {FC, useEffect, useState} from "react";
import {
    CustomDialog, CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle
} from "../../../../../../../shared/components/CustomDialog";
import {Button, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {createTemplateId, hideCreateTemplateId, openFileEditor} from "../../../../store/slice";



export const CreateTemplateIdDialog: FC = () => {

    const dispatch = useDispatch();
    const {isOpen} = useSelector(createTemplateId);
    const [docId, setDocId] = useState<string>('');

    const handleNext = () => {
        dispatch(hideCreateTemplateId());
        dispatch(openFileEditor({docId: docId?.trim().length > 0 ? docId : null}));
    }

    const handleCancel = () => {
        dispatch(hideCreateTemplateId());
    }

    useEffect(() => {
        isOpen && setDocId('');

        //eslint-disable-next-line
    }, [isOpen]);

    return (
        <CustomDialog open={isOpen} onClose={handleNext}>
            <CustomDialogTitle>Enter template Id</CustomDialogTitle>
            <CustomDialogContent>
                <TextField size={'small'} sx={{width: '100%'}} onChange={(e) => setDocId(e.target.value)} value={docId ?? ''} />
            </CustomDialogContent>
            <CustomDialogActions>
                <Button onClick={handleCancel} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>{'Cancel'}</Button>
                <Button onClick={handleNext} variant={'contained'} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>{'Next'}</Button>
            </CustomDialogActions>
        </CustomDialog>
    )
}
