//CORE
import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";

//CONSTS
import {
    BUILDER_WITH_FRAME,
    BUILDER_WITH_FRAME_AND_CONTROL,
    ROOT_BUILDER,
    ROOT_CONTROLS,
    ROOT_EVIDENCES, ROOT_GALLERY,
    ROOT_POLICIES
} from "./constants";

//COMPONENTS
import {Framework} from "./pages/Framework";
import {Controls} from "./pages/Controls";
import {Evidences} from "./pages/Evidences";
import {Policies} from "./pages/Policies";
import {GalleryPage} from "./pages/Gallery";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route path={ROOT_BUILDER} exact component={Framework}/>
            <Route path={BUILDER_WITH_FRAME} exact component={Framework}/>
            <Route path={BUILDER_WITH_FRAME_AND_CONTROL} exact component={Framework}/>

            <Route path={ROOT_CONTROLS} exact component={Controls}/>
            <Route path={ROOT_EVIDENCES} exact component={Evidences}/>
            <Route path={ROOT_POLICIES} exact component={Policies}/>
            <Route path={ROOT_GALLERY} exact component={GalleryPage}/>

            <Redirect to={ROOT_BUILDER}/>
        </Switch>
    );
}