import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {
    builderInitResponseData,
    controlInputType,
    controlType,
    evidenceInputType,
    evidenceType,
    frameworkInputType,
    frameworkType,
    GET_CONTROLS_EVIDENCES_REQUEST_TYPES,
    policyInputType,
    policyType,
    RequestControlWithFilterResponseType,
    RequestEvidencesWithFilterResponseType,
    RequestPoliciesWithFilterResponseType,
    TBuilderAdminAddFiles,
    TBuilderAdminAddGapFiles
} from "../types";
import {
    addControl,
    addControlsAndEvidencesFromFiles,
    addEvidence,
    addFramework,
    addGapFromFiles,
    addPolicy,
    adminCreateDocumentsRefactorApi,
    adminDeleteDocumentsRefactorApi,
    adminGetDocumentByIdRefactorApi,
    adminGetDocumentsRefactorApi,
    adminUpdateDocumentsRefactorApi,
    changeFrameworkName,
    changeFrameworkVisibility,
    deattachControl,
    deattachEvidence,
    deleteAllFiles,
    deleteControl,
    deleteEvidence,
    deleteFramework,
    deletePolicy,
    editControl,
    editEvidence,
    getAllControlsDialogData,
    getAllEvidecnesDialogData,
    getBuilderData,
    getControls,
    getPrivatePolicies,
    getPublicAndPrivateControlsWithFilterPag,
    getPublicAndPrivateEvidencesWithFilterPag,
    linkControl,
    linkEvidence,
    updateFramework,
    updatePolicy
} from "../api";
import {addInfoSnack, addSuccessfulSnack} from "../../../BarsENV/snack/store/slice";
import {
    recalculateFrameworkWhenControlLinkedOrUnlinked,
    recalculateFrameworkWhenEvidenceLinkedOrUnlinked
} from "../helpers";
import {FilterInput} from "../../../../shared/types";
import {
    AdminCreateDocumentsRefactorMutationVariables,
    AdminDeleteDocumentsRefactorMutationVariables,
    AdminGetDocumentByIdRefactorQueryVariables,
    AdminGetDocumentsRefactorQueryVariables,
    AdminUpdateDocumentsRefactorMutationVariables
} from "../../../../shared/GQLTypes";

const defaultResponse: builderInitResponseData = {
    frameworks: [],
    allControlsCounter: 0,
    allEvidencesCounter: 0,
    allPoliciesCounter: 0,
};

export const GetBuilderData = createAsyncThunk(
    'Builder/GetBuilderData',
    async (_): Promise<builderInitResponseData> => {
        try{
            return await getBuilderData();
        }catch (ex){
            return defaultResponse;
        }
    }
);

export const ChangeFrameworkName = createAsyncThunk(
    'Builder/changeFrameworkName',
    async (data: {frameworkId: string, name: string}, {dispatch}): Promise<{frameworkId: string, name: string}> => {
        const message = await changeFrameworkName( data.name, data.frameworkId);
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

//changeFrameworkVisibility

export const ChangeFrameworkVisibility = createAsyncThunk(
    'Builder/changeFrameworkVisibility',
    async (data: {frameworkId: string, visibility: string}, { dispatch}): Promise<{frameworkId: string, visibility: string}> => {
        const message = await changeFrameworkVisibility(data.visibility, data.frameworkId);
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

//deleteFramework
export const DeleteFramework = createAsyncThunk(
    'Builder/deleteFramework',
    async (data: string, { dispatch}): Promise<string> => {
        const message = await deleteFramework(data);
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

//getControls

export const GetControls = createAsyncThunk(
    'Builder/getControls',
    async (data: { frameworkId: string, onReject?: () => void }): Promise<controlType[]> => {
        try{
            return await getControls(data.frameworkId);
        }catch (ex: any){
            data.onReject?.();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//addControl

export const AddControl = createAsyncThunk(
    'Builder/addControl',
    async (data: {control: controlInputType, onSuccess?: (id: string) => void}): Promise<{control: controlType, frameworkId: string}> => {
        try{
            const control = await addControl(data.control);
            if(control && control.id) data.onSuccess?.(control.id);
            return{
                control,
                frameworkId: data.control.frameworkId || '',
            }
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//editControl

export const EditControl = createAsyncThunk(
    'Builder/editControl',
    async (data: {control: controlType}): Promise<controlType> => {
        try{
            return await editControl(data.control);
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//deattachControl

export const DeattachControl = createAsyncThunk(
    'Builder/deattachControl',
    async (data: {controlId: string, frameworkId: string, onSuccess?: () => void}, {dispatch, getState}): Promise<{controlId: string, framework: frameworkType}> => {
        const state = getState() as AppState;
        const {Builder: {selectedFramework, controls, frameworks}} = state;
        try{
            const message = await deattachControl(data.controlId, data.frameworkId);
            if(data.controlId === selectedFramework) data.onSuccess?.();
            dispatch(addSuccessfulSnack(message));
            //TODO
            return {
                controlId: data.controlId,
                framework: recalculateFrameworkWhenControlLinkedOrUnlinked(frameworks.find(e => e.id === data.frameworkId), controls, 'UNLINK', undefined, data.controlId)

        };
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//addEvidence
export const AddEvidence = createAsyncThunk(
    'Builder/addEvidence',
    async (data: {evidence: evidenceInputType, frameworkId?: string, isEvidencesPage: boolean}): Promise<{evidence: evidenceType, controlId: string, frameworkId?: string, isEvidencesPage: boolean}> => {
        try{
            const evidence = await addEvidence(data.evidence);
            return{
                evidence,
                controlId: data.evidence.controlId || '',
                frameworkId: data.frameworkId,
                isEvidencesPage: data.isEvidencesPage,
            }
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//editEvidence
export const EditEvidence = createAsyncThunk(
    'Builder/editEvidence',
    async (data: {evidence: evidenceType, isEvidencesPage: boolean}): Promise<{evidence:  evidenceType, isEvidencesPage: boolean }> => {
        try{
            return {
                evidence: await editEvidence(data.evidence),
                isEvidencesPage: data.isEvidencesPage,
            };
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//deattachEvidence
export const DeattachEvidence = createAsyncThunk(
    'Builder/deattachEvidence',
    async (data: {controlId: string, evidenceId: string}, {getState, dispatch}): Promise<{controlId: string, evidenceId: string, framework: frameworkType | null}> => {
        const state = getState() as AppState;
        const {Builder: {controls,frameworks, selectedFramework}} = state;
        try{
            const message = await deattachEvidence(data.controlId, data.evidenceId);
            dispatch(addSuccessfulSnack(message));
            return {
                ...data,
                framework: selectedFramework ? recalculateFrameworkWhenEvidenceLinkedOrUnlinked(
                    controls,
                    'UNLINK',
                    data.controlId,
                    frameworks.find(e => e.id === (selectedFramework || '')),
                    undefined,
                    data.evidenceId
                ): null
            };
        }catch (ex: any){
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//getControlsWithFilter
export const GetControlsWithFilter = createAsyncThunk(
    'Builder/getControlsWithFilter',
    async (data: { page: number, count: number, filters?: FilterInput , name?: string, type?: GET_CONTROLS_EVIDENCES_REQUEST_TYPES}): Promise<RequestControlWithFilterResponseType> => {
        return await getAllControlsDialogData(data.page, data.count, data.filters || {});
    }
);

//linkControl
export const LinkControl = createAsyncThunk(
    'Builder/linkControl',
    async (data: {frameworkId: string, control: controlType}, {dispatch, getState}): Promise<{ control: controlType, framework: frameworkType }> => {
        const state = getState() as AppState;
        const {Builder: {frameworks}} = state;
        const counters = await linkControl(data.control?.id || '', data.frameworkId);
        //now counters come from middleware -> so no needed recalculate framework counters locally
        const framework = frameworks.find(e => e.id === data.frameworkId);
        if(!framework) throw new Error(`LinkControl action: framework ${framework} not found!`);
        return {
            control: data.control,
            framework: {...framework, ...counters}
        }
    }
);

//getAllEvidecnesDialogData

//getControlsWithFilter
export const GetEvidencesWithFilter = createAsyncThunk(
    'Builder/GetEvidencesWithFilter',
    async (data: { page: number, count: number, filters?: FilterInput, name?: string }): Promise<RequestEvidencesWithFilterResponseType> => {
        return await getAllEvidecnesDialogData( data.page, data.count, data.filters || {}, data.name);
    }
);

export const LinkEvidence = createAsyncThunk(
    'Builder/linkEvidence',
    async (data: {evidence: evidenceType, controlId: string}, {getState, dispatch}): Promise<{evidence: evidenceType, controlId: string, framework: frameworkType | null}> => {
        const state = getState() as AppState;
        const {Builder: {frameworks, selectedFramework}} = state;

        const counters = await linkEvidence(data.controlId, data.evidence.id, selectedFramework);

        if(!counters.controls && !counters.evidences){
            return {
                controlId: data.controlId,
                evidence: data.evidence,
                framework: null
            }
        }else{
            const framework = frameworks.find(e => e.id === (selectedFramework || ''));
            if(!framework) throw new Error(`LinkEvidence action: framework ${selectedFramework} not found!`);

            return {
                controlId: data.controlId,
                evidence: data.evidence,
                framework: {...framework, ...counters}
            }
        }
    }
);

//addFramework

export const AddFramework = createAsyncThunk(
    'Builder/addFramework',
    async (data: {framework: frameworkInputType, onSuccess?: (id: string) => void}): Promise<frameworkType> => {
        const addedFrame =  await addFramework(data.framework);
        if(addedFrame) data.onSuccess?.(addedFrame.id);
        return addedFrame;
    }
);

export const UpdateFramework = createAsyncThunk(
    'Builder/updateFramework',
    async (data: {framework: frameworkInputType, onSuccess?: (data: frameworkType) => void}): Promise<frameworkType> => {
        const addedFrame =  await updateFramework(data.framework);
        if(addedFrame) data.onSuccess?.(addedFrame);
        return addedFrame;
    }
);

export const GetPrivateControls = createAsyncThunk(
    'Builder/GetPrivateControls',
    async (data: { page: number, count: number, filters?: FilterInput}): Promise<RequestControlWithFilterResponseType> => {
        // console.log(`Builder/GetPrivateControls ${data.page} - - ${data.count}`);
        return await getPublicAndPrivateControlsWithFilterPag(data.page, data.count, data.filters);
    }
);

export const GetPrivateEvidences = createAsyncThunk(
    'Builder/GetPrivateEvidences',
    async (data: { page: number, count: number, filters?: FilterInput}): Promise<RequestEvidencesWithFilterResponseType> => {
        return await getPublicAndPrivateEvidencesWithFilterPag(data.page, data.count, data.filters);
    }
);


//deleteControl

export const DeleteControl = createAsyncThunk(
    'Builder/deleteControl',
    async (data: string, {dispatch}): Promise<string> => {
        const mes = await deleteControl(data);
        dispatch(addSuccessfulSnack(mes));
        return data;
    }
);

//deleteEvidence

export const DeleteEvidence = createAsyncThunk(
    'Builder/deleteEvidence',
    async (data: string, {dispatch}): Promise<string> => {
        const mes = await deleteEvidence(data);
        dispatch(addSuccessfulSnack(mes));
        return data;
    }
);


//getPrivatePolicies
export const GetPrivatePolicies = createAsyncThunk(
    'Builder/getPrivatePolicies',
    async (data: { page: number, count: number}): Promise<RequestPoliciesWithFilterResponseType> => {
        return await getPrivatePolicies(data.page, data.count);
    }
);

// addPolicy

export const AddPolicy = createAsyncThunk(
    'Builder/addPolicy',
    async (data: policyInputType): Promise<policyType> => {
        return await addPolicy(data);
    }
);

//updatePolicy

export const UpdatePolicy = createAsyncThunk(
    'Builder/updatePolicy',
    async (data: policyType): Promise<policyType> => {
        return await updatePolicy(data);
    }
);

//deletePolicy

export const DeletePolicy = createAsyncThunk(
    'Builder/deletePolicy',
    async (data: string, {dispatch}): Promise<string> => {
        const mes =  await deletePolicy(data);
        dispatch(addInfoSnack(mes));
        return data;
    }
);


export const DeleteAllFile = createAsyncThunk(
    'Automations/DeleteAllFiles',
    async (data: {fileIds: string[], autoId: string}, {dispatch}): Promise<{fileIds: string[], autoId: string }> => {
        await deleteAllFiles(data.fileIds);
        // console.log('DELETE FILES: ', data.fileIds, ' AUTOID: ', data.autoId);
        if(data.autoId.length > 0) dispatch(addInfoSnack('Warning: All files in documents stage were deleted!'));
        return data;
    }
);

export const addControlsAndEvidencesFromFilesAction = createAsyncThunk(
    'Builder/addControlsAndEvidencesFromFiles',
    async ({files, onSuccess }: {files: TBuilderAdminAddFiles, onSuccess?: () => void}, {dispatch}) => {
        const resp = await addControlsAndEvidencesFromFiles(files);

        onSuccess?.();
        dispatch(addSuccessfulSnack(resp));
        return resp;
    }
);

export const addGapFromFilesAction = createAsyncThunk(
    'Builder/addGapFromFiles',
    async ({files, onSuccess }: {files: TBuilderAdminAddGapFiles, onSuccess?: () => void}, {dispatch}) => {
        const resp = await addGapFromFiles(files);

        onSuccess?.();
        dispatch(addSuccessfulSnack(resp));
        return resp;
    }
);

//getFilesByFolderId
// export const GetFilesByFolderId = createAsyncThunk(
//     'Documents/adminGetFilesByFolderId',
//     async (_, {getState}): Promise<TDocumentFile[]> => {
//         //when making copy of template - fetching selected folder files before creating - so adding one file at the end
//
//         return await getFilesByFolderId();
//     }
// );


// //createDoc
// export const CreateDoc = createAsyncThunk(
//     'Documents/adminCreateDoc',
//     async (data: {type: string, name: string, innerHtml: string, editor: TFileContent,  variables: fileVariableType[], id: string | null}, {dispatch, getState, rejectWithValue}): Promise<TDocumentFile> => {
//
//         const resp = await createDoc(data.type, data.name, data.innerHtml, data.editor, data.variables, data.id);
//         dispatch(addInfoSnack('Document created!'));
//         // return {...data, id};
//         return resp;
//     }
// );
//
// export const UpdateDoc = createAsyncThunk(
//     'Documents/adminUpdateDoc',
//     async (data: {id: string, type: string, name: string, innerHtml: string, editor: TFileContent, variables: fileVariableType[]}, {dispatch, getState, rejectWithValue}): Promise<TDocumentFile> => {
//
//         const resp = await updateDoc(data.id, data.type, data.name, data.innerHtml, data.editor, data.variables);
//         dispatch(addInfoSnack('Document updated!'));
//         // return {...data, newId: id};
//         return resp;
//     }
// );
//
// export const DeleteDoc = createAsyncThunk(
//     'Documents/adminDeleteDoc',
//     async (data: {id: string}, {dispatch, getState, rejectWithValue}) => {
//
//         const resp = await deleteDoc(data.id);
//         // dispatch(addInfoSnack('Document deleted!'));
//         dispatch(addInfoSnack(resp));
//         return {...data, id: data.id};
//     }
// );


//TEMPLATES
export const getTemplatesAction = createAsyncThunk(
    'Builder/getTemplates',
    async (data: AdminGetDocumentsRefactorQueryVariables, {dispatch}) => {
        const resp = await adminGetDocumentsRefactorApi(data);
        return resp;
    }
);

export const getTemplateByIdAction = createAsyncThunk(
    'Builder/getTemplateById',
    async (data: AdminGetDocumentByIdRefactorQueryVariables, {dispatch}) => {
        const resp = await adminGetDocumentByIdRefactorApi(data);
        return resp;
    }
);

export const createTemplateAction = createAsyncThunk(
    'Builder/createTemplateById',
    async (data: AdminCreateDocumentsRefactorMutationVariables, {dispatch}) => {
        const resp = await adminCreateDocumentsRefactorApi(data);
        return resp;
    }
);

export const updateTemplateByIdAction = createAsyncThunk(
    'Builder/updateTemplateById',
    async (data: AdminUpdateDocumentsRefactorMutationVariables, {dispatch}) => {
        const resp = await adminUpdateDocumentsRefactorApi(data);
        dispatch(addSuccessfulSnack('Template updated!'));
        return resp;
    }
);

export const deleteTemplateByIdAction = createAsyncThunk(
    'Builder/deleteTemplateById',
    async (data: AdminDeleteDocumentsRefactorMutationVariables, {dispatch}) => {
        const resp = await adminDeleteDocumentsRefactorApi(data);
        dispatch(addInfoSnack(resp));
        return resp;
    }
);