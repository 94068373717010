
//getWorkspaceById

import {createAsyncThunk} from "@reduxjs/toolkit";
import {OrganizationInviteType, TExtendedCollaborator, TWorkspaceWithExtendedCollabsAndInvites} from "../../types";
import {
    cancelWorkspaceInvite,
    changeWSInviteRole,
    deleteWorkspace,
    deleteWorkspaceCollaborator,
    getWorkspaceById,
    inviteMemberIntoWorkspace,
    resendWorkspaceInvite,
    updateWorkspaceCollaboratorRole,
    updateWorkspaceName
} from "../../api";
import {AppState} from "../../../../../shared/redux/rootReducer";
import {addInfoSnack, addSuccessfulSnack} from "../../../../BarsENV/snack/store/slice";
import {prevUserRoleRollback, prevInviteRoleRollback} from "../manageWorkpsace/slice";

export const GetWorkspaceById = createAsyncThunk(
    'ManageWorkspace/getWorkspaceById',
    async (workspaceId: string ): Promise<TWorkspaceWithExtendedCollabsAndInvites> => {
        return await getWorkspaceById(workspaceId);
    }
);

//updateWorkspaceName
export const UpdateWorkspaceName = createAsyncThunk(
    'ManageWorkspace/updateWorkspaceName',
    async (data: {workspaceId: string, newName: string} ): Promise<{workspaceId: string, newName: string}> => {
        await updateWorkspaceName(data.workspaceId, data.newName);
        return data;
    }
);

//inviteMemberIntoWorkspace
export const InviteMemberIntoWorkspace = createAsyncThunk(
    'ManageWorkspace/inviteMemberIntoWorkspace',
    async (data: {workspaceId: string, email: string, role: string}, {getState, dispatch} ): Promise<{invite: OrganizationInviteType | null, collaborator: TExtendedCollaborator | null}> => {
        const state = getState() as AppState;
        const publicId = state.SharedWorkspacesAndOrganizations.multistore.currentUser?.publicId || '';

        const res = await inviteMemberIntoWorkspace(data.workspaceId, data.email, data.role, publicId);

        dispatch(addSuccessfulSnack(`Invite sent to ${data.email}!`));

        return res;
    }
);

//updateWorkspaceCollaboratorRole
export const UpdateWorkspaceCollaboratorRole = createAsyncThunk(
    'ManageWorkspace/updateWorkspaceCollaboratorRole',
    async (data: {workspaceId: string, publicId: string, newRole: string}, {dispatch} ): Promise<{workspaceId: string, publicId: string, newRole: string} | undefined> => {
        try{
            await updateWorkspaceCollaboratorRole(data.workspaceId, data.publicId, data.newRole);
            return data;
        }catch (ex){
            dispatch(prevUserRoleRollback());
        }
    }
);

//changeWSInviteRole
export const ChangeWSInviteRole = createAsyncThunk(
    'ManageWorkspace/changeWSInviteRole',
    async (data: {workspaceId: string, email: string, newRole: string, inviteId: string}, {dispatch} ): Promise<{workspaceId: string, email: string, newRole: string} | undefined> => {
        try{
            await changeWSInviteRole(data.workspaceId, data.email, data.newRole, data.inviteId);
            return data;
        }catch (ex){
            dispatch(prevInviteRoleRollback());
        }
    }
);

//cancelWorkspaceInvite
export const CancelWorkspaceInvite = createAsyncThunk(
    'ManageWorkspace/cancelWorkspaceInvite',
    async (data: {workspaceId: string, email: string, inviteId: string}, {dispatch} ): Promise<{workspaceId: string, email: string, inviteId: string}> => {
        const mes = await cancelWorkspaceInvite(data.workspaceId, data.email, data.inviteId);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//resendWorkspaceInvite
export const ResendWorkspaceInvite = createAsyncThunk(
    'ManageWorkspace/resendWorkspaceInvite',
    async (data: {workspaceId: string, email: string, inviteId: string}, {dispatch} ): Promise<{workspaceId: string, email: string, inviteId: string}> => {
        const mes = await resendWorkspaceInvite(data.workspaceId, data.email, data.inviteId);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//deleteWorkspace
export const DeleteWorkspace = createAsyncThunk(
    'ManageWorkspace/deleteWorkspace',
    async (data: { workspaceId: string, onSuccess: () => void}, {dispatch} ): Promise<string> => {
        const mes = await deleteWorkspace(data.workspaceId);
        if(mes){
            data.onSuccess();
            dispatch(addInfoSnack(mes));
        }
        return data.workspaceId;
    }
);

//deleteWorkspaceCollaborator
export const DeleteWorkspaceCollaborator = createAsyncThunk(
    'ManageWorkspace/deleteWorkspaceCollaborator',
    async (data: { workspaceId: string, publicId: string}, {dispatch} ): Promise<string> => {
        const mes = await deleteWorkspaceCollaborator(data.workspaceId, data.publicId);
        if(mes){
            dispatch(addInfoSnack(mes));
        }
        return data.publicId;
    }
);