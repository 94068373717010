import {useMemo, useState} from "react";
import {useParameters} from "../../../hooks/useParameters";
import {useChipObject} from "../../../hooks/useChipObject";
import {buildFilter} from "../../../utils";
import {useHistory} from "react-router-dom";
import {ChipProps} from "../../../types";
import {buildChipsFromFilter, replaceFilter} from "../../../utils/pagination";

type TProps = {
    withoutPagination?: boolean,

    localFilter?: {
        filter: {[key: string]: string[]}
        setFilter: (filter: {[key: string]: string[]}) => void
    }
}
export const useMiniFilter = ({withoutPagination, localFilter}: TProps) => {

    const [isOpen, setFilter] = useState(false);
    const {filter} = useParameters();
    const {
        addChip,
        deleteChip,
        chips,
        setSpecificChips,
        setInitialChips,
    } = useChipObject([]);
    const dbFilter = buildFilter(chips);
    const history = useHistory();
    const {location} = window;
    const compareValue1 = JSON.stringify(dbFilter);
    const compareValue2 = JSON.stringify(chips);

    const toggleFilter = () => {setFilter((prev) => !prev)};
    const openFilter = () => {setFilter(true)};
    const hideFilter = () => {setFilter(false)};

    const handleAdd = ({filterName, value}: ChipProps) => {addChip(filterName, value)}
    const handleDel = ({filterName, value}: ChipProps) => {
        deleteChip(filterName, value);
        openFilter();
    }
    const clearAll = () => {chips.forEach(chip => {handleDel(chip)})};

    const restoreFilter = () => { setSpecificChips(buildChipsFromFilter(localFilter?.filter || filter)) }

    return useMemo(() => ({
            toggleFilter,
            handleDel,
            handleAdd,
            clearAll,
            hideFilter,
            isOpen,
            openFilter,
            chips,
            doFilter: () => {
                if (localFilter) {
                    localFilter.setFilter(dbFilter)
                } else {
                    history.push({
                        pathname: location.pathname,
                        search: replaceFilter(location.search, dbFilter, !withoutPagination),
                    });
                }
                hideFilter()
            },
            dbFilter,
            setSpecificChips,
            setInitialChips,
            restoreFilter
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpen, compareValue1, compareValue2]);
}