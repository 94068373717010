import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hidePreviewEvidence, previewEvidence} from "../../../../store/slice";
import {Typo} from "../../../../../../../shared/components/Typography";
import {Flex} from "../../../../../../../shared/components/Layouts";

export const PreviewEvidenceDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, evidence} = useSelector(previewEvidence);

    const handleClose = () => {
        dispatch(hidePreviewEvidence());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth>
            <DialogTitle>
                <Typography fontSize={'22px'} >{`Preview evidence ${evidence?.id}`}</Typography>
            </DialogTitle>

            <DialogContent>
                {evidence &&
                    <>
                        <Typography variant={'body1'} color={'#747474'}>{('ID:')} <i style={{color: '#343440', marginLeft: '8px'}}>{evidence.id}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Name:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.name}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px', overflowWrap: 'normal'}}>{`Description: `} <i style={{color: '#343440', marginLeft: '8px'}}>{evidence.description}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Categories:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.categories.join(', ')}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Collector ID:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.collectorId}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Origin:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.origin ? 'True' : 'False'}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Visibility:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.visibility}</i></Typography>
                        <Typography variant={'body1'} color={'#747474'} sx={{mt: '3px'}}>{('Used in controls:')} <i style={{color: '#343440', margin: '0 0 0 8px'}}>{evidence.foundInControlsCounter}</i></Typography>
                        <Box display={'flex'} alignItems={'center'} width={'100%'} flexWrap={'wrap'} sx={{mt: '6px'}} overflow={'auto'} maxHeight={'450px'}>
                            {(evidence.foundInControlsCounter && evidence.foundInControlsCounter > 0) &&
                                (evidence.foundInControls ?? []).map((c, index) => (
                                    <Box key={c.id} display={'flex'} width={'100%'} sx={{
                                        border: '1px solid black',
                                        borderTop: index !== 0 ? 'none' : undefined,
                                        p: '8px'
                                    }}>
                                        <Typography fontSize={'14px'} sx={{ml: '4px'}}>{c.id}</Typography>
                                        <Typography fontSize={'14px'} sx={{ml: '16px'}}>{c.name}</Typography>
                                    </Box>
                                ))}
                        </Box>
                        <Flex ai={'center'} m={'8px 0 0 0'}>
                            <Typography variant={'body1'} color={'#747474'}>{('Gap:')}</Typography>
                            {Object.keys(evidence.gap ?? {}).map(e => <Flex key={e} background={'green'} m={'0 0 0 5px'} br={'4px'} p={'2px'}><Typo color={'#fff'} fontSize={'10px'}>{e}</Typo></Flex>)}
                        </Flex>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
