import axiosSync from '../../../../shared/utils/axios/axios';
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {TAddAssetJobTitleData, TAddAssetSettingData, TAssetField, TUpdateAssetJobTitleData} from "../types";
import {gql} from "@apollo/react-hooks";
import {print} from "graphql";

export const getAssetsSettingsApi = async (data: {asset: string}): Promise<TAssetField[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAssetsSettingsByAdmin($asset: String!) {
                        getAssetsSettingsByAdmin(asset: $asset) {
                            name
                            title
                            values
                            jobDescription {
                                id
                                title
                                type
                                description
                                requirements
                            }
                            status {
                                id
                                title
                                textColor
                                backgroundColor
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAssetsSettingsByAdmin;
    })
)

export const addAssetSettingsApi = async (data: TAddAssetJobTitleData): Promise<TAssetField[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addAssetSettingsByAdmin($asset: String!, $variant: String!, $field: String!, $jobTitle: JobTitleByAdminInput, $status: StatusByAdminInput) {
                        addAssetSettingsByAdmin(asset: $asset, variant: $variant, field: $field, jobTitle: $jobTitle, status: $status) {
                            name
                            title
                            values
                            jobDescription {
                                id
                                title
                                type
                                description
                                requirements
                            }
                            status {
                                id
                                title
                                textColor
                                backgroundColor
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addAssetSettingsByAdmin;
    })
)

export const updateAssetSettingsApi = async (data: TUpdateAssetJobTitleData): Promise<TAssetField[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editAssetSettingsByAdmin($asset: String!, $field: String!, $title: String!, $oldTitle: String!, $jobTitle: JobTitleByAdminInput, $status: StatusByAdminInput) {
                        editAssetSettingsByAdmin(asset: $asset, field: $field, title: $title, oldTitle: $oldTitle, jobTitle: $jobTitle, status: $status) {
                            name
                            title
                            values
                            jobDescription {
                                id
                                title
                                type
                                description
                                requirements
                            }
                            status {
                                id
                                title
                                textColor
                                backgroundColor
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editAssetSettingsByAdmin;
    })
)

export const removeAssetSettingsApi = async (data: TAddAssetSettingData): Promise<TAssetField[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation removeAssetSettingsByAdmin($asset: String!, $variant: String!, $field: String!, $newJobType: String) {
                        removeAssetSettingsByAdmin(asset: $asset, variant: $variant, field: $field, newJobType: $newJobType) {
                            name
                            title
                            values
                            jobDescription {
                                id
                                title
                                type
                                description
                                requirements
                            }
                            status {
                                id
                                title
                                textColor
                                backgroundColor
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.removeAssetSettingsByAdmin;
    })
)

export const getDataForCreateJobTitleApi = async (): Promise<{ jobTypeVariants: string[] }> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDataForCreateJobTitleByAdmin {
                        getDataForCreateJobTitleByAdmin {
                            jobTypeVariants
                        }
                    }
                `),
                // variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDataForCreateJobTitleByAdmin;
    })
)