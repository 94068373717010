// import styled from "styled-components";
// import {Chip} from "@mui/material";
import {Chip, styled} from "@mui/material";

export const ChipItem = styled(Chip)<{margin?: string}>`
    background-color: #2681FF !important;
    color: white !important;
    padding: 0 5px !important;
    margin-right: 8px;
    
    //& span {
    //  //overflow: unset !important;
    //  //overflow-wrap: anywhere;
    //}
    margin-top: 4px;
    margin-bottom: 4px;
    ${props => props.margin? `margin: ${props.margin};` : ""}


    .MuiChip-deleteIcon{
        margin: 0;
    }

  
  
  &.MuiChip-root {
    justify-content: space-between;
  }
`;