import {useDispatch, useSelector} from "react-redux";
import {
    attachEvidenceDialog,
    controls,
    fetchedEvidences, hideAttachEvidence,
    isLoading,
    pageInfo,
    selectedControl
} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {controlType, evidenceType} from "../../../types";
import {GetEvidencesWithFilter, LinkEvidence} from "../../../store/actions";
import {PaginationInput} from "../../../../../../shared/types";
import {useParameters} from "../../../../../../shared/hooks/useParameters";
import {useSearchFieldURL} from "../../../../../../shared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../../shared/hooks/useFilterUrl";


export const useSelectExistingEvidence = () => {

    const dispatch = useDispatch();
    const {isOpen} = useSelector(attachEvidenceDialog);
    const {filter} = useParameters();
    const _isLoading = useSelector(isLoading);
    const _controls = useSelector(controls);
    const _fetchedEvidences = useSelector(fetchedEvidences);
    const _pageInfo = useSelector(pageInfo);
    const selectedControlId = useSelector(selectedControl);

    const {value, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getData({page: 0, count: 10, filters})}
    });

    // const [step] = useState<GET_CONTROLS_EVIDENCES_REQUEST_TYPES>('ALL');
    const [currentControl, setCurrentControl] = useState<controlType | null>(null);

    useEffect(() => {
        if(!selectedControlId) {
            setCurrentControl(null);
            return;
        }
        const currControl = _controls.find(e => e.id === selectedControlId);
        if(currControl && JSON.stringify(currControl) !== JSON.stringify(currentControl)) setCurrentControl(currControl);
        //eslint-disable-next-line
    }, [_controls, selectedControlId]);

    useEffect(() => {
        if(isOpen){
            // setStep('ALL');
            if(_fetchedEvidences.length === 0 && !_isLoading) getData({page: 0, count: 10, filters: filter});
        } else {
            handleChange({target: {value: ''}});
        }

        //eslint-disable-next-line
    }, [isOpen]);

    // const getEvidences = (page: number, count: number, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES, name: string) => dispatch(GetEvidencesWithFilter({page, count, name, type}));

    const getData = (pageInfo: PaginationInput) => {
        //only for controls page
        dispatch(GetEvidencesWithFilter({page: pageInfo.page, count: pageInfo.count, filters: pageInfo.filters || {}}));
    };

    const handleClose = () => {
        dispatch(hideAttachEvidence());
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        getData({page: newPage, count: _pageInfo.count, filters: filter});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        getData({page: 0, count: parseInt(event.target.value, 10), filters: filter});
    };

    const handleAddEvidence = (evidence: evidenceType) => {
        if(selectedControlId && evidence.id.length > 0) dispatch(LinkEvidence({evidence, controlId: selectedControlId}));
    }

    return {
        isOpen,
        currentControl,
        isLoading: _isLoading,
        fetchedEvidences: _fetchedEvidences,
        pageInfo: _pageInfo,
        handleClose,
        handleChangePage,
        handleChangeRowsPerPage,
        handleAddEvidence,
        //filter
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    }

}
