
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    ManageOrganizationType,
    OrganizationInviteType,
} from "../../types";
import {
    cancelOrganizationInvite, deleteOrganizationCollaborator,
    getOrganizationWithExtendedCollabsAndWorkspacesByID,
    inviteMemberIntoOrg,
    resendOrganizationInvite, transferOrganizationOwnership,
    updateCollabRole,
    updateInviteRole,
    updateOrganizationLogo,
    updateOrganizationName
} from "../../api";

import {prevUserRoleRollback, prevInviteRoleRollback} from './slice';
import { addInfoSnack } from "../../../../BarsENV/snack/store/slice";
import {AppState} from "../../../../../shared/redux/rootReducer";

export const GetOrganizationWithExtendedCollabsAndWorkspacesByID = createAsyncThunk(
    'ManageOrganization/getOrganizationWithExtendedCollabsAndWorkspacesByID',
    async (organizationid: string ): Promise<ManageOrganizationType> => {
        return await getOrganizationWithExtendedCollabsAndWorkspacesByID(organizationid);
    }
);



export const UpdateOrganizationName = createAsyncThunk(
    'ManageOrganization/updateOrganizationName',
    async (data: {organizationId: string, newName: string} ): Promise<{organizationId: string, newName: string}> => {
        await updateOrganizationName(data.organizationId, data.newName);
        return data;
    }
);

//updateOrganizationLogo
export const UpdateOrganizationLogo = createAsyncThunk(
    'ManageOrganization/updateOrganizationLogo',
    async (data: {organizationId: string, newLogo: string} ): Promise<{organizationId: string, newLogo: string}> => {
        await updateOrganizationLogo(data.organizationId, data.newLogo);
        return data;
    }
);

//updateCollabRole
export const UpdateCollabRole = createAsyncThunk(
    'ManageOrganization/updateCollabRole',
    async (data: {organizationId: string, publicId: string, newRole: string}, {dispatch} ): Promise<{organizationId: string, publicId: string, newRole: string} | undefined> => {
        try{
            await updateCollabRole(data.organizationId, data.publicId, data.newRole);
            return data;
        }catch (ex){
            dispatch(prevUserRoleRollback());
        }
    }
);

//updateInviteRole

export const UpdateInviteRole = createAsyncThunk(
    'ManageOrganization/updateInviteRole',
    async (data: {organizationId: string, email: string, newRole: string, inviteId: string}, {dispatch} ): Promise<{organizationId: string, email: string, newRole: string} | undefined> => {
        try{
            await updateInviteRole(data.organizationId, data.email, data.newRole, data.inviteId);
            return data;
        }catch (ex){
            dispatch(prevInviteRoleRollback());
        }
    }
);

//resendOrganizationInvite

export const ResendOrganizationInvite = createAsyncThunk(
    'ManageOrganization/resendOrganizationInvite',
    async (data: {organizationId: string, email: string, inviteId: string}, {dispatch} ): Promise<{organizationId: string, email: string, inviteId: string}> => {
        const mes = await resendOrganizationInvite(data.organizationId, data.email, data.inviteId);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//cancelOrganizationInvite
export const CancelOrganizationInvite = createAsyncThunk(
    'ManageOrganization/cancelOrganizationInvite',
    async (data: {organizationId: string, email: string, inviteId: string}, {dispatch} ): Promise<{organizationId: string, email: string, inviteId: string}> => {
        const mes = await cancelOrganizationInvite(data.organizationId, data.email, data.inviteId);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//inviteMemberIntoOrg
export const InviteMemberIntoOrg = createAsyncThunk(
    'ManageOrganization/inviteMemberIntoOrg',
    async (data: {organizationId: string, email: string, role: string}, {getState, dispatch} ): Promise<OrganizationInviteType> => {
        const state = getState() as AppState;
        const publicId = state.SharedWorkspacesAndOrganizations.multistore.currentUser?.publicId || '';

        const res = await inviteMemberIntoOrg(data.organizationId, data.email, data.role, publicId);
        dispatch(addInfoSnack(`Invite sent to ${data.email}!`));
        return res;
    }
);

//deleteOrganizationCollaborator
export const DeleteOrganizationCollaborator = createAsyncThunk(
    'ManageOrganization/deleteOrganizationCollaborator',
    async (data: {organizationId: string, publicId: string}, {getState, dispatch} ): Promise<{organizationId: string, publicId: string}> => {
        const mes = await deleteOrganizationCollaborator(data.organizationId, data.publicId);
        dispatch(addInfoSnack(mes));
        return data;
    }
);

//transferOrganizationOwnership
export const TransferOrganizationOwnership = createAsyncThunk(
    'ManageOrganization/transferOrganizationOwnership',
    async (data: {organizationId: string, transferToPublicId: string, transferFromPublicId: string, onSuccess: () => void}, {dispatch} ): Promise<{organizationId: string, transferToPublicId: string, transferFromPublicId: string, onSuccess: () => void}> => {
        const mes = await transferOrganizationOwnership(data.organizationId, data.transferToPublicId, data.transferFromPublicId);
        if(mes){
            data.onSuccess();
        }
        dispatch(addInfoSnack(mes));
        return data;
    }
);
