import axiosSync from '../../../../shared/utils/axios/axios';
import {PATH_SERVER} from "../../../../shared/constants";
import {TPermission, TRole, TRoleCreateModel, TRoleEditModel} from "../types";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";

export const getAdminRoles = async (): Promise<{roles: TRole[], permissions: TPermission[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getAdminRoles{
                  getAdminRoles{
                    roles{
                      id
                      name
                      origin
                      system
                      workspaceId
                      permissions
                      createDate
                      lastUpdateDate
                    }
                    permissions{
                      name
                      tags
                    }
                  }
                }
                `,
                variables: {

                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAdminRoles;
    })
)

export const createAdminRole = async (role: TRoleCreateModel): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createAdminRole($role:createRoleInputModel!){
                  createAdminRole(role:$role){
                    message
                  }
                }
                `,
                variables: {
                    role
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createAdminRole.message;
    })
)

export const deleteRoleByAdmin = async (roleId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteRoleByAdmin($roleId:String!){
                  deleteRoleByAdmin(roleId:$roleId){
                    message
                  }
                }
                `,
                variables: {
                    roleId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteRoleByAdmin.message;
    })
)

export const editAdminRole = async (role: TRoleEditModel): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation editRoleByAdmin($role:updateAdminRoleRequestInput!){
                  editRoleByAdmin(role:$role){
                    message
                  }
                }
                `,
                variables: {
                    role
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editRoleByAdmin.message;
    })
)

export const createPermissionByAdmin = async (permission: TPermission): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createPermissionByAdmin($permission: AdminPermissionInput!){
                  createPermissionByAdmin(permission:$permission){
                    message
                  }
                }
                `,
                variables: {
                    permission
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createPermissionByAdmin.message;
    })
)

export const updatePermissionByAdmin = async (permission: TPermission): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                
                mutation updatePermissionByAdmin($permission: AdminPermissionInput!){
                  updatePermissionByAdmin(permission:$permission){
                    message
                  }
                }
                `,
                variables: {
                    permission
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updatePermissionByAdmin.message;
    })
)

export const deletePermissionByAdmin = async (permissionName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                
                mutation deletePermissionByAdmin($permissionName: String!){
                  deletePermissionByAdmin(permissionName:$permissionName){
                    message
                  }
                }
                `,
                variables: {
                    permissionName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deletePermissionByAdmin.message;
    })
)