import axios, {AxiosRequestHeaders} from "axios";
import {ChipProps, TCommonSliceTemplate} from "../types";
import {ReactNode} from "react";
import {v4} from "uuid";

export const uuid = v4;

export const encodeSpecialSymbols = (x: string) => x
    .replace('&', '%26')
    .replace('#', '%23')
    .replace(' ', '%20');

export type TypeRequestFilter = { [key: string]: string[] };
const encodeAmp = (x: string) => x.replace(/&/g, '%26');
const decodeAmp = (x: string) => x.replace(/%26/g, '&');
// &item=Email%2520&%2520Password%2520Status %26
export const _encodeURI = (str: string) => encodeURI(str).replace(/#/g, '%23');
export const _decodeURI = (str: string) => decodeURI(str).replace(/%23/g, '#');
export default axios;
export const buildCommonHeader = (): AxiosRequestHeaders => ({
  'Content-Type': 'application/json'
})

export const rejected = <T extends TCommonSliceTemplate>(slice: T) => {
  slice.isLoading = false;
  slice.isError = true;
}

export const pending = <T extends TCommonSliceTemplate>(slice: T) => {
  slice.isLoading = true;
  slice.isError = false;
}

export const isItDigits = (val: string) => /^\d+$/.test(val);

// addressString example path/2/10?application=GSuit;Figma&account=
export const searchStringToFilter = (address: string, ...skipKeys: string[]): TypeRequestFilter => {
  if (!address) {
    return {};
  }
  const [, queryPart] = _decodeURI(address).split('?');
  if (queryPart) {
    return _decodeURI(queryPart).split('&').map(decodeAmp).filter(empty => empty).reduce((acc, eq) => {
      const [category, values] = eq.split('=');
      return (skipKeys?.includes(category)
        ? acc
        : {
          ...acc,
          [category]: values.split(';').filter(notEmpty => notEmpty)
        })
    }, {})
  }
  return {}
};

export const buildSearchString = (data: { [key: string] : string[] | string }, useQuestionMark: boolean = true): string => {
  return _encodeURI((useQuestionMark ? '?' : '') + Object.entries(data).reduce((acc, [key, value], index) => {
    return (acc + (key !== '' ? (index !== 0 ? '&' : '') + `${key}=${Array.isArray(value) 
        ? value.map(encodeAmp).join(';') 
        : encodeAmp(value)
    }` : ''))
  }, ''));
};

export const replaceValueInSearchByKey = (search: string, key: string, value: number | string) => (
  _decodeURI(search).indexOf('?') !== 0
    ? `?${key}=${value}`
    : _encodeURI('?'+ _decodeURI(search).split('?')[1]?.split('&')?.map(decodeAmp)?.map(
    entry => (entry.split('=')[0] === key)
      ? (`${entry.split('=')[0]}=${value}`)
      : entry
    )?.map(encodeAmp)?.join('&') || '')
)


export const readValueByKey = (search: string, key: string) => (
  _decodeURI(search).indexOf('?') !== 0
    ? ''
    : (search.split('?')[1]?.split('&')?.map(decodeAmp)?.filter(entry => entry.split('=')[0] === key)
      ?.map(entry => entry.split('=')[1]) || [])[0]
)

export const replaceFilterInSearch = (search: string, filter: TypeRequestFilter, ...notFilterKeys: string[]): string => {
  const filterString = (Object.keys(filter).length > 0)
      ? buildSearchString(filter, false)
      : '';
  const notFilterString = _decodeURI(search)
      .split('?')[1]
      ?.split('&')
      ?.map(decodeAmp)
      ?.filter(entry => notFilterKeys?.includes(entry.split('=')[0]))
      ?.map(encodeAmp)
      ?.join('&') || '';
  return notFilterString || filterString ? _encodeURI(`?`+ (
      notFilterString
    ? notFilterString + (!filterString ? '' : '&') + filterString
    : filterString))
      : "";
}

export const joiner = (list: ReactNode[], divider: ReactNode, fromStart?: boolean) => (
    fromStart ? list.flatMap((element, index, array) => [divider, element])
    : list.flatMap((element, index, array) => index !== list.length - 1 ? [element, divider] : [element])
)

export const buildFilter = (chips: ChipProps[]) => (chips || []).reduce((acc, chip) => ({
  ...acc,
  [chip.filterName]: [...acc[chip.filterName] || [],...Array.isArray(chip.value) ? chip.value : [chip.value]]
}),{} as TypeRequestFilter);

export const clone = (obj: unknown): unknown => JSON.parse(JSON.stringify(obj));

export const compareRequestFilters = (f1: { [key: string]: string[] }, f2: { [key: string]: string[] }) => {
  if (Object.keys(f1).length !== Object.keys(f2).length) return false;
  const f2entries = Object.entries(f2);
  return  (
      !Object.entries(f1).some(
          ([key, f1values]) => {
            const [f2key, f2values] = f2entries.find(([k]) => k === key) ?? [];
            return !(f2key && (JSON.stringify([...f1values].sort()) === JSON.stringify([...(f2values || []) ]?.sort())))
          }
      )
  )
}

const isExist = (pathname: string, str: string): boolean => pathname.startsWith(`/${str}`);
export const getCurrentPath = (pathname: string): string => {
  const isExistPath = isExist.bind(null, pathname);
  return isExistPath('inventory')
      ? 'Inventory'
      : isExistPath('collections')
          ? 'Collections'
          : isExistPath('rules')
              ? 'Rules'
                : isExistPath('compliance')
                  ? 'Compliance'
                    : isExistPath('settings')
                        ? 'Settings' : '';
}
