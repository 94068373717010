//paths
import {
    ASSETS_SETTINGS_VARIANTS,
    PATH_ACCOUNTS,
    PATH_APPLICATIONS, PATH_LICENSE,
    PATH_LOCAL_NEW_WORKSPACES,
    PATH_ORGANIZATIONS
} from "../../BarsENV/shell/constants";
import {ROOT_BUILDER} from "../../Components/builder/constants";
import {LOCAL_ACCESS_MANAGEMENT_PATH} from "../../Components/AccessManagement/constants";
import {NEW_APPLICATIONS_ROOT} from "../../Components/newApplications/constants";

export const SELECT_WORKSPACES_PATH = '/selectWorkspace';
export const CREATE_ORGANIZATION = '/createOrganization';
export const MANAGE_ORGANIZATION = '/manageOrganization';
export const MANAGE_WORKSPACE = '/manageWorkspace';
export const INVITES = '/invites';

export const ACCEPT_INVITATION = '/invitation';
export const ACCEPT_INVITATION_PROTECTED = '/protected_invitation';
export const ACCEPT_INVITATION_UNPROTECTED = '/unprotected_invitation';


//roles
export const WORKSPACE_VIEWER = 'viewer';
export const WORKSPACE_EDITOR = 'editor';
export const WORKSPACE_ADMIN = 'admin';
export const WORKSPACE_OWNER = 'owner';

export const ORGANIZATION_MEMBER = 'member';
export const ORGANIZATION_ADMIN = 'admin';
export const ORGANIZATION_OWNER = 'owner';

export const PATHS_AVAILABLE_WITH_NO_WORKSPACE = [
    ACCEPT_INVITATION,
    ACCEPT_INVITATION_PROTECTED,
    ACCEPT_INVITATION_UNPROTECTED,

    PATH_ACCOUNTS,
    PATH_ORGANIZATIONS,
    PATH_APPLICATIONS,
    ROOT_BUILDER,
    PATH_LICENSE,

    PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH,
    PATH_LOCAL_NEW_WORKSPACES + CREATE_ORGANIZATION,
    PATH_LOCAL_NEW_WORKSPACES + MANAGE_ORGANIZATION,
    PATH_LOCAL_NEW_WORKSPACES + MANAGE_WORKSPACE,
    PATH_LOCAL_NEW_WORKSPACES + INVITES,

    LOCAL_ACCESS_MANAGEMENT_PATH,
    ASSETS_SETTINGS_VARIANTS,
    NEW_APPLICATIONS_ROOT
];

export const isPathAvailableWithNoWorkspace = (pathname: string): boolean => {
    // return  PATHS_AVAILABLE_WITH_NO_WORKSPACE.some(e => pathname.startsWith(e));
    return true;
}

export const PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN = [
    //for example - invitation - this path have to be opened for signed in user and for not signed ether
    ACCEPT_INVITATION,
]

export const isPathAvailableForEveryone = (pathname: string): boolean => {
    return  PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN.some(e => pathname.startsWith(e));
}


export const INVITE_USER_ROLES = [ORGANIZATION_MEMBER, ORGANIZATION_ADMIN];
export const INVITE_USER_WORKSPACE_ROLES = [WORKSPACE_VIEWER, WORKSPACE_EDITOR, WORKSPACE_ADMIN];

