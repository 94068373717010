import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {
    builderInitResponseData,
    controlInputType,
    controlType,
    evidenceInputType,
    evidenceType,
    frameworkInputType,
    frameworkType,
    policyInputType,
    policyType,
    RequestControlWithFilterResponseType,
    RequestEvidencesWithFilterResponseType,
    RequestPoliciesWithFilterResponseType,
    TBuilderAdminAddFiles,
    TBuilderAdminAddGapFiles
} from "../types";
import {FilterInput} from "../../../../shared/types";
import {gql} from "graphql.macro";
import {print} from "graphql";
import {
    adminCreateDocumentsRefactor,
    adminDeleteDocumentsRefactor,
    adminGetDocumentByIdRefactor,
    adminGetDocumentsRefactor,
    adminUpdateDocumentsRefactor
} from "./query";
import {
    AdminCreateDocumentsRefactorMutation,
    AdminCreateDocumentsRefactorMutationVariables,
    AdminDeleteDocumentsRefactorMutation,
    AdminDeleteDocumentsRefactorMutationVariables,
    AdminGetDocumentByIdRefactorQuery,
    AdminGetDocumentByIdRefactorQueryVariables,
    AdminGetDocumentsRefactorQuery,
    AdminGetDocumentsRefactorQueryVariables,
    AdminUpdateDocumentsRefactorMutation,
    AdminUpdateDocumentsRefactorMutationVariables
} from "../../../../shared/GQLTypes";

export const getBuilderData = async (): Promise<builderInitResponseData> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getBuilderData{
                  adminGetBuilderData{
                    frameworks{
                      id
                      name
                      logo
                      type
                      description
                      origin
                      visibility
                      organizationId
                      controls
                      evidences
                    }
                    allControlsCounter
                    allEvidencesCounter
                    allPoliciesCounter
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetBuilderData;
    })
)

export const changeFrameworkName = async (name: string, frameworkId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeFrameworkName($data:AdminChangeFrameworkNameInput!){
                  adminChangeFrameworkName(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: {
                        name,
                        frameworkId,
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminChangeFrameworkName.message;
    })
)

export const changeFrameworkVisibility = async (visibility: string, frameworkId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeFrameworkVisibility($data:AdminChangeFrameworkVisibilityInput!){
                  adminChangeFrameworkVisibility(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: {
                        visibility,
                        frameworkId,
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminChangeFrameworkVisibility.message;
    })
)

export const deleteFramework = async (frameworkId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteFramework($data:String!){
                  adminDeleteFramework(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: frameworkId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminDeleteFramework.message;
    })
)

export const getControls = async (frameworkId: string): Promise<controlType[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getControlsByFrameworkId($data:String!){
                        adminGetControlsByFrameworkId(data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                                foundInControls {
                                    id
                                    name
                                }
                                gap
                            }
                            foundInFrameworksCounter
                        }
                    }
                `),
                variables: {
                    data: frameworkId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetControlsByFrameworkId;
    })
)

export const addControl = async (control: controlInputType): Promise<controlType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                mutation addControl($data:AdminControlInput!){
                  adminAddBuilderControl(data:$data){
                    name
                    category
                    description
                    origin
                    organizationId
                    visibility
                    group
                    id
                    foundInFrameworksCounter
                    foundInFrameworks {
                        id
                        name
                    }
                    evidences{
                      categories
                      collectorId
                      description
                      id
                      name
                      origin
                      organizationId
                      visibility
                      foundInControlsCounter
                      foundInControls {
                          id
                          name
                      }
                      gap
                    }
                  }
                }
                `),
                variables: {
                    data: {...control},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminAddBuilderControl;
    })
)

export const editControl = async (control: controlType): Promise<controlType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editControl($data:AdminEditControlInput!){
                        adminEditBuilderControl(data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            foundInFrameworksCounter
                            foundInFrameworks {
                                id
                                name
                            }
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                                foundInControls {
                                    id
                                    name
                                }
                                gap
                            }
                        }
                    }
                `),
                variables: {
                    data: {...control},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminEditBuilderControl;
    })
)

export const deattachControl = async (controlId: string, frameworkId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deattachControl($data: AdminUnlinkControlInput!){
                  adminUnlinkControl(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: {controlId, frameworkId},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUnlinkControl.message;
    })
)

export const addEvidence = async (evidence: evidenceInputType): Promise<evidenceType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addEvidence($data:AdminAddEvidenceInput!){
                        adminAddBuilderEvidence(data:$data){
                            categories
                            collectorId
                            description
                            id
                            name
                            origin
                            organizationId
                            visibility
                            gap
                            foundInControlsCounter
                            foundInControls {
                                id 
                                name
                            }
                        }
                    }
                `),
                variables: {
                    data: {...evidence},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminAddBuilderEvidence;
    })
)


export const editEvidence = async ( evidence: evidenceType): Promise<evidenceType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editEvidence($data:AdminEvidenceInput!){
                        adminEditBuilderEvidence(data:$data){
                            categories
                            collectorId
                            description
                            id
                            name
                            origin
                            organizationId
                            visibility
                            gap
                            foundInControlsCounter
                            foundInControls {
                                id
                                name
                            }
                        }
                    }
                `),
                variables: {
                    data: {...evidence},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminEditBuilderEvidence;
    })
)

export const deattachEvidence = async (controlId: string, evidenceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deattachEvidence($data: AdminUnlinkEvidenceInput!){
                  adminUnlinkEvidence(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: {controlId, evidenceId},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUnlinkEvidence.message;
    })
)

export const getAllControlsDialogData = async (page: number, count: number, filters: FilterInput, name?: string): Promise<RequestControlWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getAllControlsDialogData($data:PaginationInput!, $name:String){
                  adminGetAllControlsDialogData(data:$data, name:$name){
                    controls{
                      category
                      description
                      group
                      id
                      name
                      origin
                      organizationId
                      visibility
                      foundInFrameworksCounter
                      evidences{
                        categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                      }
                    }
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                  }
                }
                `,
                variables: {
                    data: {page, count, filters},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetAllControlsDialogData;
    })
);

export const getRegulaitControlsDialogData = async (page: number, count: number, name: string): Promise<RequestControlWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query adminGetCompanyControlsDialogData($data:PaginationInput!, $name:String!){
                  adminGetCompanyControlsDialogData(data:$data, name:$name){
                    controls{
                      category
                      description
                      group
                      id
                      name
                      origin
                      organizationId
                      visibility
                      foundInFrameworksCounter
                      evidences{
                        categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                      }
                    }
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                  }
                }
                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetCompanyControlsDialogData;
    })
);

export const getPrivateControlsDialogData = async (page: number, count: number, name: string): Promise<RequestControlWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPrivateControlsDialogData($data:PaginationInput!, $name:String!){
                  adminGetPrivateControlsDialogData(data:$data, name:$name){
                    controls{
                      category
                      description
                      group
                      id
                      name
                      origin
                      organizationId
                      visibility
                      foundInFrameworksCounter
                      evidences{
                        categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                      }
                    }
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                  }
                }
                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPrivateControlsDialogData;
    })
);

export const getPublicControlsDialogData = async ( page: number, count: number, name: string): Promise<RequestControlWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPublicControlsDialogData($data:PaginationInput!, $name:String!){
                  adminGetPublicControlsDialogData( data:$data, name:$name){
                    controls{
                      category
                      description
                      group
                      id
                      name
                      origin
                      organizationId
                      visibility
                      foundInFrameworksCounter
                      evidences{
                        categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                      }
                    }
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                  }
                }
                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPublicControlsDialogData;
    })
);

export const getPublicAndPrivateControlsWithFilterPag = async (page: number, count: number, filters?: FilterInput): Promise<RequestControlWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicAndPrivateControlsWithFilterPag($data:PaginationInput!){
                        adminGetPublicAndPrivateControlsWithFilterPag(data:$data){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                foundInFrameworks {
                                    id
                                    name
                                }
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                    foundInControls {
                                        id
                                        name
                                    }
                                    gap
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    data: {page, count, filters},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPublicAndPrivateControlsWithFilterPag;
    })
);

export const linkControl = async (controlId: string, frameworkId: string): Promise<{controls: number, evidences: number}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation linkControl($data:AdminUnlinkControlInput!){
                  adminLinkControl(data:$data){
                    controls
                    evidences
                  }
                }
                `,
                variables: {
                    data: {frameworkId, controlId},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminLinkControl;
    })
);


export const getAllEvidecnesDialogData = async (page: number, count: number, filters?: FilterInput, name?: string): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAllEvidecnesDialogData($data:PaginationInput!, $name:String){
                        adminGetAllEvidencesDialogData(data:$data, name:$name){
                            evidences {
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                                gap
                            }
                            pageInfo {
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: {
                    data: {page, count, filters},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetAllEvidencesDialogData;
    })
);


export const getRegulaitEvidencesDialogData = async (page: number, count: number, name: string): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query adminGetCompanyEvidencesDialogData($data:PaginationInput!, $name:String!){
                  adminGetCompanyEvidencesDialogData(data:$data, name:$name){
                    evidences{
                      categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                    }
                    pageInfo{
                      page
                      total
                      sort
                      count
                    }
                  }
                }

                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetCompanyEvidencesDialogData;
    })
);

export const getPrivateEvidencesDialogData = async (page: number, count: number, name: string): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPrivateEvidencesDialogData($data:PaginationInput!, $name:String!){
                  adminGetPrivateEvidencesDialogData(data:$data, name:$name){
                    evidences{
                      categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                    }
                    pageInfo{
                      page
                      total
                      sort
                      count
                    }
                  }
                }

                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPrivateEvidencesDialogData;
    })
);

export const getPublicEvidencesDialogData = async (page: number, count: number, name: string): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPublicEvidencesDialogData( $data:PaginationInput!, $name:String!){
                  adminGetPublicEvidencesDialogData( data:$data, name:$name){
                    evidences{
                      categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                    }
                    pageInfo{
                      page
                      total
                      sort
                      count
                    }
                  }
                }

                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPublicEvidencesDialogData;
    })
);

export const getPublicAndPrivateEvidencesDialogData = async ( page: number, count: number, name: string): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPublicAndPrivateEvidencesDialogData($data:PaginationInput!, $name:String!){
                  adminGetPublicAndPrivateEvidencesDialogData(data:$data, name:$name){
                    evidences{
                      categories
                        collectorId
                        description
                        id
                        name
                        origin
                        organizationId
                        visibility
                        foundInControlsCounter
                        gap
                    }
                    pageInfo{
                      page
                      total
                      sort
                      count
                    }
                  }
                }

                `,
                variables: {
                    data: {page, count},
                    name,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPublicAndPrivateEvidencesDialogData;
    })
);

export const getPublicAndPrivateEvidencesWithFilterPag = async ( page: number, count: number, filters?: FilterInput): Promise<RequestEvidencesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicAndPrivateEvidencesDialogData($data:PaginationInput!){
                        adminGetPublicAndPrivateEvidencesWithFilterPag(data:$data){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                                foundInControls {
                                    id
                                    name
                                }
                                gap
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: {
                    data: {page, count, filters},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPublicAndPrivateEvidencesWithFilterPag;
    })
);

export const linkEvidence = async ( controlId: string, evidenceId: string, frameworkId: string | null): Promise<{controls: number, evidences: number}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation linkEvidence($data:AdminUnlinkEvidenceInput!){
                  adminLinkEvidence(data:$data){
                    controls
                    evidences
                  }
                }
                `,
                variables: {
                    data: {evidenceId, controlId, frameworkId},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminLinkEvidence;
    })
);

export const addFramework = async (framework: frameworkInputType): Promise<frameworkType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addFramework($data:AdminAddFrameworkInput!){
                  adminAddFramework(data:$data){
                    id
                    name
                    description
                    type
                    origin
                    logo
                    organizationId
                    controls
                    evidences
                    visibility
                  }
                }
                `,
                variables: {
                    data: {...framework},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminAddFramework;
    })
);

export const updateFramework = async (framework: frameworkInputType): Promise<frameworkType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addFramework($data:AdminAddFrameworkInput!){
                  adminUpdateFramework(data:$data){
                    id
                    name
                    description
                    type
                    origin
                    logo
                    organizationId
                    controls
                    evidences
                    visibility
                  }
                }
                `,
                variables: {
                    data: {...framework},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUpdateFramework;
    })
);

export const deleteControl = async (controlId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteControl($data:String!){
                  adminDeleteControl(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: controlId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminDeleteControl.message;
    })
);

export const deleteEvidence = async (evidenceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteEvidence($data:String!){
                  adminDeleteEvidence(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: evidenceId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminDeleteEvidence.message;
    })
);

export const getPrivatePolicies = async (page: number, count: number): Promise<RequestPoliciesWithFilterResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getPrivatePolicies($data:PaginationInput!){
                  adminGetPrivatePolicies(data:$data){
                    policies{
                      name
                      description
                      templates{
                        fileId
                        fileName
                      }
                      id
                      tag
                      origin
                      organizationId
                      visibility
                      pipeLinesCounter
                    }
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                  }
                }
                `,
                variables: {
                    data: {page, count},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetPrivatePolicies;
    })
);



export const addPolicy = async (policy: policyInputType): Promise<policyType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addCustomPolicy($data:AdminAddPolicyInput!){
                  adminAddPolicy(data:$data){
                    name
                    description
                    id
                    templates{
                      fileId
                      fileName
                    }
                    organizationId
                    origin
                    tag
                    visibility
                    pipeLinesCounter
                  }
                }
                `,
                variables: {
                    data: policy,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminAddPolicy;
    })
);


export const updatePolicy = async (policy: policyType): Promise<policyType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateCustomPolicy($data:AdminPolicyInput!){
                    adminUpdatePolicy(data:$data){
                        name
                        description
                        id
                        templates{
                          fileId
                          fileName
                        }
                        organizationId
                        origin
                        tag
                        visibility
                        pipeLinesCounter
                      }
                    }
                `,
                variables: {
                    data: policy,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUpdatePolicy;
    })
);


export const deletePolicy = async (policyId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deletePolicy($data:String!){
                  adminDeletePolicy(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data: policyId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminDeletePolicy.message;
    })
);

//---
export const deleteAllFiles = async (fileIds: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
               mutation deleteAllFiles($fileIds:AdminDeleteAllFilesInput!){
                  adminDeleteAllFile(fileIds:$fileIds){
                    message
                  }
                }
                `,
                variables: {
                    fileIds: {
                        fileIds
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return 'done';
    })
)

export const uploadFile = async (file: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation uploadFile($file:String!){
                  adminUploadFile(file:$file){
                    message
                  }
                }

                `,
                variables: {
                    file,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUploadFile.message;
    })
)

export const addControlsAndEvidencesFromFiles = async (files: TBuilderAdminAddFiles): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addControlsAndEvidencesFromFiles($files: AdminBuilderFilesInput!){
                    addControlsAndEvidencesFromFiles(files:$files){
                        message
                    }
                }
                `,
                variables: {
                    files,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addControlsAndEvidencesFromFiles.message;
    })
)

export const addGapFromFiles = async (files: TBuilderAdminAddGapFiles): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addGapFromFiles($files: AdminBuilderGapFilesInput!){
                    addGapFromFiles(files:$files){
                        message
                    }
                }
                `,
                variables: {
                    files,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addGapFromFiles.message;
    })
)


// export const getFilesByFolderId = async (): Promise<TDocumentFile[]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     query adminGetFilesByFolderId{
//                         adminGetFilesByFolder{
//                             id
//                             workspaceId
//                             documentId
//                             status
//                             origin
//                             description
//                             type
//                             name
//                             folderId
//                             version
//                             latest
//                             parentId
//                             visible
//                             lastEditDate
//                             editorId
//                             creatorId
//                             createdDate
//                             automationId
//                             tags
//                             accessRules{
//                                 rule
//                             }
//                             data{
//                                 editor
//                                 config{
//                                     page
//                                 }
//                                 innerHtml
//                                 variables{
//                                     type
//                                     title
//                                     key
//                                     order
//                                     value
//                                     format
//                                     description
//                                 }
//                             }
//                         }
//                     }
//                 `),
//                 variables: {}
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.adminGetFilesByFolder;
//     })
// )
//
// export const createDoc = async (type: string, name: string, innerHtml: string, editor: TFileContent, variables: fileVariableType[], id: string | null): Promise<TDocumentFile> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation adminCreateDocument($newValue:adminCreateOrUpdateDocumentDto!){
//                         adminCreateDocument(newValue:$newValue){
//                             id
//                             workspaceId
//                             documentId
//                             status
//                             origin
//                             description
//                             type
//                             name
//                             folderId
//                             version
//                             latest
//                             parentId
//                             visible
//                             lastEditDate
//                             editorId
//                             creatorId
//                             createdDate
//                             automationId
//                             tags
//                             accessRules{
//                                 rule
//                             }
//                             data{
//                                 editor
//                                 config{
//                                     page
//                                 }
//                                 innerHtml
//                                 variables{
//                                     type
//                                     title
//                                     key
//                                     order
//                                     value
//                                     format
//                                     description
//                                 }
//                             }
//                         }
//                     }
//                 `),
//                 variables: {
//                     newValue: {
//                         id,
//                         type,
//                         data: {
//                             innerHtml,
//                             variables,
//                             editor,
//                         },
//                         name,
//                     }
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.adminCreateDocument;
//     })
// )
//
// export const updateDoc = async (id: string, type: string, name: string, innerHtml: string, editor: TFileContent, variables: fileVariableType[]): Promise<TDocumentFile> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation adminUpdateDoc($newValue:adminCreateOrUpdateDocumentDto!){
//                         adminUpdateDocument(newValue:$newValue){
//                             id
//                             workspaceId
//                             documentId
//                             status
//                             origin
//                             description
//                             type
//                             name
//                             folderId
//                             version
//                             latest
//                             parentId
//                             visible
//                             lastEditDate
//                             editorId
//                             creatorId
//                             createdDate
//                             automationId
//                             tags
//                             accessRules{
//                                 rule
//                             }
//                             data{
//                                 editor
//                                 config{
//                                     page
//                                 }
//                                 innerHtml
//                                 variables{
//                                     type
//                                     title
//                                     key
//                                     order
//                                     value
//                                     format
//                                     description
//                                 }
//                             }
//                         }
//                     }
//                 `),
//                 variables: {
//                     newValue: {
//                         id,
//                         type,
//                         data: {
//                             innerHtml,
//                             variables,
//                             editor,
//                         },
//                         name,
//                     }
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.adminUpdateDocument;
//     })
// )
//
// export const deleteDoc = async (id: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     mutation adminDeleteDoc($id:String!){
//                         adminDeleteDocument(id:$id){
//                             message
//                         }
//                     }
//                 `,
//                 variables: {
//                     id
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.adminDeleteDocument.message;
//     })
// )



export const adminGetDocumentsRefactorApi = async (data: AdminGetDocumentsRefactorQueryVariables, signal?: AbortSignal): Promise<AdminGetDocumentsRefactorQuery["adminGetDocumentsRefactor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adminGetDocumentsRefactor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetDocumentsRefactor;
    })
)

export const adminGetDocumentByIdRefactorApi = async (data: AdminGetDocumentByIdRefactorQueryVariables, signal?: AbortSignal): Promise<AdminGetDocumentByIdRefactorQuery["adminGetDocumentByIdRefactor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adminGetDocumentByIdRefactor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGetDocumentByIdRefactor;
    })
)

export const adminCreateDocumentsRefactorApi = async (data: AdminCreateDocumentsRefactorMutationVariables, signal?: AbortSignal): Promise<AdminCreateDocumentsRefactorMutation["adminCreateDocumentsRefactor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adminCreateDocumentsRefactor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminCreateDocumentsRefactor;
    })
)

export const adminUpdateDocumentsRefactorApi = async (data: AdminUpdateDocumentsRefactorMutationVariables, signal?: AbortSignal): Promise<AdminUpdateDocumentsRefactorMutation["adminUpdateDocumentsRefactor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adminUpdateDocumentsRefactor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminUpdateDocumentsRefactor;
    })
)

export const adminDeleteDocumentsRefactorApi = async (data: AdminDeleteDocumentsRefactorMutationVariables, signal?: AbortSignal): Promise<AdminDeleteDocumentsRefactorMutation["adminDeleteDocumentsRefactor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adminDeleteDocumentsRefactor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminDeleteDocumentsRefactor;
    })
)
