import {Flex} from "../../../../../shared/components/Layouts";
import {Spinner} from "../../../../../shared/components/Spinner";
import {usePolicies} from "../../hooks/usePolicies";
import {BuilderPoliciesTable} from "../../components/builderPoliciesTable";
import {AddPolicyComponent} from "../../components/dialogs/policy/addPolicyComponent";
import {UpdatePolicyComponent} from "../../components/dialogs/policy/updatePolicyComponent";
import {DeletePolicyDialog} from "../../components/dialogs/policy/deletePolicy";

export const Policies = () => {
    const {isLoading, isLoadingDialogs, isOpenAdd, selectedPolicy} = usePolicies();

    return(
        <Flex w={'100%'} h={'100%'} jc={'space-between'} m={'10px 0 0 0'}>
            <BuilderPoliciesTable />
            <AddPolicyComponent isOpen={isOpenAdd.isOpen} />
            <UpdatePolicyComponent isOpen={selectedPolicy !== null} policyId={selectedPolicy}/>
            <DeletePolicyDialog />
            {(isLoading || isLoadingDialogs) && <Spinner />}
        </Flex>
    )
}