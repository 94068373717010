import {accessManagementInitialState, TPermission, TRole} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../shared/redux/rootReducer";
import {CreateAdminRole, CreatePermissionByAdmin,
    DeletePermissionByAdmin, DeleteRoleByAdmin, EditAdminRole, GetAdminRoles, UpdatePermissionByAdmin} from "./actions";

export const initialState: accessManagementInitialState = {
    roles: [],
    permissions: [],
    permissionsPageInfo: {
        total: 1,
        count: 10,
        page: 0,
        sort: ''
    },
    loadings: {
        rolesList: false,
        rolesCreate: false,
        rolesUpdate: false,
        rolesDelete: false,

        permissionsCreate: false,
        permissionsUpdate: false,
        permissionsDelete: false,
    },
    dialog: {
        createRole: false,
        editRole: {
            isOpen: false,
            role: null,
        },
        deleteRole: {
            isOpen: false,
            role: null,
        },
        previewRole: {
            isOpen: false,
            role: null,
        },

        createPermission: false,
        deletePermission: {
            isOpen: false,
            permission: null,
        },
        editPermission: {
            isOpen: false,
            permission: null,
        },
        managePermissions: false
    }
}

export const AccessManagementSlice = createSlice({
    name: 'accessManagement',
    initialState,
    reducers: {
        openCreateRoleDialog: (slice) => {slice.dialog.createRole = true},
        hideCreateRoleDialog: (slice) => {slice.dialog.createRole = false},

        openEditRoleDialog: (slice, {payload}: {payload: TRole}) => {slice.dialog.editRole = {isOpen: true, role: payload}},
        hideEditRoleDialog: (slice) => {slice.dialog.editRole = {isOpen: false, role: null}},

        openDeleteRoleDialog: (slice, {payload}: {payload: TRole}) => {slice.dialog.deleteRole = {isOpen: true, role: payload}},
        hideDeleteRoleDialog: (slice) => {slice.dialog.deleteRole = {isOpen: false, role: null}},

        openPreviewRoleDialog: (slice, {payload}: {payload: TRole}) => {slice.dialog.previewRole = {isOpen: true, role: payload}},
        hidePreviewRoleDialog: (slice) => {slice.dialog.previewRole = {isOpen: false, role: null}},
        //--

        openCreatePermissionDialog: (slice) => {slice.dialog.createPermission = true},
        hideCreatePermissionDialog: (slice) => {slice.dialog.createPermission = false},

        openEditPermissionDialog: (slice, {payload}: {payload: TPermission}) => {slice.dialog.editPermission = {isOpen: true, permission: payload}},
        hideEditPermissionDialog: (slice) => {slice.dialog.editPermission = {isOpen: false, permission: null}},

        openDeletePermissionDialog: (slice, {payload}: {payload: TPermission}) => {slice.dialog.deletePermission = {isOpen: true, permission: payload}},
        hideDeletePermissionDialog: (slice) => {slice.dialog.deletePermission = {isOpen: false, permission: null}},

        //
        openManagePermissionsDialog: (slice) => {slice.dialog.managePermissions = true},
        hideManagePermissionsDialog: (slice) => {slice.dialog.managePermissions = false},


        cleanUp: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            //GetAdminRoles
            .addCase(GetAdminRoles.pending, (slice) => {
                slice.loadings.rolesList = true;
            })
            .addCase(GetAdminRoles.rejected, (slice) => {
                slice.loadings.rolesList = false;
            })
            .addCase(GetAdminRoles.fulfilled, (slice, {payload}) => {
                slice.loadings.rolesList = false;
                slice.roles = payload.roles;
                slice.permissions = payload.permissions;
            })
        //CreateAdminRole
            .addCase(CreateAdminRole.pending, (slice) => {
                slice.loadings.rolesCreate = true;
            })
            .addCase(CreateAdminRole.rejected, (slice) => {
                slice.loadings.rolesCreate = false;
            })
            .addCase(CreateAdminRole.fulfilled, (slice, {payload}) => {
                slice.loadings.rolesCreate = false;
                slice.dialog.createRole = false;
                slice.dialog.managePermissions = false;

                slice.roles.push(payload);
            })
        //DeleteRoleByAdmin
            .addCase(DeleteRoleByAdmin.pending, (slice) => {
                slice.loadings.rolesDelete = true;
            })
            .addCase(DeleteRoleByAdmin.rejected, (slice) => {
                slice.loadings.rolesDelete = false;
            })
            .addCase(DeleteRoleByAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.rolesDelete = false;
                slice.dialog.deleteRole = {isOpen: false, role: null};

                slice.roles = slice.roles.filter(e => e.id !== payload);
            })
        //EditAdminRole
            .addCase(EditAdminRole.pending, (slice) => {
                slice.loadings.rolesUpdate = true;
            })
            .addCase(EditAdminRole.rejected, (slice) => {
                slice.loadings.rolesUpdate = false;
            })
            .addCase(EditAdminRole.fulfilled, (slice, {payload}) => {
                slice.loadings.rolesUpdate = false;
                slice.dialog.editRole = {isOpen: false, role: null};

                slice.roles = slice.roles.map(e => e.id === payload.id ? payload : e);
            })
        //CreatePermissionByAdmin
            .addCase(CreatePermissionByAdmin.pending, (slice) => {
                slice.loadings.permissionsCreate = true;
            })
            .addCase(CreatePermissionByAdmin.rejected, (slice) => {
                slice.loadings.permissionsCreate = false;
            })
            .addCase(CreatePermissionByAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.permissionsCreate = false;
                slice.dialog.createPermission = false;

                slice.permissions.push(payload);
            })
        //UpdatePermissionByAdmin
            .addCase(UpdatePermissionByAdmin.pending, (slice) => {
                slice.loadings.permissionsUpdate = true;
            })
            .addCase(UpdatePermissionByAdmin.rejected, (slice) => {
                slice.loadings.permissionsUpdate = false;
            })
            .addCase(UpdatePermissionByAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.permissionsUpdate = false;
                slice.dialog.editPermission = {isOpen: false, permission: null};

                slice.permissions = slice.permissions.map(e => e.name === payload.name ? payload : e);
            })
        //DeletePermissionByAdmin
            .addCase(DeletePermissionByAdmin.pending, (slice) => {
                slice.loadings.permissionsDelete = true;
            })
            .addCase(DeletePermissionByAdmin.rejected, (slice) => {
                slice.loadings.permissionsDelete = false;
            })
            .addCase(DeletePermissionByAdmin.fulfilled, (slice, {payload}) => {
                slice.loadings.permissionsDelete = false;
                slice.dialog.deletePermission = {isOpen: false, permission: null};

                slice.permissions = slice.permissions.filter(e => e.name !== payload);
            })
    }
});

export const AccessManagementReducer = AccessManagementSlice.reducer;

export const {
    openCreateRoleDialog,
    hideCreateRoleDialog,

    openEditRoleDialog,
    hideEditRoleDialog,

    openDeleteRoleDialog,
    hideDeleteRoleDialog,

    openPreviewRoleDialog,
    hidePreviewRoleDialog,

    openCreatePermissionDialog,
    hideCreatePermissionDialog,

    openEditPermissionDialog,
    hideEditPermissionDialog,

    openDeletePermissionDialog,
    hideDeletePermissionDialog,

    openManagePermissionsDialog,
    hideManagePermissionsDialog,

    cleanUp

} = AccessManagementSlice.actions;

const selectSelf = (state: AppState):accessManagementInitialState => state.AccessManagement as accessManagementInitialState;

export const dialogs = createSelector(selectSelf, state => state.dialog);
export const loading = createSelector(selectSelf, state => state.loadings);
export const permissions = createSelector(selectSelf, state => state.permissions);
export const roles = createSelector(selectSelf, state => state.roles);
export const permissionsPageInfo = createSelector(selectSelf, state => state.permissionsPageInfo);

