import React, {FC, useState} from "react";
import {menu, WIDTH_EXPANDED} from "../constants";
import {LeftSideBarItem, TAccordion} from "../types";
import {ExpandedItem} from "./ExpandedItem";
import {AnimatedLeftSideBar} from "../styles";

type PropTypes = {
    activeRoute: LeftSideBarItem[];
};

export const ExtendedMenu: FC<PropTypes> = (props: PropTypes) => {
    const { activeRoute } = props;
    const [status, setExpanded] = useState<TAccordion>({
        accordion: null,
        expanded: false
    });
    const [opacity, setOpacity] = useState("0.9");
    React.useEffect(() => {
        setTimeout(() => setOpacity("1"), 0)
    }, []);
    return (
        <AnimatedLeftSideBar w={WIDTH_EXPANDED} opacity={opacity} expanded>
            {menu?.map((item) =>
                <ExpandedItem key={`ii:${item.title}`}
                              item={item}
                              activeRoute={activeRoute}
                              setExpanded={setExpanded}
                              status={status}
                />)}
        </AnimatedLeftSideBar>
    )
};
