//core
import styled from 'styled-components';
//other
import {
    Dialog as dialog,
    DialogContent as dialogContent,
    Paper as paper,
    TableCell as tablecell,
    TableContainer as tc,
    TextField as textfield
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';


import {Box, Flex} from '../../../../../shared/components/Layouts';
import {OutlinedInput} from "@mui/material";


//accessManagement
export const NavButton = styled.div<{isMain: boolean, margin?: string, padding?: string}>`
    padding: ${props => props.padding ? props.padding : "0 20px"};
    height: 46px;
    margin: ${props => props.margin ? props.margin : "none"};
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 40px;
    background-color: ${props => props.theme.colors[props.isMain ? "navButtonActive" : "none"]};

    cursor: pointer;
`;

//Table styles

export const Paper = styled(paper)`
    width: 100%;
    margin-top: 18px;
    color: white;
    border-radius: 25px !important;
    background-color: white !important;
    overflow: hidden;

    .MuiTableCell-stickyHeader{
        background-color: white !important;
    }

    .MuiTableCell-stickyHeader:first-child{
        border-radius: 25px 0 0 0 !important;
    }

    .MuiTableCell-stickyHeader:last-child{
        border-radius: 0 25px 0 0 !important;
    }
    `;

export const TableContainer = styled(tc)`
    padding: 0;
`;

export const TableCell = styled(tablecell)<{borderBottom?: string, minWidth?: number, p?: string, maxWidth?:number, width?:string, margin?:string, radius?: boolean}>`
    ${props => props.radius && `
        .MuiTableCell-stickyHeader {
            border-radius: 25px 25px 0 0 !important;
        }
        border-radius: 25px 25px 0 0 !important;
    `}
    
    ${props => props.borderBottom && `border-bottom: ${props.borderBottom} !important;`}
    ${props => props.minWidth && `min-width: ${props.minWidth}px !important;`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth}px !important;`}
    ${props => `padding: ${props.p ? props.p : "10px"} !important;`}
    ${props => props.width && `width: ${props.width} !important;`}
    ${props => props.margin && `margin: ${props.margin} !important;`}


`;

export const Pagination = styled(TablePagination)`
    min-height: 0;
    height: 70px;
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom: none !important;
`;

export const Switch = (active: boolean) => {
    if(active){
        return (<Flex background={"#2681FF"} w="44.2px" h="22px" p="4px" br="41px" ai="center" jc="flex-end">
                    <Box border="2px solid white" background={"#2681FF"} w="14px" h="14px" br="50%" />
                </Flex>)
    }else{
        return (<Flex background={"#878BA1"} w="44.2px" h="20px" p="4px 0 4px 8px" br="41px" ai="center" jc="flex-start">
                    <Box background={"white"} w="3px" h="12px" br="10px"/>
                </Flex>)
    }

}

// Delete Dialog
export const DialogContent = styled(dialogContent)`
    width: 30vw;
    // height: 37vh ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Dialog = styled(dialog)`
    .MuiDialog-paper{
        border-radius: 25px;
        box-shadow: 0 0 25px 2px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
    }
    .MuiDialogContent-root{

    }
`;

export const DeleteDialogInput = styled(textfield)`
    width: 100%;

    .MuiFilledInput-input{
        background-color: #ebebeb !important;
    }

    .MuiFormLabel-root.Mui-focused{
        color: #A0A2B1 !important;
    }

    .MuiFilledInput-root{
        height: 45px;
        // font-size: 12px;
    }

`;

//FilterSideBar
export const SideBarAutoSelectContainer = styled.div`
    .MuiAutocomplete-root{
        width: 100% !important;
        background-color: #f5f5f5 !important;
    }

    .MuiFilledInput-root:hover{
        background-color: #f5f5f5 !important;
        border-bottom: none;
    }

    .MuiFilledInput-root{
        background-color: inherit;
    }

    .MuiFormLabel-root{
        color: #212121 !important;
    }  

    .MuiFormLabel-root.Mui-focused{
        color: #212121 !important;
    }

    .MuiFilledInput-underline{
        border-bottom: none;
    }

    .MuiFilledInput-underline:before{
        border-bottom: none;
    }

    .MuiFilledInput-underline:after{
        border-bottom: none;
    }

    .MuiFilledInput-underline-focused{
        border-bottom: none;
        // border-bottom-color: #ebebeb !important;
    }
`;

//addUser Side Bar

export const AddUserInput = styled(textfield)`
    width: 100%;
    height: 30px !important;
    color: #212121 !important;
    
    .MuiFilledInput-input{
        background-color: #f5f5f5 !important;
    }

    .MuiFormLabel-root.Mui-focused{
        background-color: #f5f5f5 !important;
    }

    .MuiFilledInput-root{
        color: #212121 !important;
        // height: 35px;
        // font-size: 12px;
    }

`;

//change user dialog
// Delete Dialog
export const UpdateUserDialogContent = styled(dialogContent)`
    width: 30vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const UpdateUserDialog = styled(dialog)`
    width: 100% !important;
    height: 100% !important;
    .MuiDialog-paper{
        max-width: 2600px;
        max-height:2500px;
        width: 30vw !important;
        height: 80vh !important;
    };
    .MuiDialog-paper{
        width: 60vw;
        height: 70vh;
        border-radius: 25px;
        box-shadow: 0 0 25px 2px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
    };
    .MuiDialogContent-root{

    }
`;

export const UpdateUserDeleteDialogInput = styled(textfield)`
    width: 80%;

    .MuiFilledInput-input{
        background-color: #ebebeb !important;
    }

    .MuiFormLabel-root.Mui-focused{
        color: #A0A2B1 !important;
    }

    .MuiFilledInput-root{
        height: 45px;
        // font-size: 12px;
    }

`;

export const WarningDialog = styled(Dialog)``;

export const CustomOutlinedInput = styled(OutlinedInput)`
    border-radius: 10px !important;
    height: 37px !important;
    &:focus{
        outline: none !important;
        border: none !important;
    }
    &:hover{
        outline: none !important;
        border: none !important;
    }
    & .Mui-focused{
        outline: none !important;
        border: none !important;
    }
    
`;


