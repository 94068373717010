import {RefObject} from "react";
import {EditorItemPage} from "./components/editorItemPage";
import {EditorItemRow} from "./components/editorItemRow";
import {EditorItemColumn} from "./components/editorItemColumn";
import {EditorItemBlock} from "./components/editorItemBlock";
import {EditorItemPagebreak} from "./components/editorItemPagebreak";
import {TToolBarSlice} from "./components/editorToolBar/types";
import {ToolBarProps} from "./components/editorToolBar";
import {TextComponent} from "./components/TextComponent";
import {EditorItemTable} from "./components/editorItemTable";
import {DocumentExecutorModel, NewDocDataVariableModel, RecipientModel} from "../../GQLTypes";
import {EditorItemPdfPage} from "./components/editorItemPdfPage";

export interface IStatusProps {
    status: {
        currentEmail: string;
        currentExecutorStatus: string | null;
        currentFileStatus: string;

        isUserCurrentActor: boolean;
        isUserRecipient: boolean;
        isUserApprover: boolean;
        isUserEditor: boolean;

        isAbleToSendDocument: boolean;
        isAbleToRejectDocument: boolean;
        isAbleToApproveDocument: boolean;
        isAbleToResendInvite: boolean;
        isAbleToSkip: boolean;
        isAbleToInvalidate: boolean;

        isMain: boolean;
        isStaff: boolean;
        isAdmin: boolean;

        isSigning: boolean;
        isApproval: boolean;
        isDraft: boolean;

        isPdf: boolean;
        createdFromPdf: boolean;
        isVerifyEditablePdf: boolean;
        isVerifyUploadedPdf: boolean;
        isVerifyPdf: boolean;
    };
    isLoading: TEditorIsLoading;

    readonly?: boolean;
    execution?: DocumentExecutorModel;
}

export type TFileContent = (TEditorPageElementData | TEditorPdfPageElementData)[];

// Data in Page
export interface EditorPdfPageData {
    index: number; //from 1
    pdfFileId: string;

    content: [];
}
export type TEditorPdfPageElementData = {
    id: string,
    type: 'pdfPage',
    data: EditorPdfPageData,
}

// Data in Page
export interface EditorPageData {
    orientation: TPageOrientation;
    size: TPageSizeTitle;

    headerHeight: number;
    footerHeight: number;

    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;

    header: TEditorRowElementData[];
    footer: TEditorRowElementData[];
    content: TEditorRowElementData[];
}
export type TEditorPageElementData = {
    id: string,
    type: 'page',
    data: EditorPageData,
}

// Data in Row
export interface EditorRowData {
    content: (TEditorColumnElementData | TEditorBlockElementData | TEditorPagebreakElementData)[];
}
export type TEditorRowElementData = {
    id: string,
    type: 'row',
    data: EditorRowData,
}

// Data in Column
export interface EditorColumnData {
    content: TEditorRowElementData[];
    width: number,
}
export type TEditorColumnElementData = {
    id: string,
    type: 'column',
    data: EditorColumnData,
}

// Data in Block
export interface EditorBlockData {
    content: TEditorElementData<any>[];
    width: number,
}
export type TEditorBlockElementData = {
    id: string,
    type: 'block',
    data: EditorBlockData,
}

// Data in Pagebreak
export interface EditorPagebreakData {
    content: [];
    positionTop: number;
}
export type TEditorPagebreakElementData = {
    id: string,
    type: 'pagebreak',
    data: EditorPagebreakData,
}

// Data in Text
export interface EditorTextData {
    content: [];
    innerHtml: string;
    alignText: string;
}
export type TEditorTextElementData = {
    id: string,
    type: 'text',
    data: EditorTextData,
}

// Data in Image
export interface EditorImageData {
    content: [];
    src: string | null; // fileId
    align: 'left' | 'center' | 'right';
    widthPx: number;
    heightPx: number;
}
export type TEditorImageElementData = {
    id: string,
    type: 'image',
    data: EditorImageData,
}

// Data in List
export interface EditorListData {
    content: TEditorListLiElementData[];
    type: 'ordered' | 'unordered';
    // listStyleType: 'decimal' | 'upper-roman' | 'circle' | 'disc' | 'square';
    listStyleType: 'decimal' | 'circle';
}
export type TEditorListElementData = {
    id: string,
    type: 'list',
    data: EditorListData,
}

// Data in List Li
export interface EditorListLiData {
    content: TEditorListLiElementData[];
    textBlock: TEditorTextElementData | null;
}
export type TEditorListLiElementData = {
    id: string,
    type: 'listElement',
    data: EditorListLiData,
}


// Data in Table
export interface EditorTableData {
    content: TEditorTableRowElementData[];
    // width: number[],
    // size: [number, number]; // [rows, columns]
}
export type TEditorTableElementData = {
    id: string,
    type: 'table',
    data: EditorTableData,
}

// Data in TableRow
export interface EditorTableRowData {
    content: TEditorTableCellElementData[];
}
export type TEditorTableRowElementData = {
    id: string,
    type: 'tableRow',
    data: EditorTableRowData,
}

// Data in TableCell
export interface EditorTableCellData {
    content: TEditorRowElementData[];
    width: number;
    colSpan: number;
    rowSpan: number;
}
export type TEditorTableCellElementData = {
    id: string,
    type: 'tableCell',
    data: EditorTableCellData,
}

// Common Data
export type TEditorElementData<TData extends object= {}> = {
    id: string,
    type: string,
    data: {
        content: TEditorElementData[],
    } & TData,
}

export type TEditorVariableData = NewDocDataVariableModel & {count: number};

export type TFillableFieldFromDB = {
    id: string;
    type: TEditorFillableFieldItemType[keyof TEditorFillableFieldItemType],
    pageId: string;

    position: {x: number, y: number, metaPageHeight?: number | null},
    size: {width: number, height: number},

    isRequired?: boolean | null;
};

// export type TEditorRecipientFromDB = {
//     fields: TFillableFieldFromDB[];
//     actor: TEditorActor;
//     order: number;
//     message: string;
// };

//Fillable block data
export type TEditorFillableBlockData = TFillableFieldFromDB & {
    actor: TEditorActor;
    role: string;
    sourceEmailRole: string;
    data: string; //fileId or text
};

export type CommonRefObject =
    | RefObject<EditorItemPdfPage>
    | RefObject<EditorItemPage>
    | RefObject<EditorItemRow>
    | RefObject<EditorItemColumn>
    | RefObject<EditorItemPagebreak>
    | RefObject<EditorItemBlock>
    | RefObject<TextComponent>
    | RefObject<EditorItemTable>
    ;

export type TPageOrientation = 'portrait' | 'landscape';
export type TPageSizeTitle =
    // | 'Letter'
    // | 'A2'
    // | 'A3'
    | 'A4'
    // | 'A5'
    // | 'A6'
    // | 'A7'
    // | 'A8'
;

export type TEditorBlockItemType = {
    TEXT: 'text',
    IMAGE: 'image',
    TABLE: 'table',
    PAGEBREAK: 'pagebreak',
    LIST: 'list',

    COLUMN: 'column',
    BLOCK: 'block',
};
export const EditorBlockItemType: TEditorBlockItemType = {
    TEXT: 'text',
    IMAGE: 'image',
    TABLE: 'table',
    PAGEBREAK: 'pagebreak',
    LIST: 'list',

    COLUMN: 'column',
    BLOCK: 'block',
}

export type TEditorFillableFieldItemType = {
    TEXT: 'fillable-text',
    SIGN: 'fillable-sign',
};
export const EditorFillableFieldItemType: TEditorFillableFieldItemType = {
    TEXT: 'fillable-text',
    SIGN: 'fillable-sign',
}

export type TResize = { x: number, px: number, percent: number };

export type ValueOf<T> = T[keyof T];

export type WrappedComponentType<C extends { WrappedComponent: any }> = InstanceType<C['WrappedComponent']>;

export interface MapDispatchToPropsObject {
    setToolBar: (toolBar: Partial<TToolBarSlice>, ids: string[]) => void
}

///////
export type TEditorActor = {
    email: string,
    firstName: string,
    lastName: string,
}

export type TEditorStageApprover = {
    id?: string | null,
    order: number,
    groups: TEditorGroupApprover[],
    eta: number,
}

export type TEditorGroupApprover = {
    id?: string | null,
    actors: TEditorActor[],
    name: string,
    isRequired: boolean,
    message?: string | null,
}

export type TEditorRecipient = RecipientModel;

export type TEditorActorsConfigFromDB = {
    approvers: TEditorStageApprover[],
    recipients: TEditorRecipient[],
    editors: TEditorActor[],
}
export type TEditorActorsConfigTemporary = {
    approvers: TEditorStageApprover[],
    recipients: (TEditorRecipient & {sourceEmailRole: string})[],
}

///////
export type TChangeTypeOfBlock =
    | {type: TEditorBlockItemType["BLOCK"], data: EditorBlockData}
    | {type: TEditorBlockItemType["COLUMN"], data: EditorColumnData}
    | {type: 'row', data: EditorRowData}
    | {type: 'rowArray', data: EditorRowData[]}
    ;



///////
export interface FunctionsForToolBar {
    setToolBar: MapDispatchToPropsObject["setToolBar"],
    setTextByToolBar: ToolBarProps["toolBarHandler"],

    setActiveBlock: (ids: string[]) => void,
}
export interface getToolBarProps extends IStatusProps {
    getToolBarState: () => TToolBarSlice,
}

export interface EditorComponentsMethods<T extends object> extends FunctionsForToolBar {
    getData: () => TEditorElementData<T>,
    // copySelf: () => TEditorElementData<T>,
    onClickAway: () => void,
    setFocus: () => void,
}


//////
export type TChangeVariableEventData = {
    id: string,
    value: string,
}

export type TSetActiveBlockEventData = {
    ids: string[],
}

export type TSetForceHighlightedBlocksEventData = {
    ids: string[],
}

export type TSetHighlightedVariableEventData = {
    id: string | null,
}

export type TSetListElementActiveEventData = {
    id: string | null,
    byBackspace?: boolean,
    insertHtml?: string,
}
export type TDeleteVariableEventData = {
    id: string,
}
export type TDeleteBlockEventData = {
    id: string,
}
export type TOpenDialogEventData = {
    isOpen: boolean,
}
export type TUpdateRecipientEventData = {
    sourceEmailRole: string,
    role: string,
    actor: TEditorActor,
}[]

export type TSetPagePropertiesMenuEventData = {
    id: string,

    data: {
        orientation: TPageOrientation;
        size: TPageSizeTitle;

        marginTop: number;
        marginBottom: number;
        marginLeft: number;
        marginRight: number;
    }
} | undefined;

export type TBlockCreatedByDropEventData = TSetActiveBlockEventData;
export type TUpdateDataInFillableFieldEventData = {
    fieldId: string,
    value: string
}[];

export type TCommonDialogOpenEventData = {
    type: 'noRecipients' | 'emptyRecipient' | 'notAssignedRole' | 'noAttachedField' | 'emptyTitle' | 'onReject' | 'notFilledField' | 'onInvalidate' | 'resendInviteGroup' | 'resendInviteRecipient';
    isOpen: boolean;
    data: string[];
};

export type TAddFillableFieldEventData = Omit<TEditorFillableBlockData, 'id'> & {id?: string, withoutDxDy?: boolean};

///
export type TEditorDragElementData = {type: string, block?: TEditorElementData<EditorBlockData>, getData?: () => TEditorElementData<EditorBlockData>};
export type TEditorDragElementFillableFieldData = TAddFillableFieldEventData;



export type TBlockWithHtml = {html: string, type: 'block' | 'list'};


export type TEditorIsLoading = {
    isSave: boolean;
    isPrev: boolean;
    isInvalidating: boolean;
    isRejecting: boolean;
    isApproving: boolean;
    isReinviting: boolean;
    isSkipping: boolean;
    isFillingField: boolean;
    isSending: boolean;
    isChangingEtaInProgress: boolean;
}

export const DRAFT_STATUS = "DRAFT";
export const SHARED_DRAFT_STATUS = "SHARED_DRAFT";
export const APPROVAL_STATUS = "APPROVAL";
export const REMARKS_STATUS = "REMARKS";
export const SIGNING_STATUS = "SIGNING";
export const COMPLETED_STATUS = "COMPLETED";
export const TERMINATED_STATUS = "TERMINATED";

//
export const PENDING_STATUS = "PENDING";
export const IN_PROGRESS_STATUS = "IN_PROGRESS";
export const REJECTED_STATUS = "REJECTED";
export const APPROVED_STATUS = "APPROVED";
export const SKIPPED_STATUS = "SKIPPED";


export type TDocumentStatus = typeof DRAFT_STATUS | typeof SHARED_DRAFT_STATUS |
    typeof APPROVAL_STATUS | typeof REMARKS_STATUS | typeof SIGNING_STATUS  |
    typeof COMPLETED_STATUS | typeof TERMINATED_STATUS;

export type TDocumentExecutorStatus = typeof PENDING_STATUS | typeof IN_PROGRESS_STATUS |
    typeof REJECTED_STATUS | typeof APPROVED_STATUS | typeof SKIPPED_STATUS;

export interface IKeyIndex {
    key: string;
    index: number;
}

export const ItemTypes = {
    CARD: 'card',
}