import axiosSync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {PaginationInput} from "../../../../shared/types";
import {getExtendedCollabResponse, getSmallCollabsResponse} from "../types";

export const getShortCollabs = async (pageInfo: PaginationInput): Promise<getSmallCollabsResponse> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getShortCollabsByAdmin($pageInfo:PaginationInput!){
                      getShortCollabsByAdmin(pageInfo:$pageInfo){
                        pageInfo{
                          total
                          sort
                          count
                          page
                        }
                        collabs{
                          publicId
                          firstName
                          lastName
                          email
                          region
                          active
                        }
                      }
                    }
                `,
                variables: {
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortCollabsByAdmin;
    })
)

export const getExtendedCollab = async (id: string): Promise<getExtendedCollabResponse> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    query getExtendedCollabByAdmin($id:String!){
                      getExtendedCollabByAdmin(id:$id){
                        collab{
                          publicId
                          firstName
                          lastName
                          email
                          region
                          active
                          lastLoginDate
                          createDate
                          blockedReason
                          organizationName
                        }
                        orgs{
                          id
                          name
                          role
                          blocked
                          subscription
                        }
                      }
                    }
                `,
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getExtendedCollabByAdmin;
    })
)

export const blockUser = async (id: string, reason: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation blockUserByAdmin($reason:String!, $id:String!){
                      blockUserByAdmin(reason:$reason, id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                    reason
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.blockUserByAdmin.message;
    })
)

export const activateUser = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation activateUserByAdmin($id:String!){
                      activateUserByAdmin(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.activateUserByAdmin.message;
    })
)

export const deleteUser = async (id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation deleteUserByAdmin($id:String!){
                      deleteUserByAdmin(id:$id){
                        message
                      }
                    }
                `,
                variables: {
                    id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteUserByAdmin.message;
    })
)

export const adminGenerateRecoveryCodesByPublicId = async (id: string): Promise<string[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                    mutation adminGenerateRecoveryCodesByPublicId($publicId:String!){
                      adminGenerateRecoveryCodesByPublicId(publicId:$publicId)
                    }
                `,
                variables: {
                    publicId: id,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adminGenerateRecoveryCodesByPublicId;
    })
)