// Core
import {combineReducers} from 'redux';

// INIT Reducers
import {authReducer} from "../../domain/AuthWorkspaceAndCookies/signin/store/slice";
import {snackReducer} from "../../domain/BarsENV/snack/store/slice";
import {I18NextReducer} from "../hooks/useMainTranslationHooks/slice";
import {
    SharedWorkspacesAndOrganizationsReducer
} from "../../domain/AuthWorkspaceAndCookies/newWorkspaces/store/sharedWorkspacesAndOrganizations/slice";
import {ChooseWorkspaceReducer} from "../../domain/AuthWorkspaceAndCookies/newWorkspaces/store/chooseWorkspace/slice";
import {
    ManageOrganizationReducer
} from "../../domain/AuthWorkspaceAndCookies/newWorkspaces/store/manageOrganization/slice";
import {
    ManageNewWorkspaceReducer
} from "../../domain/AuthWorkspaceAndCookies/newWorkspaces/store/manageWorkpsace/slice";
import {InvitesReducer} from "../../domain/AuthWorkspaceAndCookies/newWorkspaces/store/invites/slice";

//COMPONENTS REDUCERS
import {BuilderReducer} from "../../domain/Components/builder/store/slice";
import {AccountsReducer} from "../../domain/Components/accounts/store/slice";
import {OrganizationsReducer} from "../../domain/Components/organizations/store/slice";
import {LicenseReducer} from "../../domain/Components/License/store/slice";
import {AccessManagementReducer} from "../../domain/Components/AccessManagement/store/slice";
import {AssetsSettingsVariantsReducer} from "../../domain/Components/assetsVariants/store/slice";
import {newApplicationsReducer} from "../../domain/Components/newApplications/store/slice";
import {VicaTasksReducer} from "../../domain/Components/vicaTasks/store/slice";
import {EmailTemplatesReducer} from "../../domain/Components/emailTemplates/store/slice";


export const rootReducer = combineReducers({
    auth: authReducer,
    snack: snackReducer,
    I18Next: I18NextReducer,
    //NEW WORKSPACES
    SharedWorkspacesAndOrganizations: SharedWorkspacesAndOrganizationsReducer,
    ChooseWorkspace: ChooseWorkspaceReducer,
    ManageOrganization: ManageOrganizationReducer,
    ManageNewWorkspaceReducer: ManageNewWorkspaceReducer,
    Invites: InvitesReducer,
    //PATHS
    Builder: BuilderReducer,
    Accounts: AccountsReducer,
    Organizations: OrganizationsReducer,
    License: LicenseReducer,
    AccessManagement: AccessManagementReducer,

    assetsSettingsVariants: AssetsSettingsVariantsReducer,
    vicaTasks: VicaTasksReducer,

    newApplications: newApplicationsReducer,
    EmailTemplates: EmailTemplatesReducer
});


export type AppState = ReturnType<typeof rootReducer>;
