import {useDispatch, useSelector} from "react-redux";
import {PaginationInput} from "../../../../../shared/types";
import {GetShortCollabs} from "../../store/actions";
import {extendedCollab, loading, cleanUp} from "../../store/slice";
import {useEffect} from "react";

export const useAccounts = () => {
    const dispatch = useDispatch();

    const getData = (pageInfo: PaginationInput) => {
        dispatch(GetShortCollabs({pageInfo}));
    }

    useEffect(() => {
        return function clean(){
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    return{
        getData,
        isLoadingExtendedUser: useSelector(loading).isLoadingExtendedCollab,
        isExtendedUserPresent: useSelector(extendedCollab) !== null
    }
}