import {useDispatch, useSelector} from "react-redux";
import {PaginationInput} from "../../../../../../shared/types";
import {useSearchFieldURL} from "../../../../../../shared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../../shared/hooks/useFilterUrl";
import {controlType} from "../../../types";
import {GetControlsWithFilter, LinkControl} from "../../../store/actions";
import {
    attachControlDialog,
    controls,
    fetchedControls, hideAttachControl,
    isLoading,
    pageInfo,
    selectedFramework
} from "../../../store/slice";
import React, {useEffect} from "react";
import {useParameters} from "../../../../../../shared/hooks/useParameters";


export const useSelectExistingControl = () => {
    const dispatch = useDispatch();
    const {filter} = useParameters();
    const _selectedFrameworkId = useSelector(selectedFramework);
    const {isOpen} = useSelector(attachControlDialog);
    const isLoadingList = useSelector(isLoading);
    const _controls = useSelector(controls);
    const _fetchedControls = useSelector(fetchedControls);
    const _pageInfo = useSelector(pageInfo);

    const getData = (pageInfo: PaginationInput) => {
        //only for controls page
        dispatch(GetControlsWithFilter({page: pageInfo.page, count: pageInfo.count, filters: pageInfo.filters || {}}));
    };

    const {value, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getData({page: 0, count: 10, filters})}
    });

    useEffect(() => {
    }, [_controls]);

    useEffect(() => {
        if(isOpen){
            // setStep('ALL');
            if(_fetchedControls.length === 0 && !isLoadingList) getData({page: 0, count: 10, filters: filter});
        } else {
            handleChange({target: {value: ''}});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideAttachControl());
        // setRequestObject(initRequest);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        getData({page: newPage, count: _pageInfo.count, filters: filter});
    };

    const handleAddControl = (control: controlType) => {
        if(_selectedFrameworkId && control) dispatch(LinkControl({frameworkId: _selectedFrameworkId, control}));

    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        // getData(0, parseInt(event.target.value, 10), step, '');
        getData({page: 0, count: parseInt(event.target.value, 10), filters: filter});
    };


    // const getControls = (page: number, count: number, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES, name: string) => dispatch(GetControlsWithFilter({page, count, name, type}));


    return {
        isOpen,
        isLoadingList,
        controls: _controls,
        fetchedControls: _fetchedControls,
        pageInfo: _pageInfo,
        handleClose,
        handleChangePage,
        handleAddControl,
        handleChangeRowsPerPage,
        // getData,
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    }
}
