import axiosAsync from "../../../../shared/utils/axios/axios";
import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {AxiosResponse} from "axios";
import {TOrganizationWithWorkspaces, TUserInfo} from "../../newWorkspaces/types";


export const validateCode = async (code: string):Promise<{organizations: TOrganizationWithWorkspaces[], currentUser: TUserInfo}> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation twoFactorLoginAdmin($TwoFactorCode:String!){
                  twoFactorLoginAdmin(TwoFactorCode:$TwoFactorCode){
                    organizations{
                      collaborators{
                        role
                        publicId
                      }
                      id
                      name
                      logo
                      subscription
                      subscriptionExpiry
                      configuration{
                        availableWizards
                        availableWorkspaceCount
                        availableCollaboratorsCount
                      }
                      pageInfo{
                        total
                        sort
                        page
                        count
                      }
                      workspaces{
                        id
                        name
                        subscription
                        status
                        collaborators{
                          publicId
                          role
                        }
                        organizationId
                      }
                      blocked
                      blockedReason
                      trialUsed
                      requester{
                        outsideCollaborator
                        publicId
                        role
                      }
                    }
                    currentUser{
                      publicId
                      email
                      firstName
                      lastName
                      creationDate
                      lastLoginDate
                      status
                    }
                  }
                }
                `,
            variables: {
                TwoFactorCode: code
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.twoFactorLoginAdmin;
        }catch (e){
            throw new Error(result.data.errors[0].message);
        }
    })
)


export const resendCode = async ():Promise<string> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation resendTwoFactorEmail{
                    twoFactorResendEmail{
                        message
                    }
                }
                `,
            variables: {
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.twoFactorResendEmail.message;
        }catch (e){
            return result.data.errors[0];
        }
    })
)

export const loginByRecoveryCode = async (recoveryCode: string):Promise<{organizations: TOrganizationWithWorkspaces[], currentUser: TUserInfo}> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation loginByRecoveryCode($recoveryCode: String!){
                    loginByRecoveryCode(recoveryCode: $recoveryCode){
                        organizations{
                          collaborators{
                            role
                            publicId
                          }
                          id
                          name
                          logo
                          subscription
                          subscriptionExpiry
                          configuration{
                            availableWizards
                            availableWorkspaceCount
                            availableCollaboratorsCount
                          }
                          pageInfo{
                            total
                            sort
                            page
                            count
                          }
                          workspaces{
                            id
                            name
                            subscription
                            status
                            collaborators{
                              publicId
                              role
                            }
                            organizationId
                          }
                          blocked
                          blockedReason
                          trialUsed
                          requester{
                            outsideCollaborator
                            publicId
                            role
                          }
                        }
                        currentUser{
                          publicId
                          email
                          firstName
                          lastName
                          creationDate
                          lastLoginDate
                          status
                        }
                    }
                }
                `,
            variables: {
                recoveryCode
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.loginByRecoveryCode;
        }catch (e){
            return result.data.errors[0];
        }
    })
)


