import React, {FC, useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useVicaTasks} from "../../../hooks/useVicaTasks";
import {TCategoryWithTasks, TTask, TTaskAction} from "../../../types";
import {Add, Delete, Edit} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {DeleteDialogButton} from "../../../../../../shared/components/deleteDialogButton";
import {useOpenDialog} from "../../../../../../shared/hooks/useOpenDialog";

export const EditTaskDialog: FC = () => {

    const {
        selectedCategoryId, selectedTaskId, setSelectedTask,
        selectedTask, selectedCategory, isLoading: {isEditingTask, isLoadingTask, isDeletingTask},
        createTask, updateTask, deleteTask,
        getTaskById,
        categories
    } = useVicaTasks();
    const isLoading = isEditingTask || isLoadingTask || isDeletingTask;
    const defaultTask: TTask = {id: '', name: '', evidenceIds: [], order: selectedCategory?.tasks.length ?? 0, actions: []};

    const [edit, setEdit] = useState<TTask>(selectedTask || defaultTask);
    const isNew = !selectedTask;

    useEffect(() => {
        if (selectedCategory?.id && selectedTaskId && selectedTaskId !== 'new') getTaskById(selectedCategory?.id, selectedTaskId);
        setEdit(selectedTask ? {...selectedTask, actions: selectedTask.actions || []} : defaultTask);

        !selectedTaskId && closeDialog();
        // eslint-disable-next-line
    }, [selectedTaskId])

    useEffect(() => {
        setEdit(selectedTask ? {...selectedTask, actions: selectedTask.actions || []} : defaultTask);
        // eslint-disable-next-line
    }, [selectedTask])

    useEffect(() => {
        if(selectedCategory) setCurrentCategory(selectedCategory);
    }, [selectedCategory]);

    const handleClose = () => {
        if (isLoading) return;

        setSelectedTask(selectedCategoryId);
        setEdit(defaultTask)
    };

    const handleSave = () => {
        if (!selectedCategoryId || isLoading) return;

        if (isNew) createTask(selectedCategoryId, edit)
        else updateTask(selectedCategoryId, edit, (currentCategory && selectedCategory && currentCategory?.id !== selectedCategory?.id) ? currentCategory?.id : undefined);
    };

    const handleDelete = (id: string) => {
        if (!selectedCategoryId || isNew || isLoading) return;

        deleteTask(selectedCategoryId, id);
    };

    const [addEvidence, setEvidence] = useState<string>('');
    const handleAddEvidence = () => {
        setEdit(prev => ({...prev, evidenceIds: [...prev.evidenceIds, addEvidence.trim()]}));
        setEvidence('');
    };

    const [addAction, setAction] = useState<TTaskAction | null>(null);
    const handleAddAction = () => {
        addAction && setEdit(prev => ({...prev, actions: [...prev.actions, {data: addAction.data, title: addAction.title.trim(), type: addAction.type}]}));
        setAction(null);
    };

    const {isOpen, openDialog, closeDialog, dialogData} = useOpenDialog<string>();

    //CATEGORY CHOOSE
    const [currentCategory, setCurrentCategory] = useState<TCategoryWithTasks | null>(selectedCategory);
    const isExistId = edit.id!== "" && selectedCategory?.tasks?.some(e => e.id === edit.id);


    return (
        <Dialog open={!!selectedTaskId} onClose={handleClose}>
            <DialogTitle>
                <Typography fontWeight={600} fontSize={'24px'}>{isNew ? 'Add new task' : `Edit task ${edit.id}`}</Typography>
            </DialogTitle>

            <DialogContent sx={{gap: '18px', padding: '4px 0', display: 'flex', flexDirection: 'column', p: '10px', width: 'min(500px, 90vw)'}}>
                {isNew && <TextField
                    label={'Id'} value={edit.id} sx={{mt: '4px'}}
                    onChange={e => setEdit(prev => ({...prev, id: e.target.value}))}
                    error={isExistId}
                    helperText={isExistId ? 'Already exist' : undefined}
                />}

                <TextField label={'Name'} required value={edit.name}
                           onChange={e => setEdit(prev => ({...prev, name: e.target.value}))}
                           sx={{mt: '4px'}} error={edit.name.trim().length === 0}/>

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={categories.categories}
                    disabled={isNew}
                    getOptionLabel={e => e.name}
                    value={currentCategory}
                    onChange={(e, newValue) => newValue && setCurrentCategory(newValue)}
                    renderInput={(params) => <TextField {...params} label="Category" />}
                />

                {/*Changing order only if keeping task inside current category*/}
                { isNew && <TextField label={'Order'} type={"number"}
                                     value={(currentCategory !== null && currentCategory.id !== selectedCategory?.id) ? 0 : edit.order || 0}
                                     disabled={currentCategory !== null && currentCategory.id !== selectedCategory?.id}
                                     onChange={e => setEdit(prev => ({...prev, order: Number(e.target.value)}))}
                                     InputProps={{inputProps: {min: 0, max: selectedCategory?.tasks.length || 0}}}/>
                }

                <Box display={"flex"} flexDirection={"column"} width={'100%'} gap={'8px'}>
                    {isLoadingTask ? (
                        <Typography>Loading Evidence Ids...</Typography>
                    ) : (
                        <Box display={"flex"} alignItems={"center"} gap={'8px'}>
                            <TextField label={'Add evidence ID'} value={addEvidence} onChange={e => setEvidence(e.target.value)} sx={{flexGrow: 1}}/>

                            <IconButton onClick={handleAddEvidence}
                                        disabled={isLoading || addEvidence.trim() === '' || edit.evidenceIds.some(e => e === addEvidence.trim())}>
                                <Add/>
                            </IconButton>
                        </Box>
                    )}

                    {edit.evidenceIds?.map((evidenceId) => (
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} key={evidenceId} p={'4px 0'}>
                            <Typography noWrap>{evidenceId}</Typography>

                            <IconButton onClick={() => setEdit(prevState => ({...prevState!, evidenceIds: prevState!.evidenceIds.filter(e => e !== evidenceId)}))}
                                        disabled={isLoading}>
                                <Delete/>
                            </IconButton>
                        </Box>
                    ))}
                </Box>

                <Box display={"flex"} flexDirection={"column"} width={'100%'} gap={'8px'}>
                    {isLoadingTask ? (
                        <Typography>Loading Actions...</Typography>
                    ) : (
                        <>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'}>
                                <TextField label={'Add action title'} value={addAction?.title || ''} sx={{flexGrow: 1.2}}
                                           onChange={e => setAction(prev => prev ? {...prev, title: e.target.value} : {type: 'LINK', data: '', title: e.target.value})}/>
                                <TextField label={'Type'} value={addAction?.type || ''} sx={{flexGrow: 1}} select
                                           onChange={e => setAction(prev => prev ? {...prev, type: e.target.value as TTaskAction['type']} : {title: '', data: '', type: e.target.value as TTaskAction['type']})}>
                                    {['LINK', 'UPLOAD', 'TEMPLATE'].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <IconButton onClick={handleAddAction}
                                            disabled={isLoading || !addAction || addAction.type.trim() === '' || addAction.title.trim() === ''}>
                                    <Add/>
                                </IconButton>
                            </Box>

                            <TextField label={'Action data'} value={addAction?.data || ''} sx={{flexGrow: 1}}
                                       onChange={e => setAction(prev => prev ? {...prev, data: e.target.value} : {type: 'LINK', title: '', data: e.target.value})}/>
                        </>
                    )}

                    {edit.actions.map((action) => (
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} key={action.title} p={'4px 0'}>
                            <Box display={"flex"} flexDirection={"column"} gap={'4px'} flexGrow={1}>
                                <Typography fontWeight={500} fontSize={'18px'} >{action.title}</Typography>
                                <Typography fontWeight={400} fontSize={'16px'} noWrap>{action.type}</Typography>
                                {action.data.length > 0 && <Typography fontWeight={400} fontSize={'16px'} noWrap>{action.data}</Typography>}
                            </Box>

                            <IconButton onClick={() => setEdit(prevState => ({...prevState!, actions: prevState!.actions.filter(e => e.title !== action.title)}))}
                                        disabled={isLoading}>
                                <Delete/>
                            </IconButton>

                            <IconButton onClick={() => {
                                setAction(action);
                                setEdit(prevState => ({...prevState!, actions: prevState!.actions.filter(e => e.title !== action.title)}));
                            }} disabled={isLoading}>
                                <Edit/>
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant={"outlined"} onClick={handleClose} disabled={isLoading}>Cancel</Button>
                {!isNew && <Button variant={"contained"} onClick={() => openDialog(edit.id)} disabled={isLoading} color={"error"}>Delete task</Button>}
                <LoadingButton variant={"contained"} onClick={handleSave} loading={isEditingTask} disabled={isLoading}>Save</LoadingButton>
            </DialogActions>

            <DeleteDialogButton isOpen={isOpen} handleClose={closeDialog} isLoading={isDeletingTask}
                                title={'Delete category'} message={'Are you sure you want to delete this task?'}
                                handleConfirm={() => dialogData && handleDelete(dialogData)}/>
        </Dialog>
    );
};
