import {LeftSecondMenuColumn} from "../../../../../shared/components/LeftSecondMenuColumn";
import {LeftPaginationFilter} from "../../../../../shared/components/LeftPaginationFilter";
import {useDispatch, useSelector} from "react-redux";
import {loader} from "graphql.macro";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {SecondMenuRow} from "../../../../../shared/components/SecondMenuRow";
import {Typo} from "../../../../../shared/components/Typography";
import {Flex} from "../../../../../shared/components/Layouts";
import {extendedOrganization, loading, pageInfo, smallOrganizations} from "../../store/slice";
import {GetExtendedOrganization} from "../../store/actions";
import {calculateColorByDate} from "../../helpers";
import {useEffect} from "react";
import {handleGetSubColor, handleGetSubNormalName} from "../../../accounts/helpers";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";

export const OrganizationsLeftSideBar = () => {
    const dispatch = useDispatch();
    const {page, count, total} = useSelector(pageInfo);
    const smalls = useSelector(smallOrganizations);
    const {isLoadingSmallOrgs} = useSelector(loading);
    const selected = useSelector(extendedOrganization);

    const onClick = (id: string) => {
        dispatch(GetExtendedOrganization({id}));
    }

    useEffect(() => {}, [smalls]);

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    return(
        <Flex direction={'column'}>
            <LeftPaginationFilter page={page} count={count} total={total}
                                  handle={{type: "url"}}
                                  withFilter={{
                                      mapCategoryTitle: {
                                          blocked: 'Blocked',
                                          subscription: 'Subscription',
                                      },
                                      mapLocalValues: {
                                          blocked: ['Blocked', 'Active'],
                                          subscription: ['Free', 'Business', 'Business+', 'Enterprise', 'Consultant', 'Auditor']
                                      },

                                      mapQueryTitle: {
                                          name: 'Name',
                                          id: 'ID',
                                      },
                                      queryCategory: loader('../../gql/query.available.filter.graphql'),
                                      localePrefix: 'adminOrganizationsFilter'
                                  }}
            />

            <LeftSecondMenuColumn>
                {isLoadingSmallOrgs && <LocalSpinner />}
                {!isLoadingSmallOrgs && smalls.length > 0 && smalls.map((e) => {
                    return(
                        <SecondMenuRow
                            direction={'column'}
                            onClick={() => onClick(e.id)}
                            jc={'space-between'}
                            p={'18px'}
                            h={'auto'}
                            active={selected ? selected.id === e.id : false}
                        >
                            <Typo margin={'0 0 5px 0'}>{e.name}</Typo>

                            <Typo margin={'0 0 5px 0'} fontSize={'10px'} color={'#c0c0c0'}>{e.id}</Typo>

                            <Typo margin={'0 0 5px 0'} color={handleGetSubColor(e.subscription)}>{handleGetSubNormalName(e.subscription)}</Typo>
                            
                            <Flex w={'100%'} jc={'space-between'}>
                                <Typo color={!e.blocked ? 'green' : 'red'}>{!e.blocked ? 'Active' : 'Inactive'}</Typo>
                                <Typo margin={'0 0 5px 0'} color={calculateColorByDate(e.subscriptionExpiry)}>{parseDate(e.subscriptionExpiry, momentLocale)}</Typo>
                            </Flex>
                        </SecondMenuRow>
                    )
                })
                }
            </LeftSecondMenuColumn>
        </Flex>
    )
}