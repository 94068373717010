import {TInvitesInitialState} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../shared/redux/rootReducer";
import {AcceptInvite, DeclineInvite, GetInvites} from "./actions";

export const initialState: TInvitesInitialState = {
    invites: [],
    isLoadingInvites: true,
    acceptInvite: {
        isLoading: false,
        inviteId: null,
    },
    declineInvite: {
        isLoading: false,
        inviteId: null
    }
};


export const InvitesSlice = createSlice({
    name: 'Invites',
    initialState,
    reducers: {
        setIsLoadingAcceptInvite: (state, {payload}: {payload: string}) => {state.acceptInvite = {isLoading: true, inviteId: payload}},
        setIsLoadingDeclineInvite: (state, {payload}: {payload: string}) => {state.declineInvite = {isLoading: true, inviteId: payload}},
        cleanUp: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addCase(GetInvites.pending, (slice) => {
                slice.isLoadingInvites = true;
            })
            .addCase(GetInvites.rejected, (slice) => {
                slice.isLoadingInvites = false;
            })
            .addCase(GetInvites.fulfilled, (slice, {payload}) => {
                slice.isLoadingInvites = false;
                slice.invites = payload;
            })
        //AcceptInvite
            .addCase(AcceptInvite.pending, (slice) => {
                slice.acceptInvite.isLoading = true;
            })
            .addCase(AcceptInvite.rejected, (slice) => {
                slice.acceptInvite.isLoading = false;
            })
            .addCase(AcceptInvite.fulfilled, (slice, {payload}) => {
                slice.acceptInvite.isLoading = false;
                slice.acceptInvite = {isLoading: false, inviteId: null};
                slice.invites = slice.invites.filter(e => e.id !== payload);
            })
        //DeclineInvite
            .addCase(DeclineInvite.pending, (slice) => {
                slice.declineInvite.isLoading = true;
            })
            .addCase(DeclineInvite.rejected, (slice) => {
                slice.declineInvite.isLoading = false;
            })
            .addCase(DeclineInvite.fulfilled, (slice, {payload}) => {
                slice.declineInvite.isLoading = false;
                slice.declineInvite = {isLoading: false, inviteId: null};
                slice.invites = slice.invites.filter(e => e.id !== payload);
            })
    }
});

export const InvitesReducer = InvitesSlice.reducer;

export const {
    setIsLoadingAcceptInvite,
    setIsLoadingDeclineInvite,
    cleanUp
} = InvitesSlice.actions;

const selectSelf = (state: AppState): TInvitesInitialState => state.Invites as TInvitesInitialState;

export const isLoadingInvites = createSelector(selectSelf, state => state.isLoadingInvites);
export const invites = createSelector(selectSelf, state => state.invites);
export const acceptInvite = createSelector(selectSelf, state => state.acceptInvite);
export const declineInvite = createSelector(selectSelf, state => state.declineInvite);







