import {useDispatch, useSelector} from "react-redux";
import {
    cleanUpAction,
    setSelectedTaskAction,
    TVicaTasksSlice,
    vicaTasksCategoriesSelector,
    vicaTasksIsLoadingSelector,
    vicaTasksSelectedCategorySelector,
    vicaTasksSelectedTaskSelector
} from "../../store/slice";
import {TCategoryWithTasks, TTask, TTaskCategory, TTaskFiles, TTaskOrder} from "../../types";
import {
    vicaAdminAddFromFilesAction, vicaAdminChangeCategoriesOrderAction,
    vicaAdminCreateCategoryAction,
    vicaAdminCreateTaskAction,
    vicaAdminDeleteCategoryAction,
    vicaAdminDeleteTaskAction,
    vicaAdminGetCategoriesAction,
    vicaAdminGetTaskByIdAction,
    vicaAdminGetTasksByCategoryIdAction,
    vicaAdminUpdateCategoryAction,
    vicaAdminUpdateTaskAction
} from "../../store/actions";

type TResponse = {
    categories: TVicaTasksSlice['categories'],
    isLoading: TVicaTasksSlice['isLoading'],

    setSelectedTask: (categoryId: TVicaTasksSlice['selectedCategoryId'], taskId?: TVicaTasksSlice['selectedTaskId']) => void,
    selectedCategoryId: TVicaTasksSlice['selectedCategoryId'],
    selectedTaskId: TVicaTasksSlice['selectedTaskId'],
    selectedCategory: TCategoryWithTasks | null,
    selectedTask: TTask | null,

    getCategories: () => void,
    getTasksByCategoryId: (categoryId: string) => void,
    getTaskById: (categoryId: string, taskId: string) => void,

    deleteTask: (categoryId: string, taskId: string) => void,
    updateTask: (categoryId: string, task: TTask, moveToCategoryId?: string) => void,
    createTask: (categoryId: string, createTask: TTask) => void,

    deleteCategory: (categoryId: string) => void,
    updateCategory: (category: TTaskCategory, orders: TTaskOrder[], tasks?: TTask[]) => void,
    updateCategoryOrder: (order: TTaskOrder[]) => void,
    createCategory: (category: TTaskCategory, orders: TTaskOrder[]) => void,

    addFromFiles: (files: TTaskFiles) => void,

    clean: () => void,
}
export const useVicaTasks = (): TResponse => {
    const dispatch = useDispatch();

    const categories = useSelector(vicaTasksCategoriesSelector);
    const selectedCategoryId = useSelector(vicaTasksSelectedCategorySelector);
    const selectedTaskId = useSelector(vicaTasksSelectedTaskSelector);
    const isLoading = useSelector(vicaTasksIsLoadingSelector);

    const setSelectedTask: TResponse['setSelectedTask'] = (categoryId, taskId) => {
        dispatch(setSelectedTaskAction({categoryId, taskId}))
    }

    const getCategories: TResponse['getCategories'] = () => {
        dispatch(vicaAdminGetCategoriesAction({}))
    }
    const getTasksByCategoryId: TResponse['getTasksByCategoryId'] = (categoryId) => {
        dispatch(vicaAdminGetTasksByCategoryIdAction({categoryId, frameworkView: true}))
    }
    const getTaskById: TResponse['getTaskById'] = (categoryId, taskId) => {
        dispatch(vicaAdminGetTaskByIdAction({categoryId, taskId, frameworkView: true}))
    };

    const deleteTask: TResponse['deleteTask'] = (categoryId, taskId) => {
        dispatch(vicaAdminDeleteTaskAction({categoryId, taskId, frameworkView: true}))
    };
    const updateTask: TResponse['updateTask'] = (categoryId, task, moveToCategoryId?: string) => {
        dispatch(vicaAdminUpdateTaskAction({categoryId, task, frameworkView: true, moveToCategoryId}))
    };
    const createTask: TResponse['createTask'] = (categoryId, task) => {
        dispatch(vicaAdminCreateTaskAction({categoryId, task, frameworkView: true}))
    };

    const deleteCategory: TResponse['deleteCategory'] = (categoryId) => {
        dispatch(vicaAdminDeleteCategoryAction({categoryId}))
    };
    const updateCategory: TResponse['updateCategory'] = (category, orders, tasks) => {
        dispatch(vicaAdminUpdateCategoryAction({category, orders, tasks}))
    };

    const updateCategoryOrder: TResponse['updateCategoryOrder'] = (order) => {
        dispatch(vicaAdminChangeCategoriesOrderAction({order}))
    };

    const createCategory: TResponse['createCategory'] = (category, orders) => {
        dispatch(vicaAdminCreateCategoryAction({category, orders}))
    };

    const addFromFiles: TResponse['addFromFiles'] = (files) => {
        dispatch(vicaAdminAddFromFilesAction({files}))
    };

    const clean: TResponse['clean'] = () => {dispatch(cleanUpAction())}
    return {
        categories: {
            categories: categories.categories.slice()
                .sort((a, b) => (categories.order.find(e => e.id === a.id)?.order ?? 999999) - (categories.order.find(e => e.id === b.id)?.order ?? -1)),
            order: categories.order,
        },
        isLoading,

        setSelectedTask,
        selectedCategoryId,
        selectedTaskId,
        selectedCategory: categories.categories.find(category => category.id === selectedCategoryId) ?? null,
        selectedTask: categories.categories.find(category => category.id === selectedCategoryId)?.tasks.find(task => task.id === selectedTaskId) || null,

        getCategories,
        getTasksByCategoryId,
        getTaskById,

        deleteTask,
        updateTask,
        createTask,

        deleteCategory,
        updateCategory,
        updateCategoryOrder,
        createCategory,

        addFromFiles,

        clean,
    }
}
