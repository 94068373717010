import React, {FC, Fragment, useState} from "react";

import {Flex} from "../../Layouts";
import {Typo} from "../../Typography";
import colors from "../../../theme/colors";

import {Checkbox, Collapse} from "@mui/material";
// import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

import {ChipProps, TGeneralCategory, TLocalValues, TMapCategoryFieldType,} from "../../../types";

import {arrToObj} from "../helper";
import {gql} from "@apollo/react-hooks";
import {DocumentNode} from "@apollo/client";
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";
import {QueryFilterItem} from "../QueryFilterItem";


type TProps = {
    localePrefix: string,
    doFilter: () => void,
    chips: ChipProps[],
    handleDel: (chip: ChipProps) => void,
    handleAdd: (chip: ChipProps) => void,
    clearAll: () => void,
    setSpecificChips: (chips: ChipProps[]) => void,

    // filterValues?: TFilterItem[],
    mapCategoryTitle: TGeneralCategory,
    mapQueryTitle?: TGeneralCategory,
    mapCategoryFieldType?: TMapCategoryFieldType,
    mapLocalValues?: TLocalValues,

    queryCategory?: DocumentNode, //for autocomplete name
}
export const MiniFilter: FC<TProps> = (
    {
        localePrefix,
        doFilter,
        mapCategoryTitle,
        mapQueryTitle = {},
        handleAdd,
        handleDel,
        clearAll,
        setSpecificChips,
        mapLocalValues,
        chips,

        queryCategory = gql`
            query mockFilterData($data: CommonFilterDto!, $workspaceId: String!) {
                mockFilterData(data: $data, workspaceId: $workspaceId)
            }`
    }
) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'Filter'});

    const changeChip = (filterName: string, value: string) => {
        if (chips.some(ch => (ch.value === value && ch.filterName === filterName))) {
            handleDel({value, filterName})
        } else { handleAdd({value, filterName}) }
    }

    const [rowsOpen, setRowsOpen] = useState<{[key: string]: boolean}>(
        arrToObj(Object.keys(mapCategoryTitle).filter(key => key !== 'name'))
    );

    return (
        <Flex w={'100%'} minh={'370px'} h={'100%'} ai={"center"} direction={"column"}>
            <Flex ai={"center"} w={'100%'} m={'0 0 10px 0'}>
                <Typo fontWeight={400} letterSpacing={'1.23px'} fontSize={'16px'} cursor={'inherit'} color={colors.table.head} margin={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>
                    {('Filter')}
                </Typo>

                <Typo cursor={'pointer'} margin={'0 0 0 auto'} onClick={() => {clearAll()}}
                      fontSize={'14px'} fontWeight={400} letterSpacing={'0.4px'} color={colors.blue}>
                    {('Clear All')}
                </Typo>
                <Typo cursor={'inherit'}
                      margin={'0 7px'} fontSize={'14px'} fontWeight={400} letterSpacing={'0.4px'} color={colors.lightGrey}>
                    -
                </Typo>
                <Typo cursor={'pointer'} onClick={() => {doFilter()}}
                      fontSize={'14px'} fontWeight={400} letterSpacing={'0.4px'} color={colors.blue}>
                    {('Save')}
                </Typo>
            </Flex>

            {
                Object.keys(mapQueryTitle).map((filterName, index, array) => (
                    <QueryFilterItem index={index} array={array} filterName={filterName}
                                     queryCategory={queryCategory} mapQueryTitle={mapQueryTitle}
                                     chips={chips}
                                     localePrefix={localePrefix}
                                     handleAdd={handleAdd} handleDel={handleDel}
                                     localValues={mapLocalValues?.[filterName]}

                                     key={filterName + ' filter input row'}
                    />
                ))
            }

            {
                Object.keys(rowsOpen).map((key) => (
                    <Fragment key={key + (rowsOpen[key] ? 'true' : 'false')}>
                        <Flex ai={"center"} jc={"space-between"} w={'100%'}
                              onClick={() => {
                                  const tmp = JSON.parse(JSON.stringify(rowsOpen));
                                  tmp[key] = !tmp[key];
                                  setRowsOpen(tmp);
                              }}>
                            <Typo fontSize={'12px'} fontWeight={400} letterSpacing={'0.4px'} color={colors.lightGrey}
                                  margin={!revDir ? "0 10px 0 0" : "0 0 0 10px"}>{mapCategoryTitle[key] || ''}</Typo>
                            {
                                rowsOpen[key]
                                    ? <KeyboardArrowUpOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)", marginLeft: 15}}/>
                                    : <KeyboardArrowDownOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)", marginLeft: 15}}/>
                            }
                        </Flex>
                        <Collapse in={rowsOpen[key]} sx={{width: '100%'}}>
                            {
                                mapLocalValues?.[key]?.map((value) => (
                                    <Flex ai={"center"} w={'100%'}
                                          key={value + ' ' + JSON.stringify(mapLocalValues?.[key])}>
                                        <Checkbox size={"small"} checked={chips.some(ch => (ch.filterName === key && ch.value === value))}
                                                  sx={{marginRight: '9px'}}
                                                  onChange={() => {changeChip(key, value)}}/>
                                        <Typo fontSize={'12px'} fontWeight={400} letterSpacing={'0.4px'}>{value}</Typo>
                                    </Flex>
                                )) || null
                            }
                        </Collapse>
                    </Fragment>
                ))
            }
        </Flex>
    )
}