import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {notificationsSelector} from "../../store/selectors";
import {useEffect} from "react";
import {removeSnack} from "../../store/slice";

export const useSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);

  const getMessage = (message: string): string => {
    return typeof message === "object" ? JSON.stringify(message) : message;
  }

  useEffect(() => {
    for (const { key, message, variant } of notifications) {
      enqueueSnackbar(getMessage(message), {
        key,
        variant,
        onExited: (event, sKey) => {
          dispatch(removeSnack(sKey));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, dispatch]);
}
