import {Flex} from "../../../../../../../shared/components/Layouts"
import {useDispatch, useSelector} from "react-redux";
import {evidences, frameworks} from "../../../../store/slice";
import { Button, Divider, Tab, Tabs, TextField} from "@mui/material";
import {evidenceType, gapObject} from "../../../../types";
import React, {useEffect, useState} from "react";
import {EditEvidence} from '../../../../store/actions';
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {GAP_ORIGIN} from "../../../../constants";
import {CreateFrameworkIdGap} from "../gap/createFrameworkIdGap";
import {CreateInnerGap} from "../gap/createInnerGap";

export const UpdateEvidenceComponent = ({isOpen, evidenceId}: {isOpen: boolean, evidenceId: string | null}) => {
    const dispatch = useDispatch();
    // const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const _evidences = useSelector(evidences);
    const _frameworks = useSelector(frameworks);

    const emptyEvidence: evidenceType = {
        name: '',
        categories: [],
        description: '',
        id: '',
        origin: true,
        organizationId: null,
        visibility: null,
        collectorId: null,
        foundInControlsCounter: 0,
        // foundInControls: [],
        gap:null
    }

    const [form, updateForm] = useState<evidenceType>(emptyEvidence);
    const [currentEvidence, setCurrentEvidence] = useState<evidenceType>(emptyEvidence);


    useEffect(() => {
        if(isOpen && evidenceId){
            const currEvidence = _evidences.find(e => e.id === evidenceId);
            if(currEvidence){
                updateForm(currEvidence);
                setCurrentEvidence(currEvidence);
                //gap
                if(currEvidence.gap){
                    setFrameworkIdGapAvailableTabs([GAP_ORIGIN, ...Object.keys(currEvidence.gap)]);
                    setSelectedFrameworkIdGapTab(GAP_ORIGIN);
                    setSelectedLanguageGabTab('');
                }else{
                    setFrameworkIdGapAvailableTabs([GAP_ORIGIN]);
                    setSelectedFrameworkIdGapTab(GAP_ORIGIN);
                    setSelectedLanguageGabTab('');
                }
            }
        }
        // eslint-disable-next-line
    }, [isOpen, evidenceId]);

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleChangeCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            categories: [event.target.value as string],
        }));
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     updateForm((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

    const validateForm = (): boolean => {
        if(JSON.stringify(currentEvidence) === JSON.stringify(form)) return false;
        return(
            form.name.trim().length > 0 &&
            form.description.trim().length > 0 &&
            form.categories.length > 0
        )
    }

    const onSave = () => {
        if(validateForm()) {
            dispatch(EditEvidence({evidence: form, isEvidencesPage: true}));
            updateForm(emptyEvidence);
        }
    }

    //GAP

    const [frameworkIdGapAvailableTabs, setFrameworkIdGapAvailableTabs] = useState<string[]>([GAP_ORIGIN]);
    const [selectedFrameworkIdGapTab, setSelectedFrameworkIdGapTab] = useState<string>(GAP_ORIGIN);

    const [languageGapAvailableTabs, setLanguageGapAvailableTabs] = useState<string[]>([]);
    const [selectedLanguageGabTab, setSelectedLanguageGabTab] = useState<string>('');


    const handleSelectFrameworkIdGapTab = (ttab: string) => {
        setSelectedFrameworkIdGapTab(ttab);
        if(ttab === GAP_ORIGIN){
            setLanguageGapAvailableTabs([]);
            setSelectedLanguageGabTab('');
        }else{
            if(form.gap){
                const availableTabs = Object.keys(form.gap[ttab]);
                setLanguageGapAvailableTabs(availableTabs);
                availableTabs.length > 0 && setSelectedLanguageGabTab(availableTabs[0]);
            }
        }
    }

    const getGapNameValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.name ?? `No gap found: (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap)})`;
    }

    const getGapCategoryValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.categories?.[0] ?? `No gap found (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap?.categories)})`;
    }

    const getGapDescValue = (frameworkId: string, language: string): string => {
        if(!form.gap){
            return 'No gap found';
        }
        const gap: gapObject =  form.gap[frameworkId][language];
        return gap?.description ?? `No gap found: (form.gap[frameworkId][language] (${frameworkId} : ${language}): ${JSON.stringify(gap)})`;
    }

    //field updates
    const handleChangeInnerGapName = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        name: event.target.value as string
                    }
                }
            } : null
        }));
    }

    const handleChangeInnerGapCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        categories: [event.target.value as string]
                    }
                }
            } : null
        }));
    }

    const handleChangeInnerGapDesc = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!form.gap) return;
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            gap: prevValues.gap ? {
                ...prevValues.gap,
                [selectedFrameworkIdGapTab]: {
                    ...prevValues.gap[selectedFrameworkIdGapTab],
                    [selectedLanguageGabTab]: {
                        ...prevValues.gap[selectedFrameworkIdGapTab][selectedLanguageGabTab],
                        description: event.target.value as string
                    }
                }
            } : null
        }));
    }

    //dialogs
    const [isOpenCreateFrameworkIdGapDialog, setIsOpenCreateFrameworkIdGapDialog] = useState<boolean>(false);

    const handleAddFrameworkGap = (id: string, innerGap: string) => {
        updateForm({
            ...form,
            gap: {
                ...form.gap,
                [id]: {
                    [innerGap]: {
                        name: '',
                        categories: [''],
                        description: ''
                    }
                }
            }
        });
        setIsOpenCreateFrameworkIdGapDialog(false); //closing dialog
        setFrameworkIdGapAvailableTabs([...frameworkIdGapAvailableTabs, id]); //adding tab
        setLanguageGapAvailableTabs([innerGap]); //adding inner tab
        setSelectedFrameworkIdGapTab(id); //selecting just created framework id gap
        setSelectedLanguageGabTab(innerGap); //selecting just created inner gap
    }

    const [isOpenCreateInnerGap, setIsOpenCreateInnerGap] = useState<boolean>(false);

    const handleAddInnerGap = (innerGap: string) => {
        updateForm({
            ...form,
            gap: form.gap ? {
                ...form.gap,
                [selectedFrameworkIdGapTab]: {
                    ...form.gap[selectedFrameworkIdGapTab],
                    [innerGap]: {
                        name: '',
                        categories: [''],
                        description: ''
                    }
                }
            } : null
        });
        setIsOpenCreateInnerGap(false); //closing dialog
        setLanguageGapAvailableTabs([...languageGapAvailableTabs, innerGap]); //adding inner tab
        setSelectedLanguageGabTab(innerGap); //selecting just created inner gap
    }

    const handleDeleteCurrentFrameworkGap = () => {
        if(!form.gap || selectedFrameworkIdGapTab === GAP_ORIGIN) return;
        const newGap = {...form.gap};
        delete newGap[selectedFrameworkIdGapTab];
        if(!Object.keys(newGap).length){
            //no gap
            updateForm({...form, gap: null});
        }else{
            updateForm({...form, gap: newGap});
        }
        setFrameworkIdGapAvailableTabs([...frameworkIdGapAvailableTabs.filter(e => e !== selectedFrameworkIdGapTab)]); //deleting framework tab
        setSelectedFrameworkIdGapTab(GAP_ORIGIN); //setting default tab
        setLanguageGapAvailableTabs([]); //removing all inner tabs as its origin
        setSelectedLanguageGabTab('');
    }

    const handleDeleteCurrentInnerGap = () => {
        if(!form.gap || selectedFrameworkIdGapTab === GAP_ORIGIN || !selectedLanguageGabTab.trim().length) return;
        let newGap = JSON.parse(JSON.stringify(form.gap));
        // console.log(`currentGap: ${JSON.stringify(form.gap)} -> \n gap[${selectedFrameworkIdGapTab}]: ${JSON.stringify(form.gap[selectedFrameworkIdGapTab])} -> deleting ${selectedLanguageGabTab}`);
        const frameworkGapToDeleteFrom = newGap[selectedFrameworkIdGapTab];
        if(frameworkGapToDeleteFrom){
            delete frameworkGapToDeleteFrom[selectedLanguageGabTab];
        }
        updateForm({...form, gap: {...newGap, [selectedFrameworkIdGapTab]: frameworkGapToDeleteFrom}});
        setLanguageGapAvailableTabs([...languageGapAvailableTabs.filter(e => e !== selectedLanguageGabTab)]); //removing inner tab
        const availableTabs = Object.keys(form.gap[selectedFrameworkIdGapTab]);
        availableTabs.length > 0 && setSelectedLanguageGabTab(availableTabs[0]);
    }

    return(
        <>
            {(isOpen && evidenceId) &&
                <Flex w={'49.5%'} h={'fit-content'} direction={'column'} p={'20px'} background={'white'} box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'} br={'10px'}>
                    <Flex ai={'center'} jc={'space-between'}>
                        <Button variant={'contained'} onClick={() => setIsOpenCreateFrameworkIdGapDialog(true)}>{('Create gap')}</Button>
                        <Button color={'error'} variant={'contained'} onClick={handleDeleteCurrentFrameworkGap} disabled={selectedFrameworkIdGapTab === GAP_ORIGIN}>{('Delete current gap')}</Button>
                    </Flex>
                    <Tabs sx={{margin: '8px 0 0 0'}} variant={'scrollable'} value={selectedFrameworkIdGapTab} onChange={(e, newValue: string) => handleSelectFrameworkIdGapTab(newValue)} aria-label="basic tabs example">
                        {frameworkIdGapAvailableTabs.map(e => {
                            //searching for such framework by id
                            const currFramework = _frameworks.find(f => f.id === e);
                            return(
                                <Tab key={e} label={e === GAP_ORIGIN ? e : `${e} (${currFramework?.name ?? '404'})`} value={e}/>
                            )
                        })}
                    </Tabs>

                    <Divider flexItem sx={{bgcolor: 'gray', borderColor: 'gray', margin: '16px 0'}}/>

                    <Flex ai={'center'} jc={'space-between'}>
                        <Button color={'secondary'} variant={'contained'} onClick={() => setIsOpenCreateInnerGap(true)} disabled={!(selectedFrameworkIdGapTab.trim().length > 0 && selectedFrameworkIdGapTab !== GAP_ORIGIN)}>{('Create inner gap')}</Button>
                        <Button color={'error'} variant={'contained'} onClick={handleDeleteCurrentInnerGap} disabled={selectedFrameworkIdGapTab === GAP_ORIGIN || languageGapAvailableTabs.length <= 1 || !selectedLanguageGabTab.trim().length}>{('Delete current inner gap')}</Button>
                    </Flex>

                    <Divider flexItem sx={{bgcolor: 'gray', borderColor: 'gray', margin: '16px 0'}}/>

                    {selectedFrameworkIdGapTab === GAP_ORIGIN ?
                        <Flex w={'100%'} direction={'column'}>
                            <TextField id="filled-basic" label={'Name'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={form.name} onChange={handleChange}/>
                            <TextField id="filled-basic" label={'Category'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'categories'} value={form.categories[0] || ''} onChange={handleChangeCategories}/>
                            <TextField id="filled-basic" label={'Description'} variant="outlined" multiline minRows={5} sx={{margin: '0 0 28px 0'}} name={'description'} value={form.description} onChange={handleChange}/>
                        </Flex>
                        :
                        <Flex w={'100%'} direction={'column'}>
                            <Tabs variant={'scrollable'} value={selectedLanguageGabTab} onChange={(e, newValue: string) => setSelectedLanguageGabTab(newValue)} aria-label="basic tabs example">
                                {languageGapAvailableTabs.map(e => {
                                    return(
                                        <Tab key={e} label={e} value={e}/>
                                    )
                                })}
                            </Tabs>

                            <Flex w={'100%'} direction={'column'} m={'16px 0 0 0'}>
                                <TextField id="filled-basic" label={'Name'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapNameValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapName}/>
                                <TextField id="filled-basic" label={'Category'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapCategoryValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapCategories}/>
                                <TextField id="filled-basic" label={'Description'} variant="outlined" sx={{margin: '0 0 20px 0'}} name={'name'} value={getGapDescValue(selectedFrameworkIdGapTab, selectedLanguageGabTab)} onChange={handleChangeInnerGapDesc}/>
                            </Flex>
                        </Flex>
                    }


                    {/*/SAVE*/}
                    <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                        <CustomButton
                            variant={'contained'}
                            disabled={!validateForm()}
                            onClick={onSave}
                        >
                            {'Save'}
                        </CustomButton>

                        {/*<CustomButton*/}
                        {/*    variant={'contained'}*/}
                        {/*    onClick={() => console.log(JSON.stringify(form))}*/}
                        {/*>*/}
                        {/*    {('LOG')}*/}
                        {/*</CustomButton>*/}
                    </Flex>

                    {/*DIALOGS*/}
                    <CreateFrameworkIdGap
                        isOpen={isOpenCreateFrameworkIdGapDialog}
                        handleClose={() => setIsOpenCreateFrameworkIdGapDialog(false)}
                        onCreate={handleAddFrameworkGap}
                        addedGaps={Object.keys(form.gap ?? {})}
                     />

                    <CreateInnerGap
                        isOpen={isOpenCreateInnerGap}
                        handleClose={() => setIsOpenCreateInnerGap(false)}
                        onCreate={handleAddInnerGap}
                        addedGaps={Object.keys(form.gap ? (form.gap[selectedFrameworkIdGapTab] ?? {}) : {})}
                    />
                </Flex>
            }
        </>
    )
}
