import {
    DownloadImageInEditorQuery,
    DownloadImageInEditorQueryVariables,
    DownloadImageInEditorStaffQuery,
    DownloadImageInEditorStaffQueryVariables,
    UploadImageInEditorMutation,
    UploadImageInEditorMutationVariables,
    UploadImageInEditorStaffMutation,
    UploadImageInEditorStaffMutationVariables
} from "../../../GQLTypes";
import axiosSync from "../../../utils/axios/axios";
import {PATH_SERVER} from "../../../constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../utils/builders";
import {AxiosResponse} from "axios";
import {
    downloadImageInEditor,
    downloadImageInEditorStaff,
    uploadImageInEditor,
    uploadImageInEditorStaff
} from "./query";

export const downloadImageInEditorApi = async (data: DownloadImageInEditorQueryVariables, signal?: AbortSignal): Promise<DownloadImageInEditorQuery["downloadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadImageInEditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadFile
    })
)

export const uploadImageInEditorApi = async (data: UploadImageInEditorMutationVariables, signal?: AbortSignal): Promise<UploadImageInEditorMutation["uploadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadImageInEditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile
    })
)

export const downloadImageInEditorStaffApi = async (data: DownloadImageInEditorStaffQueryVariables, signal?: AbortSignal): Promise<DownloadImageInEditorStaffQuery["workerDownloadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadImageInEditorStaff),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.workerDownloadFile
    })
)

export const uploadImageInEditorStaffApi = async (data: UploadImageInEditorStaffMutationVariables, signal?: AbortSignal): Promise<UploadImageInEditorStaffMutation["workerUploadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadImageInEditorStaff),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.workerUploadFile
    })
)