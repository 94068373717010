import {TableCell, TableHead, TableRow} from "@mui/material";
import {Typo} from "../../../../../shared/components/Typography";

export const RoleTableHeader = () => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typo>Name</Typo>
                </TableCell>

                <TableCell>
                    <Typo>Permissions</Typo>
                </TableCell>

                <TableCell>
                    <Typo>Last edit</Typo>
                </TableCell>

                <TableCell>
                    <Typo>Create date</Typo>
                </TableCell>

                <TableCell>
                    <Typo>ID</Typo>
                </TableCell>

                <TableCell>
                    <Typo>isSystem</Typo>
                </TableCell>

                <TableCell>
                    <Typo>Actions</Typo>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}