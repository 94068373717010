import {PATH_SERVER} from "../../../../shared/constants";
import {buildCommonHeader} from "../../../../shared/utils";
import {TAuthObj} from "../types";
import axiosAsync from "../../../../shared/utils/axios/axios";
import {AxiosResponse} from "axios";


export const loginApi = async ({password, username}: TAuthObj):Promise<boolean> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation loginAdmin($data:LoginInputRequest!){
                  loginAdmin(data:$data){
                    isGoogleAuthConfigComplete
                  }
                }
                `,
            variables: {
                data: {
                    username,
                    password
                }
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        // setTokenExist();
        //receiving message: 'Welcome!'
        try{
            return result.data.data.loginAdmin.isGoogleAuthConfigComplete;
        }catch (ex: any){
            throw new Error(ex.message);
            // console.log('login error: ', ex.message);
        }
    })
)

export const logoutApi = async (): Promise<{message: string}> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation adminLogout {
                    adminLogout {
                        message
                    }
                }`,
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.adminLogout;
    })
}

export const sendEmailSupport = async (message: string, email: string, name: string): Promise<boolean> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER,
        {
            query: `
                mutation sendEmail($data:MainLayoutSupportMessageInput!){
                  sendMessageToSupportMainLayout(data:$data){
                    isSent
                  }
                }
                `,
            variables: {
                data: {
                    message,
                    email,
                    name
                }
            }
        })
        ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.sendMessageToSupportMainLayout.isSent;
    })
}
