import {TPermission} from "../../types";
import {Chip, Divider, IconButton} from "@mui/material";
import React from "react";
import {Typo} from "../../../../../shared/components/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Flex } from "../../../../../shared/components/Layouts";
import colors from "../../../../../shared/theme/colors";
import {useDispatch} from "react-redux";
import {openDeletePermissionDialog, openEditPermissionDialog} from "../../store/slice";

export function PermissionsTabRowRenderer(props: any) {
    const dispatch = useDispatch();
    const { index, style, _permissions} = props;
    let currPermission: TPermission | undefined = _permissions.find((e: TPermission, id: number) => id === index);

    // useEffect(() => {
    //     currPermission = _permissions.find((e: TPermission, id: number) => id === index);
    // }, [_permissions]);

    const handleEditPermission = () => {
        currPermission && dispatch(openEditPermissionDialog(currPermission));
    }

    const handleDeletePermission = () => {
        currPermission && dispatch(openDeletePermissionDialog(currPermission));
    }
    return (
        <>
            <Flex
                key={`${currPermission?.name}-${index}`}
                style={style}
                p={'4px 8px 0 8px'}
                ai={'center'}
                jc={'space-between'}
                direction={'column'}
                background={currPermission?.name === 'bish' ? 'red' : '#fff'}
                hoverStyles={'background-color: #E8EAF6;'}
            >
                <Flex w={'100%'} ai={'center'}>
                    <Flex w={'10%'}>
                        <Typo>{index}</Typo>
                    </Flex>

                    <Flex w={'25%'}>
                        <Typo>{currPermission?.name}</Typo>
                    </Flex>

                    <Flex w={'55%'} ai={'center'}>
                        {currPermission &&
                            <>
                                {!currPermission.tags.length && <Typo>No tags</Typo>}
                                {currPermission.tags.filter((e, id) => id >= 0 && id < 8).map((e: string) => <Chip
                                    label={e}
                                    key={e}
                                    sx={{lineHeight: 'normal', margin: '0 8px 0 0'}}
                                />)}
                                {currPermission.tags.length > 8 && <Chip label={`+${currPermission.tags.length - 8}`} sx={{lineHeight: 'normal'}}/>}
                            </>
                        }
                    </Flex>

                    <Flex w={'10%'}>
                        <IconButton onClick={handleEditPermission} sx={{padding: '10px'}}>
                            <EditIcon fontSize={'medium'} color={'primary'}/>
                        </IconButton>
                        <IconButton onClick={handleDeletePermission} sx={{padding: '10px'}}>
                            <DeleteForeverIcon fontSize={'medium'} color={'primary'}/>
                        </IconButton>
                    </Flex>
                </Flex>

                {<Divider flexItem sx={{bgcolor: colors.skip, borderColor: colors.skip}}/>}
            </Flex>
        </>
    );
}
