import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {useDeletePermissionDialog} from "../../../hooks/dialogs/useDeletePermissionDialog";

export const DeletePermissionDialog = () => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        permission,
        isPermissionUsedInSomeRole,
        permissionUsedInRoles
    } = useDeletePermissionDialog();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Delete permission')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {isPermissionUsedInSomeRole &&
                        <>
                            <Typography variant={'body2'}>CAUTION: Permission {permission?.name} is used in </Typography>
                            <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{permissionUsedInRoles.map(e => e.name).join(', ')} roles.</Typography>
                        </>
                    }
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{(`Permission ${permission?.name} will be deleted.`)}</Typography>
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{('Are you sure you want to delete this permission?')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: '0 16px 0 0'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    color={'error'}
                >
                    {('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}