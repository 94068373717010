import {createAsyncThunk} from "@reduxjs/toolkit";
import {TAddAssetJobTitleData, TAddAssetSettingData, TAssets, TUpdateAssetJobTitleData} from "../types";
import {
    addAssetSettingsApi,
    getAssetsSettingsApi,
    getDataForCreateJobTitleApi,
    removeAssetSettingsApi,
    updateAssetSettingsApi
} from "../api";

export const getAssetSettingsAction = createAsyncThunk(
    'AssetSettings/getAssetSettings',
    async (data: {asset: TAssets}, {dispatch}) => {
        const resp = await getAssetsSettingsApi(data);
        return resp;
    }
)

export const addAssetSettingsAction = createAsyncThunk(
    'AssetSettings/addAssetSettings',
    async (data: TAddAssetJobTitleData, {dispatch}) => {
        const resp = await addAssetSettingsApi(data);
        return resp;
    }
)

export const updateAssetSettingsAction = createAsyncThunk(
    'AssetSettings/updateAssetSettings',
    async (data: TUpdateAssetJobTitleData, {dispatch}) => {
        const resp = await updateAssetSettingsApi(data);
        return resp;
    }
)

export const removeAssetSettingsAction = createAsyncThunk(
    'AssetSettings/removeAssetSettings',
    async (data: TAddAssetSettingData, {dispatch}) => {
        const resp = await removeAssetSettingsApi(data);
        return resp;
    }
)

export const getDataForCreateJobTitleAction = createAsyncThunk(
    'AssetSettings/getDataForCreateJobTitleAction',
    async (_, {dispatch}) => {
        const resp = await getDataForCreateJobTitleApi();
        return resp;
    }
)

