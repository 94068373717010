import React, {FC, useContext} from "react";
import {LeftSideBarItem, TAccordion} from "../../../types";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Box} from "../../../../../../shared/components/Layouts";
import {AccordionStyled, Content, MenuItemStyled, Summary, Titled} from "../../../styles";
import {RegularItem} from "../RegularItem";
import {ACTIVE_MENU} from "../../../constants";
import {ThemeContext} from "styled-components";

type PropTypes = {
    item: LeftSideBarItem;
    activeRoute: LeftSideBarItem[]
    setExpanded:  React.Dispatch<React.SetStateAction<TAccordion>>;
    status: TAccordion;
    handleActive: (item: LeftSideBarItem) => void;
};

export const AccordionItem: FC<PropTypes> = (props: PropTypes) => {
    const {item, activeRoute,  setExpanded, status: { accordion, expanded}, handleActive} = props;
    const secondLevel = activeRoute[1];
    const {short, bit} = useContext(ThemeContext);
    return (
        <AccordionStyled expanded={accordion === item.title && expanded}
                         onChange={() => setExpanded((prev): { accordion: string | null; expanded: boolean } =>
                             (prev.accordion === item.title ? ({
                                 accordion,
                                 expanded: !expanded
                             }) : ({accordion: item.title, expanded: true}))
                         )}
                         style={{...expanded && bit && accordion !== item.title && {display: 'none'}}}
        >
            <Summary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                onClick={() => handleActive(item)}
            >
                <RegularItem {...props} complex />
            </Summary>
            <Content>
                <Box>
                    {item?.items?.map(i => {
                            return (
                                <MenuItemStyled onClick={() => {
                                    handleActive(i)
                                }} key={i.title}>
                                    <Titled fs={bit ? "10px" : short ? '12px' : '14px'}
                                            fw={'300'} {...(secondLevel?.title === i.title && {color: ACTIVE_MENU})}
                                            primary={i.title}
                                    />
                                </MenuItemStyled>
                            );
                        }
                    )
                    }
                </Box>
            </Content>
        </AccordionStyled>
    )
};
