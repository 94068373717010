import React, {FC, useContext, useRef} from "react";
import {FlexStart} from "../../../shared/components/Layouts";
import {ThemeContext} from "styled-components";
import {TopBarProtectedLeftSide, TopBarUnProtectedLeftSide} from "./LeftSideTopBar";
import {TopBarProtectedRightSide, TopBarUnProtectedRightSide} from "./RightSideTopBar";
import { showProtectedTopBar, showUnProtectedTopBar } from "../leftSideBar/helpers";
import { useLocation } from "react-router";
import {useMainTranslation} from "../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

type PropTypes = {
    expanded: boolean;
    expand: () => void;
};

export const TopBar: FC<PropTypes> = (props: PropTypes) => {
    const {revDir} = useMainTranslation();

    const reference = useRef(null);
    const {height} = useContext(ThemeContext);
    const {pathname} = useLocation();
    return (
        <>
            {
                (showProtectedTopBar(pathname) || showUnProtectedTopBar(pathname)) && (
                    <FlexStart p={!revDir ? '0 40px 0 6px' : '0 6px 0 40px'} w="100vw" h={`calc(100vh - ${height})`}
                               minh={`calc(100vh - ${height})`} of={"hidden"} bc={'#0C1629'}
                               position={'sticky'} top={'0'}
                               left={'0'} zi={"3000"} ref={reference}>
                        <>
                            {showProtectedTopBar(pathname) && (
                                <>
                                    <TopBarProtectedLeftSide {...props}/>
                                    <TopBarProtectedRightSide reference={reference}/>
                                </>)
                            }
                            {showUnProtectedTopBar(pathname) && (
                                <>
                                    <TopBarUnProtectedLeftSide  {...props}/>
                                    <TopBarUnProtectedRightSide reference={reference} />
                                </>)
                            }
                        </>
                    </FlexStart>)
            }
        </>
    )
};
