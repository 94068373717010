import {useDispatch, useSelector} from "react-redux";
import {
    addEvidenceDialog,
    editEvidenceDialog,
    evidences,
    isLoading,
    isLoadingEvidences,
    selectedEvidence
} from "../../store/slice";
import {useEffect} from "react";
import {GetPrivateEvidences} from "../../store/actions";
import {useParameters} from "../../../../../shared/hooks/useParameters";

export const useEvidences = () => {
    const dispatch = useDispatch();
    const _evidences = useSelector(evidences);
    const addEvidence = useSelector(addEvidenceDialog);
    const editEvidence = useSelector(editEvidenceDialog);
    const selectedEvidenceId = useSelector(selectedEvidence);
    const {filter} = useParameters();

    useEffect(() => {
        dispatch(GetPrivateEvidences({page: 0, count: 10, filters: filter || {}}));
        //eslint-disable-next-line
    }, [])

    return {
        _evidences,
        isLoading: useSelector(isLoadingEvidences),
        addDialog: addEvidence.isOpen && addEvidence.isEvidencesPage,
        editDialog: {
            isOpen: editEvidence.isOpen && editEvidence.isEvidencesPage,
            evidenceId: selectedEvidenceId
        },
        isLoadingDialogs: useSelector(isLoading),

    }
}
