import {PaginationInput} from "../../../../../shared/types";
import {GetPrivateControls} from "../../store/actions";
import {useSearchFieldURL} from "../../../../../shared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../shared/hooks/useFilterUrl";
import {useDispatch, useSelector} from "react-redux";
import {
    controls,
    isLoadingControlAndEvidencesByFrameworkId,
    isLoadingControls, openAddControl, openAttachControl,
    pageInfo,
    selectedControl
} from "../../store/slice";
import {useParameters} from "../../../../../shared/hooks/useParameters";
import {useRouteMatch} from "react-router";
import {ROOT_CONTROLS} from "../../constants";
import React, {useEffect, useState} from "react";
import {controlType} from "../../types";


export const useBuilderControlsTable = () => {
    const dispatch = useDispatch();
    const _controls = useSelector(controls);
    const selected = useSelector(selectedControl);
    // const _selectedFramework = useSelector(selectedFramework);
    const _pageInfo = useSelector(pageInfo);
    const isLoadingList = useSelector(isLoadingControls);
    const isLoadingControlsByFrameworkId = useSelector(isLoadingControlAndEvidencesByFrameworkId);
    const {filter} = useParameters();
    const isControlsPage = useRouteMatch<{controlId: string}>(ROOT_CONTROLS)?.isExact;
    const [search, setSearch] = useState<string>("");

    const getData = (pageInfo: PaginationInput) => {
        //only for controls page
        dispatch(GetPrivateControls({page: pageInfo.page, count: pageInfo.count, filters: pageInfo.filters || {}}));
    };

    const {value, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getData({page: 0, count: 10, filters})}
    });



    const [filteredControls, setFilteredControls] = useState<controlType[]>(_controls);


    const [anchorEl, setAnchorEl] = React.useState<{id: string | null, anchorEl: HTMLButtonElement | null}>({id: null, anchorEl: null});

    useEffect(() => {
        setFilteredControls(_controls);

        // eslint-disable-next-line
    }, [_controls]);

    useEffect(() => {
        setSearch('');

        // eslint-disable-next-line
    }, []);


    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        if(isControlsPage){
            dispatch(openAddControl());
        }else{
            setAnchorEl({
                id: id,
                anchorEl: event.target as HTMLButtonElement,
            });
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl({id: null, anchorEl: null});
    }

    const handleChoose = (type: 'new' | 'exists') => {
        type === 'new' && dispatch(openAddControl());
        type === 'exists' && dispatch(openAttachControl());
        handlePopoverClose();
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        dispatch(GetPrivateControls({page: 0, count: parseInt(event.target.value, 10), filters: filter}));

    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        //only for controls page
        dispatch(GetPrivateControls({page: newPage, count: _pageInfo.count, filters: filter}));
    };

    const handleSetSearch = (value: string) => {
        setSearch(value);
        setFilteredControls(_controls.filter((c) => c.name.toLowerCase().includes(value.toLowerCase()) || c.id?.toLowerCase().includes(value.toLowerCase())));
    }


    return {
        controls: _controls,
        selected,
        pageInfo: _pageInfo,
        isLoadingList,
        isLoadingControlsByFrameworkId,
        isControlsPage,
        search,
        setSearch,
        filteredControls,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        handleChoose,
        handleChangeRowsPerPage,
        handleChangePage,
        handleSetSearch,

        //filter
        getData,
        value,
        handleChange,
        setFilter,
        handleKeyDown,
    }
}
