import styled from "styled-components";


export interface BoxProps {
  w?: string,
  maxw?: string,
  minw?: string,
  h?: string,
  maxh?: string,
  minh?: string,
  m?: string,
  p?:string,
  b?:string,
  br?: string,
  brbottom?: string,
  talign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent",
  grow?: string,
  shrink?: string,
  box_shadow?:string,
  background?: string,
  cursor?: string,
  hide?: boolean,
  overflow?: "visible" | "hidden" | "clip" | "scroll" | "auto" | 'overlay',
  overflowy?: "visible" | "hidden" | "clip" | "scroll" | "auto" | 'overlay',
  overflowx?: "visible" | "hidden" | "clip" | "scroll" | "auto" | 'overlay',
  // minWidth?: string,
  // maxWidth?: string,
  // minHeight?: string,
  // maxHeight?: string,
  marginLeft?: string,
  border?: string,
  brright?: string,
  brleft?: string,
  lastTypeOf?: string,
  lastTypeOfStyle?: string,
  hoverStyles?: string,
  hoverTextStyles?: string,
  pe?: string,
  pos?: string,
  opacity?: string,
  top?: string,
  left?: string,
  right?: string,
  bottom?: string,
  zi?: string,
  wordBreak?: string
  flex?: string;
  bb?: string;
  tr?: string;
  sa?: string;
}

export const Box = styled.div<BoxProps>`
  ${props => props.border && `border: ${props.border}`};
  ${props => props.w && `width: ${props.w}`};
  ${props => props.maxw && `max-width: ${props.maxw}`};
  ${props => props.minw && `min-width: ${props.minw}`};
  ${props => props.h && `height: ${props.h}`};
  ${props => props.maxh && `max-height: ${props.maxh}`};
  ${props => props.minh && `min-height: ${props.minh}`};
  ${props => props.wordBreak && `word-break: ${props.wordBreak}`};
  
  ${props => props.grow && `flex-grow: ${props.grow}`};
  ${props => props.shrink && `flex-shrink: ${props.shrink}`};
  
  ${props => props.m && `margin: ${props.m}`};
  ${props => props.p && `padding: ${props.p}`};
  
  ${props => props.talign && `text-align: ${props.talign}`};
  ${props => props.cursor && `cursor: ${props.cursor}`};
  
  
  ${props => props.br && `border-radius: ${props.br}`};
  ${props => props.brbottom && `border-bottom: ${props.brbottom}`};
  ${props => props.brright && `border-right: ${props.brright}`};
  ${props => props.brleft && `border-left: ${props.brleft}`};
  ${props => props.box_shadow && `box-shadow: ${props.box_shadow}`};
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.overflowy && `overflow-y: ${props.overflowy}`};
  ${props => props.overflowx && `overflow-x: ${props.overflowx}`};  
  
  ${props => props.background && `background-color: ${props.background}`};  
  ${props => props.hide && `display: none`};  
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`};
  ${props => props.lastTypeOf && props.lastTypeOfStyle && `${props.lastTypeOf}:last-of-type{${props.lastTypeOfStyle}}`};
  &:hover{
    ${props => props.hoverStyles}
  }
  &:hover h2{ 
    ${props => props.hoverTextStyles}
  }
  ${props => props.pe && `pointer-events: ${props.pe}`};
  ${props => props.pos && `position: ${props.pos}`}
  ${props => props.opacity && `opacity: ${props.opacity}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.zi && `z-index: ${props.zi}`};
  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.bb && `border-bottom: ${props.bb}`};
  ${props => props.tr && `transition: ${props.tr}`};
  ${props => props.sa && `align-self: ${props.sa}`};
`;



interface FlexProps {
  active?:boolean,
  direction?:"row" | "row-reverse" | "column" | "column-reverse",
  jc?: "start" | "end" | "flex-start" | "flex-end" | "center" | "left" | "right" | "normal" | "baseline" | "space-between" | "space-around" | "space-evenly" | "stretch",
  ai?: "normal" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch",
  wrap?: "nowrap" | "wrap" | "wrap-reverse",
  grow?: string,
  basis?: string,
  top?: string,
  left?: string,
  right?: string,
  bottom?: string,
  pos?: string,
  disableScrollBar?: boolean,
  position?:'static' | 'relative' | 'absolute' | 'fixed';

  gap?: string;
}

export const
    Flex = styled(Box)<FlexProps>`
  display: flex;
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.jc && `justify-content: ${props.jc}`};
  ${props => props.ai && `align-items: ${props.ai}`};
  ${props => props.wrap && `flex-wrap: ${props.wrap}`};
  ${props => props.grow && `flex-grow: ${props.grow}`};
  ${props => props.basis && `flex-basis: ${props.basis}`};
  ${props => props.pos && `position: ${props.pos}`}
  ${props => props.opacity && `opacity: ${props.opacity}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.zi && `z-index: ${props.zi}`};
  ${props => props.background && `background-color: ${props.background}`};
  ${props => props.position && `position: ${props.position}`};
  ${props => props.gap && `gap: ${props.gap}`};
      
  
  ${props => props.disableScrollBar && `
  &&::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  `
  }
`

export const Circle = styled.div<{color: string, cursor?:string, size?:string}>`
  ${props => props.size ? `width: ${props.size}` : `width: 1.5rem;`};
  ${props => props.size ? `height: ${props.size}` : `height: 1.5rem;`};
  border-radius: 100%;
  background-color: ${props => props.theme.colors.circles[props.color]};
  color: ${props => props.theme.colors.circles.text[props.color]};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center!important;
  ${props => props.cursor && `cursor: ${props.cursor}!important`};
  
  //margin: 0 auto;
`;


type FlexPropsNew = {
  w?: string,
  h?: string,
  ai?: string;
  p?: string;
  m?: string;
  br?: string;
  bs?: string,
  bc?: string,
  of?: string,
  wrap?: string,
  d?: string;
  g?: string;
  minw?: string;
  minh?: string;
  cursor?: string;
  ofy?: string;
  ofx?: string;
  jc?: string;
  wordBreak?: string;
  position?: string;
  border?: string;
  maxh?: string;
  maxw?: string;
  ac?: string;
  hover?: string;
  top?: string;
  left?: string;
  zi?: string;
  shrink?: string;
}

export const FlexBoxNew = styled.div<FlexPropsNew>`
  display: flex;
  align-items: ${props => props.ai ? props.ai : "center"};
  width: ${props => props.w ? props.w : "100%"};
  height: ${props => props.h ? props.h : "100%"};
  min-width: ${props => props.minw ? props.minw : "0"};
  min-height: ${props => props.minh ? props.minh : "0"};
  max-width: ${props => props.maxw && props.maxw};
  max-height: ${props => props.maxh && props.maxh};
  padding: ${props => props.p ? props.p : "0 0 0 0"};
  margin: ${props => props.m ? props.m : "0 0 0 0"};
  border-radius: ${props => props.br ? props.br : "0"};
  box-shadow: ${props => props.bs ? props.bs : "none"};
  background-color: ${props => props.bc ? props.bc : "inherit"};
  overflow: ${props => props.of ? props.of : "visible"};
  overflow-y: ${props => props.ofy ? props.ofy : "visible"};
  overflow-x: ${props => props.ofx ? props.ofx : "visible"};
  flex-wrap: ${props => props.wrap ? props.wrap : "nowrap"};
  flex-direction: ${props => props.d ? props.d : "row"};
  flex-shrink: ${props => props.shrink ? props.shrink : "1"};
  ${props => props.zi && `z-index: ${props.zi}`};
  flex-grow: ${props => props.g ? props.g : "0"};
  cursor: ${props => props.cursor ? props.cursor : "auto"};
  justify-content: ${props => props.jc ? props.jc : "flex-start"};
  ${props => props.position && `position: ${props.position}`};
  ${props => props.border && `border: ${props.border}`};
  ${props => props.ac && `align-content: ${props.ac}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.left && `left: ${props.left}`};
  &:hover{
    ${props => props.hover}
  }
`;

export const FlexAround = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: space-around !important;
`;

export const FlexBetween = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: space-between !important;
`;

export const FlexStart = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: flex-start !important;
`;

export const FlexCenter = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: center !important;
`;

export const FlexEnd = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: flex-end !important;
`;

export const FlexEvenly = styled(FlexBoxNew)<FlexPropsNew>`
  justify-content: space-evenly !important;
`;

export const AppContainer = styled(Box)<{height?: string}>`
  width:  100%;
  height: ${({height}) => height ? height : '100%'}; 
  max-height: 100%;
  background: #F5F5F5;
  padding: 28px 40px 28px 16px; 
  /* overflow: auto; */
`
