// import {CE_mapper, FC_mapper} from "../types";
//
// export const getEvidencesCountByMappers = (frameworkId: string, fcMappers: FC_mapper[], ceMappers: CE_mapper[]): {controls: number, evidences: number} => {
//     if(!fcMappers || !ceMappers) return {controls: 0, evidences: 0};
//     const filteredByFrameworkId = fcMappers.filter(e => e.frameworkId === frameworkId);
//     const filteredByControls = ceMappers.filter(e => filteredByFrameworkId.some(fc => fc.controlId === e.controlId))
//     return {
//         controls: filteredByFrameworkId.length,
//         evidences: filteredByControls.length,
//     }
// }
import {LABEL} from "../../../../shared/constants";
import {controlType, evidenceType, frameworkType, policyType} from "../types";
import {NewDocumentModel} from "../../../../shared/GQLTypes";

export {};





export const getPolicyVisibilityStatus = (policy: policyType): string => {
    if(!policy.visibility || policy.origin) return `Created by ${LABEL}`;
    if(!policy.origin) {
        return 'Created by another user'
    }
    return '';
}


export const recalculateFrameworkWhenControlLinkedOrUnlinked = (framework: frameworkType | undefined, controls: controlType[], type: 'LINK' | 'UNLINK',  addedControl?: controlType, deletedControlId?: string): frameworkType => {
    if(!framework) throw new Error('recalculateFrameworkWhenControlLinked - Framework not found!');
    const evidencesArr: evidenceType[] = [];
    let currControls = [...controls];

    if(type === "LINK" && addedControl){
        currControls.push(addedControl);
    }else if(type === "UNLINK" && deletedControlId){
        currControls = currControls.filter(e => e.id !== deletedControlId);
    }

    currControls.forEach((c) => {
        c.evidences?.forEach((ev) => {
            if(!evidencesArr.some((evA) => evA.id === ev.id)) evidencesArr.push(ev);
        })
    })
    // console.log(evidencesArr);
    return {...framework, controls: currControls.length, evidences: evidencesArr.length};
}

export const recalculateFrameworkWhenEvidenceLinkedOrUnlinked = (controls: controlType[], type: 'LINK' | 'UNLINK', controlIdAction: string, framework?: frameworkType, addedEvidence?: evidenceType, deletedEvidence?: string): frameworkType => {
    if(!framework) throw new Error('recalculateFrameworkWhenControlLinked - Framework not found!');
    const evidencesArr: evidenceType[] = [];
    let currControls = [...controls];

    if(type === "LINK" && addedEvidence){
        currControls = currControls.map(e => e.id === controlIdAction ? {...e, evidences: [...(e.evidences || []), addedEvidence]} : e);
    }else if(type === 'UNLINK' && deletedEvidence){
        currControls = currControls.map(e => e.id === controlIdAction ? {...e, evidences: [...(e.evidences?.filter(ev => ev.id !== deletedEvidence) || [])]} : e);
    }

    currControls.forEach((c) => {
        c.evidences?.forEach((ev) => {
            if(!evidencesArr.some((evA) => evA.id === ev.id)) evidencesArr.push(ev);
        })
    })

    return {...framework, controls: controls.length, evidences: evidencesArr.length};
}

export const shortString = (text: string, howMuch: number) => text.split('').length >= howMuch ? text.split('').slice(0, howMuch).concat('.', '.', '.').join('') : text;

export const getBlankDocument = (id: string): NewDocumentModel => ({
    id,
    name: '',
    origin: true,
    type: 'TEMPLATE',
})