import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteRoleDialog, loading} from "../../../store/slice";
import {DeleteRoleByAdmin} from "../../../store/actions";

export const useDeleteRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const {isOpen, role} = useSelector(dialogs).deleteRole;
    const isLoading = useSelector(loading).rolesDelete;

    //actions
    const handleClose = () => {
        dispatch(hideDeleteRoleDialog());
    }

    const handleSubmit = () => {
        if(role){
            dispatch(DeleteRoleByAdmin({id: role.id}));
        }
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        role,
    }
}