import {LeftSecondMenuColumn} from "../../../../../shared/components/LeftSecondMenuColumn";
import {LeftPaginationFilter} from "../../../../../shared/components/LeftPaginationFilter";
import {useDispatch, useSelector} from "react-redux";
import {extendedCollab, loading, pageInfo, smallCollabs} from "../../store/slice";
import {loader} from "graphql.macro";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {SecondMenuRow} from "../../../../../shared/components/SecondMenuRow";
import {Typo} from "../../../../../shared/components/Typography";
import {Flex} from "../../../../../shared/components/Layouts";
import {GetExtendedCollab} from "../../store/actions";
import {makeFirstLetterUpper} from "../../../../../shared/utils/text";

export const AccountsLeftSideBar = () => {
    const dispatch = useDispatch();
    const {page, count, total} = useSelector(pageInfo);
    const smalls = useSelector(smallCollabs);
    const {isLoadingSmallCollabs} = useSelector(loading);
    const selected = useSelector(extendedCollab);

    const onClick = (id: string) => {
        dispatch(GetExtendedCollab({id}));
    }

    return(
        <Flex direction={'column'}>
            <LeftPaginationFilter page={page} count={count} total={total}
                                  handle={{type: "url"}}
                                  withFilter={{
                                      mapCategoryTitle: {
                                          blocked: 'Blocked',
                                          region: 'Region',
                                      },
                                      mapLocalValues: {
                                          blocked: ['Blocked', 'Active'],
                                          region: ['Canada', 'Global'],
                                      },

                                      mapQueryTitle: {
                                          email: 'Email',
                                          publicId: 'Public ID',
                                      },
                                      queryCategory: loader('../../gql/query.available.filter.graphql'),
                                      localePrefix: 'adminAccountsFilter'
                                  }}
            />

            <LeftSecondMenuColumn>
                {isLoadingSmallCollabs && <LocalSpinner />}
                {!isLoadingSmallCollabs && smalls.length > 0 && smalls.map((e) => {
                    return(
                        <SecondMenuRow
                            direction={'column'}
                            onClick={() => onClick(e.publicId)}
                            jc={'space-between'}
                            p={'18px'}
                            h={'auto'}
                            active={selected ? selected.publicId === e.publicId : false}
                        >
                            <Typo margin={'0 0 5px 0'}>{e.firstName} {e.lastName}</Typo>

                            <Typo margin={'0 0 5px 0'}>{e.email}</Typo>

                            <Typo margin={'0 0 5px 0'} fontSize={'10px'} color={'#c0c0c0'}>{e.publicId}</Typo>

                            <Flex w={'100%'} jc={'space-between'}>
                                <Typo color={e.active ? 'green' : 'red'}>{e.active ? 'Active' : 'Inactive'}</Typo>
                                <Typo>{makeFirstLetterUpper(e.region)}</Typo>
                            </Flex>
                        </SecondMenuRow>
                    )
                })}
            </LeftSecondMenuColumn>
        </Flex>
    )
}