import {useMediaQuery, useTheme} from "@mui/material";

export const useMedia = () => {

    const theme = useTheme();
    const matches_1440Up = useMediaQuery(theme.breakpoints.up('c1440'));
    const matches_1680Up = useMediaQuery(theme.breakpoints.up('c1680'));
    const matches_1920Up = useMediaQuery(theme.breakpoints.up('c1920'));
    const matches_2048Up = useMediaQuery(theme.breakpoints.up('c2048'));

    const matches_1440Down = useMediaQuery(theme.breakpoints.down('c1440'));
    const matches_1680Down = useMediaQuery(theme.breakpoints.down('c1680'));
    const matches_1920Down = useMediaQuery(theme.breakpoints.down('c1920'));
    const matches_2048Down = useMediaQuery(theme.breakpoints.down('c2048'));

    const matches_between_1440_1680 = useMediaQuery(theme.breakpoints.between('c1440', 'c1680'));
    const matches_between_1680_1920 = useMediaQuery(theme.breakpoints.between('c1680', 'c1920'));

    return {
        matches_1440Up,
        matches_1680Up,
        matches_1920Up,
        matches_2048Up,

        matches_1440Down,
        matches_1680Down,
        matches_1920Down,
        matches_2048Down,

        matches_between_1440_1680,
        matches_between_1680_1920
    }
}