import {useDispatch, useSelector} from "react-redux";
import {extendedLicense, hideReleaseLicenseDialog, dialogs, loading} from "../../../store/slice";
import {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalSpinner} from "../../../../../../shared/components/Spinner";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {CustomInput} from "../../../../../../shared/components/Inputs";
import {ReleaseLicense} from "../../../store/actions";

export const ReleaseExistingLicense = () => {
    const dispatch = useDispatch();
    const license = useSelector(extendedLicense);
    const {releaseLicense: {isOpen}} = useSelector(dialogs);
    const {isLoadingRelease} = useSelector(loading);

    const handleClose = () => dispatch(hideReleaseLicenseDialog());

    const [form, setForm] = useState<{note: string, email: string}>({note: '', email: ''});

    const handleUpdateNote = (value: string) => {setForm({...form, note: value})};
    const handleUpdateEmail = (value: string) => {setForm({...form, email: value})};

    const handleSendForm = () => {
        if(license && isOk()){
            dispatch(ReleaseLicense({id: license.id, email: form.email, note: form.note}));
        }
    }

    const isOk = () => license && form.note.trim().length > 0;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Release License</DialogTitle>

            <DialogContent>
                <CustomInput
                    sx={{margin: '15px 0 0 0'}}
                    value={form.note}
                    onChange={(e) => handleUpdateNote(e.target.value as string)}
                    minRows={5}
                    multiline
                    label={'Note'}
                />

                <CustomInput
                    value={form.email}
                    type={'email'}
                    onChange={(e) => handleUpdateEmail(e.target.value as string)}
                    label={'Email (leave it blank if not needed.)'}
                    sx={{width: '70%', margin: '15px 0 0 0'}}
                />
            </DialogContent>

            {isLoadingRelease ?
                <LocalSpinner />
                :
                <DialogActions>
                    <Button onClick={handleClose} color={'warning'}>Cancel</Button>
                    <Button onClick={handleSendForm} disabled={!isOk()} color={'success'}>Release</Button>
                </DialogActions>
            }
        </Dialog>
    )
}