import {ChangeEventHandler, FC, MouseEvent, useEffect, useRef} from "react";

import {Divider, Popover, TablePagination} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import {Flex} from "../Layouts";
import {Typo} from "../Typography";

import colors from "../../theme/colors";

import {usePagination} from "../../hooks/usePagination";
import {MiniFilter} from "./MiniFilter";
import {useMiniFilter} from "./hooks/useMiniFilter";
import {TGeneralCategory, TLocalValues, TMapCategoryFieldType} from "../../types";
import {DocumentNode} from "@apollo/client";
import {TablePaginationActions} from "../TablePaginationAction";
import {useMedia} from "../../hooks/useMedia";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";

type TProps = {
    page: number,
    count: number,
    total: number,

    widePagination?: boolean,
    disablePagination?: boolean,
    handle: {
        type: 'url',
    } | {
        type: 'local',
        handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void,
        handleChangeRowsPerPage: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    },
    withFilter?: {
        mapCategoryTitle: TGeneralCategory,
        mapQueryTitle?: TGeneralCategory,
        mapCategoryFieldType?: TMapCategoryFieldType,
        mapLocalValues?: TLocalValues,

        queryCategory?: DocumentNode, //for autocomplete name

        right?: boolean,
        withoutLabel?: boolean,
        localePrefix: string,

        localFilter?: {
            filter: {[key: string]: string[]}
            setFilter: (filter: {[key: string]: string[]}) => void
        }
    },
}
export const LeftPaginationFilter: FC<TProps> = ({
                                                     page, count, total,
                                                     handle,
                                                     withFilter,
                                                     widePagination= false,
                                                     disablePagination = false,
}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'Filter'});
    const url = usePagination({totalSelector: total})

    const handleChangePage = handle.type === 'url'
        ? url.handleChangePage
        : handle.handleChangePage
    const handleChangeRowsPerPage = handle.type === 'url'
        ? url.handleChangeRowsPerPage
        : handle.handleChangeRowsPerPage

    const anchor = useRef<HTMLDivElement>(null);
    const {
        isOpen, openFilter, hideFilter,
        doFilter,
        setSpecificChips,
        restoreFilter,
        chips,
        handleDel, handleAdd, clearAll
    } = useMiniFilter({localFilter: withFilter?.localFilter});

    useEffect(() => {
        restoreFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const {matches_1680Down, matches_1440Down} = useMedia();
    const horizontalPosition = withFilter?.right ? 'center' : 'left';

    return (
        <>
            <Flex ai={"center"} jc={"space-between"}
                  w={withFilter?.right ? undefined : '100%'} ref={anchor}
                  style={ withFilter?.right ? { flexGrow:1} : { flexGrow:0}} >
                {
                    withFilter ? (
                        <>
                            { withFilter.right && <Flex w={'1px'} h={'1px'} grow={'1'}/>}
                            <Flex ai={"center"} m={!revDir ? '0 10px 0 0' : '0 0 0 10px'} onClick={() => {openFilter()}} cursor={'pointer'} hoverStyles={`& *{color: ${colors.blue} !important};`}>
                                <FilterAltOutlinedIcon sx={{margin: '0 9px', color: isOpen || chips.length > 0 ? colors.blue : colors.table.head}}/>
                                {!withFilter.withoutLabel && !isOpen && <Typo fontWeight={400} fontSize={'16px'} letterSpacing={'1.23px'} color={isOpen || chips.length > 0 ? colors.blue : colors.table.head} cursor={'inherit'}>{t('Filter')}</Typo>}
                            </Flex>
                            {
                                anchor.current && (
                                    <Popover open={isOpen} onClose={() => {hideFilter(); restoreFilter()}}
                                             anchorEl={anchor.current}
                                             anchorOrigin={{
                                                 vertical: 'bottom',
                                                 horizontal: horizontalPosition
                                             }}
                                             sx={{
                                                 '& .MuiPaper-root': {
                                                     padding: '10px 20px',
                                                     borderRadius: '12px',
                                                     minWidth: '250px',
                                                     width: anchor.current.offsetWidth/3*2 + 'px',
                                                     maxWidth: anchor.current.offsetWidth + 'px'
                                                 }
                                             }}>
                                        <MiniFilter doFilter={doFilter}
                                                    chips={chips}
                                                    handleDel={handleDel} handleAdd={handleAdd}
                                                    clearAll={clearAll} setSpecificChips={setSpecificChips}

                                                    mapCategoryTitle={withFilter.mapCategoryTitle}
                                                    mapQueryTitle={withFilter.mapQueryTitle}
                                                    mapCategoryFieldType={withFilter.mapCategoryFieldType}
                                                    mapLocalValues={withFilter.mapLocalValues}
                                                    queryCategory={withFilter.queryCategory}

                                                    localePrefix={withFilter.localePrefix}
                                        />
                                    </Popover>
                                )
                            }
                        </>
                    ) : <div/>
                }

                { !disablePagination && (
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={count}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={widePagination && !matches_1680Down ? TablePaginationActions : undefined}
                        sx={{
                            '& .MuiTablePagination-selectLabel': {display: 'none'},
                            '& .MuiTablePagination-spacer': {display: 'none'},
                            '& .MuiInputBase-root': {display: 'none'},
                            '& .MuiTablePagination-displayedRows': {
                                color: colors.table.head,
                                display: matches_1440Down ? 'none' : undefined
                            },
                            '& .MuiToolbar-root .MuiTablePagination-actions': {marginLeft: matches_1440Down ? 0 : undefined},
                            '& .MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '10px'} : {marginRight: '10px'})},
                            '& .MuiToolbar-root .MuiButtonBase-root': {width: '24px', height: '24px', ...(!revDir ? {marginLeft: '10px'} : {marginRight: '10px'})},

                            '& .MuiButtonBase-root': {paddingTop: '0', paddingBottom: '0'},
                            '& .MuiToolbar-root': {minHeight: '40px', ...(!revDir ? {paddingLeft: '24px', paddingRight: '2px'} : {paddingRight: '24px', paddingLeft: '2px'})},
                        }}
                    />
                )}
            </Flex>
            <Divider orientation={"horizontal"} flexItem sx={{background: colors.skip, margin: '0 5px 16px'}}/>
        </>

    )
}