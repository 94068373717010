import {useDispatch, useSelector} from "react-redux";
import { Flex } from "../../../../../shared/components/Layouts";
import {
    creatableLicense,
    updateCreatableLicense,
    openCreateMultiNoReleaseDialog,
    openCreateMultiReleaseDialog,
    openCreateOneNoReleaseDialog,
    openCreateOneReleaseDialog
} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {handleGetSubNormalName} from "../../../accounts/helpers";
import {Checkbox, ListItemButton, ListItemIcon, ListItemText, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {ORGANIZATION_SUBSCRIPTIONS} from "../../../../AuthWorkspaceAndCookies/newWorkspaces/types";
import {CustomInput} from "../../../../../shared/components/Inputs";
import {creatableLicenseType} from "../../types";

export const CreatableLicenseTopBar = () => {
    const dispatch = useDispatch();
    const license = useSelector(creatableLicense);
    const subs: ORGANIZATION_SUBSCRIPTIONS[] = ['SUBSCRIPTION_BUSINESS', 'SUBSCRIPTION_BUSINESS_PLUS', 'SUBSCRIPTION_ENTERPRISE', 'SUBSCRIPTION_CONSULTANT', 'SUBSCRIPTION_AUDITOR', 'SUBSCRIPTION_FREE']
    const expiryUnits = ['YEARS', 'MONTHS', 'DAYS'];


    const createOne = () => {
        if(license){
            license.issued ? dispatch(openCreateOneReleaseDialog()) : dispatch(openCreateOneNoReleaseDialog());
        }
    }

    const createMulti = () => {
        if(license){
            license.issued ? dispatch(openCreateMultiReleaseDialog()) : dispatch(openCreateMultiNoReleaseDialog());
        }
    }

    const setSubscription = (subscription: string) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, subscription};
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetRelease = () => {
        if(license){
            const newLicense:creatableLicenseType = {...license, issued: !license.issued };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetCollabsCounter = (counter: number) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, availableCollaborators: counter };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetWorkspaceCounter = (counter: number) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, availableWorkspaces: counter };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetVulTargetsCounter = (counter: number) => {
        if(license){
            const newLicense:creatableLicenseType = {...license, availableTargetsVulnerabilityScan: counter };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetExpiryUnit = (unit: string) => {
        if(license){
            const newLicense: creatableLicenseType = {...license, expiryUnit: unit };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    const handleSetExpiryCounter = (counter: number) => {
        if(license){
            const newLicense = {...license, expiry: counter };
            dispatch(updateCreatableLicense(newLicense));
        }
    }

    return(
        license ?
            <Flex
                w={'100%'}
                jc={'space-between'}
                p={'18px'}
                br={'12px'}
                box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                background={'#fff'}
            >
                <Flex w={'70%'} direction={'column'}>
                    <Autocomplete
                        sx={{marginTop: '10px'}}
                        autoComplete
                        fullWidth
                        openOnFocus
                        id="combo-box-demo"
                        options={subs}
                        getOptionLabel={handleGetSubNormalName}
                        onChange={(e, value) => {value && setSubscription(value)}}
                        value={license.subscription}
                        renderInput={(params) => <TextField {...params} label="Subscription" />}
                    />

                    <ListItemButton role={undefined} onClick={handleSetRelease} dense>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={license.issued}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'someId' }}
                            />
                        </ListItemIcon>
                        <ListItemText id={'someId'} primary={'Release'} />
                    </ListItemButton>

                    <Flex w={'100%'} jc={'space-between'}>
                        <Flex w={'25%'} jc={'space-between'} ai={'center'} m={'15px 0 0 0'}>
                            <Typo>Collaborators </Typo>
                            <CustomInput
                                sx={{margin: '0 0 0 5px', width: '40%'}}
                                type={'number'}
                                value={license.availableCollaborators}
                                onChange={(e) => handleSetCollabsCounter(parseInt(e.target.value))}
                            />
                        </Flex>

                        <Flex w={'25%'} jc={'space-between'} ai={'center'} m={'15px 0 0 0'}>
                            <Typo>Workspaces </Typo>
                            <CustomInput
                                sx={{margin: '0 0 0 5px', width: '40%'}}
                                type={'number'}
                                value={license.availableWorkspaces}
                                onChange={(e) => handleSetWorkspaceCounter(parseInt(e.target.value))}
                            />
                        </Flex>

                        <Flex w={'25%'} jc={'space-between'} ai={'center'} m={'15px 0 0 0'}>
                            <Typo>Vul. Targets </Typo>
                            <CustomInput
                                sx={{margin: '0 0 0 5px', width: '40%'}}
                                type={'number'}
                                value={license.availableTargetsVulnerabilityScan}
                                onChange={(e) => handleSetVulTargetsCounter(parseInt(e.target.value))}
                            />
                        </Flex>
                    </Flex>

                    <Flex jc={'space-between'} ai={'center'} m={'15px 0 0 0'}>
                        <CustomInput
                            type={'number'}
                            value={license.expiry}
                            onChange={(e) => handleSetExpiryCounter(parseInt(e.target.value))}
                        />

                        <Autocomplete
                            sx={{margin: '0 0 0 5px'}}
                            autoComplete
                            fullWidth
                            openOnFocus
                            id="combo-box-demo"
                            options={expiryUnits}
                            onChange={(e, value) => {value && handleSetExpiryUnit(value)}}
                            value={license.expiryUnit}
                            renderInput={(params) => <TextField {...params} label="Expiry Unit" />}
                        />
                    </Flex>
                </Flex>

                <Flex w={'15%'} direction={'column'}>
                    <CustomButton variant={'contained'} color={'success'} size={'small'} onClick={createOne}>Create One</CustomButton>
                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'success'} size={'small'} onClick={createMulti}>Create Multi</CustomButton>
                </Flex>
            </Flex>
        :
            <></>
    )
}