import React, {FC, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {useVicaTasks} from "../../../hooks/useVicaTasks";
import {LoadingButton} from "@mui/lab";
import {useTasksDialogs} from "../../../hooks/useTasksDialogs";
import {UploadFileBTN} from "../../../../../../shared/components/FileBTN/UploadFileBTN";
import {toBase64} from "../../../../../../shared/utils/base64";
import {Flex} from "../../../../../../shared/components/Layouts";
import {Delete} from "@mui/icons-material";
import {TTaskFiles} from "../../../types";

export const AddFilesDialog: FC = () => {

    const {
        addFromFiles, isLoading: {isUploadingFiles},
    } = useVicaTasks();
    const {
        addFiles: {isOpen, setAddFilesDialog},
    } = useTasksDialogs();

    const defaultEdit = {actionFile: null, taskByProcessFile: null, taskByFrameworkFile: null, categoryFile: null};
    const [edit, setEdit] = useState<Record<keyof TTaskFiles, {file: string, fileName: string} | null>>(defaultEdit);

    const handleClose = () => {
        if (isUploadingFiles) return;

        setAddFilesDialog({isOpen: false});
        setEdit(defaultEdit)
    };

    const handleSave = () => {
        addFromFiles({
            actionFile: edit.actionFile?.file,
            taskByProcessFile: edit.taskByProcessFile?.file,
            taskByFrameworkFile: edit.taskByFrameworkFile?.file,
            categoryFile: edit.categoryFile?.file,
        });
    };

    useEffect(() => {
        setEdit(defaultEdit)
        // eslint-disable-next-line
    }, [isOpen])

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Typography fontWeight={600} fontSize={'24px'}>{'Add categories from files'}</Typography>
            </DialogTitle>

            <DialogContent sx={{gap: '18px', padding: '4px 0', display: 'flex', flexDirection: 'column', p: '10px', width: 'min(500px, 90vw)'}}>

                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>
                        <b style={{fontWeight: 600}}>Tasks</b>: TaskId, Task Name, Task Category, Evidence
                    </Typography>
                    <Typography fontWeight={400} fontSize={'16px'}>
                        <b style={{fontWeight: 600}}>Actions</b>: Action Name, Action Type, TaskId, data
                    </Typography>
                </Box>


                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Tasks File: ' + (edit.taskByFrameworkFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'taskByFrameworkFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, taskByFrameworkFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.taskByFrameworkFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, taskByFrameworkFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>

                {/*<Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>*/}
                {/*    <Typography fontWeight={400} fontSize={'16px'}>{'Task By Process File: ' + (edit.taskByProcessFile?.fileName || '')}</Typography>*/}
                {/*    <Flex ai={"center"} style={{gap: '8px'}}>*/}
                {/*        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'taskByProcessFile'} accept={'.csv'} onChange={async (e) => {*/}
                {/*            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;*/}
                {/*            setEdit(prev => ({...prev, taskByProcessFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))*/}
                {/*        }}/>*/}

                {/*        {edit.taskByProcessFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, taskByProcessFile: null}))}><Delete/></IconButton>}*/}
                {/*    </Flex>*/}
                {/*</Box>*/}

                {/*<Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>*/}
                {/*    <Typography fontWeight={400} fontSize={'16px'}>{'Category File: ' + (edit.categoryFile?.fileName || '')}</Typography>*/}
                {/*    <Flex ai={"center"} style={{gap: '8px'}}>*/}
                {/*        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'categoryFile'} accept={'.csv'} onChange={async (e) => {*/}
                {/*            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;*/}
                {/*            setEdit(prev => ({...prev, categoryFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))*/}
                {/*        }}/>*/}

                {/*        {edit.categoryFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, categoryFile: null}))}><Delete/></IconButton>}*/}
                {/*    </Flex>*/}
                {/*</Box>*/}

                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'Action File: ' + (edit.actionFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'actionFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, actionFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.actionFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, actionFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant={"outlined"} onClick={handleClose} disabled={isUploadingFiles}>Cancel</Button>
                <LoadingButton variant={"contained"} onClick={handleSave} loading={isUploadingFiles}
                               disabled={!edit.taskByFrameworkFile && !edit.taskByProcessFile && !edit.categoryFile && !edit.actionFile}>
                    Upload
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};