import {FC, Fragment, useState} from "react";
import {useAssetsVariants} from "../../hooks/useAssetsVariants";
import {Flex} from "../../../../../shared/components/Layouts";
import {
    Box,
    Button,
    Collapse,
    Divider,
    IconButton,
    Table, TableBody, TableCell,
    TableContainer, TableRow,
    Typography
} from "@mui/material";
import {Typo} from "../../../../../shared/components/Typography";
import colors from "../../../../../shared/theme/colors";
import {LocalSpinner} from "../../../../../shared/components/Spinner";
import {Delete} from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {SearchField} from "../../../../../shared/components/CustomInputs";

export const AssetSettingsExact: FC = () => {

    const {
        page,
        fields: {fields, selectedField},
        variants, jobs, statuses, setSelectedField,
        isLoading: {isLoadingFields},
        openAddSetting, openDeleteSetting, openEditSetting,
        filter, handleSetFilter,
    } = useAssetsVariants();

    // const [value, setValue] = useState<{title: string, description: string, requirements: string}>({title: '', description: '', requirements: ''});
    // useEffect(() => {
    //     !isAddingField && setValue({title: '', description: '', requirements: ''});
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isAddingField])

    const [collapse, setCollapse] = useState<string>('');

    const toggleCollapse = (id: string) => {
        if (id === collapse) setCollapse('');
        else setCollapse(id);
    }

    return (
        <>
            <Box display={'flex'} flexDirection={"column"} width={'50%'} m={'20px 0'} overflow={'auto'}>
                {isLoadingFields && <LocalSpinner/>}

                {fields.map((field, index, array) => (
                    <Fragment key={field.name}>
                        <Flex ai={"center"} p={'8px 16px'}
                              style={{backgroundColor: field.name === selectedField ? colors.navButtonActive : undefined}}
                              onClick={() => {
                                  setSelectedField(field.name);
                                  handleSetFilter('');
                              }}>
                            <Typo fontSize={'18px'} fontWeight={600}>{field.title}</Typo>
                        </Flex>

                        {index < array.length - 1 && <Divider flexItem variant={"fullWidth"}/>}
                    </Fragment>
                ))}
            </Box>

            {selectedField && (
                <Divider flexItem variant={"fullWidth"} orientation={"vertical"}/>
            )}

            {selectedField &&
                <Flex direction={"column"} basis={'50%'} p={'16px 12px'} overflow={'hidden'}>

                    <Box display={'flex'}>
                        <Button variant={'contained'} startIcon={<AddIcon />} sx={{alignSelf: 'flex-start', fontWeight: 700}} disabled={isLoadingFields} onClick={openAddSetting}>{('Create')}</Button>

                        <SearchField
                            size={'small'}
                            sx={{ml: '16px', width: '320px'}}
                            variant={'outlined'}
                            placeholder={'Search'}
                            onChange={(e) => handleSetFilter(e.currentTarget.value)}
                            value={filter}
                            disabled={isLoadingFields}
                            onClear={() => handleSetFilter('')}
                        />
                    </Box>

                    <TableContainer sx={{mt: '9px'}}>
                        <Table >
                            <TableBody>
                                {jobs && jobs.map((job) => (
                                    <Fragment key={job.id}>
                                        <TableRow onClick={() => toggleCollapse(job.id)} >
                                            <TableCell sx={{width: '320px', p: '8px'}} >
                                                <Typography sx={{flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '18px', fontWeight: 600}}>{job.title}</Typography>
                                            </TableCell>
                                            <TableCell align={'right'} sx={{p: '0 16px'}} >
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                                    {page === 'employees' &&
                                                        <IconButton onClick={(e) => {e.stopPropagation(); openEditSetting({oldTitle: job.id, jobDescription: job})}} disabled={isLoadingFields} size={"small"}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    }
                                                    <IconButton onClick={(e) => {e.stopPropagation(); openDeleteSetting(job.id, job.title)}} disabled={isLoadingFields} size={"small"}>
                                                        <Delete/>
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}>
                                                <Collapse in={collapse === job.id} timeout="auto" unmountOnExit>
                                                    <Box display={'flex'} flexDirection={"column"} sx={{mt: '12px'}} width={'100%'}>
                                                        <Typo fontSize={'16px'} style={{marginLeft: '12px'}} fontWeight={600}>{`Type: ${job.type || 'N/A'}`}</Typo>
                                                        <Typo fontSize={'16px'} style={{marginLeft: '12px'}} fontWeight={600}>{`Description: ${job.description || 'N/A'}`}</Typo>
                                                        <Typo fontSize={'16px'} style={{marginLeft: '12px'}} fontWeight={600}>{`Requirements: ${job.requirements || 'N/A'}`}</Typo>
                                                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{mt: '6px'}}/>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                ))}

                                {statuses && statuses.map((status) => (
                                    <Fragment key={status.id}>
                                        <TableRow onClick={() => toggleCollapse(status.id)}>
                                            <TableCell sx={{width: '320px', p: '8px'}} >
                                                <Typography sx={{flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '18px', fontWeight: 600}}>{status.title}</Typography>
                                            </TableCell>
                                            <TableCell align={'right'} sx={{p: '0 16px'}} >
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                                    {page === 'employees' &&
                                                        <IconButton onClick={(e) => {e.stopPropagation(); openEditSetting({oldTitle: status.id, status})}} disabled={isLoadingFields} size={"small"}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    }
                                                    <IconButton onClick={(e) => {e.stopPropagation(); openDeleteSetting(status.id, status.title)}} disabled={isLoadingFields} size={"small"}>
                                                        <Delete/>
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}>
                                                <Collapse in={collapse === status.id} timeout="auto" unmountOnExit>
                                                    <Box display={'flex'} flexDirection={"column"} sx={{mt: '12px'}} width={'100%'}>
                                                        <Typo fontSize={'16px'} style={{marginLeft: '12px'}} fontWeight={600}>{`Text color: ${status.textColor || 'N/A'}`}</Typo>
                                                        <Typo fontSize={'16px'} style={{marginLeft: '12px'}} fontWeight={600}>{`Background color: ${status.backgroundColor || 'N/A'}`}</Typo>
                                                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{mt: '6px'}}/>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                ))}

                                { !jobs && variants && variants.map((variant) => (
                                    <TableRow key={variant} >
                                        <TableCell sx={{width: '320px', p: '10px', fontSize: '14px',}} ><Typo fontSize={'18px'} fontWeight={600} sx={{flexGrow: 1}}>{variant}</Typo></TableCell>
                                        <TableCell align={'right'} width={'50px'} sx={{p: '0 16px'}}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                                <IconButton onClick={(e) => {e.stopPropagation(); openEditSetting({oldTitle: variant})}} disabled={isLoadingFields} size={"small"}>
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton onClick={(e) => {e.stopPropagation(); openDeleteSetting(variant, variant)}} disabled={isLoadingFields} size={"small"}>
                                                    <Delete/>
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Flex>
            }


            {/*{selectedField && (*/}
            {/*    jobs ? (*/}
            {/*        <Flex direction={"column"} basis={'50%'} p={'18px'} overflow={'hidden'}>*/}

            {/*            <Box display={'flex'}>*/}
            {/*                <Button variant={'contained'} startIcon={<AddIcon />} sx={{alignSelf: 'flex-start', fontWeight: 700}} disabled={isLoadingFields} onClick={openAddSetting}>{('Create')}</Button>*/}

            {/*                <SearchField*/}
            {/*                    size={'small'}*/}
            {/*                    sx={{ml: '16px', width: '320px'}}*/}
            {/*                    variant={'outlined'}*/}
            {/*                    placeholder={'Search'}*/}
            {/*                    onChange={(e) => handleSetFilter(e.currentTarget.value)}*/}
            {/*                    value={filter}*/}
            {/*                    disabled={isLoadingFields}*/}
            {/*                    onClear={() => handleSetFilter('')}*/}
            {/*                />*/}
            {/*            </Box>*/}

            {/*            /!*<Flex ai={"center"}>*!/*/}
            {/*            /!*    <TextField variant={"outlined"} size={"small"} style={{maxWidth: '300px'}}*!/*/}
            {/*            /!*               label={'New variant'}*!/*/}
            {/*            /!*               value={value.title} onChange={(e) => setValue(prev => ({...prev, title: e.target.value}))}/>*!/*/}
            {/*            /!*    <IconButton onClick={() => {add('', value)}} disabled={isAddingField || !!isDeletingField || value.title.trim() === '' || jobs.some(e => e.title === value.title.trim())}*!/*/}
            {/*            /!*                style={{width: '45px', height: '45px', margin: '0 10px'}}>*!/*/}
            {/*            /!*        {isAddingField ? <CircularProgress/> : '+'}*!/*/}
            {/*            /!*    </IconButton>*!/*/}
            {/*            /!*</Flex>*!/*/}
            {/*            /!*<TextField variant={"outlined"} size={"small"} style={{maxWidth: '300px', margin: '6px 0 0 0'}} minRows={2} multiline maxRows={3}*!/*/}
            {/*            /!*           label={'New variant description'}*!/*/}
            {/*            /!*           value={value.description} onChange={(e) => setValue(prev => ({...prev, description: e.target.value}))}/>*!/*/}
            {/*            /!*<TextField variant={"outlined"} size={"small"} style={{maxWidth: '300px', margin: '6px 0 0 0'}} minRows={2} multiline maxRows={3}*!/*/}
            {/*            /!*           label={'New variant requirement'}*!/*/}
            {/*            /!*           value={value.requirements} onChange={(e) => setValue(prev => ({...prev, requirements: e.target.value}))}/>*!/*/}

            {/*            <Flex direction={"column"} basis={'50%'} overflowy={"auto"} style={{flexGrow: 1, marginTop: '12px'}}>*/}
            {/*                {jobs.map((variant, index, array) => (*/}
            {/*                    <Box key={JSON.stringify(variant)} display={'flex'} flexDirection={'column'} onClick={() => toggleCollapse(variant.id)}>*/}
            {/*                        <Flex ai={"center"} jc={"space-between"} p={'8px 16px'} cursor={'pointer'} >*/}
            {/*                            <Typo fontSize={'18px'} fontWeight={600} sx={{flexGrow: 1}}>{variant.title}</Typo>*/}

            {/*                            <IconButton onClick={(e) => {e.stopPropagation(); openEditSetting(variant.id, variant)}} disabled={isLoadingFields} size={"small"}>*/}
            {/*                                <EditIcon/>*/}
            {/*                            </IconButton>*/}
            {/*                            <IconButton onClick={(e) => {e.stopPropagation(); openDeleteSetting(variant.id, variant.title)}} disabled={isLoadingFields} size={"small"}>*/}
            {/*                                <Delete/>*/}
            {/*                            </IconButton>*/}
            {/*                        </Flex>*/}

            {/*                        <Collapse in={collapse === variant.id}>*/}
            {/*                            <Flex p={'0 16px 8px'} direction={"column"}>*/}
            {/*                                <Typo fontSize={'16px'} fontWeight={600}>{`Type: ${variant.type || 'N/A'}`}</Typo>*/}
            {/*                                <Typo fontSize={'16px'} fontWeight={600}>{`Description: ${variant.description || 'N/A'}`}</Typo>*/}
            {/*                                <Typo fontSize={'16px'} fontWeight={600}>{`Requirements: ${variant.requirements || 'N/A'}`}</Typo>*/}
            {/*                            </Flex>*/}
            {/*                        </Collapse>*/}

            {/*                        {index < array.length - 1 && <Divider flexItem variant={"fullWidth"}/>}*/}
            {/*                    </Box>*/}
            {/*                ))}*/}
            {/*            </Flex>*/}
            {/*        </Flex>*/}
            {/*    ) : (*/}
            {/*        <Flex direction={"column"} basis={'50%'} p={'18px'} overflow={'hidden'}>*/}

            {/*            <Box display={'flex'}>*/}
            {/*                <Button variant={'contained'} startIcon={<AddIcon />} sx={{alignSelf: 'flex-start', fontWeight: 700}} disabled={isLoadingFields} onClick={openAddSetting}>{('Create')}</Button>*/}

            {/*                <SearchField*/}
            {/*                    size={'small'}*/}
            {/*                    sx={{ml: '16px', width: '320px'}}*/}
            {/*                    variant={'outlined'}*/}
            {/*                    placeholder={'Search'}*/}
            {/*                    onChange={(e) => handleSetFilter(e.currentTarget.value)}*/}
            {/*                    value={filter}*/}
            {/*                    disabled={isLoadingFields}*/}
            {/*                    onClear={() => handleSetFilter('')}*/}
            {/*                />*/}
            {/*            </Box>*/}

            {/*            <Flex direction={"column"} basis={'50%'} overflowy={"auto"} style={{flexGrow: 1, marginTop: '12px'}}>*/}
            {/*                {variants.map((variant, index, array) => (*/}
            {/*                    <Fragment key={variant}>*/}
            {/*                        <Flex ai={"center"} jc={"space-between"} p={'8px 16px'}>*/}
            {/*                            <Typo fontSize={'18px'} fontWeight={600} sx={{flexGrow: 1}}>{variant}</Typo>*/}

            {/*                            {page === 'employees' &&*/}
            {/*                                <IconButton onClick={(e) => {e.stopPropagation(); openEditSetting(variant)}} disabled={isLoadingFields} size={"small"}>*/}
            {/*                                    <EditIcon/>*/}
            {/*                                </IconButton>*/}
            {/*                            }*/}
            {/*                            <IconButton onClick={(e) => {e.stopPropagation(); openDeleteSetting(variant, variant)}} disabled={isLoadingFields} size={"small"}>*/}
            {/*                                <Delete/>*/}
            {/*                            </IconButton>*/}
            {/*                        </Flex>*/}

            {/*                        {index < array.length - 1 && <Divider flexItem variant={"fullWidth"}/>}*/}
            {/*                    </Fragment>*/}
            {/*                ))}*/}
            {/*            </Flex>*/}
            {/*        </Flex>*/}
            {/*    )*/}
            {/*)}*/}
        </>
    )
}