import {useSelector} from "react-redux";
import {extendedCollabOrgs, loading} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
//TABLE
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material/styles";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {useHistory} from "react-router-dom";
import {PATH_ORGANIZATIONS} from "../../../../BarsENV/shell/constants";
import {makeFirstLetterUpper} from "../../../../../shared/utils/text";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#56657F',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

    '&': {
        padding: 0
    }
}));

export const CollabOrganizationsTable = () => {
    const history = useHistory();
    const orgs = useSelector(extendedCollabOrgs);
    const {isLoadingExtendedCollab} = useSelector(loading);

    const handleGoToOrg = (id: string) => history.push(PATH_ORGANIZATIONS + `?page=0&count=5&id=${id}`);

    const handleGetSubColor = (sub: string): string => {
        switch (sub){
            case 'SUBSCRIPTION_FREE': return '#FAC000';
            case 'SUBSCRIPTION_BUSINESS': return '#4c4f6a';
            case 'SUBSCRIPTION_BUSINESS_PLUS': return '#23273F';
            case 'SUBSCRIPTION_ENTERPRISE': return '#00C853';
            case 'SUBSCRIPTION_CONSULTANT': return '#56657F';
            case 'SUBSCRIPTION_AUDITOR': return '#56657F';
            default: return 'black';
        }
    }

        return(
            <>
                {!orgs.length && !isLoadingExtendedCollab && <Typo margin={'15px 0 0 0'}>User has no orgs</Typo>}
                {orgs.length > 0 &&
                    <TableContainer component={Paper} sx={{margin: '15px 0 0 0' }}>
                        <Table sx={{ minWidth: 700, maxHeight: '60vh', margin: 'dense'}} aria-label="customized table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Organization Name</StyledTableCell>
                                    <StyledTableCell align="left">Subscription</StyledTableCell>
                                    <StyledTableCell align="left">Role in Organization</StyledTableCell>
                                    <StyledTableCell align="left">Organization Status</StyledTableCell>
                                    <StyledTableCell align="left">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orgs.map((row) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell align="left">
                                            <Typo>{row.name}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo color={handleGetSubColor(row.subscription)}>{row.subscription}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo>{makeFirstLetterUpper(row.role)}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <Typo color={row.blocked ? 'red' : 'green'}>{row.blocked ? 'Blocked' : 'Active'}</Typo>
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            <CustomButton size={'small'} onClick={() => handleGoToOrg(row.id)}>
                                                View
                                            </CustomButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
        )
}