import {Button, MenuItem, TextField, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../shared/components/Buttons";
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle
} from "../../../../../../shared/components/CustomDialog";
import {useDeleteAssetSetting} from "../../../hooks/dialogs/useDeleteAssetSetting";


export const DeleteAssetSettingDialog = () => {

    const {
        isOk,
        isOpen,
        isLoading,
        name,
        selectedField,
        variants,
        newJobType,
        handleClose,
        handleConfirm,
        handleSetJobType,
    } = useDeleteAssetSetting();

    return (
        <CustomDialog open={isOpen} onClose={handleClose} >
            <CustomDialogTitle >{'Delete row'}</CustomDialogTitle>

            <CustomDialogContent >
                { selectedField !== 'jobType' && <Typography >{`Are you sure you want to delete ${name} ?`}</Typography>}

                { selectedField === 'jobType' &&
                    <TextField label={'Select new job type'} select size={'small'}
                               value={newJobType}
                               required
                               sx={{width: '100%', mt: '10px', '& label': {top: '0 !important'}}}
                               disabled={isLoading}
                    >
                        {variants && variants.filter(e => e !== name).map((e) => (<MenuItem key={e} value={e} onClick={() => {handleSetJobType(e)}}>{e}</MenuItem>))}
                    </TextField>
                }
            </CustomDialogContent>



            <CustomDialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>{'Cancel'}</Button>
                <LoadingButton loading={isLoading} disabled={!isOk} onClick={handleConfirm} sx={{textTransform: 'none', fontWeight: 700, fontSize: '16px'}}>
                    {'Confirm'}
                </LoadingButton>
            </CustomDialogActions>
        </CustomDialog>
    )
}