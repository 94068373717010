import React, {FC, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Flex} from "../../../../../../../shared/components/Layouts";
import {UploadFileBTN} from "../../../../../../../shared/components/FileBTN/UploadFileBTN";
import {toBase64} from "../../../../../../../shared/utils/base64";
import {Delete} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {
    addGapFromFile,
    hideAddGapFromFile,
    isLoadingAddGapFromFile
} from "../../../../store/slice";
import {TBuilderAdminAddGapFiles} from "../../../../types";
import {addGapFromFilesAction} from "../../../../store/actions";
import {LoadingButton} from "@mui/lab";


export const AddGapDialog: FC = () => {

    const dispatch = useDispatch();
    const isUploadingFiles = useSelector(isLoadingAddGapFromFile);
    const isOpen = useSelector(addGapFromFile);

    const defaultEdit = {gapFile: null};
    const [edit, setEdit] = useState<Record<keyof TBuilderAdminAddGapFiles, {file: string, fileName: string} | null>>(defaultEdit);

    const addFromFiles = (files: TBuilderAdminAddGapFiles) => {
        dispatch(addGapFromFilesAction({files}))
    };

    const handleSave = () => {
        addFromFiles({
            gapFile: edit.gapFile?.file,
        })
    }

    const handleClose = () => {
        if (isUploadingFiles) return;

        dispatch(hideAddGapFromFile());
        setEdit(defaultEdit);
    };

    useEffect(() => {
        setEdit(defaultEdit)
        // eslint-disable-next-line
    }, [isOpen])


    return (
        <Dialog open={isOpen} >
            <DialogTitle>
                <Typography fontWeight={600} fontSize={'24px'}>{'Add GAP from file'}</Typography>
            </DialogTitle>
            <DialogContent sx={{gap: '18px', padding: '4px 0', display: 'flex', flexDirection: 'column', p: '10px', width: 'min(500px, 90vw)'}}>
                <Box display={"flex"} flexDirection={"column"} gap={'6px'} width={'100%'}>
                    <Typography fontWeight={400} fontSize={'16px'}>{'GAP File: ' + (edit.gapFile?.fileName || '')}</Typography>
                    <Flex ai={"center"} style={{gap: '8px'}}>
                        <UploadFileBTN disabled={isUploadingFiles} text={'Select file'} id={'gapFile'} accept={'.csv'} onChange={async (e) => {
                            const fileBase64 = e.target.files ? await toBase64(e.target.files[0]) : undefined;
                            setEdit(prev => ({...prev, gapFile: fileBase64 ? {file: fileBase64, fileName: e.target.files![0].name} : null}))
                        }}/>

                        {edit.gapFile && <IconButton size={"medium"} onClick={() => setEdit(prev => ({...prev, gapFile: null}))}><Delete/></IconButton>}
                    </Flex>
                </Box>

            </DialogContent>

            <DialogActions>
                <Button variant={"outlined"} onClick={handleClose} disabled={isUploadingFiles}>Cancel</Button>
                <LoadingButton variant={"contained"} onClick={handleSave} loading={isUploadingFiles}
                               disabled={!edit.gapFile}>
                    Upload
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}