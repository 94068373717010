import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {apps, loadings, openDeleteApp, selectApp, selectedApp} from "../../store/slice";
import React, {useEffect, useState} from "react";
import {useForm} from "../../../../../shared/hooks/useForm";
import {AppInfo} from "../../types";
import {PATH_LOCAL_APPS_LIST} from "../../constants";
import {GetAppsInfos, UpdateApp} from "../../store/actions";
import {addErrorSnack} from "../../../../BarsENV/snack/store/slice";

export const useExactApp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams<{id: string}>();
    //selectors
    const exactApp = useSelector(selectedApp);
    const _apps = useSelector(apps);
    const {update, list} = useSelector(loadings);

    useEffect(() => {
        if(!_apps.length && !exactApp && id){
            //if not loading and no app presented
            dispatch(GetAppsInfos());
        }

        if(exactApp){
            setForm(exactApp);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!list && _apps.length > 0){
            const _app = _apps.find(e => e.id === id);
            if(_app){
                dispatch(selectApp(_app));
                setForm(_app);
            }else{
                history.push(PATH_LOCAL_APPS_LIST);
                dispatch(addErrorSnack(`App with id ${id} not found.`));
            }
        }
        //eslint-disable-next-line
    }, [list]);

    const initialForm: AppInfo = {
        id: '',
        name: '',
        releaseDate: null,
        type: null,
        logo: null,
        certifications: [],
        description: null,
        applicationGroup: null,
        securityScope: null,
        documentationLink: null,
        endpointMappingName: null,
        status: '',
        config: null,
    };
    const {form, setForm, handleChange} = useForm<AppInfo>(initialForm);

    //
    const cleanUp = () => {
        setForm(initialForm);
    }

    const isOkToSave = (): boolean => {
        return JSON.stringify(form) !== JSON.stringify(exactApp);
    }

    //
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    //
    const cerfForm = useForm<{cerf: string}>({cerf: ''});
    const handleAddCerf = () => {
        if(cerfForm.form.cerf.trim().length > 0 && !form.certifications.some(e => e.trim().toLowerCase() === cerfForm.form.cerf.trim().toLowerCase())){
            setForm({...form, certifications: [...form.certifications, cerfForm.form.cerf]});
            cerfForm.setForm({cerf: ''});
        }
    }
    const handleDeleteCerf = (cerf: string) => {
        setForm({...form, certifications: [...form.certifications.filter(e => e !== cerf)]});
    }

    const handleSetStatus = (status: string) => {
        setForm({...form, status});
    }

    const handleSetReleaseDate = (releaseDate: string) => {
        setForm({...form, releaseDate});
    }

    const handleCreateConfig = () => {
        setForm({
            ...form,
            config: {
                collectionPrefix: '',
                collectionConfigs: [],
                accountMappingConfig: {
                    accountCollection: '',
                    accountCollectionField: '',
                    employeeCollection: '',
                    employeeCollectionField: ''
                }
            }
        })
    }

    //CONFIG
    const handleChangeCollectionPrefix = (field: string) => {
        form.config && setForm({...form, config: {...form.config, collectionPrefix: field}});
    }

    const handleChangeAccountMappingConfig = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        form.config?.accountMappingConfig &&  setForm({
            ...form,
            config: {
                ...form.config,
                accountMappingConfig: {
                    ...form.config?.accountMappingConfig,
                    [event.target.name]: event.target.value,
                }
            }
        });
    };

    const collectionForm = useForm<{name: string}>({name: ''});

    const handleAddCollection = () => {
        if(collectionForm.form.name.trim().length > 0 && form.config && !form.config.collectionConfigs.some(e => e.name.trim().toLowerCase() === collectionForm.form.name.trim().toLowerCase())){
            setForm({
                ...form,
                config: {
                    ...form.config,
                    collectionConfigs: [{name: collectionForm.form.name, alias: '', data: [], headers: [], excludeFields: [], sortingField: '', mappingFieldsSchema: [] }, ...form.config.collectionConfigs]
                }
            });
            collectionForm.setForm({name: ''});
        }
    }

    const handleDeleteCollection = (name: string) => {
        form.config && setForm({...form, config: {...form.config, collectionConfigs: [...form.config.collectionConfigs.filter(e => e.name !== name)]}});
    }

    const handleChangeCollectionConfig = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, collectionName: string) => {
        event.persist();
        form.config?.accountMappingConfig &&  setForm({
            ...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionName ? {...e, [event.target.name]: event.target.value} : e)
            }
        });
    };


    //
    const [headerForm, setHeaderForm] = useState<{header: string, forCollectionName: string}>({header: '', forCollectionName: ''});

    const handleAddHeader = () => {
        if(headerForm.header.trim().length > 0 && form.config){
            const collectionConfig = form.config.collectionConfigs.find(e => e.name === headerForm.forCollectionName);
            if(form.config && collectionConfig && !collectionConfig.headers.some(e => e.trim().toLowerCase() === headerForm.header.trim().toLowerCase())){
                //if collection config found and there is no such header
                setForm({
                    ...form,
                    config: {
                        ...form.config,
                        collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionConfig.name ? {...collectionConfig, headers: [headerForm.header, ...collectionConfig.headers]} : e)
                    }
                });
                setHeaderForm({header: '', forCollectionName: ''});
            }
        }
    }

    const handleDeleteHeader = (header: string, collectionName: string) => {
        form.config && setForm({...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionName ? {...e, headers: e.headers.filter(h => h !== header)} : e)
            }
        })
    }

    //
    const [dataForm, setDataForm] = useState<{data: string, forCollectionName: string}>({data: '', forCollectionName: ''});

    const handleAddData = () => {
        if(dataForm.data.trim().length > 0 && form.config){
            const collectionConfig = form.config.collectionConfigs.find(e => e.name === dataForm.forCollectionName);
            if(form.config && collectionConfig && !collectionConfig.data.some(e => e.trim().toLowerCase() === headerForm.header.trim().toLowerCase())){
                //if collection config found and there is no such header
                setForm({
                    ...form,
                    config: {
                        ...form.config,
                        collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionConfig.name ? {...collectionConfig, data: [dataForm.data, ...collectionConfig.data]} : e)
                    }
                })
                setDataForm({data: '', forCollectionName: ''});
            }
        }
    }

    const handleDeleteData = (data: string, collectionName: string) => {
        form.config && setForm({...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionName ? {...e, data: e.data.filter(d => d !== data)} : e)
            }
        })
    }

    //
    const [excludeForm, setExcludeForm] = useState<{exclude: string, forCollectionName: string}>({exclude: '', forCollectionName: ''});

    const handleAddExclude = () => {
        if(excludeForm.exclude.trim().length > 0 && form.config){
            const collectionConfig = form.config.collectionConfigs.find(e => e.name === excludeForm.forCollectionName);
            if(form.config && collectionConfig && !collectionConfig.excludeFields.some(e => e.trim().toLowerCase() === excludeForm.exclude.trim().toLowerCase())){
                //if collection config found and there is no such header
                setForm({
                    ...form,
                    config: {
                        ...form.config,
                        collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionConfig.name ? {...collectionConfig, excludeFields: [excludeForm.exclude, ...collectionConfig.excludeFields]} : e)
                    }
                })
                setExcludeForm({exclude: '', forCollectionName: ''})
            }
        }
    }

    const handleDeleteExclude = (data: string, collectionName: string) => {
        form.config && setForm({...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionName ? {...e, excludeFields: e.excludeFields.filter(d => d !== data)} : e)
            }
        })
    }

    //account mapping scheme
    const [mappingFieldForm, setMappingFieldForm] = useState<{name: string, forCollectionName: string}>({name: '', forCollectionName: ''});

    const handleAddMappingFieldScheme = () => {
        if(mappingFieldForm.name.trim().length > 0 && form.config){
            const collectionConfig = form.config.collectionConfigs.find(e => e.name === mappingFieldForm.forCollectionName);
            if(form.config && collectionConfig && !collectionConfig.mappingFieldsSchema.some(e => e.name.trim().toLowerCase() === mappingFieldForm.name.trim().toLowerCase())){
                //if collection config found and there is no such header
                setForm({
                    ...form,
                    config: {
                        ...form.config,
                        collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionConfig.name ? {...collectionConfig, mappingFieldsSchema: [...collectionConfig.mappingFieldsSchema, {name: mappingFieldForm.name, representationName: '', sourceName: ''}]} : e)
                    }
                })
                setMappingFieldForm({name: '', forCollectionName: ''})
            }
        }
    }

    const handleDeleteMappingFieldScheme = (data: string, collectionName: string) => {
        form.config && setForm({...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(e => e.name === collectionName ? {...e, mappingFieldsSchema: e.mappingFieldsSchema.filter(d => d.name !== data)} : e)
            }
        })
    }

    const handleChangeMappingAccountField = (value: string, collectionName: string, mappingFieldsSchemeName: string, fieldName: 'sourceName' | 'representationName') => {
        form.config && setForm({...form,
            config: {
                ...form.config,
                collectionConfigs: form.config.collectionConfigs.map(c => c.name !== collectionName ? c :
                    {
                        ...c,
                        mappingFieldsSchema: c.mappingFieldsSchema.map(m => m.name !== mappingFieldsSchemeName ? m :
                            {
                                ...m,
                                [fieldName]: value
                            }
                        )
                    }
                )
            }
        })
    }

    return{
        app: exactApp,
        isLoadingExact: list,
        isLoadingUpdate: update,
        form,
        handleChange,
        handleGoBack: () => {
            history.push(PATH_LOCAL_APPS_LIST);
            cleanUp();
        },
        handleDeleteApp: () => {
            dispatch(openDeleteApp());
        },
        handleSaveChanges: () => {
            const req: AppInfo = form.config ? {...form, config: {...form.config, collectionConfigs: form.config?.collectionConfigs.map(e => {return {...e, sortingField: !e.sortingField?.length ? null : e.sortingField}}) ?? []}} : form;
            dispatch(UpdateApp({
                app: req,
                onSuccess: () => {
                    setIsEditMode(false);
                }
            }))
        },
        handleExportJson: () => {
            if(exactApp){
                let dataStr = JSON.stringify(exactApp);
                let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

                let exportFileDefaultName = `${exactApp.name}_integration.json`;

                let linkElement = document.createElement('a');
                linkElement.setAttribute('href', dataUri);
                linkElement.setAttribute('download', exportFileDefaultName);
                linkElement.click();
            }
        },
        handleCancelChanges: () => {
            if(exactApp){
                setForm(exactApp);
                setIsEditMode(false);
            }
        },
        isOkToSave: isOkToSave(),
        isEditMode,
        setIsEditMode,
        handleSetStatus,
        handleSetReleaseDate,
        handleCreateConfig,
        handleChangeCollectionPrefix,

        handleAddCerf,
        handleDeleteCerf,
        cerfForm,
        handleChangeAccountMappingConfig,

        collectionForm,
        handleDeleteCollection,
        handleAddCollection,
        handleChangeCollectionConfig,

        header: {
            headerForm,
            setHeaderForm,
            handleAddHeader,
            handleDeleteHeader
        },
        data: {
            dataForm,
            setDataForm,
            handleAddData,
            handleDeleteData
        },
        exclude: {
            excludeForm,
            setExcludeForm,
            handleAddExclude,
            handleDeleteExclude
        },
        mappingField:{
            mappingFieldForm,
            setMappingFieldForm,
            handleAddMappingFieldScheme,
            handleDeleteMappingFieldScheme,
            handleChangeMappingAccountField
        },
    }
}