import {gql} from "@apollo/react-hooks";
import {print} from "graphql";

const categoryFragment = gql`
    fragment categoryFragment on VicaAdminCategoryModel {
        id
        name
        description
    }
`
const taskFragment = gql`
    fragment taskFragment on VicaAdminTaskModel {
        id
        name
        order
        evidenceIds
        actions {
            title
            data
            type
        }
    }
`
const orderFragment = gql`
    fragment orderFragment on VicaAdminOrderModel {
        id
        name
        order
    }
`

export const vicaAdminGetCategories = print(gql`
    query vicaAdminGetCategories {
        vicaAdminGetCategories {
            categories {
                ...categoryFragment
            }
            flow {
                ...orderFragment
            }
        }
    }
    ${categoryFragment}
    ${orderFragment}
`)

export const vicaAdminGetTasksByCategoryId = print(gql`
    query vicaAdminGetTasksByCategoryId($categoryId: String!, $frameworkView: Boolean!) {
        vicaAdminGetTasksByCategoryId(categoryId: $categoryId, frameworkView: $frameworkView) {
            ...taskFragment
        }
    }
    ${taskFragment}
`)

export const vicaAdminGetTaskById = print(gql`
    query vicaAdminGetTaskById($categoryId: String!, $taskId: String!, $frameworkView: Boolean!) {
        vicaAdminGetTaskById(categoryId: $categoryId, taskId: $taskId, frameworkView: $frameworkView) {
            ...taskFragment
        }
    }
    ${taskFragment}
`)

export const vicaAdminCreateTask = print(gql`
    mutation vicaAdminCreateTask($categoryId: String!, $task: VicaAdminTaskInput!, $frameworkView: Boolean!) {
        vicaAdminCreateTask(categoryId: $categoryId, task: $task, frameworkView: $frameworkView) {
            ...taskFragment
        }
    }
    ${taskFragment}
`)

export const vicaAdminUpdateTask = print(gql`
    mutation vicaAdminUpdateTask($categoryId: String!, $task: VicaAdminTaskInput!, $frameworkView: Boolean!, $moveToCategoryId: String) {
        vicaAdminUpdateTask(categoryId: $categoryId, task: $task, frameworkView: $frameworkView, moveToCategoryId :$moveToCategoryId) {
            ...taskFragment
        }
    }
    ${taskFragment}
`)

export const vicaAdminDeleteTask = print(gql`
    mutation vicaAdminDeleteTask($categoryId: String!, $taskId: String!, $frameworkView: Boolean!) {
        vicaAdminDeleteTask(categoryId: $categoryId, taskId: $taskId, frameworkView: $frameworkView) {
            message
        }
    }
`)

export const vicaAdminCreateCategory = print(gql`
    mutation vicaAdminCreateCategory($category: VicaAdminCategoryInput!, $orders: [VicaAdminOrderInput!]!) {
        vicaAdminCreateCategory(category: $category, orders: $orders) {
            ...categoryFragment
        }
    }
    ${categoryFragment}
`)

export const vicaAdminUpdateCategory = print(gql`
    mutation vicaAdminUpdateCategory($category: VicaAdminCategoryInput!, $orders: [VicaAdminOrderInput!]!, $tasks: [VicaAdminTaskInput!]) {
        vicaAdminUpdateCategory(category: $category, orders: $orders, tasks: $tasks) {
            ...categoryFragment
        }
    }
    ${categoryFragment}
`)

export const vicaAdminChangeCategoriesOrder = print(gql`
    mutation vicaAdminChangeCategoriesOrder($order: [VicaAdminOrderInput!]!) {
        vicaAdminChangeCategoriesOrder(order: $order) {
            ...orderFragment
        }
    }
    ${orderFragment}
`)

export const vicaAdminDeleteCategory = print(gql`
    mutation vicaAdminDeleteCategory($categoryId: String!) {
        vicaAdminDeleteCategory(categoryId: $categoryId) {
            message
        }
    }
`)

export const vicaAdminAddFromFiles = print(gql`
    mutation vicaAdminAddFromFiles($files: VicaTasksFilesInput!) {
        vicaAdminAddFromFiles(files: $files) {
            categories {
                ...categoryFragment
            }
            flow {
                ...orderFragment
            }
        }
    }
    ${categoryFragment}
    ${orderFragment}
`)
