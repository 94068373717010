import {
    Button,
    Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ListItemIcon,
    Typography
} from "@mui/material";
import React from "react";
import {FixedSizeList as List} from 'react-window';
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import {ManagePermissionsDialogProps, TPermission} from "../../../types";
import {useManagePermissionsDialog} from "../../../hooks/dialogs/useManagePermissionsDialog";
import {CustomSearchField} from "../../../../../../shared/components/Inputs";
import { Flex } from "../../../../../../shared/components/Layouts";
import colors from "../../../../../../shared/theme/colors";

export const ManagePermissionsDialog = (props: ManagePermissionsDialogProps) => {
    const {
        availablePermissions,
        selectedPermissions,
        isOpen,
        handleClose,
        roleName,
        onCellClick,
        search,
        selectAllPermission,
        deselectAllPermissions,
        isSelectAllCheckboxSelected,
        isShowNoResult
    } = useManagePermissionsDialog(props);

    // console.log(`availablePermissions.filter(e => selectedPermissions.some(s => s === e)).length > 0 ${availablePermissions.filter(e => selectedPermissions.some(s => s === e)).length} ${isSelectAllCheckboxSelected}`);
    return(
        <Dialog open={isOpen} onClose={handleClose}  fullWidth>
            <DialogTitle>{`Manage ${roleName} permissions`}</DialogTitle>

            <DialogContent>
                <CustomSearchField
                    variant={'outlined'}
                    onChange={search.handleChange}
                    name={'search'}
                    value={search.search}
                    onClear={search.handleCleanSearch}
                    onKeyDown={search.handleEnterKey}
                    label={('Search')}
                    dir={'ltr'}
                    placeholder={('Search')}
                    size="small"
                    fullWidth
                    sx={{margin: '8px 0 0 0'}}
                />

                {!isShowNoResult && <Flex w={'100%'} ai={'center'} p={'0 16px'} jc={'space-between'}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={isSelectAllCheckboxSelected} //checked if filtered array has 1 or more selected rows
                            // disableRipple
                            //availablePermissions is filtered array - after search if click on top checkbox - selecting all permissions that were on screen
                            onClick={() => isSelectAllCheckboxSelected ? deselectAllPermissions(availablePermissions.map(e => e.name)) : selectAllPermission(availablePermissions.map(e => e.name))}
                        />
                    </ListItemIcon>

                    <Flex w={'65%'}>
                        <Typography>{('Permission name')}</Typography>
                    </Flex>

                    <Flex w={'65%'}>
                        <Typography>{('Tags')}</Typography>
                    </Flex>
                </Flex>}

                {!isShowNoResult && <Divider flexItem sx={{bgcolor: colors.skip, borderColor: colors.skip}}/>}

                {isShowNoResult && <Typography sx={{margin: '16px 0 0 0'}}>{('Nothing was found for your query. Try changing the query.')}</Typography>}

                <Flex w={'100%'} minh={'300px'} h={'300px'}>
                    <AutoSizer>
                        {({height, width}: Size) => (
                            <List
                                height={height ?? 0}
                                itemCount={availablePermissions.length}
                                itemSize={58}
                                width={width ?? 0}
                                // itemData={{availablePermissions, selectedPermissions, onCellClick}}
                            >
                                {(props) => RowRenderer({...props, availablePermissions, selectedPermissions, onCellClick})}
                                {/*{rowRenderer}*/}
                            </List>
                        )}
                    </AutoSizer>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

function RowRenderer(props: any) {
    const { index, style, onCellClick, selectedPermissions, availablePermissions} = props;
    const permission: TPermission | undefined = availablePermissions.find((e: string, id: number) => id === index);
    const isChecked: boolean = Boolean(selectedPermissions.find((e: string) => e === (permission?.name ?? '')));
    const labelId = `checkbox-list-label-${permission}`;
    return (
        <Flex
            w={'100%'}
            ai={'center'}
            jc={'space-between'}
            p={'8px 16px'}
            style={style}
            hoverStyles={`background-color: ${colors.background}`}
            onClick={() => onCellClick(permission)}
        >
            <Checkbox
                edge="start"
                checked={isChecked}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
            />

            <Flex w={'65%'}>
                <Typography variant={'body1'} sx={{margin: '0 0 0 20px'}}>{permission?.name ?? 'Name not found'}</Typography>
            </Flex>

            <Flex w={'30%'}>
                {permission?.tags.map(e => <Chip label={e} key={e}/>)}
            </Flex>
        </Flex>
    );
}
