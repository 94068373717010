import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsVariantFieldsSelector,
    assetsVariantIsLoadingsSelector,
    assetsVariantPageSelector,
    cleanAction, hideCreateAssetSetting,
    hideDeleteAssetSetting,
    hideEditAssetSetting, jobTypeVariantsSelector,
    openCreateAssetSetting,
    openDeleteAssetSetting,
    openEditAssetSetting,
    setPageAction,
    setSelectedFieldAction,
    TAssetsSettingsSlice
} from "../../store/slice";
import {TAssetDialogs, TAssetField, TAssets, TJobDescription, TStatus} from "../../types";
import {
    addAssetSettingsAction,
    getAssetSettingsAction, getDataForCreateJobTitleAction,
    removeAssetSettingsAction,
    updateAssetSettingsAction
} from "../../store/actions";
import {useState} from "react";

type TResponse = {
    page: TAssetsSettingsSlice["page"],
    fields: TAssetsSettingsSlice["fields"],
    jobTypeVariants: TAssetsSettingsSlice['jobTypeVariants'],
    variants: TAssetField["values"],
    jobs: TAssetField["jobDescription"],
    statuses: TAssetField["status"],
    isLoading: TAssetsSettingsSlice["isLoading"],
    dialogs: TAssetDialogs,

    setPage: (page: TAssets) => void,
    setSelectedField: (page: TAssetsSettingsSlice["fields"]["selectedField"]) => void,

    getFields: (page: TAssets) => void,
    getDataForCreateJobTitle: () => void,
    add: (data: {variant: string, job?: TJobDescription, status?: TStatus}) => void,
    update: (data: {title: string, job?: TJobDescription, status?: TStatus}) => void,
    remove: (value: string, newJobType?: string) => void,
    openAddSetting: () => void,
    hideAddSetting: () => void,
    openEditSetting: (data: {oldTitle: string, jobDescription?: TJobDescription, status?: TStatus}) => void,
    hideEditSetting: () => void,
    openDeleteSetting: (id: string, name: string) => void,
    hideDeleteSetting: () => void,
    filter: string,
    handleSetFilter: (value: string) => void,

    clean: (data: ('page' | 'fields' | 'isLoading')[]) => void,
}
export const useAssetsVariants = (): TResponse => {
    const dispatch = useDispatch();

    const page = useSelector(assetsVariantPageSelector);
    const fields = useSelector(assetsVariantFieldsSelector);
    const jobTypeVariants = useSelector(jobTypeVariantsSelector);
    const isLoading = useSelector(assetsVariantIsLoadingsSelector);
    const dialogs = useSelector(assetsDialogsSelector);

    const [filter, setFilter] = useState<string>('');
    const handleSetFilter: TResponse['handleSetFilter'] = (value) => {
        setFilter(value);
    }

    const setPage: TResponse['setPage'] = (data) => {dispatch(setPageAction(data))};
    const setSelectedField: TResponse['setSelectedField'] = (data) => {dispatch(setSelectedFieldAction(data))};

    const getFields: TResponse['getFields'] = (asset) => {dispatch(getAssetSettingsAction({asset}))};

    const getDataForCreateJobTitle: TResponse['getDataForCreateJobTitle'] = () => {dispatch(getDataForCreateJobTitleAction())}
    const add: TResponse['add'] = ({variant, job, status}) => {fields.selectedField && dispatch(addAssetSettingsAction(
        {asset: page, variant: variant, field: fields.selectedField, jobTitle: job ? job : undefined, status: status ? status : undefined}))};

    const update: TResponse['update'] = ({title, job, status}) => {
        fields.selectedField && dialogs.editAssetSetting.oldTitle && dispatch(updateAssetSettingsAction(
            {asset: page, field: fields.selectedField, title, oldTitle: dialogs.editAssetSetting.oldTitle, jobTitle: job, status}))
    };
    const remove: TResponse['remove'] = (variant, newJobType) => {fields.selectedField && dispatch(removeAssetSettingsAction({asset: page, variant, field: fields.selectedField, newJobType}))};

    const openAddSetting: TResponse['openAddSetting'] = () => {
        dispatch(openCreateAssetSetting());
    }
    const hideAddSetting: TResponse['hideAddSetting'] = () => {
        dispatch(hideCreateAssetSetting());
    }

    const openEditSetting: TResponse['openEditSetting'] = (data) => {
        dispatch(openEditAssetSetting(data));
    }
    const hideEditSetting: TResponse['hideEditSetting'] = () => {
        dispatch(hideEditAssetSetting());
    }

    const openDeleteSetting: TResponse['openDeleteSetting'] = (id, name) => {
        dispatch(openDeleteAssetSetting({id, name}));
    }
    const hideDeleteSetting: TResponse['hideDeleteSetting'] = () => {
        dispatch(hideDeleteAssetSetting());
    }

    const clean: TResponse['clean'] = (data) => {dispatch(cleanAction(data))}

    return {
        page,
        fields,
        jobTypeVariants,
        variants: fields.fields.find(e => e.name === fields.selectedField)?.values.filter(e => e.toLowerCase().includes(filter.toLowerCase())) || [],
        jobs: fields.fields.find(e => e.name === fields.selectedField)?.jobDescription?.filter(e => e.title.toLowerCase().includes(filter.toLowerCase())),
        statuses: fields.fields.find(e => e.name === fields.selectedField)?.status?.filter(e => e.title.toLowerCase().includes(filter.toLowerCase())),
        isLoading,
        dialogs,
        filter,
        handleSetFilter,

        setPage,
        setSelectedField,

        getFields,
        getDataForCreateJobTitle,
        add,
        update,
        openAddSetting,
        hideAddSetting,
        openEditSetting,
        hideEditSetting,
        openDeleteSetting,
        hideDeleteSetting,
        remove,


        clean,
    }
}