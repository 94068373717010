export const ROOT_BUILDER = '/builder';
export const ROOT_CONTROLS = `${ROOT_BUILDER}/controls`;
export const ROOT_EVIDENCES = `${ROOT_BUILDER}/evidences`;
export const ROOT_POLICIES = `${ROOT_BUILDER}/documents`;
export const ROOT_GALLERY = `${ROOT_BUILDER}/gallery`;


export const BUILDER_WITH_FRAME = `${ROOT_BUILDER}/framework/:frameworkId`;
export const BUILDER_WITH_FRAME_AND_CONTROL = `${ROOT_BUILDER}/framework/:frameworkId/control/:controlId`;
export const CONTROLS = `${ROOT_CONTROLS}/:controlId`;
export const EVIDENCES = `${ROOT_EVIDENCES}/:evidenceId`;
export const POLICIES = `${ROOT_POLICIES}/:policyId`;
export const PATH_LOCAL_2FA = '/mfa';


export const FRAMEWORK_VISIBILITY = ['PRIVATE', 'PUBLIC', 'RESTRICTED'];

export const LOGO_MAX_SIZE = 1048576;


//
export const GAP_ORIGIN = 'Origin';
