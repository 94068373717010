import {
    addEmailTemplatesAdmin,
    deleteEmailTemplatesAdmin,
    getAllEmailTemplatesAdmin,
    updateEmailTemplatesAdmin
} from "../api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {TEmailTemplate} from "../types";

export const GetAllEmailTemplatesAdmin = createAsyncThunk(
    'Builder/getAllEmailTemplatesAdmin',
    async (): Promise<TEmailTemplate[]> => {
        return  await getAllEmailTemplatesAdmin();
    }
);

export const AddEmailTemplatesAdmin = createAsyncThunk(
    'Builder/addEmailTemplatesAdmin',
    async ({template, onSuccess}: {template: TEmailTemplate, onSuccess: (id: string) => void}): Promise<TEmailTemplate> => {
        const res =  await addEmailTemplatesAdmin(template);
        onSuccess(res.id);
        return res;
    }
);

export const UpdateEmailTemplatesAdmin = createAsyncThunk(
    'Builder/UpdateEmailTemplatesAdmin',
    async ({template, onSuccess}: {template: TEmailTemplate, onSuccess?: () => void}): Promise<TEmailTemplate> => {
        const res = await updateEmailTemplatesAdmin(template);
        onSuccess && onSuccess();
        return res;
    }
);

export const DeleteEmailTemplatesAdmin = createAsyncThunk(
    'Builder/deleteEmailTemplatesAdmin',
    async ({template, onSuccess}: {template: TEmailTemplate, onSuccess: () => void}): Promise<TEmailTemplate> => {
        await deleteEmailTemplatesAdmin(template);
        onSuccess();
        return template;
    }
);