import {useAssetsVariants} from "../../useAssetsVariants";
import {useForm} from "../../../../../../shared/hooks/useForm";
import {TJobDescription, TStatus} from "../../../types";
import {useEffect, useState} from "react";


export const useEditAssetSetting = () => {
    const {
        hideEditSetting: handleClose,
        fields: {selectedField},
        jobs, variants, jobTypeVariants, statuses,
        isLoading: {isUpdate: isLoading, dataForDialog: isLoadingDataForDialog},
        dialogs: {editAssetSetting: {isOpen, oldTitle, jobDescription, status: oldStatus}},
        update,
        getDataForCreateJobTitle,
    } = useAssetsVariants();

    const {form: job, setForm, handleChange} = useForm<TJobDescription | null>(null);
    const {form: status, setForm: setStatusForm, handleChange: handleChangeStatus} = useForm<TStatus | null>(null);
    const [title, setTitle] = useState<string>('');

    const handleConfirm = () => {
        switch(selectedField) {
            case 'jobTitle': job && update({title: job.title, job}); break;
            case 'status': status && update({title: status.title, status}); break;
            default: update({title})
        }
    }

    const handleSetJobType = (type: string) => {
        setForm(prev => prev && ({...prev, type}))
    }

    const isOk = () => {
        switch(selectedField) {
            case 'jobTitle': return job && job?.title.trim().length > 0 && job?.type.trim().length > 0
                && !jobs?.filter(e => e.id !== job.id).some((e) => e.title.toLowerCase() === job.title.toLowerCase().trim())
                && (job.title.trim() !== jobDescription?.title.trim() || job.type.trim() !== jobDescription?.type.trim()
                    || job.description.trim() !== jobDescription?.description.trim() || job.requirements.trim() !== jobDescription?.requirements.trim());

            case 'status': return status && status?.title.trim().length > 0
                && !statuses?.filter(e => e.id !== status.id).some((e) => e.title.toLowerCase() === status.title.toLowerCase().trim())
                && (status.title.trim() !== oldStatus?.title.trim() || status.textColor.trim() !== oldStatus?.textColor.trim()
                    || status.backgroundColor.trim() !== oldStatus?.backgroundColor.trim());
            default: return title.trim().length > 0 && title.trim() !== oldTitle?.trim()
                && !variants.filter(e => e.toLowerCase() !== oldTitle?.toLowerCase()).some(e => e.toLowerCase() === title.toLowerCase().trim());
        }
    }

    const isTitleExist = (): boolean => {
        switch (selectedField) {
            case 'jobTitle': return Boolean(jobs?.filter(e => e.title.toLowerCase() !== jobDescription?.title.toLowerCase()).some((e) => e.title.toLowerCase() === job?.title.toLowerCase().trim()));
            case 'status': return Boolean(statuses?.filter(e => e.title.toLowerCase() !== oldStatus?.title.toLowerCase()).some((e) => e.title.toLowerCase() === status?.title.toLowerCase().trim()));
            default: return Boolean(variants.filter(e => e.toLowerCase().trim() !== oldTitle?.toLowerCase().trim()).some(e => e.toLowerCase() === title.toLowerCase().trim()));
        }
    }

    useEffect(() => {
        if(isOpen){
            if (jobDescription) {
                getDataForCreateJobTitle();
                setForm(jobDescription);
            }
            if (oldStatus) setStatusForm(oldStatus);
            else oldTitle && setTitle(oldTitle);
        } else {
            setForm(null);
            setStatusForm(null);
            setTitle('');
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return {
        isOk: isOk(),
        isTitleExist: isTitleExist(),
        isOpen,
        isLoading,
        isLoadingDataForDialog,
        job,
        status,
        jobTypeVariants,
        jobDescription,
        title, setTitle,

        handleClose,
        handleConfirm,
        handleChange,
        handleChangeStatus,
        handleSetJobType,
    }

}