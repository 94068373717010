export const handleGetSubColor = (sub: string): string => {
    switch (sub){
        case 'SUBSCRIPTION_FREE': return '#FAC000';
        case 'SUBSCRIPTION_BUSINESS': return '#4c4f6a';
        case 'SUBSCRIPTION_BUSINESS_PLUS': return '#23273F';
        case 'SUBSCRIPTION_ENTERPRISE': return '#00C853';
        case 'SUBSCRIPTION_CONSULTANT': return '#56657F';
        case 'SUBSCRIPTION_AUDITOR': return '#56657F';
        default: return 'black';
    }
}

export const handleGetSubNormalName = (sub: string): string => {
    switch (sub){
        case 'SUBSCRIPTION_FREE': return 'Free';
        case 'SUBSCRIPTION_BUSINESS': return 'Business';
        case 'SUBSCRIPTION_BUSINESS_PLUS': return 'Business+';
        case 'SUBSCRIPTION_ENTERPRISE': return 'Enterprise';
        case 'SUBSCRIPTION_CONSULTANT': return 'Consultant';
        case 'SUBSCRIPTION_AUDITOR': return 'Auditor';
        default: return 'black';
    }
}