import {useDispatch, useSelector} from "react-redux";
import {
    controls,
    hideUnattachEvidence,
    isLoading,
    selectedControl,
    unattachEvidenceDialog
} from "../../../../store/slice";
import {Flex} from "../../../../../../../shared/components/Layouts";
import CloseIcon from "@material-ui/icons/Close";
import {DialogContent, TextField} from "@mui/material";
import {Typo} from "../../../../../../../shared/components/Typography";
// import {ButtonWithBackround} from "../../../../../../shared/components/ButtonWithBackground";
import {WarningDialog} from "../../../styled";
import React, {useEffect, useState} from "react";
import {controlType, evidenceType} from "../../../../types";
import {DeattachEvidence} from "../../../../store/actions";
import {CustomButton} from "../../../../../../../shared/components/Buttons";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";

export const DetachEvidenceDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const dispatch = useDispatch();
    const {isOpen, evidenceId} = useSelector(unattachEvidenceDialog);
    const _controls = useSelector(controls);
    const currentControlId = useSelector(selectedControl);
    const _isLoading = useSelector(isLoading);

    const [currentControl, setCurrentControl] = useState<controlType | null>(null);
    const [currentEvidence, setCurrentEvidence] = useState<evidenceType | null>(null);

    const [field, updateField] = useState<string>('');

    useEffect(() => {
        if(evidenceId && currentControlId){
            const currControl = _controls.find(e => e.id === currentControlId);
            if(currControl) setCurrentControl(currControl);
            if(currControl && currControl.evidences){
                const currEvidence = currControl.evidences.find(e => e.id === evidenceId);
                if(currEvidence){
                    setCurrentEvidence(currEvidence);
                }
            }
        }
        //eslint-disable-next-line
    }, [evidenceId]);

    useEffect(() => {
        updateField('');
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideUnattachEvidence());
    }
    
    const handleConfirm = () => {
        if(field.trim().toLowerCase() === 'confirm' && currentControl?.id && currentEvidence?.id){
            dispatch(DeattachEvidence({controlId: currentControl.id, evidenceId: currentEvidence.id}))
        }
    }

    return((currentEvidence && !_isLoading) ?
        <WarningDialog
            // maxWidth={''}
            style={{borderRadius: '25px !important', border: '1px solid red'}}
            open={isOpen}
            onClose={handleClose}
        >
            <Flex w={'100%'} jc={'flex-end'} p={!revDir ? '30px 30px 0 0' : '30px 0 0 30px'}>
                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}} />
            </Flex>

            <DialogContent>
                <Flex w={'100%'} direction={'column'} ai={'center'} m={'-20px 0 0 0'} talign={'center'} p={'0 50px'}>
                    <Typo fontSize={'25px'} fontWeight={300} letterSpacing={'1.53px'} color={'#1D1D1D'}>{t('Detach Evidence')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} margin={'27px 0 0 0'} color={'background: rgba(0, 0, 0, 0.54)'}>{t('Any changes will be effects all controls that the evidence has attached!')}</Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'}>Effected on {currentEvidence?.foundInControlsCounter || 0} controls </Typo>
                    <Typo fontSize={'12px'} fontWeight={400} color={'background: rgba(0, 0, 0, 0.54)'} margin={'20px 0 10px 0'}>{t('Please type')} “confirm” {t('to detach')}</Typo>

                    <TextField id="filled-basic" label={t("Confirm")}  variant="outlined" sx={{margin: '0 0 20px 0', width: '400px'}} value={field} onChange={(e) => {updateField(e.target.value as string)}}/>

                    {/* <ButtonWithBackround
                        margin={'0 0 10px 0 '}
                        width={'214px'}
                        height={'37px'}
                        color={field.trim().toLowerCase() === 'confirm' ? '#FFA000' : 'gray'}
                        text={'CONFIRM'}
                        fontColor={'white'}
                        fontSize={'16px'}
                        fontWeight={600}
                        onClick={handleConfirm}
                    /> */}
                    <CustomButton
                        variant={'contained'}
                        color={'lightError'}
                        disabled={field.trim().toLowerCase() !== 'confirm'}
                        onClick={handleConfirm}
                    >
                        {t('CONFIRM')}
                    </CustomButton>
                </Flex>
            </DialogContent>
        </WarningDialog>
            :
            null
    )
}