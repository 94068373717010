import {useDispatch, useSelector} from "react-redux";
import {Flex} from "../../../../../shared/components/Layouts";
import {
    extendedCollab,
    openActivateCollabDialog,
    openBlockCollabDialog,
    openDeleteCollabDialog
} from "../../store/slice";
import {Typo} from "../../../../../shared/components/Typography";
import {CustomButton} from "../../../../../shared/components/Buttons";
import {AdminGenerateRecoveryCodesByPublicId} from "../../store/actions";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDate} from "../../../../../shared/utils/dateTools";

export const CollabInfoWithActions = () => {
    const dispatch = useDispatch();
    const collab = useSelector(extendedCollab);

    const {currentLanguage: {momentLocale}} = useMainTranslation('')

    const blockUser = () => {
        dispatch(openBlockCollabDialog());
    }

    const deleteUser = () => {
        dispatch(openDeleteCollabDialog());
    }

    const activateUser = () => {
        dispatch(openActivateCollabDialog());
    }

    const forceMfa = () => {
        collab && dispatch(AdminGenerateRecoveryCodesByPublicId({id: collab.publicId}));
    }

    return(
        collab ?
            <Flex
                w={'100%'}
                jc={'space-between'}
                p={'18px'}
                br={'12px'}
                box_shadow={'0 0 5px rgba(0, 0, 0, 0.15)'}
                background={'#fff'}
            >
                <Flex w={'70%'} direction={'column'}>
                    <Typo margin={'0 0 10px 0'}>{collab.firstName} {collab.lastName}</Typo>
                    <Typo margin={'0 0 10px 0'}>{collab.email}</Typo>
                    <Typo margin={'0 0 10px 0'}>{collab.publicId}</Typo>
                    <Typo margin={'0 0 10px 0'}>Organization: {collab.organizationName}</Typo>
                    <Typo margin={'0 0 10px 0'}>Created at: {parseDate(collab.createDate, momentLocale)}</Typo>
                    <Typo margin={'0 0 10px 0'}>Last login: {parseDate(collab.lastLoginDate, momentLocale)}</Typo>
                    {!collab.active && <Typo margin={'0 0 10px 0'} color={'red'}>User blocked with {collab.blockedReason ? `reason: ${collab.blockedReason}` : 'no reason'}</Typo>}
                </Flex>

                <Flex w={'15%'} direction={'column'}>
                    {!collab.active ?
                        <CustomButton variant={'contained'} color={'success'} size={'small'} onClick={activateUser}>Active</CustomButton>
                        :
                        <CustomButton variant={'contained'} color={'error'} size={'small'} onClick={blockUser}>Block</CustomButton>
                    }

                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'error'} size={'small'} onClick={deleteUser}>Delete</CustomButton>
                    <CustomButton sx={{marginTop: '15px'}} variant={'contained'} color={'error'} size={'small'} onClick={forceMfa}>Force MFA</CustomButton>
                </Flex>
            </Flex>
        :
            <></>
    )
}