import React, {FC, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {ContentCopy, DeleteOutline, EditOutlined, InfoOutlined} from "@mui/icons-material";
import {EditorSideMenuVariableBlockStyled} from "../../styled";
import {useMenu} from "../../../../../../hooks/useMenu";
import {TEditorVariableData} from "../../../../types";
import {Flex} from "../../../../../Layouts";
import colors from "../../../../../../theme/colors";

interface IProps {
    v: TEditorVariableData;
    onFocus: () => void;
    onBlur: () => void;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;

    handleEditClick: () => void;

    handleCopy: (id: string, value: string, title: string) => void;
    deleteVariable: () => void
}
export const VariableCard: FC<IProps> = ({v, onFocus, onBlur, handleEditClick, onChange, deleteVariable, handleCopy}) => {

    const {open, handleClick: handleDeleteClick, handleClose} = useMenu();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <EditorSideMenuVariableBlockStyled onMouseOver={() => setIsHovered(true)}
                                           onMouseLeave={() => setIsHovered(false)}>
            <div className={'titleWithButtons'}>
                <div className={'name'}>{v.title}</div>

                {isHovered ? (
                    <div className={'buttons'}>
                        <IconButton disableRipple size={"small"} onClick={() => {handleCopy(v.id, v.value || '', v.title)}}>
                            <ContentCopy/>
                        </IconButton>

                        <IconButton disableRipple size={"small"} onClick={handleEditClick}>
                            <EditOutlined/>
                        </IconButton>

                        <IconButton disableRipple size={"small"} onClick={handleDeleteClick}>
                            <DeleteOutline/>
                        </IconButton>
                    </div>
                ) : (
                    <div className={'buttons'}>
                        <span className={'usedCount'}>
                            {v.count > 0 ? `Used: ` : 'Not used'}
                            {v.count > 0 && <span className={'usedCount-count'}>{v.count}</span>}
                        </span>
                    </div>
                )}
            </div>

            <TextField value={v.value || ''} variant={"standard"}
                       placeholder={'Enter value'} multiline
                       onFocus={onFocus}
                       onBlur={onBlur}
                       onChange={onChange}/>

            {v.description?.length ? (
                <Flex gap={'8px'} style={{marginTop: '10px'}}>
                    <InfoOutlined sx={{color: colors.text.grey_dark, width: '18px', height: '18px'}}/>
                    <Typography fontWeight={400} fontSize={'12px'} lineHeight={'130%'} color={colors.text.grey}>{v.description}</Typography>
                </Flex>
            ) : null}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{'Delete variable'}</DialogTitle>
                <DialogContent>
                    <Typography variant={"body2"}>
                        {`You are about to delete [${v.title}] variable.`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                    <Button variant={"contained"} onClick={deleteVariable}>
                        {'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

        </EditorSideMenuVariableBlockStyled>
    )
}