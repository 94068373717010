import {useMainTranslation} from "../../../../../../shared/hooks/useMainTranslationHooks/useMainTranslation";
import {useGallery} from "../../../hooks/useGallery";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {TableRowSkeleton} from "../../../../../../shared/components/tableRowSkeleton";
import {SearchField} from "../../../../../../shared/components/CustomInputs";
import {Add, DeleteForever, Edit, InsertDriveFile} from "@mui/icons-material";
import {DeleteDialogButton} from "../../../../../../shared/components/deleteDialogButton";
import {useOpenDialog} from "../../../../../../shared/hooks/useOpenDialog";
import {LoadingButton} from "../../../../../../shared/components/Basic/CommonButtons";


export const BuilderFilesList = () => {
    const {t: tCommon, revDir} = useMainTranslation('', {keyPrefix: 'CommonComponents'});

    const {
        documents, selectedDocument,
        isLoading: {isLoadingList, isLoadingCurrent, isDeleting, isCreating},
        getTemplatesList,
        getTemplateById,
        deleteTemplate,
        createTemplate,

        search, handleSetSearch,
    } = useGallery();

    const deleteDialog = useOpenDialog<string>();
    const createDialog = useOpenDialog<{id: string, name: string}>();

    useEffect(() => {
        getTemplatesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        createDialog.closeDialog();
        deleteDialog.closeDialog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedDocument), JSON.stringify(documents)])

    return (
        <Box width={'100%'} height={'98%'} display={'flex'} flexDirection={'column'} sx={{backgroundColor: 'white', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.15)', borderRadius: '10px', overflow: 'hidden' }}>
            <Box width={'100%'} p={!revDir ? '17px 20px 8px 15px' : '17px 15px 8px 20px'} display={'flex'} alignItems={'center'} >
                <Typography fontSize={'24px'} color={'rgba(0, 0, 0, 0.54)'}>Gallery</Typography>

                <Box flexGrow={1} sx={{ml: '16px', mr: '16px'}}>
                    <SearchField
                        size={'small'}
                        variant={'outlined'}
                        sx={{width: '320px'}}
                        onClear={handleSetSearch}
                        onChange={(e) => {handleSetSearch(e.target.value)}}
                        value={search}
                        disabled={isLoadingList}
                    />
                </Box>

                <Button startIcon={<Add fontSize={'small'}/>} size={'medium'} variant={'contained'} sx={{ml: '8px', mr: '8px'}} onClick={() => createDialog.openDialog({id: '', name: ''})}>
                    {'Create'}
                </Button>
            </Box>

            <Divider flexItem style={{ margin: !revDir ? '0 20px 10px 15px' : '0 15px 10px 20px', borderColor: '#FAC000'}} />

            <TableContainer sx={{mt: '9px'}}>
                <Table stickyHeader>
                    <TableBody>
                        {documents.map((file) => (
                            <TableRow key={file.id}>
                                <TableCell align={'center'} sx={{p: '8px'}} >
                                    <Typography>{file.id}</Typography>
                                </TableCell>
                                <TableCell sx={{p: '8px'}} >
                                    <Box display={'flex'} alignItems={'center'}>
                                        <InsertDriveFile />
                                        <Typography sx={{ml: '10px', cursor: 'pointer', flexGrow: 1}}>{file.name}</Typography>

                                    </Box>
                                </TableCell>
                                <TableCell >
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                        <IconButton onClick={() => getTemplateById(file.id)} sx={{p: '0'}} disabled={isLoadingCurrent.length > 0}>
                                            {isLoadingCurrent.includes(file.id) ? <CircularProgress size={20} /> : <Edit/>}
                                        </IconButton>
                                        <IconButton onClick={() => deleteDialog.openDialog(file.id)} sx={{p: '0', m: '0 8px'}}>
                                            <DeleteForever/>
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                        {isLoadingList && <TableRowSkeleton columns={2} />}
                    </TableBody>
                </Table>
            </TableContainer>



            <DeleteDialogButton isOpen={deleteDialog.isOpen}
                                isLoading={isDeleting.includes(deleteDialog.dialogData || '')}
                                title={'Delete template'}
                                message={`Are you sure you want to delete template ${deleteDialog.dialogData || ''}?`}
                                handleConfirm={() => deleteDialog.dialogData !== '' && deleteTemplate(deleteDialog.dialogData || '')}
                                handleClose={deleteDialog.closeDialog}/>

            <Dialog open={createDialog.isOpen} onClose={createDialog.closeDialog} sx={{
                '& .MuiDialog-paper': {
                    width: '400px',
                    minWidth: '400px',
                }
            }}>
                <DialogTitle>{'Create new template'}</DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '12px', pt: '6px !important'}}>
                    <TextField value={createDialog.dialogData?.id || ''}
                               onChange={(e) => createDialog.openDialog({id: e.target.value, name: createDialog.dialogData!.name})}
                               label={'ID'}/>
                    <TextField value={createDialog.dialogData?.name || ''}
                               onChange={(e) => createDialog.openDialog({name: e.target.value, id: createDialog.dialogData!.id})}
                               label={'Name'}/>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} onClick={createDialog.closeDialog} disabled={isCreating}>{tCommon('Cancel')}</Button>
                    <LoadingButton loading={isCreating} disabled={createDialog.dialogData?.name.trim() === '' || createDialog.dialogData?.id.trim() === '' || documents.some(e => e.id === createDialog.dialogData?.id.trim())}
                                   onClick={() => createDialog.dialogData && createTemplate(createDialog.dialogData.id, createDialog.dialogData.name)}>
                        {tCommon('Confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
